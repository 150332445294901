// @flow

import React from 'react';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Icon from '../../../components/UiElements/Icon';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';

type Props = {
	symbols: Array<Symbol>,
	symbolsTotal: number,
	loading: boolean,
	handleEditFractional: (Object) => void,
	applicationId: string,
	groupId: string,
	currentPage: number,
	handlePageChange: Function,
	headerHeight: number,
}

const FractionalSymbols = (props: Props) => {
	const {
		handleEditFractional,
		loading,
		applicationId,
		symbols,
		groupId,
		currentPage,
		handlePageChange,
		symbolsTotal,
		headerHeight,
	} = props;

	const getLink = (symbolId) => {
		if (groupId === 'applicationSymbols') {
			return (
				<a
					href={`/application/${applicationId}/system/symbols/${symbolId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{symbolId}
				</a>
			);
		}
		return (
			<a
				href={`/application/${applicationId}/system/groups/${groupId}/${symbolId}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{symbolId}
			</a>
		);
	};

	const columns = [
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 112,
			render: (text: string) => getLink(text),
		},
		{
			title: 'name',
			dataIndex: 'displayName',
			key: 'name',
			width: 160,

		},
		{
			title: 'symbol type',
			dataIndex: 'group',
			key: 'type',
			width: 144,
			render: () => (
				<div>
					{groupId === 'applicationSymbols' ? 'Application Symbol' : 'Group Symbol'}
				</div>
			),
		},
		{
			title: 'exchange precision',
			dataIndex: 'exchangePrecision',
			key: 'exchangePrecision',
			width: 176,
			render: (text: string, record: Symbol) => (
				<div>
					<span>
						{text}
					</span>
					<span className="bold">
						{` increment ${record.orderSize.increment || '-'}`}
					</span>
				</div>
			),
		},
		{
			title: 'exchange minimum',
			dataIndex: 'exchangeMinimum',
			key: 'exchangeMinimum',
			align: 'right',
			width: 144,
		},
		{
			title: 'base precision',
			dataIndex: 'basePrecision',
			key: 'basePrecision',
			width: 112,
		},
		{
			title: 'min base quantity',
			dataIndex: 'orderSize.minQuantity.baseInstrument',
			key: 'minBaseQuantity',
			align: 'right',
			width: 144,
		},
		{
			title: 'edit',
			dataIndex: '',
			key: 'action',
			width: 80,
			render: () => (
				<Icon name="Edit" />
			),
			onCell: (record: Symbol) => ({
				onClick: (e) => {
					e.stopPropagation();
					handleEditFractional(record);
				},
			}),
		},
	];

	return (
		<div>
			{!loading
				? (
					<CustomTable
						columns={columns}
						data={symbols}
						total={symbolsTotal}
						headerHeight={headerHeight}
						place="fractionalSymbols"
						applicationId={applicationId}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
					/>
				)
				: <Spin spinning={loading} />}
		</div>
	);
};

export default FractionalSymbols;
