// @flow

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import types
import type { Webhook } from '../../../modules/reducers/WebhooksReducer';

type Props = {
	webhooks: Array<Webhook>,
	isFetchingWebhooks: boolean,
	active: boolean,
	currentPage: number,
	numberOnPage: number,
	handlePageChange: (number) => void,
	applicationId: string,
	webhooksTotal: number,
}

function WebhooksList(props: Props) {
	const {
		webhooks,
		isFetchingWebhooks,
		active,
		currentPage,
		handlePageChange,
		numberOnPage,
		applicationId,
		webhooksTotal,
	} = props;

	const columns = [
		{
			title: active ? 'created at' : 'deactivated at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string, record: Object) => (
				<div>
					{active ? moment(text).format('YYYY-MM-DD HH:mm:ss') : moment(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		}, {
			title: 'endpoint url',
			dataIndex: 'baseUrl',
			key: 'endpoint',
			width: 544,
			render: (text: string, record) => (
				<Link
					to={`/application/${applicationId}/system/webhooks/${record.id}`}
				>
					{text}
				</Link>
			),
		}, {
			title: 'event',
			dataIndex: ['event', 'name'],
			key: 'event',
			width: 224,
		}];
	return (
		<div>
			{!isFetchingWebhooks
				? (
					<CustomTable
						columns={columns}
						data={webhooks}
						total={webhooksTotal}
						numberOnPage={numberOnPage}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={48}
						place={active ? 'webhooks' : 'archivedWebhooks'}
						applicationId={applicationId}
					/>
				)
				: <Spin spinning={isFetchingWebhooks} />}
		</div>
	);
}

export default WebhooksList;
