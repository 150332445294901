// @flow

import React from 'react';
import type { Element } from 'react';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import styles
import styles from './assets/card.module.scss';

type Props = {
	title?: string,
	parts: Array<{
		key: string,
		value: string | Element<'span'>,
		color: string,
	}>,
}
const NumbersCard = (props: Props) => {
	const {
		title, parts,
	} = props;
	return (
		<div className={styles.card}>
			{title && (
				<div className={styles.numberTitle}>
					<h4>{title}</h4>
				</div>
			)}
			<div className={styles.numbersCard}>
				{parts.length && parts.map((part) => (
					<div className={styles.numberPart} key={part.key}>
						<h1 style={{ color: part.color }}>{part.value}</h1>
						<h6>{ActivityHelpers.transformToReadable(part.key)}</h6>
					</div>
				))}
			</div>
		</div>
	);
};

NumbersCard.defaultProps = {
	title: '',
};

export default NumbersCard;
