// @flow

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { ProviderLog } from '../../../modules/reducers/LogsReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	providerLog: Array<ProviderLog>,
	fetchingProviderLog: boolean,
	providerLogTotal: number,
	currentPage: number,
	handlePageChange: (number) => void,
	filterHeight: number,
	applicationId: string,
}

function ProviderLogs(props: Props) {
	const {
		applicationId,
		providerLog,
		fetchingProviderLog,
		providerLogTotal,
		currentPage,
		handlePageChange,
		filterHeight,
	} = props;

	const columns = [
		{
			title: 'performed at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 224,
			render: (text: string) => (
				<div>
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'provider name',
			dataIndex: 'name',
			key: 'name',
			width: 224,
			render: (text: string) => (
				<div>
					{ActivityHelpers.transformToReadable(text)}
				</div>
			),
		},
		{
			title: 'action',
			dataIndex: 'action',
			key: 'action',
			width: 192,
			render: (text: string) => (
				<div>
					{ActivityHelpers.transformToReadable(text)}
				</div>
			),
		},
		{
			title: 'reference',
			dataIndex: 'reference',
			key: 'reference',
			width: 160,
			render: (text: string) => (
				<div>
					{ActivityHelpers.transformToReadable(text)}
				</div>
			),
		},
	];
	return (
		<div>
			{!fetchingProviderLog
				? (
					<CustomTable
						columns={columns}
						data={providerLog}
						total={providerLogTotal}
						expandRowByClick
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={filterHeight}
						place="providerLogs"
						applicationId={applicationId}
					/>
				) : <Spin spinning={fetchingProviderLog} />}
		</div>
	);
}

const mapStateToProps = (state: State) => ({
	providerLog: state.logs.providerLog,
	providerLogTotal: state.logs.providerLogTotal,
	fetchingProviderLog: state.logs.fetchingProviderLog,
	currentPage: state.ui.providerLogPage,
});

export default connect(mapStateToProps)(ProviderLogs);
