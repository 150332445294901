// @flow

import React from 'react';
import { Link } from 'react-router-dom';
//= import components
import Tag from '../../../components/UiElements/Tag';
import Spin from '../../../components/UiElements/Spin';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import types
import type { CardType } from '../../../modules/reducers/CardsReducer';

type Props = {
	cards: Array<Object>,
	isFetchingCards: boolean,
	applicationId: string,
	place: string,
	total: number,
	page: number,
	pageSize: number,
	handlePageChange: () => void
}

function CardList(props: Props) {
	const {
		cards,
		isFetchingCards,
		applicationId,
		pageSize,
		place,
		total,
		page,
		handlePageChange,
	} = props;

	const nameColumns = [{
		title: 'id',
		dataIndex: 'id',
		key: 'id',
		width: 336,
		render: (text: string, record: CardType) => (
			<CopyComponent
				text={text}
				content={(
					<Link
						to={place === 'appCards'
							? `/application/${applicationId}/cards/${record.id}`
							: `/application/${applicationId}/clients/${record.userPlatformId}/user_cards/${record.id}?accountId=${record.accountPlatformId}`}
					>
						{text}
					</Link>
				)}
			/>
		),
	}, {
		title: 'name',
		dataIndex: 'name',
		key: 'name',
		width: 240,
		render: (text: string, record: CardType) => (
			<div>
				<Link
					to={place === 'appCards'
						? `/application/${applicationId}/cards/${record.id}`
						: `/application/${applicationId}/clients/${record.userPlatformId}/user_cards/${record.id}?accountId=${record.accountPlatformId}`}
				>
					{text}
				</Link>
			</div>
		),
	}];
	const ownerColumns = {
		title: 'owner',
		dataIndex: 'user',
		key: 'owner',
		width: 176,
		render: (text: Object, record: CardType) => (
			<Link
				to={`/application/${applicationId}/clients/${record.userPlatformId}`}
			>
				{`${text ? text.firstName : '-'} ${text ? text.lastName : ''}`}
			</Link>
		),
	};
	const restColumns = [{
		title: 'fund source',
		dataIndex: 'primaryWalletPlatformId',
		key: 'fundSource',
		width: 336,
		render: (text: string, record: CardType) => (
			<CopyComponent
				text={text}
				content={(
					<Link
						to={`/application/${applicationId}/clients/${record.userPlatformId || record.user.platformId}/user_wallets/${text || record.primaryWallet.platformId}?accountId=${record.accountPlatformId}`}
					>
						{text || record.primaryWallet.platformId}
					</Link>
				)}
			/>
		),
	},
	{
		title: 'pan',
		dataIndex: 'primaryCardInstance',
		key: 'pan',
		width: 160,
		render: (text: Object) => (
			<div>
				{text ? text.pan : '-'}
			</div>
		),
	},
	{
		title: 'country iso code',
		dataIndex: 'country',
		key: 'countryIsoCode',
		width: 160,
		render: (text: Object) => (
			<div>
				{text ? text.isoAlpha3Code : '-'}
			</div>
		),
	},
	{
		title: 'currency iso code',
		dataIndex: 'currency',
		key: 'currencyIsoCode',
		width: 160,
		render: (text: Object) => (
			<div>
				{text.isoCode || '-'}
			</div>
		),
	},
	{
		title: 'user platform id',
		dataIndex: 'user',
		key: 'userPlatformId',
		width: 160,
		render: (text: Object) => (
			<div>
				{text.platformId || '-'}
			</div>
		),
	},
	{
		title: 'account platform id',
		dataIndex: 'accountPlatformId',
		key: 'accountPlatformId',
		width: 160,
		render: (text: string) => (
			<div>
				{text || '-'}
			</div>
		),
	},
	{
		title: 'expiration date',
		dataIndex: 'primaryCardInstance',
		key: 'expirationDate',
		width: 160,
		render: (text: Object) => (
			<div>
				{`${text ? text.expiresMonth : '-'}/${text ? text.expiresYear : '-'}`}
			</div>
		),
	},
	{
		title: 'status',
		dataIndex: 'status',
		key: 'status',
		width: 160,
		render: (text: string) => (
			<Tag status={text} />
		),
	}];

	const columns = place === 'appCards' ? [...nameColumns, ownerColumns, ...restColumns] : [...nameColumns, ...restColumns];

	return (
		<>
			{!isFetchingCards
				? (
					<CustomTable
						columns={columns}
						data={cards}
						total={total}
						currentPage={page}
						headerHeight={78}
						numberOnPage={pageSize}
						place="products"
						handlePageChange={handlePageChange}
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingCards} />}
		</>
	);
}

export default CardList;
