// @flow

import React from 'react';
import map from 'lodash/map';
//= import components
import Card from '../../../components/UiElements/Card';
import List from '../../../components/UiElements/List';

type Props = {
	data: Object,
}

const MetaComponent = (props: Props) => {
	const { data } = props;
	const metaData = data ? map(data, (key, value) => ({
		title: value,
		value: key,
	})) : [{
		title: '-',
		value: '-',
	}];
	return (
		<Card className="custom-card" bordered={false}>
			<h2>Meta Data</h2>
			<List
				dataSource={metaData}
				renderItem={
					(item) => (
						<List.Item key={item.title} className="item">
							<List.Item.Meta
								description={item.title || '-'}
							/>
							<div className="left-2">
								{typeof item.value === 'string' && item.value.startsWith('http')
									? <a className="meta-link" href={item.value} target="_blank" rel="noreferrer noopener">{item.value}</a>
									: <p className="meta-text">{item.value && typeof item.value === 'string' ? item.value : '-'}</p>}
							</div>
						</List.Item>
					)
				}
			/>
		</Card>
	);
};

export default MetaComponent;
