// @flow

const initialState = {
	dividendsCalendars: [],
	isFetchingDividendsCalendars: false,
	isFetchedDividendsCalendars: false,
	dividendsCalendar: {},
	isFetchingDividendsCalendar: false,
	isFetchedDividendsCalendar: false,
	payouts: [],
	payoutsTotal: 0,
	isFetchingPayouts: false,
	isFetchedPayouts: false,
	isFetchingOwnedInstrument: false,
	isFetchedOwnedInstrument: false,
	ownedInstrument: {},
};

export type DividendsCalendar = {
	id: string,
	grossAmount: string,
	taxAmount: string,
	announcementDate: string,
	exDividendDate: string,
	recordDate: string,
	payoutDate: string,
	dividendInstrumentId: string,
	payoutInstrumentId: string,
	isExecuted: boolean,
}
export type DividendPayout = {
	id: string,
	quantity: string,
	grossAmount: string,
	taxAmount: string,
	instrumnetId: string,
	walletId: string,
	accountId: string,
	createdAt: string,
	dividend: DividendsCalendar,
}
export type OwnedInstrument = {
	totalShares: string,
	date: string,
	instrumentId: string,
}
export type DividendsState = {
	dividendsCalendars: Array<DividendsCalendar>,
	isFetchingDividendsCalendars: boolean,
	isFetchedDividendsCalendars: boolean,
	dividendsCalendar: DividendsCalendar,
	isFetchingDividendsCalendar: boolean,
	isFetchedDividendsCalendar: boolean,
	payouts: Array<DividendPayout>,
	isFetchingPayouts: boolean,
	isFetchedPayouts: boolean,
	payoutsTotal: number,
	isFetchingOwnedInstrument: boolean,
	isFetchedOwnedInstrument: boolean,
	ownedInstrument: OwnedInstrument,
};

type CalendarsAction = {
	type:
	| 'GET_DIVIDENDS_CALENDARS'
	| 'GET_DIVIDENDS_CALENDARS_FAIL'
	| 'GET_DIVIDENDS_CALENDARS_SUCCESS',
	payload: {
		data: Array<DividendsCalendar>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}

type CalendarAction = {
	type:
	| 'GET_DIVIDENDS_CALENDAR'
	| 'GET_DIVIDENDS_CALENDAR_FAIL'
	| 'GET_DIVIDENDS_CALENDAR_SUCCESS'
	| 'UPDATE_DIVIDENDS_CALENDAR'
	| 'UPDATE_DIVIDENDS_CALENDAR_FAIL'
	| 'UPDATE_DIVIDENDS_CALENDAR_SUCCESS'
	| 'EXECUTE_PAYOUT'
	| 'EXECUTE_PAYOUT_FAIL'
	| 'EXECUTE_PAYOUT_SUCCESS',
	payload: {
		data: DividendsCalendar,
	},
	error: {
		code: number,
		message: string,
	}
}

type PayoutsAction = {
	type:
	| 'GET_DIVIDENDS_PAYOUTS'
	| 'GET_DIVIDENDS_PAYOUTS_FAIL'
	| 'GET_DIVIDENDS_PAYOUTS_SUCCESS',
	payload: {
		data: Array<DividendPayout>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}

type InstrumentAction = {
	type:
	| 'GET_OWNED_INSTRUMENT'
	| 'GET_OWNED_INSTRUMENT_FAIL'
	| 'GET_OWNED_INSTRUMENT_SUCCESS',
	payload: {
		data: OwnedInstrument,
	},
	error: {
		code: number,
		message: string,
	}
}

type Action = CalendarAction | CalendarsAction | InstrumentAction | PayoutsAction;

export default function dividendsReducer(
	state: DividendsState = initialState,
	action: Action,
): DividendsState {
	switch (action.type) {
	case 'GET_DIVIDENDS_CALENDARS':
		return {
			...state,
			isFetchingDividendsCalendars: true,
			isFetchedDividendsCalendars: false,
			error: false,
			dividendsCalendars: [],
		};
	case 'GET_DIVIDENDS_CALENDARS_FAIL':
		return {
			...state,
			isFetchingDividendsCalendars: false,
			isFetchedDividendsCalendars: false,
			error: action.error,
		};
	case 'GET_DIVIDENDS_CALENDARS_SUCCESS':
		return {
			...state,
			isFetchingDividendsCalendars: false,
			isFetchedDividendsCalendars: true,
			error: false,
			dividendsCalendars: action.payload.data,
		};
	case 'GET_DIVIDENDS_CALENDAR':
		return {
			...state,
			isFetchingDividendsCalendar: true,
			isFetchedDividendsCalendar: false,
			error: false,
			dividendsCalendar: {},
		};
	case 'GET_DIVIDENDS_CALENDAR_FAIL':
		return {
			...state,
			isFetchingDividendsCalendar: false,
			isFetchedDividendsCalendar: false,
			error: action.error,
		};
	case 'GET_DIVIDENDS_CALENDAR_SUCCESS':
		return {
			...state,
			isFetchingDividendsCalendar: false,
			isFetchedDividendsCalendar: true,
			error: false,
			dividendsCalendar: action.payload.data,
		};
	case 'GET_DIVIDENDS_PAYOUTS':
		return {
			...state,
			isFetchingPayouts: true,
			isFetchedPayouts: false,
			error: false,
			payouts: [],
		};
	case 'GET_DIVIDENDS_PAYOUTS_FAIL':
		return {
			...state,
			isFetchingPayouts: false,
			isFetchedPayouts: false,
			error: action.error,
		};
	case 'GET_DIVIDENDS_PAYOUTS_SUCCESS':
		return {
			...state,
			isFetchingPayouts: false,
			isFetchedPayouts: true,
			error: false,
			payouts: action.payload.data,
			payoutsTotal: action.payload.headers['total-count'],
		};
	case 'GET_OWNED_INSTRUMENT':
		return {
			...state,
			isFetchingOwnedInstrument: true,
			isFetchedOwnedInstrument: false,
			error: false,
			ownedInstrument: {},
		};
	case 'GET_OWNED_INSTRUMENT_FAIL':
		return {
			...state,
			isFetchingOwnedInstrument: false,
			isFetchedOwnedInstrument: false,
			error: action.error,
		};
	case 'GET_OWNED_INSTRUMENT_SUCCESS':
		return {
			...state,
			isFetchingOwnedInstrument: false,
			isFetchedOwnedInstrument: true,
			error: false,
			ownedInstrument: action.payload.data,
		};
	case 'UPDATE_DIVIDENDS_CALENDAR_SUCCESS':
		return {
			...state,
			isFetchingDividendsCalendar: false,
			isFetchedDividendsCalendar: true,
			error: false,
			dividendsCalendar: action.payload.data,
		};
	case 'EXECUTE_PAYOUT_SUCCESS':
		return {
			...state,
			isFetchingDividendsCalendar: false,
			isFetchedDividendsCalendar: true,
			error: false,
			dividendsCalendar: action.payload.data,
		};
	default:
		return state;
	}
}
