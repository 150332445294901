// @flow

import React from 'react';
import { Button } from 'antd';
// import components
import Icon from '../Icon';
// import styles
import styles from './assets/button.module.scss';

type Props = {
	type?: 'primary' | 'default' | 'danger' | 'link',
	ghost?: boolean,
	text?: string,
	icon?: string,
	action?: ?Function,
	disabled?: boolean,
	href?: ?string,
	target?: ?string,
	loading?: boolean,
}
const CPButton = (props: Props) => {
	const {
		type, text, icon, action, disabled, ghost, href, target, loading, htmlType,
	} = props;
	const iconColor = () => {
		if (disabled) {
			return 'ghost';
		}
		if (ghost) {
			return 'default';
		}
		if (type === 'default') {
			return 'light';
		}
		return type;
	};
	return (
		<Button
			className={ghost ? styles.ghost : styles.all}
			onClick={action}
			type={type}
			disabled={disabled}
			href={href}
			target={target}
			loading={loading}
			htmlType={htmlType}
		>
			<span>
				<div className={styles.content}>
					{!!icon && <Icon colorName={iconColor()} size={14} name={icon} />}
					{text}
				</div>
			</span>
		</Button>
	);
};

CPButton.defaultProps = {
	ghost: false,
	disabled: false,
	icon: '',
	type: 'default',
	action: undefined,
	text: '',
	href: undefined,
	target: undefined,
	loading: false,
};

export default CPButton;
