// @flow

import React from 'react';
import currencies from 'world-currencies';
import { InputNumber as NumberInput } from 'antd';
//= import styles
import styles from './assets/inputNumber.module.scss';

type Props = {
	min?: number,
	max?: number,
    onChange?: Function,
    placeholder?: string,
    symbol?: string,
    disabled?: boolean,
	type?: string,
	value?: number,
}

const InputNumber = React.forwardRef<Props, HTMLDivElement>((props: Props, ref) => {
	const {
		min,
		max,
		onChange,
		placeholder,
		symbol,
		disabled,
		type,
		value,
	} = props;

	const sign = currencies[symbol]
		? currencies[symbol].units.major.symbol
		: symbol;

	return (
		<>
			{type === 'number'
				? (
					<NumberInput
						ref={ref}
						min={min}
						max={max}
						parser={(val: string) => val.replace(/[^0-9]/g, '')}
						onChange={onChange}
						placeholder={placeholder}
						defaultValue={value}
						value={value}
						disabled={disabled}
					/>
				)
				: (
					<div className={styles.container}>
						<div className={styles.symbolInput}>{sign}</div>
						<NumberInput
							ref={ref}
							min={min}
							max={max}
							parser={(val: string) => val.replace(/[^0-9.]/g, '')}
							onChange={onChange}
							placeholder={placeholder}
							disabled={disabled}
							defaultValue={value}
						/>
					</div>
				)}
		</>
	);
});

InputNumber.defaultProps = {
	min: 0,
	max: undefined,
	onChange: undefined,
	placeholder: '',
	symbol: '',
	disabled: false,
	type: '',
	value: undefined,
};
export default InputNumber;
