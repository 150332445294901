// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import Tag from '../../../components/UiElements/Tag';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Wallet } from '../../../modules/reducers/WalletReducer';

type Props = {
	data: Array<Wallet>,
	loading: boolean,
	applicationId: string,
	handlePageChange: Function,
	currentPage: number,
	walletsTotal: number,
	numberOnPage: number,
}

const BasicWalletsTable = (props: Props) => {
	const {
		data,
		loading,
		applicationId,
		walletsTotal,
		handlePageChange,
		currentPage,
		numberOnPage,
	} = props;

	const columns = [
		{
			title: 'date created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>,
		}, {
			title: 'wallet id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string, record: Wallet) => (
				<div>
					{text !== 'sum'
						? (
							<CopyComponent
								text={text}
								content={(
									<Link
										to={`/application/${applicationId}/clients/${record.userId}/user_wallets/${record.id}?accountId=${record.accountId}`}
									>
										{text}
									</Link>
								)}
							/>
						)
						: ''}
				</div>
			),
		}, {
			title: 'wallet',
			dataIndex: 'instrumentId',
			key: 'instrumentId',
			width: 208,
			render: (text: string, record: Wallet) => (
				<div className="flex">
					<span className="primary-tag">
						{text ? `${text} Wallet` : ''}
					</span>
					{record.primary && <Tag status="primary" />}
					{record.delisted && <Tag status="delisted" />}
				</div>
			),
		}, {
			title: 'available balance',
			dataIndex: 'available',
			key: 'available',
			align: 'right',
			width: 240,
			render: (text: string, record: Wallet) => (
				<div className="wrap">
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'reserved balance',
			dataIndex: 'reserved',
			key: 'reserved',
			align: 'right',
			width: 240,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'total balance',
			dataIndex: 'total',
			key: 'total',
			align: 'right',
			width: 240,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'debt',
			dataIndex: 'debt',
			key: 'debt',
			align: 'right',
			width: 192,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'updated at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>,
		},
	];
	return (
		<>
			{!loading
				?						(

					<CustomTable
						columns={columns}
						data={data}
						total={walletsTotal}
						currentPage={currentPage}
						place="basicUserWallets"
						applicationId={applicationId}
						handlePageChange={handlePageChange}
						numberOnPage={numberOnPage}
					/>
				)
				: (
					<Spin spinning={loading} position="spin-relative" />
				)}
		</>

	);
};

const mapStateToProps = (state: State, props) => ({
	walletsTotal: state.wallet.walletsTotal,
});

export default connect(mapStateToProps)(BasicWalletsTable);

