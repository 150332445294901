// @flow

import React, { useState } from 'react';
//= import components
import Dropdown from '../../../components/UiElements/Dropdown';
import Icon from '../../../components/UiElements/Icon';
import Menu from '../../../components/UiElements/Menu';
import CPButton from '../../../components/UiElements/Button';
import Tag from '../../../components/UiElements/Tag';
//= import type
import type { Account } from '../../../modules/reducers/AccountsReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	accounts: Array<Account>,
	action: Function,
	activeAccountId: string,
	openModal: (string) => void,
	accountsTotal: number,
}

function AccountTabContent(props: Props) {
	const [down, setDown] = useState(true);
	const {
		accounts, action, activeAccountId, openModal, accountsTotal,
	} = props;

	return (
		<Dropdown
			overlay={(
				<div className={styles.accountList}>
					<Menu defaultSelectedKeys={[activeAccountId]}>
						{accounts.slice(0, 5).map((account) => (
							<Menu.Item
								key={account.id}
								onClick={() => { action(account.id); }}
							>
								<div className="flex-container-start ">
									<div>{account.name}</div>
									{account.primary && <Tag status="primary" />}
								</div>
							</Menu.Item>
						))}
					</Menu>
					{accountsTotal > 5 && (
						<CPButton type="primary" action={() => openModal('account')} text="view all" />
					)}
				</div>
			)}
			trigger={['click']}
			onVisibleChange={() => setDown(!down)}
			overlayClassName={styles.overlay}
		>
			<div className={styles.dropdownContent}>
				<span>{`ACCOUNTS ( ${accountsTotal || ''} )`}</span>
				<Icon name={down ? 'CaretDown' : 'CaretUp'} style={{ marginLeft: 8 }} />
			</div>
		</Dropdown>
	);
}
export default AccountTabContent;
