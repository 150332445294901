// @flow
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
//= import components
import DatePicker from '../../../components/UiElements/DatePicker';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import Spin from '../../../components/UiElements/Spin';
import InputNumber from '../../../components/UiElements/InputNumber';
//= import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type InstrumentsResponse = {
	payload: {
		data: Array<Instrument>,
	}
}

type Props = {
	form: *,
	getAppInstruments: (string, string, number, string) => Promise<InstrumentsResponse>,
	submitChanges: (value: Object) => void,
	handleCancelClick: () => void,
	edit: boolean,
	applicationId: string,
}

type LocalState = {
	instrumentData: Array<Instrument>,
	instrumentFetching: boolean,
	errorMessage: string,
	loading: boolean,
	announcementDate: string,
	exDate: string,
	recordDate: string,
	grossAmount: number,
	symbol: string,
	notFoundContent: string,
	fiatInstruments: Array<Instrument>,
}

class Calendar extends React.Component<Props, LocalState> {
	state = {
		instrumentData: [],
		instrumentFetching: false,
		errorMessage: '',
		loading: false,
		announcementDate: '',
		exDate: '',
		recordDate: '',
		grossAmount: 0,
		symbol: '',
		notFoundContent: '',
		fiatInstruments: [],
	}

	async componentDidMount() {
		const { getAppInstruments: getAppInstrumentsAction, applicationId } = this.props;
		const filterOptions: string = queryString.stringify({ type: 'fiat' });
		const res = await getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
		this.setState({
			fiatInstruments: res.payload.data,
		});
	}

	handleInstrumentSearch = debounce((instrumentValue: string) => {
		const { getAppInstruments: getAppInstrumentsAction, applicationId } = this.props;
		if (instrumentValue && instrumentValue.match(StatusHelpers.validInstrument)) {
			this.setState({
				instrumentFetching: true,
			});
			const idsFilter = instrumentValue?.toUpperCase();
			const filterOptions: string = queryString.stringify({ ids: idsFilter });
			getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							instrumentData: data,
							instrumentFetching: false,
						});
					} else {
						this.setState({
							instrumentData: [],
							instrumentFetching: false,
							notFoundContent: `Instrument with id ${instrumentValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						instrumentFetching: false,
						instrumentData: [],
						notFoundContent: 'Instrument not found',
					});
				});
		} else {
			this.setState({
				instrumentFetching: false,
				instrumentData: [],
				notFoundContent: 'Enter valid Id to find the Instrument',
			});
		}
	}, 500);

	setAnnouncementDate = (value: string) => {
		this.setState({
			announcementDate: value,
			exDate: '',
			recordDate: '',
		});
		this.props.form.setFieldsValue({ recordDate: '', exDividendDate: '' });
	}

	setExDate = (value: string) => {
		this.setState({
			exDate: value,
			recordDate: '',
		});
		this.props.form.setFieldsValue({ recordDate: '', payoutDate: '' });
	}

	setRecordDate = (value: string) => {
		this.setState({
			recordDate: value,
		});
	}

	setInstrument = (value) => {
		const { fiatInstruments } = this.state;
		if (value) {
			const instrument = fiatInstruments.find((el) => el.instrumentId === value);
			this.setState({
				symbol: instrument?.formatting?.grapheme || value,
			});
		} else {
			this.setState({
				instrumentData: [],
				notFoundContent: 'Enter Id to find the Instrument',
			});
		}
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					...values,
					grossAmount: values.grossAmount.toString(),
					taxAmount: values.taxAmount.toString(),
				};
				submitChanges(data);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			grossAmount: 0,
			symbol: '',
		});
	}

	setGrossAmount = (value) => {
		this.setState({
			grossAmount: value,
		});
	}

	validatePrice = (rule, value, callback) => {
		if (!value) {
			callback('Gross Amount must be greater than 0');
		} else if (parseFloat(value.toFixed(2)) !== value) {
			callback('Price and Tax must have precision less than or equal to 2');
		}
		callback();
	}

	validateTax = (rule, value, callback) => {
		const { grossAmount } = this.state;
		if (value !== 0 && !value) {
			callback('Please set Tax Amount!');
		} else {
			if (value > grossAmount) {
				callback('Tax Amount must be greater than 0 and smaller than Gross Amount');
			}
			if (parseFloat(value.toFixed(2)) !== value) {
				callback('Price and Tax must have precision less than or equal to 2');
			}
		}
		callback();
	}

	render() {
		const {
			handleCancelClick,
			form: {
				getFieldDecorator, isFieldTouched,
			},
		} = this.props;

		const {
			errorMessage,
			instrumentData,
			loading,
			announcementDate,
			exDate,
			recordDate,
			instrumentFetching,
			notFoundContent,
			symbol,
			fiatInstruments,
		} = this.state;
		const FormItem = Form.Item;
		const { Option } = Select;
		const fixedLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{!loading ? (
					<FormItem label="DIVIDEND INSTRUMENT" {...fixedLayout}>
						{getFieldDecorator(
							'dividendInstrumentId', {
								rules: [
									{
										required: true,
										message: 'Please select Dividend Instrument Id!',
									},
								],
							},
						)(
							<Select
								allowClear
								showSearch
								className={styles.firstSelect}
								placeholder="Search Instrument by InstrumentId"
								notFoundContent={instrumentFetching ? <Spin size={16} /> : notFoundContent}
								filterOption={false}
								onSearch={this.handleInstrumentSearch}
								onChange={this.setInstrument}
							>
								{instrumentData.map((instrument) => (
									<Option
										value={instrument.instrumentId}
										key={instrument.instrumentId}
										className={styles.options}
									>
										{instrument.instrumentId}
									</Option>
								))}
							</Select>,
						)}
					</FormItem>
				)
					: <Spin spinning={loading} />}
				<FormItem label="PAYOUT INSTRUMENT" {...fixedLayout}>
					{getFieldDecorator(
						'payoutInstrumentId', {
							rules: [
								{
									required: true,
									message: 'Please select Payout Instrument Id!',
								},
							],
						},
					)(
						<Select
							allowClear
							showSearch
							className={styles.firstSelect}
							placeholder="Select the Payout Instrument"
							onChange={this.setInstrument}
						>
							{fiatInstruments.map((instrument) => (
								<Option value={instrument.instrumentId} key={instrument.id} className={styles.options}>
									{instrument.name}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="PRICE PER SHARE" {...fixedLayout} className={styles.number}>
					{getFieldDecorator(
						'grossAmount', {
							rules: [{ validator: this.validatePrice }],
						},
					)(
						<InputNumber
							symbol={symbol}
							placeholder="Set Price per Share"
							onChange={this.setGrossAmount}
						/>,
					)}
				</FormItem>
				<FormItem label="TAX AMOUNT" {...fixedLayout} className={styles.number}>
					{getFieldDecorator(
						'taxAmount', {
							rules: [{ validator: this.validateTax }],
						},
					)(
						<InputNumber
							placeholder="Set Tax Amount"
							symbol={symbol}
						/>,
					)}
				</FormItem>
				<FormItem label="ANNOUNCEMENT DATE" {...fixedLayout}>
					{getFieldDecorator('announcementDate', {
						rules: [
							{
								required: true,
								message: 'Please select Announcement Date!',
							},
						],
					})(
						<DatePicker
							className={styles.date}
							dropdownClassName={styles.picker}
							onChange={this.setAnnouncementDate}
						/>,
					)}
				</FormItem>
				<FormItem label="EX-DATE" {...fixedLayout}>
					{getFieldDecorator('exDividendDate', {
						rules: [
							{
								required: true,
								message: 'Please select EX-Date!',
							},
						],
					})(
						<DatePicker
							className={styles.date}
							dropdownClassName={styles.picker}
							disabledDate={(current) => current < moment(announcementDate).add(1, 'day')}
							onChange={this.setExDate}
						/>,
					)}
				</FormItem>
				<FormItem label="RECORD DATE" {...fixedLayout}>
					{getFieldDecorator('recordDate', {
						rules: [
							{
								required: true,
								message: 'Please select Record Date!',
							},
						],
					})(
						<DatePicker
							className={styles.date}
							dropdownClassName={styles.picker}
							disabledDate={(current) => (current < moment(exDate).add(1, 'day')) || (current > moment(exDate).add(2, 'day'))}
							onChange={this.setRecordDate}
						/>,
					)}
				</FormItem>
				<FormItem label="PAYOUT DATE" {...fixedLayout}>
					{getFieldDecorator('payoutDate', {
						rules: [
							{
								required: true,
								message: 'Please select Payout Date!',
							},
						],
					})(
						<DatePicker
							className={styles.date}
							dropdownClassName={styles.picker}
							disabledDate={(current) => current < moment(recordDate).add(1, 'day')}
						/>,
					)}
				</FormItem>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldTouched('grossAmount')} text="schedule dividend payout" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const CalendarForm = Form.create()(Calendar);

const mapDispatchToProps = {
	getAppInstruments,
};
export default connect(null, mapDispatchToProps)(CalendarForm);
