// @flow
import React, { useState, useEffect, useCallback } from 'react';
import QRCode from 'qrcode';
import { connect } from 'react-redux';
import Button from '../../../../Button';
import { confirm2FA } from '../../../../../../modules/actions/OauthActions';
import FormModal from '../../../../Modal/FormModal';
import OTPInput from './OTPInput';

type Props = {
    secret: string,
    closeModal: () => void,
    confirm2FA: (string) => Promise<Object>,
}

const QR = ({secret, closeModal, confirm2FA: confirm2FAAction}: Props) => {
    const [otpAuth, setOtpAuth] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [hasErrored, setHasErrored] = useState(false)
    const [token, setToken] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [visibleModal, setVisibleModal] = useState(false)

    const collectInput = useCallback((input) => {
        setHasErrored(false);
        setErrorMessage('');
        setToken(input);
    }, []);

    const closeSuccess = useCallback(() => {
        closeModal();
        setVisibleModal(false);
    }, [closeModal]);

    const verifyToken = useCallback(async () => {
        setIsDisabled(true);
        try {
            await confirm2FAAction(token);
            setVisibleModal(true);
        }
        catch(error) {
                const TOKEN_FORMAT_ERROR = error.error.response.data.errors?.[0].messages?.[0].message;
                const INVALID_TOKEN_ERROR = error.error.response.data.message;
                if (TOKEN_FORMAT_ERROR) setErrorMessage(TOKEN_FORMAT_ERROR);
                if (INVALID_TOKEN_ERROR) setErrorMessage(INVALID_TOKEN_ERROR);
                setHasErrored(true);
                setToken('');
                setIsDisabled(false);
        }
    }, [token, confirm2FAAction]);
    
    const OTP_URL =`otpauth://totp/TradeCore:Customer%20Panel?secret=${secret}&issuer=TradeCore`;
    
    useEffect(() => {
        QRCode.toDataURL(OTP_URL, function(err, data_url) {
            setOtpAuth(data_url);
          })
    }, [secret, OTP_URL]);
    
    return (
        <>
            <div className='qr-container'>
                <div className='qr-instructions'>
                    <p>Scan the QR code with your authenticator app</p>
                    <p>Then enter the generated token into the input below</p>
                    <OTPInput
                        isDisabled={isDisabled}
                        hasErrored={hasErrored}
                        errorMessage={errorMessage}
                        collectInput={collectInput}
                        token={token}
                    />
                </div>
                <img src={otpAuth} alt='qr-code' className='qr-code' />
            </div>
            <div className='qr-code-buttons'>
                <Button
                    type='primary'
                    text='Turn on 2FA'
                    action={verifyToken}
                    disabled={isDisabled}
                />
                <Button
                    type='default'
                    text='Cancel'
                    action={closeModal}
                />
                <FormModal
                    title='Two-factor authentication successfully turned on!'
                    visible={visibleModal}
                    form={
                        <Button
                            type='primary'
                            text='Dismiss'
                            action={closeSuccess}
                        />
                    }
                    cancelFunction={closeSuccess}
                />
            </div>
        </>
    )
}

const mapDispatchToProps = {
    confirm2FA,
}

export default connect(null, mapDispatchToProps)(QR);