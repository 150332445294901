// @flow
import * as React from 'react';
import { connect } from 'react-redux';
//= import components
import WrappedLoginForm from './components/LoginForm';
//= import actions
import { login } from '../../modules/actions/OauthActions';
//= import types
import type { State } from '../../modules/types/FlowTypes';
import TwoFactorInputModal from './components/TwoFactorInputModal';

type Values = {
	username: string,
	password: string,
	appName: string,
}

type OTPState = {
	credentials: {
		username: string,
		password: string,
		appName: string,
	},
	visible: boolean,
}
type Props = {
	login: (string, string, string) => Promise<void>,
	isAuthenticated: boolean,
	loginLoading: boolean,
	history: {
		push: ({
			pathname: string,
		}) => void,
	},
}

class SignIn extends React.Component<Props, OTPState> {
	state: OTPState = {
		credentials: {
			username: '',
			password: '',
			appName: '',
		},
		visible: false,
	}

	componentDidMount() {
		const { isAuthenticated, history: { push } } = this.props;
		if (isAuthenticated === true) {
			push({ pathname: '/' });
		}
	}

	backAction = () => {
		this.setState((prevState) => ({ ...prevState, visible: false }));
	}

	//= handle form submition
	handleSubmit = (values: Values) => {
		// eslint-disable-next-line
		const { login, history: { push } } = this.props;
		return login(values.username.trim(), values.password, values.appName.trim())
			.then((response) => Promise.resolve(response))
			.catch((error) => {
				const is2FA = error.error.response.data.message.includes('2FA');
				if (!is2FA) return Promise.reject(error);
				return this.setState({
					credentials: {
						username: values.username,
						password: values.password,
						appName: values.appName,
					},
					visible: true,
				});
			});
	}

	render() {
		const { loginLoading } = this.props;
		return (
			<div className="auth">
				<WrappedLoginForm
					handleSubmit={this.handleSubmit}
					loading={loginLoading}
				/>
				<TwoFactorInputModal
					credentials={this.state.credentials}
					visible={this.state.visible}
					backAction={this.backAction}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: State) => ({
	isAuthenticated: state.oauth.isAuthenticated,
	loginLoading: state.oauth.loginLoading,
	error: state.oauth.error,
});

const mapDispatchToProps = {
	login,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
