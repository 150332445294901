// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import AccountsList from '../components/AccountsList';
//= import actions
import { getClientBankAccounts } from '../../../modules/actions/BankActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { BankAccountType } from '../../../modules/reducers/BankReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Props = {
	getClientBankAccounts: (string, number, string) => void,
	clientAccounts: Array<BankAccountType>,
	pageSize: number,
	isFetchingClientAccounts: boolean,
	clientAccountsTotal: number,
	currentPage: number,
	clientAccountsFilters: { [string]: string },
	setCurrentPage: (string, number) => void,
	getProvidersInstances: (string) => void,
	providers: Array<ProviderInstance>,
	match: {
		params: {
			applicationId: string,
		}
	}
}

function ClientBankAccounts(props: Props) {
	const {
		getClientBankAccounts: getClientBankAccountsActions,
		clientAccounts,
		isFetchingClientAccounts,
		clientAccountsTotal,
		currentPage,
		clientAccountsFilters,
		setCurrentPage: setCurrentPageAction,
		getProvidersInstances: getProvidersInstancesAction,
		providers,
		pageSize,
		match: { params: { applicationId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');

	const filterRef = useRef(null);

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		async function getData() {
			try {
				await getClientBankAccountsActions(applicationId, currentPage, queryString.stringify(clientAccountsFilters, { arrayFormat: 'comma' }), pageSize);
				setErrorMessage('');
			} catch (err) {
				const errMessage: string = err.error?.response?.data?.message;
				if (errMessage && errMessage.startsWith('You do not have permission')) {
					setErrorMessage("This feature is not available for your application. If you're interested in it, please contact your TradeCore representative.");
				}
			}
		}
		getData();
	}, [applicationId, getClientBankAccountsActions, currentPage, clientAccountsFilters, pageSize]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [clientAccountsFilters]);

	const handleClientAccountsPageChange = (page: number) => {
		setCurrentPageAction('clientAccountsPage', page);
	};

	const availableProviders = providers.filter((el) => el.provider.name === 'modulr').map((el) => ({
		id: el.provider.name,
		name: el.provider.name,
		displayName: el.provider.displayName,
	}));

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="client bank accounts"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="clientAccounts"
					fields={{
						instrumentId: StatusHelpers.bankInstruments,
						provider: availableProviders,
						dateRange: 'time',
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
					filterMode="single"
				/>
			</div>
			<div className="container">
				{errorMessage
					? (
						<div className="empty-state">
							<UserNoChecks />
							<p>{errorMessage}</p>
						</div>
					)
					: (
						<AccountsList
							applicationId={applicationId}
							accounts={clientAccounts}
							accountsTotal={clientAccountsTotal}
							currentPage={currentPage}
							pageSize={pageSize}
							headerHeight={filterHeight}
							handlePageChange={handleClientAccountsPageChange}
							place="clientAccounts"
							loading={isFetchingClientAccounts}
						/>
					)}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.clientAccountsPage,
	clientAccountsFilters: state.ui.clientAccountsFilters,
	clientAccounts: state.bank.clientAccounts,
	isFetchingClientAccounts: state.bank.isFetchingClientAccounts,
	clientAccountsTotal: state.bank.clientAccountsTotal,
	providers: state.providers.providersInstances,
	pageSize: state.ui.clientAccountsPageSize
});

const mapDispatchToProps = {
	getClientBankAccounts,
	setCurrentPage,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientBankAccounts);
