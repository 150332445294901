// @flow

const initialState = {
	sections: [],
	isFetchingSections: false,
	isFetchedSections: false,
	error: false,
	section: {},
	isFetchingSection: false,
	isFetchedSection: false,
	addingSection: false,
	sectionAdded: false,
	removingSection: false,
	removedSection: false,
	updatingSection: false,
	updatedSection: false,
};

export type Section = {
	id: string,
	name: string,
	nameSlug: string,
	description: string,
	display: string,
	icon: string,
	coverPhoto: string,
	order: number,
	applicationId: string,
	createdAt: string,
	updatedAt: string,
	deletedAt: string,
	exploreGroups: Array<string>,
}
export type Sections = Array<Section>
export type SectionState = {
	sections: Sections,
	isFetchingSections: boolean,
	isFetchedSections: boolean,
	section: Section,
	isFetchingSection: boolean,
	isFetchedSection: boolean,
	error: boolean,
	addingSection: boolean,
	sectionAdded: boolean,
	removingSection: boolean,
	removedSection: boolean,
	updatingSection: boolean,
	updatedSection: boolean,
}
type SectionAction = {
	type:
	| 'GET_SECTION'
	| 'GET_SECTION_FAIL'
	| 'GET_SECTION_SUCCESS'
	| 'ADD_SECTION'
	| 'ADD_SECTION_FAIL'
	| 'ADD_SECTION_SUCCESS'
	| 'UPDATE_SECTION'
	| 'UPDATE_SECTION_FAIL'
	| 'UPDATE_SECTION_SUCCESS'
	| 'ADD_GROUP_TO_SECTION'
	| 'ADD_GROUP_TO_SECTION_FAIL'
	| 'ADD_GROUP_TO_SECTION_SUCCESS'
	| 'REMOVE_GROUP_FROM_SECTION'
	| 'REMOVE_GROUP_FROM_SECTION_FAIL'
	| 'REMOVE_GROUP_FROM_SECTION_SUCCESS',
	payload: {
		data: Section,
	},
	error: Object,
}
type SectionsAction = {
	type:
	| 'GET_SECTIONS'
	| 'GET_SECTIONS_FAIL'
	| 'GET_SECTIONS_SUCCESS',
	payload: {
		data: Array<Section>,
		headers: {
			'total-count': number,
		}
	},
	error: Object,
}
type Action = SectionAction | SectionsAction;

export default function sectionReducer(
	state: SectionState = initialState,
	action: Action,
): SectionState {
	switch (action.type) {
	case 'GET_SECTIONS': {
		return {
			...state,
			isFetchingSections: true,
			isFetchedSections: false,
		};
	}
	case 'GET_SECTIONS_FAIL': {
		return {
			...state,
			error: false,
			isFetchingSections: false,
			isFetchedSections: false,
		};
	}
	case 'GET_SECTIONS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingSections: false,
			isFetchedSections: true,
			sections: action.payload.data,
		};
	}
	case 'GET_SECTION': {
		return {
			...state,
			isFetchingSection: true,
			isFetchedSection: false,
		};
	}
	case 'GET_SECTION_FAIL': {
		return {
			...state,
			error: false,
			isFetchingSection: false,
			isFetchedSection: false,
		};
	}
	case 'GET_SECTION_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingSection: false,
			isFetchedSection: true,
			section: action.payload.data,
		};
	}
	case 'ADD_SECTION': {
		return {
			...state,
			addingSection: true,
			sectionAdded: false,
		};
	}
	case 'ADD_SECTION_FAIL': {
		return {
			...state,
			error: true,
			addingSection: false,
			sectionAdded: false,
		};
	}
	case 'ADD_SECTION_SUCCESS': {
		return {
			...state,
			error: false,
			addingSection: false,
			sectionAdded: true,
			sections: [...state.sections, action.payload.data],
		};
	}
	case 'UPDATE_SECTION': {
		return {
			...state,
			updatingSection: true,
			updatedSection: false,
		};
	}
	case 'UPDATE_SECTION_FAIL': {
		return {
			...state,
			error: true,
			updatingSection: false,
			updatedSection: false,
		};
	}
	case 'UPDATE_SECTION_SUCCESS': {
		return {
			...state,
			error: false,
			updatingSection: false,
			updatedSection: true,
			section: action.payload.data,
			sections: state.sections.map((section) => {
				if (section.id === action.payload.data.id) {
					return action.payload.data;
				}
				return section;
			}),
		};
	}
	case 'ADD_GROUP_TO_SECTION': {
		return {
			...state,
			updatingSection: true,
			updatedSection: false,
		};
	}
	case 'ADD_GROUP_TO_SECTION_FAIL': {
		return {
			...state,
			error: true,
			updatingSection: false,
			updatedSection: false,
		};
	}
	case 'ADD_GROUP_TO_SECTION_SUCCESS': {
		return {
			...state,
			error: false,
			updatingSection: false,
			updatedSection: true,
			section: action.payload.data,
		};
	}
	case 'REMOVE_GROUP_FROM_SECTION': {
		return {
			...state,
			updatingSection: true,
			updatedSection: false,
		};
	}
	case 'REMOVE_GROUP_FROM_SECTION_FAIL': {
		return {
			...state,
			error: true,
			updatingSection: false,
			updatedSection: false,
		};
	}
	case 'REMOVE_GROUP_FROM_SECTION_SUCCESS': {
		return {
			...state,
			error: false,
			updatingSection: false,
			updatedSection: true,
			section: action.payload.data,
		};
	}
	default:
		return state;
	}
}
