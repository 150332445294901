// @flow

import * as React from 'react';
import { Resizable } from 'react-resizable';

type Props = {
	onResize: Function,
	width: number,
}

const ResizeableTitle = (props: Props) => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

export default ResizeableTitle;
