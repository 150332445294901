// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import InputNumber from '../../../components/UiElements/InputNumber';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	appSettings: Object,
}

type State = {
	errorMessage: string,
	accrualSelected: string,
	compoundRequired: boolean,
}

class SettingsForm extends React.Component<Props, State> {
	state = {
		errorMessage: '',
		accrualSelected: this.props.appSettings.accrualPeriod,
		compoundRequired: false,
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	selectAccrual = (value: string) => {
		const { getFieldValue, setFieldsValue } = this.props.form;
		const compound: string = getFieldValue('compoundPeriod');
		if (
			StatusHelpers.accrualPeriods.indexOf(value) > StatusHelpers.accrualPeriods.indexOf(compound)
		) {
			this.setState({
				accrualSelected: value,
				compoundRequired: true,
			});
			setFieldsValue({ compoundPeriod: '' });
		}
		this.setState({
			accrualSelected: value,
		});
	}

	validatePrecision = (rule, value, callback) => {
		if (parseFloat(value.toFixed(2)) !== value) {
			callback('The Interest Rate must have precision less than or equal to 2');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator }, handleCancelClick, appSettings,
		} = this.props;
		const {
			errorMessage,
			accrualSelected,
			compoundRequired,
		} = this.state;
		const FormItem = Form.Item;
		const { Option } = Select;
		const formItemLayout = {
			labelCol: {
				xs: { span: 13 },
			},
			wrapperCol: {
				xs: { span: 11 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<FormItem label="ANNUAL INTEREST RATE" {...formItemLayout} help="">
					{getFieldDecorator('annualInterestRate', {
						initialValue: appSettings.annualInterestRate,
						rules: [
							{ validator: (rule, value, cb) => (value > 100 ? cb('Annual Interest Rate has to be between 0 and 100 (%)!') : cb()) },
							{ validator: this.validatePrecision },
						],
					})(
						<InputNumber
							symbol="%"
							placeholder="Set Annual Interest Rate"
						/>,
					)}
				</FormItem>
				<FormItem label="ACCRUAL PERIOD" {...formItemLayout} help="">
					{getFieldDecorator('accrualPeriod', {
						initialValue: appSettings.accrualPeriod,
					})(
						<Select
							showSearch
							placeholder="Select Accrual Period"
							onChange={this.selectAccrual}
						>
							{StatusHelpers.accrualPeriods.map((el) => (
								<Option
									value={el}
									key={el}
									className="capitalize"
								>
									{el}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="COMPOUND PERIOD" {...formItemLayout} help="">
					{getFieldDecorator('compoundPeriod', {
						initialValue: appSettings.compoundPeriod,
						rules: [{
							required: compoundRequired,
							message: 'Compound Period is required (Compound period can not be lesser than the Accrual period)!',
						}],
					})(
						<Select
							showSearch
							placeholder="Select Compound Period"
						>
							{StatusHelpers.compundPriods[accrualSelected
							|| appSettings.accrualPeriod].map((el) => (
								<Option
									value={el}
									key={el}
									className="capitalize"
								>
									{el}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!this.props.form.isFieldsTouched()} text="edit interest rate" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EditSettingsForm = Form.create()(SettingsForm);

export default EditSettingsForm;
