// @flow

//= OAUTH
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const STORE_USER = 'STORE_USER';
export const OAUTH_SUCCESS = 'OAUTH_SUCCESS';
export const OAUTH_FAILED = 'OAUTH_FAILED';
export const OAUTH_ATTEMPT = 'OAUTH_ATTEMPT';
export const INITIALIZED = 'INITIALIZED';
export const LOGOUT = 'LOGOUT';
export const TURN_ON_2FA = 'TURN_ON_2FA';
export const TURN_OFF_2FA = 'TURN_OFF_2FA';
export const LOG_IN_WITH_2FA = 'LOG_IN_WITH_2FA';
export const CHECK_2FA = 'CHECK_2FA';
export const CONFIRM_2FA = 'CONFIRM_2FA';
//= USERS
export const UPDATE_USER = 'UPDATE_USER';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILED = 'USERS_FAILED';
export const USERS_ATTEMPT = 'USERS_ATTEMPT';
export const SEARCH_USERS = 'SEARCH_USERS';
export const USER_CURRENCY = 'USER_CURRENCY';
export const TRIGGER_CHECK = 'TRIGGER_CHECK';
export const UPLOAD_KYC_DOCUMENT = 'UPLOAD_KYC_DOCUMENT';
export const CREATE_APPLICANT = 'CREATE_APPLICANT';
export const SUSPEND_USER = 'SUSPEND_USER';
export const UNSUSPEND_USER = 'UNSUSPEND_USER';
export const GET_SUSPEND_REASONS = 'GET_SUSPEND_REASONS';
export const ADD_SUSPEND_REASON = 'ADD_SUSPEND_REASON';
export const GET_SUSPEND_REASON = 'GET_SUSPEND_REASON';
export const UPDATE_SUSPEND_REASON = 'UPDATE_SUSPEND_REASON';
export const DELETE_SUSPEND_REASON = 'DELETE_SUSPEND_REASON';
export const GET_SUSPENSIONS = 'GET_SUSPENSIONS';
//= HISTORY
export const HISTORY_ATTEMPT = 'HISTORY_ATTEMPT';
export const HISTORY_SUCCESS = 'HISTORY_SUCCESS';
export const HISTORY_FAILED = 'HISTORY_FAILED';
//= ACCOUNTS
export const ACCOUNTS = 'ACCOUNTS';
export const ACCOUNT = 'ACCOUNT';
export const ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT';
export const GET_ACCOUNT_STATISTICS = 'GET_ACCOUNT_STATISTICS';
export const GET_ACCOUNT_POSITIONS = 'GET_ACCOUNT_POSITIONS';
//= APPLICATIONS
export const APPLICATIONS = 'APPLICATIONS';
export const APPLICATIONS_SETTINGS = 'APPLICATIONS_SETTINGS';
export const UPDATE_APPLICATION_SETTINGS = 'UPDATE_APPLICATION_SETTINGS';
export const APPLICATION = 'APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const GET_APPLICATIONS_COUNTRIES = 'GET_APPLICATIONS_COUNTRIES';
export const ADD_APPLICATIONS_COUNTRIES = 'ADD_APPLICATIONS_COUNTRIES';
export const DELETE_APPLICATIONS_COUNTRIES = 'DELETE_APPLICATIONS_COUNTRIES';
//= DOCUMENTS
export const DOCUMENTS = 'DOCUMENTS';
//= LEDGER
export const LEDGER = 'LEDGER';
//= ORDER
export const ORDERS = 'ORDERS';
export const ORDER = 'ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const APPLICATION_ORDERS = 'APPLICATION_ORDERS';
export const RECURRING_ORDERS = 'RECURRING_ORDERS';
export const RECURRING_ORDER = 'RECURRING_ORDER';
export const PLACE_ORDER = 'PLACE_ORDER';
export const GET_QUOTE_FOR_ORDER = 'GET_QUOTE_FOR_ORDER';
//= PAYMENT
export const PAYMENTS = 'PAYMENTS';
export const PAYMENT = 'PAYMENT';
export const APP_PAYMENTS = 'APP_PAYMENTS';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const APPROVE_WITHDRAWAL_REQUEST = 'APPROVE_WITHDRAWAL_REQUEST';
export const REJECT_WITHDRAWAL_REQUEST = 'REJECT_WITHDRAWAL_REQUEST';
export const WALLET_PAYMENTS = 'WALLET_PAYMENTS';
export const RECURRING_PAYMENT = 'RECURRING_PAYMENT';
export const RECURRING_PAYMENTS = 'RECURRING_PAYMENTS';
export const CRYPTO_PAYOUT = 'CRYPTO_PAYOUT';
//= WALLETS
export const WALLETS = 'WALLETS';
export const CREDIT_WALLET = 'CREDIT_WALLET';
export const DEBIT_WALLET = 'DEBIT_WALLET';
export const GET_WALLET_TRANSACTIONS = 'GET_WALLET_TRANSACTIONS';
export const GET_WALLET = 'GET_WALLET';
export const CREATE_WALLET = 'CREATE_WALLET';
export const EXPORT_WALLET_TRANSACTIONS = 'EXPORT_WALLET_TRANSACTIONS';
export const TRANSFER_TO_WALLET = 'TRANSFER_TO_WALLET';
export const EXECUTE_CONVERSION = 'EXECUTE_CONVERSION';
export const GET_CONVERSIONS = 'GET_CONVERSIONS';
export const GET_CONVERSION_QUOTE = 'GET_CONVERSION_QUOTE';
export const EXPORT_CONVERSIONS = 'EXPORT_CONVERSIONS';
//= STATIC
export const STATIC_COUNTRIES = 'STATIC_COUNTRIES';
export const STATIC_INSTRUMENTS = 'STATIC_INSTRUMENTS';
export const RATES = 'RATES';
//= UI
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const CURRENT_PAGE_SIZE = 'CURRENT_PAGE_SIZE';
export const CURRENT_FILTERS = 'CURRENT_FILTERS';
export const CURRENT_SORTER = 'CURRENT_SORTER';
export const ACTIVE_TAB = 'ACTIVE_TAB';
export const MENU_COLLAPSED = 'MENU_COLLAPSED';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CURRENT_SEARCH = 'CURRENT_SEARCH';
// STATUS
export const UPDATE_STATUS = 'UPDATE_STATUS';
// USERS
export const USERS = 'USERS';
export const FULL_USER = 'FULL_USER';
export const USERS_SETTINGS = 'USERS_SETTINGS';
export const UPDATE_USERS_SETTINGS = 'UPDATE_USERS_SETTINGS';
// LOGS
export const ACTIVITY_LOG = 'ACTIVITY_LOG';
export const PROVIDER_LOGS = 'PROVIDER_LOGS';
// PROVIDERS
export const PROVIDERS = 'PROVIDERS';
// KYC
export const KYC_REPORTS = 'KYC_REPORTS';
export const ONFIDO_SDK_TOKEN = 'ONFIDO_SDK_TOKEN';
// SYMBOLS
export const APPLICATION_SYMBOLS = 'APPLICATION_SYMBOLS';
export const SYMBOL = 'SYMBOL';
export const UPDATE_SYMBOL = 'UPDATE_SYMBOL';
export const GROUP_SYMBOLS = 'GROUP_SYMBOLS';
export const GROUP_SYMBOL = 'GROUP_SYMBOL';
export const ADD_GROUP_SYMBOLS = 'ADD_GROUP_SYMBOLS';
export const UPDATE_GROUP_SYMBOL = 'UPDATE_GROUP_SYMBOL';
export const INSTRUMENT_SYMBOLS = 'INSTRUMENT_SYMBOLS';
export const ALL_APPLICATION_SYMBOLS = 'ALL_APPLICATION_SYMBOLS';
// INSTRUMENT
export const APPLICATIONS_INSTRUMENTS = 'APPLICATION_INSTRUMENTS';
export const INSTRUMENT = 'INSTRUMENT';
export const ADD_PAYMENT_METHODS = 'ADD_PAYMENT_METHODS';
export const REMOVE_PAYMENT_METHODS = 'REMOVE_PAYMENT_METHODS';
export const UPDATE_INSTRUMENT = 'UPDATE_INSTRUMENT';
export const DELIST_INSTRUMENT = 'DELIST_INSTRUMENT';
// GROUP
export const GROUP = 'GROUP';
export const GROUPS = 'GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const MOVE_USER_TO_GROUP = 'MOVE_USER_TO_GROUP';
export const GROUP_USERS = 'GROUP_USERS';
export const SEARCH_GROUP_USERS = 'SEARCH_GROUP_USERS';
// FEES
export const GET_FEES = 'GET_FEES';
export const ADD_FEE = 'ADD_FEE';
export const UPDATE_FEE = 'UPDATE_FEE';
export const REMOVE_FEE = 'REMOVE_FEE';
export const GET_FEE_QUOTE = 'GET_FEE_QUOTE';
// SECTIONS
export const GET_SECTIONS = 'GET_SECTIONS';
export const GET_SECTION = 'GET_SECTION';
export const ADD_SECTION = 'ADD_SECTION';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const ADD_GROUP_TO_SECTION = 'ADD_GROUP_TO_SECTION';
export const REMOVE_GROUP_FROM_SECTION = 'REMOVE_GROUP_FROM_SECTION';
// FILES
export const GET_FILE = 'GET_FILE';
// EXPLORE GROUPS
export const GET_EXPLORE_GROUPS = 'GET_EXPLORE_GROUPS';
export const GET_EXPLORE_GROUP = 'GET_EXPLORE_GROUP';
export const ADD_EXPLORE_GROUP = 'ADD_EXPLORE_GROUP';
export const UPDATE_EXPLORE_GROUP = 'UPDATE_EXPLORE_GROUP';
export const REMOVE_EXPLORE_GROUP = 'REMOVE_EXPLORE_GROUP';
export const ADD_SYMBOL_TO_EXPLORE = 'ADD_SYMBOL_TO_EXPLORE';
export const REMOVE_SYMBOL_FROM_EXPLORE = 'REMOVE_SYMBOL_FROM_EXPLORE';
// WEBHOOKS
export const GET_WEBHOOKS = 'GET_WEBHOOKS';
export const DELETE_WEBHOOKS = 'DELETE_WEBHOOKS';
export const GET_ACTIVE_WEBHOOKS = 'GET_ACTIVE_WEBHOOKS';
export const GET_INACTIVE_WEBHOOKS = 'GET_INACTIVE_WEBHOOKS';
export const ADD_WEBHOOK = 'ADD_WEBHOOK';
export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK';
export const GET_WEBHOOK_JOB = 'GET_WEBHOOK_JOB';
export const GET_WEBHOOK_JOBS = 'GET_WEBHOOK_JOBS';
export const GET_EVENTS = 'GET_EVENTS';
// FEATURES
export const GET_APP_FEATURES = 'GET_APP_FEATURES';
export const UPDATE_APP_FEATURES = 'UPDATE_APP_FEATURES';
// MARGIN
export const UPDATE_USERS_MARGIN_INTEREST = 'UPDATE_USERS_MARGIN_INTEREST';
export const ACCOUNT_MARGIN = 'ACCOUNT_MARGIN';
export const RISKIEST_BORROWERS = 'RISKIEST_BORROWERS';
export const ACCOUNT_MARGIN_INTEREST = 'ACCOUNT_MARGIN_INTEREST';
export const ACCOUNT_MARGIN_CHARGES = 'ACCOUNT_MARGIN_CHARGES';
// INVENTORY
export const GET_INVENTORY_WALLETS = 'GET_INVENTORY_WALLETS';
export const GET_INVENTORY_WALLET = 'GET_INVENTORY_WALLET';
export const GET_INVENTORY_TRANSACTIONS = 'GET_INVENTORY_TRANSACTIONS';
export const EXPORT_INVENTORY_TRANSACTIONS = 'EXPORT_INVENTORY_TRANSACTIONS';
// PROVIDERS
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDERS_INSTANCES = 'GET_PROVIDERS_INSTANCES';
export const GET_PROVIDER_INSTANCE = 'GET_PROVIDER_INSTANCE';
export const ADD_PROVIDER_INSTANCE = 'ADD_PROVIDER_INSTANCE';
export const UPDATE_PROVIDER_INSTANCE = 'UPDATE_PROVIDER_INSTANCE';
// DIVIDENDS
export const GET_DIVIDENDS_CALENDARS = 'GET_DIVIDENDS_CALENDARS';
export const GET_DIVIDENDS_CALENDAR = 'GET_DIVIDENDS_CALENDAR';
export const ADD_DIVIDENDS_CALENDAR = 'ADD_DIVIDENDS_CALENDAR';
export const UPDATE_DIVIDENDS_CALENDAR = 'UPDATE_DIVIDENDS_CALENDAR';
export const DELETE_DIVIDENDS_CALENDAR = 'DELETE_DIVIDENDS_CALENDAR';
export const GET_DIVIDENDS_PAYOUTS = 'GET_DIVIDENDS_PAYOUTS';
export const EXECUTE_PAYOUT = 'EXECUTE_PAYOUT';
export const GET_OWNED_INSTRUMENT = 'GET_OWNED_INSTRUMENT';
// STATEMENTS
export const GET_STATEMENTS = 'GET_STATEMENTS';
export const GET_STATEMENT = 'GET_STATEMENT';
export const UPDATE_STATEMENT = 'UPDATE_STATEMENT';
export const UPDATE_STATEMENTS = 'UPDATE_STATEMENTS';
// SUBSCRIPTION
export const GET_EVENT_SUBSCRIPTIONS = 'GET_EVENT_SUBSCRIPTIONS';
export const ADD_EVENT_SUBSCRIPTIONS = 'ADD_EVENT_SUBSCRIPTIONS';
export const GET_EVENT_SUBSCRIPTION = 'GET_EVENT_SUBSCRIPTION';
export const REMOVE_EVENT_SUBSCRIPTIONS = 'REMOVE_EVENT_SUBSCRIPTIONS';
export const GET_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS = 'GET_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS';
export const ADD_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS = 'ADD_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS';
export const REMOVE_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS = 'REMOVE_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS';
export const GET_EVENT_SUBSCRIPTIONS_EVENTS = 'GET_EVENT_SUBSCRIPTIONS_EVENTS';
export const ADD_EVENT_SUBSCRIPTIONS_EVENTS = 'ADD_EVENT_SUBSCRIPTIONS_EVENTS';
export const REMOVE_EVENT_SUBSCRIPTIONS_EVENTS = 'REMOVE_EVENT_SUBSCRIPTIONS_EVENTS';
export const GET_EVENT_HANDLERS = 'GET_EVENT_HANDLERS';
// CARDS
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_DESIGN = 'GET_DESIGN';
export const GET_AUTHORIZATION_CONTROL = 'GET_AUTHORIZATION_CONTROL';
export const GET_LIMIT_CONTROL = 'GET_LIMIT_CONTROL';
export const GET_MERCHANT_SCOPE = 'GET_MERCHANT_SCOPE';
export const ADD_MERCHANT_SCOPE = 'ADD_MERCHANT_SCOPE';
export const ADD_AUTHORIZATION_CONTROL = 'ADD_AUTHORIZATION_CONTROL';
export const ADD_LIMIT_CONTROL = 'ADD_LIMIT_CONTROL';
export const UPDATE_MERCHANT_SCOPE = 'UPDATE_MERCHANT_SCOPE';
export const UPDATE_AUTHORIZATION_CONTROL = 'UPDATE_AUTHORIZATION_CONTROL';
export const UPDATE_LIMIT_CONTROL = 'UPDATE_LIMIT_CONTROL';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_CARDS = 'GET_CARDS';
export const GET_CARD = 'GET_CARD';
export const ADD_CARD = 'ADD_CARD';
export const GET_ACCOUNT_CARDS = 'GET_ACCOUNT_CARDS';
export const GET_ACCOUNT_CARD_PAYMENTS = 'GET_ACCOUNT_CARD_PAYMENTS';
export const GET_ACCOUNT_CARD_PAYMENT = 'GET_ACCOUNT_CARD_PAYMENT';
export const GET_CARD_PAYMENTS = 'GET_CARD_PAYMENTS';
export const GET_CARD_PAYMENT = 'GET_CARD_PAYMENT';
export const GET_ENTRY_MODE = 'GET_ENTRY_MODE';
export const GET_TRANSACTION_TYPES = 'GET_TRANSACTION_TYPES';
export const GET_MERCHANT_CATEGORY = 'GET_MERCHANT_CATEGORY';
export const ACTIVATE_CARD = 'ACTIVATE_CARD';
export const CHANGE_CARD_STATUS = 'CHANGE_CARD_STATUS';
export const ISSUE_CARD = 'ISSUE_CARD';
export const SIMULATE_CARD_PAYMENT = 'SIMULATE_CARD_PAYMENT';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CARD_SPEND_CONTROLS = 'GET_CARD_SPEND_CONTROLS';
// BANK
export const GET_CUSTOMER_BANK_ACCOUNTS = 'GET_CUSTOMER_BANK_ACCOUNTS';
export const GET_CUSTOMER_BANK_ACCOUNT = 'GET_CUSTOMER_BANK_ACCOUNT';
export const ASSOCIATE_CUSTOMER_BANK_ACCOUNT = 'ASSOCIATE_CUSTOMER_BANK_ACCOUNT';
export const EDIT_CUSTOMER_BANK_ACCOUNT = 'EDIT_CUSTOMER_BANK_ACCOUNT';
export const GET_CLIENT_BANK_ACCOUNTS = 'GET_CLIENT_BANK_ACCOUNTS';
export const GET_CLIENT_BANK_ACCOUNT = 'GET_CLIENT_BANK_ACCOUNT';
export const CREATE_CLIENT_BANK_ACCOUNT = 'CREATE_CLIENT_BANK_ACCOUNT';
export const GET_USER_BANK_ACCOUNTS = 'GET_USER_BANK_ACCOUNTS';
export const GET_ACCOUNT_BANK_ACCOUNTS = 'GET_ACCOUNT_BANK_ACCOUNTS';
export const GET_BANK_ACCOUNT_PAYMENTS = 'GET_BANK_ACCOUNT_PAYMENTS';
export const GET_APPLICATION_BANK_PAYMENTS = 'GET_APPLICATION_BANK_PAYMENTS';
export const CUSTOMER_PAYOUT = 'CUSTOMER_PAYOUT';
export const SIMULATE_CUSTOMER_PAYMENT = 'SIMULATE_CUSTOMER_PAYMENT';
export const GET_BANK_PAYMENT = 'GET_BANK_PAYMENT';
export const GET_SCHEMES = 'GET_SCHEMES';
export const GET_PAYOUT_FIELDS = 'GET_PAYOUT_FIELDS';
// KYT
export const KYT_REPORTS = 'KYT_REPORTS';
export const SCHEDULE_KYT_CHECKS = 'SCHEDULE_KYT_CHECKS';
export const UPDATE_COMPLIANCE_STATUS = 'UPDATE_COMPLIANCE_STATUS';
// STOCK SPLIT
export const GET_STOCK_SPLITS = 'GET_STOCK_SPLITS';
export const GET_STOCK_SPLIT = 'GET_STOCK_SPLIT';
export const ADD_STOCK_SPLIT = 'ADD_STOCK_SPLIT';
export const UPDATE_STOCK_SPLIT = 'UPDATE_STOCK_SPLIT';
export const DELETE_STOCK_SPLIT = 'DELETE_STOCK_SPLIT';
export const EXECUTE_STOCK_SPLIT = 'EXECUTE_STOCK_SPLIT';
export const GET_ACCOUNT_STOCK_SPLITS = 'GET_ACCOUNT_STOCK_SPLITS';
export const GET_ACCOUNT_STOCK_SPLIT = 'GET_ACCOUNT_STOCK_SPLIT';
// API KEYS
export const GET_API_KEYS = 'GET_API_KEYS';
export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const REVOKE_API_KEY = 'REVOKE_API_KEY';
// USER TAGS
export const CREATE_USER_TAG = 'CREATE_USER_TAG';
export const EDIT_USER_TAG = 'EDIT_USER_TAG';
export const DELETE_USER_TAG = 'DELETE_USER_TAG';
export const FETCH_USER_TAGS = 'FETCH_USER_TAGS';
export const ADD_TAG_TO_USER = 'ADD_TAG_TO_USER';
export const GET_USER_TAGS = 'GET_USER_TAGS';
export const REMOVE_USER_TAG = 'REMOVE_USER_TAG';
