// @flow

import React from 'react';
import type { Node } from 'react';
import Modal from './index';
import CPButton from '../Button';
import Icon from '../Icon';

type FooterProps = {
	type: string,
	action: Function,
	text: string,
}
type Props = {
	cancelFunction: Function,
	visible: boolean,
	title: string,
	message: { [string]: Node },
	footer: Array<FooterProps>,
	success?: boolean,
	loading?: boolean,
}
const WarningModal = (props: Props) => {
	const {
		cancelFunction, visible, footer, title, message, success, loading,
	} = props;
	return (
		<Modal
			title={(
				<div className="flex">
					{success
						? <Icon name="Approve" colorName="info" style={{ marginRight: '8px' }} />
						: <Icon name="Warning" colorName="warning" style={{ marginRight: '8px' }} />}
					<h4>{title}</h4>
				</div>
			)}
			visible={visible}
			onCancel={cancelFunction}
			maskClosable={false}
			closable={false}
			centered
			wrapClassName="warning-modal"
			footer={footer.map((button) => (
				<CPButton
					type={button.type === 'continue' ? 'primary' : 'default'}
					ghost={button.type !== 'continue'}
					key={button.type}
					action={button.action}
					text={button.text}
					loading={button.type === 'continue' && loading}
				/>
			))}
		>
			<div>
				<div className="warning-text">
					{message.firstPart}
				</div>
				<div className="warning-text">
					{message.secondPart}
				</div>
			</div>
		</Modal>
	);
};

WarningModal.defaultProps = {
	success: false,
	loading: false,
};

export default WarningModal;
