// @flow
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import differenceBy from 'lodash/differenceBy';
//= import components
import DatePicker from '../../../components/UiElements/DatePicker';
import Spin from '../../../components/UiElements/Spin';
import CPButton from '../../../components/UiElements/Button';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Checkbox from '../../../components/UiElements/Checkbox';
import Input from '../../../components/UiElements/Input';
import Select from '../../../components/UiElements/Select';
import Card from '../../../components/UiElements/Card';
import Radio from '../../../components/UiElements/Radio';
import Drawer from '../../../components/UiElements/Drawer';
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import ControlComponent from './ControlComponent';
import LimitControls from './LimitControls';
import LimitControlForm from './LimitControlForm';
//= import actions
import {
	getCardDesign,
	getEntryMode,
	getTransactionTypes,
	getMerchantCategoryCodes,
	getProgram,
	getProduct,
	updateProduct,
	addMerchantScope,
	updateMerchantScope,
	addAuthorizationControl,
	updateAuthorizationControl,
	addLimitControl,
	updateLimitControl,
} from '../../../modules/actions/CardAction';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type {
	CardDesign,
	EntryModes,
	TransactionTypes,
	MerchantCategoryCodes,
	LimitControlType,
	Program,
	Product,
} from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';
import StatusHelpers from '../../../lib/helpers/statusHelpers';

type Props = {
	form: *,
	getCardDesign: (string, string) => Promise<Object>,
	getEntryMode: () => Promise<Object>,
	getTransactionTypes: () => Promise<Object>,
	getMerchantCategoryCodes: () => Promise<Object>,
	getProgram: (string, string) => Promise<Object>,
	addLimitControl: (string, Object) => Promise<Object>,
	updateLimitControl: (string, string, Object) => Promise<Object>,
	addAuthorizationControl: (string, Object) => Promise<Object>,
	updateAuthorizationControl: (string, string, Object) => Promise<Object>,
	addMerchantScope: (string, Object) => Promise<Object>,
	updateMerchantScope: (string, string, Object) => Promise<Object>,
	getProduct: (string, string) => Promise<Object>,
	updateProduct: (string, string, Object) => Promise<Object>,
	match: {
		params: {
			applicationId: string,
			productId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
	cardDesign: Array<CardDesign>,
	allEntryModes: Array<EntryModes>,
	allTransactionTypes: Array<TransactionTypes>,
	allMerchantCategoryCodes: Array<MerchantCategoryCodes>,
}
type DetailsData = {
	name: string,
	description: string,
	maxCardsPerUser: string,
	physicalCardsEnabled: boolean,
	virtualCardsEnabled: boolean,
	externalWalletsEnabled: boolean,
	cardEmbossingStrategy: string,
	multiCurrencyEnabled: boolean,
	autoConversionEnabled: boolean,
	threeDomainSecureEnabled: boolean,
	currencyCodes: Array<string>,
	countryCodes: Array<string>,
	cardDesignIds: Array<string>,
	isActive: boolean,
	cardCreationEnabled: boolean,
	startDate: Object,
	endDate: Object,
	deliveryMethodIds: Array<string>,
};
type MerchantFormData = {
	name: string,
	merchantCategoryStrategy: string,
	merchantStrategy: string,
	isActive: boolean,
	merchantIds: string,
};
type AuthorizationFormData = {
	name: string,
	isActive: boolean,
	transactionTypeStrategy: string,
	entryModeStrategy: string,
};
type FooterProps = {
	type: string,
	action: Function,
	text: string,
}
type LocalState = {
	loading: boolean,
	program: Program,
	product: Product,
	unsetLimit: Array<{
		level: string,
		limitControl: LimitControlType
	}>,
	drawerOpen: boolean,
	warningModal: boolean,
	warningTitle: string,
	message: Object,
	footer: Array<FooterProps>,
	add: boolean,
	loading: boolean,
	entryModes: Array<EntryModes>,
	transactionTypes: Array<TransactionTypes>,
	merchantCategoryCodes: Array<MerchantCategoryCodes>,
	limitToUpdate: ?LimitControlType,
	drawerData: Object,
	buttonLoading: boolean,
}
type ErrorMessageType = Array<{
	field: string,
	messages: Array<Object>,
}>
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: ErrorMessageType,
			}
		}
	}
}

const getAvailable = (strategy: string, programData: Array<Object>, allData: Array<Object>) => {
	if (strategy === 'AllowAll') {
		return allData;
	}
	if (strategy === 'BlockTransactionTypes' || strategy === 'BlockEntryModes') {
		return differenceBy(allData, programData, 'id');
	}
	if (strategy === 'BlockCategories') {
		return differenceBy(allData, programData, 'code');
	}
	return programData;
};

const printError = (error: ErrorMessageType) => {
	if (!error) {
		return '';
	}
	const text = error.length
		? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
		: [];
	return text.map((el: string) => (<div key={el}>{el}</div>));
};

class UpdateProductFormTest extends React.Component<Props, LocalState> {
	state = {
		loading: false,
		program: {},
		product: {},
		unsetLimit: [],
		drawerOpen: false,
		warningModal: false,
		warningTitle: 'Warning!',
		message: {},
		footer: [],
		add: false,
		entryModes: [],
		transactionTypes: [],
		merchantCategoryCodes: [],
		limitToUpdate: null,
		drawerData: {},
		buttonLoading: false,
	}

	formRef: ?LimitControlForm;

	async componentDidMount() {
		const {
			getCardDesign: getCardDesignAction,
			getProgram: getProgramAction,
			getProduct: getProductAction,
			getEntryMode: getEntryModeAction,
			getTransactionTypes: getTransactionTypesAction,
			getMerchantCategoryCodes: getMerchantCategoryCodesAction,
			match: {
				params: {
					productId, applicationId,
				},
			},
		} = this.props;
		this.setState({
			loading: true,
		});
		const productRes = await getProductAction(applicationId, productId);
		const programRes = await getProgramAction(
			applicationId,
			productRes.payload.data.programInstanceId,
		);

		getCardDesignAction(applicationId, productRes.payload.data.programInstanceId);
		const entryModesRes = await getEntryModeAction();
		const transactionTypesRes = await getTransactionTypesAction();
		const merchantCategoryCodesRes = await getMerchantCategoryCodesAction();

		this.setState({
			loading: false,
			program: programRes.payload.data,
			product: productRes.payload.data,
			unsetLimit: productRes.payload.data.limitControls,
			entryModes: getAvailable(
				programRes.payload.data.authorizationControl.entryModeStrategy,
				programRes.payload.data.authorizationControl.entryModes,
				entryModesRes.payload.data,
			),
			transactionTypes: getAvailable(
				programRes.payload.data.authorizationControl.transactionTypeStrategy,
				programRes.payload.data.authorizationControl.transactionTypes,
				transactionTypesRes.payload.data,
			),
			merchantCategoryCodes: getAvailable(
				programRes.payload.data.authorizationControl.merchantScope.merchantCategoryStrategy,
				programRes.payload.data.authorizationControl.merchantScope.merchantCategoryCodes,
				merchantCategoryCodesRes.payload.data,
			),
		});
	}

	backToDefault = () => {
		const {
			match: {
				params: {
					productId, applicationId,
				},
			},
		} = this.props;
		this.props.history.push(`/application/${applicationId}/system/card/products/${productId}`);
	};

	// limit drawer

	handleOpenDrawer = (limitControl: LimitControlType) => {
		const data = {
			name: 'Limit Control',
			details: [
				{ key: 'Amount Limit', value: limitControl.amountLimit },
				{ key: 'Currency', value: limitControl.currency.name },
				{ key: 'Usage Limit', value: limitControl.usageLimit },
				{ key: 'Velocity Window', value: limitControl.velocityWindow },
			],
			control: limitControl,
		};
		this.setState({
			drawerOpen: true,
			drawerData: data,
		});
	};

	onClose = () => {
		this.setState({
			drawerOpen: false,
		});
	};

	// add and update limit control

	addLimit = () => {
		this.setState({
			add: true,
			limitToUpdate: null,
		});
	};

	updateLimit = (limit: LimitControlType) => {
		this.setState({
			add: true,
			limitToUpdate: limit,
		});
	}

	handleWarningCancel = () => {
		this.setState({
			warningModal: false,
			add: true,
		});
	};

	handleErrorCancel = () => {
		if (this.formRef) {
			this.formRef.handleReset();
		}
		this.setState({
			warningModal: false,
		});
	};

	handleOnError = (error: ErrorType) => {
		this.setState({
			loading: false,
			buttonLoading: false,
			warningModal: true,
			warningTitle: 'Error',
			message: {
				firstPart: error.error && error.error.response.data
					? error.error.response.data.message
					: 'Error',
				secondPart: error.error && error.error.response.data
					? printError(error.error.response.data.errors)
					: '',
			},
			footer: [
				{ type: 'cancel', action: this.handleErrorCancel, text: 'close' },
			],
		});
	};

	handleAddLimitCancel = () => {
		if (this.formRef && this.formRef.props.form.isFieldsTouched()) {
			this.setState({
				add: false,
				loading: false,
				warningModal: true,
				warningTitle: 'Warning!',
				message: { firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' },
				footer: [
					{ type: 'cancel', action: this.handleErrorCancel, text: 'cancel' },
					{ type: 'continue', action: this.handleWarningCancel, text: 'continue' },
				],
			});
		} else {
			if (this.formRef) {
				this.formRef.handleReset();
			}
			this.setState({
				add: false,
				warningModal: false,
			});
		}
	};

	submitAddLimit = async (limitData, merchantData) => {
		const {
			match: {
				params: {
					applicationId,
				},
			},
			addMerchantScope: addMerchantScopeAction,
			addLimitControl: addLimitControlAction,
			updateMerchantScope: updateMerchantScopeAction,
			updateLimitControl: updateLimitControlAction,
		} = this.props;
		const { limitToUpdate } = this.state;
		try {
			if (limitToUpdate) {
				let merchantRes;
				if (merchantData) {
					merchantRes = limitToUpdate.merchantScope
						? await updateMerchantScopeAction(
							applicationId,
							limitToUpdate.merchantScope.id,
							merchantData,
						)
						: await addMerchantScopeAction(
							applicationId,
							merchantData,
						);
				}
				const data = {
					...limitData,
					merchantScopeId: merchantRes ? merchantRes.payload.data.id : null,
				};
				const limitRes = await updateLimitControlAction(applicationId, limitToUpdate.id, data);

				this.setState((prevState) => {
					const resData = prevState.unsetLimit.map((el) => {
						if (el.limitControl.id === limitRes.payload.data.id) {
							return { limitControl: limitRes.payload.data, level: 'Card' };
						}
						return el;
					});
					return ({
						unsetLimit: resData,
						warningModal: false,
					});
				});
			} else {
				const merchantRes = merchantData
					? await addMerchantScopeAction(applicationId, merchantData)
					: null;
				const data = {
					...limitData,
					merchantScopeId: merchantRes ? merchantRes.payload.data.id : null,
				};
				const limitRes = await addLimitControlAction(applicationId, data);

				this.setState((prevState) => {
					const resData = [...prevState.unsetLimit, { limitControl: limitRes.payload.data, level: 'Card' }];
					return ({
						unsetLimit: resData,
						warningModal: false,
					});
				});
			}
		} catch (error) { this.handleOnError(error); }
	};

	continueAddingLimit = (limitData, merchantData) => {
		this.setState({
			warningModal: true,
			add: false,
			warningTitle: 'Are you sure?',
			message: {
				firstPart: `Once you confirm these changes,
					they will affect this Product.`,
				secondPart: 'It is advised to proceed editing with caution.',
			},
			footer: [
				{ type: 'cancel', action: this.handleWarningCancel, text: 'go back' },
				{ type: 'continue', action: () => this.submitAddLimit(limitData, merchantData), text: 'save' },
			],
		});
	};

	saveChanges = async (
		merchantFormData: ?MerchantFormData,
		authorizationFormData: ?AuthorizationFormData,
		detailsData: DetailsData,
	) => {
		const {
			product,
			unsetLimit,
		} = this.state;
		const {
			updateAuthorizationControl: updateAuthorizationControlAction,
			updateMerchantScope: updateMerchantScopeAction,
			addMerchantScope: addMerchantScopeAction,
			updateProduct: updateProductAction,
			match: {
				params: {
					applicationId,
				},
			},
		} = this.props;
		this.setState({
			buttonLoading: true,
		});
		try {
			let merchantRes;
			if (merchantFormData) {
				merchantRes = product.authorizationControl.merchantScope
					? await updateMerchantScopeAction(
						applicationId,
						product.authorizationControl.merchantScope.id,
						merchantFormData,
					)
					: await addMerchantScopeAction(applicationId, merchantFormData);
			}
			const authorizationData = authorizationFormData || merchantFormData
				? {
					...authorizationFormData,
					isActive: true,
					merchantScopeId: merchantRes
						? merchantRes.payload.data.id
						: null,
				}
				: null;
			if (authorizationData) {
				await updateAuthorizationControlAction(
					applicationId,
					product.authorizationControl.id,
					authorizationData,
				);
			}
			const data = {
				...detailsData,
				cardValidityPeriodInMonths: product.cardValidityPeriodInMonths,
				defaultCurrencyCode: product.defaultCurrency.isoCode,
				authorizationControlId: product.authorizationControl.id,
				limitControls: unsetLimit.length
					? unsetLimit.map((el) => ({
						level: 'Card',
						limitControlId: el.limitControl.id,
					}))
					: null,
				startDate: detailsData.startDate.format('YYYY-MM-DD 00:00:00'),
				endDate: detailsData.endDate.format('YYYY-MM-DD 00:00:00'),
			};
			const productRes = await updateProductAction(applicationId, product.id, data);
			this.setState({
				buttonLoading: false,
				warningModal: false,
				loading: false,
			});
			this.props.history.push(`/application/${applicationId}/system/card/products/${productRes.payload.data.id}`);
		} catch (error) {
			this.handleOnError(error);
		}
	};

	saveClick = () => {
		const { form } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (!err) {
				const merchantFormData = form.isFieldsTouched(['merchantScopeName', 'merchantCategoryStrategy', 'merchantCategoryCodes', 'merchantStrategy', 'merchantIds'])
					? {
						name: values.merchantScopeName,
						merchantCategoryStrategy: values.merchantCategoryStrategy,
						merchantCategoryCodes: values.merchantCategoryCodes,
						merchantStrategy: values.merchantStrategy,
						merchantIds: form.isFieldTouched('merchantIds') ? values.merchantIds.split(',') : values.merchantIds,
						isActive: true,
					}
					: null;
				const authorizationFormData = form.isFieldsTouched([
					'authorizationControlName',
					'transactionTypeStrategy',
					'TransactionTypeIds',
					'entryModeStrategy',
					'EntryModeIds',
					'merchantScopeName',
					'merchantCategoryStrategy',
					'merchantCategoryCodes',
					'merchantStrategy',
					'merchantIds',
				])
					? {
						name: values.authorizationControlName,
						isActive: true,
						transactionTypeStrategy: values.transactionTypeStrategy,
						transactionTypeIds: values.TransactionTypeIds,
						entryModeStrategy: values.entryModeStrategy,
						EntryModeIds: values.EntryModeIds,
					}
					: null;
				const detailsData = {
					name: values.name,
					description: values.description,
					maxCardsPerUser: values.maxCardsPerUser,
					physicalCardsEnabled: values.physicalCardsEnabled,
					virtualCardsEnabled: values.virtualCardsEnabled,
					externalWalletsEnabled: values.externalWalletsEnabled,
					cardEmbossingStrategy: values.cardEmbossingStrategy,
					multiCurrencyEnabled: values.multiCurrencyEnabled,
					autoConversionEnabled: values.autoConversionEnabled,
					threeDomainSecureEnabled: values.threeDomainSecureEnabled,
					currencyCodes: values.currencyCodes,
					countryCodes: values.countryCodes,
					cardDesignIds: values.cardDesignIds,
					isActive: values.isActive,
					cardCreationEnabled: values.cardCreationEnabled,
					startDate: values.startDate,
					endDate: values.endDate,
					programInstanceId: values.programInstanceId,
					deliveryMethodIds: values.deliveryMethods,
				};

				this.setState({
					warningModal: true,
					add: false,
					warningTitle: 'Are you sure?',
					message: {
						firstPart: `Once you confirm these changes,
							they will affect this Product.`,
						secondPart: 'It is advised to proceed editing with caution.',
					},
					footer: [
						{ type: 'cancel', action: this.handleErrorCancel, text: 'go back' },
						{ type: 'continue', action: () => this.saveChanges(merchantFormData, authorizationFormData, detailsData), text: 'save changes' },
					],
				});
			}
		});
	};

	render() {
		const {
			match: { params: { applicationId } },
			cardDesign,
			allEntryModes,
			allTransactionTypes,
			allMerchantCategoryCodes,
			form: {
				getFieldDecorator,
				getFieldValue,
			},
		} = this.props;
		const {
			loading,
			program,
			product,
			unsetLimit,
			warningModal,
			warningTitle,
			message,
			footer,
			add,
			drawerData,
			drawerOpen,
			entryModes,
			transactionTypes,
			merchantCategoryCodes,
			limitToUpdate,
			buttonLoading,
		} = this.state;

		const cardAttributes: Array<{name: string, value: boolean, initialValue: boolean}> = [
			{ name: 'physicalCardsEnabled', value: program.physicalCardsEnabled, initialValue: product.physicalCardsEnabled },
			{ name: 'virtualCardsEnabled', value: program.virtualCardsEnabled, initialValue: product.virtualCardsEnabled },
			{ name: 'externalWalletsEnabled', value: program.externalWalletsEnabled, initialValue: product.externalWalletsEnabled },
			{ name: 'autoConversionEnabled', value: program.autoConversionEnabled, initialValue: product.autoConversionEnabled },
			{ name: 'multiCurrencyEnabled', value: program.multiCurrencyEnabled, initialValue: product.multiCurrencyEnabled },
			{ name: 'threeDomainSecureEnabled', value: program.threeDomainSecureEnabled, initialValue: product.threeDomainSecureEnabled },
		];
		const firstPart = [
			{ name: 'isActive', value: true, initialValue: product.isActive },
			{ name: 'cardCreationEnabled', value: true, initialValue: product.cardCreationEnabled },
		];
		const dateData = [
			{ name: 'startDate', initialValue: moment(product.startDate) },
			{ name: 'endDate', initialValue: moment(product.endDate) },
		];
		const restFields: Array<{
			name: string,
			values: Array<Object>,
			mode: string, initialValue:
			Array<Object> | string,
		}> = product.id ? [
			{
				name: 'cardEmbossingStrategy',
				values: [{ id: 'EmbossedOnly' }, { id: 'UnembossedOnly' }, { id: 'AllowAny' }],
				mode: 'single',
				initialValue: product.cardEmbossingStrategy,
			},
			{
				name: 'currencyCodes',
				values: program.currencies,
				mode: 'multiple',
				initialValue: product.currencies.map((el) => el.isoCode),
			},
			{
				name: 'countryCodes',
				values: program.countries,
				mode: 'multiple',
				initialValue: product.countries.map((el) => el.isoAlpha3Code),
			},
			{
				name: 'deliveryMethods',
				values: program.deliveryMethods,
				mode: 'multiple',
				initialValue: product.deliveryMethods.map((el) => el.id),
			},
		] : [];
		const controlData: Array<Object> = product.authorizationControl ? [
			{
				strategy: 'transactionTypeStrategy',
				strategyValues: StatusHelpers.transactionStrategy,
				checked: product.authorizationControl.transactionTypeStrategy,
				name: 'TransactionTypeIds',
				options: transactionTypes,
				initialValue: product.authorizationControl.transactionTypes.map((el) => el.id),
			},
			{
				strategy: 'entryModeStrategy',
				strategyValues: StatusHelpers.entryModeStrategy,
				checked: product.authorizationControl.entryModeStrategy,
				name: 'EntryModeIds',
				options: entryModes,
				initialValue: product.authorizationControl.entryModes.map((el) => el.id),
			},
		] : [];

		const FormItem = Form.Item;
		const formLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 14 },
			},
		};

		return (
			<div className="page-container">
				{!loading && product.id
					? (
						<Form
							onSubmit={this.saveClick}
							layout="horizontal"
							hideRequiredMark
							labelAlign="left"
						>
							<Row gutter={24}>
								<Col span={12}>
									<Card bordered={false} className={styles.productCard}>
										<FormItem label="program" {...formLayout}>
											{getFieldDecorator('programInstanceId', {
												initialValue: product.programInstanceId,
												rules: [{
													required: true, message: 'Please Select Program!',
												}],
											})(
												<Select
													showSearch
												>
													<Select.Option
														value={program.id}
														key={program.id}
														className="capitalize"
													>
														{program.name}
													</Select.Option>
												</Select>,
											)}
										</FormItem>
										{firstPart.map((el) => {
											if (el.value) {
												return (
													<FormItem {...formLayout} key={el.name}>
														{getFieldDecorator(el.name, {
															initialValue: el.initialValue,
															valuePropName: 'checked',
														})(
															<Checkbox>
																{ActivityHelpers.transformToReadable(el.name)}
															</Checkbox>,
														)}
													</FormItem>
												);
											}
											return null;
										})}
										{dateData.map((el) => (
											<FormItem
												label={ActivityHelpers.transformToReadable(el.name)}
												{...formLayout}
												key={el.name}
											>
												{getFieldDecorator(el.name, {
													initialValue: el.initialValue,
													rules: [{
														required: true, message: `Please Select ${el.name}!`,
													}],
												})(
													<DatePicker
														dropdownClassName={styles.picker}
														format="YYYY-MM-DD"
													/>,
												)}
											</FormItem>
										))}
									</Card>
									<Card bordered={false} className={styles.productCard}>
										<h2>Product Details</h2>
										<FormItem label="Name" {...formLayout}>
											{getFieldDecorator('name', {
												initialValue: product.name,
												rules: [{
													required: true, message: 'Please Enter Product Name',
												}],
											})(
												<Input
													name="name"
													placeholder="Maximum Length 50 Characters"
													maxLength={50}
													minLength={3}
												/>,
											)}
										</FormItem>
										<FormItem label="Description" {...formLayout}>
											{getFieldDecorator('description', {
												initialValue: product.description,
												rules: [{
													required: true, message: 'Please Enter Product Description',
												}],
											})(
												<Input.TextArea
													name="description"
													placeholder="Maximum Length 400 Characters"
													maxLength={400}
												/>,
											)}
										</FormItem>
										<FormItem label="Max Card Per Client" {...formLayout}>
											{getFieldDecorator('maxCardsPerUser', {
												initialValue: product.maxCardsPerUser,
												rules: [{
													required: true, message: 'Please Set Max Card Per Client',
												}],
											})(
												<InputNumber
													max={program.maxCardsPerUser}
													placeholder="Set Max Card Per Client"
													type="number"
												/>,
											)}
										</FormItem>
										{restFields.map((el) => (
											<FormItem
												label={ActivityHelpers.transformToReadable(el.name)}
												{...formLayout}
												key={el.name}
											>
												{getFieldDecorator(el.name, {
													initialValue: el.initialValue,
													rules: [{
														required: true, message: `Please Select ${el.name}`,
													}],
												})(
													<Select
														showSearch
														optionFilterProp="children"
														filterOption={
															(input, data) => data.props.children.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
														}
														mode={el.mode}
													>
														{el.values.map((value) => (
															<Select.Option
																value={value.isoCode || value.isoAlpha3Code || value.id}
																key={value.id}
																className="capitalize"
															>
																{value.name || value.id}
															</Select.Option>
														))}
													</Select>,
												)}
											</FormItem>
										))}
										{cardAttributes.map((el) => {
											if (el.value) {
												return (
													<FormItem {...formLayout} key={el.name}>
														{getFieldDecorator(el.name, {
															initialValue: el.initialValue,
															valuePropName: 'checked',
														})(
															<Checkbox>
																{ActivityHelpers.transformToReadable(el.name)}
															</Checkbox>,
														)}
													</FormItem>
												);
											}
											return null;
										})}
										<FormItem label="Card Design">
											{getFieldDecorator('cardDesignIds', {
												initialValue: product.cardDesigns.map((el) => el.id),
												rules: [{
													required: true, message: 'Please Select Card Design!',
												}],
											})(
												<Checkbox.Group
													options={program.cardDesignIds.map((el) => (
														{
															label: <img
																src={cardDesign.filter((design) => design.id === el).length
																	? cardDesign.filter((design) => design.id === el)[0].imageUrl
																	: ''}
																alt="design"
															/>,
															value: el,
														}
													))}
												/>,
											)}
										</FormItem>
									</Card>
								</Col>
								<Col span={12} className="col-margin">
									<Card bordered={false} className={styles.limit}>
										<h2>Authorization Control</h2>
										<h4>Name</h4>
										<FormItem>
											{getFieldDecorator('authorizationControlName', {
												initialValue: product.authorizationControl.name,
												rules: [{
													required: true, message: 'Please Enter Authorization Control Name',
												}],
											})(
												<Input
													name="name"
													placeholder="Maximum Length 50 Characters"
													maxLength={50}
													minLength={3}
												/>,
											)}
										</FormItem>
										{controlData.map((el: Object) => (
											<div key={el.name}>
												<h4>{ActivityHelpers.transformToReadable(el.strategy)}</h4>
												<FormItem>
													{getFieldDecorator(el.strategy, {
														initialValue: el.checked,
													})(
														<Radio.Group
															buttonStyle="solid"
														>
															{el.strategyValues.map((strategyValue) => (
																<Radio.Button
																	key={strategyValue}
																	value={strategyValue}
																>
																	{ActivityHelpers.transformToReadable(strategyValue)}
																</Radio.Button>
															))}
														</Radio.Group>,
													)}
												</FormItem>
												<h4>{ActivityHelpers.transformToReadable(el.name)}</h4>
												<FormItem>
													{getFieldDecorator(el.name, {
														initialValue: el.initialValue,
													})(
														<Select
															showSearch
															allowClear
															mode="multiple"
															optionFilterProp="children"
															filterOption={
																(input, data) => data.props.children.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															}
															disabled={getFieldValue(el.strategy) === 'AllowAll'}
														>
															{el.options.map((value) => (
																<Select.Option
																	value={value.id}
																	key={value.id}
																	className="capitalize"
																>
																	{value.name || value.id}
																</Select.Option>
															))}
														</Select>,
													)}
												</FormItem>
											</div>
										))}
										<h4>Name</h4>
										<FormItem>
											{getFieldDecorator('merchantScopeName', {
												initialValue: product.authorizationControl.merchantScope?.name,
											})(
												<Input
													name="name"
													placeholder="The name is a required field if you want to set Merchant Scope!"
													maxLength={50}
													minLength={3}
												/>,
											)}
										</FormItem>
										<h4>Merchant Category Strategy</h4>
										<FormItem>
											{getFieldDecorator('merchantCategoryStrategy', {
												initialValue: product.authorizationControl
												&& product.authorizationControl.merchantScope
													? product.authorizationControl.merchantScope.merchantCategoryStrategy
													: '',
											})(
												<Radio.Group
													buttonStyle="solid"
												>
													{StatusHelpers.merchantCategoryStrategy.map((strategyValue) => (
														<Radio.Button
															key={strategyValue}
															value={strategyValue}
														>
															{ActivityHelpers.transformToReadable(strategyValue)}
														</Radio.Button>
													))}
												</Radio.Group>,
											)}
										</FormItem>
										<h4>Merchant Category Codes</h4>
										<FormItem>
											{getFieldDecorator('merchantCategoryCodes', {
												initialValue: product.authorizationControl
												&& product.authorizationControl.merchantScope
													? product.authorizationControl.merchantScope.merchantCategoryCodes
														.map((el) => el.code)
													: [],
											})(
												<Select
													showSearch
													allowClear
													mode="multiple"
													optionFilterProp="children"
													filterOption={
														(input, data) => data.props.children.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
													disabled={getFieldValue('merchantCategoryStrategy') === 'AllowAll'}
												>
													{merchantCategoryCodes.map((value) => (
														<Select.Option
															value={value.code}
															key={value.code}
															className="capitalize"
														>
															{`${value.code} ${value.description}`}
														</Select.Option>
													))}
												</Select>,
											)}
										</FormItem>
										<h4>Merchant Strategy</h4>
										<FormItem>
											{getFieldDecorator('merchantStrategy', {
												initialValue: product.authorizationControl
												&& product.authorizationControl.merchantScope
													? product.authorizationControl.merchantScope.merchantStrategy
													: '',
											})(
												<Radio.Group
													buttonStyle="solid"
												>
													{StatusHelpers.merchantStrategy.map((strategyValue) => (
														<Radio.Button
															key={strategyValue}
															value={strategyValue}
														>
															{ActivityHelpers.transformToReadable(strategyValue)}
														</Radio.Button>
													))}
												</Radio.Group>,
											)}
										</FormItem>
										<h4>Merchant Ids</h4>
										<FormItem>
											{getFieldDecorator('merchantIds', {
												initialValue: product.authorizationControl
												&& product.authorizationControl.merchantScope
													? product.authorizationControl.merchantScope.merchantIds
													: undefined,
											})(
												<Input
													allowClear
													disabled={getFieldValue('merchantStrategy') === 'AllowAll'}
													placeholder="Enter four-digit codes separated by commas"
												/>,
											)}
										</FormItem>
									</Card>
								</Col>
							</Row>
							<Row>
								<LimitControls
									limitControlsData={unsetLimit}
									title="Product Limit Controls"
									applicationId={applicationId}
									addLimitControl={this.addLimit}
									handleOpenDrawer={this.handleOpenDrawer}
									handleEditClick={this.updateLimit}
								/>
							</Row>
							<div className={styles.saveButtons}>
								<FormItem>
									<CPButton ghost action={this.backToDefault} text="cancel" />
								</FormItem>
								<FormItem>
									<CPButton
										type="primary"
										action={this.saveClick}
										text="save changes"
									/>
								</FormItem>
							</div>
						</Form>
					)
					: <Spin spinning={loading} />}
				<FormModal
					title="Create New Limit Control"
					visible={add}
					cancelFunction={this.handleAddLimitCancel}
					form={(
						<LimitControlForm
							submitChanges={this.continueAddingLimit}
							handleCancelClick={this.handleAddLimitCancel}
							entryModes={allEntryModes}
							transactionTypes={allTransactionTypes}
							merchantCategoryCodes={allMerchantCategoryCodes}
							currencies={program.currencies}
							limitToUpdate={limitToUpdate}
							wrappedComponentRef={(element) => { this.formRef = element; }}
						/>
					)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={this.handleErrorCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
				<Drawer
					width={600}
					placement="right"
					onClose={this.onClose}
					visible={drawerOpen}
					closable={false}
				>
					<ControlComponent controlData={drawerData} />
					<div className={styles.close}>
						<CPButton
							text="close"
							action={this.onClose}
						/>
					</div>
				</Drawer>
			</div>
		);
	}
}

const UpdateProductForm = Form.create()(UpdateProductFormTest);

const mapStateToProps = (state: State) => ({
	program: state.cards.program,
	isFetchingProgram: state.cards.isFetchingProgram,
	cardDesign: state.cards.cardDesign,
	allEntryModes: state.cards.entryModes,
	allTransactionTypes: state.cards.transactionTypes,
	allMerchantCategoryCodes: state.cards.merchantCategoryCodes,
});
const mapDispatchToProps = {
	getCardDesign,
	getEntryMode,
	getTransactionTypes,
	getMerchantCategoryCodes,
	getProgram,
	getProduct,
	updateProduct,
	addMerchantScope,
	updateMerchantScope,
	addAuthorizationControl,
	updateAuthorizationControl,
	addLimitControl,
	updateLimitControl,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProductForm);
