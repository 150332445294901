// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import CPButton from '../../../components/UiElements/Button';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import { getActivityLog } from '../../../modules/actions/LogsActions';
//= import selectors
import { activityMappedSelector } from '../../../modules/selectors/ActivitySelector';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { ActivityLog } from '../../../modules/reducers/LogsReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	getActivityLog: (string, number, number, string) => void,
	activityLog: Array<ActivityLog>,
	applicationId: string,
	userId: string,
	fetchingActivityLog: boolean,
}

const Activity = (props: Props) => {
	const {
		activityLog,
		applicationId,
		userId,
		fetchingActivityLog,
		getActivityLog: getActivityLogAction,
	} = props;

	useEffect(() => {
		getActivityLogAction(applicationId, 10, 1, `ownedBy=${userId}`);
	}, [getActivityLogAction, applicationId, userId]);

	const columns = [
		{
			title: 'performed at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 160,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'event',
			dataIndex: 'eventName',
			key: 'eventName',
			width: 240,
			render: (text: string, record) => (
				<Link
					to={`/application/${applicationId}/clients/${userId}/logs?active=${record.id}`}
				>
					{ActivityHelpers.createActivityName(record).event}
				</Link>
			),
		},
		{
			title: 'amount',
			dataIndex: 'affectedObjectNew',
			key: 'amount',
			align: 'right',
			width: 112,
			render: (text: string, record) => (
				<div>
					{ActivityHelpers.createActivityName(record).amount}
				</div>
			),
		},
		{
			title: 'performed by',
			dataIndex: 'performer.email',
			key: 'performer',
			width: 176,
			render: (text: string, record) => (
				<div>
					{record?.performer?.email}
				</div>
			),
		},
		{
			title: 'owner',
			dataIndex: 'owner.email',
			key: 'owner',
			width: 176,
			render: (text: string, record) => (
				<div>
					{record?.owner?.email}
				</div>
			),
		},
		{
			title: 'affected object',
			dataIndex: 'affectedModel',
			key: 'affectedModel',
			width: 144,
			render: (text: string) => (
				<div className="bold">
					{ActivityHelpers.transformToReadable(text)}
				</div>
			),
		},
	];
	return (
		<div>
			{!fetchingActivityLog ? (
				<div className={styles.activity}>
					<CustomTable
						columns={columns}
						data={activityLog}
						total={10}
						currentPage={1}
						headerHeight={240}
						place="userActivity"
						applicationId={applicationId}
					/>
					<div className={styles.viewAll}>
						<CPButton
							text="view all"
							href={`/application/${applicationId}/clients/${userId}/logs`}
							type="link"
						/>
					</div>
				</div>
			)
				: <Spin spinning={fetchingActivityLog} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	activityLog: activityMappedSelector(state),
	fetchingActivityLog: state.logs.fetchingActivityLog,
});

const mapDispatchToProps = {
	getActivityLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
