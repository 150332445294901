// @flow
import { createSelector } from 'reselect';
//= import types
import type { State } from '../types/FlowTypes';
import type { Instrument } from '../reducers/InstrumentReducer';

export type ProfileValue = {
	total: string,
	reserved: string,
	available: string,
	availablePercent: number,
}

const instrumentsSelector = (state: State): Array<Instrument> => state.instrument.instruments;

const instrumentsMappedSelector = createSelector(
	instrumentsSelector,
	(instruments: Array<Instrument>) => instruments.reduce((prev, curr: Instrument) => ({
		...prev,
		[curr.instrumentId]: curr,
	}), {}),
);

export default instrumentsMappedSelector;
