// @flow

import React from 'react';
import type { Node, Element } from 'react';
import map from 'lodash/map';
//= import components
import Card from '../Card';
import List from '../List';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';

type Props = {
	data: { [string]: string | number | Node },
	colNumber: number,
	title?: string,
	subtitle?: string,
	border?: boolean,
	grid?: boolean,
	left?: string,
	styles?: Object,
	image?: Element<'svg'> | boolean,
}
const TextCard = (props: Props) => {
	const {
		title, subtitle, data, colNumber, border, grid, left, styles, image,
	} = props;
	const listData = data && map(data, (key, value) => ({
		title: value,
		content: key,
	}));

	return (
		<Card className={grid ? 'grid-card custom-card' : 'custom-card'} bordered={border} style={styles}>
			{
				title
					? <h2>{title}</h2>
					: ''
			}
			{
				subtitle
					? <h4 className="subtitle">{subtitle}</h4>
					: ''
			}
			<List
				itemLayout="horizontal"
				dataSource={listData}
				grid={colNumber > 1 ? { gutter: 48, column: colNumber } : false}
				renderItem={
					(item) => (
						<List.Item key={item.title} className="item">
							<List.Item.Meta
								description={item.title ? ActivityHelpers.transformToReadable(item.title) : '-'}
							/>
							<div className={left}>
								{item.content}
							</div>
						</List.Item>
					)
				}
			/>
			{image
				&& (
					<div className="image-in-card">
						{image}
					</div>
				)}
		</Card>
	);
};

TextCard.defaultProps = {
	title: '',
	subtitle: '',
	border: false,
	grid: false,
	left: '',
	styles: {},
	image: false,
};

export default TextCard;
