// @flow

import {
	GET_CUSTOMER_BANK_ACCOUNTS,
	GET_CUSTOMER_BANK_ACCOUNT,
	ASSOCIATE_CUSTOMER_BANK_ACCOUNT,
	EDIT_CUSTOMER_BANK_ACCOUNT,
	GET_CLIENT_BANK_ACCOUNTS,
	GET_CLIENT_BANK_ACCOUNT,
	CREATE_CLIENT_BANK_ACCOUNT,
	GET_USER_BANK_ACCOUNTS,
	GET_ACCOUNT_BANK_ACCOUNTS,
	GET_BANK_ACCOUNT_PAYMENTS,
	GET_APPLICATION_BANK_PAYMENTS,
	GET_BANK_PAYMENT,
	GET_SCHEMES,
	GET_PAYOUT_FIELDS,
	CUSTOMER_PAYOUT,
	SIMULATE_CUSTOMER_PAYMENT,
} from '../const/types';

export function getCustomerBankAccounts(appId: string, page: number, parameter: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CUSTOMER_BANK_ACCOUNTS,
		payload: {
			client: 'bank',
			request: {
				url: `accounts/v1/applications/${appId}/customer-bank-accounts?${parameter || ''}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getCustomerBankAccount(customerBankAccountId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CUSTOMER_BANK_ACCOUNT,
		payload: {
			client: 'bank',
			request: {
				url: `accounts/v1/customer-bank-accounts/${customerBankAccountId}`,
			},
		},
	});
}

export function associateBankAccount(applicationId: string, providerName: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ASSOCIATE_CUSTOMER_BANK_ACCOUNT,
		payload: {
			client: 'bank',
			request: {
				method: 'POST',
				url: `accounts/v1/applications/${applicationId}/providers/${providerName}/customer-bank-accounts/associate`,
				data,
			},
		},
	});
}

export function editCustomerBankAccount(customerBankAccountId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: EDIT_CUSTOMER_BANK_ACCOUNT,
		payload: {
			client: 'bank',
			request: {
				method: 'POST',
				url: `accounts/v1/customer-bank-accounts/${customerBankAccountId}`,
				data,
			},
		},
	});
}

export function getSchemes(bankAccountId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_SCHEMES,
		payload: {
			client: 'bank',
			request: {
				url: `payments/v1/bank-accounts/${bankAccountId}/schemes`,
			},
		},
	});
}

export function getPayoutFields(bankAccountId: string, schemeName: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PAYOUT_FIELDS,
		payload: {
			client: 'bank',
			request: {
				method: 'OPTIONS',
				url: `payments/v1/bank-accounts/${bankAccountId}/schemes/${schemeName}/bank-payments`,
			},
		},
	});
}

export function customerPayout(bankAccountId: string, schemeName: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: CUSTOMER_PAYOUT,
		payload: {
			client: 'bank',
			request: {
				method: 'POST',
				url: `payments/v1/bank-accounts/${bankAccountId}/schemes/${schemeName}/bank-payments`,
				data,
			},
		},
	});
}

export function simulateCustomerPayment(bankAccountId: string, categoryName: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: SIMULATE_CUSTOMER_PAYMENT,
		payload: {
			client: 'bank',
			request: {
				method: 'POST',
				url: `payments/v1/bank-accounts/${bankAccountId}/credit/category/${categoryName}`,
				data,
			},
		},
	});
}

export function getBankAccountPayments(accId: string, page: number, parameter: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_BANK_ACCOUNT_PAYMENTS,
		payload: {
			client: 'bank',
			request: {
				url: `payments/v1/bank-accounts/${accId}/bank-payments?${parameter || ''}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getApplicationBankPayments(appId: string, page: number, parameter: string, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: GET_APPLICATION_BANK_PAYMENTS,
		payload: {
			client: 'bank',
			request: {
				url: `payments/v1/applications/${appId}/bank-payments?${parameter || ''}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page),
				},
			},
		},
	});
}

// client

export function getClientBankAccounts(appId: string, page: number, parameter: string, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: GET_CLIENT_BANK_ACCOUNTS,
		payload: {
			client: 'bank',
			request: {
				url: `accounts/v1/applications/${appId}/bank-accounts?${parameter || ''}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': page,
				},
			},
		},
	});
}

export function getClientBankAccount(accountId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CLIENT_BANK_ACCOUNT,
		payload: {
			client: 'bank',
			request: {
				url: `accounts/v1/bank-accounts/${accountId}`,
			},
		},
	});
}

export function createClientBankAccount(data: Object) {
	return (dispatch: Function): void => dispatch({
		type: CREATE_CLIENT_BANK_ACCOUNT,
		payload: {
			client: 'bank',
			request: {
				method: 'POST',
				url: 'accounts/v1/bank-accounts',
				data,
			},
		},
	});
}

export function getUserBankAccounts(userId: string, page: number, parameter: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_USER_BANK_ACCOUNTS,
		payload: {
			client: 'bank',
			request: {
				url: `accounts/v1/users/${userId}/bank-accounts?${parameter || ''}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getAccountBankAccounts(accountId: string, page: number, parameter: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_BANK_ACCOUNTS,
		payload: {
			client: 'bank',
			request: {
				url: `accounts/v1/accounts/${accountId}/bank-accounts?${parameter || ''}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getBankPayment(paymentId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_BANK_PAYMENT,
		payload: {
			client: 'bank',
			request: {
				url: `payments/v1/bank-payments/${paymentId}`,
			},
		},
	});
}
