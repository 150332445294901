// @flow

import React from 'react';

type IconStyle = {
	width?: string,
	height?: string,
}

const CoverPlaceholder = ({
	width = '306px',
	height = '96px',
}: IconStyle) => (
		<svg width={width} height={height} viewBox="0 0 306 96" version="1.1">
			<title>cover_placeholder</title>
			<desc>Created with Sketch.</desc>
			<defs>
				<rect id="path-1" x="0" y="0" width="306" height="96" rx="9.6" />
			</defs>
			<g id="cover_placeholder" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Group">
					<mask id="mask-2" fill="white">
						<use xlinkHref="#path-1" />
					</mask>
					<use id="Mask" fill="#E3E4E5" fillRule="nonzero" xlinkHref="#path-1" />
					<g id="Bitmap" mask="url(#mask-2)" fill="#FFFFFF" fillRule="nonzero">
						<g transform="translate(128.000000, 30.000000)" id="Combined-Shape">
							<path d="M50,29.3864399 L50,40.0014666 C50,41.6583208 48.6568542,43.0014666 47,43.0014666 L3,43.0014666 C1.34314575,43.0014666 0,41.6583208 0,40.0014666 L0,34.5417576 L9.85392899,23.8522615 C10.9769217,22.6340443 12.8748474,22.5568495 14.0930647,23.6798423 C14.1758426,23.7561496 14.2542594,23.8370562 14.3279429,23.922178 L24.051314,35.154956 C24.4127737,35.5725268 25.0443026,35.6180139 25.4618734,35.2565543 C25.5536087,35.1771459 25.6301273,35.0817061 25.6876894,34.9748994 L36.9609424,14.057309 C37.7469937,12.598786 39.5665807,12.05364 41.0251037,12.8396913 C41.5645483,13.1304176 42.0020234,13.5793698 42.2786857,14.1261607 L50,29.3864399 Z M16,12 C12.6862915,12 10,9.3137085 10,6 C10,2.6862915 12.6862915,5.68434189e-14 16,5.68434189e-14 C19.3137085,5.68434189e-14 22,2.6862915 22,6 C22,9.3137085 19.3137085,12 16,12 Z" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);

CoverPlaceholder.defaultProps = {
	width: '306px',
	height: '96px',
};

export default CoverPlaceholder;
