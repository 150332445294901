// flow

import { createSelector } from 'reselect';
import type { State } from '../types/FlowTypes';
import type { Event, Webhook, WebhookJobs } from '../reducers/WebhooksReducer';

export type WebhookMapped = {
    ...Webhook,
    eventName: String,
};
export type WebhookJobsMapped = {
    ...WebhookJobs,
    eventName: String,
};

const webhookSelector = (state: State):Webhook => state.webhooks.webhooks;
const webhookJobsSelector = (state: State):Webhook => state.webhooks.webhookJobs;

export const eventMappedSelector = (state: State):Array<Event> => state.webhooks.events.reduce(
	(prev, curr) => ({
		...prev,
		[curr.id]: curr,
	}), {},
);

export const webhooksMappedSelector = createSelector(
	webhookSelector,
	eventMappedSelector,
	(webhhoks: Array<Webhook>, events: Array<Event>):WebhookMapped => webhhoks
		.map((webhook: Webhook):WebhookMapped => ({
			...webhook,
			eventName: events[webhook.eventId] ? events[webhook.eventId].name : '-',
		})),
);

export const webhookJobsMappedSelector = createSelector(
	webhookJobsSelector,
	eventMappedSelector,
	(webhookJobs: Array<WebhookJobs>, events: Array<Event>):WebhookJobsMapped => webhookJobs
		.map((webhookJob: Webhook):WebhookMapped => ({
			...webhookJob,
			eventName: events[webhookJob.applicationWebhook.eventId] ? events[webhookJob.applicationWebhook.eventId].name : '',
		})),
);
