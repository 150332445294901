import React from 'react';
import { Switch, Route } from 'react-router-dom';
//= import components
import UsersPage from '../Users';
import FullUserPage from '../FullUser';
import UserActivityLog from '../FullUser/components/UserActivityLog';
import PaymentsPage from '../Payments';
import PaymentPage from '../Accounts/components/PaymentPage';
import WalletPage from '../Accounts/components/WalletPage';
import ApplicationOrders from '../Orders';
import OrderPage from '../Accounts/components/OrderPage';
import InventoryWalletsPage from '../InventoryWallets';
import InventoryWalletPage from '../InventoryWallets/containers/InventoryWalletPage';
import Borrowers from '../Borrowers/containers/BorrowersTab';
import ControlPanel from '../System/ControlPanel';
import FeePage from '../Fee/containers/Fee';
import Instruments from '../Instrument/container/Instruments';
import InstrumentDetails from '../Instrument/container/Instrument';
import Symbols from '../Symbol/container/Symbols';
import SymbolDetails from '../Symbol/container/Symbol';
import Groups from '../Group/containers/Groups';
import GroupDetails from '../Group/containers/Group';
import ApplicationFees from '../Fee/containers/Fees';
import Explore from '../Explore';
import SectionDetails from '../Explore/containers/Section';
import ExploreGroupDetails from '../Explore/containers/ExploreGroup';
import Log from '../Logs';
import ApplicationDetails from '../Details/index';
import Webhooks from '../Integrations/containers/Webhooks';
import Webhook from '../Integrations/containers/Webhook';
import Providers from '../Integrations/containers/Providers';
import AppFeatures from '../Features/containers/AppFeatures';
import MarginTrading from '../Features/containers/MarginTrading';
import FractionalTrading from '../Features/containers/FractionalTrading';
import PostTradeSettlement from '../Features/containers/PostTradeSettlement';
import Dividends from '../CorporateActions/containers/Dividends';
import Dividend from '../CorporateActions/containers/ScheduledPayout';
import Payout from '../CorporateActions/containers/Payout';
import Delistings from '../CorporateActions/containers/Delistings';
import StockSplits from '../CorporateActions/containers/StockSplits';
import StockSplit from '../CorporateActions/containers/StockSplit';
import AccountStockSplits from '../CorporateActions/containers/AccountStockSplits';
import AccountStockSplit from '../CorporateActions/containers/AccountStockSplit';
import Card from '../Card';
import Program from '../Card/containers/Program';
import Product from '../Card/containers/Product';
import ProductForm from '../Card/components/ProductForm';
import UpdateProductForm from '../Card/components/UpdateProductForm';
import Cards from '../Cards/containers/CardsPage';
import CardPage from '../Cards/containers/CardPage';
import CardPaymentSimulations from '../CardPayments/containers/CardPaymentsSimulations';
import CardPayments from '../CardPayments/containers/CardPaymentsPage';
import CardPaymentPage from '../CardPayments/containers/CardPaymentPage';
import Statements from '../Statements';
import StatementPage from '../Statements/containers/StatementPage';
import EventSubscriptions from '../EventSubscription/container/EventSubscriptions';
import EventSubscription from '../EventSubscription/container/EventSubscription';
import SuspendReasons from '../Suspensions/index';
import SuspendReasonPage from '../Suspensions/container/SuspendReasonPage';
import CustomerBankAccounts from '../Banking/container/CustomerBankAccounts';
import ClientBankAccounts from '../Banking/container/ClientBankAccounts';
import UserBankAccounts from '../Banking/container/UserBankAccounts';
import BankAccount from '../Banking/container/AccountPage';
import ApplicationBankPaymentsPage from '../Banking/container/ApplicationBankPaymentsPage';
import BankPaymentPage from '../Banking/container/BankPaymentPage';
import KYCReports from '../KYC';
import KYTReports from '../KYT';
import Conversions from '../Conversions';
import NewFeature from '../System/components/NewFeature';
import FourZeroFour from '../Page/404';
import FlexPayment from '../Accounts/components/FlexPaymentPage';
import ApiKeys from '../ApiKeys/containers/ApiKeys';
import UserTags from '../UserTags/containers/UserTags';

const PrivateApplicationRoutes = () => (
	<div>
		<Switch>
			<Route
				exact
				path="/application/d139541c-a64a-4b37-8f45-e68c66b8cadc/payments/10d1b6ba-5121-4c42-bc1d-fec035f70d27"
				component={FlexPayment}
			/>
			<Route
				exact
				path="/application/:applicationId/clients"
				component={UsersPage}
			/>
			<Route
				exact
				path="/application/:applicationId/KYCReports"
				component={KYCReports}
			/>
			<Route
				exact
				path="/application/:applicationId/KYTReports"
				component={KYTReports}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId"
				component={FullUserPage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/user_wallets/:walletId"
				component={WalletPage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/user_payouts/:payoutId"
				component={Payout}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/user_fees/:feeId"
				component={FeePage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/stock_splits"
				component={AccountStockSplits}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/stock_splits/:stockSplitId"
				component={AccountStockSplit}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/logs"
				component={UserActivityLog}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/payments"
				component={PaymentsPage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/payments/:paymentId"
				component={PaymentPage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/orders"
				component={ApplicationOrders}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/orders/:orderId"
				component={OrderPage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/user_cards/:cardId"
				component={CardPage}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/client_bank_accounts"
				component={UserBankAccounts}
			/>
			<Route
				exact
				path="/application/:applicationId/clients/:userId/client_bank_accounts/:accountId"
				component={BankAccount}
			/>
			<Route
				path="/application/:applicationId/orders/:orderId"
				component={OrderPage}
			/>
			<Route
				path="/application/:applicationId/orders"
				component={ApplicationOrders}
			/>
			<Route
				exact
				path="/application/:applicationId/payments/:paymentId"
				component={PaymentPage}
			/>
			<Route
				path="/application/:applicationId/payments"
				component={PaymentsPage}
			/>
			<Route
				path="/application/:applicationId/inventory/:symbolId"
				component={InventoryWalletPage}
			/>
			<Route
				path="/application/:applicationId/inventory"
				component={InventoryWalletsPage}
			/>
			<Route
				path="/application/:applicationId/borrowers"
				component={Borrowers}
			/>
			<Route
				exact
				path="/application/:applicationId/cards/:cardId"
				component={CardPage}
			/>
			<Route
				path="/application/:applicationId/cards"
				component={Cards}
			/>
			<Route
				exact
				path="/application/:applicationId/card_payments/:paymentId"
				component={CardPaymentPage}
			/>
			<Route
				path="/application/:applicationId/card_payments"
				component={CardPayments}
			/>
			<Route
				exact
				path="/application/:applicationId/card_payments_simulations"
				component={CardPaymentSimulations}
			/>
			<Route
				exact
				path="/application/:applicationId/banking/:accountId"
				component={BankAccount}
			/>
			<Route
				path="/application/:applicationId/banking"
				component={CustomerBankAccounts}
			/>
			<Route
				path="/application/:applicationId/client_bank_accounts/:accountId"
				component={BankAccount}
			/>
			<Route
				path="/application/:applicationId/client_bank_accounts"
				component={ClientBankAccounts}
			/>
			<Route
				path="/application/:applicationId/bank_payments/:bankPaymentId"
				component={BankPaymentPage}
			/>
			<Route
				path="/application/:applicationId/bank_payments"
				component={ApplicationBankPaymentsPage}
			/>
			<Route
				exact
				path="/application/:applicationId/system/instruments"
				component={Instruments}
			/>
			<Route
				exact
				path="/application/:applicationId/system/instruments/:instrumentId"
				component={InstrumentDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/symbols"
				component={Symbols}
			/>
			<Route
				exact
				path="/application/:applicationId/system/symbols/:symbolId"
				component={SymbolDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/groups"
				component={Groups}
			/>
			<Route
				exact
				path="/application/:applicationId/system/groups/:groupId"
				component={GroupDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/groups/:groupId/:symbolId"
				component={SymbolDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/fees"
				component={ApplicationFees}
			/>
			<Route
				exact
				path="/application/:applicationId/system/fees/:feeId"
				component={FeePage}
			/>
			<Route
				exact
				path="/application/:applicationId/system/explore"
				component={Explore}
			/>
			<Route
				exact
				path="/application/:applicationId/system/explore/section/:sectionId"
				component={SectionDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/explore/group/:groupId"
				component={ExploreGroupDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/logs"
				component={Log}
			/>
			<Route
				exact
				path="/application/:applicationId/system/details"
				component={ApplicationDetails}
			/>
			<Route
				exact
				path="/application/:applicationId/system/webhooks"
				component={Webhooks}
			/>
			<Route
				exact
				path="/application/:applicationId/system/webhooks/:webhookId"
				component={Webhook}
			/>
			<Route
				exact
				path="/application/:applicationId/system/providers"
				component={Providers}
			/>
			<Route
				exact
				path="/application/:applicationId/system/features"
				component={AppFeatures}
			/>
			<Route
				exact
				path="/application/:applicationId/system/features/margin_trading"
				component={MarginTrading}
			/>
			<Route
				exact
				path="/application/:applicationId/system/features/fractional_trading"
				component={FractionalTrading}
			/>
			<Route
				exact
				path="/application/:applicationId/system/features/post_trade_settlement"
				component={PostTradeSettlement}
			/>
			<Route
				exact
				path="/application/:applicationId/system/features/card_module"
				component={Card}
			/>
			<Route
				exact
				path="/application/:applicationId/system/features/:featureId"
				component={NewFeature}
			/>
			<Route
				exact
				path="/application/:applicationId/system/margin_trading"
				component={MarginTrading}
			/>
			<Route
				exact
				path="/application/:applicationId/system/fractional_trading"
				component={FractionalTrading}
			/>
			<Route
				exact
				path="/application/:applicationId/system/post_trade_settlement"
				component={PostTradeSettlement}
			/>
			<Route
				exact
				path="/application/:applicationId/system/dividends/:dividendId"
				component={Dividend}
			/>
			<Route
				exact
				path="/application/:applicationId/system/dividends"
				component={Dividends}
			/>
			<Route
				exact
				path="/application/:applicationId/system/delistings"
				component={Delistings}
			/>
			<Route
				exact
				path="/application/:applicationId/system/stock_splits/:stockSplitId"
				component={StockSplit}
			/>
			<Route
				exact
				path="/application/:applicationId/system/stock_splits"
				component={StockSplits}
			/>
			<Route
				exact
				path="/application/:applicationId/system/conversions"
				component={Conversions}
			/>
			<Route
				exact
				path="/application/:applicationId/system/statements"
				component={Statements}
			/>
			<Route
				exact
				path="/application/:applicationId/system/statements/:statementId"
				component={StatementPage}
			/>
			<Route
				exact
				path="/application/:applicationId/system/event_subscriptions"
				component={EventSubscriptions}
			/>
			<Route
				exact
				path="/application/:applicationId/system/event_subscriptions/:eventSubscriptionId"
				component={EventSubscription}
			/>
			<Route
				exact
				path="/application/:applicationId/system/card"
				component={Card}
			/>
			<Route
				exact
				path="/application/:applicationId/system/card/programs/:programId"
				component={Program}
			/>
			<Route
				exact
				path="/application/:applicationId/system/card/products/:productId"
				component={Product}
			/>
			<Route
				exact
				path="/application/:applicationId/system/card/create_product"
				component={ProductForm}
			/>
			<Route
				exact
				path="/application/:applicationId/system/card/update_product/:productId"
				component={UpdateProductForm}
			/>
			<Route
				exact
				path="/application/:applicationId/system/suspend_reasons/:reasonId"
				component={SuspendReasonPage}
			/>
			<Route
				exact
				path="/application/:applicationId/system/suspend_reasons"
				component={SuspendReasons}
			/>
			<Route
				exact
				path="/application/:applicationId/system/api_keys"
				component={ApiKeys}
			/>
			<Route
				exact
				path="/application/:applicationId/system/user_tags"
				component={UserTags}
			/>
			<Route
				exact
				path="/application/:applicationId/system"
				component={ControlPanel}
			/>
			<Route component={FourZeroFour} />
		</Switch>
	</div>
);

export default PrivateApplicationRoutes;
