// @flow

import React from 'react';
import type { Node } from 'react';
import map from 'lodash/map';
//= import components
import Card from '../Card';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';

type Props = {
	title?: string,
	data: { [string]: Node},
	action?: boolean,
	colored?: string,
	clickAction?: Function,
}
const InfoCard = (props: Props) => {
	const {
		title, data, action, colored, clickAction,
	} = props;
	return (
		<Card
			bordered={false}
			className={action ? 'in-card action-card' : 'in-card'}
			onClick={clickAction}
		>
			{title ? <h2>{title}</h2> : null}
			<div className="in-card-container">
				{map(data, (key, value) => (
					<div key={key + value}>
						<h1 style={value === colored ? { color: '#7266BA' } : { }}>{key}</h1>
						<h5>{ActivityHelpers.transformToReadable(value)}</h5>
					</div>
				))}
			</div>
		</Card>
	);
};

InfoCard.defaultProps = {
	action: false,
	title: '',
	colored: '',
	clickAction: undefined,
};

export default InfoCard;
