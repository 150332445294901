// @flow

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Tag from '../../../components/UiElements/Tag';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import types
import type { FormattedOrders } from '../../../modules/selectors/OrdersSelector';
import type { Provider } from '../../../modules/reducers/ProviderReducer';

type Props = {
	orders: Array<FormattedOrders>,
	ordersTotal: number,
	currentPage: number,
	headerHeight: number,
	applicationId: string,
	pageSize: number,
	place: string,
	providers: Array<Provider>,
	handleOrdersPageChange: (number) => void,
}

function ApplicationOrdersTable(props: Props) {
	const {
		orders,
		ordersTotal,
		currentPage,
		handleOrdersPageChange,
		applicationId,
		place,
		pageSize,
		headerHeight,
		providers,
	} = props;

	const ordersColumns = [
		{
			title: 'time created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'order id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string, record) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={place === 'appOrders'
								? `/application/${applicationId}/orders/${record.id}`
								: `/application/${applicationId}/clients/${record.user.id}/orders/${record.id}?accountId=${record.accountId}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'description',
			dataIndex: 'type',
			key: 'description',
			width: 288,
			render: (text: string, record: FormattedOrders) => (
				<div className="flex">
					<div className={`${record.side} box`} />
					<div className="capitalize primary-tag">
						{`${text} ${record.side} ${record.symbolId}`}
					</div>
					{!!record.recurrenceId && (
						<Tag status="recurring" />
					)}
				</div>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 160,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'side',
			dataIndex: 'side',
			key: 'side',
			width: 128,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'symbol id',
			dataIndex: 'symbolId',
			key: 'symbolId',
			width: 128,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'client',
			dataIndex: 'user',
			key: 'user',
			width: 208,
			render: (user: { [string]: string }) => (
				place === 'appOrders'
					? (
						<div>
							<a href={`/application/${applicationId}/clients/${user.id}`} target="_blank" rel="noopener noreferrer">
								{`${user.firstName || 'Name Not Set'} ${user.lastName || ''}`}
							</a>
						</div>
					)
					: (<div>{`${user.firstName || 'Name Not Set'} ${user.lastName || ''}`}</div>)
			),
			onCell: () => ({ onClick: (e) => { e.stopPropagation(); } }),
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 160,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
		{
			title: 'quantity (amount)',
			dataIndex: 'orderReservationQuantity',
			key: 'quantity',
			align: 'right',
			width: 320,
			render: (text: string, record: FormattedOrders) => (
				<div>
					<span>
						{text}
					</span>
					<span>
						{` (${record.orderReservationAmount})`}
					</span>
				</div>
			),
		},
		{
			title: 'requested price',
			dataIndex: 'orderCommandPrice',
			key: 'requestedPrice',
			align: 'right',
			width: 160,
		},
		{
			title: 'reservation price',
			dataIndex: 'orderReservationPrice',
			key: 'reservationPrice',
			align: 'right',
			width: 160,
		},
		{
			title: 'filled price',
			dataIndex: 'averagePurchasePrice',
			key: 'filledPrice',
			align: 'right',
			width: 160,
		},
		{
			title: 'time filled on exchange',
			dataIndex: 'executedAt',
			key: 'executedAt',
			width: 208,
			render: (text: string) => (
				<div>
					{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'}
				</div>
			),
		},
		{
			title: 'price difference',
			dataIndex: 'priceDiff',
			key: 'priceDiff',
			align: 'right',
			width: 160,
		},
		{
			title: 'time difference',
			dataIndex: 'timeDiff',
			key: 'timeDiff',
			width: 160,
		},
		{
			title: 'filled/size',
			dataIndex: 'orderReservationQuantity',
			key: 'size',
			align: 'right',
			width: 320,
			render: (text: string, record: FormattedOrders) => (
				<div className={record.status === 'executed' ? 'bold' : 'disabled-color'}>
					<span className="bold">
						{record.orderExecutedQuantity}
					</span>
					<span>
						{`/ ${text}`}
					</span>
				</div>
			),
		},
		{
			title: 'fee',
			dataIndex: 'orderExecutedFee',
			key: 'fee',
			align: 'right',
			width: 144,
		},
		{
			title: 'trading provider id',
			dataIndex: 'tradingProviderId',
			key: 'tradingProvider',
			width: 192,
			render: (text: string) => {
				const provider = providers.length && providers.find((el) => el.id === text);
				return (
					<div>
						{provider ? provider.displayName : '-'}
					</div>
				);
			},
		},
	];
	return (
		<CustomTable
			columns={ordersColumns}
			data={orders}
			total={ordersTotal}
			handlePageChange={handleOrdersPageChange}
			numberOnPage={pageSize}
			currentPage={currentPage}
			headerHeight={headerHeight}
			place={place}
			applicationId={applicationId}
		/>
	);
}

export default ApplicationOrdersTable;
