// @flow

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//= import components
import Filter from '../../../components/UiElements/Filter';
import CPButton from '../../../components/UiElements/Button';
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import Tag from '../../../components/UiElements/Tag';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import methods
import { getAccountPayments } from '../../../modules/actions/PaymentActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import ProviderHelpers from '../../../lib/helpers/providerHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Payment } from '../../../modules/reducers/PaymentReducer';

type Props = {
	payments: Array<Payment>,
	paymentsTotal: number,
	isFetchingPayments: boolean,
	paymentPage: number,
	accountId: string,
	paymentsFilters: { [string]: string },
	getAccountPayments: (string, number, string) => void,
	setCurrentPage: (string, number) => void,
	applicationId: string,
	pageSize: number,
}

const PaymentsTab = (props: Props) => {
	const {
		payments,
		paymentsTotal,
		paymentPage,
		paymentsFilters,
		pageSize,
		accountId,
		isFetchingPayments,
		applicationId,
		getAccountPayments: getAccountPaymentsAction,
		setCurrentPage: setCurrentPageAction,
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [date, setDate] = useState(false);

	useEffect(() => {
		getAccountPaymentsAction(accountId, paymentPage, queryString.stringify(paymentsFilters, { arrayFormat: 'comma' }), pageSize);
	}, [paymentsFilters, paymentPage, accountId, getAccountPaymentsAction, pageSize]);

	const handlePageChange = (page: number, pageSize: number) => {
		setCurrentPageAction('paymentPage', page);
	};

	const paymentsColumns = [
		{
			title: 'last updated',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'payment id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string, record) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/clients/${record.user.id}/payments/${record.id}?accountId=${record.accountId}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 208,
			render: (text: string, record: Payment) => (
				<div className="flex">
					<div className={`${text} box`} />
					<span className="capitalize primary-tag">
						{ActivityHelpers.transformToReadable(text)}
					</span>
					{!!record.recurrenceId && (
						<Tag status="recurring" />
					)}
				</div>
			),
		},
		{
			title: 'method',
			dataIndex: 'paymentMethodType',
			key: 'method',
			width: 112,
			render: (text: string) => (
				<div className="capitalize">
					{text || 'Manual'}
				</div>
			),
		},
		{
			title: 'amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			width: 160,
			render: (text: string, record: Payment) => (
				<div
					className={record.status === 'canceled' || record.status === 'rejected' ? 'disabled-color' : 'bold'}
				>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'wallet',
			dataIndex: 'instrumentId',
			key: 'wallet',
			width: 144,
			render: (text: string) => <div>{`${text} Wallet`}</div>,
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 144,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
		{
			title: 'fee',
			dataIndex: 'fee',
			key: 'fee',
			align: 'right',
			width: 144,
			render: (text: string, record: Payment) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'provider name',
			dataIndex: 'providerName',
			key: 'providerName',
			width: 144,
			render: (text: string) => (
				<div className="capitalize">
					{text || '-'}
				</div>
			),
		},
	];
	return (
		<div>
			<div className="logs">
				<CPButton
					action={() => { setFilterVisible(true); setDate(true); }}
					icon="CalendarDate"
					text="date"
				/>
				<CPButton
					action={() => setFilterVisible(true)}
					text="filter"
					icon="Funnel"
				/>
			</div>
			<Filter
				fields={{
					type: ProviderHelpers.allPaymentType,
					status: ProviderHelpers.paymentStatus,
					dateRange: 'time',
				}}
				date={date}
				visible={filterVisible}
				place="payments"
				position={432}
				closeFunction={() => { setFilterVisible(false); setDate(false); }}
			/>
			{!isFetchingPayments
				? (
					<CustomTable
						columns={paymentsColumns}
						data={payments}
						total={paymentsTotal}
						handlePageChange={handlePageChange}
						currentPage={paymentPage}
						numberOnPage={pageSize}
						place="userPayments"
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingPayments} position="spin-relative" />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	payments: state.payment.payments,
	isFetchingPayments: state.payment.isFetchingPayments,
	paymentsTotal: state.payment.paymentsTotal,
	paymentPage: state.ui.paymentPage,
	paymentsFilters: state.ui.paymentsFilters,
	pageSize: state.ui.userPaymentsPageSize
});

const mapDispatchToProps = {
	getAccountPayments,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTab);
