// @flow

import React from 'react';
//= import components
import Icon from '../../../components/UiElements/Icon';
import Spin from '../../../components/UiElements/Spin';
import Tag from '../../../components/UiElements/Tag';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';

type Props = {
	collateralInstruments: Array<Instrument>,
	handleEditClick: (Instrument) => void,
	applicationId: string,
	isFetchingInstruments: boolean,
	handlePageChange: Function,
	currentPage: number,
	headerHeight: number,
	instrumentsTotal: number,
}

const CollateralInstruments = (props: Props) => {
	const {
		isFetchingInstruments,
		currentPage,
		collateralInstruments,
		handleEditClick,
		applicationId,
		handlePageChange,
		headerHeight,
		instrumentsTotal,
	} = props;
	const columns = [
		{
			title: 'id',
			dataIndex: 'instrumentId',
			key: 'instrumentId',
			width: 160,
		},
		{
			title: 'instrument',
			dataIndex: 'name',
			key: 'name',
			width: 288,
			render: (text: string, record: Instrument) => (
				<a
					href={`/application/${applicationId}/system/instruments/${record.instrumentId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{text}
				</a>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 144,
			render: (text: string) => (
				<div style={{ color: StatusHelpers.typeColor(text) }}>
					{StatusHelpers.typeName(text)}
				</div>
			),
		},
		{
			title: 'suitable for collateral',
			dataIndex: 'haircut',
			key: 'collateral',
			width: 192,
			render: (text: string) => (
				<Tag status={text === null ? 'unsuitable' : 'suitable'} />
			),
		},
		{
			title: 'haircut',
			dataIndex: 'haircut',
			key: 'haircut',
			width: 144,
			render: (text: string) => (
				<span>
					{text === null ? '-' : `${text}%` }
				</span>
			),
		},
		{
			title: 'edit',
			dataIndex: '',
			key: 'action',
			width: 80,
			render: () => (
				<Icon name="Edit" />
			),
			onCell: (record: Instrument) => ({
				onClick: (e) => {
					e.stopPropagation(); handleEditClick(record);
				},
			}),
		},
	];

	return (
		<div>
			{!isFetchingInstruments
				? (
					<CustomTable
						columns={columns}
						data={collateralInstruments}
						total={instrumentsTotal}
						currentPage={currentPage}
						headerHeight={headerHeight}
						place="collateralSettings"
						applicationId={applicationId}
						handlePageChange={handlePageChange}
					/>
				)
				: <Spin spinning={isFetchingInstruments} />}
		</div>
	);
};

export default CollateralInstruments;
