/* eslint-disable react/no-did-update-set-state */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import differenceBy from 'lodash/differenceBy';
import { debounce } from 'lodash';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import actions
import { getConversionQuote } from '../../../modules/actions/WalletActions';
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
// = import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
// = import types
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Values = {
	fromWallet: ?Wallet,
	toWallet: string,
	toCurrency?: string,
	amount: number,
	fixedSide: string,
	fixedRate: number,
}
type Props = {
	form: *,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	wallets: Array<Wallet>,
	getConversionQuote: (Object) =>Promise<Object>,
	accountId: string,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	fiatInstruments: Array<Instrument>,
	applicationId: string,
	walletId: string,

}
type LocalState = {
	errorMessage: string,
	side: string,
	amount: number,
	rate: string,
	fixedRate: number,
	selectedWallet: Wallet,
	selectedInstrument: Instrument,
	fromAmount: string,
	toAmount: string,
}

const setSelected = (wallets, walletId) => {
	const fiatWallets = wallets.filter((el) => el.instrument.type === 'fiat');
	if (walletId) {
		return fiatWallets.find((el) => el.id === walletId) || {};
	}
	if (fiatWallets.length === 1) {
		return fiatWallets[0];
	}
	return {};
};
class Conversion extends React.Component<Props, LocalState> {
	debounceGetQuote;

	constructor(props: Props) {
		super(props);
		this.debounceGetQuote = debounce(this.getQuote, 300, { maxWait: 100 });
	}

	state = {
		errorMessage: '',
		side: 'from',
		amount: 0,
		rate: '',
		fixedRate: 0,
		selectedWallet: setSelected(this.props.wallets, this.props.walletId),
		selectedInstrument: {},
		fromAmount: '',
		toAmount: '',
	}

	componentDidMount() {
		const {
			getAppInstruments: getAppInstrumentsAction,
			applicationId,
		} = this.props;
		const filterOptions: string = queryString.stringify({ type: 'fiat' });
		getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
	}

	componentDidUpdate(prevProps: Object, prevState: Object) {
		const {
			fromAmount,
			selectedWallet,
			side,
			rate,
			fixedRate,
		} = this.state;

		const {
			form: { getFieldValue },
		} = this.props;

		const amount:String = getFieldValue('amount');
		this.calculateFromAmount();
		if (prevState.fromAmount !== fromAmount || prevState.fixedRate !== fixedRate) {
			this.validateAvailableWallet(fixedRate, amount, selectedWallet, side, rate);
		}
	}

	validateAvailableWallet = (fixedRate, amount, selectedWallet, side, rate) => {
		let calculatedAmount;
		if (side === 'to') {
			if (fixedRate) {
				calculatedAmount = Number(amount) * Number(fixedRate);
			} else {
				this.debounceGetQuote();
				calculatedAmount = Number(amount) * Number(rate);
			}
			if (Number(calculatedAmount) > Number(selectedWallet.available)) {
				this.setState({
					errorMessage: 'The Amount must be less than or equal to the Source Wallet Balance',
				});
			} else {
				this.setState({
					errorMessage: '',
				});
			}
		}

		if (side === 'from') {
			if (Number(amount) > Number(selectedWallet.available)) {
				this.setState({
					errorMessage: 'The Amount must be less than or equal to the Source Wallet Balance',
				});
			} else {
				this.setState({
					errorMessage: '',
				});
			}
		}
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			side: 'from',
			amount: 0,
			rate: '',
			fixedRate: 0,
			selectedWallet: setSelected(this.props.wallets, this.props.walletId),
			selectedInstrument: {},
			fromAmount: '',
			toAmount: '',
		});
	}

	calculateFromAmount = () => {
		const {
			amount, side, fixedRate, selectedWallet,
		} = this.state;
		if (side === 'from') {
			return PriceHelpers.formatAmount(amount.toString(), selectedWallet.instrumentId || '');
		}
		return fixedRate && !/^\.$|^0.$/g.test(fixedRate)
			? PriceHelpers.formatAmount(
				PriceHelpers.formatNumber(Numbers.mul(amount.toString(), fixedRate.toString()), 2),
				selectedWallet.instrumentId || '',
			)
			: '-';
	};

	calculateToAmount = () => {
		const {
			amount, side, fixedRate, selectedInstrument,
		} = this.state;
		if (side === 'to') {
			return PriceHelpers.formatAmount(amount.toString(), selectedInstrument.instrumentId || '');
		}
		return fixedRate && !/^\.$|^0.$/g.test(fixedRate)
			? PriceHelpers.formatAmount(
				PriceHelpers.formatNumber(Numbers.div(amount.toString(), fixedRate.toString()), 2),
				selectedInstrument.instrumentId || '',
			)
			: '-';
	};

	onWalletChange = (value: string) => {
		const { form: { setFieldsValue } } = this.props;
		const selectedWallet = this.props.wallets.filter((wallet) => wallet.id === value)[0];
		this.setState({
			selectedWallet,
			rate: '',
			fromAmount: '',
			toAmount: '',
		});
		setFieldsValue({ toCurrency: '' });
	}

	onInstrumentChange = (value: string) => {
		const { fiatInstruments } = this.props;
		const instrument = fiatInstruments.find((el) => el.instrumentId === value);
		this.setState({
			selectedInstrument: instrument,
			rate: '',
			fromAmount: '',
			toAmount: '',
		});
	}

	onAmountChange = (value: number) => {
		if (value) {
			this.setState({
				amount: value,
				fromAmount: '',
				toAmount: '',
			});
		} else {
			this.setState({
				amount: 0,
				fromAmount: '',
				toAmount: '',
			});
		}
	}

	onRateChange = (value: number) => {
		if (value) {
			this.setState({
				fixedRate: value,
				rate: '',
				fromAmount: '',
				toAmount: '',
			});
		} else {
			this.setState({
				fixedRate: 0,
				rate: '',
			});
		}
	}

	onSideChange = (value: string) => {
		this.setState({
			side: value,
			rate: '',
			fromAmount: '',
			toAmount: '',
		});
	}

	getQuote = async () => {
		const {
			form: { getFieldValue },
			getConversionQuote: getConversionQuoteAction,
			accountId,
		} = this.props;
		const { selectedWallet } = this.state;
		const data = {
			accountId,
			toCurrency: getFieldValue('toCurrency'),
			fromCurrency: selectedWallet.instrumentId,
			fixedSide: getFieldValue('fixedSide'),
			amount: getFieldValue('amount'),
		};
		getConversionQuoteAction(data).then((response) => {
			this.setState({
				rate: response.payload.data.conversionRate,
				fromAmount: PriceHelpers.formatAmount(
					response.payload.data.fromAmount,
					response.payload.data.fromCurrency,
				),
				toAmount: PriceHelpers.formatAmount(
					response.payload.data.toAmount,
					response.payload.data.toCurrency,
				),
			});
		}).catch((error) => {
			const errorMessage = error.error.response.data.message;
			if (errorMessage !== 'Bad Request') {
				this.setState({
					errorMessage,

				});
			}
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	quoteDisabled = () => {
		const { form: { getFieldValue } } = this.props;
		const fromWallet = getFieldValue('fromWallet');
		const toCurrency = getFieldValue('toCurrency');
		const fixedSide = getFieldValue('fixedSide');
		const amount = getFieldValue('amount');
		const fixedRate = getFieldValue('fixedRate');
		if (fromWallet && toCurrency && fixedSide && amount && !fixedRate) {
			return false;
		}
		return true;
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			wallets,
			fiatInstruments,
			walletId,
		} = this.props;
		const {
			errorMessage,
			rate,
			selectedWallet,
			fromAmount,
			toAmount,
			amount,
		} = this.state;
		const fiatWallets: Array<Wallet> = wallets.filter((el) => el.instrument.type === 'fiat');
		const restWallets: Array<Wallet> = fiatWallets.filter((el) => el.id !== selectedWallet.id);
		const availableFiatInstruments: Array<Instrument> = differenceBy(fiatInstruments, restWallets, 'instrumentId')
			.filter((el) => el.instrumentId !== selectedWallet?.instrumentId);
		const { Option } = Select;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 10 },
				offset: 4,
			},
			labelAlign: 'left',
		};
		const walletLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 14 },
			},
			labelAlign: 'left',
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<FormItem label="FROM WALLET" {...walletLayout}>
					{getFieldDecorator('fromWallet', {
						rules: [{ required: true, message: "Wallet can't be left empty" }],
						initialValue: selectedWallet.id,
					})(
						<Select
							showSearch
							placeholder="Select Wallet"
							onChange={this.onWalletChange}
							disabled={fiatWallets.length === 1 || !!walletId}
						>
							{fiatWallets.map((wallet) => (
								<Option value={wallet.id} key={wallet.id}>
									<div>
										{`${wallet.instrumentId} Wallet `}
									</div>
									<div className={styles.walletId}>{`ID ${wallet.id}`}</div>
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="TO WALLET" {...walletLayout}>
					{getFieldDecorator('toCurrency', {
						rules: [{ required: true, message: "Wallet can't be left empty" }],
					})(
						<Select
							showSearch
							placeholder="Select Wallet"
							onChange={this.onInstrumentChange}
						>
							{restWallets.length && (
								<Option key="wallets" disabled>
									<span className="bold">WALLETS</span>
								</Option>
							)}
							{restWallets.map((wallet) => (
								<Option value={wallet.instrumentId} key={wallet.id}>
									<div>
										{`${wallet.instrumentId} Wallet `}
									</div>
									<div className={styles.walletId}>{`ID ${wallet.id}`}</div>
								</Option>
							))}
							{!!availableFiatInstruments.length
								&& (
									<Option key="creditFiat" disabled>
										<span className="bold">CONVERSION AND CREATE WALLET</span>
									</Option>
								)}
							{availableFiatInstruments.map((el) => (
								<Option value={el.instrumentId} key={el.id}>
									<div>
										{`${el.instrumentId} Wallet `}
									</div>
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="FIXED SIDE" {...walletLayout}>
					{getFieldDecorator('fixedSide')(
						<Select
							showSearch
							placeholder="Select Side"
							onChange={this.onSideChange}
						>
							{StatusHelpers.fixedSides.map((el) => (
								<Option value={el} key={el}>
									{el}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="AMOUNT" {...formItemLayout}>
					{getFieldDecorator('amount')(
						<InputNumber
							placeholder="0.00"
							min={0}
							className={styles.input}
							onChange={this.onAmountChange}
						/>,
					)}
				</FormItem>
				<div className={styles.quote}>
					<CPButton type="primary" action={this.getQuote} disabled={this.quoteDisabled()} text="Get a Quote" />
					<p>{rate}</p>
				</div>
				<FormItem label="FIXED RATE" {...formItemLayout}>
					{getFieldDecorator('fixedRate')(
						<InputNumber
							placeholder="0.00"
							onChange={this.onRateChange}
						/>,
					)}
				</FormItem>
				<div className={styles.quote}>
					<h4>From Amount: {fromAmount || this.calculateFromAmount()}</h4>
					<h4>To Amount: {toAmount || this.calculateToAmount()}</h4>
				</div>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!amount} text="execute conversion" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const ConversionForm = Form.create()(Conversion);

const mapStateToProps = (state) => ({
	fiatInstruments: state.instrument.instruments,
});

const mapDispatchToProps = {
	getConversionQuote,
	getAppInstruments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversionForm);
