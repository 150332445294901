// @flow

import {
	GET_INVENTORY_WALLETS,
	GET_INVENTORY_WALLET,
	GET_INVENTORY_TRANSACTIONS,
	EXPORT_INVENTORY_TRANSACTIONS,
} from '../const/types';

export function getInventoryWallets(applicationId: string, page: number = 1, param: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_INVENTORY_WALLETS,
		payload: {
			request: {
				url: `/applications/${applicationId}/symbol-inventories?${param}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getInventoryWallet(applicationId: string, symbolId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_INVENTORY_WALLET,
		payload: {
			request: {
				url: `applications/${applicationId}/symbol-inventories/${symbolId}`,
			},
		},
	});
}

export function getInventoryWalletTransactions(
	applicationId: string,
	symbolId: string,
	page: number = 1,
) {
	return (dispatch: Function): void => dispatch({
		type: GET_INVENTORY_TRANSACTIONS,
		payload: {
			request: {
				url: `applications/${applicationId}/symbol-inventories/${symbolId}/ledger`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function exportInventoryWalletTransactions(
	applicationId: string,
	symbolId: string,
	params: string,
) {
	return (dispatch: Function): void => dispatch({
		type: EXPORT_INVENTORY_TRANSACTIONS,
		payload: {
			request: {
				url: `applications/${applicationId}/export/symbol-inventories/${symbolId}?${params}`,
			},
		},
	});
}
