// @flow

import * as React from 'react';
import * as Sentry from '@sentry/browser';
import CPButton from './components/UiElements/Button';

type Props = {
	children: React.Node,
	applicationId: string,
	applicationName: string,
  };
  type State = {
		hasError: boolean,
		eventId: '',
  }
class ErrorBoundary extends React.Component<Props, State> {
	state = {
		hasError: false,
		eventId: '',
	};

	componentDidCatch(error: Object, info: Object) {
		const { applicationName, applicationId } = this.props;
		const nodeEnv = process.env.NODE_ENV;
		this.setState({ hasError: true });
		if (nodeEnv === 'development') {
			console.log(error, info);
		} else {
			Sentry.withScope((scope) => {
				scope.setTag('applicationName', applicationName);
				Object.keys(info).forEach((key) => {
					scope.setExtra(key, info[key]);
				});
				scope.setExtra('applicationId', applicationId);
				const eventId = Sentry.captureException(error);
				this.setState({ eventId });
			});
		}
	}

	render() {
		const { hasError, eventId } = this.state;
		const { children } = this.props;
		if (hasError) {
			return (
				<div className="error">
					<h1>Something went wrong.</h1>
					<p>Please reload the page to continue using this app.</p>
					{!!eventId && (
						<CPButton
							action={() => Sentry.showReportDialog({ eventId })}
							text="report feedback"
						/>
					)}
				</div>
			);
		}
		return children;
	}
}

export default ErrorBoundary;
