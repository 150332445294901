// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
// import components
import Input from '../Input';
import Tag from '../Tag';
import FilterTag from '../FilterTag';
import Menu from '../Menu';
import Dropdown from '../Dropdown';
import CPButton from '../Button';
import Select from '../Select';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import RangePicker from '../RangePicker';
import NumberRangePicker from '../NumberRangePicker';
import FormModal from '../Modal/FormModal';
import Modal from '../Modal';
import NameForm from '../Filter/NameForm';
import CustomTabs from '../Tabs/CustomTabs';
//= import actions
import { setCurrentSearch, setCurrentFilters, setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { FilterType } from '../../../modules/reducers/UiReducer';
import type { State } from '../../../modules/types/FlowTypes';
// import styles
import styles from './assets/title.module.scss';

type Props = {
	title: string,
	applicationId: string,
	place: string,
	filter?: boolean,
	tags?: Array<string>,
	buttons?: Array<{
		text: string,
		action: Function,
		icon: string,
		type: 'primary' | 'danger' | 'default',
		disabled: boolean,
		tooltip?: string,
	}>,
	backAction?: () => void,
	searchComponent?: {
		placeholder: string,
		disabled: boolean,
	},
	fields?: {
		[key: string]: Array<{
			id: string,
			name?: string,
			value?: string,
			displayName?: string,
		}> | string,
	},
	visible?: boolean,
	searchFunction?: () => void,
	closeFunction: () => void,
	currentSearch: string,
	setCurrentSearch: (string, string) => void,
	currentFilters: FilterType,
	setCurrentFilters: (string, FilterType) => void,
	setCurrentPage: (string, number) => void,
	tabs?: Array<Object>,
	tab: string,
	actions?: {
		overlay: React.Node,
	},
	children: React.Node,
	date?: boolean,
	filterMode?: string,
}

const TitleWithFilters = (props: Props) => {
	const {
		applicationId,
		title,
		tags,
		buttons,
		backAction,
		searchComponent,
		currentSearch,
		searchFunction,
		place,
		filter,
		fields,
		visible,
		closeFunction,
		currentFilters,
		tabs,
		tab,
		actions,
		children,
		date,
		filterMode,
	} = props;
	const [savedViews, setSavedViews] = React.useState({});
	const [inView, setInView] = React.useState('');
	const [property, setProperty] = React.useState('');
	const [filterValue, setFilterValue] = React.useState(currentFilters);
	const [saveViewModal, setSaveViewModal] = React.useState(false);
	const [viewChanged, setViewChanged] = React.useState(false);
	const [dateVisible, setDateVisible] = React.useState(false);

	React.useEffect(() => {
		setFilterValue(currentFilters);
		const saved = localStorage.getItem(applicationId);
		const obj = saved ? JSON.parse(saved) : null;
		const views = !!obj && !!obj[place] ? obj[place].filters : {};
		setSavedViews(views);
	}, [currentFilters, applicationId, place]);

	const handleSearch = (value: string) => {
		props.setCurrentPage(`${place}Page`, 1);
		props.setCurrentSearch(`${place}Search`, value.toLowerCase());
	};

	const selectView = (key, value) => {
		props.setCurrentFilters(`${place}Filters`, value);
		setFilterValue(value);
		setInView(key);
	};

	const closeModal = () => {
		setProperty('');
		closeFunction();
	};

	const openDate = () => {
		setDateVisible(true);
	};

	const makeFilterValue = (value) => {
		if (filterMode === 'multiple') {
			const currValue = filterValue[property] || [];
			const newValue = uniq([...currValue, value]);
			const filters = { ...filterValue, [property]: newValue };
			setFilterValue(filters);
		} else {
			const newValue = [value];
			const filters = { ...filterValue, [property]: newValue };
			setFilterValue(filters);
		}
	};

	const removeFilterValue = (value) => {
		const currValue = filterValue[property] || [];
		const newValue = currValue.filter((el) => el !== value);
		const filters = { ...filterValue, [property]: newValue };
		setFilterValue(filters);
	};

	const wipeOutMultipleFilters = (value) => {
		const filters = { ...filterValue, [property]: value };
		if (!filters[property].length) {
			setFilterValue(filters);
		}
	};

	const makeTimeFilter = (value, values) => {
		const fromValue = value[0] ? [value[0].format('YYYY-MM-DD')] : [];
		const toValue = value[1] ? [value[1].format('YYYY-MM-DD')] : [];
		if (values === 'time') {
			const timeFilter = {
				...filterValue,
				from: fromValue,
				to: toValue,
			};
			setFilterValue(timeFilter);
		} else if (values === 'dob') {
			const timeFilter = {
				...filterValue,
				'start-date-of-birth': fromValue,
				'end-date-of-birth': toValue,
			};
			setFilterValue(timeFilter);
		} else if (values === 'lastUpdated') {
			const timeFilter = {
				...filterValue,
				updatedAtMin: fromValue,
				updatedAtMax: toValue,
			};
			setFilterValue(timeFilter);
		}
	};

	const makeInputFilterValue = (e) => {
		const newValue = [e.target.value];
		const filters = { ...filterValue, [property]: newValue };
		setFilterValue(filters);
	};

	const makeNumberFilterValue = (number: number, property: string) => {
		const newValue = number;
		const filters = { ...filterValue, [property]: [newValue] };
		setFilterValue(filters);
	};

	const replaceFilterKey = (newKey: string, oldKey: string) => {
		delete Object.assign(filterValue, { [newKey]: filterValue[oldKey] })[oldKey];
	};

	const makeQuery = () => {
		if (filterValue.clientId) replaceFilterKey('userId', 'clientId');
		props.setCurrentFilters(`${place}Filters`, filterValue);
		props.setCurrentPage(`${place}Page`, 1);
		setViewChanged(true);
		closeModal();
	};

	const filterByTime = (value) => {
		const timeFilter = {
			...filterValue,
			from: value[0] ? [value[0].format('YYYY-MM-DD 00:00:00')] : [],
			to: value[1] ? [value[1].format('YYYY-MM-DD 23:59:59')] : [],
		};
		props.setCurrentFilters(`${place}Filters`, timeFilter);
		props.setCurrentPage(`${place}Page`, 1);
		setDateVisible(false);
	};

	const onTagRemove = (key: string, value: string) => {
		const newFilterValue = filterValue[key].filter((el) => el !== value);
		const createFilter = { ...filterValue, [key]: newFilterValue };
		setFilterValue(createFilter);
		props.setCurrentFilters(`${place}Filters`, createFilter);
		setViewChanged(true);
	};

	const clearAllFilters = () => {
		setFilterValue({});
		props.setCurrentFilters(`${place}Filters`, {});
		setInView('');
	};

	const setName = (data) => {
		setSaveViewModal(false);
		const saved = localStorage.getItem(applicationId);
		const oldObj = saved ? JSON.parse(saved) : null;
		const oldPlace = oldObj ? oldObj[place] : null;
		const oldFilters = oldObj && oldObj[place] ? oldObj[place].filters : null;
		const obj = {
			...oldObj,
			[place]: {
				...oldPlace,
				filters: {
					...oldFilters,
					[data.name]: filterValue,
				},
			},
		};
		localStorage.setItem(applicationId, JSON.stringify(obj));
		setInView(data.name);
		setViewChanged(false);
	};

	const getNames = (field: string, id: string) => {
		if (fields) {
			if (field === 'from' || field === 'to' || fields[field] === 'input' || !fields[field]) {
				return id;
			}
			const selectedElement = fields[field].filter((el) => el.id === id);
			const name = selectedElement?.[0]?.name
				|| selectedElement?.[0]?.value
				|| selectedElement?.[0]?.displayName
				|| selectedElement?.[0]?.id;
			return name;
		}
		return id;
	};

	const deleteView = (key: string) => {
		const saved = localStorage.getItem(applicationId);
		const oldObj = saved ? JSON.parse(saved) : null;
		const oldPlace = oldObj ? oldObj[place] : null;
		const oldFilters = oldObj && oldObj[place] ? oldObj[place].filters : null;
		const newFilters = omit(oldFilters, key);
		const obj = {
			...oldObj,
			[place]: {
				...oldPlace,
				filters: {
					...newFilters,
				},
			},
		};
		localStorage.setItem(applicationId, JSON.stringify(obj));
		const newView = omit(savedViews, key);
		setSavedViews(newView);
	};

	const renderField = (values) => {
		if (values === 'time' || values === 'dob' || values === 'lastUpdated') {
			return (
				<RangePicker
					className={styles.date}
					dropdownClassName={styles.picker}
					onChange={(value) => makeTimeFilter(value, values)}
				/>
			);
		} if (values === 'input') {
			return (
				<Input
					className={styles.input}
					placeholder="Paste value"
					maxLength={100}
					onChange={(value) => makeInputFilterValue(value)}
					defaultValue={currentFilters?.[property]}
				/>
			);
		} if (values === 'numberRange') {
			return (
				<>
					<NumberRangePicker placeholder="From..." onChange={(value) => makeNumberFilterValue(value, `${property}GreaterThan`)} />
					<NumberRangePicker placeholder="To..." onChange={(value) => makeNumberFilterValue(value, `${property}LowerThan`)} />
				</>
			);
		}

		return (
			<Select
				key={property}
				allowClear
				className={styles.select}
				mode={filterMode}
				showSearch
				onSearch={searchFunction}
				optionFilterProp="children"
				filterOption={
					(input, data) => data.props.children.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}
				onSelect={makeFilterValue}
				onDeselect={removeFilterValue}
				onChange={wipeOutMultipleFilters}
				defaultValue={currentFilters?.[property]}
			>
				{values && values.map((option) => (
					<Option value={option.id} key={option.id} className={styles.options}>
						{
							option.name
							|| option.displayName
							|| option.value
							|| option.id
						}
					</Option>
				))}
			</Select>
		);
	};

	const { Option } = Select;

	return (
		<>
			<div className={styles.titleTags}>
				<div className={styles.container}>
					<div className={styles.start}>
						<div className={place === 'webhook' ? styles.link : styles.title}>
							{backAction && (
								<CPButton
									ghost
									action={backAction}
									icon="ArrowLeft"
								/>
							)}
							<h2>{title}</h2>
							{tags && tags.map((tag) => (
								!!tag && <Tag status={tag} key={tag} />))}
						</div>
						{children}
						{tabs && !!tabs.length
							&& <CustomTabs tabs={tabs} place={tab} />}
					</div>
					<div className={styles.buttons}>
						{!!searchComponent && !!Object.keys(searchComponent).length
							&& (
								<Input.Search
									className="tc-input-group"
									placeholder={searchComponent.placeholder}
									allowClear
									onSearch={handleSearch}
									defaultValue={currentSearch}
									disabled={searchComponent.disabled}
								/>
							)}

						{filter && (
							<Dropdown
								placement="bottomCenter"
								overlay={(
									<Menu className={styles.savedMenu}>
										{savedViews && Object.keys(savedViews).length
											? map(savedViews, (value, key) => (
												<Menu.Item key={key}>
													<div onClick={() => selectView(key, value)} role="presentation">{key}</div>
													<CPButton icon="Delete" action={() => deleteView(key)} ghost />
												</Menu.Item>
											))
											: (
												<Menu.Item key="noView">
													<div>No saved views</div>
												</Menu.Item>
											)}
									</Menu>
								)}
								trigger={['click']}
							>
								<span>
									<CPButton text={inView || 'saved views'} icon="Save" />
								</span>
							</Dropdown>
						)}
						{date && (
							<CPButton
								action={openDate}
								icon="CalendarDate"
								text="date"
							/>
						)}
						{buttons && buttons.map((button) => (
							<>
								<CPButton
									key={button.text}
									action={button.action}
									text={button.text}
									icon={button.icon}
									type={button.type || (button.text === 'filter' || button.text === 'date' ? 'default' : 'primary')}
									disabled={button.disabled}
								/>
								{button.tooltip && (
									<Tooltip
										title={button.tooltip}
										placement="topLeft"
									>
										<span className={styles.tooltip}>
											<Icon name="Info" />
										</span>
									</Tooltip>
								)}
							</>
						))}
						{actions
							&& (
								<Dropdown
									placement="bottomCenter"
									overlay={actions.overlay}
									trigger={['click']}
								>
									<span>
										<CPButton text="Actions" icon="Play" />
									</span>
								</Dropdown>
							)}
					</div>
				</div>
			</div>
			{!!currentFilters && filter
				&& (
					<div className={styles.tags}>
						{map(currentFilters, (value, key) => (
							value.map((val) => (
								<FilterTag
									closable
									onClose={() => onTagRemove(key, val)}
									key={val}
									className={styles.filterTags}
									color="#E3F0FF"
								>
									{key === 'tagIds' ? `tag is ${getNames(key, val)}` : `${key} is ${getNames(key, val)}`}
								</FilterTag>
							))
						))}
						{!!Object.keys(currentFilters).length && (
							<CPButton
								action={clearAllFilters}
								key="clearAll"
								text="clear all"
								icon="Close"
							/>
						)}
						{!!Object.keys(currentFilters).length && (!inView || viewChanged) && (
							<CPButton
								action={() => setSaveViewModal(true)}
								key="saveView"
								text="save view"
								icon="Save"
							/>
						)}
					</div>
				)}
			{filter && (
				<Modal
					visible={visible}
					footer=""
					closable={false}
					destroyOnClose
					width={600}
					style={{ top: 64, marginRight: 16 }}
					bodyStyle={{ padding: 8 }}
					onCancel={closeModal}
				>
					<div className={styles.filter}>
						<Select
							allowClear
							showSearch
							className={styles.firstSelect}
							dropdownStyle={{ top: '100px' }}
							onChange={(value: string) => setProperty(value)}
						>
							{!!fields && Object.keys(fields).map((field) => (
								<Option value={field} key={field} className={styles.options}>
									{ActivityHelpers.transformToReadable(field)}
								</Option>
							))}
						</Select>
						<div className={styles.condition}>is</div>
						{renderField(fields[property])}
						<CPButton
							type="primary"
							text="filter"
							action={makeQuery}
						/>
					</div>
				</Modal>
			)}
			<Modal
				visible={dateVisible}
				footer=""
				closable={false}
				destroyOnClose
				width={240}
				style={{ top: 64, marginRight: 16 }}
				bodyStyle={{ padding: 0 }}
				onCancel={() => setDateVisible(false)}
			>
				<RangePicker
					dropdownClassName={styles.picker}
					onChange={(value) => filterByTime(value)}
					open
				/>
			</Modal>
			<FormModal
				destroyOnClose
				title="Save View"
				visible={saveViewModal}
				cancelFunction={() => setSaveViewModal(false)}
				form={(
					<NameForm
						submitChanges={setName}
						handleCancelClick={() => setSaveViewModal(false)}
						name={inView}
					/>
				)}
			/>
		</>
	);
};

TitleWithFilters.defaultProps = {
	filter: false,
	tags: [],
	buttons: [],
	backAction: undefined,
	fields: { '': [] },
	visible: false,
	searchComponent: {},
	searchFunction: () => { },
	actions: undefined,
	tabs: [],
	date: false,
	filterMode: 'multiple',
};

const mapStateToProps = (state: State, props) => ({
	currentSearch: state.ui[`${props.place}Search`],
	currentFilters: state.ui[`${props.place}Filters`],
});

const mapDispatchToProps = {
	setCurrentSearch,
	setCurrentFilters,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleWithFilters);
