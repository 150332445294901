// @flow
import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Select from '../../../components/UiElements/Select';
import Checkbox from '../../../components/UiElements/Checkbox';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import styles
import styles from '../assets/banking.module.scss';

type Props = {
	form: *,
	submitChanges: (value: Object) => void,
	handleCancelClick: () => void,
	edit: boolean,
	providers: Array<{
		id: string,
		name: string,
		displayName: string,
	}>,
}
export type LocalState = {
	errorMessage: string,
}

class Associate extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	};

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					...values,
				};
				submitChanges(data);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	render() {
		const {
			providers,
			handleCancelClick,
			form: {
				getFieldDecorator, isFieldsTouched,
			},
		} = this.props;

		const { errorMessage } = this.state;
		const { Option } = Select;
		const FormItem = Form.Item;
		const fixedLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className={styles.associate}>
				<div className={styles.subtitle}>
					<h4>
						Provide the external ID to the account you’ve already
						created on the corresponding vendor.
						If you need assistance, please contact us.
					</h4>
				</div>
				<FormItem label="PROVIDER" {...fixedLayout} help="">
					{getFieldDecorator(
						'provider', {
							rules: [
								{ required: true, message: "Provider can't be left empty" },
							],
						},
					)(
						<Select
							allowClear
							showSearch
							className={styles.bankingFormSelect}
							placeholder="Select Provider"
						>
							{providers.map((provider) => (
								<Option value={provider.name} key={provider.id}>
									{provider.displayName}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="EXTERNAL ID" {...fixedLayout} help="">
					{getFieldDecorator(
						'externalId', {
							rules: [
								{ required: true, message: "External ID can't be left empty" },
							],
						},
					)(
						<Input
							placeholder="Provide the external ID"
						/>,
					)}
				</FormItem>
				<FormItem label="CURRENCY" {...fixedLayout} help="">
					{getFieldDecorator(
						'instrumentId', {
							rules: [
								{ required: true, message: "Currency can't be left empty" },
							],
						},
					)(
						<Select
							allowClear
							showSearch
							className={styles.bankingFormSelect}
							placeholder="Select Instrument"
						>
							{StatusHelpers.bankInstruments.map((instrument) => (
								<Option value={instrument.id} key={instrument.id}>
									{instrument.id}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="BANK ACCOUNT NAME" {...fixedLayout} help="">
					{getFieldDecorator(
						'name', {
							rules: [
								{ required: true, message: "Bank Account Name can't be left empty" },
							],
						},
					)(
						<Input
							placeholder="Set Customer Bank Account Name"
							maxLength={50}
						/>,
					)}
				</FormItem>
				<FormItem label="BANK ACCOUNT DESCRIPTION" {...fixedLayout} help="">
					{getFieldDecorator(
						'description', {
							rules: [
								{ required: true, message: "Bank Account Description can't be left empty" },
							],
						},
					)(
						<Input.TextArea
							placeholder="Set Customer Bank Account Description"
							maxLength={400}
						/>,
					)}
				</FormItem>
				<FormItem label="IS COLLECTION" {...fixedLayout} help="">
					{getFieldDecorator(
						'isCollection', {
							valuePropName: 'checked',
							initialValue: false,
						},
					)(
						<Checkbox />,
					)}
				</FormItem>
				<FormItem label="IS PAYOUT" {...fixedLayout} help="">
					{getFieldDecorator(
						'isPayout', {
							valuePropName: 'checked',
							initialValue: false,
						},
					)(
						<Checkbox />,
					)}
				</FormItem>

				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="associate external bank account" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const AssociateForm = Form.create()(Associate);

export default AssociateForm;
