// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import TableInfoComponent from '../../../components/UiElements/TableInfoComponent';
//= import types
import type { Suspension } from '../../../modules/reducers/UsersReducer';

type Props = {
	applicationId: string,
	suspensions: Array<Suspension>,
	suspensionsTotal: number,
}

function SuspensionsList(props: Props) {
	const {
		applicationId,
		suspensions,
		suspensionsTotal,
	} = props;

	const columns = [{
		title: 'updatedAt',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		width: 160,
		render: (text: {[string]: string}) => (
			<div className="capitalize">
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'client id',
		dataIndex: 'userId',
		key: 'userId',
		width: 320,
		render: (text: string) => (
			<CopyComponent
				text={text}
				content={(
					<Link
						to={`/application/${applicationId}/clients/${text}`}
					>
						{text}
					</Link>
				)}
			/>
		),
	}, {
		title: 'note',
		dataIndex: 'note',
		key: 'note',
		width: 560,
		render: (text: string) => (
			<TableInfoComponent
				content={{ note: text }}
				text={text || '-'}
				name="note"
			/>
		),
	}];

	return (
		<CustomTable
			columns={columns}
			data={suspensions}
			total={suspensionsTotal}
			numberOnPage={suspensionsTotal}
			headerHeight={64}
			applicationId={applicationId}
			place="suspensions"
		/>
	);
}

export default SuspensionsList;
