import React from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './lib/store';
import Router from './modules/router/router';
import DragDropContext from './components/UiElements/CustomTable/DragDropContext';
//= import css (sass compiled)
import './assets/styles/style.css';

ReactGA.initialize('UA-57866648-12');
history.listen((location) => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const Dash = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Router />
		</ConnectedRouter>
	</Provider>
);

const DashApp = DragDropContext(Dash);
export default DashApp;
