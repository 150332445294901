// @flow

import React from 'react';
//= import components
//= import images
import master from '../../../assets/img/cards/master.png';
import visa from '../../../assets/img/cards/visa.png';
import bcLogo from '../../../assets/img/company_logo.svg';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	id: number,
	name: string,
	description: string,
	cardScheme: string,
	action: (number) => void,
}
const imageMap = {
	MasterCard: master,
	Visa: visa,
};

const ProgramCard = (props: Props) => {
	const {
		id,
		name,
		description,
		cardScheme,
		action,
	} = props;

	return (
		<div className={styles.card} onClick={() => action(id)} role="presentation">
			<div className={styles.title}>
				<h2>{name}</h2>
				{
					imageMap[cardScheme]
						? <img src={imageMap[cardScheme]} alt="bc" />
						: <img src={bcLogo} alt="bc" />
				}
			</div>
			<div className="capitalize">{description}</div>
		</div>
	);
};

export default ProgramCard;
