// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import Title from '../../../components/UiElements/Title';
import Tag from '../../../components/UiElements/Tag';
import Tooltip from '../../../components/UiElements/Tooltip';
import CustomTable from '../../../components/UiElements/CustomTable';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import Icon from '../../../components/UiElements/Icon';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import SimulationForm from '../components/SimulationForm';
//= import methods
import {
	getCardPayments,
	getCardPayment,
	simulateCardPayment,
	getEntryMode,
	getTransactionTypes,
	getMerchantCategoryCodes,
	getCurrencies,
	getCard,
} from '../../../modules/actions/CardAction';
import { setCurrentPage, setCurrentPageSize } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type {
	Payment, EntryModes, TransactionTypes, MerchantCategoryCodes, Currency, CardType,
} from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';

type CardRes = {
	payload: {
		data: CardType
	}
}
type Props = {
	match: {
		params: {
			applicationId: string,
		}
	},
	cardPayments: Array<Object>,
	getCardPayments: (applicationId:string, pageSize: number, page: number) => void,
	getCardPayment: (string, string) => Promise<Object>,
	simulateCardPayment: (Object) => Promise<Object>,
	getEntryMode: () => void,
	getTransactionTypes: () => void,
	getMerchantCategoryCodes: () => void,
	getCurrencies: () => void,
	getCard: (string, string) => Promise<CardRes>,
	entryModes: Array<EntryModes>,
	transactionTypes: Array<TransactionTypes>,
	merchantCategoryCodes: Array<MerchantCategoryCodes>,
	cardCurrencies: Array<Currency>,
	isFetchingCardPayments: boolean,
	cardPaymentsSimulationsPageSize: number,
	cardPaymentsTotal: number,
	setCurrentPage: (place: string, page: number) => void,
	setCurrentPageSize: (place: string, pageSize: number) => void,
}
type Value = {
	scheme: string,
	authorizationType: string,
	cardId: string,
	transactionAmount: number,
	transactionCurrencyIson: string,
	amountToBeReversed: number,
	billingAmount: number,
	billingCurrencyIson: string,
	entryModeType: string,
	transactionType: string,
	mccCode: string,
	merchantId: string,
	merchantName: string,
}
type ErrorType = {
	error: {
		response: {
			data: {
				title: string,
				message: string,
				errors: {[string]: Array<string>}
			}
		}
	}
}

const randomId = () => `${Math.random().toString(36).substr(2, 9)}`;
const randomNumber = () => Math.floor(Math.random() * 10000);

function CardPaymentsSimulations(props: Props) {
	const {
		cardPayments,
		match: { params: { applicationId } },
		entryModes,
		transactionTypes,
		merchantCategoryCodes,
		cardCurrencies,
		getCardPayments: getCardPaymentsAction,
		getCardPayment: getCardPaymentAction,
		simulateCardPayment: simulateCardPaymentAction,
		getEntryMode: getEntryModeAction,
		getTransactionTypes: getTransactionTypesAction,
		getMerchantCategoryCodes: getMerchantCategoryCodesAction,
		getCurrencies: getCurrenciesAction,
		getCard: getCardAction,
		cardPaymentsTotal,
		cardPaymentsSimulationsPageSize,
		setCurrentPage: setCurrentPageAction,
		setCurrentPageSize: setCurrentPageSizeAction,
	} = props;

	const [cardPayment, setCardPayment] = useState({});
	const [edit, setEdit] = useState(false);
	const [type, setType] = useState('create');
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [success, setSuccess] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const formRef = useRef(null);
	const filterRef = useRef(null);

	useEffect(() => {
		async function getData() {
			try {
				await getCardPaymentsAction(applicationId);
				setErrorMessage('');
			} catch (err) {
				const errMessage: string = err.error?.response?.data?.message;
				if (errMessage && errMessage.startsWith('Application with id')) {
					setErrorMessage("This feature is not available for your application. If you're interested in it, please contact your TradeCore representative.");
				}
			}
		}
		getData();
	}, [applicationId, getCardPaymentsAction, submitted]);

	useEffect(() => {
		getEntryModeAction();
		getTransactionTypesAction();
		getMerchantCategoryCodesAction();
		getCurrenciesAction();
	}, [
		getEntryModeAction,
		getTransactionTypesAction,
		getMerchantCategoryCodesAction,
		getCurrenciesAction,
	]);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(cardPaymentsSimulationsPageSize);
	const handlePageChange = async (page: number, pageSize: number) => {
		await getCardPaymentsAction(applicationId, pageSize, page);
		setPageSize(pageSize);
		setPage(page);
		setCurrentPageAction('cardPaymentsSimulations', page);
		setCurrentPageSizeAction('cardPaymentsSimulationsPageSize', pageSize);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error: ErrorType) => {
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error && error.error.response.data ? error.error.response.data.title || error.error.response.data.message : 'Error',
			secondPart: error.error && error.error.response.data
				? printError(error.error.response.data.errors)
				: '',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleOnSuccess = (successData) => {
		setSuccess(successData.status === 'success');
		setWarningModal(true);
		setWarningTitle(successData.status || 'Info');
		setMessage({
			firstPart: successData.message || `You've successfully ${type} Card Payment.`,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const simulate = async (value: Value) => {
		const card = value.cardId || cardPayment.cardId;
		try {
			const cardResponse: CardRes = await getCardAction(applicationId, card);
			const cardRes = cardResponse.payload.data;
			const cardAuthorizationData = {
				cardTokenId: null,
				availableBalance: randomNumber(),
				settledBalance: randomNumber(),
				transactionStatusCode: 'A',
				statusCode: '00',
				responseCode: '00',
				authorizeType: 'AN',
				transactionFee: 0,
				currencyConvertionFee: null,
				mti: '0100',
				acquirerId: randomId(),
				txAmountFee: 0,
				authCode: randomId(),
				note: 'null',
				mccPad: null,
				cumulativePaddingAmount: null,
				appliedPaddingAmount: null,
				posDataCode: randomId(),
				posEntryMode: randomId(),
				posData: randomId(),
				posTerminalId: randomId(),
				posTime: '54362424',
				procCode: '000000',
				retrievalReferenceNumber: randomId(),
				settlementAmount: null,
				settlementCurrencyIson: null,
				traceLogId: randomNumber(),
				transactionTime: Date.now(),
				additionalData1: null,
				additionalData2: null,
				authorizedByStandIn: 'N',
				avsResult: null,
				cardUsageGroup: 1,
				institutionCode: 1,
				recordData: null,
				partialApproval: 0,
				suspicious: 0,
				notifyHolder: 1,
				riskRuleCodes: 'RRC-01;RRC-02;',
				rawMessage: null,
				productId: 3,
				riskActions: {
					markTransactionAsSuspicious: 0,
					notifyCardholderBySendingTaisNotification: 0,
					changeCardStatusToRisk: 0,
					changeAccountStatusToSuspended: 0,
					rejectTransaction: 0,
				},
				securityChecks: {
					cardExpirationDatePresent: 0,
					onlinePin: 0,
					offlinePin: 0,
					'3DSecure': 0,
					cvv2: 0,
					magneticStripe: 0,
					chipData: 0,
					avs: 0,
					phoneNumber: 0,
				},
				validDateTo: '2022-01-01',
			};
			const allData = {
				cardId: cardRes.id,
				accountId: cardRes.externalAccountId,
				sourceId: 1,
				version: '1.2',
				apiId: '1',
				sendingRetriesCount: 0,
				cardAuthorization: null,
				cardSettlement: null,
				cardStatusChange: null,
				externalPaymentSettlement: null,
				directDebitMandate: null,
				directDebitDue: null,
				directCreditReceived: null,
				negativeAcknowledgement: null,
				financialDetailAddendum: null,
				epmAddressAssignCompleted: null,
				epmAddressAssignFailed: null,
				customNotification: null,
			};
			const sameData = {
				cardId: cardRes.externalId,
				accountId: cardRes.externalAccountId,
				rowMessage: cardPayment.rowMessage,
				riskActions: cardPayment.riskActions,
				riskRuleCodes: cardPayment.riskRuleCodes,
				securityChecks: cardPayment.securityChecks,
				acquirerId: cardPayment.acquirerId,
				traceLogId: cardPayment.traceLogId,
				transLink: cardPayment.transLink,
				holderCurrencyIson: cardPayment.billingCurrency?.isoNumber ? cardPayment.billingCurrency?.isoNumber.toString() : '',
				transactionCurrencyIson: cardPayment.originalCurrency?.isoNumber ? cardPayment.originalCurrency?.isoNumber.toString() : '',
				transactionCountryIson: cardPayment.transactionCountry?.isoNumericCode ? cardPayment.transactionCountry?.isoNumericCode.toString() : '',
				billingCurrencyIson: cardPayment.billingCurrency?.isoNumber ? cardPayment.billingCurrency?.isoNumber.toString() : '',
				settlementCurrencyIson: cardPayment.billingCurrency?.isoNumber ? cardPayment.billingCurrency?.isoNumber.toString() : '',
				posEntryMode: cardPayment.entryMode?.id ? cardPayment.entryMode?.id.toString() : '',
				entryModeType: cardPayment.entryMode?.id,
				mccCode: cardPayment.mcc?.code,
				merchantId: cardPayment.merchantId,
				merchantName: cardPayment.merchantName,
			};
			const createData = {
				requestType: ['CardAuthorization'],
				...allData,
				cardAuthorization: {
					...cardAuthorizationData,
					cardId: cardRes.externalId,
					accountId: cardRes.externalAccountId,
					transactionType: value.transactionType,
					holderAmount: value.billingAmount ? Math.floor(value.billingAmount * 100) : 0,
					totalHolderAmount: value.billingAmount ? Math.floor(value.billingAmount * 100) : 0,
					holderCurrencyIson: cardRes.currency.isoNumber ? cardRes.currency.isoNumber.toString() : '',
					transactionAmount: value.transactionAmount
						? Math.floor(value.transactionAmount * 100)
						: 0,
					transactionCurrencyIson: cardRes.currency.isoNumber ? cardRes.currency.isoNumber.toString() : '',
					transactionCountryIson: cardRes.country.isoNumericCode.toString(),
					billingAmount: value.billingAmount ? Math.floor(value.billingAmount * 100) : 0,
					billingCurrencyIson: cardRes.currency.isoNumber ? cardRes.currency.isoNumber.toString() : '',
					settlementAmount: null,
					settlementCurrencyIson: null,
					authorizeType: 'AN',
					transactionStatusCode: 'A',
					entryModeType: value.entryModeType,
					mccCode: value.mccCode,
					merchantId: value.merchantId,
					merchantName: value.merchantName,
					transLink: randomId(),
					additionalAmount: {
						accountType1: '00',
						amountType1: '40',
						currencyCode1: '826',
						creditDebitIndicator1: 'D',
						amount1: '000000001000',
						accountType2: '00',
						amountType2: '40',
						currencyCode2: '826',
						creditDebitIndicator2: 'D',
						amount2: '000000001000',
						original: '*****',
						raw: '*****',
					},
				},
			};
			const settlementData = {
				...allData,
				requestType: ['cardSettlement'],
				cardAuthorization: null,
				cardSettlement: {
					...cardAuthorizationData,
					...sameData,
					transactionId: randomNumber(),
					transactionStatusCode: 'S',
					statusCode: null,
					responseCode: '2',
					posEntryMode: '2',
					transactionType: cardPayment.transactionType?.id,
					transactionAmount: cardPayment.originalAmount
						? 0 - (cardPayment.originalAmount * 100)
						: 0,
					billingAmount: cardPayment.billingAmount
						? (0 - cardPayment.billingAmount * 100)
						: 0,
					holderAmount: cardPayment.billingAmount
						? cardPayment.billingAmount * 100
						: 0,
					settlementAmount: cardPayment.billingAmount
						? (0 - cardPayment.billingAmount * 100)
						: 0,
					settlementCurrencyIson: cardPayment.billingCurrency?.isoNumber ? cardPayment.billingCurrency?.isoNumber.toString() : '',
					authorizeType: 'AN',
					additionalAmount: null,
					loadSource: null,
					loadType: null,
				},
			};
			const partialReversalData = {
				...allData,
				requestType: ['CardAuthorization'],
				cardSettlement: null,
				cardAuthorization: {
					...cardAuthorizationData,
					...sameData,
					responseCode: '00',
					holderAmount: value.amountToBeReversed * 100,
					totalHolderAmount: value.amountToBeReversed * 100,
					billingAmount: value.amountToBeReversed * 100,
					settlementAmount: cardPayment.billingAmount * 100,
					authorizeType: 'R0',
					transactionStatusCode: 'A',
				},
			};
			const reversalData = {
				...allData,
				requestType: ['CardAuthorization'],
				cardSettlement: null,
				cardAuthorization: {
					...cardAuthorizationData,
					...sameData,
					responseCode: '00',
					holderAmount: cardPayment.billingAmount * 100,
					totalHolderAmount: cardPayment.billingAmount * 100,
					billingAmount: cardPayment.billingAmount * 100,
					settlementAmount: cardPayment.billingAmount * 100,
					authorizeType: 'R0',
					transactionStatusCode: 'A',
				},
			};
			const data = {
				create: createData,
				settle: settlementData,
				revers: reversalData,
				partialRevers: partialReversalData,
				repeat: createData,
			};

			setWarningModal(false);
			const simulateData = await simulateCardPaymentAction(data[type]);
			setSubmitted(!submitted);
			handleOnSuccess(simulateData.payload.data);
		} catch (error) {
			setSuccess(false);
			handleOnError(error);
		}
	};

	const continueEdit = (value: Value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect the user\'s card.',
			secondPart: 'It is advised to proceed simulation with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => simulate(value), text: type },
		]);
	};

	const handleOpenModal = async (typeData: string, paymentId) => {
		if (paymentId) {
			const paymentRes = await getCardPaymentAction(applicationId, paymentId);
			setCardPayment(paymentRes.payload.data);
		} else {
			setCardPayment({});
		}
		setSuccess(false);
		setType(typeData);
		setEdit(true);
	};

	const columns = [{
		title: 'date',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 176,
		render: (text: string) => (
			<div className="wrap">
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'card payment id',
		dataIndex: 'id',
		key: 'id',
		width: 340,
		render: (text: string) => (
			<CopyComponent
				text={text}
				content={(
					<Link
						to={`/application/${applicationId}/card_payments/${text}`}
					>
						{text}
					</Link>
				)}
			/>
		),
	}, {
		title: 'auth type',
		dataIndex: 'status',
		key: 'status',
		width: 176,
		render: (text: string) => (
			<Tag status={text} />
		),
	}, {
		title: 'response code',
		dataIndex: 'responseCode',
		key: 'responseCode',
		width: 272,
		render: (text: string) => (
			<div className="wrap">
				{text}
			</div>
		),
	}, {
		title: 'transaction type',
		dataIndex: 'transactionType.name',
		key: 'transactionType',
		width: 208,
		render: (text: string) => (
			<div className="wrap">
				{text}
			</div>
		),
	}, {
		title: 'entry mode type',
		dataIndex: 'entryMode.name',
		key: 'entryModeType',
		width: 208,
		render: (text: string) => (
			<div className="wrap">
				{text}
			</div>
		),
	}, {
		title: 'scheme',
		dataIndex: 'scheme',
		key: 'scheme',
		width: 208,
		render: (text: string) => (
			<div className="wrap">
				{text || '-'}
			</div>
		),
	}, {
		title: 'card UUID',
		dataIndex: 'cardId',
		key: 'cardId',
		width: 340,
		render: (text: string) => (
			<CopyComponent
				text={text}
				content={(
					<Link to={`/application/${applicationId}/cards/${text}`}>
						{text}
					</Link>
				)}
			/>
		),
	}, {
		title: 'transaction amount',
		dataIndex: 'originalAmount',
		key: 'originalAmount',
		width: 192,
		render: (text: string, record: Payment) => (
			<div>
				{PriceHelpers.formatAmount(
					PriceHelpers.formatNumber(text.toString(), record.originalCurrency.digits),
					record.originalCurrency.isoCode,
					Numbers.sign(text.toString()),
				)}
			</div>
		),
	}, {
		title: 'billing amount',
		dataIndex: 'billingAmount',
		key: 'billingAmount',
		width: 160,
		render: (text: string, record: Payment) => (
			<div>
				{PriceHelpers.formatAmount(
					PriceHelpers.formatNumber(text.toString(), record.billingCurrency.digits),
					record.billingCurrency.isoCode,
					Numbers.sign(text.toString()),
				)}
			</div>
		),
	}, {
		title: 'mcc',
		dataIndex: 'mcc',
		key: 'mcc',
		width: 340,
		render: (text: {[string]: string}) => (
			<div className="wrap">
				{`${text.code} - ${text.description}`}
			</div>
		),
	}, {
		title: 'actions',
		dataIndex: 'id',
		key: 'actions',
		width: 192,
		render: (text: string, record: Payment) => (
			<div className={styles.simulationsIcon}>
				<Tooltip title="View card Payment">
					<span>
						<Link to={`/application/${applicationId}/cards/card_payment/${text}`}>
							<Icon name="View" />
						</Link>
					</span>
				</Tooltip>
				{(record.status === 'Authorized')
					&& (
						<Tooltip title="Settle Card payment">
							<span>
								<Icon name="Checkmark" onClick={() => handleOpenModal('settle', text)} />
							</span>
						</Tooltip>
					)}
				<Tooltip title="Repeat Card Payment">
					<span>
						<Icon name="Duplicate" onClick={() => handleOpenModal('repeat', text)} />
					</span>
				</Tooltip>
			</div>
		),
	}];

	const simulationsButton = process.env.REACT_APP_ENV === 'sandbox'
		? [{
			action: () => handleOpenModal('create', ''),
			text: 'simulate payment',
			icon: 'ArrowUp',
			disabled: !!errorMessage,
		}]
		: [];

	return (
		<>

			<div ref={filterRef}>
				<Title
					title="Card Payment Simulations"
					buttons={simulationsButton}
					place="cardSimulations"
				/>
			</div>
			<div className="container">
				{errorMessage
					? (
						<div className="empty-state">
							<UserNoChecks />
							<p>{errorMessage}</p>
						</div>
					)
					: (
						<CustomTable
							columns={columns}
							data={cardPayments}
							total={cardPaymentsTotal}
							currentPage={page}
							numberOnPage={pageSize}
							headerHeight={35}
							handlePageChange={handlePageChange}
							place="cardPaymentsSimulations"
							applicationId={applicationId}
						/>
					)}
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					success={success}
				/>
				<FormModal
					title={`${type} card payment`}
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<SimulationForm
							payment={cardPayment}
							submitChanges={continueEdit}
							handleCancelClick={handleEditCancel}
							wrappedComponentRef={formRef}
							type={type}
							entryModes={entryModes}
							transactionTypes={transactionTypes}
							merchantCategoryCodes={merchantCategoryCodes}
							cardCurrencies={cardCurrencies}
						/>
					)}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	cardPayments: state.cards.cardPayments,
	isFetchingCardPayments: state.cards.isFetchingCardPayments,
	entryModes: state.cards.entryModes,
	transactionTypes: state.cards.transactionTypes,
	merchantCategoryCodes: state.cards.merchantCategoryCodes,
	cardCurrencies: state.cards.cardCurrencies,
	cardPaymentsTotal: state.cards.cardPaymentsTotal,
	cardPaymentsSimulationsPageSize: state.cards.cardPaymentsSimulationsPageSize,
});

const mapDispatchToProps = {
	getCardPayments,
	getCardPayment,
	simulateCardPayment,
	getEntryMode,
	getTransactionTypes,
	getMerchantCategoryCodes,
	getCurrencies,
	getCard,
	setCurrentPage,
	setCurrentPageSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentsSimulations);
