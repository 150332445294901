// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../../components/UiElements/Title';
import RegexHelpers from '../../../lib/helpers/regexHelpers';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import CardPaymentsList from '../components/CardPaymentsList';
//= import actions
import { getCardPayments } from '../../../modules/actions/CardAction';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Payment } from '../../../modules/reducers/CardsReducer';
import { setCurrentPage, setCurrentPageSize } from '../../../modules/actions/UiActions';

type Props = {
	getCardPayments: (applicationId: string, pageSize: number, page: number) => void,
	cardPayments: Array<Payment>,
	cardPaymentsPageSize: number,
	queryFilters: {[string]: Array<string>},
	cardPaymentsTotal: number,
	currentPage: number,
	setCurrentPage: (string, number) => void,
	isFetchingCardPayments: boolean,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}

function CardPayments(props: Props) {
	const {
		getCardPayments: getCardPaymentsAction,
		setCurrentPage: setCurrentPageAction,
		match: { params: { applicationId } },
		cardPayments,
		cardPaymentsTotal,
		cardPaymentsPageSize,
		isFetchingCardPayments,
		currentPage,
		queryFilters,
	} = props;

	const [pageSize, setPageSize] = useState(cardPaymentsPageSize);

	const handlePageChange = async (page: number, pageSize: number) => {
		await getCardPaymentsAction(applicationId, pageSize, page);
		setCurrentPageAction('cardPaymentsPage', page);
		setCurrentPageSize('cardPaymentsPageSize', pageSize);
		setPageSize(pageSize);
	};

	const [errorMessage, setErrorMessage] = useState('');
	const [isFilterVisible, setIsFilterVisible] = useState(false);

	const queryTemplate = (filters) => {
		let string = '';
		Object.entries(filters).forEach((entry) => {
			if (!entry[1].length) return;
			const key = RegexHelpers.camelCaseToUnderscore(entry[0]);
			const value = (key === 'currency_iso_code' || key === 'country_iso_code') ? entry[1][0].toUpperCase() : entry[1][0];
			string += `${key}==${value}&`;
		});
		return string;
	};

	const filterToggle = (): void => {
		setIsFilterVisible(true);
	};

	const filterRef = useRef(null);

	useEffect(() => {
		async function getData() {
			try {
				if (Object.keys(queryFilters).length) {
					const queryString = queryTemplate(queryFilters);
					await getCardPaymentsAction(applicationId, 50, 1, queryString);
					setErrorMessage('');
				} else {
					await getCardPaymentsAction(applicationId);
					setErrorMessage('');
				}
			} catch (err) {
				const errMessage: string = err.error?.response?.data?.message;
				if (errMessage && errMessage.startsWith('Application with id')) {
					setErrorMessage("This feature is not available for your application. If you're interested in it, please contact your TradeCore representative.");
				}
			}
		}
		getData();
	}, [applicationId, getCardPaymentsAction, queryFilters]);

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="Card Payments"
					buttons={[
						{
							action: filterToggle,
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="payments"
					fields={{
						id: 'input',
						billingCurrencyIsoCode: 'input',
						originalCurrencyIsoCode: 'input',
						settlementCurrencyIsoCode: 'input',
						transLink: 'input',
						billingAmount: 'input',
						originalAmount: 'input',
						settlementAmount: 'input',
						merchantName: 'input',
						createdAt: 'input',
					}}
					visible={isFilterVisible}
					closeFunction={()=> setIsFilterVisible(false)}
					filter
				/>
			</div>
			<div className="container">
				{errorMessage
					? (
						<div className="empty-state">
							<UserNoChecks />
							<p>{errorMessage}</p>
						</div>
					)
					: (
						<CardPaymentsList
							applicationId={applicationId}
							cardPayments={cardPayments}
							totalCardPayments={cardPaymentsTotal}
							isFetchingCardPayments={isFetchingCardPayments}
							place="appCardPayments"
							pageSize={pageSize}
							handlePageChange={handlePageChange}
							currentPage={currentPage}
						/>
					)}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	cardPayments: state.cards.cardPayments,
	cardPaymentsTotal: state.cards.cardPaymentsTotal,
	isFetchingCardPayments: state.cards.isFetchingCardPayments,
	currentPage: state.ui.cardPaymentsPage,
	queryFilters: state.ui.paymentsFilters,
	cardPaymentsPageSize: state.ui.appCardPaymentsPageSize,
});
const mapDispatchToProps = {
	getCardPayments,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPayments);
