import { createSelector } from 'reselect';
import { fullUserMappedSelector } from './UsersSelector';

const activitySelector = state => state.logs.activityLog;

// eslint-disable-next-line
export const activityMappedSelector = createSelector(
	fullUserMappedSelector,
	activitySelector,
	(fullUser, activities) => activities.map(activity => ({
		...activity,
		performerName: activity.performerId === fullUser.id ? fullUser.name : 'Admin',
	})),
);
