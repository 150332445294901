// @flow

import React from 'react';
import axios from 'axios';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Icon from '../../../components/UiElements/Icon';
import Select from '../../../components/UiElements/Select';
import Switch from '../../../components/UiElements/Switch';
import Upload from '../../../components/UiElements/Upload';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= Import Types
import type { FormProps } from '../../../modules/types/FormTypes';
import type { Application } from '../../../modules/reducers/ApplicationReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	form: FormProps,
	submitChanges: (Object) => void,
	handleCancelClick: () => void,
	data: Application,
	userId: string,
}
type LocalState = {
	errorMessage: string,
	isTwoSided: boolean,
	frontFiles: Array<Object>,
	backFiles: Array<Object>,
	loading: boolean,
}
class UploadDocument extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		isTwoSided: false,
		loading: false,
		frontFiles: [],
		backFiles: [],
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			isTwoSided: false,
			loading: false,
			frontFiles: [],
			backFiles: [],
		});
	}

	handleChange = (value: boolean) => {
		this.setState({
			isTwoSided: value,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges, userId } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (!err) {
				const { frontFiles, backFiles, isTwoSided } = this.state;

				const oAuthData = localStorage.getItem('@Auth:auth');
				const accessToken: ?string = oAuthData ? JSON.parse(oAuthData).accessToken : undefined;
				const nameData = localStorage.getItem('@Auth:appName');
				const config = {
					headers: {
						Authorization: accessToken ? `Bearer ${accessToken}` : '',
						'X-Tradecore-App-Name': nameData,
					},
				};

				this.setState({
					loading: true,
				});

				if (isTwoSided) {
					const formData = new FormData();
					frontFiles.forEach((file) => {
						formData.append('file', file);
					});
					formData.append('documentType', values.documentType);
					formData.append('documentSide', values.frontSide || 'front');
					formData.append('documentTwoSided', values.documentTwoSided);

					const backFormData = new FormData();
					backFiles.forEach((file) => {
						backFormData.append('file', file);
					});
					backFormData.append('documentType', values.documentType);
					backFormData.append('documentSide', values.backSide);
					backFormData.append('documentTwoSided', values.documentTwoSided);
					Promise.all([
						axios.post(
							process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1/users/${userId}/kyc-documents/internal/proofOfAddress` : '',
							formData,
							config,
						),
						axios.post(
							process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1/users/${userId}/kyc-documents/internal/proofOfAddress` : '',
							backFormData,
							config,
						)])
						.then(() => { submitChanges('success'); })
						.catch((error) => { submitChanges(error); });
				} else {
					const formData = new FormData();
					frontFiles.forEach((file) => {
						formData.append('file', file);
					});
					formData.append('documentType', values.documentType);
					formData.append('documentSide', values.frontSide || 'front');
					formData.append('documentTwoSided', values.documentTwoSided);

					axios.post(
						process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1/users/${userId}/kyc-documents/internal/proofOfAddress` : '',
						formData,
						config,
					)
						.then(() => { submitChanges('success'); })
						.catch((error) => { submitChanges(error); });
				}
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
		} = this.props;
		const {
			errorMessage, isTwoSided, loading, frontFiles, backFiles,
		} = this.state;
		const FormItem = Form.Item;
		const { Option } = Select;
		const formLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const frontProps = {
			onRemove: (file) => {
				this.setState((state) => {
					const index = state.frontFiles.indexOf(file);
					const newFileList = state.frontFiles.slice();
					newFileList.splice(index, 1);
					return {
						frontFiles: newFileList,
					};
				});
			},
			beforeUpload: (file) => {
				if (file.size > 1300000) {
					this.setState({
						errorMessage: 'Selected image is too large, please choose a different one',
					});
					return false;
				}
				if (
					file.type !== 'image/jpeg'
					&& file.type !== 'image/png'
					&& file.type !== 'application/pdf'
					&& file.type !== 'image/gif'
				) {
					this.setState({
						errorMessage: 'Upload Image in a PNG, JPG, GIF or PDF format',
					});
					return false;
				}
				if (frontFiles.length > 0) {
					this.setState({
						errorMessage: 'You have already added the file, upload this one and then add a new one',
					});
					return false;
				}
				this.setState((state) => ({
					frontFiles: [...state.frontFiles, file],
					errorMessage: '',
				}));
				return false;
			},
			fileList: frontFiles,
		};
		const backProps = {
			onRemove: (file) => {
				this.setState((state) => {
					const index = state.backFiles.indexOf(file);
					const newFileList = state.backFiles.slice();
					newFileList.splice(index, 1);
					return {
						backFiles: newFileList,
					};
				});
			},
			beforeUpload: (file) => {
				if (file.size > 1300000) {
					this.setState({
						errorMessage: 'Selected image is too large, please choose a different one',
					});
					return false;
				}
				if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
					this.setState({
						errorMessage: 'Upload Image in a PNG, JPG or GIF format',
					});
					return false;
				}
				if (backFiles.length > 0) {
					this.setState({
						errorMessage: 'You have already added the file, upload this one and then add a new one',
					});
					return false;
				}
				this.setState((state) => ({
					backFiles: [...state.backFiles, file],
					errorMessage: '',
				}));
				return false;
			},
			fileList: backFiles,
		};
		const { Dragger } = Upload;
		return (
			<Form onSubmit={this.submit} layout="horizontal" labelalign="left" className="details-form" hideRequiredMark>
				<FormItem label="DOCUMENT TYPE" {...formLayout}>
					{getFieldDecorator('documentType', {
						initialValue: StatusHelpers.documentTypes[0],
						rules: [{
							required: true,
							message: 'Please select Document Type!',
						}],
					})(
						<Select
							showSearch
							placeholder="Select Document Type"
						>
							{StatusHelpers.documentTypes.map((el) => (
								<Option
									value={el}
									key={el}
								>
									{el}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="IS TWO SIDED" {...formLayout}>
					{getFieldDecorator('documentTwoSided', {
						initialValue: isTwoSided,
					})(
						<Switch
							defaultChecked={isTwoSided}
							checkedChildren="Yes"
							unCheckedChildren="No"
							onChange={this.handleChange}
						/>,
					)}
				</FormItem>
				{isTwoSided && (
					<FormItem label="DOCUMENT SIDE" {...formLayout}>
						{getFieldDecorator('frontSide', {
							initialValue: StatusHelpers.documentSides[0],
							rules: [{
								required: true,
								message: 'Please select Document Side!',
							}],
						})(
							<Select
								showSearch
								placeholder="Select Document Side"
							>
								<Option
									value={StatusHelpers.documentSides[0]}
									key={StatusHelpers.documentSides[0]}
								>
									{StatusHelpers.documentSides[0]}
								</Option>
							</Select>,
						)}
					</FormItem>
				)}
				<FormItem>
					{getFieldDecorator('file', {
						valuePropName: 'file',
					})(
						<Dragger {...frontProps}>
							<div className={styles.uploadIcon}>
								<Icon name="Upload" />
							</div>
							<p className="ant-upload-text">Upload your document</p>
							<p className="ant-upload-hint">
								Drop your files here or Browser.
							</p>
						</Dragger>,
					)}
				</FormItem>
				{isTwoSided && (
					<>
						<FormItem label="DOCUMENT SIDE" {...formLayout}>
							{getFieldDecorator('backSide', {
								initialValue: StatusHelpers.documentSides[1],
								rules: [{
									required: true,
									message: 'Please select Document Side!',
								}],
							})(
								<Select
									showSearch
									placeholder="Select Document Side"
								>
									<Option
										value={StatusHelpers.documentSides[1]}
										key={StatusHelpers.documentSides[1]}
									>
										{StatusHelpers.documentSides[1]}
									</Option>
								</Select>,
							)}
						</FormItem>
						<FormItem>
							{getFieldDecorator('file', {
								valuePropName: 'file',
							})(
								<Dragger {...backProps}>
									<div className={styles.uploadIcon}>
										<Icon name="Upload" />
									</div>
									<p className="ant-upload-text">Upload your document</p>
									<p className="ant-upload-hint">
										Drop your files here or Browser.
									</p>
								</Dragger>,
							)}
						</FormItem>
					</>
				)}
				{errorMessage && (
					<div className="upload-error">
						{errorMessage}
					</div>
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="discard" />
					</FormItem>
					<FormItem>
						<CPButton
							type="primary"
							action={this.submit}
							disabled={
								isTwoSided
									? frontFiles.length !== 1 || backFiles.length !== 1
									: frontFiles.length !== 1
							}
							text="upload document"
							icon="Upload"
							loading={loading}
						/>
					</FormItem>
				</div>
			</Form>
		);
	}
}

const UploadForm = Form.create()(UploadDocument);

export default UploadForm;
