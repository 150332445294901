// @flow

import {
	CURRENT_PAGE,
	CURRENT_PAGE_SIZE,
	CURRENT_FILTERS,
	ACTIVE_TAB,
	MENU_COLLAPSED,
	CLEAR_FILTERS,
	CURRENT_SEARCH,
	CURRENT_SORTER,
} from '../const/types';

export function setCurrentPage(place: string, page: number) {
	return (dispatch: Function): void => dispatch({
		type: CURRENT_PAGE,
		place,
		page,
	});
}

export function setCurrentPageSize(place: string, pageSize: number) {
	return (dispatch: Function): void => dispatch({
		type: CURRENT_PAGE_SIZE,
		place,
		pageSize,
	});
}

export function setCurrentFilters(place: string, filters: string) {
	return (dispatch: Function): void => dispatch({
		type: CURRENT_FILTERS,
		place,
		filters,
	});
}

export function setCurrentSorter(place: string, sortData: string) {
	return (dispatch: Function): void => dispatch({
		type: CURRENT_SORTER,
		place,
		sortData,
	});
}

export function clearFilters() {
	return (dispatch: Function): void => dispatch({
		type: CLEAR_FILTERS,
	});
}

export function setActiveTab(place: string, tab: string) {
	return (dispatch: Function): void => dispatch({
		type: ACTIVE_TAB,
		place,
		tab,
	});
}

export function setMenuState(collapsed: boolean) {
	return (dispatch: Function): void => dispatch({
		type: MENU_COLLAPSED,
		collapsed,
	});
}

export function setCurrentSearch(place: string, search: string) {
	return (dispatch: Function): void => dispatch({
		type: CURRENT_SEARCH,
		place,
		search,
	});
}