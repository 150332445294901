// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Switch from '../../../components/UiElements/Switch';
import Select from '../../../components/UiElements/Select';
import Row from '../../../components/UiElements/Layout/Row';
import Popover from '../../../components/UiElements/Popover';
import Icon from '../../../components/UiElements/Icon';
import Checkbox from '../../../components/UiElements/Checkbox';
import Spin from '../../../components/UiElements/Spin';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { getGroups } from '../../../modules/actions/GroupAction';
import { getApplicationSettings } from '../../../modules/actions/ApplicationActions';
// = import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
// = import types
import type { UserSettings } from '../../../modules/reducers/UsersReducer';
import type { ApplicationSettings } from '../../../modules/reducers/ApplicationReducer';
import type { Group } from '../../../modules/reducers/GroupReducer';
import type { AccountMarginWithCurrency } from '../../../modules/selectors/MarginSelector';
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';
import StatusHelpers from '../../../lib/helpers/statusHelpers';

type Values = { [string]: string }
type Props = {
	form: *,
	submitChanges: (Object, Object, Object, Object) => void,
	handleCancelClick: () => void,
	userSettings: UserSettings,
	accountMargin: AccountMarginWithCurrency,
	groups: Array<Group>,
	group: string,
	part: string,
	instruments: Array<Instrument>,
	providers: Array<ProviderInstance>,
	lowestRank: number,
	applicationId: string,
	getProvidersInstances: (string) => void,
	getGroups: (string) => void,
	getApplicationSettings: (string) => void,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	getAppSymbols: (string, string, number, string) => Promise<Object>,
	appSettings: ApplicationSettings,
	accountId: string,
}
type LocalState = {
	marginEnabled: boolean,
	calculatedLoanLimit: string,
	instrument: {
			id: string;
			type: string;
			precision: number,
			instrumentId: string,
			formatting: {
				grapheme: string;
		};
	},
	feeType: string,
	selectedInstrument: boolean,
	accrualSelected: string,
	compoundRequired: boolean,
	errorMessage: string,
	symbolSelected: boolean,
	resetToApp: boolean,
	instrumentData: Array<Instrument>,
	instrumentFetching: boolean,
	symbolData: Array<Instrument>,
	symbolFetching: boolean,
	notFoundContent: string,
	fiatInstruments: Array<Instrument>,
}

class Settings extends React.Component<Props, LocalState> {
	state = {
		marginEnabled: this.props.userSettings.marginTrading,
		calculatedLoanLimit: this.props.accountMargin.loanLimit || '0',
		accrualSelected: this.props.userSettings.accrualPeriod,
		compoundRequired: false,
		instrument: {},
		feeType: 'deposit',
		selectedInstrument: false,
		errorMessage: '',
		symbolSelected: false,
		resetToApp: false,
		instrumentData: [],
		instrumentFetching: false,
		symbolData: [],
		symbolFetching: false,
		notFoundContent: '',
		fiatInstruments: [],
	}

	async componentDidMount() {
		const {
			getProvidersInstances: getProvidersInstancesAction,
			getGroups: getGroupsAction,
			getApplicationSettings: getApplicationSettingsAction,
			getAppInstruments: getAppInstrumentsAction,
			applicationId,
			providers,
			part,
			groups,
			appSettings,
			userSettings,
		} = this.props;
		if (part === 'fee' && !providers.length) {
			getProvidersInstancesAction(applicationId);
		}
		if (part === 'group' && !groups.length) {
			getGroupsAction(applicationId);
		}
		if (part === 'margin' && !appSettings.accrualPeriod) {
			getApplicationSettingsAction(applicationId);
		}
		if (part === 'post-trade settlement') {
			const filterOptions: string = queryString.stringify({ type: 'fiat' });
			const res = await getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
			const initialInstrument = res.payload.data
				.find((el) => el.instrumentId === userSettings.maximumRiskExposureInstrument);
			this.setState({
				fiatInstruments: res.payload.data,
				instrument: initialInstrument,
			});
		}
	}

	handleChange = (value: boolean) => {
		this.setState({
			marginEnabled: value,
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			marginEnabled: this.props.userSettings.marginTrading,
			calculatedLoanLimit: this.props.accountMargin.loanLimit || '0',
			accrualSelected: this.props.userSettings.accrualPeriod,
			compoundRequired: false,
			instrument: {},
			feeType: 'deposit',
			selectedInstrument: false,
			errorMessage: '',
			resetToApp: false,
		});
	}

	handleInstrumentSearch = debounce((instrumentValue: string) => {
		const { getAppInstruments: getAppInstrumentsAction, applicationId } = this.props;
		if (instrumentValue && instrumentValue.match(StatusHelpers.validInstrument)) {
			this.setState({ instrumentFetching: true });
			const idsFilter = instrumentValue?.toUpperCase();
			const filterOptions: string = queryString.stringify({ ids: idsFilter });
			getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							instrumentData: data,
							instrumentFetching: false,
						});
					} else {
						this.setState({
							instrumentData: [],
							instrumentFetching: false,
							notFoundContent: `Instrument with id ${instrumentValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						instrumentFetching: false,
						instrumentData: [],
						notFoundContent: 'Instrument not found',
					});
				});
		} else {
			this.setState({
				instrumentFetching: false,
				instrumentData: [],
				notFoundContent: 'Enter valid Id to find the Instrument',
			});
		}
	}, 500);

	handleSymbolSearch = debounce((symbolValue: string) => {
		const { getAppSymbols: getAppSymbolsAction, applicationId } = this.props;
		this.setState({ symbolFetching: true });
		if (symbolValue) {
			const filterOptions: string = queryString.stringify({ search: symbolValue });
			getAppSymbolsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							symbolData: data,
							symbolFetching: false,
						});
					} else {
						this.setState({
							symbolData: [],
							symbolFetching: false,
							notFoundContent: `Symbol with id ${symbolValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						symbolFetching: false,
						symbolData: [],
						notFoundContent: 'Symbol not found',
					});
				});
		} else {
			this.setState({
				symbolFetching: false,
				symbolData: [],
				notFoundContent: 'Enter Id to find the Symbol',
			});
		}
	}, 500);

	selectAccrual = (value: string) => {
		const { getFieldValue, setFieldsValue } = this.props.form;
		const compound: string = getFieldValue('compoundPeriod');
		if (
			StatusHelpers.accrualPeriods.indexOf(value) > StatusHelpers.accrualPeriods.indexOf(compound)
		) {
			this.setState({
				accrualSelected: value,
				compoundRequired: true,
			});
			setFieldsValue({ compoundPeriod: '' });
		}
		this.setState({
			accrualSelected: value,
		});
	}

	setLoanLimit = (value: number) => {
		this.setState({
			calculatedLoanLimit: Numbers.mul(this.props.accountMargin.marginCollateral || '0', value || '0'),
		});
	}

	selectPtsInstrument = (value: string) => {
		const { fiatInstruments } = this.state;
		if (value) {
			const selected = fiatInstruments.filter(
				(instrument) => instrument.instrumentId === value,
			)[0];
			this.setState({
				instrument: selected,
			});
		} else {
			this.setState({
				instrument: {},
			});
		}
	}

	selectInstrument = (value: string) => {
		const { instrumentData } = this.state;
		if (value) {
			const selected = instrumentData.filter(
				(instrument) => instrument.instrumentId === value,
			)[0];
			this.setState({
				selectedInstrument: true,
				instrument: selected,
				errorMessage: '',
			});
		} else {
			this.setState({
				instrument: {},
				selectedInstrument: false,
				errorMessage: 'When Instrument is not selected, you can set only Percentage Fee to All Instruments.',
				instrumentData: [],
			});
		}
	}

	selectSymbol = (value: string) => {
		if (value) {
			this.setState({
				symbolSelected: true,
			});
		} else {
			this.setState({
				symbolSelected: false,
				symbolData: [],
			});
		}
	}

	resetToApp = (e) => {
		const {
			form: { setFieldsValue },
			appSettings,
			userSettings,
		} = this.props;
		if (e.target.checked) {
			this.setState({
				resetToApp: true,
			});
			setFieldsValue({
				annualInterestRate: appSettings.annualInterestRate,
				accrualPeriod: appSettings.accrualPeriod,
				compoundPeriod: appSettings.compoundPeriod,
			});
		} else {
			this.setState({
				resetToApp: false,
			});
			setFieldsValue({
				annualInterestRate: userSettings.annualInterestRate,
				accrualPeriod: userSettings.accrualPeriod,
				compoundPeriod: userSettings.compoundPeriod,
			});
		}
	}

	setFeeType = (value: string) => {
		this.setState({
			feeType: value,
		});
	}

	validateNetFee = (rule, value, callback) => {
		const { form } = this.props;
		const netFee = form.getFieldValue('netFee');
		const percentageFee = form.getFieldValue('percentageFee');
		if (!netFee && !percentageFee) {
			callback('The Fee rule must contain at least one value: Net Fee or Percentage Fee');
		}
		callback();
	}

	validatePercentageFee = (rule, value, callback) => {
		const { form } = this.props;
		const netFee = form.getFieldValue('netFee');
		const percentageFee = form.getFieldValue('percentageFee');
		if (value > 100) {
			callback('Value has to be between 0 and 100 (%)!');
		}
		if (!netFee && !percentageFee) {
			callback('The Fee rule must contain at least one value: Net Fee or Percentage Fee');
		}
		if (parseFloat(value.toFixed(2)) !== value) {
			callback('Value must have precision less than or equal to 2');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		const { resetToApp } = this.state;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = resetToApp
					? {
						...values,
						annualInterestRate: null,
						accrualPeriod: null,
						compoundPeriod: null,
					} : values;
				submitChanges(data);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			userSettings,
			accountMargin,
			groups,
			group,
			part,
			providers,
		} = this.props;

		const {
			marginEnabled,
			accrualSelected,
			compoundRequired,
			calculatedLoanLimit,
			feeType,
			selectedInstrument,
			errorMessage,
			instrument,
			symbolSelected,
			resetToApp,
			instrumentData,
			instrumentFetching,
			symbolData,
			symbolFetching,
			notFoundContent,
			fiatInstruments,
		} = this.state;

		const currentGroup = groups.find((el) => el.id === group);
		const FormItem = Form.Item;
		const { Option } = Select;
		const paymentProviders = providers.filter((el) => el.provider.type === 'payment');
		const tradingProvider = providers.filter((el) => el.provider.type === 'market');

		const formItemLayout = {
			labelCol: {
				xs: { span: 13 },
			},
			wrapperCol: {
				xs: { span: 11 },
			},
		};
		const feeLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const numberLayout = {
			labelCol: {
				xs: { span: 11 },
			},
			wrapperCol: {
				xs: { span: 13 },
			},
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className={styles.settings}>

				{part === 'group' && (
					<FormItem label="TRANSFER TO GROUP" {...formItemLayout} className={styles.formItem}>
						{getFieldDecorator('groupId', {
							initialValue: currentGroup ? currentGroup.id : '',
							rules: [{
								required: true,
								message: 'Please select Group!',
							}],
						})(
							<Select
								allowClear
								showSearch
								placeholder="Select where to Transfer"
								className={styles.formItemSelect}
							>
								{groups.map((el) => (
									<Option
										value={el.id}
										key={el.id}
									>
										{el.name}
									</Option>
								))}
							</Select>,
						)}
					</FormItem>
				)}

				{part === 'margin'
					&& (
						<>
							<h4 className="subtitle">
								Margin trading
							</h4>
							<FormItem label="ENABLE MARGIN" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('marginTrading', {
									initialValue: userSettings.marginTrading,
								})(
									<Switch
										defaultChecked={marginEnabled}
										checkedChildren="Yes"
										unCheckedChildren="No"
										onChange={this.handleChange}
									/>,
								)}
							</FormItem>
							<FormItem label="MULTIPLIER" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('collateralMultiplier', {
									initialValue: userSettings.collateralMultiplier,
									rules: [
										{ validator: (rule, value, cb) => (value > 10 ? cb('Value has to be between 0 and 10!') : cb()) },
									],
								})(
									<InputNumber
										disabled={!marginEnabled}
										onChange={this.setLoanLimit}
										placeholder="Set Multiplier"
									/>,
								)}
							</FormItem>
							<FormItem label="MARGIN COLLATERAL AMOUNT" {...formItemLayout} className={styles.formItem}>
								<div className={styles.text}>
									{PriceHelpers.formatAmount(
										accountMargin.marginCollateral,
										accountMargin.accountCurrency,
									)}
								</div>
							</FormItem>
							<FormItem label="CALCULATED LOAN LIMIT" {...formItemLayout} className={styles.formItem}>
								<div className={styles.text}>
									{calculatedLoanLimit
										? PriceHelpers.formatAmount(calculatedLoanLimit, accountMargin.accountCurrency)
										: '0'}
								</div>
							</FormItem>
							<FormItem label="ANNUAL INTEREST RATE" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('annualInterestRate', {
									initialValue: userSettings.annualInterestRate,
									rules: [
										{ validator: (rule, value, cb) => (value > 100 ? cb('Value has to be between 0 and 100 (%)!') : cb()) },
									],
								})(
									<InputNumber
										symbol="%"
										placeholder="Set Annual Interest Rate"
										disabled={resetToApp}
									/>,
								)}
							</FormItem>
							<FormItem label="ACCRUAL PERIOD" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('accrualPeriod', {
									initialValue: userSettings.accrualPeriod,
								})(
									<Select
										showSearch
										placeholder="Select Accrual Period"
										onChange={this.selectAccrual}
										disabled={resetToApp}
										className={styles.formItemSelect}
									>
										{StatusHelpers.accrualPeriods.map((el) => (
											<Option
												value={el}
												key={el}
												className="capitalize"
											>
												{el}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="COMPOUND PERIOD" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('compoundPeriod', {
									initialValue: userSettings.compoundPeriod,
									rules: [{
										required: compoundRequired,
										message: 'Compound Period is required (Compound period can not be lesser than the Accrual period)!',
									}],
								})(
									<Select
										showSearch
										placeholder="Select Compound Period"
										disabled={resetToApp}
										className={styles.formItemSelect}
									>
										{StatusHelpers.compundPriods[accrualSelected || userSettings.accrualPeriod]
											.map((el) => (
												<Option
													value={el}
													key={el}
													className="capitalize"
												>
													{el}
												</Option>
											))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="RESET TO APP SETTINGS" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('resetToApp', {
									valuePropName: 'checked',
								})(
									<Checkbox onChange={this.resetToApp} />,
								)}
							</FormItem>
						</>
					)}
				{part === 'post-trade settlement'
					&& (
						<>
							<h4 className="subtitle">
								Post-Trade Settlement
							</h4>
							<FormItem label="ENABLE POST-TRADE SETTLEMENT" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('postTradeSettlement')(
									<Switch
										defaultChecked={userSettings.postTradeSettlement}
										checkedChildren="Yes"
										unCheckedChildren="No"
										onChange={this.handleChange}
									/>,
								)}
							</FormItem>
							<FormItem label="Maximum Risk Exposure Instrument" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('maximumRiskExposureInstrument', {
									initialValue: userSettings.maximumRiskExposureInstrument,
									rules: [{
										message: 'Please select Instrument!',
									}],
								})(
									<Select
										allowClear
										showSearch
										placeholder="Please select Instrument"
										onChange={this.selectPtsInstrument}
										className={styles.formItemSelect}
									>
										{fiatInstruments.map((el) => (
											<Option
												value={el.instrumentId}
												key={el.id}
											>
												{el.name}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="Maximum Risk Exposure Amount" {...formItemLayout} className={styles.formItem}>
								{getFieldDecorator('maximumRiskExposureAmount', {
									initialValue: userSettings.maximumRiskExposureAmount,
								})(
									<InputNumber
										placeholder="Set Maximum Risk Exposure Amount"
										symbol={instrument?.formatting?.grapheme}
									/>,
								)}
							</FormItem>
						</>
					)}

				{part === 'fee' && (
					<>
						<FormItem label="FEE TYPE" {...feeLayout} className={styles.formItem}>
							{getFieldDecorator('type', { initialValue: 'deposit' })(
								<Select
									placeholder="Set Fee Type"
									onChange={this.setFeeType}
									className={styles.formItemSelect}
								>
									{StatusHelpers.feeType.map((fee) => (
										<Option
											value={fee}
											key={fee}
										>
											{fee}
										</Option>
									))}
								</Select>,
							)}
						</FormItem>
						<>
							{feeType !== 'cardFx' && (
								<>
									<h4 className="subtitle capitalize">
										{`${feeType} Fee Parameters`}
									</h4>
									{feeType === 'trading'
										? (
											<div>
												<FormItem label="LIQUIDITY PROVIDER" {...feeLayout} className={styles.formItem}>
													{getFieldDecorator('providerInstanceId')(
														<Select
															allowClear
															showSearch
															placeholder="All Liquidity Providers"
															className={styles.formItemSelect}
														>
															{tradingProvider.map((provider) => (
																<Option
																	value={provider.id}
																	key={provider.id}
																>
																	{provider.provider.displayName}
																</Option>
															))}
														</Select>,
													)}
												</FormItem>
												<FormItem label="INSTRUMENT" {...numberLayout} className={styles.formItem}>
													<div className="fee-info">
														<Popover
															content="Fee is set on a Symbol level. Selecting an Instrument will apply fee rule to all Symbols for selected instrument."
															overlayClassName="button-popover"
														>
															<div className="info-icon">
																<Icon name="Info" />
															</div>
														</Popover>
														{getFieldDecorator('instrumentId')(
															<Select
																allowClear
																showSearch
																placeholder={symbolSelected ? "Symbol's Quoting Instrument" : 'All Instruments'}
																disabled={symbolSelected}
																notFoundContent={instrumentFetching
																	? <Spin size={16} />
																	: notFoundContent}
																filterOption={false}
																onSearch={this.handleInstrumentSearch}
																onChange={this.selectInstrument}
																className={styles.formItemSelect}
															>
																{instrumentData.map((el) => (
																	<Option
																		value={el.instrumentId}
																		key={el.instrumentId}
																	>
																		{el.instrumentId}
																	</Option>
																))}
															</Select>,
														)}
													</div>
												</FormItem>
												<FormItem label="SYMBOL" {...feeLayout} className={styles.formItem}>
													{getFieldDecorator('symbolId')(
														<Select
															allowClear
															showSearch
															placeholder="All Symbols"
															disabled={selectedInstrument}
															notFoundContent={symbolFetching
																? <Spin size={16} />
																: notFoundContent}
															filterOption={false}
															onSearch={this.handleSymbolSearch}
															onChange={this.selectSymbol}
															className={styles.formItemSelect}
														>
															{symbolData.map((el) => (
																<Option
																	value={el.id}
																	key={el.id}
																>
																	{el.id}
																</Option>
															))}
														</Select>,
													)}
												</FormItem>
												<FormItem label="ORDER TYPE" {...feeLayout} className={styles.formItem}>
													{getFieldDecorator('orderType')(
														<Select
															allowClear
															showSearch
															placeholder="All Order Types"
															className={styles.formItemSelect}
														>
															{StatusHelpers.orderTypes.map((el) => (
																<Option value={el.id} key={el.id}>
																	{el.name}
																</Option>
															))}
														</Select>,
													)}
												</FormItem>
												<FormItem label="ORDER SIDE" {...feeLayout} className={styles.formItem}>
													{getFieldDecorator('orderSide')(
														<Select
															allowClear
															showSearch
															placeholder="All Order Sides"
															className={styles.formItemSelect}
														>
															<Option
																value="buy"
																key="buy"
															>
																Buy
															</Option>
															<Option
																value="sell"
																key="sell"
															>
																Sell
															</Option>
														</Select>,
													)}
												</FormItem>
											</div>
										)
										: (
											<div className="fee-field-right">
												<FormItem label="PAYMENT PROVIDER" {...feeLayout} className={styles.formItem}>
													{getFieldDecorator('providerInstanceId')(
														<Select
															allowClear
															showSearch
															placeholder="All Payment Providers"
															className={styles.formItemSelect}
														>
															{paymentProviders.map((provider) => (
																<Option
																	value={provider.id}
																	key={provider.id}
																>
																	{provider.provider.displayName}
																</Option>
															))}
														</Select>,
													)}
												</FormItem>
												<FormItem label="INSTRUMENT" {...feeLayout} className={styles.formItem}>
													{getFieldDecorator('instrumentId')(
														<Select
															allowClear
															showSearch
															placeholder="All Instruments"
															notFoundContent={instrumentFetching
																? <Spin size={16} />
																: notFoundContent}
															filterOption={false}
															onSearch={this.handleInstrumentSearch}
															onChange={this.selectInstrument}
															className={styles.formItemSelect}
														>
															{instrumentData.map((el) => (
																<Option
																	value={el.instrumentId}
																	key={el.instrumentId}
																>
																	{el.instrumentId}
																</Option>
															))}
														</Select>,
													)}
												</FormItem>
											</div>
										)}
								</>
							)}
							<h4 className="subtitle capitalize">
								{`${feeType} Fee Rules`}
							</h4>
							{feeType === 'trading' || feeType === 'cardFx'
								? (

									<FormItem label="PERCENTAGE FEE" {...feeLayout} className="form-item-small form-item-fee">
										{getFieldDecorator('percentageFee', {
											initialValue: 0,
											rules: [{ validator: this.validatePercentageFee }],
										})(
											<InputNumber
												max={100}
												symbol="%"
												placeholder="Set Percentage Fee"
											/>,
										)}
									</FormItem>
								)
								: (
									<Row>
										<FormItem label="PERCENTAGE FEE" {...numberLayout} className="form-item-small form-item-fee">
											{getFieldDecorator('percentageFee', {
												initialValue: 0,
												rules: [{ validator: this.validatePercentageFee }],
											})(
												<InputNumber
													max={100}
													symbol="%"
													placeholder="Set Percentage Fee"
												/>,
											)}
										</FormItem>
										<FormItem label="MINIMUM FEE" {...numberLayout} className="form-item-small form-item-fee">
											{getFieldDecorator('minimumFee', {
												initialValue: 0,
												rules: [{ validator: this.validateNetFee }],
											})(
												<InputNumber
													disabled={!selectedInstrument}
													symbol={instrument.instrumentId}
													placeholder="Set Minimum Fee"
												/>,
											)}
										</FormItem>
										<FormItem label="NET FEE" {...numberLayout} className="form-item-small form-item-fee">
											{getFieldDecorator('netFee', {
												initialValue: 0,
												rules: [{ validator: this.validateNetFee }],
											})(
												<InputNumber
													disabled={!selectedInstrument}
													symbol={instrument?.instrumentId}
													placeholder="Set Net Fee"
												/>,
											)}
										</FormItem>
										<FormItem label="MAXIMUM FEE" {...numberLayout} className="form-item-small form-item-fee">
											{getFieldDecorator('maximumFee', {
												initialValue: 0,
												rules: [{ validator: this.validateNetFee }],
											})(
												<InputNumber
													disabled={!selectedInstrument}
													symbol={instrument?.instrumentId}
													placeholder="Set Maximum Fee"
												/>,
											)}
										</FormItem>
									</Row>
								)}
							<h4 className="subtitle capitalize">
								{`${feeType} Fee Rule Rank`}
							</h4>
							<p>{`This newly created ${feeType} Fee Rule will have the highest Rank. If you wont to change Rank use drag and drop on Fee Rule Table.`}</p>
						</>
					</>
				)}
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<Form.Item className={styles.formItemButton}>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</Form.Item>
					<Form.Item className={styles.formItemButton}>
						<CPButton
							type="primary"
							action={this.submit}
							disabled={!isFieldsTouched()}
							text="save changes"
						/>
					</Form.Item>
				</div>
			</Form>
		);
	}
}

const SettingsForm = Form.create()(Settings);

const mapStateToProps = (state: State) => ({
	appSettings: state.application.applicationSettings,
	providers: state.providers.providersInstances,
	groups: state.group.groups,
});

const mapDispatchToProps = {
	getAppInstruments,
	getAppSymbols,
	getProvidersInstances,
	getGroups,
	getApplicationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
