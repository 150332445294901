// @flow

import React from 'react';
import map from 'lodash/map';
import type { Node } from 'react';
import ReactJson from 'react-json-view';
//= import components
import Tag from '../Tag';
import CPButton from '../Button';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import styles from './assets/card.module.scss';
import pdf from '../../../assets/img/pdf.svg';
import zip from '../../../assets/img/zip.svg';

type Props = {
	parts: Array<{
		title?: string,
		tag?: string,
		data?: {[string]: Node},
		button?: {
			action: ()=> void,
			text: string,
			icon: string,
		},
		photos?: Array<{[string]: Object}>,
		isIdentity?: boolean,
		documents?: Array<{[string]: Object}>,
		json?: Object,
		component?: Node,
		nested?: boolean,
		photoAction: (Object) => void,
		action: ()=> void,
	}>,
	style?: Object,
}

const DataCard = (props: Props) => {
	const {
		style, parts,
	} = props;

	const returnItem = (item: {[string]: Object}): Node => {
		if (item !== null) {
			return (
				typeof item === 'string' || typeof item === 'function' || React.isValidElement(item)
					? item
					: (
						<>
							{map(item, (value, key) => (
								<div className={styles.data} key={key}>
									<div className={styles.key}>{ActivityHelpers.transformToReadable(key || '')}</div>
									<div className={styles.value}>{value}</div>
								</div>
							))}
						</>
					)
			);
		}
		return null;
	};

	return (
		<div style={style} className={`${styles.dataCard} ${styles.card}`}>
			{parts.map((part, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<div className={styles.part} key={index}>
					{(part.title || part.tag)
					&& (
						<div className={styles.title}>
							{part.title && <h4>{part.title}</h4>}
							{part.tag && <Tag status={part.tag} />}
						</div>
					)}
					{part.data && (
						<div>
							{map(part.data, (value, key) => (
								<div className={styles.data} key={key}>
									<div className={styles.key}>{ActivityHelpers.transformToReadable(key || '')}</div>
									<div className={part.nested ? styles.nested : styles.value}>{returnItem(value)}</div>
								</div>
							))}
						</div>
					)}
					{part.button && (
						<div className={styles.button}>
							<CPButton
								type="primary"
								action={() => part.button.action()}
								icon={part.button.icon}
								text={part.button.text}
							/>
						</div>
					)}
					{part.photos && part.photos.map((document) => document.files.map((file) => (
						<img
							key={file.id}
							src={file.contentType === 'application/pdf' ? pdf : file.url}
							alt="document"
							role="presentation"
							onClick={() => part.photoAction(part.photos)}
						/>
					)))}
					<div className={styles.documents}>
						{part.isIdentity && part.documents?.map((document) => document.files.map((file) => (
							file.contentType === 'application/pdf'
								? (
									<div className={styles.document}>
										<img
											key={file.id}
											src={pdf}
											alt="document"
											role="presentation"
											onClick={() => part.photoAction(part.documents)}
										/>
										<p>
											Open PDF
										</p>
									</div>
								)
								: (
									<div className={styles.document}>
										<a href={file.url} download={file.id.substring(0, 7)}>
											<img
												key={file.id}
												src={zip}
												alt="mp3"
												role="presentation"
											/>
										</a>
										<p>
											Download ZIP
										</p>
									</div>
								)
						)))}
					</div>
					{part.json && (
						<div className={styles.json}>
							<ReactJson
								src={part.json}
								theme="shapeshifter:inverted"
								enableClipboard={false}
								style={{
									backgroundColor: '#F0F6FA',
									padding: '16px',
								}}
								collapseStringsAfterLength={36}
							/>
						</div>
					)}
					{part.component}
				</div>
			))}
		</div>
	);
};

DataCard.defaultProps = {
	style: {},
};

export default DataCard;
