// @flow

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//= import components
import CustomTable from '../../components/UiElements/CustomTable';
import Tag from '../../components/UiElements/Tag';
import Title from '../../components/UiElements/Title';
import CopyComponent from '../../components/UiElements/CopyComponent';
import KytChecksForm from './components/KytChecks';
import FormModal from '../../components/UiElements/Modal/FormModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
//= import methods
import { getApplicationPayments } from '../../modules/actions/PaymentActions';
import { scheduleKYTChecks } from '../../modules/actions/KYTActions';
import { getProvidersInstances } from '../../modules/actions/ProviderActions';
import { setCurrentPage, setCurrentSorter } from '../../modules/actions/UiActions';
import type { ProviderInstance } from '../../modules/reducers/ProviderReducer';
//= import helpers
import ProviderHelpers from '../../lib/helpers/providerHelpers';
import ActivityHelpers from '../../lib/helpers/activityHelpers';
import PriceHelpers from '../../lib/helpers/priceHelpers';
import StatusHelpers from '../../lib/helpers/statusHelpers';
//= import types
import type { Payment } from '../../modules/reducers/PaymentReducer';
import type { State } from '../../modules/types/FlowTypes';

type MethodData = {
	paymentMethodInfo: {
		type: string,
	},
	paymentMethodType: string,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}
type Props = {
	appPayments: Array<Payment>,
	isFetchingPayments: boolean,
	paymentsTotal: number,
	currentPage: number,
	sorter: Object,
	filters: { [string]: Array<string> },
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	location: {
		pathname: string,
		search: string,
	},
	getApplicationPayments: (string, number, Object, string, order: string, pageSize: number) => void,
	setCurrentPage: (string, number) => void,
	getProvidersInstances: (string) => void,
	providerInstance: Array<ProviderInstance>,
	scheduleKYTChecks: (string, Object) => Promise<Object>,
	pageSize: number,
}

function PaymentsPage(props: Props) {
	const {
		appPayments,
		sorter,
		isFetchingPayments,
		paymentsTotal,
		pageSize,
		currentPage,
		filters,
		providerInstance,
		getApplicationPayments: getApplicationPaymentsAction,
		getProvidersInstances: getProvidersInstancesAction,
		setCurrentPage: setCurrentPageAction,
		scheduleKYTChecks: scheduleKYTChecksAction,
		match: { params: { applicationId, userId } },
		location: { search },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [modal, setModal] = useState(false);
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [success, setSuccess] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [applicationProviders, setApplicationProviders] = useState([]);
	const getUniquePaymentProviders = (paymentProviders: Array<{id: string, name: string}>, key): Array<{id: string, name: string}> =>
		([...new Map(paymentProviders.map((item) => [item[key], item])).values()]);

	useEffect(() => {
		const fetchProviderInstances = async () => getProvidersInstancesAction(applicationId);
		fetchProviderInstances()
			.then((response) => {
				const instances = response?.payload?.data;
				const providers = instances
					.filter((instance) => instance.provider?.type === 'payment')
					.map((instance) => ({ id: instance?.provider?.name, name: instance.provider?.name, providerId: instance?.provider?.id }));
				const unique = getUniquePaymentProviders(providers, 'providerId');
				setApplicationProviders(unique);
			});
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		const { order, column } = sorter;
		const orderBy = column?.title;
		const asc = order === 'ascend' ? 'true' : 'false';
		if (search) {
			const params = queryString.parse(search);
			const { type, accountId } = params;
			const parameter = type
				? {
					accountId: [accountId],
					type: [type],
					status: ['pending', 'pendingNew'],
				}
				: { accountId: [accountId] };
			getApplicationPaymentsAction(applicationId, currentPage, queryString.stringify(parameter, { arrayFormat: 'comma' }), orderBy, asc, pageSize);
		} else {
			getApplicationPaymentsAction(applicationId, currentPage, queryString.stringify(filters, { arrayFormat: 'comma' }), orderBy, asc, pageSize);
		}
	}, [
		filters,
		currentPage,
		getApplicationPaymentsAction,
		pageSize,
		applicationId,
		search,
		userId,
		sorter,
	]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('appPaymentsPage', page);
	};

	const filterRef = useRef(null);
	const formRef = useRef(null);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [filters]);

	const openModal = () => {
		setModal(true);
		setSuccess(false);
	};

	const handleWarningCancel = () => {
		setModal(true);
		setWarning(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setModal(false);
		setWarning(false);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setModal(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setModal(false);
			setWarning(false);
		}
	};

	const scheduleKYT = (value) => {
		setButtonLoading(true);
		scheduleKYTChecksAction(applicationId, value)
			.then(() => {
				setButtonLoading(false);
				setSuccess(true);
				setWarningTitle('Confirmation');
				setModal(false);
				setWarning(true);
				setMessage({ firstPart: 'KYT checks successfully scheduled' });
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'close' },
				]);
			})
			.catch((error: ErrorType) => {
				setButtonLoading(false);
				setSuccess(false);
				setWarningTitle('Error!');
				setModal(false);
				setWarning(true);
				setMessage({
					firstPart: error.error && error.error.response.data ? error.error.response.data.message : 'Error',
					secondPart: error.error && error.error.response.data && error.error.response.data.errors && typeof error.error.response.data.errors !== 'string'
						? error.error.response.data.errors[0].messages[0].message
						: error.error.response.data.errors,
				});
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'close' },
				]);
			});
	};

	const scheduleOptions = {
		dateRange: {
			options: 'time',
			initialValue: filters.from && filters.to
				? [
					filters.from[0] ? moment(filters.from[0]) : filters.from[0],
					filters.to[0] ? moment(filters.to[0]) : filters.to[0],
				]
				: [],
		},
		complianceReviewStatuses: {
			options: StatusHelpers.scheduleOptions,
			initialValue: filters.complianceReview?.filter((el) => (
				StatusHelpers.scheduleOptions.includes(el)
			)),
		},
	};

	const kytProviderInstance = providerInstance.find((el) => el.provider.type === 'kyt');

	const columns = [
		{
			title: 'last updated',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => (
				<div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
			),
		},
		{
			title: 'payment id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string, record) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={userId
								? `/application/${applicationId}/clients/${record.user.id}/payments/${record.id}?accountId=${record.accountId}`
								: `/application/${applicationId}/payments/${record.id}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 208,
			render: (text: string, record: Payment) => (
				<div className="flex">
					<div className={`${text} box`} />
					<span className="capitalize primary-tag">
						{ActivityHelpers.transformToReadable(text)}
					</span>
					{!!record.recurrenceId && (
						<Tag status="recurring" />
					)}
				</div>
			),
		},
		{
			title: 'client',
			dataIndex: 'user',
			key: 'user',
			width: 260,
			render: (user: { [string]: string }) => (
				<div>
					{`${user.firstName || 'Name Not Set'} ${user.lastName || ''}`}
				</div>
			),
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 144,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
		{
			title: 'compliance review',
			dataIndex: 'complianceReview',
			key: 'complianceReview',
			width: 208,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
		{
			title: 'method',
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			width: 144,
			render: (data: MethodData) => (
				<div className="capitalize">
					{data?.paymentMethodType ? `${data?.paymentMethodInfo ? data?.paymentMethodInfo.type : ''} ${data?.paymentMethodType || ''}` : '-'}
				</div>
			),
		},
		{
			title: 'amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'left',
			sorter: true,
			showSorterTooltip: true,
			width: 208,
			render: (text: string, record: Payment) => (
				<div
					className={record.status === 'canceled' || record.status === 'rejected' ? 'disabled-color' : 'bold'}
				>
					{PriceHelpers.formatAmount(
						record.amount,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'fee',
			dataIndex: 'fee',
			key: 'fee',
			align: 'right',
			width: 192,
			render: (text: string, record: Payment) => (
				<div
					className={record.status === 'canceled' || record.status === 'rejected' ? 'disabled-color' : 'bold'}
				>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'provider name',
			dataIndex: 'providerName',
			key: 'providerName',
			width: 176,
			render: (text: string) => (
				<div>
					{ActivityHelpers.transformToReadable(text || '-')}
				</div>
			),
		},
		{
			title: 'message',
			dataIndex: 'message',
			key: 'message',
			width: 320,
			render: (text: string) => (
				<div>
					{text || '-'}
				</div>
			),
		},
	];

	return (
		<>
			{search
				? (
					<Title
						title={queryString.parse(search).type ? `pending ${queryString.parse(search).type}` : 'client payments'}
					/>
				)
				: (
					<div ref={filterRef}>
						<Title
							title="payments"
							buttons={kytProviderInstance
								? [
									{
										action: () => setFilterVisible(true),
										text: 'filter',
										icon: 'Funnel',
									},
									{
										action: () => openModal(),
										text: 'schedule kyt checks',
										icon: 'Open',
										type: 'primary',
									},
								]
								: [
									{
										action: () => setFilterVisible(true),
										text: 'filter',
										icon: 'Funnel',
									},
								]}
							applicationId={applicationId}
							place="appPayments"
							fields={{
								type: ProviderHelpers.allPaymentType,
								status: ProviderHelpers.paymentStatus,
								amount: 'numberRange',
								complianceReview: StatusHelpers.complianceReviewStatuses,
								dateRange: 'time',
								instrumentId: ProviderHelpers.instrumentIds,
								paymentId: 'input',
								paymentMethod: ProviderHelpers.paymentMethods,
								fee: 'input',
								clientId: 'input',
								providerName: applicationProviders,
								message: 'input',
								lastUpdated: 'lastUpdated',
							}}
							date
							visible={filterVisible}
							closeFunction={() => setFilterVisible(false)}
							filter
						/>
					</div>
				)}
			<div className="container">
				<>
					<CustomTable
						columns={columns}
						data={appPayments}
						total={paymentsTotal}
						handlePageChange={handlePageChange}
						numberOnPage={pageSize}
						loading={isFetchingPayments}
						currentPage={currentPage}
						headerHeight={filterHeight}
						setCurrentSorter={setCurrentSorter}
						place="appPayments"
						applicationId={applicationId}
					/>
					<FormModal
						destroyOnClose
						title="Schedule KYT Checks"
						visible={modal}
						cancelFunction={handleEditCancel}
						form={(
							<KytChecksForm
								submitFunction={scheduleKYT}
								scheduleOptions={scheduleOptions}
								cancelFunction={handleEditCancel}
							/>
						)}
					/>
					<WarningModal
						title={warningTitle}
						visible={warning}
						cancelFunction={handleErrorCancel}
						footer={footer}
						message={message}
						success={success}
						loading={buttonLoading}
					/>
				</>
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	appPayments: state.payment.appPayments,
	isFetchingPayments: state.payment.isFetchingAppPayments,
	paymentsTotal: state.payment.appPaymentsTotal,
	providerInstance: state.providers.providersInstances,
	currentPage: state.ui.appPaymentsPage,
	filters: state.ui.appPaymentsFilters,
	sorter: state.ui.appPaymentsSorter,
	pageSize: state.ui.appPaymentsPageSize,
});

const mapDispatchToProps = {
	getApplicationPayments,
	setCurrentPage,
	getProvidersInstances,
	scheduleKYTChecks,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
