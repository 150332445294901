// @flow

import { createSelector } from 'reselect';
import instrumentsMappedSelector from './AccountSelector';
//= import helpers
import PriceHelpers from '../../lib/helpers/priceHelpers';
//= import type
import type { Fee } from '../reducers/FeeReducer';
import type { State } from '../types/FlowTypes';

export type MappedFee = Fee & {
	instrumentName: string,
	feeName: string,
	minimumFeeCal: string,
	maximumFeeCal: string,
	netFeeCal: string,
	feeProvider: string,
}
export const feesSelector = (state: State) => state.fees.fees;
export const providerInstanceSelector = (state: State) => state.providers.providersInstances;

export const feesMappedSelector = createSelector(
	instrumentsMappedSelector,
	feesSelector,
	providerInstanceSelector,
	(instruments, fees, providers) => fees.map((fee) => {
		const feeProvider = fee.providerInstanceId
			? providers.find((provider) => provider.id === fee.providerInstanceId)?.provider?.displayName
			: 'All Payment Providers';
		const feeSymbol = fee && (fee.instrumentId
			? `All ${fee.instrumentId} Symbols`
			: 'All Symbols');
		return ({
			...fee,
			instrumentName: fee.instrumentId
				? instruments[fee.instrumentId] && instruments[fee.instrumentId].name
				: '-',
			feeName: fee && (fee.type === 'trading'
				? `${fee.orderType || ''} ${fee.orderSide || ''} ${fee.symbolId ? fee.symbolId : feeSymbol}`
				: `${feeProvider} & ${fee.instrumentId || 'All Instruments'}`),
			minimumFeeCal: fee.minimumFee
				? PriceHelpers.formatAmount(
					fee.minimumFee,
					fee.instrumentId,
				)
				: '-',
			maximumFeeCal: fee.maximumFee
				? PriceHelpers.formatAmount(
					fee.maximumFee,
					fee.instrumentId,
				)
				: '-',
			netFeeCal: fee.netFee
				? PriceHelpers.formatAmount(
					fee.netFee,
					fee.instrumentId,
				)
				: '-',
			feeProvider,
		});
	}),
);
