// @flow

import React from 'react';
//= import components
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import CompanyInfo from '../components/CompanyInfo';
import ApplicationInfo from '../components/ApplicationInfo';
//= import types
import type { Application } from '../../../modules/reducers/ApplicationReducer';

type Props = {
	application: Application,
	logo: string,
	icon: string,
}

function ApplicationDetails(props: Props) {
	const {
		application,
		logo,
		icon,
	} = props;


	return (
		<Row gutter={24} type="flex">
			<Col span={9}>
				{
					application.applicationDetail
						? <ApplicationInfo name={application.name} logo={logo} icon={icon} />
						: <div>No details</div>
				}
			</Col>
			<Col span={15}>
				{
					application.applicationDetail
						? <CompanyInfo companyDetails={application.applicationDetail} />
						: <div>No details</div>
				}
			</Col>
		</Row>
	);
}

export default ApplicationDetails;
