// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Icon from '../../../components/UiElements/Icon';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import types
import type { SuspendReason } from '../../../modules/reducers/UsersReducer';

type Props = {
	applicationId: string,
	reasons: Array<SuspendReason>,
	reasonsTotal: number,
	setReason: Function,
}

function ReasonsList(props: Props) {
	const {
		applicationId,
		reasons,
		reasonsTotal,
		setReason,
	} = props;

	const columns = [{
		title: 'updatedAt',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		width: 176,
		render: (text: {[string]: string}) => (
			<div className="capitalize">
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'id',
		dataIndex: 'id',
		key: 'id',
		width: 340,
		render: (text: string) => (
			<CopyComponent
				text={text}
				content={(
					<Link
						to={`/application/${applicationId}/system/suspend_reasons/${text}`}
					>
						{text}
					</Link>
				)}
			/>
		),
	}, {
		title: 'title',
		dataIndex: 'title',
		key: 'title',
		width: 160,
	}, {
		title: 'description',
		dataIndex: 'description',
		key: 'description',
		width: 656,
		render: (text: string) => (
			<div className="wrap">
				{text}
			</div>
		),
	}, {
		title: 'edit',
		dataIndex: '',
		key: 'action',
		width: 96,
		render: () => (
			<Icon name="Edit" />
		),
		onCell: (record: SuspendReason) => ({
			onClick: (e) => {
				e.stopPropagation();
				setReason(record);
			},
		}),
	}];

	return (
		<CustomTable
			columns={columns}
			data={reasons}
			total={reasonsTotal}
			numberOnPage={reasonsTotal}
			headerHeight={64}
			applicationId={applicationId}
			place="suspendReasons"
		/>
	);
}

export default ReasonsList;
