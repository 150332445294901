// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactJson from 'react-json-view';
//= import components
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Card from '../../../components/UiElements/Card';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
//= import methods
import {
	approveWithdrawalRequest, rejectWithdrawalRequest, getPayment, getRecurringPayment,
} from '../../../modules/actions/PaymentActions';
import { getAccount } from '../../../modules/actions/AccountsActions';
import { getFeeQuote } from '../../../modules/actions/FeeActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { getWallet } from '../../../modules/actions/WalletActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { Wallet } from '../../../modules/reducers/WalletReducer';

const masterLogo = require('../../../assets/img/cards/masterLogo.png');
const location = require('../../../assets/img/location.png');

const payment = {
	id: '10d1b6ba-5121-4c42-bc1d-fec035f70d27',
	amount: '37.41',
	instrumentId: 'EUR',
	status: 'executed',
	type: 'debitCardTransaction',
	customerTransactionId: null,
	recurring: false,
	createdAt: '2020-05-29T11:00:04.354Z',
	updatedAt: '2020-05-29T11:00:04.354Z',
	deletedAt: null,
	recurrenceId: null,
	externalTransactionId: 'aca1ab66c65ca29ca6bbb12db30ff79aa6a6dbb1e7dc4b2c7729eaba02206e24',
	fee: '0',
	providerName: 'TCP - Card Cloud',
	walletId: 'eeb39636-5a7e-4dd5-9ad9-d8613dee1d8f',
	providerInstanceId: '03509df1-5af1-4df1-8ffe-65a24ccba012',
	user: {
		firstName: 'Jacob',
		id: 'ca886b7e-340a-4abb-912f-2fa7703aa348',
		lastName: 'Evans',
	},
	walletTransactions: [{}],
	accountId: '37e24231-f52f-4629-9b0e-bb83ebbac25d',
	paymentMethod: {
		paymentMethodType: 'credit',
		paymentMethodInfo: {
			id: '10d1b6ba-5121-4c42-bc1d-fec035f70d27',
			type: 'credit',
			company: 'Mastercard',
			tail: '1111',
			expMonth: 12,
			expYear: 2022,
			brand: 'FlexInvest',
			archived: false,
			isValid: true,
			name: null,
			createdAt: '2020-05-29T11:00:04.354Z',
			updatedAt: '2019-05-29T11:00:04.354Z',
			deletedAt: null,
			profileId: '10d1b6ba-5121-4c42-bc1d-fec035f70d27',
		},
	},
	providerLogs: [{
		action: 'debitCardTransaction',
		applicationId: 'd139541c-a64a-4b37-8f45-e68c66b8cadc',
		createdAt: '2020-05-29T11:00:04.354Z',
		deletedAt: null,
		id: '09f9c4a8-f2ae-4e0e-8c7a-18a8fa613a94',
		log: {},
		name: 'TCP - Card Cloud',
		providerInstanceId: 'bab48362-81dc-441a-a4c3-3654c4006ad2',
		reference: 'payment',
		referenceId: 'ba0e29bf-551d-44af-b030-23a3962f5b69',
		updatedAt: '2020-05-29T12:01:05.529Z',
	}],
	message: '',
};

type Props = {
	account: Account,
	getAccount: (string) => void,
	getWallet: (string) => Promise<Object>,
	wallet: Wallet,
	match: {
		params: {
			paymentId: string,
			instrumentId: string,
			userId: string,
			applicationId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
		length: number,
	}
}
function FlexPaymentPage(props: Props) {
	const {
		match: { params: { applicationId } },
		account,
		getAccount: getAccountAction,
		getWallet: getWalletAction,
		history,
		wallet,
	} = props;

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchData() {
			try {
				getWalletAction(payment.walletId);
				getAccountAction(payment.accountId);
				setLoading(false);
			} catch (error) { setLoading(false); }
		}
		fetchData();
	}, [
		applicationId,
		getAccountAction,
		getWalletAction,
	]);

	const handleBackClicked = () => {
		props.history.goBack();
	};

	const infoData = {
		requested: PriceHelpers.formatAmount(
			payment.amount,
			payment.instrumentId,
		),
		fee: PriceHelpers.formatAmount(
			payment.fee,
			payment.instrumentId,
		),
		availableAfter: wallet.available
			? PriceHelpers.formatAmount(
				'641.50',
				payment.instrumentId,
			) : '-',
	};

	return (
		<>
			{!loading ? (
				<div>
					<Title
						backAction={history.length > 1 ? handleBackClicked : undefined}
						title="Card Transaction (Mastercard debit)"
						tags={['authorized']}
					/>
					<div className="page-container">
						<Row gutter={24} className="payment-details">
							<Col span={16}>
								<Row>
									<Col span={24} className="payment-details-card">
										<InfoCard
											title="Payment Details"
											data={infoData}
										/>
									</Col>
									<Col span={24} className="payment-details-card">
										<Card bordered={false}>
											<div className="flexPayment">
												<img src={masterLogo} alt="location" className="flex-logo" />
												<h2>
													FlexInvest: &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
													&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; 1111
												</h2>
											</div>
										</Card>
									</Col>
									<Col span={24} className="payment-details-card">
										<Card bordered={false}>
											<img src={location} alt="location" className="flex-location" />
										</Card>
									</Col>
								</Row>
							</Col>
							<Col span={8} className="payment-details-card">
								<Row>
									<Col span={24} className="payment-details-card">
										<Card bordered={false}>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													PAYMENT ID
												</Col>
												<Col span={14}>
													{payment.id}
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													TIME ZONE
												</Col>
												<Col span={14}>
													Local Time Zone
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													CREATED AT
												</Col>
												<Col span={14}>
													{moment(payment.createdAt).format('YYYY-MM-DD HH:mm:ss')}
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													PROVIDER
												</Col>
												<Col span={14} className="capitalize">
													{payment.providerName || '-'}
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													WALLET ID
												</Col>
												<Col span={14} className="capitalize">
													{payment.walletId || '-'}
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													TRADING ACCOUNT
												</Col>
												<Col span={10}>
													<a
														href={`/application/${applicationId}/clients/${payment.user.id}?accountId=${payment.accountId}&tab=user_payments`}
														target="_blank"
														rel="noopener noreferrer"
													>
														{account.name}
													</a>
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													CLIENT
												</Col>
												<Col span={14}>
													<a
														href={`/application/${applicationId}/clients/${payment.user.id}`}
														target="_blank"
														rel="noopener noreferrer"
													>
														{`${payment.user.firstName || 'Name Not Set'} ${payment.user.lastName || ''}`}
													</a>
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													MERCHANT ID
												</Col>
												<Col span={14} className="capitalize">
													5814
												</Col>
											</Row>
											<Row className="list-item">
												<Col span={10} className="payment-title">
													MERCHANT
												</Col>
												<Col span={14} className="capitalize">
													KFC London
												</Col>
											</Row>
										</Card>
									</Col>
									<Col span={24} className="payment-details-card">
										<Card className="payload" bordered={false}>
											<h2>Message</h2>
											<p className="raw-response">
												{payment.message || '-'}
											</p>
										</Card>
									</Col>
									<Col span={24}>
										<Card className="payload" bordered={false}>
											<h2>Provider Log</h2>
											<div className="raw-response">
												<ReactJson
													src={payment.providerLogs}
													theme="rjv-default"
													enableClipboard={false}
													style={{
														backgroundColor: '#F1F1F3',
														color: '#6EBA1A',
														padding: '16px',
													}}
												/>
											</div>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
			)
				: <Spin spinning={loading} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	payment: state.payment.payment,
	account: state.account.account,
	recurringPayment: state.payment.recurringPayment,
	wallet: state.wallet.wallet,
});

const mapDispatchToProps = {
	approveWithdrawalRequest,
	rejectWithdrawalRequest,
	getAccount,
	getPayment,
	getFeeQuote,
	getProvidersInstances,
	getRecurringPayment,
	getWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlexPaymentPage);
