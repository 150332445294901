// @flow

import React from 'react';
//= import components
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import CountriesList from '../components/CountriesList';

type CountryType = {
	label: string,
	value: string,
}
type Props = {
	applicationCountries: Array<CountryType>,
	countries: Array<CountryType>,
	removeCountries: (Array<string>) => void,
	addCountries: (Array<string>) => void,
	isFetchingApplicationCountries: boolean,
}

function ApplicationCountries(props: Props) {
	const {
		applicationCountries,
		countries,
		removeCountries,
		addCountries,
		isFetchingApplicationCountries,
	} = props;

	return (
		<Row gutter={24} type="flex">
			<Col span={12}>
				{!isFetchingApplicationCountries ? (
					<CountriesList
						title="Application Countries"
						button="remove countries"
						buttonAction={removeCountries}
						data={applicationCountries}
						icon="Close"
					/>
				) : <Spin spinning={isFetchingApplicationCountries} />}
			</Col>
			<Col span={12}>
				{!isFetchingApplicationCountries ? (
					<CountriesList
						title="Available Countries"
						button="add countries"
						buttonAction={addCountries}
						data={countries}
						icon="Plus"
					/>
				) : <Spin spinning={isFetchingApplicationCountries} />}
			</Col>
		</Row>
	);
}

export default ApplicationCountries;
