// @flow

import React, { useState, useEffect } from 'react';
//= import components
import Card from '../../../components/UiElements/Card';
import Table from '../../../components/UiElements/Table';
import CPButton from '../../../components/UiElements/Button';
import Spin from '../../../components/UiElements/Spin';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';

type Props = {
	symbols: Array<Symbol>,
	isFetchingInstrumentSymbols: boolean,
	applicationId: string,
}
const InstrumentsApplicationSymbols = (props: Props) => {
	const { symbols, isFetchingInstrumentSymbols, applicationId } = props;
	const [symboPreview, setSymbolPreview] = useState([]);

	useEffect(() => {
		setSymbolPreview(symbols.slice(0, 10));
	}, [symbols]);

	const viewAll = () => {
		setSymbolPreview(symbols);
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: 90,
			render: (text: string) => (
				<div>
					<a
						href={`/application/${applicationId}/system/symbols/${text}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{text || '-'}
					</a>
				</div>
			),
		}, {
			title: 'NAME',
			dataIndex: 'displayName',
			key: 'name',
			width: 156,
			render: (text: string) => (
				<div>
					{text || '-'}
				</div>
			),
		},
	];
	return (
		<Card className="custom-card instrument-symbols" bordered={false}>
			<h2>Instrument&apos;s Application Symbols</h2>
			<div>
				{!isFetchingInstrumentSymbols
					? (
						<Table
							rowKey={(record) => record.id}
							columns={columns}
							dataSource={symboPreview}
							locale={{ emptyText: 'No symbols!' }}
							size="middle"
							pagination={false}
						/>
					)
					: <Spin spinning={isFetchingInstrumentSymbols} />}
				{symbols.length > 10
					&& (
						<CPButton
							ghost
							action={viewAll}
							text="view all"
						/>
					)}
			</div>
		</Card>
	);
};

export default InstrumentsApplicationSymbols;
