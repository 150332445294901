// @flow

const initialState = {
	countries: [],
	rates: {},
	isFetchingRates: false,
	isFetchedRates: false,
	isFetchingCountries: false,
	isFetchedCountries: false,
	error: false,
};

export type Country = {
	id: string,
	name: string,
	countryCode: string,
}
export type Rates = {
	[string]: {
		ask: number,
		bid: number,
	}
}
export type StaticState = {
	countries: Array<Country>,
	rates: Rates,
	isFetchingRates: boolean,
	isFetchedRates: boolean,
	isFetchingCountries: boolean,
	isFetchedCountries: boolean,
	error: Object | boolean,
}
export type CountriesAction = {
	type:
	| 'STATIC_COUNTRIES'
	| 'STATIC_COUNTRIES_FAIL'
	| 'STATIC_COUNTRIES_SUCCESS',
	payload: {
		data: Array<Country>,
	},
	error: Object,
}
export type RatesAction = {
	type:
	| 'RATES'
	| 'RATES_FAIL'
	| 'RATES_SUCCESS',
	payload: {
		data: Rates,
	},
	error: Object,
}
type Action = CountriesAction | RatesAction;

function staticReducer(state: StaticState = initialState, action: Action): StaticState {
	switch (action.type) {
	case 'STATIC_COUNTRIES':
		return {
			...state,
			isFetchingCountries: true,
		};
	case 'STATIC_COUNTRIES_FAIL':
		return {
			...state,
			isFetchingCountries: false,
			isFetchedCountries: false,
			error: action.error,
		};
	case 'STATIC_COUNTRIES_SUCCESS':
		return {
			...state,
			isFetchingCountries: false,
			isFetchedCountries: true,
			error: false,
			countries: action.payload.data.map((country) => ({
				id: country.countryCode,
				name: country.name,
				countryCode: country.countryCode,
			})),
		};
	case 'RATES':
		return {
			...state,
			isFetchingRates: true,
			isFetchedRates: false,
		};
	case 'RATES_SUCCESS':
		return {
			...state,
			isFetchingRates: false,
			isFetchedRates: true,
			error: false,
			rates: { ...state.rates, ...action.payload.data },
		};
	case 'RATES_FAIL':
		return {
			...state,
			isFetchingRates: false,
			isFetchedRates: false,
			error: action.error,
		};
	default:
		return state;
	}
}

export default staticReducer;
