// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import types
import type { EventHandler } from '../../../modules/reducers/EventSubscriptionReducer';
import type { FormProps } from '../../../modules/types/FormTypes';

type Values = {
	name: string,
	handler: string,
}
type Props = {
	form: FormProps,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	handlers: Array<EventHandler>,
}
type LocalState = {
	errorMessage: string,
}

class HandlerForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}


	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			handlers,
		} = this.props;
		const {
			errorMessage,
		} = this.state;
		const { Option } = Select;
		const FormItem = Form.Item;
		const feeLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<FormItem label="HANDLER" {...feeLayout} help="">
					{getFieldDecorator('handlerId', {
						rules: [
							{
								required: true,
								message: 'Please select Event Handler!',
							},
						],
					})(
						<Select
							showSearch
							optionFilterProp="children"
							placeholder="Select Handler"
						>
							{handlers.map((el) => (
								<Option
									value={el.id}
									key={el.id}
								>
									{el.name}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} text="add event handler" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const AddHandlerForm = Form.create()(HandlerForm);

export default AddHandlerForm;
