// @flow

import React from 'react';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Card from '../../../components/UiElements/Card';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	cardInstancesData: Array<{
		cardNameLine: string,
		expiresMonth: string,
		expiresYear: string,
		isActive: boolean,
		isVirtual: boolean,
		pan: string,
	}>,
	title: string,
	applicationId: string,
}

const CardInstances = (props: Props) => {
	const {
		cardInstancesData,
		title,
		applicationId,
	} = props;

	const columns = [
		{
			title: 'card name line',
			dataIndex: 'cardNameLine',
			key: 'cardNameLine',
			width: 208,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'pan',
			dataIndex: 'pan',
			key: 'pan',
			width: 160,
			render: (text: string) => (
				<div className="capitalize">
					{text || '-'}
				</div>
			),
		},
		{
			title: 'expiration date',
			dataIndex: 'expiresMonth',
			key: 'expirationDate',
			width: 160,
			render: (text: string, record: Object) => (
				<div className="capitalize">
					{`${text || '-'}/${record.expiresYear || '-'}`}
				</div>
			),
		},
		{
			title: 'isActive',
			dataIndex: 'isActive',
			key: 'isActive',
			width: 160,
			render: (text: boolean) => (
				<div className="capitalize">
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'isVirtual',
			dataIndex: 'isVirtual',
			key: 'isVirtual',
			width: 160,
			render: (text: boolean) => (
				<div className="capitalize">
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
	];

	return (
		<Card className={styles.designCards} bordered={false}>
			<h2>{title}</h2>
			<CustomTable
				columns={columns}
				data={cardInstancesData}
				total={cardInstancesData.length}
				headerHeight={16}
				numberOnPage={cardInstancesData.length}
				place="cardInstances"
				applicationId={applicationId}
				width={848}
			/>
		</Card>
	);
};

export default CardInstances;
