// @flow

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//= import components
import CustomTable from '../../components/UiElements/CustomTable';
import Spin from '../../components/UiElements/Spin';
import Title from '../../components/UiElements/Title';
import CopyComponent from '../../components/UiElements/CopyComponent';
import Export from '../../components/UiElements/Export';
import FormModal from '../../components/UiElements/Modal/FormModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
//= import methods
import { getConversions, exportConversions } from '../../modules/actions/WalletActions';
import { getAppInstruments } from '../../modules/actions/InstrumentAction';
import { setCurrentPage } from '../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../lib/helpers/priceHelpers';
import StatusHelpers from '../../lib/helpers/statusHelpers';
//= import types
import type { Conversion } from '../../modules/reducers/WalletReducer';
import type { Instrument } from '../../modules/reducers/InstrumentReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	conversions: Array<Conversion>,
	isFetchingConversions: boolean,
	conversionsTotal: number,
	currentPage: number,
	filters: { [string]: string },
	instruments: Array<Instrument>,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	location: {
		pathname: string,
		search: string,
	},
	history: {
		goBack: () => void,
	},
	getConversions: (string, number, Object) => Promise<Object>,
	exportConversions: (string, Object) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	getAppInstruments: (string, string, number, string) => void,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

function Conversions(props: Props) {
	const {
		conversions,
		isFetchingConversions,
		conversionsTotal,
		currentPage,
		filters,
		instruments,
		getConversions: getConversionsAction,
		setCurrentPage: setCurrentPageAction,
		exportConversions: exportConversionsAction,
		getAppInstruments: getAppInstrumentsAction,
		match: { params: { applicationId, userId } },
		location: { search },
		history: { goBack },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [modal, setModal] = useState(false);
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [success, setSuccess] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getConversionsAction(applicationId, currentPage, queryString.stringify(filters, { arrayFormat: 'comma' }));
	}, [filters, currentPage, getConversionsAction, applicationId, search, userId]);

	const fiatInstruments = instruments.map((el) => ({ id: el.instrumentId, name: el.name }));

	const handlePageChange = (page: number) => {
		setCurrentPageAction('conversions', page);
	};

	const filterRef = useRef(null);
	const formRef = useRef(null);

	useEffect(() => {
		const filterOptions: string = queryString.stringify({ type: 'fiat' });
		getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
	}, [getAppInstrumentsAction, applicationId]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [filters]);

	const openModal = () => {
		setModal(true);
		setSuccess(false);
	};

	const handleBackClick = () => {
		goBack();
	};

	const handleWarningCancel = () => {
		setModal(true);
		setWarning(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setModal(false);
		setWarning(false);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setModal(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setModal(false);
			setWarning(false);
		}
	};

	const exportData = (value) => {
		setButtonLoading(true);
		const data = {
			...value,
			userId: value.userId || undefined,
			accountId: value.accountId || undefined,
			fromWalletId: value.fromWalletId || undefined,
			toWalletId: value.toWalletId || undefined,
			referenceId: value.referenceId || undefined,
		};
		const params: string = queryString.stringify(data);
		exportConversionsAction(applicationId, params)
			.then((res: Object) => {
				setButtonLoading(false);
				setSuccess(true);
				setWarningTitle('Confirmation');
				setModal(false);
				setWarning(true);
				setMessage({ firstPart: res.payload.data.message });
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'close' },
				]);
			})
			.catch((error: ErrorType) => {
				setButtonLoading(false);
				setSuccess(false);
				setWarningTitle('Error!');
				setModal(false);
				setWarning(true);
				setMessage({
					firstPart: error.error && error.error.response.data ? error.error.response.data.message : 'Error',
					secondPart: error.error && error.error.response.data && error.error.response.data.errors && typeof error.error.response.data.errors !== 'string'
						? error.error.response.data.errors[0].messages[0].message
						: error.error.response.data.errors,
				});
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'close' },
				]);
			});
	};

	const exportOptions = {
		dateRange: {
			options: 'time',
			initialValue: filters.from && filters.to
				? [
					filters.from[0] ? moment(filters.from[0]) : filters.from[0],
					filters.to[0] ? moment(filters.to[0]) : filters.to[0],
				]
				: [],
		},
		fromCurrency: { options: fiatInstruments, initialValue: filters.fromCurrency, label: 'from currency' },
		toCurrency: { options: fiatInstruments, initialValue: filters.toCurrency, label: 'to currency' },
		userId: { options: 'input', initialValue: filters.userId, label: 'client id' },
		accountId: { options: 'input', initialValue: filters.accountId, label: 'account id' },
		fromWalletId: { options: 'input', initialValue: filters.fromWalletId, label: 'from wallet' },
		toWalletId: { options: 'input', initialValue: filters.toWalletId, label: 'to wallet' },
		reference: { options: StatusHelpers.references, initialValue: filters.reference, label: 'reference' },
		referenceId: { options: 'input', initialValue: filters.referenceId, label: 'reference id' },
	};

	const columns = [
		{
			title: 'last updated',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => (
				<div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
			),
		},
		{
			title: 'conversion id ',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/system/conversions`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'fixed side',
			dataIndex: 'fixedSide',
			key: 'fixedSide',
			width: 120,
		},
		{
			title: 'from currency',
			dataIndex: 'fromCurrencyId',
			key: 'fromCurrencyId',
			align: 'right',
			width: 144,
		},
		{
			title: 'to currency',
			dataIndex: 'toCurrencyId',
			key: 'toCurrencyId',
			align: 'right',
			width: 144,
		},
		{
			title: 'from amount',
			dataIndex: 'fromAmount',
			key: 'fromAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: Conversion) => (
				<div>
					{PriceHelpers.formatAmount(
						PriceHelpers.formatNumber(text, 2),
						record.fromCurrencyId,
					)}
				</div>
			),
		},
		{
			title: 'to amount',
			dataIndex: 'toAmount',
			key: 'toAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: Conversion) => (
				<div>
					{PriceHelpers.formatAmount(
						PriceHelpers.formatNumber(text, 2),
						record.toCurrencyId,
					)}
				</div>
			),
		},
		{
			title: 'conversion rate',
			dataIndex: 'conversionRate',
			key: 'conversionRate',
			width: 176,
			align: 'right',
			render: (text: string) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'fixed rate ',
			dataIndex: 'fixedRate',
			key: 'fixedRate',
			align: 'right',
			width: 144,
			render: (text: string) => (
				<div>
					{text || '-'}
				</div>
			),
		},
		{
			title: 'price offset',
			dataIndex: 'priceOffset',
			key: 'priceOffset',
			width: 144,
			align: 'right',
			render: (text: string) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'price',
			dataIndex: 'price',
			key: 'price',
			width: 144,
			align: 'right',
			render: (text: string) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'account id',
			dataIndex: 'accountId',
			key: 'accountId',
			width: 340,
			render: (text: string, record: Conversion) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/clients/${record.userId}/?accountId=${text}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'client id',
			dataIndex: 'userId',
			key: 'userId',
			width: 340,
			render: (text: string) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/clients/${text}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'from wallet',
			dataIndex: 'fromWalletId',
			key: 'fromWalletId',
			width: 340,
			render: (text: string, record: Conversion) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/clients/${record.userId}/user_wallets/${text}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'to wallet',
			dataIndex: 'toWalletId',
			key: 'toWalletId',
			width: 340,
			render: (text: string, record: Conversion) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/clients/${record.userId}/user_wallets/${text}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
	];

	return (
		<>
			{search
				? (
					<Title
						title={queryString.parse(search).type ? `pending ${queryString.parse(search).type}` : 'client payments'}
					/>
				)
				: (
					<div ref={filterRef}>
						<Title
							backAction={handleBackClick}
							title="fiat conversions"
							buttons={[
								{
									action: () => setFilterVisible(true),
									text: 'filter',
									icon: 'Funnel',
								},
								{
									action: () => openModal(),
									text: 'export',
									icon: 'Open',
								},
							]}
							applicationId={applicationId}
							place="conversions"
							fields={{
								dateRange: 'time',
								fromCurrency: fiatInstruments,
								toCurrency: fiatInstruments,
								userId: 'input',
								accountId: 'input',
								fromWalletId: 'input',
								toWalletId: 'input',
								reference: StatusHelpers.references,
								referenceId: 'input',
							}}
							date
							visible={filterVisible}
							closeFunction={() => setFilterVisible(false)}
							filter
							filterMode="single"
						/>
					</div>
				)}
			<div className="container">
				{!isFetchingConversions
					? (
						<>
							<CustomTable
								columns={columns}
								data={conversions}
								total={conversionsTotal}
								handlePageChange={handlePageChange}
								currentPage={currentPage}
								headerHeight={filterHeight}
								place="appPayments"
								applicationId={applicationId}
							/>
							<FormModal
								destroyOnClose
								title="Export Fiat Conversions"
								visible={modal}
								cancelFunction={handleEditCancel}
								form={(
									<Export
										submitFunction={exportData}
										exportOptions={exportOptions}
										cancelFunction={handleEditCancel}
										mode="single"
									/>
								)}
							/>
							<WarningModal
								title={warningTitle}
								visible={warning}
								cancelFunction={handleErrorCancel}
								footer={footer}
								message={message}
								success={success}
								loading={buttonLoading}
							/>
						</>
					) : <Spin spinning={isFetchingConversions} />}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	conversions: state.wallet.conversions,
	isFetchingConversions: state.wallet.isFetchingConversions,
	conversionsTotal: state.wallet.conversionsTotal,
	currentPage: state.ui.conversionsPage,
	filters: state.ui.conversionsFilters,
	instruments: state.instrument.instruments,
});

const mapDispatchToProps = {
	getConversions,
	setCurrentPage,
	exportConversions,
	getAppInstruments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversions);
