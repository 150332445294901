// @flow

import React, {
	useEffect, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import difference from 'lodash/difference';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import Icon from '../../../components/UiElements/Icon';
import EditInstrumentForm from '../components/EditInstrumentForm';
//= import methods
import {
	getAppInstruments,
	updateAppInstrument,
	addPaymentMethods,
	removePaymentMethods,
} from '../../../modules/actions/InstrumentAction';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	instruments: Array<Instrument>,
	isFetchingInstruments: boolean,
	currentPage: number,
	getAppInstruments: (string, string, number, string) => Promise<void>,
	updateAppInstrument: (string, string, Object) => Promise<Object>,
	removePaymentMethods: (string, string, Object) => Promise<Object>,
	addPaymentMethods: (string, string, Object) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	instrumentFilters: { [string]: string },
	instrumentsTotal: number,
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

const Instruments = (props: Props) => {
	const {
		instruments,
		isFetchingInstruments,
		currentPage,
		getAppInstruments: getAppInstrumentsAction,
		updateAppInstrument: updateAppInstrumentAction,
		removePaymentMethods: removePaymentMethodsAction,
		addPaymentMethods: addPaymentMethodsAction,
		setCurrentPage: setCurrentPageAction,
		instrumentFilters,
		instrumentsTotal,
		match: { params: { applicationId } },
	} = props;

	const [edit, setEdit] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [title, setTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [selectedInstrument, setSelectedInstrument] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	const formRef = useRef(null);

	useEffect(() => {
		const idsFilter = instrumentFilters.ids?.[0]?.replace(/\s+/g, '').toUpperCase();
		const filterOptions: string = queryString.stringify({ ...instrumentFilters, ids: idsFilter });
		getAppInstrumentsAction(applicationId, 'numbered-pages', (currentPage - 1) * StatusHelpers.paginationLimit, filterOptions);
	}, [getAppInstrumentsAction, applicationId, instrumentFilters, currentPage]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('instrumentPage', page);
	};

	const handleEditClick = (instrument: Instrument) => {
		setEdit(true);
		setTitle(`Edit ${instrument.name} ${instrument.instrumentId}`);
		setSelectedInstrument(instrument);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setEdit(false);
		setWarningModal(false);
	};

	const handleWarningCancel = () => {
		setEdit(true);
		setWarningModal(false);
	};

	const handleEditCancel = () => {
		if (formRef.current
			&& (formRef.current.props.form.isFieldsTouched() || formRef.current.state.isTouched)) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setEdit(false);
			setWarningModal(false);
		}
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setEdit(false);
		setWarningModal(true);
		setWarningTitle('Error!');
		setMessage({
			firstPart: error.error.response.data.message,
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitChanges = (value) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		if (value.default !== selectedInstrument.default || value.rank !== selectedInstrument.rank) {
			const defaultData = {
				default: value.default !== selectedInstrument.default ? value.default : undefined,
				rank: value.rank !== selectedInstrument.rank ? value.rank : undefined,
			};
			updateAppInstrumentAction(applicationId, selectedInstrument.instrumentId, defaultData)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error) => handleOnError(error));
		} else {
			setButtonLoading(false);
			setWarningModal(false);
		}
		const depositPaymentMethods = value.depositPaymentMethods.includes('Not Depositable') ? [] : value.depositPaymentMethods;
		const withdrawalPaymentMethods = value.withdrawalPaymentMethods.includes('Not Withdrawable') ? [] : value.withdrawalPaymentMethods;
		if (difference(selectedInstrument.depositPaymentMethods, depositPaymentMethods).length) {
			const data = {
				depositPaymentMethods: difference(
					selectedInstrument.depositPaymentMethods,
					depositPaymentMethods,
				),
			};
			removePaymentMethodsAction(applicationId, selectedInstrument.instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error) => handleOnError(error));
		}
		if (difference(selectedInstrument.withdrawalPaymentMethods, withdrawalPaymentMethods).length) {
			const data = {
				withdrawalPaymentMethods: difference(
					selectedInstrument.withdrawalPaymentMethods,
					withdrawalPaymentMethods,
				),
			};
			removePaymentMethodsAction(applicationId, selectedInstrument.instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error) => handleOnError(error));
		}
		if (difference(depositPaymentMethods, selectedInstrument.depositPaymentMethods).length) {
			const data = {
				depositPaymentMethods: difference(
					depositPaymentMethods,
					selectedInstrument.depositPaymentMethods,
				),
			};
			addPaymentMethodsAction(applicationId, selectedInstrument.instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error) => handleOnError(error));
		}
		if (difference(withdrawalPaymentMethods, selectedInstrument.withdrawalPaymentMethods).length) {
			const data = {
				withdrawalPaymentMethods: difference(
					withdrawalPaymentMethods,
					selectedInstrument.withdrawalPaymentMethods,
				),
			};
			addPaymentMethodsAction(applicationId, selectedInstrument.instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error) => handleOnError(error));
		}
	};

	const continueChanges = (value) => {
		setEdit(false);
		setWarningModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: "Once you confirm these changes, they will affect this Instrument and all it's Symbols.",
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(value), text: 'save changes' },
		]);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const filterRef = useRef(null);
	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [instrumentFilters]);

	const delisted = [{ id: true, name: 'yes' }, { id: false, name: 'no' }];

	const columns = [
		{
			title: 'id',
			dataIndex: 'instrumentId',
			key: 'instrumentId',
			width: 96,
			render: (text: string) => (
				<Link
					to={`/application/${applicationId}/system/instruments/${text}`}
				>
					{text}
				</Link>
			),
		},
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 208,
			render: (text: string) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'depositable',
			dataIndex: 'depositPaymentMethods',
			key: 'depositPaymentMethods',
			width: 256,
			render: (text: string, record: Instrument) => (
				<div>
					{record.depositPaymentMethods.length === 0
						? <span className="disabled-color">Not Depositable</span>
						: (
							<span>
								{record.depositPaymentMethods.map((method) => StatusHelpers.paymentMethods(method)).join(', ')}
							</span>
						)}
				</div>
			),
		},
		{
			title: 'withdrawable',
			dataIndex: 'withdrawalPaymentMethods',
			key: 'withdrawalPaymentMethods',
			width: 256,
			render: (text: string, record: Instrument) => (
				<div>
					{record.withdrawalPaymentMethods.length === 0
						? <span className="disabled-color">Not Withdrawable</span>
						: (
							<span>
								{record.withdrawalPaymentMethods.map((method) => StatusHelpers.paymentMethods(method)).join(', ')}
							</span>
						)}
				</div>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 112,
			render: (text: string) => (
				<div style={{ color: StatusHelpers.typeColor(text) }}>
					{StatusHelpers.typeName(text)}
				</div>
			),
		},
		{
			title: 'precision',
			dataIndex: 'precision',
			key: 'precision',
			width: 112,
		},
		{
			title: 'haircut',
			dataIndex: 'haircut',
			key: 'haircut',
			width: 112,
			render: (text: string) => (
				<div>
					{text || '-'}
				</div>
			),
		},
		{
			title: 'edit',
			dataIndex: '',
			key: 'action',
			width: 80,
			render: () => (
				<Icon name="Edit" />
			),
			onCell: (record: Instrument) => ({
				onClick: (e) => {
					e.stopPropagation();
					handleEditClick(record);
				},
			}),
		},
	];
	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="instruments list"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="instrument"
					fields={{
						delisted,
						ids: 'input',
					}}
					filterMode="single"
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
				/>
			</div>
			<div className="container">
				{!isFetchingInstruments
					? (
						<CustomTable
							columns={columns}
							data={instruments}
							total={instrumentsTotal}
							handlePageChange={handlePageChange}
							currentPage={currentPage}
							headerHeight={filterHeight}
							place="instruments"
							applicationId={applicationId}
						/>
					)
					: <Spin spinning={isFetchingInstruments} />}
				<FormModal
					title={title}
					visible={edit}
					cancelFunction={handleEditCancel}
					destroyOnClose
					form={(
						<EditInstrumentForm
							submitChanges={continueChanges}
							handleCancelClick={handleEditCancel}
							instrument={selectedInstrument}
							wrappedComponentRef={formRef}
						/>
					)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.instrumentPage,
	instruments: state.instrument.instruments,
	isFetchingInstruments: state.instrument.isFetchingInstruments,
	instrumentsTotal: state.instrument.instrumentsTotal,
	instrumentFilters: state.ui.instrumentFilters,
});

const mapDispatchToProps = {
	getAppInstruments,
	setCurrentPage,
	updateAppInstrument,
	removePaymentMethods,
	addPaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(Instruments);
