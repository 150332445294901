// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import orderBy from 'lodash/orderBy';
//= import components
import Title from '../../components/UiElements/Title';
import FormModal from '../../components/UiElements/Modal/FormModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
import ApplicationDetails from './containers/ApplicationDetails';
import ApplicationCountries from './containers/ApplicationCountries';
import DetailsForm from './components/DetailsForm';

//= import helpers
import RegexHelpers from '../../lib/helpers/regexHelpers';
//= import methods
import {
	getApplication,
	updateApplication,
	getApplicationCountries,
	addApplicationCountries,
	deleteApplicationCountries,
} from '../../modules/actions/ApplicationActions';
import getFile from '../../modules/actions/FilesActions';
import { getStaticCountries } from '../../modules/actions/StaticActions';
import { setActiveTab } from '../../modules/actions/UiActions';
//= import types
import type { State } from '../../modules/types/FlowTypes';
import type { Application, AppCountry } from '../../modules/reducers/ApplicationReducer';
import type { Country } from '../../modules/reducers/StaticReducer';


type Props = {
	getApplication: (string, string) => Promise<Object>,
	updateApplication: (string, string, Object) => Promise<Object>,
	getFile: (string) => Promise<Object>,
	getStaticCountries: () => Promise<Object>,
	getApplicationCountries: (string) => Promise<Object>,
	addApplicationCountries: (string, Object) => Promise<Object>,
	deleteApplicationCountries: (string, Object) => Promise<Object>,
	activeTab: string,
	customer: { referenceId: string },
	application: Application,
	countries: Array<Country>,
	applicationCountries: Array<AppCountry>,
	isFetchingApplicationCountries: boolean,
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}
type Data = {
	name: string,
	status: boolean,
	description: string,
	companyName: string,
	streetName: string,
	streetNumber: string,
	addressLine2: string,
	zipCode: string,
	state: string,
	country: string,
	contactEmail: string,
	phone: string,
	faxNumber: string,
	website: string,
	vatNumber: string,
	registrationNumber: string,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}
type AppResponse = {
	payload: {
		data: Application,
	}
}
type ImgResponse = {
	payload: {
		data: {
			url: string,
		}
	}
}

const ApplicationPage = (props: Props) => {
	const {
		getApplication: getApplicationAction,
		getFile: getFileAction,
		updateApplication: updateApplicationAction,
		getApplicationCountries: getApplicationCountriesAction,
		addApplicationCountries: addApplicationCountriesAction,
		deleteApplicationCountries: deleteApplicationCountriesAction,
		getStaticCountries: getStaticCountriesAction,
		application,
		applicationCountries,
		countries,
		isFetchingApplicationCountries,
		customer: { referenceId },
		activeTab,
		match: { params: { applicationId } },
		history: { goBack },
	} = props;

	const handleBackClick = () => {
		goBack();
	};

	const [edit, setEdit] = useState(false);
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [icon, setIcon] = useState('');
	const [logo, setLogo] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [countriesChanged, setCountriesChanged] = useState(false);
	const [success, setSuccess] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getStaticCountriesAction();
	}, [getStaticCountriesAction]);

	useEffect(() => {
		getApplicationCountriesAction(applicationId);
	}, [applicationId, getApplicationCountriesAction, countriesChanged]);

	useEffect(() => {
		getApplicationAction(referenceId, applicationId)
			.then((appResponse: AppResponse) => {
				if (appResponse?.payload.data?.applicationDetail?.icon && appResponse?.payload?.data?.applicationDetail?.icon.startsWith('http')) {
					const iconUrl = new URL(appResponse?.payload?.data?.applicationDetail?.icon);
					const iconId = iconUrl.pathname;
					getFileAction(iconId)
						.then((res: ImgResponse) => setIcon(res.payload.data.url));
				}
				if (appResponse.payload?.data?.applicationDetail?.logo && appResponse?.payload?.data?.applicationDetail?.logo.startsWith('http')) {
					const logoUrl = new URL(appResponse.payload.data.applicationDetail.logo);
					const logoId = logoUrl.pathname;
					getFileAction(logoId)
						.then((res: ImgResponse) => setLogo(res.payload.data.url));
				}
			});
	}, [applicationId, referenceId, submitted, getApplicationAction, getFileAction]);

	const formRef = useRef(null);

	const handleWarningCancel = () => {
		setEdit(true);
		setWarning(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarning(false);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarning(false);
		}
	};

	const handleOnError = (error: ErrorType) => {
		setWarning(true);
		setWarningTitle('Error!');
		setMessage({
			firstPart: error.error ? error.error.response.data.message : 'Error!',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
		]);
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const handleOnSuccess = () => {
		setWarning(true);
		setWarningTitle('Success');
		setMessage({ firstPart: "Congrats. You've successfully change Application Countries." });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitChanges = (data: Data) => {
		setButtonLoading(true);
		updateApplicationAction(referenceId, applicationId, data)
			.then((appData: AppResponse) => {
				if (RegexHelpers.validateURL(appData?.payload?.data?.applicationDetail?.icon)) {
					const iconUrl = new URL(appData?.payload?.data?.applicationDetail?.icon);
					const iconId = iconUrl.pathname;
					getFileAction(iconId)
						.then((res: ImgResponse) => setIcon(res.payload.data.url));
				}
				if (RegexHelpers.validateURL(appData?.payload.data?.applicationDetail?.logo)) {
					const logoUrl = new URL(appData?.payload?.data?.applicationDetail?.logo);
					const logoId = logoUrl?.pathname;
					getFileAction(logoId)
						.then((res: ImgResponse) => setLogo(res?.payload?.data?.url));
				}
				setEdit(false);
				setButtonLoading(false);
				setWarning(false);
				setSubmitted(!submitted);
				if (formRef.current) {
					formRef.current.handleReset();
				}
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueEditing = (value) => {
		setWarning(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
					they will affect this Application.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(value), text: 'edit application details' },
		]);
	};

	const removeCountries = async (value) => {
		setButtonLoading(true);
		try {
			await deleteApplicationCountriesAction(applicationId, { countries: value });
			setCountriesChanged(!countriesChanged);
			handleOnSuccess();
			setButtonLoading(false);
			setSuccess(true);
		} catch (error) {
			setSuccess(false);
			handleOnError(error);
		}
	};

	const addCountries = async (value) => {
		setButtonLoading(true);
		try {
			await addApplicationCountriesAction(applicationId, { countries: value });
			setCountriesChanged(!countriesChanged);
			handleOnSuccess();
			setButtonLoading(false);
			setSuccess(true);
		} catch (error) {
			setSuccess(false);
			handleOnError(error);
		}
	};

	const handleAddCountries = (value) => {
		setWarning(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
					they will affect this Application.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'go back' },
			{ type: 'continue', action: () => addCountries(value), text: 'add application countries' },
		]);
	};

	const handleRemoveCountries = (value) => {
		setWarning(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
					they will affect this Application.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'go back' },
			{ type: 'continue', action: () => removeCountries(value), text: 'remove application countries' },
		]);
	};

	const restCountries = orderBy(differenceBy(countries, applicationCountries, 'countryCode').map((el) => (
		{ value: el.countryCode, label: `${el.countryCode} - ${el.name}` }
	)), 'value');
	const appCountries = orderBy(applicationCountries.map((el) => (
		{ value: el.country?.countryCode || el.countryCode, label: `${el.country?.countryCode || el.countryCode} - ${el.country?.name || el.name || ''}` }
	)), 'value');

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Application Details"
				buttons={activeTab === 'appDetails' ? [{
					action: () => setEdit(true),
					text: 'edit details',
				}] : []}
				tab="details"
				tabs={[
					{
						key: 'appDetails',
						title: 'GENERAL DETAILS',
					},
					{
						key: 'countries',
						title: 'APPLICATION COUNTRIES',
					},
				]}
			/>
			<div className="page-container">
				{activeTab === 'appDetails' && (
					<ApplicationDetails
						application={application}
						icon={icon}
						logo={logo}
					/>
				)}
				{activeTab === 'countries' && (
					<ApplicationCountries
						applicationCountries={appCountries}
						countries={restCountries}
						addCountries={handleAddCountries}
						removeCountries={handleRemoveCountries}
						isFetchingApplicationCountries={isFetchingApplicationCountries}
						key={countriesChanged.toString()}
					/>
				)}
				<FormModal
					title="Edit General Details"
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<DetailsForm
							data={application}
							submitChanges={continueEditing}
							handleCancelClick={handleEditCancel}
							wrappedComponentRef={formRef}
							icon={icon}
							logo={logo}
						/>
					)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warning}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					success={success}
					loading={buttonLoading}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	activeTab: state.ui.detailsTab,
	customer: state.oauth.customer,
	application: state.application.application,
	countries: state.static.countries,
	applicationCountries: state.application.applicationCountries,
	isFetchingApplicationCountries: state.application.isFetchingApplicationCountries,
});

const mapDispatchToProps = {
	getApplication,
	updateApplication,
	setActiveTab,
	getFile,
	getStaticCountries,
	getApplicationCountries,
	addApplicationCountries,
	deleteApplicationCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPage);
