// @flow

import React from 'react';
//= import components
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
// = import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import UsersFees from './UsersFees';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	userSettings: Object,
	applicationId: string,
	userId: string,
	viewFee: (Object) => void,
	appMarginEnabled: boolean,
	postTradeSettlementEnabled: boolean,
}
const SettingsInfo = (props: Props) => {
	const {
		userSettings,
		applicationId,
		viewFee,
		userId,
		appMarginEnabled,
		postTradeSettlementEnabled,
	} = props;

	const marginData = {
		annualInterestRate: `${userSettings.annualInterestRate}%`,
		accrualPeriod: userSettings.accrualPeriod ? userSettings.accrualPeriod.toUpperCase() : '-',
		compoundPeriod: userSettings.compoundPeriod ? userSettings.compoundPeriod.toUpperCase() : '-',
	};

	const postData = {
		maximumRiskExposureInstrument: userSettings.maximumRiskExposureInstrument || '-',
		maximumRiskExposureAmount: PriceHelpers.formatAmount(
			userSettings.maximumRiskExposureAmount,
			userSettings.maximumRiskExposureInstrument || '',
		),
	};
	return (
		<div className={styles.userSettings}>
			{appMarginEnabled && (
				<div className={styles.margin}>
					<h4>Margin Info</h4>
					<TextCard data={marginData} colNumber={1} border left="left-1" />
				</div>
			)}
			{postTradeSettlementEnabled && (
				<div className={styles.pts}>
					<h4>Post-Trade Settlement Info</h4>
					<TextCard data={postData} colNumber={1} border left="left-1" />
				</div>
			)}
			<div>
				<h4>Fee Info</h4>
				<UsersFees
					applicationId={applicationId}
					userId={userId}
					viewFee={viewFee}
				/>
			</div>
		</div>
	);
};

export default SettingsInfo;
