// @flow

const initialState = {
	error: false,
	isFetchingProviders: false,
	isFetchedProviders: false,
	providers: [],
	isFetchingProviderInstance: false,
	isFetchedProviderInstance: false,
	providerInstance: {},
	isFetchingProvidersInstances: false,
	isFetchedProvidersInstances: false,
	providersInstances: [],
	updatingProviderInstance: false,
	providerInstanceUpdated: false,
	addingProviderInstance: false,
	providerInstanceAdded: false,
};

export type ProviderInstance = {
	active: boolean,
	config: {[string]: string},
	id: string,
	primary: boolean,
	provider: {
		id: string,
		name: string,
		displayName: string,
		type: string,
		supports: Array<string>,
	},
	type: string,
}
export type Provider = {
    active: boolean,
	config: {[string]: string},
	displayName: string,
	formTemplate: {[string]: Object},
	id: string,
    name: string,
    providerInstanceTypes: Array<string>,
    type: string,
    supports: Array<string>,
	visible: boolean,
}
export type ProviderState = {
	error: boolean | Object,
	isFetchingProviders: boolean,
	isFetchedProviders: boolean,
	providers: Array<Provider>,
	isFetchingProviderInstance: boolean,
	isFetchedProviderInstance: boolean,
	providerInstance: ProviderInstance,
	isFetchingProvidersInstances: boolean,
	isFetchedProvidersInstances: boolean,
	providersInstances: Array<ProviderInstance>,
	updatingProviderInstance: boolean,
	providerInstanceUpdated: boolean,
	addingProviderInstance: boolean,
	providerInstanceAdded: boolean,
}

export type ProviderAction = {
	type:
	| 'GET_PROVIDER_INSTANCE'
	| 'GET_PROVIDER_INSTANCE_SUCCESS'
	| 'GET_PROVIDER_INSTANCE_FAIL'
	| 'ADD_PROVIDER_INSTANCE'
	| 'ADD_PROVIDER_INSTANCE_SUCCESS'
	| 'ADD_PROVIDER_INSTANCE_FAIL'
	| 'UPDATE_PROVIDER_INSTANCE'
	| 'UPDATE_PROVIDER_INSTANCE_SUCCESS'
	| 'UPDATE_PROVIDER_INSTANCE_FAIL',
	payload: {
		data: ProviderInstance,
	},
	error: {
        code: number,
        message: string,
    }
}
export type ProvidersAction = {
	type:
	| 'GET_PROVIDERS'
	| 'GET_PROVIDERS_SUCCESS'
	| 'GET_PROVIDERS_FAIL',
	payload: {
		data: Array<Provider>,
	},
	error: {
        code: number,
        message: string,
    }
}
export type ProviderInstanceAction = {
	type:
	| 'GET_PROVIDERS_INSTANCES'
	| 'GET_PROVIDERS_INSTANCES_SUCCESS'
	| 'GET_PROVIDERS_INSTANCES_FAIL',
	payload: {
		data: Array<ProviderInstance>,
	},
	error: {
        code: number,
        message: string,
    }
}
export type Action = ProviderAction | ProvidersAction | ProviderInstanceAction;

export default function paymentReducer(
	state: ProviderState = initialState,
	action: Action,
): ProviderState {
	switch (action.type) {
	case 'GET_PROVIDERS': {
		return {
			...state,
			isFetchingProviders: true,
			isFetchedProviders: false,
			error: false,
		};
	}
	case 'GET_PROVIDERS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingProviders: false,
			isFetchedProviders: true,
			providers: action.payload.data,
		};
	}
	case 'GET_PROVIDERS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingProviders: false,
			isFetchedProviders: false,
		};
	}
	case 'GET_PROVIDERS_INSTANCES': {
		return {
			...state,
			isFetchingProvidersInstances: true,
			isFetchedProvidersInstances: false,
			error: false,
		};
	}
	case 'GET_PROVIDERS_INSTANCES_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingProvidersInstances: false,
			isFetchedProvidersInstances: true,
			providersInstances: action.payload.data,
		};
	}
	case 'GET_PROVIDERS_INSTANCES_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingProvidersInstances: false,
			isFetchedProvidersInstances: false,
		};
	}
	case 'GET_PROVIDER_INSTANCE': {
		return {
			...state,
			isFetchingProviderInstance: true,
			isFetchedProviderInstance: false,
			error: false,
		};
	}
	case 'GET_PROVIDER_INSTANCE_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingProviderInstance: false,
			isFetchedProviderInstance: true,
			providerInstance: action.payload.data,
		};
	}
	case 'GET_PROVIDER_INSTANCE_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingProviderInstance: false,
			isFetchedProviderInstance: false,
		};
	}
	case 'ADD_PROVIDER_INSTANCE': {
		return {
			...state,
			addingProviderInstance: true,
			providerInstanceAdded: false,
			error: false,
		};
	}
	case 'ADD_PROVIDER_INSTANCE_SUCCESS': {
		return {
			...state,
			error: false,
			addingProviderInstance: false,
			providerInstanceAdded: true,
			providerInstance: action.payload.data,
		};
	}
	case 'ADD_PROVIDER_INSTANCE_FAIL': {
		return {
			...state,
			error: action.error,
			addingProviderInstance: false,
			providerInstanceAdded: false,
		};
	}
	case 'UPDATE_PROVIDER_INSTANCE': {
		return {
			...state,
			updatingProviderInstance: true,
			providerInstanceUpdated: false,
			error: false,
		};
	}
	case 'UPDATE_PROVIDER_INSTANCE_SUCCESS': {
		return {
			...state,
			error: false,
			updatingProviderInstance: false,
			providerInstanceUpdated: true,
			providerInstance: action.payload.data,
			providersInstances: state.providersInstances.map((provider) => {
				if (provider.id === action.payload.data.id) {
					return action.payload.data;
				}
				return provider;
			}),
		};
	}
	case 'UPDATE_PROVIDER_INSTANCE_FAIL': {
		return {
			...state,
			error: action.error,
			updatingProviderInstance: false,
			providerInstanceUpdated: false,
		};
	}
	default:
		return state;
	}
}
