// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import ant's css
import 'ant-design-pro/dist/ant-design-pro.css';
//= import components
import CustomerRoutes from './CustomerRoutes';
import Layout from '../../components/UiElements/Layout';
import Topbar from '../../components/UiElements/Layout/TopBar';
//= import types
import type { CustomerType } from '../../modules/reducers/OauthReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	customer: CustomerType,
}

const App = (props: Props) => {
	const { customer } = props;

	return (
		<div>
			{customer
				&& (
					<Layout style={{ height: '100vh' }}>
						<Topbar userName={customer.userInfo?.firstName} />
						<CustomerRoutes />
					</Layout>
				)}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	customer: state.oauth.customer,
});

export default connect(mapStateToProps)(App);
