import React from 'react';
//= import components
import CPButton from '../../components/UiElements/Button';
//= create constants
const img404 = require('../../assets/img/404.png');

const FourZeroFour = () => (
	<div className="parent">
		<div>
			<img
				src={img404}
				alt="404"
			/>
		</div>
		<div>
			<h1>
				404

			</h1>
			<h3>
				Sorry, the page you visited does not exist

			</h3>
			<CPButton type="primary" href="/" text="return to home page" />
		</div>
	</div>
);

export default FourZeroFour;
