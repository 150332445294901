// @flow

const initialState = {
	error: false,
	activityLog: [],
	fetchingActivityLog: false,
	activityLogFetched: false,
	activityLogTotal: 0,
	providerLog: [],
	fetchingProviderLog: false,
	providerLogFetched: false,
	providerLogTotal: 0,
};

export type ActivityLog = {
	eventName: string,
	performer: {
		email: string,
	},
	owner: {
		email: string,
	},
	updatedAt: string,
	affectedModel: string,
	affectedObjectNew: Object,
	affectedObjectOld: Object,
	id: string,
}
export type ProviderLog = {
	id: string,
	name: string,
	action: string,
	createdAt: string,
}
export type LogsState = {
	error: Object | boolean,
	activityLog: Array<ActivityLog>,
	fetchingActivityLog: boolean,
	activityLogFetched: boolean,
	activityLogTotal: number,
	providerLog: Array<ProviderLog>,
	fetchingProviderLog: boolean,
	providerLogFetched: boolean,
	providerLogTotal: number,
}
export type LogsAction = {
	type: string,
	payload: {
		data: Array<Object>,
		headers: {
			'total-count': number,
		}
	},
	data: string,
	error: Object,
}

export default function logsReducer(
	state: LogsState = initialState,
	action: LogsAction,
): LogsState {
	switch (action.type) {
	case 'ACTIVITY_LOG': {
		return {
			...state,
			fetchingActivityLog: true,
			activityLogFetched: false,
			error: false,
		};
	}
	case 'ACTIVITY_LOG_SUCCESS': {
		return {
			...state,
			error: false,
			fetchingActivityLog: false,
			activityLogFetched: true,
			activityLog: action.payload.data,
			activityLogTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'ACTIVITY_LOG_FAIL': {
		return {
			...state,
			error: action.error,
			fetchingActivityLog: false,
			activityLogFetched: false,
		};
	}
	case 'PROVIDER_LOGS': {
		return {
			...state,
			fetchingProviderLog: true,
			providerLogFetched: false,
			error: false,
		};
	}
	case 'PROVIDER_LOGS_SUCCESS': {
		return {
			...state,
			error: false,
			fetchingProviderLog: false,
			providerLogFetched: true,
			providerLog: action.payload.data,
			providerLogTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'PROVIDER_LOGS_FAIL': {
		return {
			...state,
			error: action.error,
			fetchingProviderLog: false,
			providerLogFetched: false,
		};
	}
	default:
		return state;
	}
}
