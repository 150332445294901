// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

//= import components
import CopyComponent from '../../../components/UiElements/CopyComponent/index';
import Spin from '../../../components/UiElements/Spin/index';

//= import actions
import { generateApiKey, getApiKeys } from '../../../modules/actions/ApiKeyActions';

type Props = {
	token: string,
	generateApiKey: (string) => Promise<Object>,
	getApiKeys: (string) => Promise<Object>,
	isGeneratingApiKey: boolean,
	generateKey: boolean,
	applicationId: string,
}

const GenerateApiKeyModal = ({
	token,
	generateApiKey: generateApiKeyAction,
	getApiKeys: getApiKeysAction,
	isGeneratingApiKey,
	generateKey,
	applicationId,
}: Props) => {
	useEffect(() => {
		if (generateKey) {
			generateApiKeyAction(applicationId).then(() => getApiKeysAction(applicationId));
		}
		// eslint-disable-next-line
	}, [generateKey]);

	return (
		<>
			{isGeneratingApiKey ? (
				<Spin loading />
			) : (
				<>
					<p>
						The API Key has been generated.
					</p>
					<p>
						Securely store it, you won't be able to access it again. Do not share your key publicly.
					</p>
					<br />
					<br />
					<CopyComponent
						text={token}
						content={token}
					/>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	token: state.apiKey?.apiKey?.token,
	isGeneratingApiKey: state.apiKey.isGeneratingApiKey,
});

const mapDispatchToProps = {
	generateApiKey,
	getApiKeys,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateApiKeyModal);
