// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../components/UiElements/Title';
import ExploreGroups from './containers/ExploreGroups';
import ExploreSections from './containers/Sections';
import FormModal from '../../components/UiElements/Modal/FormModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
import GroupAndSectionForm from './components/GroupAndSectionForm';
//= import methods
import {
	getExploreGroups, addExploreGroup, getExploreGroup, updateExploreGroup,
} from '../../modules/actions/ExploreGroupActions';
import {
	addGroupToSection, getSections, addSection, getSection, updateSection,
} from '../../modules/actions/SectionActions';
import getFile from '../../modules/actions/FilesActions';
//= import types
import type { Section } from '../../modules/reducers/SectionReducer';
import type { ExploreGroup } from '../../modules/reducers/ExploreGroupReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	activeTab: string,
	getExploreGroups: (string) => Promise<ExploreGroup>,
	addExploreGroup: (string, Object) => Promise<Object>,
	addGroupToSection: (string, string, string) => void,
	getSections: (string) => void,
	getExploreGroup: (string, string) => Promise<Object>,
	updateExploreGroup: (string, string, Object) => Promise<Object>,
	addSection: (string, Object) => Promise<Object>,
	getSection: (string, string) => Promise<Object>,
	updateSection: (string, string, Object) => Promise<Object>,
	getFile: (string) => Promise<Object>,
	sections: Array<Section>,
	match: {
		params: {
			applicationId: string
		}
	},
	location: {
		pathname: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}
type ResType = {
	payload: {
		data: Section | ExploreGroup,
	}
}
type Data = {
	name: string,
	description: string,
	coverPhoto: string,
	icon: string,
	display: string,
	order: number,
	sectionId?: string,
}

const Explore = (props: Props) => {
	const {
		activeTab,
		match: { params: { applicationId } },
		history: { push },
		sections,
		getExploreGroups: getExploreGroupsAction,
		getSections: getSectionsAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [title, setTitle] = useState('');
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [dataToEdit, setDataToEdit] = useState({});
	const [type, setType] = useState('');
	const [icon, setIcon] = useState('');
	const [coverPhoto, setCoverPhoto] = useState('');
	const [resetForm, setResetForm] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getExploreGroupsAction(applicationId);
		getSectionsAction(applicationId);
	}, [getExploreGroupsAction, getSectionsAction, applicationId]);

	const viewSingle = (data) => {
		push(`/application/${applicationId}/system/explore/${activeTab}/${data.id}`);
	};

	const handleCreateClick = () => {
		props.getSections(applicationId);
		setEdit(true);
		setTitle(`Create New Explore ${activeTab}`);
		setType('create');
		setResetForm(true);
	};

	const formRef = useRef(null);

	const handleEditClick = (data: Object) => {
		if (activeTab === 'Group') {
			props.getExploreGroup(applicationId, data.id)
				.then((res: ResType) => {
					setDataToEdit(res.payload.data);
					setTitle(`Edit ${res.payload.data.name} Group`);
					if (res.payload.data.icon) {
						const iconUrl = new URL(res.payload.data.icon);
						const iconId = iconUrl.pathname;
						props.getFile(iconId)
							.then((response) => setIcon(response.payload.data.url));
					}
					if (res.payload.data.coverPhoto) {
						const coverUrl = new URL(res.payload.data.coverPhoto);
						const coverId = coverUrl.pathname;
						props.getFile(coverId)
							.then((response) => setCoverPhoto(response.payload.data.url));
					}
					if (formRef.current) {
						formRef.current.setState({
							selected: res.payload.data.display,
						});
					}
				})
				.then(() => {
					setEdit(true);
					setType(edit);
					setResetForm(true);
				});
		} else {
			props.getSection(applicationId, data.id)
				.then((res: ResType) => {
					setDataToEdit(res.payload.data);
					setTitle(`Edit ${res.payload.data.name} Section`);
					if (res.payload.data.icon) {
						const iconUrl = new URL(res.payload.data.icon);
						const iconId = iconUrl.pathname;
						props.getFile(iconId)
							.then((response) => setIcon(response.payload.data.url));
					}
					if (res.payload.data.coverPhoto) {
						const coverUrl = new URL(res.payload.data.coverPhoto);
						const coverId = coverUrl.pathname;
						props.getFile(coverId)
							.then((response) => setCoverPhoto(response.payload.data.url));
					}
					if (formRef.current) {
						formRef.current.setState({
							selected: res.payload.data.display,
						});
					}
				})
				.then(() => {
					setEdit(true);
					setType(edit);
					setResetForm(true);
				});
		}
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setEdit(false);
		setWarning(false);
		setIcon('');
		setCoverPhoto('');
		setResetForm(false);
	};

	const handleWarningCancel = () => {
		setEdit(true);
		setWarning(false);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarning(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response ? error.error.response.data.message : 'Error',
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitAdd = (data: Data, sectionId: string) => {
		setButtonLoading(true);
		if (activeTab === 'Group') {
			props.addExploreGroup(applicationId, data)
				.then((res: ResType) => {
					setButtonLoading(false);
					setWarning(false);
					setResetForm(false);
					props.addGroupToSection(applicationId, sectionId, res.payload.data.id);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else {
			props.addSection(applicationId, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setResetForm(false);
					setIcon('');
					setCoverPhoto('');
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const submitEdit = (data: Data) => {
		setButtonLoading(true);
		if (activeTab === 'Group') {
			props.updateExploreGroup(applicationId, dataToEdit.id, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setIcon('');
					setCoverPhoto('');
					setResetForm(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else {
			props.updateSection(applicationId, dataToEdit.id, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setIcon('');
					setCoverPhoto('');
					setResetForm(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const continueAdding = (value: Data, sectionId: string) => {
		setWarning(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage(type === 'create' ? { firstPart: `Add Explore ${activeTab}.` }
			: {
				firstPart: `Once you confirm these changes,
				they will affect ${dataToEdit.name} Explore ${activeTab}`,
				secondPart: 'It is advised to proceed editing with caution.',
			});
		setFooter([
			{
				type: 'cancel',
				action: handleWarningCancel,
				text: 'go back'
				,
			},
			{
				type: 'continue',
				action: type === 'create'
					? () => submitAdd(value, sectionId)
					: () => submitEdit(value),
				text: type === 'create'
					? `create ${activeTab}`
					: `edit ${activeTab}`,
			},
		]);
	};


	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setEdit(false);
			setWarning(true);
			setWarningTitle('Warrning');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarning(false);
			setIcon('');
			setCoverPhoto('');
			setResetForm(false);
		}
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Explore"
				buttons={[
					{
						action: handleCreateClick,
						icon: 'Plus',
						text: `create new ${activeTab}`,
					},
				]}
				place={activeTab}
				tabs={[
					{
						key: 'Group',
						title: 'EXPLORE GROUP',
					},
					{
						key: 'Section',
						title: 'EXPLORE SECTIONS',
					},
				]}
				tab="explore"
			/>
			{activeTab === 'Group'
				&& (
					<ExploreGroups
						applicationId={applicationId}
						viewSingle={viewSingle}
						handleEditClick={handleEditClick}
					/>
				)}
			{activeTab === 'Section'
				&& (
					<ExploreSections
						applicationId={applicationId}
						viewSingle={viewSingle}
						handleEditClick={handleEditClick}
					/>
				)}
			{resetForm
				&& (
					<FormModal
						title={title}
						visible={edit}
						cancelFunction={handleEditCancel}
						form={(
							<GroupAndSectionForm
								submitChanges={continueAdding}
								handleCancelClick={handleEditCancel}
								applicationId={applicationId}
								sections={sections}
								data={dataToEdit}
								type={type}
								icon={icon}
								coverPhoto={coverPhoto}
								wrappedComponentRef={formRef}
								place={activeTab}
							/>
						)}
					/>
				)}
			<WarningModal
				title={warningTitle}
				visible={warning}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
		</>
	);
};


const mapStateToProps = (state: State) => ({
	activeTab: state.ui.exploreTab,
	sections: state.section.sections,
});

const mapDispatchToProps = {
	getExploreGroups,
	addExploreGroup,
	addGroupToSection,
	getSections,
	getExploreGroup,
	updateExploreGroup,
	addSection,
	getSection,
	updateSection,
	getFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
