// @flow

import { GET_FILE } from '../const/types';

export default function getFile(fileId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_FILE,
		payload: {
			request: {
				url: `/files${fileId}`,
			},
		},
	});
}
