// @flow

import React, {
	useState, useEffect, useRef, useCallback,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Select from '../../../components/UiElements/Select';
import Switch from '../../../components/UiElements/Switch';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import PostTradeSettlementSymbols from '../components/PostTradeSettlementSymbols';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import EnableSettlementForm from '../components/EnableSettlementForm';
//= import actions
import { getAppFeatures, updateAppFeatures } from '../../../modules/actions/FeaturesAction';
import { getGroups } from '../../../modules/actions/GroupAction';
import {
	getAppSymbols, getGroupSymbols, updateSymbol, updateGroupSymbol,
} from '../../../modules/actions/SymbolActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { Group } from '../../../modules/reducers/GroupReducer';
import type { Feature } from '../../../modules/reducers/FeatureReducer';
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/features.module.scss';

type SymbolsResponse = {
	payload: {
		data: Array<Symbol>,
	}
}
type Props = {
	getAppFeatures: (string) => Promise<Object>,
	updateAppFeatures: (string, string, Object) => Promise<Object>,
	applicationId: string,
	groups: Array<Group>,
	getAppSymbols: (string, string, number, string) => Promise<SymbolsResponse>,
	getGroups: (string) => Promise<Object>,
	getGroupSymbols: (string, string, number, string) => Promise<SymbolsResponse>,
	updateSymbol: (string, string, Object) => Promise<Object>,
	updateGroupSymbol: (string, string, Object) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	symbolsSearch: string,
	currentPage: number,
	symbolsTotal: number,
	groupSymbolsTotal: number,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		goBack: () => void,
	}
}
type FeaturesResponse = {
	payload: {
		data: Array<Feature>,
	},
}

type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

const PostTradeSettlement = (props: Props) => {
	const {
		match: { params: { applicationId } },
		groups,
		getAppSymbols: getAppSymbolsAction,
		getGroupSymbols: getGroupSymbolsAction,
		getGroups: getGroupsAction,
		updateSymbol: updateSymbolAction,
		updateGroupSymbol: updateGroupSymbolAction,
		getAppFeatures: getAppFeaturesAction,
		updateAppFeatures: updateAppFeaturesAction,
		setCurrentPage: setCurrentPageAction,
		symbolsSearch,
		currentPage,
		symbolsTotal,
		groupSymbolsTotal,
	} = props;

	const [settlement, setSettlement] = useState({});
	const [toggle, setToggle] = useState(false);
	const [switchKey, setSwitchKey] = useState('first');
	const [data, setData] = useState({});
	const [symbols, setSymbols] = useState([]);
	const [edit, setEdit] = useState(false);
	const [title, setTitle] = useState('');
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [add, setAdd] = useState(false);
	const [resetForm, setResetForm] = useState(false);
	const [symbolToEdit, setSymbolToEdit] = useState({});
	const [loading, setLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [symbolType, setSymbolType] = useState('applicationSymbols');
	const [symbolUpdated, setSymbolUpdated] = useState(false);

	useEffect(() => {
		getGroupsAction(applicationId);
	}, [getGroupsAction, applicationId]);

	useEffect(() => {
		async function fetchData() {
			const response: FeaturesResponse = await getAppFeaturesAction(applicationId);
			const featureData: ?Feature = response.payload.data.find((feature: Feature) => feature.featureId === 'post_trade_settlement');
			if (featureData) {
				setSettlement(featureData);
			}
			if (!featureData?.enabled) {
				setLoading(false);
				setSymbols([]);
			}
		}
		fetchData();
	}, [getAppFeaturesAction, applicationId, data]);

	useEffect(() => {
		const queryParam: string = queryString.stringify(
			{ justPostTradeSettlement: true, search: symbolsSearch },
		);
		async function getData() {
			if (symbolType === 'applicationSymbols') {
				const appRes: SymbolsResponse = await getAppSymbolsAction(applicationId, 'numbered-pages', (currentPage - 1) * StatusHelpers.paginationLimit, queryParam);
				setSymbols(appRes.payload.data);
				setLoading(false);
				setTitle('Enable Post-Trade Settlement on Application Symbol');
			} else {
				const groupId = symbolType;
				const groupRes = await getGroupSymbolsAction(groupId, 'numbered-pages', (currentPage - 1) * StatusHelpers.paginationLimit, queryParam);
				setSymbols(groupRes.payload.data);
				setLoading(false);
				setTitle('Enable Post-Trade Settlement on Group Symbol');
			}
		}
		getData();
	}, [
		applicationId,
		currentPage,
		symbolsSearch,
		symbolType,
		symbolUpdated,
		getAppSymbolsAction,
		getGroupSymbolsAction,
	]);

	const submitUpdate = useCallback(() => {
		updateAppFeaturesAction(applicationId, settlement.featureId, data)
			.then(() => {
				setData({});
				setToggle(false);
			})
			.catch(() => setSwitchKey('changed'));
	}, [applicationId, data, settlement.featureId, updateAppFeaturesAction]);

	const handleToggle = (value: boolean): void => {
		const updateData = { enabled: value };
		setData(updateData);
	};

	useEffect(() => {
		if (data.enabled) {
			submitUpdate();
		} else if (data.enabled === false) {
			setToggle(true);
		}
	}, [data, submitUpdate]);

	const handleToggleCancel = () => {
		setToggle(false);
		setSwitchKey('changed');
		setData({});
	};

	const handlePageChange = (page: number) => {
		setCurrentPageAction('settlementPage', page);
	};

	const changeSymbols = (value: string) => {
		setCurrentPageAction('settlementPage', 1);
		setSymbolType(value);
	};

	const addFormRef = useRef(null);

	const handleEnableSettlement = () => {
		setAdd(true);
		setEdit(false);
		setResetForm(true);
	};

	const handleEditSettlement = (value: Symbol) => {
		setEdit(true);
		setAdd(true);
		setTitle(`Edit ${value.displayName} Post-Trade Settlement`);
		setResetForm(true);
		setSymbolToEdit(value);
	};

	const handleAddWarningCancel = () => {
		setAdd(true);
		setWarning(false);
	};

	const handleErrorCancel = () => {
		if (addFormRef.current) {
			addFormRef.current.handleReset();
			setWarning(false);
			setResetForm(false);
			setSymbolToEdit({});
		}
	};

	const handleAddCancel = () => {
		if ((addFormRef.current && addFormRef.current.state.selectedKey.length)
			|| (addFormRef.current && addFormRef.current.props.form.isFieldsTouched())) {
			setAdd(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleAddWarningCancel, text: 'continue adding' },
			]);
		} else {
			setEdit(false);
			setAdd(false);
			setWarning(false);
			setResetForm(false);
			setSymbolToEdit({});
		}
	};

	const handleEditWarningCancel = () => {
		setEdit(true);
		setWarning(false);
		setAdd(true);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarning(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response ? error.error.response.data.message : 'Error',
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitChanges = (value: Object, symbolData) => {
		setButtonLoading(true);
		if (addFormRef.current) {
			addFormRef.current.handleReset();
		}
		const groupId = symbolType;
		if (groupId !== 'applicationSymbols') {
			updateGroupSymbolAction(
				groupId,
				value.id,
				symbolData,
			)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setResetForm(false);
					setSymbolToEdit({});
					setSymbolUpdated(!symbolUpdated);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else {
			updateSymbolAction(
				applicationId,
				value.id,
				symbolData,
			)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setResetForm(false);
					setSymbolToEdit({});
					setSymbolUpdated(!symbolUpdated);
				})
				.catch((error: ErrorType) => handleOnError(error));
			setEdit(false);
			setAdd(false);
		}
	};

	const continueAdding = (value: Symbol, symbolData) => {
		setWarning(true);
		setAdd(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Post-Trade Settlement will be Enabled on ${value.displayName} symbol.`,
		});
		setFooter([
			{ type: 'cancel', action: handleAddWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(value, symbolData), text: 'save changes' },
		]);
	};

	const continueEditing = (value: Symbol, symbolData) => {
		setWarning(true);
		setAdd(false);
		setWarningTitle('Are you sure?');

		setMessage({
			firstPart: `Post-Trade Settlement on ${value.displayName} will be changed.`,
		});
		setFooter([
			{ type: 'cancel', action: handleEditWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(value, symbolData), text: 'save changes' },
		]);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const applicationSymbolsOptions = [{ id: 'applicationSymbols', name: 'Application Symbols' }];

	const { Option } = Select;

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Post-Trade Settlement"
				searchComponent={{
					placeholder: 'Search For Symbols...',
					disabled: true,
				}}
				place="settlement"
				buttons={[{
					text: 'enable Post-Trade Settlement on symbol',
					action: handleEnableSettlement,
					disabled: !settlement.enabled,
				}]}
			>
				<div className={styles.feature}>
					<Switch
						key={switchKey}
						checked={settlement.enabled}
						onChange={(value) => handleToggle(value)}
					/>
					<div>
						<Select
							style={{ width: '100%' }}
							className="select"
							placeholder="Symbol Type"
							dropdownClassName="options"
							onChange={changeSymbols}
							defaultValue={symbolType}
						>
							{applicationSymbolsOptions.map((el) => (
								<Option value={el.id} key={el.id}>
									{el.name}
								</Option>
							))}
							<Option key="groupSymbols" disabled className={styles.options}>
								<span className="bold">GROUP SYMBOLS</span>
							</Option>
							{groups.map((group) => (
								<Option value={group.id} key={group.name}>
									{`in ${group.name} Group`}
								</Option>
							))}
						</Select>
					</div>
				</div>
			</Title>
			<div className="container">
				{settlement.featureId && (
					<div>
						{loading
							? <Spin spinning={loading} />
							: (
								<PostTradeSettlementSymbols
									applicationId={applicationId}
									symbols={symbols}
									symbolsTotal={symbolType === 'applicationSymbols' ? symbolsTotal : groupSymbolsTotal}
									handleEditSettlement={handleEditSettlement}
									loading={loading}
									headerHeight={64}
									currentPage={currentPage}
									handlePageChange={handlePageChange}
									groupId={symbolType}
									groupSymbolsTotal={groupSymbolsTotal}
								/>
							)}
						<DeleteModal
							okFunction={submitUpdate}
							cancelFunction={handleToggleCancel}
							visible={toggle}
							btnText="DISABLE"
							message="You&apos;re about to disable Post-Trade Settlement feature for all your Clients across entire Application."
						/>
						{resetForm && (
							<FormModal
								title={title}
								visible={add}
								cancelFunction={handleAddCancel}
								form={(
									<EnableSettlementForm
										submitChanges={edit ? continueEditing : continueAdding}
										edit={edit}
										symbolToEdit={symbolToEdit}
										handleCancelClick={handleAddCancel}
										applicationId={applicationId}
										groups={groups}
										wrappedComponentRef={addFormRef}
										groupId={symbolType}
									/>
								)}
							/>
						)}
						<WarningModal
							title={warningTitle}
							visible={warning}
							cancelFunction={handleErrorCancel}
							footer={footer}
							message={message}
							loading={buttonLoading}
						/>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	groups: state.group.groups,
	symbolsTotal: state.symbol.groupSymbolsTotal,
	groupSymbolsTotal: state.symbol.groupSymbolsTotal,
	symbolsSearch: state.ui.settlementSearch,
	currentPage: state.ui.settlementPage,
});
const mapDispatchToProps = {
	getAppFeatures,
	updateAppFeatures,
	getGroups,
	getGroupSymbols,
	getAppSymbols,
	updateSymbol,
	updateGroupSymbol,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostTradeSettlement);
