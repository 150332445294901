// @flow

import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Table from '../../../components/UiElements/Table';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getGroupUsers } from '../../../modules/actions/GroupAction';
import { searchUsers } from '../../../modules/actions/UsersActions';
//= import types
import type { Group } from '../../../modules/reducers/GroupReducer';
import type { User, UserInfo } from '../../../modules/reducers/UsersReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	form: *,
	submitChanges: (string, string, string, Object) => void,
	handleCancelClick: () => void,
	groups: Array<Group>,
	users: Array<User>,
	groupUsersTotal: number,
	getGroupUsers: (string, string, string, number | string, number) => Promise<Object>,
	searchUsers: (string, string) => Promise<Object>,
	applicationId: string,
	groupId: string,
	searchData: string,
	match: {
		params: {
			applicationId: string,
			groupId: string,
		},
	},
}

type LocalState = {
	errorMessage: string,
	user: User,
	groupUsers: Array<User>,
	selectedKey: Array<string>,
	search: boolean,
	searchUsersResult: Array<User>,
	infiniteScrollLoading: boolean,
}

class UserTransferForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		user: {},
		groupUsers: [],
		selectedKey: [],
		search: false,
		searchUsersResult: [],
		infiniteScrollLoading: false,
	}

	loadingRef = React.createRef();

	componentDidMount() {
		const { applicationId, groupId } = this.props;
		const date = new Date();
		this.props.getGroupUsers(applicationId, groupId, 'infinite-scroll', date.toISOString(), 50)
			.then((res) => this.setState({
				groupUsers: res.payload.data,
			}));
		const tableContent = document.querySelector('.ant-table-tbody');
		tableContent.addEventListener('scroll', this.handleEvent);
	}

	componentWillUnmount() {
		this.debounceSearch.cancel();
	}

	handleEvent = (e) => {
		const { applicationId, groupId } = this.props;
		const { groupUsers } = this.state;
		const maxScroll = e.target.scrollHeight - e.target.clientHeight;
		const currentScroll = e.target.scrollTop;
		if (currentScroll === maxScroll) {
			this.setState({
				infiniteScrollLoading: true,
			});
			const paginationOffset = groupUsers[groupUsers.length - 1].createdAt;
			this.props.getGroupUsers(applicationId, groupId, 'infinite-scroll', paginationOffset, 50)
				.then((res) => this.setState((prevState) => ({
					groupUsers: [...prevState.groupUsers, ...res.payload.data],
					infiniteScrollLoading: false,
				})));
		}
	}

	search = (keyword) => {
		const { applicationId, groupId } = this.props;
		if (keyword) {
			this.props.searchUsers(applicationId, keyword)
				.then((res) => this.setState({
					search: true,
					searchUsersResult: res.payload.data,
				}));
		} else {
			this.setState({
				search: false,
			});
			const date = new Date();
			this.props.getGroupUsers(applicationId, groupId, 'infinite-scroll', date.toISOString(), 50);
		}
	}

	debounceSearch = debounce(this.search, 500);

	handleSearchChange = (e) => {
		const keyword = e.target.value;
		this.debounceSearch(keyword);
	}

	onUserSelect = (key, value) => {
		this.setState({
			user: value[0],
			selectedKey: key,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges, groups } = this.props;
		const { user } = this.state;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const userGroup = groups.find((group) => group.id === values.groupId);
				const groupName = userGroup ? userGroup.name : '';
				const userName = user.userInfo ? `${user.userInfo.firstName || 'Name Not Set'} ${user.userInfo.lastName || ''}` : 'Name Not Set';
				submitChanges(user.id, userName, groupName, values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			selectedKey: [],
			user: {},
			groupUsers: [],
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			groups,
		} = this.props;
		const {
			errorMessage, search, groupUsers, selectedKey, searchUsersResult, infiniteScrollLoading,
		} = this.state;
		const columns = [
			{
				title: 'NAME',
				dataIndex: 'userInfo',
				key: 'name',
				width: 192,
				render: (userInfo: UserInfo) => (
					<div>
						{userInfo ? `${userInfo.firstName || 'Name Not Set'} ${userInfo.lastName || ''}` : 'Name Not Set'}
					</div>
				),
			},
			{
				title: 'E-MAIL',
				dataIndex: 'email',
				key: 'email',
				width: 272,
			},
		];
		const rowSelection = {
			onChange: this.onUserSelect,
			type: 'radio',
			selectedRowKeys: selectedKey,
		};
		const FormItem = Form.Item;
		const groupLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const { Option } = Select;
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<Input.Search
					className="search transfer-search"
					placeholder="Search For Client..."
					allowClear
					onChange={this.handleSearchChange}
				/>
				<div ref={this.loadingRef} onScroll={this.handleEvent} className="scroll-div">
					<Table
						loading={infiniteScrollLoading}
						rowSelection={rowSelection}
						rowKey={(record) => record.id}
						columns={columns}
						dataSource={search ? searchUsersResult : groupUsers}
						pagination={false}
						scroll={{ y: (window.innerHeight - 512) }}
					/>
				</div>
				<Form.Item label="TRANSFER TO GROUP" {...groupLayout}>
					{getFieldDecorator('groupId', {
						rules: [{
							required: true,
							message: 'Please select Group!',
						}],
					})(
						<Select
							allowClear
							showSearch
							placeholder="Please select Group"
						>
							{groups.map((group) => (
								<Option
									value={group.id}
									key={group.id}
								>
									{group.name}
								</Option>
							))}
						</Select>,
					)}
				</Form.Item>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched() || !selectedKey.length} text="transfer user" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const TransferForm = Form.create()(UserTransferForm);

const mapStateToProps = (state: State) => ({
	users: state.users.users,
});

const mapDispatchToProps = {
	getGroupUsers,
	searchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferForm);
