// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import types
import type { Event } from '../../../modules/reducers/WebhooksReducer';
import type { EventHandler } from '../../../modules/reducers/EventSubscriptionReducer';
import type { FormProps } from '../../../modules/types/FormTypes';

type Values = {
	name: string,
	events: Array<string>,
	handler: string,
}
type Props = {
	form: FormProps,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	events: Array<Event>,
	handlers: Array<EventHandler>,
	title: string,
	errorState: string,
	placeholder: string,
}
type LocalState = {
	errorMessage: string,
}

class NewTagForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
				this.props.form.resetFields();
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			errorState,
			placeholder,
		} = this.props;
		const FormItem = Form.Item;
		const feeLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		const handleReset = () => {
			this.props.form.resetFields();
			this.setState({
				errorMessage: '',
			});
			handleCancelClick();
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<p>{this.props.title}
				</p>
				<FormItem label="NAME" {...feeLayout} help="">
					{getFieldDecorator('value', {
						rules: [
							{
								required: true,
								message: "You can't submit an empty tag",
							},
							{
								max: 50,
								message: 'Maximum length of 50 character exceeded',
							},
							{
								whitespace: true,
								message: "You can't submit an empty tag",
							},
						],
						initialValue: placeholder,
						validateFirst: true,
					})(
						<Input
							name="value"
							placeholder={placeholder || 'Enter your tag name'}
						/>,
					)}
				</FormItem>
				{this.state.errorMessage && (
					<ErrorMessage message={this.state.errorMessage} />
				)}
				{errorState && (
					<ErrorMessage message={errorState} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleReset} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} text={this.props.title} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const TagForm = Form.create()(NewTagForm);

export default TagForm;
