// @flow

import React from 'react';
import moment from 'moment';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Tag from '../../../components/UiElements/Tag';
//= import types
import type { WebhookJobsMapped } from '../../../modules/selectors/WebhooksSelector';

type Props = {
	webhookJobs: Array<WebhookJobsMapped>,
	isFetchingWebhookJobs: boolean,
	webhookJobsTotal: number,
	currentPage: number,
	handlePageChange: (number) => void,
	applicationId: string,
	filterHeight: number,
	numberOnPage: number,
}

function WebhooksLog(props: Props) {
	const {
		webhookJobs,
		isFetchingWebhookJobs,
		webhookJobsTotal,
		currentPage,
		handlePageChange,
		applicationId,
		filterHeight,
		numberOnPage,
	} = props;

	const columns = [
		{
			title: 'created at',
			dataIndex: 'createdAt',
			key: 'deliveredAt',
			width: 160,
			render: (text: string) => (
				<div>
					{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'}
				</div>
			),
		},
		{
			title: 'endpoint url',
			dataIndex: 'applicationWebhook.baseUrl',
			key: 'applicationWebhook',
			width: 288,
		},
		{
			title: 'event',
			dataIndex: 'eventName',
			key: 'eventName',
			width: 160,
		},
		{
			title: 'delivery attempts',
			dataIndex: 'tried',
			key: 'tried',
			width: 144,
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 144,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
	];

	return (
		<div>
			{!isFetchingWebhookJobs
				? (
					<CustomTable
						columns={columns}
						data={webhookJobs}
						total={webhookJobsTotal}
						expandRowByClick
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={filterHeight}
						place="webhookLogs"
						numberOnPage={numberOnPage}
						applicationId={applicationId}
					/>
				)
				: <Spin spinning={isFetchingWebhookJobs} />}
		</div>
	);
}

export default WebhooksLog;
