// @flow

import type { Wallet } from './WalletReducer';

const initialState = {
	isFetchingAccounts: false,
	isFetchedAccounts: false,
	error: false,
	accounts: [],
	accountsTotal: 0,
	activeAccount: '',
	account: {},
	isFetchingAccount: false,
	isFetchedAccount: false,
	accountStatistics: {},
	isFetchingAccountStatistic: false,
	isFetchedAccountStatistic: false,
	accountPositions: [],
	accountPositionsTotal: 0,
	isFetchingAccountPositions: false,
};
export type Account = {
	id: string,
	name: string,
	status: string,
	primary: boolean,
	wallets: Array<Wallet>,
	reportingCurrency: string,
}
export type Statistic = {
	instrumentId: string,
	reportingCurrencyNet: string,
	walletId: string,
	walletNet: {
		netDeposits: string,
		totalDeposits: string,
		totalWithdrawals: string,
	}
}
export type Statistics = {
	reportingCurrency: String,
	statistics: Array<Statistic>,
}
export type Position = {
	averagePrice: string,
	costBasis: string,
	marketPrice: string,
	marketValue: string,
	positionQuantity: string,
	quotingInstrument: {
		id: string,
		precision: string,
	},
	realizedPnL: string,
	side: string,
	symbolId: string,
	unrealizedPnL: string,
}
export type AccountsState = {
	error: Object | boolean,
	isFetchingAccounts: boolean,
	isFetchedAccounts: boolean,
	accounts: Array<Account>,
	accountsTotal: number,
	activeAccount: string,
	account: Account,
	isFetchingAccount: boolean,
	isFetchedAccount: boolean,
	accountStatistics: Statistics,
	isFetchingAccountStatistic: boolean,
	isFetchedAccountStatistic: boolean,
	accountPositions: Array<Position>,
	accountPositionsTotal: number,
	isFetchingAccountPositions: boolean,
}
type AccountsAction = {
	type:
	| 'ACCOUNTS'
	| 'ACCOUNTS_FAIL'
	| 'ACCOUNTS_SUCCESS',
	payload: {
		headers: {
			'total-count': number,
		},
		data: Array<Account>
	},
	error: {
		code: number,
		message: string,
	}
}
type AccountAction = {
	type:
	| 'ACCOUNT'
	| 'ACCOUNT_FAIL'
	| 'ACCOUNT_SUCCESS',
	payload: {
		data: Account,
	},
	data: {
		account: Account,
	},
	error: {
		code: number,
		message: string,
	}
}
type ActiveAccountAction = {
	type:
	| 'ACTIVE_ACCOUNT',
	data: {
		account: string,
	},
}
type StatisticsAction = {
	type:
	| 'GET_ACCOUNT_STATISTICS'
	| 'GET_ACCOUNT_STATISTICS_FAIL'
	| 'GET_ACCOUNT_STATISTICS_SUCCESS',
	payload: {
		data: Statistics,
	},
	error: {
		code: number,
		message: string,
	}
}
type PositionsAction = {
	type:
	| 'GET_ACCOUNT_POSITIONS'
	| 'GET_ACCOUNT_POSITIONS_FAIL'
	| 'GET_ACCOUNT_POSITIONS_SUCCESS',
	payload: {
		data: Array<Position>,
	},
	error: {
		code: number,
		message: string,
	}
}
export type Actions = AccountAction
| AccountsAction
| StatisticsAction
| ActiveAccountAction
| PositionsAction;

export default function accountsReducer(
	state: AccountsState = initialState,
	action: Actions,
): AccountsState {
	switch (action.type) {
	case 'ACCOUNTS':
		return {
			...state,
			isFetchingAccounts: true,
			isFetchedAccounts: false,
			error: false,
			accounts: [],
		};
	case 'ACCOUNTS_FAIL':
		return {
			...state,
			isFetchingAccounts: false,
			isFetchedAccounts: false,
			error: action.error,
		};
	case 'ACCOUNTS_SUCCESS':
		return {
			...state,
			isFetchingAccounts: false,
			isFetchedAccounts: true,
			error: false,
			accounts: action.payload.data,
			accountsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'ACCOUNT':
		return {
			...state,
			isFetchingAccount: true,
			isFetchedAccount: false,
			error: false,
			account: {},
		};
	case 'ACCOUNT_FAIL':
		return {
			...state,
			isFetchingAccount: false,
			isFetchedAccount: false,
			error: action.error,
			account: {},
		};
	case 'ACCOUNT_SUCCESS':
		return {
			...state,
			isFetchingAccount: false,
			isFetchedAccount: true,
			error: false,
			account: action.payload.data,
		};
	case 'ACTIVE_ACCOUNT':
		return {
			...state,
			activeAccount: action.data.account,
		};
	case 'GET_ACCOUNT_STATISTICS':
		return {
			...state,
			isFetchingAccountStatistics: true,
			isFetchedAccountStatistics: false,
			error: false,
			accountStatistics: {},
		};
	case 'GET_ACCOUNT_STATISTICS_FAIL':
		return {
			...state,
			isFetchingAccountStatistics: false,
			isFetchedAccountStatistics: false,
			error: action.error,
		};
	case 'GET_ACCOUNT_STATISTICS_SUCCESS':
		return {
			...state,
			isFetchingAccountStatistics: false,
			isFetchedAccountStatistics: true,
			error: false,
			accountStatistics: action.payload.data,
		};
	case 'GET_ACCOUNT_POSITIONS':
		return {
			...state,
			isFetchingAccountPositions: true,
			error: false,
			accountPositions: [],
		};
	case 'GET_ACCOUNT_POSITIONS_FAIL':
		return {
			...state,
			isFetchingAccountPositions: false,
			error: action.error,
		};
	case 'GET_ACCOUNT_POSITIONS_SUCCESS':
		return {
			...state,
			isFetchingAccountPositions: false,
			error: false,
			accountPositions: action.payload.data,
			accountPositionsTotal: parseInt(action.payload.headers['x-count'], 10),
		};
	default:
		return state;
	}
}
