// @flow

import {
	CREATE_USER_TAG,
	EDIT_USER_TAG,
	DELETE_USER_TAG,
	FETCH_USER_TAGS,
	ADD_TAG_TO_USER,
	GET_USER_TAGS,
	REMOVE_USER_TAG,

} from '../const/types';

// DASHBOARD ACTIONS BELOW:

// Create Tag in system panel
export function createUserTag(data: Object, applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: CREATE_USER_TAG,
		payload: {
			request: {
				method: 'POST',
				url: `applications/${applicationId}/tags`,
				data,
			},
		},
	});
}

// Edit Tag
export function editUserTag(applicationId: string, tagId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: EDIT_USER_TAG,
		payload: {
			request: {
				method: 'PATCH',
				url: `applications/${applicationId}/tags/${tagId}`,
				data,
			},
		},
	});
}

// Delete User Tag
export function deleteUserTag(applicationId: string, tagId: string) {
	return (dispatch: Function): void => dispatch({
		type: DELETE_USER_TAG,
		payload: {
			request: {
				method: 'DELETE',
				url: `applications/${applicationId}/tags/${tagId}`,
			},
		},
	});
}

// Fetch all created tags
export function fetchUserTags(
	applicationId: string,
	page: number = 1,
	pagLimit: number = 50,
) {
	return (dispatch: Function): void => dispatch({
		type: FETCH_USER_TAGS,
		payload: {
			request: {
				headers: {
					'pagination-limit': pagLimit,
					'pagination-offset': (page - 1) * pagLimit,
				},
				url: `applications/${applicationId}/tags`,
			},
		},
	});
}

// USER TAG ACTIONS BELOW:

// Get all user tags
export function getUserTags(
	userId: string,
	applicationId: string,
) {
	return (dispatch: Function) => dispatch({
		type: GET_USER_TAGS,
		payload: {
			request: {
				method: 'GET',
				url: `applications/${applicationId}/users/${userId}/tags/`,
			},
		},
	});
}

// Add tag to user account
export function addTagToUser(
	userId: string,
	tagId: string,
	applicationId: string,
) {
	return (dispatch: Function) => dispatch({
		type: ADD_TAG_TO_USER,
		payload: {
			request: {
				method: 'POST',
				url: `applications/${applicationId}/users/${userId}/tags/${tagId}`,
			},
		},
	});
}

// Remove Tag from User
export function removeUserTag(
	userId: string,
	applicationId: string,
	tagId: string,
) {
	return (dispatch: Function) => dispatch({
		type: REMOVE_USER_TAG,
		payload: {
			request: {
				method: 'DELETE',
				url: `applications/${applicationId}/users/${userId}/tags/${tagId}`,
			},
		},
	});
}
