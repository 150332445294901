// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import WebhookForm from '../components/WebhookForm';
import WebhookList from '../components/WebhookList';
import WebhookLog from '../components/WebhooksLog';
//= import methods
import {
	addWebhook, getEvents, getWebhookJobs, getActiveWebhooks, getInactiveWebhooks,
} from '../../../modules/actions/WebhooksActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import selectors
import { webhooksMappedSelector, webhookJobsMappedSelector } from '../../../modules/selectors/WebhooksSelector';
//= import types
import type { Webhook, Event } from '../../../modules/reducers/WebhooksReducer';
import type { WebhookJobsMapped } from '../../../modules/selectors/WebhooksSelector';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getActiveWebhooks: (applicationId: string, page: number, pageSize?: number) => Promise<Object>,
	getInactiveWebhooks: (applicationId: string, page: number, pageSize?: number) => Promise<Object>,
	addWebhook: (string, Object) => Promise<Object>,
	getEvents: () => Promise<Object>,
	getWebhookJobs: (applicationId: string, page: number, param: string, pageSize?: number) => Promise<Object>,
	activeWebhooks: Array<Webhook>,
	inactiveWebhooks: Array<Webhook>,
	isFetchingWebhooks: boolean,
	events: Array<Event>,
	webhookJobs: Array<WebhookJobsMapped>,
	isFetchingWebhookJobs: boolean,
	webhookJobsTotal: number,
	activeTab: string,
	activeWebhooksTotal: number,
	inactiveWebhooksTotal: number,
	webhooksPageSize: number,
	webhookLogsPageSize: number,
	archivedWebhooksPageSize: number,
	webhookLogPage: number,
	webhooksPage: number,
	archivedPage: number,
	setCurrentPage: (string, number) => void,
	webhookLogFilter: { [string]: string },
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		goBack: () => void,
	},
}

const WebhooksPage = (props: Props) => {
	const {
		activeWebhooks,
		inactiveWebhooks,
		isFetchingWebhooks,
		events,
		activeTab,
		webhookJobs,
		isFetchingWebhookJobs,
		webhookJobsTotal,
		webhookLogPage,
		webhooksPage,
		archivedPage,
		activeWebhooksTotal,
		inactiveWebhooksTotal,
		webhooksPageSize,
		webhookLogsPageSize,
		archivedWebhooksPageSize,
		webhookLogFilter,
		getActiveWebhooks: getActiveWebhooksAction,
		getInactiveWebhooks: getInactiveWebhooksAction,
		addWebhook: addWebhookAction,
		getEvents: getEventsAction,
		getWebhookJobs: getWebhookJobsAction,
		match: { params: { applicationId } },
		setCurrentPage: setCurrentPageAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [title, setTitle] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [loading, setLoading] = useState(true);
	const [footer, setFooter] = useState([]);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getEventsAction()
			.then(() => {
				getActiveWebhooksAction(applicationId, 1, webhooksPageSize);
				getInactiveWebhooksAction(applicationId, 1, archivedWebhooksPageSize);
				getWebhookJobsAction(applicationId, 1, '', webhookLogsPageSize);
			})
			.then(() => setLoading(false));
	}, [
		applicationId,
		getActiveWebhooksAction,
		getInactiveWebhooksAction,
		getWebhookJobsAction,
		webhooksPageSize,
		archivedWebhooksPageSize,
		webhookLogsPageSize,
		getEventsAction,
	]);

	useEffect(() => {
		getActiveWebhooksAction(applicationId, webhooksPage, webhooksPageSize);
		getInactiveWebhooksAction(applicationId, webhooksPage, archivedWebhooksPageSize);
	}, [webhooksPage, applicationId, getInactiveWebhooksAction, getActiveWebhooksAction, webhooksPageSize, archivedWebhooksPageSize]);

	useEffect(() => {
		getWebhookJobsAction(applicationId, webhookLogPage, queryString.stringify(webhookLogFilter, { arrayFormat: 'comma' }), webhookLogsPageSize);
	}, [webhookLogFilter, webhookLogPage, applicationId, getWebhookJobsAction, webhookLogsPageSize]);

	const filterRef = useRef(null);
	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [webhookLogFilter]);

	const handleCreateClick = () => {
		setEdit(true);
		setTitle('Add New Webhook');
	};

	const formRef = useRef(null);

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response.data.message,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitAddWebhook = (data) => {
		setButtonLoading(true);
		addWebhookAction(applicationId, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error) => handleOnError(error));
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const continueChanges = (value) => {
		setEdit(false);
		setWarningModal(true);
		setWarningTitle('Confirmation');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect Webhooks.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAddWebhook(value), text: 'confirm' },
		]);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const handlePageChange = (page: number) => {
		setCurrentPageAction(`${activeTab}Page`, page);
	};

	const handleActive = async (page: number, pageSize: number) => {
		await getActiveWebhooksAction(applicationId, page, pageSize);
		handlePageChange(page);
	};

	const handleInactive = async (page: number, pageSize: number) => {
		await getInactiveWebhooksAction(applicationId, page, pageSize);
		handlePageChange(page);
	};

	const handleWebhookJobs = async (page: number, pageSize: number) => {
		await getWebhookJobsAction(applicationId, page, queryString.stringify(webhookLogFilter, { arrayFormat: 'comma' }), pageSize);
		handlePageChange(page);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const logStatus = [{ id: 'succeeded' }, { id: 'undelivered' }, { id: 'failed' }];

	const buttons = activeTab === 'webhookLog'
		? [{
			action: () => setFilterVisible(true),
			text: 'filter',
			icon: 'Funnel',
		}]
		: [{
			action: handleCreateClick,
			icon: 'Plus',
			text: 'add new webhook',
		}];
	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="Webhooks"
					buttons={buttons}
					place="webhookLog"
					tabs={[
						{
							key: 'endpoints',
							title: 'ACTIVE',
						},
						{
							key: 'archived',
							title: 'ARCHIVED',
						},
						{
							key: 'webhookLog',
							title: 'LOG',
						},
					]}
					tab="webhook"
					fields={{
						status: logStatus,
						type: events.map((el) => ({ id: el.name })),
						dateRange: 'time',
					}}
					filterMode=""
					date={activeTab === 'webhookLog'}
					filter={activeTab === 'webhookLog'}
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					applicationId={applicationId}
				/>
			</div>
			{!loading
				? (
					<div className="container">
						{activeTab === 'endpoints' && (
							<WebhookList
								webhooks={activeWebhooks}
								isFetchingWebhooks={isFetchingWebhooks}
								webhooksTotal={activeWebhooksTotal}
								numberOnPage={webhooksPageSize}
								active
								currentPage={webhooksPage}
								handlePageChange={handleActive}
								events={events}
								applicationId={applicationId}
							/>
						)}
						{activeTab === 'webhookLog' && (
							<WebhookLog
								webhookJobs={webhookJobs}
								isFetchingWebhookJobs={isFetchingWebhookJobs}
								webhookJobsTotal={webhookJobsTotal}
								currentPage={webhookLogPage}
								handlePageChange={handleWebhookJobs}
								events={events}
								numberOnPage={webhookLogsPageSize}
								applicationId={applicationId}
								filterHeight={filterHeight}
							/>
						)}
						{activeTab === 'archived' && (
							<WebhookList
								webhooks={inactiveWebhooks}
								isFetchingWebhooks={isFetchingWebhooks}
								active={false}
								webhooksTotal={inactiveWebhooksTotal}
								currentPage={archivedPage}
								handlePageChange={handleInactive}
								events={events}
								numberOnPage={archivedWebhooksPageSize}
								applicationId={applicationId}
							/>
						)}
					</div>
				)
				: <Spin spinning={loading} />}
			<FormModal
				title={title}
				visible={edit}
				cancelFunction={handleEditCancel}
				form={(
					<WebhookForm
						submitChanges={continueChanges}
						handleCancelClick={handleEditCancel}
						events={events}
						wrappedComponentRef={formRef}
					/>
				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	webhooks: webhooksMappedSelector(state),
	isFetchingWebhooks: state.webhooks.isFetchingWebhooks,
	webhooksTotal: state.webhooks.webhooksTotal,
	events: state.webhooks.events,
	activeTab: state.ui.webhookTab,
	webhookJobs: webhookJobsMappedSelector(state),
	webhookJobsTotal: state.webhooks.webhookJobsTotal,
	isFetchingWebhookJobs: state.webhooks.isFetchingWebhookJobs,
	webhookLogPage: state.ui.webhookLogPage,
	webhooksPage: state.ui.endpointsPage,
	archivedPage: state.ui.archivedPage,
	webhookLogFilter: state.ui.webhookLogFilters,
	activeWebhooks: state.webhooks.activeWebhooks,
	inactiveWebhooks: state.webhooks.inactiveWebhooks,
	activeWebhooksTotal: state.webhooks.activeWebhooksTotal,
	inactiveWebhooksTotal: state.webhooks.inactiveWebhooksTotal,
	webhooksPageSize: state.ui.webhooksPageSize,
	webhookLogsPageSize: state.ui.webhookLogsPageSize,
	archivedWebhooksPageSize: state.ui.archivedWebhooksPageSize,
});

const mapDispatchToProps = {
	getActiveWebhooks,
	getInactiveWebhooks,
	addWebhook,
	getEvents,
	getWebhookJobs,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksPage);
