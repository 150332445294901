// @flow

import React from 'react';
import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
	size?: number,
	position?: string,
}

const Spin = (props: Props) => {
	const { size, position } = props;
	const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;
	return (
		<div className={position}>
			<AntSpin indicator={antIcon} />
		</div>
	);
};

Spin.defaultProps = {
	size: 24,
	position: 'fix',
};

export default Spin;
