// @flow

import React from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import { Table } from 'antd';
// import components
import CPButton from '../Button';
import Dropdown from '../Dropdown';
import Icon from '../Icon';
import DragableBodyRow from './BodyRow';
import ColumnChooser from './ColumnChooser';
import { setCurrentSorter, setCurrentPageSize } from '../../../modules/actions/UiActions';

type Props = {
	data: Array<Object>,
	columns: Array<Object>,
	currentPage?: number,
	total: number,
	headerHeight: number,
	moveAction?: Function,
	applicationId: string,
	width?: number,
	handlePageChange?: Function,
	onRowAction?: Function,
	expandRowByClick?: boolean,
	place: string,
	rowClassName?: string,
	defaultExpandedRowKeys?: Array<string>,
	numberOnPage?: number,
	setCurrentSorter: Function,
	loading: string,
	setCurrentPageSize: (string, number) => void,
	pageSizeOptions: Array<number>
}
type LocalState = {
	height: number,
	allOptions: Array<string>,
	selectedOptions: Array<string>,
	columnChooser: string,
	selectedColumns: Array<Object>,
}
class CustomTable extends React.Component<Props, LocalState> {
	state = {
		allOptions: [],
		selectedOptions: [],
		height: 0,
		columnChooser: 'close',
		selectedColumns: [],
	}

	components = {
		body: {
			row: DragableBodyRow,
		},
	};

	componentDidMount() {
		const { place, applicationId, columns } = this.props;
		const saved = localStorage.getItem(applicationId);
		const obj = saved ? JSON.parse(saved) : null;
		const allSaved = obj && obj[place] && obj[place].columns
			? obj[place].columns.allView
			: columns.map((el) => el.title);
		const selectedSaved = obj && obj[place] && obj[place].columns
			? obj[place].columns.savedView
			: columns.map((el) => el.title);
		const savedView = allSaved.filter((el) => selectedSaved.includes(el));
		this.setState({
			allOptions: allSaved,
			selectedOptions: selectedSaved,
			selectedColumns: savedView.map((col) => columns.find((column) => column.title === col)),
			height: window.innerHeight,
		});
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			height: window.innerHeight,
		});
	}

	moveRow = (dragIndex: number, hoverIndex: number) => {
		if (this.props.moveAction) {
			const dragRow = this.props.data[dragIndex];
			this.props.moveAction(dragRow, hoverIndex);
		}
	};

	// handleResize = (index) => (e, { size }) => {
	// 	this.setState(({ selectedColumns }) => {
	// 		const nextColumns = [...selectedColumns];
	// 		nextColumns[index] = {
	// 			...nextColumns[index],
	// 			width: size.width,
	// 		};
	// 		return { selectedColumns: nextColumns };
	// 	});
	// };

	showTotal = (total, currentPage) => {
		const { numberOnPage } = this.props || 10;
		return `${currentPage * (numberOnPage || 50) - (numberOnPage || 50) + 1}-${currentPage * (numberOnPage || 50) > total ? total : currentPage * (numberOnPage || 50)} of ${total}`;
	}

	selectColumns = (selected: Array<string>) => {
		const { columns } = this.props;
		this.setState((prevState) => {
			const savedView = prevState.allOptions.filter((el) => selected.includes(el));
			return ({
				selectedOptions: selected,
				selectedColumns: savedView.map((col) => columns.find((column) => column.title === col)),
			});
		});
	}

	setColumnOrder = (dragIndex: number, hoverIndex: number) => {
		const { columns } = this.props;
		function memoSwap(list) {
			const memo = list.splice(dragIndex, 1);
			list.splice(hoverIndex, 0, memo[0]);
			return list;
		}
		this.setState((prevState) => {
			const newOrder = memoSwap(prevState.allOptions);
			const savedView = newOrder.filter((el) => prevState.selectedOptions.includes(el));
			return ({
				allOptions: newOrder,
				selectedColumns: savedView.map((col) => columns.find((column) => column.title === col)),
			});
		});
	}

	hideColumn = (colClicked) => {
		const { columns } = this.props;
		this.setState((prevState) => {
			const newOptions = prevState.selectedOptions.filter((el) => el !== colClicked);
			const savedView = prevState.allOptions.filter((el) => newOptions.includes(el));
			return ({
				selectedOptions: newOptions,
				selectedColumns: savedView.map((col) => columns.find((column) => column.title === col)),
			});
		});
	}

	handleChange = (...args) => {
		const { place } = this.props;
		const sorter = args[2];
		const pageSize = args[0]?.pageSize;
		this.props.setCurrentSorter(`${place}Sorter`, sorter);
		this.props.setCurrentPageSize(`${place}PageSize`, pageSize);
		localStorage.setItem(`${place}PageSize`, pageSize);
	}

	getColumnSearchProps = (dataIndex: string) => ({
		filterDropdown: () => (
			<div style={{ padding: 8 }}>
				<div>
					<CPButton icon="Close" ghost text="hide Column" action={() => this.hideColumn(dataIndex)} />
				</div>
			</div>
		),
		filterIcon: () => (
			<i className="material-icons-outlined filter-icon">arrow_drop_down</i>
		),
	})

	saveView = (visible: boolean) => {
		const { place, applicationId } = this.props;
		const saved = localStorage.getItem(applicationId);
		const oldObj = saved ? JSON.parse(saved) : null;
		const obj = {
			...oldObj,
			[place]: {
				columns: {
					allView: this.state.allOptions,
					savedView: this.state.selectedOptions,
				},
			},
		};
		if (visible) {
			this.setState({
				columnChooser: 'open',
			});
		} else {
			this.setState({
				columnChooser: 'close',
			});
			localStorage.setItem(applicationId, JSON.stringify(obj));
		}
	}

	render() {
		const {
			data,
			total,
			handlePageChange,
			expandRowByClick,
			currentPage,
			onRowAction,
			place,
			rowClassName,
			defaultExpandedRowKeys,
			headerHeight,
			width,
			numberOnPage,
			moveAction,
			loading,
		} = this.props;

		const {
			height, allOptions, selectedOptions, columnChooser, selectedColumns,
		} = this.state;
		const finalColumns = selectedColumns.map((col) => ({
			...col,
			// onHeaderCell: (column) => ({
			// 	width: column.width,
			// 	onResize: this.handleResize(index),
			// }),
			...this.getColumnSearchProps(col?.title),
		}));

		const { moveRow } = this;
		const title = () => (
			<Dropdown
				placement="bottomLeft"
				overlayStyle={{ width: 256 }}
				overlay={(
					<ColumnChooser
						key={columnChooser}
						selectFunction={this.selectColumns}
						setColumnOrder={this.setColumnOrder}
						allOptions={allOptions}
						selectedOptions={selectedOptions}
					/>
				)}
				trigger={['click']}
				onVisibleChange={(visible: boolean) => this.saveView(visible)}
			>
				<div>
					<Icon name="Columns" colorName="ghost" />
				</div>
			</Dropdown>
		);

		return (
			<div
				className="new-table-container"
			>
				<Table
					bordered
					components={moveAction ? this.components : undefined}
					columns={finalColumns}
					onChange={this.handleChange}
					rowKey={(record) => record.id}
					dataSource={data}
					rowClassName={rowClassName}
					loading={loading}
					locale={{ emptyText: 'No Data!' }}
					scroll={{
						y: height - headerHeight - 136,
						x: width,
					}}
					pagination={{
						current: currentPage,
						pageSize: numberOnPage,
						total,
						showSizeChanger: true,
						hideOnSinglePage: false,
						position: 'bottom',
						size: 'small',
						showTotal: () => this.showTotal(total, currentPage),
						onChange: handlePageChange,
						showLessItems: true,
					}}
					title={title}
					expandRowByClick={expandRowByClick}
					expandedRowRender={expandRowByClick
						? (record) => {
							if (place === 'activityLogs') {
								return (
									<div className="json-style">
										<div className="json-container">
											<h5 className="json-title">BEFORE</h5>
											<ReactJson
												src={record.affectedObjectOld}
												theme="shapeshifter:inverted"
												name="affectedObjectOld"
												enableClipboard={false}
												collapseStringsAfterLength={36}
											/>
										</div>
										<div className="json-container">
											<h5 className="json-title">AFTER</h5>
											<ReactJson
												src={record.affectedObjectNew}
												theme="shapeshifter:inverted"
												name="affectedObjectNew"
												enableClipboard={false}
												collapseStringsAfterLength={36}
											/>
										</div>
									</div>
								);
							} if (place === 'providerLogs' || place === 'webhookLogs') {
								return (
									<div className="json-container">
										<ReactJson
											src={record}
											theme="shapeshifter:inverted"
											enableClipboard={false}
											collapseStringsAfterLength={36}
										/>
									</div>
								);
							}
							return null;
						}
						: null}
					defaultExpandedRowKeys={defaultExpandedRowKeys}
					onRow={moveAction
						? (record, index: number) => ({
							index,
							moveRow,
						}) : (record, index: number) => ({
							index,
							onClick: () => onRowAction(record),
						})}
				/>
			</div>
		);
	}
}

CustomTable.defaultProps = {
	handlePageChange: () => {},
	onRowAction: () => { },
	expandRowByClick: false,
	rowClassName: '',
	defaultExpandedRowKeys: [],
	width: 1000,
	moveAction: undefined,
	headerHeight: 208,
	currentPage: 1,
	numberOnPage: 50,
};

const mapDispatchToProps = {
	setCurrentSorter,
	setCurrentPageSize,
};

export default connect(null, mapDispatchToProps)(CustomTable);
