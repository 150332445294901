import React from 'react';
import { InputNumber } from 'antd';
import styles from './assets/numberRangePicker.module.scss';

type InputNumberProps = {
	onChange: () => void,
	placeholder: string,
}

const NumberRangePicker = ({
	onChange,
	placeholder
}: InputNumberProps) => (
	<span className={styles.wrapper}>
		<InputNumber onChange={onChange} className={styles.input} placeholder={placeholder} />
	</span>
);

export default NumberRangePicker;
