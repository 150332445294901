import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import oauthReducer from './OauthReducer';
import usersReducer from './UsersReducer';
import orderReducer from './OrderReducer';
import ledgerReducer from './LedgerReducer';
import applicationReducer from './ApplicationReducer';
import staticReducer from './StaticReducer';
import updateStatusReducer from './UpdateStatusReducer';
import accountsReducer from './AccountsReducer';
import paymentReducer from './PaymentReducer';
import logsReducer from './LogsReducer';
import uiReducer from './UiReducer';
import walletReducer from './WalletReducer';
import symbolReducer from './SymbolReducer';
import instrumentReducer from './InstrumentReducer';
import groupReducer from './GroupReducer';
import feeReducer from './FeeReducer';
import sectionReducer from './SectionReducer';
import exploreGroupReducer from './ExploreGroupReducer';
import webhooksReducer from './WebhooksReducer';
import featureReducer from './FeatureReducer';
import marginReducer from './MarginReducer';
import inventoryWalletReducer from './InventoryWalletReducer';
import providerReducer from './ProviderReducer';
import DividendsReducer from './DividendsReducer';
import statementsReducer from './StatementsReducer';
import eventSubscriptionsReducer from './EventSubscriptionReducer';
import bankReducer from './BankReducer';
import cardsReducer from './CardsReducer';
import kytReducer from './KytReducer';
import stockSplitReducer from './StockSplitReducer';
import apiKeyReducer from './ApiKeysReducer';
import userTagsReducer from './UserTagsReducer';

const appReducer = (history) => combineReducers({
	router: connectRouter(history),
	oauth: oauthReducer,
	users: usersReducer,
	orders: orderReducer,
	ledger: ledgerReducer,
	application: applicationReducer,
	static: staticReducer,
	status: updateStatusReducer,
	account: accountsReducer,
	payment: paymentReducer,
	logs: logsReducer,
	ui: uiReducer,
	wallet: walletReducer,
	symbol: symbolReducer,
	instrument: instrumentReducer,
	group: groupReducer,
	fees: feeReducer,
	section: sectionReducer,
	exploreGroup: exploreGroupReducer,
	webhooks: webhooksReducer,
	feature: featureReducer,
	margin: marginReducer,
	inventoryWallets: inventoryWalletReducer,
	providers: providerReducer,
	dividends: DividendsReducer,
	statements: statementsReducer,
	eventSubscriptions: eventSubscriptionsReducer,
	bank: bankReducer,
	cards: cardsReducer,
	kyt: kytReducer,
	stockSplit: stockSplitReducer,
	apiKey: apiKeyReducer,
	tags: userTagsReducer,
});

const rootReducer = (history) => (state, action) => {
	if (action.type === 'LOGOUT') { state = undefined; }

	return appReducer(history)(state, action);
};

export default rootReducer;
