// @flow

import {
	GET_DIVIDENDS_CALENDARS,
	GET_DIVIDENDS_CALENDAR,
	ADD_DIVIDENDS_CALENDAR,
	UPDATE_DIVIDENDS_CALENDAR,
	DELETE_DIVIDENDS_CALENDAR,
	GET_DIVIDENDS_PAYOUTS,
	EXECUTE_PAYOUT,
	GET_OWNED_INSTRUMENT,
} from '../const/types';

export function getDividendsCalendars(applicationId: string, params: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_DIVIDENDS_CALENDARS,
		payload: {
			request: {
				url: `/applications/${applicationId}/dividends?${params}`,
			},
		},
	});
}

export function getDividendsCalendar(applicationId: string, dividendId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_DIVIDENDS_CALENDAR,
		payload: {
			request: {
				url: `/applications/${applicationId}/dividends/${dividendId}`,
			},
		},
	});
}

export function addDividendCalendar(applicationId: string, data: string) {
	return (dispatch: Function): void => dispatch({
		type: ADD_DIVIDENDS_CALENDAR,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/dividends`,
				data,
			},
		},
	});
}

export function updateDividendCalendar(applicationId: string, dividendId: string, data: string) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_DIVIDENDS_CALENDAR,
		payload: {
			request: {
				method: 'PUT',
				url: `/applications/${applicationId}/dividends/${dividendId}`,
				data,
			},
		},
	});
}

export function deleteDividendCalendar(applicationId: string, dividendId: string) {
	return (dispatch: Function): void => dispatch({
		type: DELETE_DIVIDENDS_CALENDAR,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/dividends/${dividendId}`,
			},
		},
	});
}

export function getDividendsPayouts(applicationId: string, page: number = 1, params: string = '') {
	return (dispatch: Function): void => dispatch({
		type: GET_DIVIDENDS_PAYOUTS,
		payload: {
			request: {
				url: `/applications/${applicationId}/dividend-payments?${params}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function executePayout(applicationId: string, dividendId: string) {
	return (dispatch: Function): void => dispatch({
		type: EXECUTE_PAYOUT,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/dividends/${dividendId}/payout`,
			},
		},
	});
}

export function getOwnedInstrument(applicationId: string, instrumentId: string, date: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_OWNED_INSTRUMENT,
		payload: {
			request: {
				url: `/applications/${applicationId}/instruments/${instrumentId}/owned-on-ex-dividends-date?exDividendDate=${date}`,
			},
		},
	});
}
