// @flow

import {
	APPLICATIONS,
	APPLICATIONS_SETTINGS,
	UPDATE_APPLICATION_SETTINGS,
	APPLICATION,
	UPDATE_APPLICATION,
	GET_APPLICATIONS_COUNTRIES,
	ADD_APPLICATIONS_COUNTRIES,
	DELETE_APPLICATIONS_COUNTRIES,
} from '../const/types';

export function getApplications(customerId: string) {
	return (dispatch: Function): void => dispatch({
		type: APPLICATIONS,
		payload: {
			request: {
				url: `/customers/${customerId}/applications`,
			},
		},
	});
}

export function getApplication(customerId: string, applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: APPLICATION,
		payload: {
			request: {
				url: `/customers/${customerId}/applications/${applicationId}`,
			},
		},
	});
}

export function updateApplication(customerId: string, applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_APPLICATION,
		payload: {
			request: {
				method: 'PUT',
				url: `/customers/${customerId}/applications/${applicationId}`,
				data,
			},
		},
	});
}

// Interest settings

export function getApplicationSettings(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: APPLICATIONS_SETTINGS,
		payload: {
			request: {
				url: `/applications/${applicationId}/settings/margin-interest`,
			},
		},
	});
}

export function updateApplicationSettings(
	applicationId: string,
	data: string,
) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_APPLICATION_SETTINGS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/settings/margin-interest`,
				data,
			},
		},
	});
}

// Application Countries

export function getApplicationCountries(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_APPLICATIONS_COUNTRIES,
		payload: {
			request: {
				url: `/applications/${applicationId}/countries`,
			},
		},
	});
}
export function addApplicationCountries(applicationId: string, data: Array<string>) {
	return (dispatch: Function): void => dispatch({
		type: ADD_APPLICATIONS_COUNTRIES,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/countries`,
				data,
			},
		},
	});
}
export function deleteApplicationCountries(applicationId: string, data: Array<string>) {
	return (dispatch: Function): void => dispatch({
		type: DELETE_APPLICATIONS_COUNTRIES,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/countries`,
				data,
			},
		},
	});
}
