// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
// import styles
import styles from './assets/breadcrumb.module.scss';

type Props = {
	location: {
		pathname: string,
		search: string,
	},
	applicationId: string,
}

const AppBreadcrumb = (props: Props) => {
	const { location, applicationId } = props;
	const pathSnippets = location.pathname.split('/').filter((i) => i).slice(2);
	const extraBreadcrumbItems = pathSnippets.map((_, index) => {
		const part = pathSnippets.slice(0, index + 1).join('/');
		const name = pathSnippets[index];
		if (part.endsWith('user_wallets')
			|| part.endsWith('user_payouts')
			|| part.endsWith('user_cards')
			|| part.endsWith('group')
			|| part.endsWith('section')
			|| part.endsWith('user_fees')
			|| part.endsWith('products')
			|| part.endsWith('programs')
			|| part.endsWith('update_product')) {
			return '';
		}
		return (
			<Breadcrumb.Item key={part}>
				<Link to={`/application/${applicationId}/${part}${location.search || ''}`}>{name}</Link>
			</Breadcrumb.Item>
		);
	});
	const breadcrumbItems = [
		<Breadcrumb.Item key="home">
			<Link to="/" replace>home</Link>
		</Breadcrumb.Item>,
	].concat(extraBreadcrumbItems);

	return <Breadcrumb className={styles.breadcrumb}>{breadcrumbItems}</Breadcrumb>;
};

export default AppBreadcrumb;
