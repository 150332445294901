// @flow

import { ACTIVITY_LOG, PROVIDER_LOGS } from '../const/types';

export function getActivityLog(appId: string, limit: number, page: number = 1, param: string) {
	return (dispatch: Function): void => dispatch({
		type: ACTIVITY_LOG,
		payload: {
			request: {
				url: `/applications/${appId}/activity-log?${param}`,
				headers: {
					'pagination-limit': limit,
					'pagination-offset': (page - 1) * limit,
				},
			},
		},
	});
}

export function getProviderLogs(appId: string, limit: number, page: number, parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: PROVIDER_LOGS,
		payload: {
			request: {
				url: `/applications/${appId}/provider-logs?${parametar}`,
				headers: {
					'pagination-limit': limit,
					'pagination-offset': (page - 1) * limit,
				},
			},
		},
	});
}
