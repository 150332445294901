// @flow

import React from 'react';
import Spin from '../Spin';

type Props = {
	value: string | number,
	additionalValue?: string | number,
	title: string,
	styles?: Object,
	loading?: boolean,
}
const ValueCard = (props: Props) => {
	const {
		styles, value, title, additionalValue, loading,
	} = props;
	return (
		<div style={styles} className="value-container">
			<div className="value-card">
				{value === '-' || additionalValue === '-' || loading
					? (
						<Spin position="spin-relative" />
					)
					: (
						<>
							<h1>{value}</h1>
							<p>{additionalValue}</p>
						</>
					)}
				<h6>{title}</h6>
			</div>
		</div>
	);
};

ValueCard.defaultProps = {
	styles: {},
	additionalValue: '',
	loading: false,
};

export default ValueCard;
