// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Spin from '../../components/UiElements/Spin';
import Title from '../../components/UiElements/Title';
import ApplicationOrdersTable from './components/OrdersTable';
//= import methods
import { setCurrentPage, setCurrentPageSize } from '../../modules/actions/UiActions';
import { getAppOrders } from '../../modules/actions/OrderActions';
import { getProviders } from '../../modules/actions/ProviderActions';
//= import selectors
import { ordersFormattedSelector } from '../../modules/selectors/OrdersSelector';
//= import helpers
import StatusHelpers from '../../lib/helpers/statusHelpers';
//= import types
import type { FormattedOrders } from '../../modules/selectors/OrdersSelector';
import type { Provider } from '../../modules/reducers/ProviderReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	appOrders: Array<FormattedOrders>,
	appOrdersTotal: number,
	isFetchingAppOrders: boolean,
	currentPage: number,
	applicationId: string,
	pageSize: number,
	ordersFilters: { [string]: Array<string> },
	getAppOrders: (string, number, string) => void,
	setCurrentPage: (string, number) => void,
	setCurrentPageSize: (string, number) => void,
	getProviders: () => Promise<Object>,
	providers: Array<Provider>,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	history: {
		push: (string) => void,
	},
}

function ApplicationOrders(props: Props) {
	const {
		appOrders,
		appOrdersTotal,
		isFetchingAppOrders,
		currentPage,
		ordersFilters,
		providers,
		setCurrentPageSize: setCurrentPageSizeAction,
		pageSize,
		getAppOrders: getAppOrdersAction,
		setCurrentPage: setCurrentPageAction,
		getProviders: getProvidersAction,
		match: { params: { applicationId, userId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		getProvidersAction();
	}, [getProvidersAction, applicationId]);

	useEffect(() => {
		if (userId) {
			const params = { userId };
			getAppOrdersAction(applicationId, currentPage, pageSize, queryString.stringify(params, { arrayFormat: 'comma' }));
		} else {
			const symbolFilter: Array<string> = ordersFilters.symbolId;
			const fixedSymbols: Array<string> = symbolFilter?.map((el) => el.toUpperCase());
			const filters = { ...ordersFilters, symbolId: fixedSymbols };
			getAppOrdersAction(applicationId, currentPage, pageSize, queryString.stringify(filters, { arrayFormat: 'comma' }));
		}
	}, [ordersFilters, currentPage, getAppOrdersAction, applicationId, userId, pageSize]);

	const handleOrdersPageChange = (page: number, pageSize: number) => {
		setCurrentPageAction('appOrdersPage', page);
		setCurrentPageSizeAction('appOrdersPageSize', pageSize);

	};

	const filterRef = useRef(null);
	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [ordersFilters]);

	return (
		<>
			{userId
				? (
					<Title title="user orders" />
				)
				: (
					<div ref={filterRef}>
						<Title
							title="orders"
							buttons={[
								{
									action: () => setFilterVisible(true),
									text: 'filter',
									icon: 'Funnel',
								},
							]}
							applicationId={applicationId}
							place="appOrders"
							fields={{
								type: StatusHelpers.orderTypes,
								status: StatusHelpers.orderStatus,
								side: StatusHelpers.orderSide,
								symbolId: 'input',
								dateRange: 'time',
							}}
							visible={filterVisible}
							date
							closeFunction={() => setFilterVisible(false)}
							filter
						/>
					</div>
				)}
			<div className="container">
				{!isFetchingAppOrders
					? (
						<ApplicationOrdersTable
							orders={appOrders}
							ordersTotal={appOrdersTotal}
							currentPage={currentPage}
							handleOrdersPageChange={handleOrdersPageChange}
							headerHeight={filterHeight}
							pageSize={pageSize}
							applicationId={applicationId}
							place={userId ? '' : 'appOrders'}
							providers={providers}
						/>
					) : <Spin spinning={isFetchingAppOrders} />}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	isFetchingAppOrders: state.orders.isFetchingAppOrders,
	appOrders: ordersFormattedSelector(state, 'appOrders'),
	appOrdersTotal: state.orders.appOrdersTotal,
	currentPage: state.ui.appOrdersPage,
	ordersFilters: state.ui.appOrdersFilters,
	providers: state.providers.providers,
	pageSize: state.ui.appOrdersPageSize,
});

const mapDispatchToProps = {
	getAppOrders,
	setCurrentPage,
	getProviders,
	setCurrentPageSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationOrders);
