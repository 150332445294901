// @flow

import {
	GET_APP_FEATURES,
	UPDATE_APP_FEATURES,
} from '../const/types';

export function getAppFeatures(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_APP_FEATURES,
		payload: {
			request: {
				url: `/applications/${applicationId}/features`,
			},
		},
	});
}

export function updateAppFeatures(applicationId: string, featureId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_APP_FEATURES,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/features/${featureId}`,
				data,
			},
		},
	});
}
