// @flow

import React from 'react';
import DonutChart from '../../../components/Chart/DonutChart';
import Spin from '../../../components/UiElements/Spin';

type Props = {
	value1: {[string]: string},
	value2: {[string]: string},
	marginUtilization: string,
	styles: {[string]: string},
	loading?: boolean,
}
const AccountMarginCard = (props: Props) => {
	const {
		value1, value2, marginUtilization, styles, loading,
	} = props;

	return (
		<div className="pie-container" style={styles}>
			<div className="pie-card">
				{marginUtilization && (
					<div className="donut">
						<DonutChart height={80} marginUtilization={marginUtilization} />
					</div>
				)}
				{loading
					? (
						<Spin position="spin-relative" />
					)
					: (
						<div className="legend">
							<div>
								<div>
									<span className={`${value1.type} square`} />
									<h6>{value1.name}</h6>
								</div>
								<span className="bold">{value1.value}</span>
							</div>
							<div>
								<div>
									<span className={`${value2.type} square`} />
									<h6>{value2.name}</h6>
								</div>
								<span className="bold">{value2.value}</span>
							</div>

						</div>
					)}
			</div>
		</div>
	);
};

AccountMarginCard.defaultProps = {
	loading: false,
};

export default AccountMarginCard;
