// @flow
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Table from '../../../components/UiElements/Table';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import {
	getAppSymbols, getGroupSymbols,
} from '../../../modules/actions/SymbolActions';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
//= import styles

type SymbolsResponse = {
	payload: {
		data: Array<Symbol>,
	}
}
type Props = {
	form: *,
	getAppSymbols: (string, string, string, string) => Promise<SymbolsResponse>,
	getGroupSymbols: (string, string, string, string) => Promise<SymbolsResponse>,
	submitChanges: Function,
	handleCancelClick: () => void,
	edit: boolean,
	symbolToEdit: Symbol,
	applicationId: string,
	groupId: string,
}

type LocalState = {
	selectedKey: Array<string>,
	symbols: Array<Object>,
	selectedSymbol: Symbol | Object,
	precision: number,
	errorMessage: string,
	loading: boolean,
	search: string,
	infiniteScrollLoading: boolean,
}

class EnableFractionalTrading extends React.Component<Props, LocalState> {
	state = {
		selectedKey: [],
		symbols: [],
		selectedSymbol: {},
		precision: 0,
		errorMessage: '',
		loading: false,
		search: '',
		infiniteScrollLoading: false,
	}

	loadingRef = React.createRef();

	componentDidMount() {
		const {
			applicationId,
			symbolToEdit,
			groupId,
			getAppSymbols: getAppSymbolsAction,
			getGroupSymbols: getGroupSymbolsAction,
		} = this.props;
		const { search } = this.state;

		if (Object.keys(symbolToEdit).length) {
			this.setState({
				selectedSymbol: symbolToEdit,
				precision: symbolToEdit ? symbolToEdit.basePrecision : 0,
			});
		} else {
			this.setState({
				loading: true,
			});
			const date = new Date();
			const param = queryString.stringify({ search });
			if (groupId) {
				if (groupId === 'applicationSymbols') {
					getAppSymbolsAction(applicationId, 'infinite-scroll', date.toISOString(), param)
						.then((appSymbolsRes: SymbolsResponse) => {
							const appSymbols = appSymbolsRes.payload.data;
							this.setState({
								symbols: appSymbols,
								loading: false,
							});
						});
				} else {
					getGroupSymbolsAction(groupId, 'infinite-scroll', date.toISOString(), param)
						.then((groupSymbolsRes) => {
							const groupSymbols = groupSymbolsRes.payload.data;
							this.setState({
								symbols: groupSymbols,
								loading: false,
							});
						});
				}
			} else {
				this.setState({
					symbols: [],
					loading: false,
				});
			}
			const tableContent = document.querySelector('.ant-table-tbody');
			tableContent.addEventListener('scroll', this.handleEvent);
		}
	}

	handleEvent = (e) => {
		const { applicationId, groupId } = this.props;
		const { symbols, search } = this.state;
		const maxScroll = e.target.scrollHeight - e.target.clientHeight;
		const currentScroll = e.target.scrollTop;
		if (currentScroll === maxScroll) {
			this.setState({
				infiniteScrollLoading: true,
			});
			const paginationOffset = symbols[symbols.length - 1].createdAt;
			const param = queryString.stringify({ search });
			if (groupId === 'applicationSymbols') {
				this.props.getAppSymbols(applicationId, 'infinite-scroll', paginationOffset, param)
					.then((res) => this.setState((prevState) => ({
						symbols: [...prevState.symbols, ...res.payload.data],
						infiniteScrollLoading: false,
					})));
			} else {
				this.props.getGroupSymbols(groupId, 'infinite-scroll', paginationOffset, param)
					.then((res) => this.setState((prevState) => ({
						symbols: [...prevState.symbols, ...res.payload.data],
						infiniteScrollLoading: false,
					})));
			}
		}
	}

	onSymbolSelect = (key: Array<string>) => {
		const selectedSymbol = this.state.symbols.find((symbol: Symbol) => symbol.id === key[0]);
		this.setState(() => ({
			selectedKey: key,
			selectedSymbol,
			precision: selectedSymbol ? selectedSymbol.basePrecision : 2,
		}));
		this.props.form.setFieldsValue({
			basePrecision: selectedSymbol ? selectedSymbol.basePrecision : 2,
			minBaseQuantity: selectedSymbol ? selectedSymbol.orderSize.minQuantity.baseInstrument : '',
		});
	}

	setMinQuantity = (value) => {
		this.setState({
			precision: value,
		});
	}

	validateQuantity = (rule, value, callback) => {
		const { precision } = this.state;
		if (precision > 18) {
			callback('Minimal Base Quantity must have less or equal decimal places then Precision.');
		}
		if ((value < 1 / (10 ** precision)) || (value === 0)) {
			callback(`Minimal Base Quantity must have less or equal decimal places then ${precision}.`);
		}
		callback();
	}

	validatePrecision = (rule, value, callback) => {
		if (value < 0 || value > 18) {
			callback('Precision must be greater than 0 and smaller than 18');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		const { selectedSymbol } = this.state;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const precisionData = { basePrecision: values.basePrecision };
				const minBaseQuantityData = { minBaseQuantity: values.minBaseQuantity.toString() };
				submitChanges(selectedSymbol, precisionData, minBaseQuantityData);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			selectedKey: [],
			symbols: [],
			selectedSymbol: {},
			precision: 0,
			errorMessage: '',
		});
	}

	handleSearch = (value: string) => {
		const {
			applicationId,
			groupId,
			getAppSymbols: getAppSymbolsAction,
			getGroupSymbols: getGroupSymbolsAction,
		} = this.props;
		this.setState({
			search: value.toLowerCase(),
			loading: true,
		});
		const date = new Date();
		const param = queryString.stringify({ search: value });
		if (groupId) {
			if (groupId === 'applicationSymbols') {
				getAppSymbolsAction(applicationId, 'infinite-scroll', date.toISOString(), param)
					.then((res) => this.setState({
						symbols: res.payload.data,
						loading: false,
					}));
			} else {
				getGroupSymbolsAction(groupId, 'infinite-scroll', date.toISOString(), param)
					.then((res) => this.setState({
						symbols: res.payload.data,
						loading: false,
					}));
			}
		} else {
			this.setState({
				symbols: [],
				loading: false,
			});
		}
	}

	render() {
		const {
			handleCancelClick,
			groupId,
			edit,
			form: {
				getFieldDecorator, isFieldsTouched,
			},
		} = this.props;
		const {
			selectedKey,
			selectedSymbol,
			precision,
			errorMessage,
			loading,
			symbols,
			search,
			infiniteScrollLoading,
		} = this.state;
		const columns = [
			{
				title: 'id',
				dataIndex: 'id',
				key: 'id',
				width: 112,
			},
			{
				title: 'name',
				dataIndex: 'displayName',
				key: 'name',
				width: 192,
				render: (text: string) => (
					<span className="wrap" style={{ maxWidth: 176 }}>
						{text}
					</span>
				),
			},
			{
				title: 'symbol type',
				dataIndex: 'group',
				key: 'type',
				width: 160,
				render: () => (
					<span className="wrap" style={{ maxWidth: 144 }}>
						{groupId === 'applicationSymbols' ? 'Application Symbol' : 'Group Symbol'}
					</span>
				),
			},
		];
		const rowSelection = {
			onChange: this.onSymbolSelect,
			type: 'radio',
			selectedRowKeys: selectedKey,
		};
		const FormItem = Form.Item;
		const fixedLayout = {
			labelCol: {
				xs: { span: 15 },
			},
			wrapperCol: {
				xs: { span: 9 },
			},
		};
		const changableLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{!edit
					&& (
						<div>
							<Row gutter={24}>
								<Col span={14}>
									<Input.Search
										key="search"
										className="search"
										placeholder="Search For Symbols..."
										onSearch={this.handleSearch}
										defaultValue={search}
										onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
									/>
								</Col>
							</Row>
							<div ref={this.loadingRef} onScroll={this.handleEvent} className="scroll-div">
								{!loading
									? (
										<Table
											loading={infiniteScrollLoading}
											rowSelection={rowSelection}
											rowKey={(record) => record.id}
											columns={columns}
											dataSource={symbols}
											pagination={false}
											scroll={{ y: (window.innerHeight - 624) }}
											rowClassName="editable-row"
										/>
									)
									: <Spin spinning={loading} />}
							</div>
						</div>
					)}
				<p>
					{`Define new Base Precision of Symbol and Minimum Base Quantity your clients can trade.
                    Base Precision must be equal or greater then Exchange Precision,
					and Minimal Base Quantity must have less or equal decimal places then ${precision}.`}
				</p>
				<Row>
					<Col span={12} className="field-left">
						<FormItem label="EXCHANGE PRECISION" {...fixedLayout} className="form-item-small">
							<div className="right-align-div">{selectedSymbol.orderSize ? selectedSymbol.exchangePrecision : '-'}</div>
						</FormItem>
					</Col>
					<Col span={12} className="field-left">
						<FormItem label="INCREMENT" {...changableLayout} className="form-item-small">
							<div className="right-align-div">{selectedSymbol.orderSize ? selectedSymbol.orderSize.increment : '-'}</div>
						</FormItem>
					</Col>
				</Row>
				<Row>
					<FormItem label="BASE PRECISION" {...changableLayout} className="form-item-small" help="">
						{getFieldDecorator(
							'basePrecision', {
								initialValue: selectedSymbol.orderSize ? selectedSymbol.basePrecision : 0,
								rules: [
									{
										required: true,
										message: 'Please input base precision!',
									},
									{
										validator: this.validatePrecision,
									},
								],
							},
						)(
							<InputNumber
								min={!edit ? selectedSymbol.exchangePrecision : 0}
								onChange={this.setMinQuantity}
								disabled={edit ? false : !selectedKey.length}
								placeholder="Set Precision"
								type="number"
							/>,
						)}
					</FormItem>
					<FormItem label="MIN QUANTITY" {...changableLayout} help="">
						{getFieldDecorator(
							'minBaseQuantity', {
								initialValue: selectedSymbol.orderSize ? selectedSymbol.orderSize.minQuantity.baseInstrument : '1',
								rules: [
									{
										required: true,
										message: 'Please input min quantity!',
									},
									{
										validator: this.validateQuantity,
									},
								],
							},
						)(
							<InputNumber
								disabled={edit ? false : !selectedKey.length}
								step={1 / (10 ** precision)}
								placeholder="Set Quantity"
								symbol={selectedSymbol?.baseInstrumentId}
							/>,
						)}
					</FormItem>
				</Row>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text={edit ? 'edit fractional trading' : 'add fractional trading'} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EnableFractionalTradingForm = Form.create()(EnableFractionalTrading);

const mapDispatchToProps = {
	getGroupSymbols,
	getAppSymbols,
};

export default connect(null, mapDispatchToProps)(EnableFractionalTradingForm);
