// @flow

import React, {
	useEffect, useState, useRef, useCallback,
} from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../components/UiElements/Title';
import Switch from '../../components/UiElements/Switch';
import FormModal from '../../components/UiElements/Modal/FormModal';
import DeleteModal from '../../components/UiElements/Modal/DeleteModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
import Programs from './containers/Programs';
import Products from './containers/Products';
import ProductForm from './components/ProductForm';
//= import actions
import { getPrograms, getProducts, createProduct } from '../../modules/actions/CardAction';
import { getAppFeatures, updateAppFeatures } from '../../modules/actions/FeaturesAction';
//= import types
import type { Program } from '../../modules/reducers/CardsReducer';
import type { Feature } from '../../modules/reducers/FeatureReducer';
import type { State } from '../../modules/types/FlowTypes';
//= import styles
import styles from './assets/cards.module.scss';

type Props = {
	getProducts: (string) => void,
	getPrograms: (string) => void,
	programs: Array<Program>,
	createProduct: (string, Object) => Promise<Object>,
	getAppFeatures: (string) => Promise<Object>,
	updateAppFeatures: (string, string, Object) => Promise<Object>,
	activeTab: string,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}
type FeaturesResponse = {
	payload: {
		data: Array<Feature>,
	},
}

function CardsPage(props: Props) {
	const {
		getPrograms: getProgramsAction,
		getProducts: getProductsAction,
		createProduct: createProductAction,
		getAppFeatures: getAppFeaturesAction,
		updateAppFeatures: updateAppFeaturesAction,
		programs,
		activeTab,
		match: { params: { applicationId } },
		history,
	} = props;

	const [edit, setEdit] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [cardFeature, setCardFeature] = useState({});
	const [switchKey, setSwitchKey] = useState('first');
	const [data, setData] = useState({});
	const [toggle, setToggle] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);
	const filterRef = useRef(null);

	useEffect(() => {
		getAppFeaturesAction(applicationId)
			.then((res: FeaturesResponse) => {
				const featureData: ?Feature = res.payload.data.find(
					(feature: Feature) => feature.featureId === 'card_module',
				);
				if (featureData) {
					setCardFeature(featureData);
				}
			});
	}, [applicationId, getAppFeaturesAction, data]);

	useEffect(() => {
		getProgramsAction(applicationId);
		getProductsAction(applicationId);
	}, [applicationId, getProductsAction, getProgramsAction]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleToggle = (value: boolean): void => {
		const updateData = { enabled: value };
		setData(updateData);
	};

	const handleToggleCancel = () => {
		setToggle(false);
		setSwitchKey('changed');
		setData({});
	};

	const submitUpdate = useCallback(() => {
		setButtonLoading(true);
		updateAppFeaturesAction(applicationId, cardFeature.featureId, data)
			.then(() => {
				setButtonLoading(false);
				setData({});
				setToggle(false);
			})
			.catch(() => setSwitchKey('changed'));
	}, [applicationId, data, cardFeature.featureId, updateAppFeaturesAction]);

	useEffect(() => {
		if (data.enabled) {
			submitUpdate();
		} else if (data.enabled === false) {
			setToggle(true);
		}
	}, [data, submitUpdate]);

	const viewProgram = (id) => {
		history.push(`/application/${applicationId}/system/card/programs/${id}`);
	};

	const handleCreateClick = () => {
		props.history.push(`/application/${applicationId}/system/card/create_product`);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitCreateProduct = (formData) => {
		setButtonLoading(true);
		createProductAction(applicationId, formData)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueEditing = (formData) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect all Fee Rules below.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitCreateProduct(formData), text: 'edit fee' },
		]);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="Cards"
					place={activeTab}
					tabs={[
						{
							key: 'programs',
							title: 'PROGRAMS',
						},
						{
							key: 'products',
							title: 'PRODUCTS',
						},
					]}
					tab="card"
					buttons={activeTab === 'products'
						? [{
							text: 'create card product',
							icon: 'Plus',
							action: handleCreateClick,
							disabled: !programs.length,
						}]
						: []}
				>
					<div className={styles.feature}>
						<Switch
							key={switchKey}
							checked={cardFeature.enabled}
							onChange={(value: boolean) => handleToggle(value)}
						/>
					</div>
				</Title>
			</div>
			<div className="container">
				{activeTab === 'programs' && (
					<Programs
						applicationId={applicationId}
						viewProgram={viewProgram}
					/>
				)}
				{activeTab === 'products' && (
					<Products
						applicationId={applicationId}
					/>
				)}
			</div>
			<FormModal
				title="Create new Product"
				visible={edit}
				cancelFunction={handleEditCancel}
				form={(
					<ProductForm
						submitChanges={continueEditing}
						handleCancelClick={handleEditCancel}
						wrappedComponentRef={formRef}
						applicationId={applicationId}
					/>
				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
			<DeleteModal
				okFunction={submitUpdate}
				cancelFunction={handleToggleCancel}
				visible={toggle}
				btnText="DISABLE"
				message={'You\'re about to disable card feature for all your clients across entire Application.'}
			/>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	activeTab: state.ui.cardTab,
	programs: state.cards.programs,
});
const mapDispatchToProps = {
	getProducts,
	getPrograms,
	createProduct,
	getAppFeatures,
	updateAppFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsPage);
