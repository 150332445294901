// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import Icon from '../../../components/UiElements/Icon';
import GroupForm from '../components/GroupForm';
//= import methods
import {
	getGroups, addGroup, getGroup, updateGroup,
} from '../../../modules/actions/GroupAction';
//= import types
import type { Group } from '../../../modules/reducers/GroupReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	groups: Array<Group>,
	isFetchingGroups: boolean,
	getGroups: (string) => Promise<Object>,
	getGroup: (string, string) => Promise<Object>,
	addGroup: (string, Object) => Promise<Object>,
	updateGroup: (string, string, Object) => Promise<Object>,
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		goBack: () => void,
	},
}
type LocalState = {
	edit: boolean,
	warningModal: boolean,
	warningTitle: string,
	title: string,
	message: Object,
	footer: Array<Object>,
	type: string,
	defaultGroup: string,
	group: Group,
	buttonLoading: boolean,
}
class Groups extends React.Component<Props, LocalState> {
	state = {
		edit: false,
		warningModal: false,
		warningTitle: 'Warning!',
		title: '',
		message: {},
		footer: [],
		type: '',
		defaultGroup: '',
		group: {},
		buttonLoading: false,
	}

	formRef: ?GroupForm;

	componentDidMount() {
		const { match: { params: { applicationId } } } = this.props;
		this.props.getGroups(applicationId)
			.then((res) => {
				const groupName = res.payload.data.filter((el) => el.default)[0].name;
				this.setState({
					defaultGroup: groupName,
				});
			});
	}

	handleCreateClick = () => {
		this.setState({
			edit: true,
			title: 'Create New Group',
			type: 'create',
		});
	};

	handleErrorCancel = () => {
		if (this.formRef) {
			this.formRef.handleReset();
		}
		this.setState({
			warningModal: false,
		});
	}

	handleWarningCancel = () => {
		this.setState({
			warningModal: false,
			edit: true,
		});
	}

	handleOnError = (error) => {
		this.setState({
			buttonLoading: false,
			warningModal: true,
			warningTitle: 'Error',
			message: {
				firstPart: error.error.response.data.message,
			},
			footer: [
				{ type: 'cancel', action: this.handleErrorCancel, text: 'close' },
			],
		});
	}

	handleEditCancel = () => {
		if ((this.formRef && this.formRef.props.form.isFieldsTouched())
			|| (this.formRef && this.formRef.state.isTouched)) {
			this.setState({
				edit: false,
				warningModal: true,
				warningTitle: 'Warning!',
				message: {
					firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
				},
				footer: [
					{ type: 'cancel', action: this.handleErrorCancel, text: 'cancel' },
					{ type: 'continue', action: this.handleWarningCancel, text: 'continue' },
				],
			});
		} else {
			if (this.formRef) {
				this.formRef.handleReset();
			}
			this.setState({
				edit: false,
				warningModal: false,
			});
		}
	}

	submitAddGroup = (data) => {
		const { match: { params: { applicationId } } } = this.props;
		this.setState({
			buttonLoading: true,
		});
		this.props.addGroup(applicationId, data)
			.then(() => this.setState({
				buttonLoading: false,
				edit: false,
			}))
			.catch((error) => this.handleOnError(error));
	};

	handleEditClick = (group: Group) => {
		const { match: { params: { applicationId } } } = this.props;
		this.props.getGroup(applicationId, group.id)
			.then((res) => {
				this.setState({
					group: res.payload.data,
					title: `Edit Group ${res.payload.data.name}`,
				});
			})
			.then(() => this.setState({
				edit: true,
				type: 'edit',
			}));
	}

	continueEditing = (value) => {
		this.setState((prevState) => ({
			warningModal: true,
			edit: false,
			warningTitle: 'Are you sure?',
			message: prevState.type === 'create'
				? { firstPart: 'Add Group.' }
				: {
					firstPart: `Once you confirm these changes,
						they will affect ${value.name} Group`,
					secondPart: 'It is advised to proceed editing with caution.',
				},
			footer: [
				{
					type: 'cancel',
					action: this.handleWarningCancel,
					text: 'go back'
					,
				},
				{
					type: 'continue',
					action: () => this.submitEditGroup(value),
					text: 'edit group',
				},
			],
		}));
	}

	submitEditGroup = (data) => {
		const { match: { params: { applicationId } } } = this.props;
		this.setState({
			buttonLoading: true,
		});
		this.props.updateGroup(applicationId, this.state.group.id, data)
			.then(() => this.setState({
				buttonLoading: false,
				warningModal: false,
			}))
			.catch((error) => this.handleOnError(error));
		if (this.formRef) {
			this.formRef.handleReset();
		}
	}

	handleBackClick = () => {
		this.props.history.goBack();
	};

	render() {
		const {
			groups,
			isFetchingGroups,
			match: { params: { applicationId } },
		} = this.props;
		const {
			message,
			footer,
			warningModal,
			warningTitle,
			edit,
			type,
			title,
			group,
			defaultGroup,
			buttonLoading,
		} = this.state;
		const columns = [
			{
				title: 'name',
				dataIndex: 'name',
				key: 'name',
				width: 160,
				render: (text: string, record: Group) => (
					<Link
						to={`/application/${applicationId}/system/groups/${record.id}`}
					>
						{text}
					</Link>
				),
			},
			{
				title: 'description',
				dataIndex: 'description',
				key: 'description',
				width: 320,
				render: (text: string) => (
					<div>
						{text || '-'}
					</div>
				),
			},
			{
				title: 'clients',
				dataIndex: 'users',
				key: 'users',
				width: 144,
				render: (text: string) => (
					<div>
						{text || '-'}
					</div>
				),
			},
			{
				title: 'edit',
				dataIndex: '',
				key: 'action',
				width: 80,
				render: () => (
					<Icon name="Edit" />
				),
				onCell: (record: Group) => ({
					onClick: (e) => {
						e.stopPropagation();
						this.handleEditClick(record);
					},
				}),
			},
		];

		return (
			<>
				<Title
					backAction={this.handleBackClick}
					title="Groups List"
					buttons={[{
						action: this.handleCreateClick,
						icon: 'Plus',
						text: 'create new group',
					}]}
				/>
				<div className="container">
					{!isFetchingGroups
						? (
							<CustomTable
								columns={columns}
								data={groups}
								total={groups.length}
								headerHeight={16}
								place="groups"
								applicationId={applicationId}
							/>
						)
						: <Spin spinning={isFetchingGroups} />}
					<FormModal
						title={title}
						visible={edit}
						cancelFunction={this.handleEditCancel}
						form={(
							<GroupForm
								submitChanges={type === 'create' ? this.submitAddGroup : this.continueEditing}
								handleCancelClick={this.handleEditCancel}
								applicationId={applicationId}
								wrappedComponentRef={(element) => { this.formRef = element; }}
								type={type}
								group={type === 'create' ? false : group}
								defaultGroup={defaultGroup}
							/>
						)}
					/>
					<WarningModal
						title={warningTitle}
						visible={warningModal}
						cancelFunction={this.handleErrorCancel}
						footer={footer}
						message={message}
						loading={buttonLoading}
					/>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state: State) => ({
	groups: state.group.groups,
	isFetchingGroups: state.group.isFetchingGroups,
});

const mapDispatchToProps = {
	getGroups,
	addGroup,
	getGroup,
	updateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
