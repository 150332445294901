// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import BankPayments from '../components/BankPayments';
//= import actions
import {
	getApplicationBankPayments,
} from '../../../modules/actions/BankActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { PaymentType } from '../../../modules/reducers/BankReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Props = {
	getApplicationBankPayments: (string, number, string) => void,
	payments: Array<PaymentType>,
	customerApplicationPaymentsTotal: number,
	isFetchingCustomerApplicationPayments: boolean,
	setCurrentPage: (string, number) => void,
	currentPage: number,
	customerApplicationPaymentsFilters: {[string]: string},
	getProvidersInstances: (string) => void,
	providers: Array<ProviderInstance>,
	search: string,
	pageSize: number,
	match: {
		params: {
			applicationId: string,
		}
	}
}

function ApplicationBankPaymentsPage(props: Props) {
	const {
		getApplicationBankPayments: getApplicationBankPaymentsAction,
		payments,
		customerApplicationPaymentsTotal,
		isFetchingCustomerApplicationPayments,
		currentPage,
		setCurrentPage: setCurrentPageAction,
		customerApplicationPaymentsFilters,
		getProvidersInstances: getProvidersInstancesAction,
		providers,
		search,
		pageSize,
		match: { params: { applicationId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');

	const filterRef = useRef(null);

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		async function getData() {
			try {
				if (search) {
					const query = `id=${search}`;
					await getApplicationBankPaymentsAction(applicationId, currentPage, query, pageSize);
					setErrorMessage('');
				} else {
					await getApplicationBankPaymentsAction(applicationId, currentPage, queryString.stringify(customerApplicationPaymentsFilters, { arrayFormat: 'comma' }), pageSize);
					setErrorMessage('');
				}
			} catch (err) {
				const errMessage: string = err.error?.response?.data?.message;
				if (errMessage && errMessage.startsWith('Application not found')) {
					setErrorMessage("This feature is not available for your application. If you're interested in it, please contact your TradeCore representative.");
				}
			}
		}
		getData();
	}, [
		getApplicationBankPaymentsAction,
		applicationId,
		currentPage,
		customerApplicationPaymentsFilters,
		search,
		pageSize,
	]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [customerApplicationPaymentsFilters]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('bankApplicationPaymentsPage', page);
	};

	const availableProviders = providers.filter((el) => el.provider.name === 'modulr').map((el) => ({
		id: el.provider.name,
		name: el.provider.name,
		displayName: el.provider.displayName,
	}));

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="Bank Payments"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					searchComponent={{
						placeholder: 'Search Payment IDs',
						disabled: false,
					}}
					place="bankApplicationPayments"
					fields={{
						instrumentId: StatusHelpers.bankInstruments,
						provider: availableProviders,
						dateRange: 'time',
						status: StatusHelpers.paymentStatus,
						paymentMethodCategory: StatusHelpers.paymentScheme,
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
					filterMode="single"
				/>
			</div>
			<div className="page-container">
				{errorMessage
					? (
						<div className="empty-state">
							<UserNoChecks />
							<p>{errorMessage}</p>
						</div>
					)
					: (
						<BankPayments
							payments={payments}
							applicationId={applicationId}
							paymentsTotal={customerApplicationPaymentsTotal}
							place="customerApplicationPayments"
							headerHeight={filterHeight}
							currentPage={currentPage}
							pageSize={pageSize}
							isFetchingPayments={isFetchingCustomerApplicationPayments}
							handlePageChange={handlePageChange}
						/>
					)}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	payments: state.bank.bankApplicationPayments,
	customerApplicationPaymentsTotal: state.bank.bankApplicationPaymentsTotal,
	isFetchingCustomerApplicationPayments: state.bank.isFetchingBankApplicationPayments,
	currentPage: state.ui.bankApplicationPaymentsPage,
	search: state.ui.bankApplicationPaymentsSearch,
	customerApplicationPaymentsFilters: state.ui.bankApplicationPaymentsFilters,
	providers: state.providers.providersInstances,
	pageSize: state.ui.customerApplicationPaymentsPageSize
});

const mapDispatchToProps = {
	getApplicationBankPayments,
	setCurrentPage,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationBankPaymentsPage);
