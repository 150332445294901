// @flow

import {
	GET_STATEMENTS,
	GET_STATEMENT,
	UPDATE_STATEMENT,
	UPDATE_STATEMENTS,
} from '../const/types';

export function getStatements(appId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_STATEMENTS,
		payload: {
			request: {
				url: `/applications/${appId}/settings/statements/`,
			},
		},
	});
}

export function updateStatements(appId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_STATEMENTS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${appId}/settings/statements`,
				data,
			},
		},
	});
}

export function getStatement(appId: string, statementId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_STATEMENT,
		payload: {
			request: {
				url: `/applications/${appId}/settings/statements/${statementId}`,
			},
		},
	});
}

export function updateStatement(appId: string, statementId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_STATEMENT,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${appId}/settings/statements/${statementId}`,
				data,
			},
		},
	});
}
