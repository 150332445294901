// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import AccountsList from '../components/AccountsList';
import AssociateForm from '../components/AssociateForm';
//= import actions
import { getCustomerBankAccounts, associateBankAccount } from '../../../modules/actions/BankActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { BankAccountType } from '../../../modules/reducers/BankReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Props = {
	getCustomerBankAccounts: (string, number, string) => void,
	associateBankAccount: (string, string, Object) => Promise<Object>,
	customerAccounts: Array<BankAccountType>,
	isFetchingCustomerAccounts: boolean,
	customerAccountsTotal: number,
	currentPage: number,
	customerAccountsFilters: { [string]: string },
	setCurrentPage: (string, number) => void,
	getProvidersInstances: (string) => void,
	providers: Array<ProviderInstance>,
	match: {
		params: {
			applicationId: string,
		}
	}
}
type FormValue = {
	provider: string,
	externalId: string,
    name: string,
    description: string,
    instrumentId: string,
    isCollection: true,
    isPayout: true
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

function CustomerBankAccounts(props: Props) {
	const {
		getCustomerBankAccounts: getCustomerBankAccountsActions,
		associateBankAccount: associateBankAccountAction,
		customerAccounts,
		isFetchingCustomerAccounts,
		customerAccountsTotal,
		currentPage,
		customerAccountsFilters,
		setCurrentPage: setCurrentPageAction,
		getProvidersInstances: getProvidersInstancesAction,
		providers,
		match: { params: { applicationId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [edit, setEdit] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [success, setSuccess] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const filterRef = useRef(null);
	const formRef = useRef(null);

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		async function getData() {
			try {
				await getCustomerBankAccountsActions(applicationId, currentPage, queryString.stringify(customerAccountsFilters, { arrayFormat: 'comma' }));
				setErrorMessage('');
			} catch (err) {
				const errMessage: string = err.error?.response?.data?.message;
				if (errMessage && errMessage.startsWith('You do not have permission')) {
					setErrorMessage("This feature is not available for your application. If you're interested in it, please contact your TradeCore representative.");
				}
			}
		}
		getData();
	}, [applicationId, getCustomerBankAccountsActions, currentPage, customerAccountsFilters]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [customerAccountsFilters]);

	const handleAssociateClick = () => {
		setEdit(true);
		setSuccess(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error?.response ? error.error.response.data.message : 'Error',
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleOnSuccess = () => {
		setWarningModal(true);
		setWarningTitle('Success');
		setMessage({ firstPart: "Congrats. You've successfully associated bank accounts." });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const submitEdit = (value: FormValue) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		const providerName = value.provider;
		const data = {
			externalId: value.externalId,
			name: value.name,
			description: value.description,
			instrumentId: value.instrumentId,
			isCollection: value.isCollection,
			isPayout: value.isPayout,
		};
		associateBankAccountAction(applicationId, providerName, data)
			.then(() => {
				setButtonLoading(false);
				handleOnSuccess();
				setSuccess(true);
			})
			.catch((error: ErrorType) => {
				setSuccess(false);
				handleOnError(error);
			});
	};

	const continueEdit = (value: FormValue) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect Customer Bank Account.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEdit(value), text: 'associate customer bank account' },
		]);
	};

	const handleCustomerAccountsPageChange = (page: number) => {
		setCurrentPageAction('customerAccounts', page);
	};

	const availableProviders = providers.filter((el) => el.provider.name === 'modulr').map((el) => ({
		id: el.provider.name,
		name: el.provider.name,
		displayName: el.provider.displayName,
	}));

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="bank accounts"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
						{
							text: 'create bank account',
							icon: 'Plus',
							disabled: true,
						},
						{
							action: handleAssociateClick,
							text: 'associate bank account',
							icon: 'Paperclip',
						},
					]}
					applicationId={applicationId}
					place="customerAccounts"
					fields={{
						instrumentId: StatusHelpers.bankInstruments,
						provider: availableProviders,
						dateRange: 'time',
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
				/>
			</div>
			<div className="container">
				{errorMessage
					? (
						<div className="empty-state">
							<UserNoChecks />
							<p>{errorMessage}</p>
						</div>
					)
					: (
						<AccountsList
							applicationId={applicationId}
							accounts={customerAccounts}
							accountsTotal={customerAccountsTotal}
							currentPage={currentPage}
							headerHeight={filterHeight}
							handlePageChange={handleCustomerAccountsPageChange}
							place="customerAccounts"
							loading={isFetchingCustomerAccounts}
						/>
					)}
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleEditCancel}
					footer={footer}
					message={message}
					success={success}
					loading={buttonLoading}
				/>
				<FormModal
					title="Associate External Bank Account"
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<AssociateForm
							submitChanges={continueEdit}
							handleCancelClick={handleEditCancel}
							applicationId={applicationId}
							wrappedComponentRef={formRef}
							providers={availableProviders}
						/>
					)}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.customerAccountsPage,
	customerAccountsFilters: state.ui.customerAccountsFilters,
	customerAccounts: state.bank.customerAccounts,
	isFetchingCustomerAccounts: state.bank.isFetchingCustomerAccounts,
	customerAccountsTotal: state.bank.customerAccountsTotal,
	providers: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getCustomerBankAccounts,
	setCurrentPage,
	getProvidersInstances,
	associateBankAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBankAccounts);
