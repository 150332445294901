// @flow

import React from 'react';
import map from 'lodash/map';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import InputNumber from '../../../components/UiElements/InputNumber';
import Switch from '../../../components/UiElements/Switch';
import Select from '../../../components/UiElements/Select';
import Checkbox from '../../../components/UiElements/Checkbox';
//= import types
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { FormProps } from '../../../modules/types/FormTypes';

type Props = {
	form: FormProps,
	providerInstance: ProviderInstance,
	formTemplate: { [string]: Object },
	submitChanges: (Object) => void,
	handleCancelClick: () => void,
	type: string,
}
type LocalState = {
	primary: boolean,
	active: boolean,
	isTouched: boolean,
}
type Values = {
	config: { [string]: string }
}
type ConfigValue = {
	description: string,
	label: string,
	type: string,
	multiple: boolean,
	options: Array<Object>,
}
class EditProviderForm extends React.Component<Props, LocalState> {
	state = {
		primary: this.props.providerInstance?.primary,
		active: this.props.providerInstance?.active,
		isTouched: false,
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			primary: false,
			active: false,
			isTouched: false,
		});
	}

	handlePrimaryChange = (value: boolean) => {
		this.setState({
			primary: value,
			isTouched: true,
		});
	}

	handleActiveChange = (value: boolean) => {
		this.setState({
			active: value,
			isTouched: true,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges, type } = this.props;
		const { primary, active } = this.state;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (!err) {
				const data = type === 'create'
					? {
						config: values,
					} : {
						config: form.isFieldsTouched() ? values : undefined,
						primary,
						active,
					};
				submitChanges(data);
			}
		});
	}

	getFormElement = (name: string, template: ConfigValue) => {
		const { Option } = Select;
		if (template.type === 'text') {
			return (
				<Input
					name={name}
					placeholder={template.description}
				/>
			);
		} if (template.type === 'select') {
			return (
				<Select
					allowClear
					mode={template.multiple ? 'multiple' : 'default'}
					placeholder={template.description}
				>
					{template.options.map((el) => (
						<Option
							value={el.value}
							key={el.value}
						>
							{el.label}
						</Option>
					))}
				</Select>
			);
		} if (template.type === 'checkbox') {
			return (
				<Checkbox />
			);
		} if (template.type === 'number') {
			return (
				<InputNumber placeholder={template.description} />
			);
		}
		return (
			<Input
				name={name}
				placeholder={template.description}
			/>
		);
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			providerInstance,
			formTemplate,
			type,
		} = this.props;
		const { primary, active, isTouched } = this.state;
		const FormItem = Form.Item;
		const labelCol = { xs: { span: 11 } };
		const wrapperCol = { xs: { span: 13 } };
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className="group-form">
				{type !== 'create' && (
					<>
						<FormItem label="PRIMARY" labelCol={labelCol} wrapperCol={wrapperCol}>
							<Switch
								defaultChecked={primary}
								onChange={this.handlePrimaryChange}
								checkedChildren="Yes"
								unCheckedChildren="No"
							/>
						</FormItem>
						<FormItem label="ACTIVE" labelCol={labelCol} wrapperCol={wrapperCol}>
							<Switch
								defaultChecked={active}
								onChange={this.handleActiveChange}
								checkedChildren="Yes"
								unCheckedChildren="No"
							/>
						</FormItem>
					</>
				)}
				{((type === 'update' && providerInstance.config) || (type === 'create')) && (
					<div>
						{map(formTemplate, (value: ConfigValue, key: string) => (
							<FormItem
								label={value.label}
								labelCol={labelCol}
								wrapperCol={wrapperCol}
								help=""
								key={key}
							>
								{getFieldDecorator(key, { initialValue: providerInstance?.config?.[key], valuePropName: value.type === 'checkbox' ? 'checked' : 'value' })(
									this.getFormElement(key, value),
								)}
							</FormItem>
						))}
					</div>
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton
							type="primary"
							action={this.submit}
							disabled={!isTouched && !isFieldsTouched()}
							text={type === 'create' ? 'config provider instance' : 'update provider instance'}
						/>
					</FormItem>
				</div>
			</Form>
		);
	}
}
const ProviderForm = Form.create()(EditProviderForm);

ProviderForm.defaultProps = {
	comingSoon: false,
};

export default ProviderForm;
