const initialState = {
	isUpdating: false,
	isUpdated: false,
	error: null,
	status: null,
};

export default function updateStatusReducer(state = initialState, action) {
	switch (action.type) {
	case 'UPDATE_STATUS':
		return {
			...state,
			isUpdating: true,
		};
	case 'UPDATE_STATUS_FAIL':
		return {
			...state,
			isUpdating: false,
			isUpdated: false,
			error: action.error,
		};
	case 'UPDATE_STATUS_SUCCESS':
		return {
			...state,
			isUpdating: false,
			isUpdated: true,
			error: null,
			status: action.payload.data.status,
		};
	default:
		return state;
	}
}
