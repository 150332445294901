// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import types
import type { SuspendReason } from '../../../modules/reducers/UsersReducer';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	reason: SuspendReason,
	applicationId: string,
	type: string,
}

type LocalState = {
	errorMessage: string,
}

class Reason extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			reason,
			type,
		} = this.props;
		const {
			errorMessage,
		} = this.state;
		const FormItem = Form.Item;
		const { TextArea } = Input;
		const formLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className="group-form">
				<Form.Item label="TITLE" {...formLayout}>
					{getFieldDecorator('title', {
						initialValue: reason.title,
						rules: [{ required: true, message: "Suspend Reason Title can't be left empty" }],
					})(
						<Input
							name="title"
							placeholder="Please input title (limit 20)"
							allowClear
							maxLength={20}
						/>,
					)}
				</Form.Item>
				<Form.Item label="DESCRIPTION" {...formLayout}>
					{getFieldDecorator('description', {
						initialValue: reason.description,
						rules: [{ required: true, message: "Suspend Reason Description can't be left empty!" }],
					})(
						<TextArea
							maxLength={100}
							placeholder="Please input description (limit 100)"
							rows="3"
						/>,
					)}
				</Form.Item>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text={`${type} suspend reason`} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const ReasonForm = Form.create()(Reason);

export default ReasonForm;
