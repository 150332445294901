// @flow

import React from 'react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
//= import components
import Card from '../../../components/UiElements/Card';
import Table from '../../../components/UiElements/Table';
//= import types
import type { FullOrder } from '../../../modules/reducers/OrderReducer';
import Numbers from '../../../lib/helpers/numbersHelpers';

type Props = {
	order: FullOrder,
	firstWallet: Array<Object>,
	secondWallet: Array<Object>,
	thirdWallet: Array<Object>,
}

const OrderTimeTable = (props: Props) => {
	const {
		order, firstWallet, secondWallet, thirdWallet,
	} = props;
	const columns = [
		{
			title: 'event',
			dataIndex: 'event',
			key: 'event',
			width: 240,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'created at',
			dataIndex: 'time',
			key: 'time',
			width: 192,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss.SSS')}
				</div>
			),
		},
		{
			title: 'time diff',
			dataIndex: 'timeDiff',
			key: 'timeDiff',
			width: 84,
			align: 'right',
			render: (text: string) => (
				<div className="wrap">
					{`${text} ms`}
				</div>
			),
		},
	];

	const reservationData = firstWallet
		? firstWallet.map((el) => ({
			event: `${el.instrumentId} wallet order ${el.type === 'reservation' && !Numbers.sign(el.amount) ? 'release reservation' : el.type}`,
			time: el.createdAt,
		}))
		: [];
	const transactionData = secondWallet
		? secondWallet.map((el) => ({
			event: `${el.instrumentId} wallet order ${el.type === 'reservation' ? 'release reservation' : el.type}`,
			time: el.createdAt,
		}))
		: [];
	const restData = thirdWallet
		? thirdWallet.map((el) => ({
			event: `${el.instrumentId} wallet order ${el.type}`,
			time: el.createdAt,
		}))
		: [];
	const placedData = order.placedOnExchangeAt
		? [{
			event: 'placed on exchange at',
			time: order.placedOnExchangeAt,
		}]
		: [];
	const exchangeExecutionData = order.providerLogs.length
		? [
			{
				event: 'response from exchange',
				time: order.providerLogs[0].createdAt,
			},
		]
		: [];
	const executionData = order.executedAt
		? [
			{
				event: 'executed',
				time: order.executedAt,
			},
		]
		: [];

	const data = [
		{
			event: 'orderCreated',
			time: order.createdAt,
		},
		...reservationData,
		...placedData,
		...exchangeExecutionData,
		...executionData,
		...transactionData,
		...restData,
	];

	const orderedData = orderBy(data, 'time');
	const diffData = orderedData.map((el, index) => {
		if (index > 0) {
			return {
				...el,
				timeDiff: moment(el.time).diff(moment(orderedData[index - 1].time), 'milliseconds'),
			};
		}
		return {
			...el,
			timeDiff: '0',
		};
	});

	return (
		<>
			{order && (
				<Card bordered={false} className="col-margin">
					<h2>Order Time Table</h2>
					<Table
						rowKey={(record) => record.time}
						columns={columns}
						dataSource={diffData}
						locale={{ emptyText: 'No data!' }}
						pagination={false}
					/>
				</Card>
			)}
		</>
	);
};


export default OrderTimeTable;
