// @flow

//= import helpers
import PriceHelpers from './priceHelpers';
//= import types
import type { ActivityLog } from '../../modules/reducers/LogsReducer';

class ActivityHelpers {
	static affectedObject = [
		{ id: 'user' },
		{ id: 'paymentMethod' },
		{ id: 'payment' },
		{ id: 'deposit' },
		{ id: 'withdrawal' },
		{ id: 'order' },
		{ id: 'symbol' },
		{ id: 'margin' },
		{ id: 'price' },
		{ id: 'kycDocument' },
		{ id: 'application' },
		{ id: 'applicationSetting' },
		{ id: 'applicationProviderInstance' },
		{ id: 'groupSymbol' },
		{ id: 'recurringOrder' },
		{ id: 'wallet' },
		{ id: 'iban' },
		{ id: 'exploreSection' },
		{ id: 'exploreGroup' },
		{ id: 'device' },
		{ id: 'customProperty' },
		{ id: 'webhook' },
		{ id: 'feeRule' },
		{ id: 'group' },
		{ id: 'applicationFeature' },
		{ id: 'file' },
		{ id: 'tradingAccount' },
		{ id: 'walletAddress' },
		{ id: 'recurringDeposit' },
		{ id: 'applicationSymbol' },
		{ id: 'applicationInstrument' },
		{ id: 'exchange' },
		{ id: 'dividendPayout' },
		{ id: 'userDividendPayout' },
		{ id: 'dividend' },
	];

	static eventName = [
		{ id: 'passwordUpdated' },
		{ id: 'deactivated' },
		{ id: 'closed' },
		{ id: 'canceled' },
		{ id: 'passwordResetRequested' },
		{ id: 'onboardingCompleted' },
		{ id: 'rejected' },
		{ id: 'accessGranted' },
		{ id: 'verified' },
		{ id: 'identityCheckFailed' },
		{ id: 'statusUpdated' },
		{ id: 'loggedIn' },
		{ id: 'loggedOut' },
		{ id: 'suspended' },
		{ id: 'credited' },
		{ id: 'debited' },
		{ id: 'balanceUpdated' },
		{ id: 'placed' },
		{ id: 'approved' },
		{ id: 'executed' },
		{ id: 'charged' },
		{ id: 'partiallyExecuted' },
		{ id: 'unfollowed' },
		{ id: 'enabled' },
		{ id: 'closeOnly' },
		{ id: 'delisted' },
		{ id: 'documentUploaded' },
		{ id: 'created' },
		{ id: 'updated' },
		{ id: 'deleted' },
		{ id: 'cancelled' },
		{ id: 'followed' },
		{ id: 'used' },
		{ id: 'movement' },
		{ id: 'sessionChanged' },
		{ id: 'failed' },
		{ id: 'faildToExecute' },
	];

	static transformToReadable = (text: string): string => {
		if (text === 'tagIds') return 'Tags';
		const result = text.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2');
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
		return finalResult;
	}

	static transformSnakeCase = (text: string): string => {
		const result = text.replace(/\s+|_+/g, ' ');
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
		return finalResult;
	}

	static devideText = (text: string): {[string]: string} => {
		if (text.length < 3750) {
			const result = {
				firstPart: text,
			};
			return result;
		}
		if (text.length > 3750 && text.length < 7500) {
			const firstPointIndex = text.lastIndexOf('. ', 3750);
			const firstPart = text.slice(0, firstPointIndex + 2);
			const secondPart = text.slice(firstPointIndex + 2, text.length);
			const result = { firstPart, secondPart };
			return result;
		}
		if (text.length > 7500 && text.length < 11250) {
			const firstPointIndex = text.lastIndexOf('. ', 3750);
			const firstPart = text.slice(0, firstPointIndex + 2);
			const secondPointIndex = text.lastIndexOf('. ', 7500);
			const secondPart = text.slice(firstPointIndex + 2, secondPointIndex + 2);
			const thirdPart = text.slice(secondPointIndex + 2, text.length);
			const result = { firstPart, secondPart, thirdPart };
			return result;
		}
		if (text.length > 11250) {
			const firstPointIndex = text.lastIndexOf('. ', 3750);
			const firstPart = text.slice(0, firstPointIndex + 2);
			const secondPointIndex = text.lastIndexOf('. ', 7500);
			const secondPart = text.slice(firstPointIndex + 2, secondPointIndex + 2);
			const thirdPointIndex = text.lastIndexOf('. ', 11250);
			const thirdPart = text.slice(secondPointIndex + 2, thirdPointIndex + 2);
			const fourthPart = text.slice(thirdPointIndex + 2, 15000);
			const result = {
				firstPart, secondPart, thirdPart, fourthPart,
			};
			return result;
		}
		return { firstPart: text };
	};

	static createActivityName = (activity: ActivityLog) => {
		const ac = {
			order: {
				event: `${activity.affectedModel}
						${activity.affectedObjectNew.type || ''}
						${activity.affectedObjectNew.side || ''}
						${(activity.affectedObjectNew.symbol
					? activity.affectedObjectNew.symbol.id
					: activity.affectedObjectNew.symbolId) || ''}
						${activity.eventName}`,
				amount: PriceHelpers.formatAmount(
					activity.affectedObjectNew.reservationInfo ? activity.affectedObjectNew.reservationInfo.quantity : '',
					activity.affectedObjectNew.symbol ? activity.affectedObjectNew.symbol.baseInstrumentId : '',
				),
			},
			wallet: {
				event: `${activity.affectedObjectNew.wallet
					? activity.affectedObjectNew.wallet.instrumentId
					: activity.affectedObjectNew.instrumentId}
						${activity.affectedModel}
						${ActivityHelpers.transformToReadable(activity.eventName)}`,
				amount: PriceHelpers.formatAmount(
					activity.affectedObjectNew.amount || '',
					activity.affectedObjectNew.wallet
						? activity.affectedObjectNew.wallet.instrumentId
						: activity.affectedObjectNew.instrumentId,
				),
			},
			deposit: {
				event: `${activity.affectedObjectNew.request
					? (activity.affectedObjectNew.request.instrumentId : '')
					: activity.affectedObjectOld.instrumentId}
					${activity.affectedModel}
					${activity.eventName} `,
				amount: PriceHelpers.formatAmount(
					activity.affectedObjectNew.request
						? activity.affectedObjectNew.request.amount
						: activity.affectedObjectOld.amount,
					activity.affectedObjectNew.request
						? activity.affectedObjectNew.request.instrumentId
						: activity.affectedObjectOld.instrumentId,
				),
			},
			withdrawal: {
				event: `${activity.affectedObjectNew.instrumentId}
						${activity.affectedModel}
						${activity.eventName} `,
				amount: PriceHelpers.formatAmount(
					activity.affectedObjectNew.amount,
					activity.affectedObjectNew.instrumentId,
				),
			},
			tradingAccount: {
				event: `${ActivityHelpers.transformToReadable(activity.affectedModel)}
						${activity.affectedObjectNew.name}
						${activity.eventName} `,
				amount: '',
			},
			paymentMethod: {
				event: `${ActivityHelpers.transformToReadable(activity.affectedModel)}
						${activity.affectedObjectNew.reference || activity.affectedObjectOld.reference}
						${activity.eventName} `,
				amount: '',
			},
			symbol: {
				event: `${activity.affectedModel}
						${activity.affectedObjectNew.id}
						${activity.eventName} `,
				amount: '',
			},
			recurringDeposit: {
				event: `${ActivityHelpers.transformToReadable(activity.affectedModel)}
						${activity.affectedObjectNew.payment ? activity.affectedObjectNew.payment.instrumentId : ''}
						${activity.eventName} `,
				amount: PriceHelpers.formatAmount(
					activity.affectedObjectNew.payment
						? activity.affectedObjectNew.payment.amount
						: '',
					activity.affectedObjectNew.payment
						? activity.affectedObjectNew.payment.instrumentId
						: '',
				),
			},
			recurringOrder: {
				event: `${ActivityHelpers.transformToReadable(activity.affectedModel)}
						${activity.affectedObjectNew.type || ''}
						${activity.affectedObjectNew.side || ''}
						${activity.affectedObjectNew.symbol
					? activity.affectedObjectNew.symbol.id
					: activity.affectedObjectNew.symbolId || ''}
						${activity.eventName} `,
				amount: PriceHelpers.formatAmount(
					activity.affectedObjectNew.reservationInfo ? activity.affectedObjectNew.reservationInfo.quantity : '',
					activity.affectedObjectNew.symbol ? activity.affectedObjectNew.symbol.baseInstrumentId : '',
				),
			},
			default: {
				event: `${ActivityHelpers.transformToReadable(activity.affectedModel)} ${ActivityHelpers.transformToReadable(activity.eventName)} `,
				amount: '-',
			},
		};
		if (!ac[activity.affectedModel]) {
			return ac.default;
		}
		return ac[activity.affectedModel];
	}
}

export default ActivityHelpers;
