// @flow

import {
	ORDERS,
	ORDER,
	CANCEL_ORDER,
	APPLICATION_ORDERS,
	RECURRING_ORDERS,
	RECURRING_ORDER,
	PLACE_ORDER,
	GET_QUOTE_FOR_ORDER,
} from '../const/types';

export function getOrders(accountId: string, page: number, pagLimit: number, parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: ORDERS,
		payload: {
			request: {
				url: `/accounts/${accountId}/orders?${parametar || ''}`,
				headers: {
					'pagination-limit': pagLimit,
					'pagination-offset': (page - 1) * pagLimit,
				},
			},
		},
	});
}

export function getOrder(orderId: string) {
	return (dispatch: Function): void => dispatch({
		type: ORDER,
		payload: {
			request: {
				url: `/orders/${orderId}`,
			},
		},
	});
}

export function cancelOrder(accountId: string, orderId: string, data: {userId: string}) {
	return (dispatch: Function): void => dispatch({
		type: CANCEL_ORDER,
		payload: {
			request: {
				method: 'PATCH',
				url: `/accounts/${accountId}/orders/${orderId}/cancel`,
				data,
			},
		},
	});
}

export function getAppOrders(applicationId: string, page: number = 1, pageSize: number = 50,  parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: APPLICATION_ORDERS,
		payload: {
			request: {
				url: `applications/${applicationId}/orders?${parametar || ''}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function getRecurringOrder(accountId: string, orderId: string) {
	return (dispatch: Function): void => dispatch({
		type: RECURRING_ORDER,
		payload: {
			request: {
				url: `/accounts/${accountId}/recurring-orders/${orderId}`,
			},
		},
	});
}

export function getRecurringOrders(accountId: string) {
	return (dispatch: Function): void => dispatch({
		type: RECURRING_ORDERS,
		payload: {
			request: {
				url: `/accounts/${accountId}/recurring-orders`,
			},
		},
	});
}

export function placeOrder(accountId: string, data: {[string]: string}, parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: PLACE_ORDER,
		payload: {
			request: {
				method: 'POST',
				url: `/accounts/${accountId}/orders${parametar}`,
				data,
			},
		},
	});
}

export function getQuoteForOrder(accountId: string, provider: string, data: {[string]: string}) {
	return (dispatch: Function): void => dispatch({
		type: GET_QUOTE_FOR_ORDER,
		payload: {
			request: {
				method: 'POST',
				url: `/accounts/${accountId}/orders/quote/${provider}`,
				data,
			},
		},
	});
}
