// @flow

const initialState = {
	error: false,
	appFeatures: [],
	fetchingAppFeatures: false,
	appFeaturesFetched: false,
};

export type Feature = {
	id: string,
	applicationId: string,
	featureId: string,
	enabled: boolean,
	createdAt: string,
	updatedAt: string,
	feature: {
		id: string,
		name: string,
		description: string,
		enabled: boolean,
		createdAt: string,
		updatedAt: string,
	}
}

export type FeatureState = {
	error: Object | boolean,
	appFeatures: Array<Feature>,
	fetchingAppFeatures: boolean,
	appFeaturesFetched: boolean,
}

type FeatureAction = {
	type:
	| 'GET_APP_FEATURES'
	| 'GET_APP_FEATURES_SUCCESS'
	| 'GET_APP_FEATURES_FAIL',
	payload: {
		data: Array<Feature>,
	},
	error: {
		code: number,
		message: string,
	}
}
type UpdateAction = {
	type:
	| 'UPDATE_APP_FEATURES'
	| 'UPDATE_APP_FEATURES_SUCCESS'
	| 'UPDATE_APP_FEATURES_FAIL',
	payload: {
		data: Feature,
	},
	error: {
		code: number,
		message: string,
	}
}
type Action = FeatureAction | UpdateAction;

export default function featureReducer(
	state: FeatureState = initialState,
	action: Action,
): FeatureState {
	switch (action.type) {
	case 'GET_APP_FEATURES': {
		return {
			...state,
			fetchingAppFeatures: true,
			appFeaturesFetched: false,
			error: false,
			appFeatures: [],
		};
	}
	case 'GET_APP_FEATURES_SUCCESS': {
		return {
			...state,
			error: false,
			fetchingAppFeatures: false,
			appFeaturesFetched: true,
			appFeatures: action.payload.data,
		};
	}
	case 'GET_APP_FEATURES_FAIL': {
		return {
			...state,
			error: action.error,
			fetchingAppFeatures: false,
			appFeaturesFetched: false,
		};
	}
	case 'UPDATE_APP_FEATURES': {
		return {
			...state,
			updatingAppFeatures: true,
			appFeaturesUpdated: false,
			error: false,
		};
	}
	case 'UPDATE_APP_FEATURES_SUCCESS': {
		return {
			...state,
			error: false,
			updatingAppFeatures: false,
			appFeaturesUpdated: true,
		};
	}
	case 'UPDATE_APP_FEATURES_FAIL': {
		return {
			...state,
			error: action.error,
			updatingAppFeatures: false,
			appFeaturesUpdated: false,
		};
	}
	default:
		return state;
	}
}
