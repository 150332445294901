// @flow

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import Tag from '../../../components/UiElements/Tag';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { Rates } from '../../../modules/reducers/StaticReducer';

type Props = {
	data: Array<Wallet>,
	loading: boolean,
	accountCurrency: string,
	rates: Rates,
	applicationId: string,
	currentPage: number,
	numberOnPage: number,
}

const WalletsTable = (props: Props) => {
	const {
		loading,
		data,
		rates,
		accountCurrency,
		applicationId,
		walletsTotal,
		handlePageChange,
		currentPage,
		numberOnPage,
	} = props;

	const columns = [
		{
			title: 'date created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>,
		}, {
			title: 'wallet id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string, record: Wallet) => (
				<div>
					{text !== 'sum'
						? (
							<CopyComponent
								text={text}
								content={(
									<Link
										to={`/application/${applicationId}/clients/${record.userId}/user_wallets/${record.id}?accountId=${record.accountId}`}
									>
										{text}
									</Link>
								)}
							/>
						)
						: ''}
				</div>
			),
		}, {
			title: 'wallet',
			dataIndex: 'instrumentId',
			key: 'instrumentId',
			width: 208,
			render: (text: string, record: Wallet) => (
				<div className="flex">
					<span className="primary-tag">
						{text ? `${text} Wallet` : ''}
					</span>
					{record.primary && <Tag status="primary" />}
					{record.delisted && <Tag status="delisted" />}
				</div>
			),
		}, {
			title: 'available balance',
			dataIndex: 'available',
			key: 'available',
			align: 'right',
			width: 240,
			render: (text: string, record: Wallet) => (
				<div className="wrap">
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'available balance (reporting currency)',
			dataIndex: 'availableReporting',
			key: 'availableReporting',
			align: 'right',
			width: 304,
			render: (text: string, record: Wallet) => (
				<div className="wrap">
					{text
						? (
							<span className="bold">
								&Sigma; {text}
							</span>
						)
						: (
							<span>
								{rates[`${accountCurrency}${record.instrumentId}`]
									? PriceHelpers.convertToCurrency(
										record.available,
										accountCurrency,
										record.instrumentId,
										rates,
										StatusHelpers.defaultFiatPrecision,
										record.delisted,
									)
									: '-'}
							</span>
						)}
				</div>
			),
		}, {
			title: 'reserved balance',
			dataIndex: 'reserved',
			key: 'reserved',
			align: 'right',
			width: 240,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'reserved balance (reporting currency)',
			dataIndex: 'reservedReporting',
			key: 'reservedReporting',
			align: 'right',
			width: 304,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? (
							<span className="bold">
								&Sigma; {text}
							</span>
						)
						: (
							<span>
								{rates[`${accountCurrency}${record.instrumentId}`]
									? PriceHelpers.convertToCurrency(
										record.reserved,
										accountCurrency,
										record.instrumentId,
										rates,
										StatusHelpers.defaultFiatPrecision,
										record.delisted,
									)
									: '-'}
							</span>
						)}
				</div>
			),
		}, {
			title: 'total balance',
			dataIndex: 'total',
			key: 'total',
			align: 'right',
			width: 240,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'total balance (reporting currency)',
			dataIndex: 'totalReporting',
			key: 'totalReporting',
			align: 'right',
			width: 272,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? (
							<span className="bold">
								&Sigma; {text}
							</span>
						)
						: (
							<span>
								{rates[`${accountCurrency}${record.instrumentId}`]
									? PriceHelpers.convertToCurrency(
										record.total,
										accountCurrency,
										record.instrumentId,
										rates,
										StatusHelpers.defaultFiatPrecision,
										record.delisted,
									) : '-'}
							</span>
						)}
				</div>
			),
		}, {
			title: 'debt',
			dataIndex: 'debt',
			key: 'debt',
			align: 'right',
			width: 192,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text),
						)
						: ''}
				</div>
			),
		}, {
			title: 'debt (reporting currency)',
			dataIndex: 'debtReporting',
			key: 'debtReporting',
			align: 'right',
			width: 224,
			render: (text: string, record: Wallet) => (
				<div>
					{text
						? (
							<span className="bold">
								&Sigma; {text}
							</span>
						)
						: (
							<span>
								{rates[`${accountCurrency}${record.instrumentId}`]
									? PriceHelpers.convertToCurrency(
										record.debt,
										accountCurrency,
										record.instrumentId,
										rates,
										StatusHelpers.defaultFiatPrecision,
										record.delisted,
									)
									: '-'}
							</span>
						)}
				</div>
			),
		}, {
			title: 'updated at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>,
		},
	];

	const sum = {
		id: 'sum',
		availableReporting: PriceHelpers.getAccountValue(
			'available',
			data,
			accountCurrency,
			rates,
		),
		reservedReporting: PriceHelpers.getAccountValue(
			'reserved',
			data,
			accountCurrency,
			rates,
		),
		debtReporting: PriceHelpers.getAccountValue(
			'debt',
			data,
			accountCurrency,
			rates,
		),
		totalReporting: PriceHelpers.getAccountValue(
			'total',
			data,
			accountCurrency,
			rates,
		),
	};

	const sumData = [...data, sum];

	return (
		<div>
			{!loading
				? (
					<CustomTable
						columns={columns}
						data={sumData}
						total={walletsTotal}
						currentPage={currentPage}
						numberOnPage={numberOnPage}
						handlePageChange={handlePageChange}
						place="userWallets"
						applicationId={applicationId}
						rowClassName="sum"
					/>
				)
				: (
					<Spin loading={loading} position="spin-relative" />
				)}
		</div>

	);
};

const mapStateToProps = (state: State, props) => ({
	walletsTotal: state.wallet.walletsTotal,
});

export default connect(mapStateToProps)(WalletsTable);
