// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import { getInventoryWallets } from '../../../modules/actions/InventoryWalletsAction';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { InventoryWallet } from '../../../modules/reducers/InventoryWalletReducer';
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getInventoryWallets: (string, number, string) => void,
	inventoryWallets: Array<InventoryWallet>,
	isFetchingInventory: boolean,
	applicationId: string,
	currentPage: number,
	inventoryWalletsTotal: number,
	setCurrentPage: (string, number) => void,
}
const DelistedInventory = (props: Props) => {
	const {
		getInventoryWallets: getInventoryWalletsAction,
		isFetchingInventory,
		inventoryWallets,
		applicationId,
		currentPage,
		inventoryWalletsTotal,
		setCurrentPage: setCurrentPageAction,
	} = props;

	useEffect(() => {
		const filters = { delisted: true };
		getInventoryWalletsAction(applicationId, 1, queryString.stringify(filters));
	}, [getInventoryWalletsAction, applicationId]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('delistedInventoryPage', page);
	};

	const columns = [
		{
			title: 'wallet',
			dataIndex: 'symbolId',
			key: 'symbolId',
			width: 224,
			render: (text: string) => (
				<a
					href={`/application/${applicationId}/inventory/${text}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{text}
				</a>
			),
		},
		{
			title: 'exchange',
			dataIndex: 'symbol.exchangeId',
			key: 'exchange',
			width: 224,
			render: (text: string) => (
				<div className="capitalize">
					{ActivityHelpers.transformSnakeCase(text)}
				</div>
			),
		},
		{
			title: 'unallocated balance',
			dataIndex: 'available',
			key: 'available',
			align: 'right',
			width: 224,
			render: (text: string, record: { symbol: Symbol }) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.symbol.baseInstrumentId,
						Numbers.sign(text),
					)}
				</div>
			),
		},
		{
			title: 'allocated balance',
			dataIndex: 'allocated',
			key: 'allocated',
			align: 'right',
			width: 224,
			render: (text: string, record: { symbol: Symbol }) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.symbol.baseInstrumentId,
						Numbers.sign(text),
					)}
				</div>
			),
		},
		{
			title: 'adjustment',
			dataIndex: 'adjustment',
			key: 'adjustment',
			align: 'right',
			width: 224,
			render: (text: string, record: { symbol: Symbol }) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.symbol.baseInstrumentId,
						Numbers.sign(text),
					)}
				</div>
			),
		},
	];

	return (
		<div>
			{!isFetchingInventory
				? (
					<CustomTable
						columns={columns}
						data={inventoryWallets}
						total={inventoryWalletsTotal}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={16}
						place="delistedInventory"
						applicationId={applicationId}
					/>
				)
				: <Spin spinning={isFetchingInventory} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	isFetchingInventory: state.inventoryWallets.isFetchingInventoryWallets,
	inventoryWallets: state.inventoryWallets.inventoryWallets,
	curentPAge: state.ui.delistedInventoryPage,
	inventoryWalletsTotal: state.inventoryWallets.inventoryWalletsTotal,
});

const mapDispatchToProps = {
	getInventoryWallets,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DelistedInventory);
