// @flow

import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { notification } from 'antd';

//= import components
import Form from '../../../../Form';
import Input from '../../../../Input';
import CPButton from '../../../../Button';
import ErrorMessage from '../../../../ErrorMessage';
//= import actions
import { changePassword } from '../../../../../../modules/actions/OauthActions';

const fixedLayout = {
	labelCol: {
		xs: { span: 12 },
	},
	wrapperCol: {
		xs: { span: 12 },
	},
};

const fields = (form) => [
	{
		id: 'oldPassword',
		label: 'OLD PASSWORD',
		placeholder: 'Provide the old password',
		dependencies: null,
		rules: [
			{ required: true, message: "Old password can't be left empty" },
		],
	},
	{
		id: 'newPassword',
		label: 'NEW PASSWORD',
		placeholder: 'Provide the new password',
		dependencies: null,
		rules: [
			{ required: true, message: "New password can't be left empty" },
		],
	},
	{
		id: 'newPasswordRepeat',
		label: 'NEW PASSWORD REPEAT',
		placeholder: 'Provide the new password',
		rules: [
			{
				validator: (rule, value, callback) => {
					if (form.getFieldValue('newPassword') !== value) {
						callback('The two passwords that you entered do not match');
					} else {
						callback();
					}
				},
			},
		],
	},
];

type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				title: string,
				errors: Array<Object>,
			}
		}
	}
}

type Props = {
	form: *,
	handleCancelClick: () => void,
	submitChanges: (value: Object) => void,
	changePassword: (string, string) => Promise<Object>,
	history: {
		push: (string) => void,
	},
};

const ChangePasswordForm = ({
	form,
	handleCancelClick,
	changePassword: changePasswordAction,
	history,
} : Props) => {
	const FormItem = Form.Item;
	const { getFieldDecorator, isFieldsTouched } = form;
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const submit = useCallback((e) => {
		e.preventDefault();

		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				setErrorMessage(formError.join(', '));
			} else {
				setLoading(true);
				changePasswordAction(values.oldPassword, values.newPassword)
					.then(() => {
						const duration = 5;

						notification.success({
							message: 'Password Successfully Changed',
							description: 'Please Log In With New Password',
							duration,
							placement: 'bottomLeft',
						});

						setTimeout(() => {
							history.push('/logout');
						}, 1000);

						handleCancelClick();
						setLoading(false);
					})
					.catch((error: ErrorType) => {
						const message = get(error, 'error.response.data.errors[0].messages[0].message');
						setErrorMessage(message || 'Something went wrong');
						setLoading(false);
					});
			}
		});
		// eslint-disable-next-line
	}, [form]);

	return (
		<Form onSubmit={() => {}} layout="horizontal" hideRequiredMark labelalign="left">
			{fields(form).map(({
				id, label, rules, placeholder,
			}) => (
				<FormItem key={id} label={label} {...fixedLayout} help="">
					{getFieldDecorator(
						id, { rules },
					)(
						<Input type="password" placeholder={placeholder} />,
					)}
				</FormItem>
			))}
			{errorMessage && (
				<ErrorMessage message={errorMessage} />
			)}
			<div className="form-buttons">
				<FormItem>
					<CPButton ghost action={handleCancelClick} text="cancel" />
				</FormItem>
				<FormItem>
					<CPButton loading={loading} type="primary" action={submit} disabled={!isFieldsTouched()} text="Change password" />
				</FormItem>
			</div>
		</Form>
	);
};

export default Form.create()(connect(null, { changePassword })(withRouter(ChangePasswordForm)));
