// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import components
import Spin from '../../../components/UiElements/Spin';
import Title from '../../../components/UiElements/Title';
import Checkbox from '../../../components/UiElements/Checkbox';
import Switch from '../../../components/UiElements/Switch';
import Input from '../../../components/UiElements/Input';
import CPButton from '../../../components/UiElements/Button';
import Tooltip from '../../../components/UiElements/Tooltip';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
// import actions
import { getStatement, updateStatement, updateStatements } from '../../../modules/actions/StatementsActions';
import { getApplication } from '../../../modules/actions/ApplicationActions';
import getFile from '../../../modules/actions/FilesActions';
// import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
// import types
import type { Statement } from '../../../modules/reducers/StatementsReducer';
import type { Application } from '../../../modules/reducers/ApplicationReducer';
import type { State } from '../../../modules/types/FlowTypes';

type StatementRes = {
	payload: {
		data: Statement,
	}
}
type FileRes = {
	payload: {
		data: {
			url: string,
		}
	}
}
type DetailsRes = {
	payload: {
		data: Application,
	}
}
type Props = {
	getStatement: (string, string) => Promise<StatementRes>,
	updateStatement: (string, string, Object) => Promise<StatementRes>,
	updateStatements: (string, Object) => Promise<Object>,
	statement: Statement,
	getApplication: (string, string) => Promise<DetailsRes>,
	application: Application,
	getFile: (string) => Promise<FileRes>,
	match: {
		params: {
			applicationId: string,
			statementId: string,
		}
	},
	history: {
		goBack: () => void,
	},
	customer: { referenceId: string },
}

const StatementPage = (props: Props) => {
	const {
		getStatement: getStatementAction,
		updateStatement: updateStatementAction,
		updateStatements: updateStatementsAction,
		getApplication: getApplicationAction,
		getFile: getFileAction,
		statement: {
			type,
			headerCompanyAddress,
			headerCompanyEmail,
			headerCompanyLogo,
			headerCompanyPhoneNumber,
			headerCompanyWebsite,
			footer,
			statementAdditionalContent,
		},
		application,
		match: {
			params: {
				applicationId, statementId,
			},
		},
		customer: { referenceId },
		history,
	} = props;

	const [logoValue, setLogoValue] = useState('');
	const [header, setHeader] = useState({});
	const [footerEdited, setFooter] = useState('');
	const [bodyEnabled, setBodyEnabled] = useState(false);
	const [bodyTitle, setBodyTitle] = useState('');
	const [bodyParagraph, setBodyParagraph] = useState('');
	const [apply, setApply] = useState(false);
	const [warningFooter, setWarningFooter] = useState([]);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const res: StatementRes = await getStatementAction(applicationId, statementId);
			const {
				headerCompanyAddress: address,
				headerCompanyEmail: email,
				headerCompanyPhoneNumber: phone,
				headerCompanyWebsite: website,
				headerCompanyLogo: logo,
				statementAdditionalContent: additionalContent,
			} = res.payload.data;
			setHeader({
				headerCompanyAddress: address,
				headerCompanyEmail: email,
				headerCompanyLogo: logo,
				headerCompanyPhoneNumber: phone,
				headerCompanyWebsite: website,
			});
			setBodyEnabled(additionalContent.enabled);
			setBodyTitle(additionalContent.title);
			setBodyParagraph(additionalContent.body);
			setFooter(res.payload.data.footer);
			const detailsData: DetailsRes = await getApplicationAction(referenceId, applicationId);
			setLoading(false);
			if (detailsData.payload.data.applicationDetail.logo) {
				const logoUrl = new URL(detailsData.payload.data.applicationDetail.logo);
				const logoId = logoUrl.pathname;
				const fileRes: FileRes = await getFileAction(logoId);
				setLogoValue(fileRes.payload.data.url);
			}
		}
		getData();
	}, [
		getStatementAction,
		getApplicationAction,
		applicationId,
		getFileAction,
		referenceId,
		statementId,
	]);

	const handleBack = () => {
		history.goBack();
	};

	const onHeaderChange = (id) => {
		setHeader({
			...header,
			[id]: !header[id],
		});
	};

	const onBodyTitleChange = ({ target: { value } }) => {
		setBodyTitle(value || null);
	};

	const onBodyParagraphChange = ({ target: { value } }) => {
		setBodyParagraph(value || null);
	};

	const onBodySwitch = (value) => {
		setBodyEnabled(value);
	};

	const onFooterChange = ({ target: { value } }) => {
		setFooter(value || null);
	};

	const backToDefault = () => {
		setHeader({
			headerCompanyLogo,
			headerCompanyAddress,
			headerCompanyEmail,
			headerCompanyPhoneNumber,
			headerCompanyWebsite,
		});
		setFooter(footer);
		setBodyEnabled(statementAdditionalContent.enabled);
		setBodyTitle(statementAdditionalContent.title);
		setBodyParagraph(statementAdditionalContent.body);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
	};

	const handleErrorCancel = () => {
		setWarningModal(false);
	};

	const handleOnError = (error) => {
		setLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setWarningFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};


	const saveChanges = async () => {
		setLoading(true);
		const data = {
			...header,
			statementAdditionalContent: {
				enabled: bodyEnabled,
				title: bodyTitle,
				body: bodyParagraph,
			},
			footer: footerEdited,
		};
		if (apply) {
			try {
				await updateStatementsAction(applicationId, data);
				setWarningModal(false);
				setLoading(false);
			} catch (error) { handleOnError(error); }
		} else {
			try {
				await updateStatementAction(applicationId, statementId, data);
				setWarningModal(false);
				setLoading(false);
			} catch (error) { handleOnError(error); }
		}
	};

	const saveClick = () => {
		setWarningModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes, they will affect ${apply ? 'All Statement Templates' : 'this Statement Template'}.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setWarningFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: saveChanges, text: 'save changes' },
		]);
	};

	const applyToAll = (e) => {
		setApply(e.target.checked);
	};

	const settings = [{
		name: 'Company Logo',
		id: 'headerCompanyLogo',
	},
	{
		name: 'Company Address',
		id: 'headerCompanyAddress',
	},
	{
		name: 'Company E-mail',
		id: 'headerCompanyEmail',
	},
	{
		name: 'Company Phone Number',
		id: 'headerCompanyPhoneNumber',
	},
	{
		name: 'Company Website',
		id: 'headerCompanyWebsite',
	}];

	const result = ActivityHelpers.devideText(bodyParagraph || '');

	return (
		<>
			<Title
				title={`statement template ${ActivityHelpers.transformToReadable(type || '')}`}
				applicationId={applicationId}
				place="statement"
				backAction={handleBack}
			/>
			<div className="page-container">
				{!loading && application.applicationDetail
					? (
						<div className="statement">
							<div className="edit">
								<div className="edit-header">
									<h4>Header Element</h4>
									{settings.map((el) => (
										<Checkbox
											key={el.id}
											checked={header[el.id]}
											onChange={() => onHeaderChange(el.id)}
										>
											{el.name}
										</Checkbox>
									))}
								</div>
								<h4>Footer Element</h4>
								<div className="item">
									<p>PARAGRAPH</p>
									<Input.TextArea
										name="footer"
										value={footerEdited}
										autoSize={{ minRows: 1, maxRows: 6 }}
										placeholder="Maximum Length 400 Characters"
										maxLength={400}
										onChange={(value) => onFooterChange(value)}
									/>
								</div>
								<h4>Additional Content Page</h4>
								<div className="item">
									<p>DISPLAY PAGE</p>
									<div>
										<Switch
											checked={bodyEnabled}
											onChange={(value) => onBodySwitch(value)}
										/>
									</div>
								</div>
								<div className="item">
									<p>TITLE</p>
									<Input.TextArea
										name="title"
										value={bodyTitle}
										autoSize={{ minRows: 1, maxRows: 6 }}
										placeholder="Maximum Length 50 Characters"
										maxLength={50}
										onChange={(value) => onBodyTitleChange(value)}
									/>
								</div>
								<div className="item">
									<p>PARAGRAPH</p>
									<Input.TextArea
										name="paragraph"
										value={bodyParagraph}
										autoSize={{ minRows: 6, maxRows: 80 }}
										placeholder="Maximum Length 15000 Characters"
										maxLength={15000}
										onChange={(value) => onBodyParagraphChange(value)}
									/>
								</div>
								<div className="statements-save">
									<Tooltip
										title="This will apply changes to all statement and confirmation templates"
										placement="topLeft"
									>
										<Checkbox
											checked={apply}
											onChange={applyToAll}
										>
											Apply to all
										</Checkbox>
									</Tooltip>
									<div className="statements-buttons">
										<CPButton ghost action={backToDefault} text="cancel" />
										<CPButton
											type="primary"
											action={saveClick}
											text="save changes"
										/>
									</div>
								</div>
							</div>
							<div>
								<h4>Statement Preview</h4>
								<div className="preview">
									<div>
										{header.headerCompanyLogo && !!logoValue
											&& <img src={logoValue} alt="logo" />}
										<div className="header">
											<div>
												{header.headerCompanyAddress
											&& <p>{`${application.applicationDetail.streetName || ''} ${application.applicationDetail.streetNumber || ''} ${application.applicationDetail.state || ''} ${application.applicationDetail.country || ''}`}</p>}
												{header.headerCompanyEmail
											&& <p>{application.applicationDetail.contactEmail}</p>}
												{header.headerCompanyPhoneNumber
											&& <p>{application.applicationDetail.phone}</p>}
												{header.headerCompanyWebsite
											&& <p>{application.applicationDetail.website}</p>}
											</div>
											<div>
												<p>From 2019-01-01 to 2019-01-31</p>
												<p>Isabelle Iglesias</p>
												<p>Account ID: d2cb2ce7</p>
												<p>Water Lane 13, SK9 5AF, United Kingdom</p>
												<p>+1 234 56 78 90</p>
											</div>
										</div>
									</div>
									<div className="table-body" />
									<div className="footer">{footerEdited}</div>
								</div>
								{bodyEnabled && (
									<div className="preview">
										<div>
											{header.headerCompanyLogo && !!logoValue
											&& <img src={logoValue} alt="logo" />}
											<div className="header">
												<div>
													{header.headerCompanyAddress
											&& <p>{`${application.applicationDetail.streetName || ''} ${application.applicationDetail.streetNumber || ''} ${application.applicationDetail.state || ''} ${application.applicationDetail.country || ''}`}</p>}
													{header.headerCompanyEmail
											&& <p>{application.applicationDetail.contactEmail}</p>}
													{header.headerCompanyPhoneNumber
											&& <p>{application.applicationDetail.phone}</p>}
													{header.headerCompanyWebsite
											&& <p>{application.applicationDetail.website}</p>}
												</div>
												<div>
													<p>From 2019-01-01 to 2019-01-31</p>
													<p>Isabelle Iglesias</p>
													<p>Account ID: d2cb2ce7</p>
													<p>Water Lane 13, SK9 5AF, United Kingdom</p>
													<p>+1 234 56 78 90</p>
												</div>
											</div>
										</div>
										<div className="body">
											<h2>{bodyTitle || ''}</h2>
											<div className="body-text">
												<div>{result.firstPart}</div>
												<div>{result.secondPart}</div>
											</div>
										</div>
										<div className="footer">{footerEdited}</div>
									</div>
								)}
								{bodyEnabled && result.thirdPart && (
									<div className="preview">
										<div className="header">
											<div>
												{header.headerCompanyAddress
											&& <p>{`${application.applicationDetail.streetName || ''} ${application.applicationDetail.streetNumber || ''} ${application.applicationDetail.state || ''} ${application.applicationDetail.country || ''}`}</p>}
												{header.headerCompanyEmail
											&& <p>{application.applicationDetail.contactEmail}</p>}
												{header.headerCompanyPhoneNumber
											&& <p>{application.applicationDetail.phone}</p>}
												{header.headerCompanyWebsite
											&& <p>{application.applicationDetail.website}</p>}
											</div>
											<div>
												<p>From 2019-01-01 to 2019-01-31</p>
												<p>Isabelle Iglesias</p>
												<p>Account ID: d2cb2ce7</p>
												<p>Water Lane 13, SK9 5AF, United Kingdom</p>
												<p>+1 234 56 78 90</p>
											</div>
										</div>
										<div className="body">
											<h2>{bodyTitle || ''}</h2>
											<div className="body-text">
												<div>{result.thirdPart}</div>
												<div>{result.fourthPart}</div>
											</div>
										</div>
										<div className="footer">{footerEdited}</div>
									</div>
								)}
							</div>
						</div>
					)
					: <Spin spinning={loading} />}
			</div>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={warningFooter}
				message={message}
				loading={loading}
			/>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	statement: state.statements.statement,
	application: state.application.application,
	customer: state.oauth.customer,
});

const mapDispatchToProps = {
	getStatement,
	getApplication,
	getFile,
	updateStatement,
	updateStatements,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementPage);
