// @flow

import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
//= import components
import Sider from '../Sider';
import Menu from '../../Menu';
import MenuIcon from '../../Icon/MenuIcon';
//= import actions
import { setMenuState } from '../../../../modules/actions/UiActions';
//= import types
import type { State } from '../../../../modules/types/FlowTypes';

type Props = {
	applicationId: string,
	applicationName: string,
	selected: string,
	collapsed: boolean,
	setMenuState: (boolean) => void,
};

function Sidebar(props: Props) {
	const {
		applicationId,
		applicationName,
		selected,
		collapsed,
	} = props;

	const setCollapsed = () => {
		props.setMenuState(!collapsed);
	};

	const { SubMenu } = Menu;

	return (
		<Sider
			className={collapsed ? 'sidebar collapsed-menu' : 'sidebar'}
			width={180}
			collapsible
			collapsed={collapsed}
			collapsedWidth={64}
			trigger={null}
			style={{
				height: '100vh',
				overflowY: 'auto',
			}}
		>
			<Menu
				selectedKeys={[selected]}
				mode="inline"
				theme="dark"
				inlineIndent={16}
				className="side-menu"
				style={{
					position: 'relative',
					overflowY: 'auto',
					height: '100vh',
					minHeight: '480px',
				}}
			>
				<Menu.Item key="customer">
					<Icon component={() => MenuIcon({
						type: 'CompanyLogo', viewBox: '0 0 36 36', width: '16', height: '16',
					})}
					/>
					<span className="nav-text">{applicationName}</span>
					<NavLink to="/" />
				</Menu.Item>
				<Menu.Item key="clients">
					<Icon component={() => MenuIcon({ type: 'Client' })} />
					<span className="nav-text">Clients</span>
					<NavLink to={`/application/${applicationId}/clients`} />
				</Menu.Item>
				<Menu.Item key="payments">
					<Icon component={() => MenuIcon({ type: 'Bill' })} />
					<span className="nav-text">Payments</span>
					<NavLink to={`/application/${applicationId}/payments`} />
				</Menu.Item>
				<SubMenu
					key="sub1"
					title={(
						<span className="submenu-item">
							<Icon component={() => MenuIcon({ type: 'Trade' })} />
							<span className="nav-text">Investing</span>
						</span>
					)}
				>
					<Menu.Item key="orders">
						<span className="nav-text">Orders</span>
						<NavLink to={`/application/${applicationId}/orders`} />
					</Menu.Item>
					<Menu.Item key="borrowers">
						<span className="nav-text">Borrowers</span>
						<NavLink to={`/application/${applicationId}/borrowers`} />
					</Menu.Item>
					<Menu.Item key="inventory">
						<span className="nav-text">Symbol Inventory</span>
						<NavLink to={`/application/${applicationId}/inventory`} />
					</Menu.Item>
				</SubMenu>
				<SubMenu
					key="sub2"
					title={(
						<span>
							<Icon component={() => MenuIcon({ type: 'Bank' })} />
							<span className="nav-text">Banking</span>
						</span>
					)}
				>
					<Menu.Item key="banking">
						<span className="nav-text">My Bank Accounts</span>
						<NavLink to={`/application/${applicationId}/banking`} />
					</Menu.Item>
					<Menu.Item key="client_bank_accounts">
						<span className="nav-text">Client Bank Accounts</span>
						<NavLink to={`/application/${applicationId}/client_bank_accounts`} />
					</Menu.Item>
					<Menu.Item key="bank_payments">
						<span className="nav-text">Bank Payments</span>
						<NavLink to={`/application/${applicationId}/bank_payments`} />
					</Menu.Item>
				</SubMenu>
				<SubMenu
					key="sub3"
					title={(
						<span>
							<Icon component={() => MenuIcon({ type: 'Payments' })} />
							<span className="nav-text">Cards</span>
						</span>
					)}
				>
					<Menu.Item key="cards">
						<span className="nav-text">Cards</span>
						<NavLink to={`/application/${applicationId}/cards`} />
					</Menu.Item>
					<Menu.Item key="card_payments">
						<span className="nav-text">Card Payments</span>
						<NavLink to={`/application/${applicationId}/card_payments`} />
					</Menu.Item>
					{process.env.REACT_APP_ENV === 'sandbox' && (
						<Menu.Item key="card_payments_simulations">
							<span className="nav-text">Simulations</span>
							<NavLink to={`/application/${applicationId}/card_payments_simulations`} />
						</Menu.Item>
					)}
				</SubMenu>
				<Menu.Item key="KYCReports">
					<Icon component={() => MenuIcon({ type: 'KYC' })} />
					<span className="nav-text">KYC Reports</span>
					<NavLink to={`/application/${applicationId}/KYCReports`} />
				</Menu.Item>
				<Menu.Item key="KYTReports">
					<Icon component={() => MenuIcon({ type: 'InternalTransaction' })} />
					<span className="nav-text">KYT Reports</span>
					<NavLink to={`/application/${applicationId}/KYTReports`} />
				</Menu.Item>
				<Menu.Item key="system">
					<Icon component={() => MenuIcon({ type: 'Settings' })} />
					<span className="nav-text">System</span>
					<NavLink to={`/application/${applicationId}/system`} />
				</Menu.Item>
				{/* <Menu.Item key="intercom" className="menu-item" id="custom_lancher">
					<Icon name="SingleChat" colorName="primary" />
					<span className="nav-text">Contact Support</span>
				</Menu.Item> */}
				<Menu.Item key="logout" className="last-item">
					<Icon component={() => MenuIcon({ type: 'Logout' })} />
					<span className="nav-text">Log out</span>
					<NavLink exact to="/logout" />
				</Menu.Item>
			</Menu>
			<Button
				className={collapsed ? 'trigger collapsed' : 'trigger'}
				onClick={setCollapsed}
			>
				<i className="material-icons">{collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}</i>
			</Button>
		</Sider>
	);
}

const mapStateToProps = (state: State) => ({
	collapsed: state.ui.menuCollapsed,
});

const mapDispatchToProps = {
	setMenuState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
