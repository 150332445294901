// @flow

import React from 'react';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Card from '../../../components/UiElements/Card';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { Feature } from '../../../modules/reducers/FeatureReducer';
//= import styles
import styles from '../assets/features.module.scss';

type Props = {
	feature: Feature,
	handleSelect: Function,
}
const FeatureCard = (props: Props) => {
	const {
		feature, handleSelect,
	} = props;
	return (
		<Card
			bordered={false}
			className={styles.card}
			onClick={() => handleSelect(feature.featureId)}
		>
			<h2 className="capitalize">
				{ActivityHelpers.transformToReadable(feature.featureId)}
			</h2>
			<div className={styles.container}>
				<div className={styles.first}>
					{feature.feature.description}
				</div>
				<div>
					{feature.feature.enabled
						? (
							<div className={styles.last}>
								<p>
									Feature is
									<span className={feature.feature.enabled ? 'green-text' : 'red-text'}>
										{feature.feature.enabled ? ' enabled ' : ' disabled '}
									</span>
									for your application.
								</p>
								<p>
									Feature is
									<span className={feature.enabled ? 'green-text' : 'red-text'}>
										{feature.enabled ? ' enabled ' : ' disabled '}
									</span>
									for your clients.
								</p>
							</div>
						)
						: (
							<CPButton
								ghost
								text="request this feature"
							/>
						)}
				</div>
			</div>
		</Card>
	);
};

export default FeatureCard;
