// @flow

const initialState = {
	kytReports: [],
	kytReportsTotal: 0,
	isFetchingKytReports: false,
	sFetchedKytReports: false,
	kytReportsError: false,
};

export type KytReport = {
	accountId: string,
	address: string,
	amount: string,
	counterparty: {
		id: string,
		name: string,
		slug: string,
		type: string,
		subtype: string,
	},
	createdAt: string,
	id: string,
	instrumentId: string,
	payload: {
		data: {
			riskscore: number,
		}
	},
	paymentId: string,
	providerInstanceId: string,
	status: string,
	transactionId: string,
	user: {
		id: string,
		firstName: string,
		lastName: string,
	};
}

export type KytState = {
	kytReports: Array<KytReport>,
	kytReportsTotal: number,
	isFetchingKytReports: boolean,
	sFetchedKytReports: boolean,
	kytReportsError: boolean | Object,
}

type KytAction = {
	type:
	| 'KYT_REPORTS'
	| 'KYT_REPORTS_FAIL'
	| 'KYT_REPORTS_SUCCESS',
	payload: {
		data: Array<KytReport>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}

export default function kytReducer(
	state: KytState = initialState,
	action: KytAction,
): KytState {
	switch (action.type) {
	case 'KYT_REPORTS':
		return {
			...state,
			isFetchingKytReports: true,
			isFetchedKytReports: false,
			kytReports: [],
		};
	case 'KYT_REPORTS_FAIL':
		return {
			...state,
			isFetchingKytReports: false,
			isFetchedKytReports: false,
			kytReportsError: action.error,
		};
	case 'KYT_REPORTS_SUCCESS':
		return {
			...state,
			isFetchingKytReports: false,
			isFetchedKytReports: true,
			kytReports: action.payload.data,
			kytReportsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	default:
		return state;
	}
}
