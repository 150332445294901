// @flow

import React from 'react';
import moment from 'moment';
//= import components
import Card from '../../../components/UiElements/Card';
import List from '../../../components/UiElements/List';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { DividendsCalendar } from '../../../modules/reducers/DividendsReducer';
import type { StockSplit } from '../../../modules/reducers/StockSplitReducer';

type Props = {
	data: {
		previousDividend: DividendsCalendar,
		nextDividend: DividendsCalendar,
		previousStockSplit: StockSplit,
		nextStockSplit: StockSplit,
	},
}

const UpcomingEvents = (props: Props) => {
	const {
		data: {
			previousDividend, nextDividend, previousStockSplit, nextStockSplit,
		},
	} = props;

	const previousDividendData = previousDividend
		? [
			{
				title: 'payout instrument',
				data: previousDividend.payoutInstrumentId,
			},
			{
				title: 'gross amount',
				data: PriceHelpers.formatAmount(
					previousDividend.grossAmount,
					previousDividend.payoutInstrumentId,
				),
			},
			{
				title: 'tax amount',
				data: PriceHelpers.formatAmount(
					previousDividend.taxAmount,
					previousDividend.payoutInstrumentId,
				),
			},
			{
				title: 'exDividend date',
				data: moment(previousDividend.exDividendDate).format('YYYY-MM-DD'),
			},
			{
				title: 'payout date',
				data: moment(previousDividend.payoutDate).format('YYYY-MM-DD'),
			},
		]
		: [{ title: 'info', data: 'NO PREVIOUS DIVIDEND RECORD' }];

	const previousStockSplitData = previousStockSplit
		? [
			{
				title: 'Record Date',
				data: moment(previousStockSplit.recordDate).format('YYYY-MM-DD'),
			},
			{
				title: 'Ex Date',
				data: moment(previousStockSplit.exDate).format('YYYY-MM-DD'),
			},
		]
		: [{ title: 'info', data: 'NO PREVIOUS STOCK SPLIT RECORD' }];

	const nextDividendData = nextDividend
		? [
			{
				title: 'payoutInstrumentId',
				data: nextDividend.payoutInstrumentId,
			},
			{
				title: 'grossAmount',
				data: PriceHelpers.formatAmount(
					nextDividend.grossAmount,
					nextDividend.payoutInstrumentId,
				),
			},
			{
				title: 'taxAmount',
				data: PriceHelpers.formatAmount(
					nextDividend.taxAmount,
					nextDividend.payoutInstrumentId,
				),
			},
			{
				title: 'exDividendDate',
				data: moment(nextDividend.exDividendDate).format('YYYY-MM-DD'),
			},
			{
				title: 'payoutDate',
				data: moment(nextDividend.payoutDate).format('YYYY-MM-DD'),
			},
		]
		: [{ title: 'info', data: 'NO NEXT DIVIDEND RECORD' }];

	const nextStockSplitData = nextStockSplit
		? [
			{
				title: 'recordDate',
				data: moment(nextStockSplit.recordDate).format('YYYY-MM-DD'),
			},
			{
				title: 'exdDate',
				data: moment(nextStockSplit.exDate).format('YYYY-MM-DD'),
			},
		]
		: [{ title: 'info', data: 'NO NEXT STOCK SPLIT RECORD' }];

	return (
		<Card className="order-component-card" bordered={false}>
			<h2>Upcoming Events</h2>
			<h4>Previous Dividend</h4>
			<List
				itemLayout="horizontal"
				dataSource={previousDividendData}
				renderItem={
					(item) => (
						<List.Item key={item.title} className="item">
							<List.Item.Meta
								description={item.title}
							/>
							<div>
								{item.data}
							</div>
						</List.Item>
					)
				}
			/>
			<h4>Next Dividend</h4>
			<List
				itemLayout="horizontal"
				dataSource={nextDividendData}
				renderItem={
					(item) => (
						<List.Item key={item.title} className="item">
							<List.Item.Meta
								description={item.title}
							/>
							<div>
								{item.data}
							</div>
						</List.Item>
					)
				}
			/>
			<h4>Previous Stock Split</h4>
			<List
				itemLayout="horizontal"
				dataSource={previousStockSplitData}
				renderItem={
					(item) => (
						<List.Item key={item.title} className="item">
							<List.Item.Meta
								description={item.title}
							/>
							<div>
								{item.data}
							</div>
						</List.Item>
					)
				}
			/>
			<h4>Next Stock Split</h4>
			<List
				itemLayout="horizontal"
				dataSource={nextStockSplitData}
				renderItem={
					(item) => (
						<List.Item key={item.title} className="item">
							<List.Item.Meta
								description={item.title}
							/>
							<div>
								{item.data}
							</div>
						</List.Item>
					)
				}
			/>
		</Card>
	);
};

export default UpcomingEvents;
