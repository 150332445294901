// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getSuspendReasons } from '../../../modules/actions/UsersActions';
// = import types
import type { SuspendReason } from '../../../modules/reducers/UsersReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Value = {
	suspendReasons: Array<string>,
	note: ?string,
}

type Props = {
	form: *,
	submitChanges: (Value) => void,
	handleCancelClick: () => void,
	applicationId: string,
	suspendReasons: Array<SuspendReason>,
	type: string,
	getSuspendReasons: (string) => void,
}
type LocalState = {
	errorMessage: string,
}

class SuspendUser extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	componentDidMount() {
		const {
			getSuspendReasons: getSuspendReasonsAction,
			suspendReasons,
			applicationId,
		} = this.props;
		if (!suspendReasons.length) {
			getSuspendReasonsAction(applicationId);
		}
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Value) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					suspendReasons: values.suspendReasons,
					note: values.note || undefined,
				};
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			suspendReasons,
			type,
		} = this.props;
		const {
			errorMessage,
		} = this.state;

		const { Option } = Select;
		const { TextArea } = Input;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<>
				{type === 'reactivate' && (
					<div className={styles.noProvider}>
						<p>
							You are about to reactivate a suspended client.
						</p>
					</div>
				)}
				<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
					{type === 'suspend' && (suspendReasons && suspendReasons.length
						?	(
							<>
								<FormItem label="SUSPEND REASONS" help="" {...formItemLayout}>
									{getFieldDecorator('suspendReasons', {
										rules: [{ required: true, message: "Suspend Reasons can't be left empty" }],
									})(
										<Select
											allowClear
											showSearch
											placeholder="Select Suspend Reason"
											mode="multiple"
											optionFilterProp="children"
											filterOption={(input, option) => option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
											{suspendReasons.map((reason) => (
												<Option
													value={reason.id}
													key={reason.id}
													className={styles.options}
												>
													{reason.title}
												</Option>
											))}
										</Select>,
									)}
								</FormItem>
								<FormItem label="SUSPEND NOTE" {...formItemLayout} help="">
									{getFieldDecorator('note')(
										<TextArea
											maxLength={1000}
											placeholder="Enter a suspend note (limit 1000)"
											rows="10"
										/>,
									)}
								</FormItem>
								{errorMessage && (
									<ErrorMessage message={errorMessage} />
								)}
							</>
						)
						: (
							<div className={styles.noProvider}>
								<p>
									You are currently unable to perform the suspend client action,
									as there is no Suspend Reasons for this application.
									Please create Suspend Reasons for your application.
								</p>
							</div>
						))}
					<div className="form-buttons">
						<FormItem>
							<CPButton ghost action={handleCancelClick} text="cancel" />
						</FormItem>
						<FormItem>
							<CPButton type="primary" action={this.submit} disabled={type === 'suspend' && !isFieldsTouched()} text={`${type} client`} />
						</FormItem>
					</div>
				</Form>
			</>
		);
	}
}

const SuspendUserForm = Form.create()(SuspendUser);

const mapStateToProps = (state: State) => ({
	suspendReasons: state.users.suspendReasons,
});
const mapDispatchToProps = {
	getSuspendReasons,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuspendUserForm);
