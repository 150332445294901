import React from 'react';
import Header from '../Header';
import TopBarLayout from './components/TopBarLayout';

const Topbar = (props) => (
	<Header className="topHeader">
		<TopBarLayout data={props} />
	</Header>
);

export default Topbar;
