// @flow

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Row from '../../../components/UiElements/Layout/Row';
import Popover from '../../../components/UiElements/Popover';
import Icon from '../../../components/UiElements/Icon';
import Spin from '../../../components/UiElements/Spin';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
// = import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Values = {
	providerInstanceId: string,
	instrumentId: string,
	percentageFee?: string,
	priority: string,
	orderType: string,
	orderSide: string,
	minimumFee?: string,
	maximumFee?: string,
	netFee?: string,
	symbolId?: string,
}
type Props = {
	form: *,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	applicationId: string,
	activeTab: string,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	getAppSymbols: (string, string, number, string) => Promise<Object>,
	providers: Array<ProviderInstance>,
}

type LocalState = {
	errorMessage: string,
	selectedInstrument: boolean,
	instrument: Instrument,
	symbolSelected: boolean,
	instrumentData: Array<Instrument>,
	instrumentFetching: boolean,
	symbolData: Array<Instrument>,
	symbolFetching: boolean,
	notFoundContent: string,
}

class FeeForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		selectedInstrument: false,
		instrument: {},
		symbolSelected: false,
		instrumentData: [],
		instrumentFetching: false,
		symbolData: [],
		symbolFetching: false,
		notFoundContent: '',
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			selectedInstrument: false,
			instrument: {},
		});
	}

	handleInstrumentSearch = debounce((instrumentValue: string) => {
		const { getAppInstruments: getAppInstrumentsAction, applicationId } = this.props;
		if (instrumentValue && instrumentValue.match(StatusHelpers.validInstrument)) {
			this.setState({ instrumentFetching: true });
			const idsFilter = instrumentValue?.toUpperCase();
			const filterOptions: string = queryString.stringify({ ids: idsFilter });
			getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							instrumentData: data,
							instrumentFetching: false,
						});
					} else {
						this.setState({
							instrumentData: [],
							instrumentFetching: false,
							notFoundContent: `Instrument with id ${instrumentValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						instrumentFetching: false,
						instrumentData: [],
						notFoundContent: 'Instrument not found',
					});
				});
		} else {
			this.setState({
				instrumentFetching: false,
				instrumentData: [],
				notFoundContent: 'Enter valid Id to find the Instrument',
			});
		}
	}, 500);

	handleSymbolSearch = debounce((symbolValue: string) => {
		const { getAppSymbols: getAppSymbolsAction, applicationId } = this.props;
		this.setState({ symbolFetching: true });
		if (symbolValue) {
			const filterOptions: string = queryString.stringify({ search: symbolValue });
			getAppSymbolsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							symbolData: data,
							symbolFetching: false,
						});
					} else {
						this.setState({
							symbolData: [],
							symbolFetching: false,
							notFoundContent: `Symbol with id ${symbolValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						symbolFetching: false,
						symbolData: [],
						notFoundContent: 'Symbol not found',
					});
				});
		} else {
			this.setState({
				symbolFetching: false,
				symbolData: [],
				notFoundContent: 'Enter Id to find the Symbol',
			});
		}
	}, 500);

	selectInstrument = (value: string) => {
		const { instrumentData } = this.state;
		if (value) {
			const selected = instrumentData.filter(
				(instrument) => instrument.instrumentId === value,
			)[0];
			this.setState({
				selectedInstrument: true,
				instrument: selected,
			});
		} else {
			this.setState({
				selectedInstrument: false,
				instrument: {},
				instrumentData: [],
			});
		}
	}

	selectSymbol = (value: string) => {
		if (value) {
			this.setState({
				symbolSelected: true,
			});
		} else {
			this.setState({
				symbolSelected: false,
				symbolData: [],
			});
		}
	}

	validateNetFee = (rule, value, callback) => {
		const { form } = this.props;
		const { instrument: { precision } } = this.state;
		const netFee = form.getFieldValue('netFee');
		const percentageFee = form.getFieldValue('percentageFee');
		if (!netFee && !percentageFee) {
			callback('The Fee rule must contain at least one value: Net Fee or Percentage Fee');
		}
		if (value) {
			if (parseFloat(value.toFixed(precision)) !== value) {
				callback(`Value must have precision less than or equal to ${precision}`);
			}
		}
		callback();
	}

	validatePercentageFee = (rule, value, callback) => {
		const { form } = this.props;
		const netFee = form.getFieldValue('netFee');
		const percentageFee = form.getFieldValue('percentageFee');
		if (value) {
			if (value > 100) {
				callback('Value has to be between 0 and 100 (%)!');
			}
			if (parseFloat(value.toFixed(2)) !== value) {
				callback('Value must have precision less than or equal to 2');
			}
		}
		if (!netFee && !percentageFee) {
			callback('The Fee rule must contain at least one value: Net Fee or Percentage Fee');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data: Values = this.state.selectedInstrument
					? {
						...values,
						minimumFee: form.isFieldTouched('minimumFee') && values.minimumFee
							? values.minimumFee.toString()
							: undefined,
						maximumFee: form.isFieldTouched('maximumFee') && values.maximumFee
							? values.maximumFee.toString()
							: undefined,
						netFee: form.isFieldTouched('netFee') && values.netFee
							? values.netFee.toString()
							: undefined,
						percentageFee: form.isFieldTouched('percentageFee') && values.percentageFee
							? values.percentageFee.toString()
							: undefined,
						priority: '1',
					}
					: {
						percentageFee: form.isFieldTouched('percentageFee') && values.percentageFee
							? values.percentageFee.toString()
							: undefined,
						priority: '1',
						providerInstanceId: values.providerInstanceId,
						orderType: values.orderType,
						orderSide: values.orderSide,
						symbolId: values.symbolId,
						instrumentId: values.instrumentId,
					};
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			activeTab,
			providers,
		} = this.props;
		const {
			errorMessage,
			selectedInstrument,
			instrument,
			symbolSelected,
			instrumentData,
			instrumentFetching,
			symbolData,
			symbolFetching,
			notFoundContent,
		} = this.state;
		const { Option } = Select;
		const FormItem = Form.Item;
		const feeLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const numberLayout = {
			labelCol: {
				xs: { span: 11 },
			},
			wrapperCol: {
				xs: { span: 13 },
			},
		};
		const paymentProviders = providers.filter((el) => el.provider.type === 'payment');
		const tradingProvider = providers.filter((el) => el.provider.type === 'market');
		const subtitle1 = `${activeTab} Fee Parameters`;
		const subtitle2 = `${activeTab} Fee Rules`;
		const subtitle3 = `${activeTab} Fee Rule Rank`;
		const parameterRule = activeTab === 'trading'
			? "If you don't select any Liquidity Provider, Order Type or Order Size, the rule will apply as if All is selected. If you don't select any Symbol, the rule will apply to all Symbols for the selected Instrument."
			: "If you don't select any Parameter, the rule will apply as if All is selected.";
		const rankRule = `This newly created ${activeTab} Fee Rule will have the highest Rank. If you wont to change rank use drag and drop on Fee Rule Table.`;

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{activeTab !== 'cardFx' && (
					<>
						<h4 className="subtitle capitalize">
							{subtitle1}
						</h4>
						<p>{parameterRule}</p>
						{activeTab === 'trading'
							? (
								<div>
									<FormItem label="LIQUIDITY PROVIDER" {...feeLayout}>
										{getFieldDecorator('providerInstanceId')(
											<Select
												allowClear
												showSearch
												placeholder="All Liquidity Providers"
											>
												{tradingProvider.map((provider) => (
													<Option
														value={provider.id}
														key={provider.id}
													>
														{provider.provider.displayName}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="INSTRUMENT" {...numberLayout}>
										<div className="fee-info">
											<Popover
												content="Fee is set on a Symbol level. Selecting an Instrument will apply fee rule to all Symbols for selected instrument."
												overlayClassName="button-popover"
											>
												<div className="info-icon">
													<Icon name="Info" />
												</div>
											</Popover>
											{getFieldDecorator('instrumentId')(
												<Select
													allowClear
													showSearch
													placeholder={symbolSelected ? "Symbol's Quoting Instrument" : 'All Instruments'}
													disabled={symbolSelected}
													notFoundContent={instrumentFetching
														? <Spin size={16} />
														: notFoundContent}
													filterOption={false}
													onSearch={this.handleInstrumentSearch}
													onChange={this.selectInstrument}
												>
													{instrumentData.map((el) => (
														<Option
															value={el.instrumentId}
															key={el.instrumentId}
														>
															{el.instrumentId}
														</Option>
													))}
												</Select>,
											)}
										</div>
									</FormItem>
									<FormItem label="SYMBOL" {...feeLayout}>
										{getFieldDecorator('symbolId')(
											<Select
												allowClear
												showSearch
												placeholder="All Symbols"
												disabled={selectedInstrument}
												notFoundContent={symbolFetching ? <Spin size={16} /> : notFoundContent}
												filterOption={false}
												onSearch={this.handleSymbolSearch}
												onChange={this.selectSymbol}
											>
												{symbolData.map((el) => (
													<Option
														value={el.id}
														key={el.id}
													>
														{el.id}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="ORDER TYPE" {...feeLayout}>
										{getFieldDecorator('orderType')(
											<Select
												allowClear
												showSearch
												placeholder="All Order Types"
											>
												{StatusHelpers.orderTypes.map((el) => (
													<Option value={el.id} key={el.id}>
														{el.name}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="ORDER SIDE" {...feeLayout}>
										{getFieldDecorator('orderSide')(
											<Select
												allowClear
												showSearch
												placeholder="All Order Sides"
											>
												<Option
													value="buy"
													key="buy"
												>
													Buy
												</Option>
												<Option
													value="sell"
													key="sell"
												>
													Sell
												</Option>
											</Select>,
										)}
									</FormItem>
								</div>
							)
							: (
								<div className="fee-field-right">
									<FormItem label="PAYMENT PROVIDER" {...feeLayout}>
										{getFieldDecorator('providerInstanceId')(
											<Select
												allowClear
												showSearch
												placeholder="All Payment Providers"
											>
												{paymentProviders.map((provider) => (
													<Option
														value={provider.id}
														key={provider.id}
													>
														{provider.provider.displayName}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="INSTRUMENT" {...feeLayout}>
										{getFieldDecorator('instrumentId')(
											<Select
												allowClear
												showSearch
												placeholder="All Instruments"
												notFoundContent={instrumentFetching ? <Spin size={16} /> : notFoundContent}
												filterOption={false}
												onSearch={this.handleInstrumentSearch}
												onChange={this.selectInstrument}
											>
												{instrumentData.map((el) => (
													<Option
														value={el.instrumentId}
														key={el.instrumentId}
													>
														{el.instrumentId}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
								</div>
							)}
					</>
				)}
				<h4 className="subtitle capitalize">
					{subtitle2}
				</h4>
				{activeTab === 'trading' || activeTab === 'cardFx'
					? (

						<FormItem label="PERCENTAGE FEE" {...feeLayout} className="form-item-small">
							{getFieldDecorator('percentageFee', {
								initialValue: 0,
								rules: [{ validator: this.validatePercentageFee }],
							})(
								<InputNumber
									max={100}
									symbol="%"
									placeholder="Set Percentage Fee"
								/>,
							)}
						</FormItem>
					)
					: (
						<Row>
							<FormItem label="PERCENTAGE FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('percentageFee', {
									initialValue: 0,
									rules: [{ validator: this.validatePercentageFee }],
								})(
									<InputNumber
										max={100}
										symbol="%"
										placeholder="Set Percentage Fee"
									/>,
								)}
							</FormItem>
							<FormItem label="MINIMUM FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('minimumFee', {
									initialValue: 0,
									rules: [{ validator: this.validateNetFee }],
								})(
									<InputNumber
										disabled={!selectedInstrument}
										symbol={instrument.instrumentId}
										placeholder="Set Minimum Fee"
									/>,
								)}
							</FormItem>
							<FormItem label="NET FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('netFee', {
									initialValue: 0,
									rules: [{ validator: this.validateNetFee }],
								})(
									<InputNumber
										disabled={!selectedInstrument}
										symbol={instrument.instrumentId}
										placeholder="Set Net Fee"
									/>,
								)}
							</FormItem>
							<FormItem label="MAXIMUM FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('maximumFee', {
									initialValue: 0,
									rules: [{ validator: this.validateNetFee }],
								})(
									<InputNumber
										disabled={!selectedInstrument}
										symbol={instrument.instrumentId}
										placeholder="Set Maximum Fee"
									/>,
								)}
							</FormItem>
						</Row>
					)}
				<h4 className="subtitle capitalize">
					{subtitle3}
				</h4>
				<p>{rankRule}</p>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} text="create fee" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const AddFeeForm = Form.create()(FeeForm);

const mapDispatchToProps = {
	getAppInstruments,
	getAppSymbols,
};

export default connect(null, mapDispatchToProps)(AddFeeForm);
