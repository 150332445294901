// @flow

import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//= import components
import Title from '../../components/UiElements/Title';
import CustomTable from '../../components/UiElements/CustomTable';
import Spin from '../../components/UiElements/Spin';
import Tag from '../../components/UiElements/Tag';
import CopyComponent from '../../components/UiElements/CopyComponent';
//= import methods
import { getKycReports } from '../../modules/actions/UsersActions';
import { getProvidersInstances, getProviders } from '../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../lib/helpers/statusHelpers';
import ActivityHelpers from '../../lib/helpers/activityHelpers';
//= import types
import type { KycReport } from '../../modules/reducers/UsersReducer';
import type { ProviderInstance, Provider } from '../../modules/reducers/ProviderReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	kycReports: Array<KycReport>,
	isFetchingKycReports: boolean,
	kycReportsTotal: number,
	getKycReports: (applicationId: string, page: number, queryParams: string, pageSize?: number) => void,
	getProvidersInstances: (string) => void,
	getProviders: () => void,
	pageSize: number,
	providerInstance: Array<ProviderInstance>,
	kycReportsPage: number,
	kycReportsFilters: { [string]: string },
	kytReportsFilters: { [string]: string },
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	providers: Array<Provider>,
	setCurrentPage: (string, number) => void,
}

function KYCReports(props: Props) {
	const {
		getKycReports: getKycReportsAction,
		setCurrentPage: setCurrentPageAction,
		getProvidersInstances: getProvidersInstancesAction,
		kycReports,
		isFetchingKycReports,
		kycReportsTotal,
		providerInstance,
		kycReportsFilters,
		kycReportsPage,
		getProviders: getProvidersAction,
		providers,
		pageSize,
		match: { params: { applicationId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		getProvidersAction();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getKycReportsAction(applicationId, kycReportsPage, queryString.stringify(kycReportsFilters), pageSize);
	}, [kycReportsFilters, kycReportsPage, getKycReportsAction, applicationId, pageSize]);

	const handleKycPageChange = (page: number) => {
		setCurrentPageAction('kycPage', page);
	};

	const filterRef = useRef(null);
	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [kycReportsFilters]);

	const getProviderName = (id) => {
		const instance = providerInstance?.find((el) => el.id === id);
		return instance ? instance.provider.displayName : id;
	};

	const getProvidersListForFiltering = () => providers.filter((provider) => provider.type === 'kyc').map((kycProvider) => {
		const obj = {
			id: kycProvider.name,
			name: kycProvider.displayName,
		};
		return obj;
	});

	const getUserName = (res) => {
		if (res.reportType) {
			if (res.reportType === 'pepsAndSanctions') {
				return res.reportData.content ? res.reportData.content.data?.search_term : res.userId;
			}
			return res.reportData.properties
				? `${res.reportData.properties.first_name || res.userId} ${res.reportData.properties.last_name || ''}`
				: `${res.reportData.rawRequest?.first_name || res.userId} ${res.reportData.rawRequest?.last_name || ''}`;
		}
		return res.userId;
	};

	const columns = [
		{
			title: 'created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => (
				<div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
			),
		},
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 332,
			render: (text: string, record: KycReport) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/clients/${record.userId}?userTab=kycAndDocumentation`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'report type',
			dataIndex: 'reportType',
			key: 'reportType',
			width: 160,
			render: (text: string) => (
				<span className="capitalize">
					{ActivityHelpers.transformToReadable(text)}
				</span>
			),
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 144,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
		{
			title: 'result',
			dataIndex: 'reportData',
			key: 'result',
			width: 160,
			render: (data: Object, record: KycReport) => (
				<div>
					{record.status === 'completed'
						? (
							<Tag
								status={record.reportType === 'pepsAndSanctions'
									? data?.content.data.match_status
									: data?.result || data?.rawResponse?.result}
							/>
						)
						: '-'}
				</div>
			),
		},
		{
			title: 'client',
			dataIndex: 'userId',
			key: 'userId',
			width: 320,
			render: (user: string, record: KycReport) => (
				<div>
					<Link to={`/application/${applicationId}/clients/${record.userId}`}>
						{getUserName(record)}
					</Link>
				</div>
			),
		},
		{
			title: 'provider',
			dataIndex: 'providerInstanceId',
			key: 'providerInstanceId',
			width: 320,
			render: (data: string) => (
				<div>
					{getProviderName(data)}
				</div>
			),
		},
	];

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="KYC Reports"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="kyc"
					fields={{
						dateRange: 'time',
						status: StatusHelpers.kycReportsStatuses,
						reportType: StatusHelpers.kycReportsType,
						providerName: getProvidersListForFiltering(),
						result: StatusHelpers.kycReportsResultType,
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
					filterMode="single"
				/>
			</div>
			<div className="container">
				{!isFetchingKycReports
					? (
						<CustomTable
							columns={columns}
							data={kycReports}
							total={kycReportsTotal}
							handlePageChange={handleKycPageChange}
							currentPage={kycReportsPage}
							headerHeight={filterHeight}
							numberOnPage={pageSize}
							place="kycReports"
							applicationId={applicationId}
						/>
					) : <Spin spinning={isFetchingKycReports} />}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	kycReports: state.users.kycReports,
	kycReportsTotal: state.users.kycReportsTotal,
	isFetchingKycReports: state.users.isFetchingKycReports,
	providerInstance: state.providers.providersInstances,
	kycReportsPage: state.ui.kycPage,
	kycReportsFilters: state.ui.kycFilters,
	providers: state.providers.providers,
	pageSize: state.ui.kycReportsPageSize,
});

const mapDispatchToProps = {
	getKycReports,
	setCurrentPage,
	getProvidersInstances,
	getProviders,
};

export default connect(mapStateToProps, mapDispatchToProps)(KYCReports);
