// @flow

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
// = import types
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Values = {
	walletId: string,
	providerName: string,
	name: string,
	description: string,
}

type Props = {
	form: *,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	wallets: Array<Wallet>,
	providers: Array<ProviderInstance>,
	account: Account,
	getProvidersInstances: (string) => void,
	applicationId: string,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	fiatInstruments: Array<Instrument>,
}
type LocalState = {
	errorMessage: string,
	instrumentId: string,
}

class CreateBankAccount extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		instrumentId: '',
	}

	componentDidMount() {
		const {
			getProvidersInstances: getProvidersInstancesAction,
			getAppInstruments: getAppInstrumentsAction,
			applicationId,
			providers,
		} = this.props;
		if (!providers.length) {
			getProvidersInstancesAction(applicationId);
		}
		const filterOptions: string = queryString.stringify({ type: 'fiat' });
		getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			instrumentId: '',
		});
	}

	selectInstrument = (value) => {
		const { setFieldsValue } = this.props.form;
		this.setState({
			instrumentId: value,
		});
		setFieldsValue({ walletId: '' });
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					walletId: values.walletId,
					providerName: values.providerName,
					name: values.name,
					description: values.description,
				};
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldTouched },
			handleCancelClick,
			wallets,
			providers,
			account,
			fiatInstruments,
		} = this.props;
		const {
			errorMessage,
			instrumentId,
		} = this.state;

		const bankProviders = providers.filter((el) => el.provider.name === 'modulr').map((el) => ({
			id: el.provider.name,
			name: el.provider.name,
			displayName: el.provider.displayName,
		}));

		const fiatWallet = wallets.filter((el) => el.instrumentId === instrumentId);

		const { Option } = Select;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};

		return (
			<>
				{bankProviders.length
					? (
						<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
							<FormItem label="NAME" {...formItemLayout}>
								{getFieldDecorator('name', {
									rules: [{
										required: true, message: 'Please Enter Bank Account Name',
									}],
								})(
									<Input
										name="name"
										placeholder="The Name of the Client Bank Account "
										maxLength={50}
									/>,
								)}
							</FormItem>
							<FormItem label="DESCRIPTION" {...formItemLayout}>
								{getFieldDecorator('description', {
									rules: [{
										required: true, message: 'Please Enter Description',
									}],
								})(
									<Input
										placeholder="Enter Description"
										maxLength={50}
									/>,
								)}
							</FormItem>
							<FormItem label="PROVIDER" {...formItemLayout}>
								{getFieldDecorator('providerName', {
									initialValue: bankProviders[0].name,
									rules: [{ required: true, message: 'Please Select Provider' }],
								})(
									<Select
										showSearch
										placeholder="Select Provider"
									>
										{bankProviders.map((el) => (
											<Option
												value={el.name}
												key={el.name}
												className={styles.options}
											>
												{el.displayName}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="ACCOUNT" {...formItemLayout}>
								{getFieldDecorator('account', {
									initialValue: `${account.name}, id: ${account.id}`,
								})(
									<Input
										placeholder="Account "
										disabled
									/>,
								)}
							</FormItem>
							<FormItem label="INSTRUMENT" {...formItemLayout}>
								{getFieldDecorator(
									'instrumentId', {
										rules: [
											{ required: true, message: "Instrument can't be left empty" },
										],
									},
								)(
									<Select
										showSearch
										placeholder="Select Instrument"
										onChange={this.selectInstrument}
									>
										{fiatInstruments.map((instrument) => (
											<Option value={instrument.instrumentId} key={instrument.id}>
												{instrument.name}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="WALLET" {...formItemLayout}>
								{getFieldDecorator('walletId', {
									rules: [{ required: true, message: "Wallet can't be left empty" }],
								})(
									fiatWallet.length
										? (
											<Select
												showSearch
												placeholder="Select Wallet"
											>
												{fiatWallet.map((el) => (
													<Option
														value={el.id}
														key={el.id}
														className={styles.options}
													>
														{`${el.instrumentId} Wallet` }
													</Option>
												))}
											</Select>
										) : (
											<div>
												Client has no wallet with this instruments,
												please create wallet for clients and try again or choose another instrument.
											</div>
										),
								)}
							</FormItem>
							{errorMessage && (
								<ErrorMessage message={errorMessage} />
							)}
							<div className="form-buttons">
								<FormItem>
									<CPButton ghost action={handleCancelClick} text="cancel" />
								</FormItem>
								<FormItem>
									<CPButton type="primary" action={this.submit} disabled={!isFieldTouched('walletId')} text="create bank account" />
								</FormItem>
							</div>
						</Form>
					)
					: (
						<div className={styles.noProvider}>
							<h4>
								You are currently unable to create Bank Account for this user,
								as there is no Bank Provider for this application.
								Please add Bank Provider Instance for your application.
							</h4>
						</div>
					)}
			</>
		);
	}
}

const CreateBankAccountForm = Form.create()(CreateBankAccount);

const mapStateToProps = (state: State) => ({
	providers: state.providers.providersInstances,
	fiatInstruments: state.instrument.instruments,
});
const mapDispatchToProps = {
	getProvidersInstances,
	getAppInstruments,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBankAccountForm);
