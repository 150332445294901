// @flow
class StatusHelpers {
	static statusColor(param: string) {
		switch (param) {
		case 'waitlisted':
		case 'requested':
		case 'new':
		case 'pending':
		case 'Pending':
		case 'rejectedPending':
		case 'pendingNew':
		case 'Suspended':
		case 'review':
		case 'GBP':
			return '#F5A623';
		case 'incomplete':
		case 'partiallyFilled':
		case 'crypto':
		case 'PartiallyAuthorized':
		case 'unsuitable':
			return '#F26F66';
		case 'complete':
		case 'completed':
		case 'stock':
		case 'payout':
		case 'Refunded':
		case 'PartiallyRefunded':
		case 'payOut':
		case 'Lost':
		case 'skipped':
		case 'suitable':
			return '#4ABCE2';
		case 'open':
			return '#2B77D0';
		case 'potential_match':
		case 'Risk':
		case 'risky':
		case 'consider':
			return '#E66E18';
		case 'Expired':
		case 'cancelled':
		case 'undelivered':
		case 'reversed':
		case 'partiallyReversed':
		case 'Reversed':
		case 'PartiallyReversed':
		case 'pendingCancel':
			return '#9B9B9B';
		case 'fiat':
		case 'awaiting_approval':
		case 'USD':
			return '#26CEA2';
		case 'rejected':
		case 'Rejected':
		case 'rejectedFailed':
		case 'manuallyRejected':
		case 'failedToExecute':
		case 'failed':
		case 'closeOnly':
		case 'delisted':
		case 'Blocked':
			return '#D0021B';
		case undefined:
			return '#A7A8A7';
		case 'verified':
		case 'executed':
		case 'success':
		case 'active':
		case 'collection':
		case 'Settled':
		case 'Activated':
		case 'approved':
		case 'systemApproved':
		case 'EUR':
			return '#67B81E';
		case 'inactive':
		case 'marginEnabledInactive':
		case 'NotActivated':
			return '#B5B7BA';
		case 'suspended':
		case 'Stolen':
			return '#BE3665';
		case 'primary':
		case 'default':
		case 'marginEnabled':
		case 'collateral':
		case 'fractionalTrading':
		case 'recurring':
		case 'postTradeSettlementEnabled':
		case 'authorized':
		case 'Authorized':
		case 'PreAuthorized':
			return '#416A9D';
		case 'clear':
		case 'no_match':
		case 'delivered':
			return '#6EBA1A';
		case 'Fraud':
			return '#e5293c';
		case null:
			return 'blue';
		case 'utility_bill':
		case 'bank_statement':
		case 'credit_statement':
		case 'permanent_residency_card':
		case 'property_tax_receipt':
		case 'driving_license':
		case 'insurance_card':
		default:
			return '#6EBA1A';
		}
	}

	static statuses = [
		{ id: 'waitlisted', name: 'waitlisted' },
		{ id: 'incomplete', name: 'incomplete' },
		{ id: 'complete', name: 'complete' },
		{ id: 'rejected', name: 'rejected' },
		{ id: 'verified', name: 'verified' },
	]

	static riskLevels = [
		{ id: null, name: 'Not Set' },
		{ id: 'low', name: 'Low' },
		{ id: 'medium', name: 'Medium' },
		{ id: 'high', name: 'High' },
	]

	static riskLevelsFilterOptions = [
		{ id: 'null', name: 'Not Set' },
		{ id: 'low', name: 'Low' },
		{ id: 'medium', name: 'Medium' },
		{ id: 'high', name: 'High' },
	]

	static suspendedStatuses = [
		{ id: true, name: 'Yes' },
		{ id: false, name: 'No' },
	]

	static kycReportsStatuses = [
		{ id: 'completed', name: 'Completed' },
		{ id: 'pending', name: 'Pending' },
		{ id: 'failed', name: 'Failed' },
	]

	static kycReportsResultType = [
		{ id: 'clear', name: 'Clear' },
		{ id: 'consider', name: 'Consider' },
		{ id: 'potential_match', name: 'PEPs: Potential match' },
		{ id: 'no_match', name: 'PEPs: No match' },
	]

	static kycReportsType = [
		{ id: 'document', name: 'Document' },
		{ id: 'proofOfAddress', name: 'Proof of address' },
		{ id: 'identity', name: 'identity' },
		{ id: 'facialSimilarity', name: 'Facial similarity' },
		{ id: 'pepsAndSanctions', name: 'PEPs and Sanctions' },
	]

	static kytReportsStatuses = [
		{ id: 'pendingNew', name: 'pendingNew' },
		{ id: 'pending', name: 'pending' },
		{ id: 'passed', name: 'passed' },
		{ id: 'risky', name: 'risky' },
		{ id: 'failed', name: 'failed' },
	]

	static scheduleOptions = ['skipped', 'pendingNew', 'pending', 'review'];

	static complianceReviewStatuses = [
		{ id: 'skipped', name: 'skipped' },
		{ id: 'pendingNew', name: 'pendingNew' },
		{ id: 'pending', name: 'pending' },
		{ id: 'review', name: 'review' },
		{ id: 'systemApproved', name: 'systemApproved' },
		{ id: 'manuallyApproved', name: 'manuallyApproved' },
		{ id: 'systemRejected', name: 'systemRejected' },
		{ id: 'manuallyRejected', name: 'manuallyRejected' },
	]

	static types = ['fiat', 'crypto', 'stock']

	static bankInstruments = [
		{
			id: 'EUR',
			type: 'fiat',
			formatting: { grapheme: '€' },
		},
		{
			id: 'GBP',
			type: 'fiat',
			formatting: { grapheme: '£' },
		},
		{
			id: 'CHF',
			type: 'fiat',
			formatting: { grapheme: 'CHF' },
		},
	]

	static typeColor(type: string) {
		switch (type) {
		case 'fiat':
			return '#26CEA2';
		case 'crypto':
			return '#BD10E0';
		case 'stock':
			return '#4ABCE2';
		default:
			return '#26CEA2';
		}
	}

	static typeName(type: string) {
		switch (type) {
		case 'fiat':
			return 'Fiat';
		case 'crypto':
			return 'Crypto';
		case 'stock':
			return 'Stock';
		default:
			return 'No Type';
		}
	}

	static paymentMethods(method: string) {
		switch (method) {
		case 'manual':
			return 'Manual';
		case 'wire':
			return 'Wire Transfer';
		case 'card':
			return 'Credit Card';
		default:
			return 'Not Depositable';
		}
	}

	static orderTypes = [
		{ id: 'limit', name: 'Limit' },
		{ id: 'market', name: 'Market' },
		{ id: 'stop', name: 'Stop' },
		{ id: 'previouslyQuoted', name: 'Previously Quoted' },
	]

	static orderStatus = [
		{ id: 'pendingNew', name: 'pending new' },
		{ id: 'new', name: 'new' },
		{ id: 'partiallyFilled', name: 'partiallyFilled' },
		{ id: 'filled', name: 'filled' },
		{ id: 'cancelled', name: 'cancelled' },
		{ id: 'failedToExecute', name: 'failedToExecute' },
		{ id: 'rejected', name: 'rejected' },
	];

	static orderSide = [{ id: 'buy', name: 'buy' }, { id: 'sell', name: 'sell' }];

	static orderInputMode = [{ id: 'quantity', name: 'quantity' }, { id: 'amount', name: 'amount' }]

	static accrualPeriods = ['day', 'week', 'month']

	static compundPriods = {
		day: ['day', 'week', 'month'],
		week: ['week', 'month'],
		month: ['month'],
	};

	static checkTypes = {
		all: ['identity', 'document', 'pepsAndSanctions', 'proofOfAddress'],
		onfido: ['identity', 'document', 'facialSimilarity'],
		complyAdvantage: ['pepsAndSanctions'],
		internal: ['proofOfAddress'],
	}

	static checkProviders = {
		identity: 'onfido',
		document: 'onfido',
		facialSimilarity: 'onfido',
		pepsAndSanctions: 'complyAdvantage',
		proofOfAddress: 'internal',
	}

	static feeType = ['deposit', 'withdrawal', 'trading', 'cardFx'];

	static transactionStrategy: Array<string> = ['AllowTransactionTypes', 'BlockTransactionTypes', 'AllowAll'];

	static entryModeStrategy: Array<string> = ['AllowEntryModes', 'BlockEntryModes', 'AllowAll'];

	static merchantCategoryStrategy: Array<string> = ['AllowCategories', 'BlockCategories', 'AllowAll'];

	static merchantStrategy: Array<string> = ['AllowMerchants', 'BlockMerchants', 'AllowAll'];

	static velocity = ['Day', 'Week', 'Month', 'Lifetime', 'Transaction'];

	static payin = {
		GBP: {
			bacs: [{
				name: 'amount',
				isRequired: true,
				type: 'string',
			}, {
				name: 'description',
				isRequired: true,
				type: 'string',
			}, {
				name: 'payerDetail',
				isRequired: true,
				type: 'object',
				properties: [{
					name: 'name',
					isRequired: true,
					type: 'string',
				}, {
					name: 'identifier',
					isRequired: true,
					properties: [{
						name: 'accountNumber',
						isRequired: true,
						type: 'string',
					}, {
						name: 'sortCode',
						isRequired: true,
						type: 'string',
					}, {
						name: 'type',
						isRequired: true,
						type: 'string',
					}],
					type: 'object',
				}, {
					name: 'address',
					isRequired: true,
					properties: [{
						name: 'addressLine1',
						isRequired: true,
						type: 'string',
					}, {
						name: 'addressLine2',
						isRequired: true,
						type: 'string',
					}, {
						name: 'postTown',
						isRequired: true,
						type: 'string',
					}, {
						name: 'postCode',
						isRequired: true,
						type: 'string',
					}, {
						name: 'country',
						isRequired: true,
						type: 'string',
					}],
					type: 'object',
				}],
			},
			],
		},
	}

	static paymentScheme = [
		{ id: 'sepa', name: 'Sepa' },
		{ id: 'ukFaster', name: 'UKFaster' },
		{ id: 'bacs', name: 'Bacs' },
		{ id: 'sic', name: 'Sic' },
		{ id: 'internal', name: 'Internal' }];

	static paymentStatus = [
		{ id: 'executed', name: 'Executed' },
		{ id: 'pending', name: 'Pending' },
		{ id: 'rejected', name: 'Rejected' },
		{ id: 'rejectedPending', name: 'Rejected Pending' },
		{ id: 'rejectedFailed', name: 'Rejected Failed' },
		{ id: 'failed', name: 'failed' },
		{ id: 'unmatched', name: 'Unmatched' },
	];

	static cardStatuses = {
		Activated: ['Blocked', 'Suspended', 'Risk', 'Stolen', 'Lost', 'Expired', 'Fraud'],
		Blocked: [],
		Suspended: ['Activated', 'Blocked', 'Risk', 'Stolen', 'Lost', 'Fraud'],
		Risk: ['Activated', 'Blocked', 'Suspended', 'Stolen', 'Lost', 'Fraud'],
		Stolen: ['Blocked'],
		Lost: ['Activated', 'Blocked', 'Suspended', 'Risk', 'Stolen', 'Fraud'],
		Expired: ['Activated', 'Blocked'],
		NotActivated: ['Blocked'],
		Fraud: ['Activated', 'Blocked', 'Suspended', 'Risk', 'Stolen', 'Lost'],
	}

	static authorizationTypes = [
		{ id: 'normalAuthorization', name: 'Normal Authorization' },
	]

	static scheme = [
		{ id: 'mastercard', name: 'Mastercard' },
	]

	static fixedSides = ['from', 'to'];

	static references = [
		{ id: 'payment', name: 'payment' },
		{ id: 'order', name: 'order' },
		{ id: 'compoundCharge', name: 'compoundCharge' },
		{ id: 'dividendPayout', name: 'dividendPayout' },
		{ id: 'delisting', name: 'delisting' },
		{ id: 'stockSplit', name: 'stockSplit' },
		{ id: 'cardPayment', name: 'cardPayment' },
	]

	static ledgerType = ['reservation', 'transaction', 'fee', 'conversion', 'margin']

	static documentTypes = ['utility_bill', 'bank_statement', 'credit_statement', 'permanent_residency_card', 'property_tax_receipt', 'driving_license', 'insurance_card'];

	static onfidoDocumentTypes = ['national_identity_card', 'driving_licence', 'passport', 'voter_id', 'work_permit', 'residence_permit', 'right_to_work_share_code_result']

	static documentSides = ['front', 'back'];

	static defaultFiatPrecision = 2;

	static paginationLimit = 50;

	static validInstrument = /^[a-zA-Z.=]{1,8}$/;

	static stockSplitStatuses = [
		{ id: 'new', name: 'new' },
		{ id: 'cancellingOrders', name: 'cancelling orders' },
		{ id: 'enqueued', name: 'enqueued' },
		{ id: 'processing', name: 'processing' },
		{ id: 'executed', name: 'executed' },
		{ id: 'failed', name: 'failed' },
	]
}

export default StatusHelpers;
