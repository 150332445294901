// @flow

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Table from '../../../components/UiElements/Table';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	applicationId: string,
	getAppSymbols: (string, string, string, string) => Promise<Object>,
}

type LocalState = {
	selectedKey: Array<string>,
	search: string,
	allSymbols: Array<Symbol>,
	isFetchingSymbols: boolean,
	infiniteScrollLoading: boolean,
}

class AddSymbolToGroup extends React.Component<Props, LocalState> {
	state = {
		selectedKey: [],
		search: '',
		allSymbols: [],
		isFetchingSymbols: false,
		infiniteScrollLoading: false,
	}

	loadingRef = React.createRef();

	componentDidMount() {
		const {
			applicationId,
			getAppSymbols: getAppSymbolsAction,
		} = this.props;
		this.setState({
			isFetchingSymbols: true,
		});
		const { search } = this.state;
		const date = new Date();
		const param = queryString.stringify({ search }, { arrayFormat: 'bracket' });
		getAppSymbolsAction(applicationId, 'infinite-scroll', date.toISOString(), param)
			.then((res) => this.setState({
				allSymbols: res.payload.data,
				isFetchingSymbols: false,
			}));
		const tableContent = document.querySelector('.ant-table-tbody');
		tableContent.addEventListener('scroll', this.handleEvent);
	}

	handleEvent = (e) => {
		const {
			applicationId,
			getAppSymbols: getAppSymbolsAction,
		} = this.props;
		const { allSymbols, search } = this.state;
		const maxScroll = e.target.scrollHeight - e.target.clientHeight;
		const currentScroll = e.target.scrollTop;
		if (currentScroll === maxScroll) {
			this.setState({
				infiniteScrollLoading: true,
			});
			const paginationOffset = allSymbols[allSymbols.length - 1].createdAt;
			const param = queryString.stringify({ search }, { arrayFormat: 'bracket' });
			getAppSymbolsAction(applicationId, 'infinite-scroll', paginationOffset, param)
				.then((res) => this.setState((prevState) => ({
					allSymbols: [...prevState.allSymbols, ...res.payload.data],
					infiniteScrollLoading: false,
				})));
		}
	}

	onSymbolSelect = (key) => {
		this.setState({
			selectedKey: key,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		const { selectedKey } = this.state;
		form.validateFields((err: Array<Object>) => {
			if (!err) {
				submitChanges(selectedKey[0]);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			selectedKey: [],
		});
	}

	handleSearch = (value: string) => {
		const {
			applicationId,
			getAppSymbols: getAppSymbolsAction,
		} = this.props;
		this.setState({
			search: value,
			isFetchingSymbols: true,
		});
		const date = new Date();
		const param = queryString.stringify({ search: value }, { arrayFormat: 'bracket' });
		getAppSymbolsAction(applicationId, 'infinite-scroll', date.toISOString(), param)
			.then((res) => this.setState({
				allSymbols: res.payload.data,
				isFetchingSymbols: false,
			}));
	}

	render() {
		const {
			handleCancelClick,
		} = this.props;
		const {
			isFetchingSymbols,
			selectedKey,
			allSymbols,
			search,
			infiniteScrollLoading,
		} = this.state;

		const columns = [
			{
				title: 'ID',
				dataIndex: 'id',
				key: 'id',
				width: 160,
			},
			{
				title: 'NAME',
				dataIndex: 'displayName',
				key: 'name',
				width: 288,
				render: (text) => (
					<div>{text}</div>
				),
			},
		];
		const rowSelection = {
			onChange: this.onSymbolSelect,
			type: 'radio',
			selectedRowKeys: selectedKey,
		};
		const FormItem = Form.Item;
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<Input.Search
					className="search transfer-search"
					placeholder="Search For Symbols..."
					allowClear
					onSearch={this.handleSearch}
					defaultValue={search}
					onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
				/>
				<div ref={this.loadingRef} onScroll={this.handleEvent} className="scroll-div">
					{!isFetchingSymbols ? (
						<Table
							loading={infiniteScrollLoading}
							rowSelection={rowSelection}
							rowKey={(record) => record.id}
							columns={columns}
							dataSource={allSymbols}
							pagination={false}
							locale={{ emptyText: 'No symbols' }}
							scroll={{ y: (window.innerHeight - 512) }}
						/>
					) : <Spin spinning={isFetchingSymbols} />}
				</div>
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!selectedKey.length} text="add symbol" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const AddSymbolForm = Form.create()(AddSymbolToGroup);

const mapDispatchToProps = {
	getAppSymbols,
};

export default connect(null, mapDispatchToProps)(AddSymbolForm);
