// @flow
import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import InputNumber from '../../../components/UiElements/InputNumber';
import Select from '../../../components/UiElements/Select';
import Input from '../../../components/UiElements/Input';
import Radio from '../../../components/UiElements/Radio';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type {
	Currency, EntryModes, TransactionTypes, MerchantCategoryCodes, LimitControlType,
} from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	form: *,
	submitChanges: (value: Object, value: Object) => void,
	handleCancelClick: () => void,
	currencies: Array<Currency>,
	entryModes: Array<EntryModes>,
	transactionTypes: Array<TransactionTypes>,
	merchantCategoryCodes: Array<MerchantCategoryCodes>,
	limitToUpdate: LimitControlType,
}

type LocalState = {
	errorMessage: string,
}

class LimitControl extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const limitData = {
					name: values.name,
					amountLimit: values.amountLimit,
					usageLimit: values.usageLimit,
					currencyCode: values.currencyCode,
					velocityWindow: values.velocityWindow,
					transactionTypeStrategy: values.transactionTypeStrategy,
					transactionTypeIds: values.transactionTypeIds,
					entryModeStrategy: values.entryModeStrategy,
					entryModeIds: values.entryModeIds,
					isActive: true,
				};
				const merchantData = values.merchantScopeName
					? {
						name: values.merchantScopeName,
						merchantCategoryStrategy: values.merchantCategoryStrategy,
						merchantCategoryCodes: values.merchantCategoryCodes,
						merchantStrategy: values.merchantStrategy,
						merchantIds: form.isFieldTouched('merchantIds') ? values.merchantIds.split(',') : values.merchantIds,
						isActive: true,
					}
					: null;
				submitChanges(limitData, merchantData);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	render() {
		const {
			handleCancelClick,
			form: {
				getFieldDecorator, isFieldsTouched, getFieldValue,
			},
			currencies,
			entryModes,
			transactionTypes,
			merchantCategoryCodes,
			limitToUpdate,
		} = this.props;

		const { errorMessage } = this.state;

		const FormItem = Form.Item;
		const feeLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 14 },
			},
		};

		const controlData = [
			{
				strategy: 'entryModeStrategy',
				strategyValues: StatusHelpers.entryModeStrategy,
				name: 'ENTRY MODES',
				field: 'entryModeIds',
				options: entryModes,
				initialValue: limitToUpdate?.entryModes.map((el) => el.id),
			},
			{
				strategy: 'transactionTypeStrategy',
				strategyValues: StatusHelpers.transactionStrategy,
				name: 'TRANSACTION TYPES',
				field: 'transactionTypeIds',
				options: transactionTypes,
				initialValue: limitToUpdate?.transactionTypes.map((el) => el.id),
			},
		];

		const merchantCategoryStrategyValues = StatusHelpers.merchantCategoryStrategy;

		const merchantStrategyValues = StatusHelpers.merchantStrategy;

		const { velocity } = StatusHelpers;

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelAlign="left">
				<FormItem label="NAME" {...feeLayout}>
					{getFieldDecorator('name', {
						initialValue: limitToUpdate?.name,
						rules: [{
							required: true, message: 'Please Enter Limit Control Name',
						}],
					})(
						<Input
							name="name"
							placeholder="Limit Control Name"
							maxLength={100}
						/>,
					)}
				</FormItem>
				<FormItem label="AMOUNT LIMIT" {...feeLayout}>
					{getFieldDecorator('amountLimit', {
						initialValue: limitToUpdate?.amountLimit,
						rules: [{
							required: true, message: 'Please Set Amount Limit',
						}],
					})(
						<InputNumber
							placeholder="Set Amount Limit"
						/>,
					)}
				</FormItem>
				<FormItem label="LIMIT CURRENCY" {...feeLayout}>
					{getFieldDecorator('currencyCode', {
						initialValue: limitToUpdate?.currency.isoCode,
						rules: [{
							required: true, message: 'Please Select Limit Currency',
						}],
					})(
						<Select
							showSearch
						>
							{currencies.map((value: Currency) => (
								<Select.Option
									value={value.isoCode}
									key={value.id}
									className="capitalize"
								>
									{value.name}
								</Select.Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="USAGE LIMIT" {...feeLayout}>
					{getFieldDecorator('usageLimit', {
						initialValue: limitToUpdate?.usageLimit,
						rules: [{
							required: true, message: 'Please Set Usage Limit',
						}],
					})(
						<InputNumber
							placeholder="Set Usage limit"
							type="number"
						/>,
					)}
				</FormItem>
				<FormItem label="VELOCITY WINDOW" {...feeLayout}>
					{getFieldDecorator('velocityWindow', {
						initialValue: limitToUpdate?.velocityWindow,
						rules: [{
							required: true, message: 'Please Select Velocity Window',
						}],
					})(
						<Select
							showSearch
						>
							{velocity.map((el) => (
								<Select.Option
									value={el}
									key={el}
									className="capitalize"
								>
									{el}
								</Select.Option>
							))}
						</Select>,
					)}
				</FormItem>
				{controlData.map((el) => (
					<div className={styles.limit} key={el.name}>
						<h4>{ActivityHelpers.transformToReadable(el.strategy)}</h4>
						<FormItem>
							{getFieldDecorator(el.strategy, {
								initialValue: limitToUpdate?.[el.strategy],
								rules: [{
									required: true, message: 'Please Select Strategy',
								}],
							})(
								<Radio.Group buttonStyle="solid">
									{el.strategyValues.map((strategyValue) => (
										<Radio.Button
											key={strategyValue}
											value={strategyValue}
										>
											{ActivityHelpers.transformToReadable(strategyValue)}
										</Radio.Button>
									))}
								</Radio.Group>,
							)}
						</FormItem>
						<FormItem label={el.name} {...feeLayout}>
							{getFieldDecorator(el.field, {
								initialValue: el.initialValue,
							})(
								<Select
									showSearch
									allowClear
									style={{ width: '100%' }}
									mode="multiple"
									optionFilterProp="children"
									filterOption={
										(input, data) => data.props.children.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									disabled={getFieldValue(el.strategy) === 'AllowAll'}
								>
									{el.options.map((value) => (
										<Select.Option
											value={value.id || value}
											key={value.id}
											className="capitalize"
										>
											{value.name || value.id || value}
										</Select.Option>
									))}
								</Select>,
							)}
						</FormItem>
					</div>
				))}
				<h4>Merchant Scope</h4>
				<FormItem label="MERCHANT SCOPE NAME" {...feeLayout}>
					{getFieldDecorator('merchantScopeName', {
						initialValue: limitToUpdate?.merchantScope
							? limitToUpdate?.merchantScope.name
							: undefined,
					})(
						<Input
							name="name"
							placeholder="The name is a required field if you want to set Merchant Scope"
							maxLength={100}
						/>,
					)}
				</FormItem>
				<div className={styles.limit}>
					<h4>Merchant Category Strategy</h4>
					<FormItem>
						{getFieldDecorator('merchantCategoryStrategy', {
							initialValue: limitToUpdate?.merchantScope
								? limitToUpdate?.merchantScope.merchantCategoryStrategy
								: undefined,
						})(
							<Radio.Group buttonStyle="solid">
								{merchantCategoryStrategyValues.map((strategyValue) => (
									<Radio.Button
										key={strategyValue}
										value={strategyValue}
									>
										{ActivityHelpers.transformToReadable(strategyValue)}
									</Radio.Button>
								))}
							</Radio.Group>,
						)}
					</FormItem>
					<FormItem label="MERCHANT CATEGORY CODES" {...feeLayout}>
						{getFieldDecorator('merchantCategoryCodes', {
							initialValue: limitToUpdate?.merchantScope
								? limitToUpdate?.merchantScope.merchantCategoryCodes.map((el) => el.code)
								: [],
						})(
							<Select
								showSearch
								allowClear
								mode="multiple"
								disabled={getFieldValue('merchantCategoryStrategy') === 'AllowAll'}
								optionFilterProp="children"
								filterOption={
									(input, data) => data.props.children.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								{merchantCategoryCodes.map((value) => (
									<Select.Option
										value={value.code}
										key={value.code}
										className="capitalize"
									>
										{`${value.code} ${value.description}`}
									</Select.Option>
								))}
							</Select>,
						)}
					</FormItem>
				</div>
				<div className={styles.limit}>
					<h4>Merchant Strategy</h4>
					<FormItem>
						{getFieldDecorator('merchantStrategy', {
							initialValue: limitToUpdate?.merchantScope
								? limitToUpdate?.merchantScope.merchantStrategy
								: undefined,
						})(
							<Radio.Group buttonStyle="solid">
								{merchantStrategyValues.map((strategyValue) => (
									<Radio.Button
										key={strategyValue}
										value={strategyValue}
									>
										{ActivityHelpers.transformToReadable(strategyValue)}
									</Radio.Button>
								))}
							</Radio.Group>,
						)}
					</FormItem>
					<FormItem label="MERCHANT IDS" {...feeLayout}>
						{getFieldDecorator('merchantIds', {
							initialValue: limitToUpdate?.merchantScope
								? limitToUpdate?.merchantScope.merchantIds
								: undefined,
						})(
							<Input
								allowClear
								disabled={getFieldValue('merchantStrategy') === 'AllowAll'}
								placeholder="Enter four-digit codes separated by commas"
							/>,
						)}
					</FormItem>
				</div>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text={limitToUpdate ? 'update limit control' : 'add limit control'} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const LimitControlForm = Form.create()(LimitControl);

export default LimitControlForm;
