// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import componnets
import Spin from '../../../components/UiElements/Spin';
import Card from '../../../components/UiElements/Card';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import CustomTable from '../../../components/UiElements/CustomTable';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import Title from '../../../components/UiElements/Title';
import WebhookForm from '../components/WebhookForm';
import SecretCard from '../components/SecretKey';
//= import methods
import {
	getWebhooks, getEvents, updateWebhook, deleteWebhook,
} from '../../../modules/actions/WebhooksActions';
//= import selectors
import { webhooksMappedSelector } from '../../../modules/selectors/WebhooksSelector';
//= import types
import type { WebhookMapped } from '../../../modules/selectors/WebhooksSelector';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getWebhooks: (string) => Promise<Object>,
	updateWebhook: (string, string, Object) => Promise<Object>,
	deleteWebhook: (applicationId: String, webhookId: string) => Promise<Object>,
	getEvents: () => Promise<Object>,
	webhooks: Array<WebhookMapped>,
	match: {
		params: {
			applicationId: string,
			webhookId: string,
		},
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
}
type LocalState = {
	webhook: WebhookMapped,
	deleteMessage: string,
	deactivateMessage: String,
	footer: Array<Object>,
	deleteClicked: boolean,
	deactivateClicked: boolean,
	editClicked: Boolean,
	btnText: string,
	message: { [string]: Object },
	footer: Array<Object>,
	warningTitle: string,
	warningModal: boolean,
	loading: boolean,
	events: Array<any>,
}

class WebhookPage extends React.Component<Props, LocalState> {
	state = {
		webhook: {},
		deleteMessage: '',
		deactivateMessage: '',
		footer: [],
		deleteClicked: false,
		editClicked: false,
		btnText: '',
		message: {},
		warningTitle: '',
		warningModal: false,
		loading: true,
		events: [],
		deleteClicked: false,
	}

	formRef: ?WebhookForm;

	componentDidMount() {
		const { match: { params: { applicationId, webhookId } } } = this.props;
		this.props.getEvents()
			.then((events) => {
				this.setState({events: events?.payload?.data})
				return this.props.getWebhooks(applicationId);
			})
			.then(() => {
				const webhook = this.props.webhooks.filter((el) => el.id === webhookId)[0];
				this.setState({
					webhook,
					loading: false,
				});
			});
	}

	handleBackClick = () => {
		this.props.history.goBack();
	}

	// Deactivate webhook

	handleDeactivateClick = () => {
		this.setState({
			deactivateClicked: true,
			deactivateMessage: 'After deactivating this endpoint, no future events will be sent to its URL. However, you can reactivate the webhook in the future. Proceed?',
			btnText: 'Deactivate Webhook',
		});
	}

	// Delete webhook

	handleDeleteClick = () => {
		this.setState({
			deleteClicked: true,
			deleteMessage: 'This will delete your webhook and you\'ll no longer be notified for future events. Proceed?',
			btnText: 'Delete Webhook',
		});
	}

	handleEditClick = () => {
		this.setState({
			editClicked: true,
			deleteMessage: 'Edit your webhook',
			btnText: 'Edit Webhook',
		});
	}

	deactivateWebhook = () => {
		const { match: { params: { applicationId, webhookId } } } = this.props;
		this.setState({
			deactivateClicked: false,
		});
		this.props.updateWebhook(applicationId, webhookId, { active: false })
			.then((res) => this.setState({
				webhook: res.payload.data,
			}))
			.catch((error) => this.handleOnError(error));
	}

	deleteWebhook = () => {
		const { match: { params: { applicationId, webhookId } }, deleteWebhook } = this.props;
		this.setState({
			deleteClicked: false,
		});
		deleteWebhook(applicationId, webhookId)
			.then(() => this.props.history.goBack())
			.catch((error) => this.handleOnError(error));
	}

	submitEdit = (formInput) => {
		const { match: { params: { applicationId, webhookId } } } = this.props;
		const { webhook, events } = this.state;
		this.setState({
			editClicked: false,
		});

		let data = {};

		if (webhook.eventName === formInput.eventId) {
			data = { ...formInput, eventId: webhook.eventId, active: true };
		} else {
			data = { ...formInput, active: true };
		}
		this.props.updateWebhook(applicationId, webhookId, data)
			.then((res) => {
				const updatedEventId = res.payload?.data?.eventId;
				const matchingEvent = events.filter((event) => event.id === updatedEventId);
				return this.setState({
					webhook: { ...res.payload.data, eventName: matchingEvent[0].name },
				});
			})
			.catch((error) => this.handleOnError(error));
	}

	reactivateWebhook = () => {
		const { match: { params: { applicationId, webhookId } } } = this.props;
		this.props.updateWebhook(applicationId, webhookId, { active: true })
			.then((res) => this.setState({
				webhook: res.payload.data,
			}))
			.catch((error) => this.handleOnError(error));
	}


	handleDeleteCancel = () => {
		this.setState({
			deleteClicked: false,
			warningModal: false,
		});
	}

	handleDeactivateCancel = () => {
		this.setState({
			deactivateClicked: false,
			warningModal: false,
		});
	}

	handleEditCancel = () => {
		this.setState({
			editClicked: false,
		});
	}

	handleOnError = (error) => {
		this.setState({
			warningModal: true,
			warningTitle: 'Error',
			message: {
				firstPart: error.error.response ? error.error.response.data.message : 'Error',
			},
			footer: [
				{ type: 'cancel', action: this.handleDeleteCancel, text: 'close' },
			],
		});
	}

	activateWebhook = () => {
		const { match: { params: { applicationId, webhookId } } } = this.props;
		this.props.updateWebhook(applicationId, webhookId, { active: true })
			.then((res) => this.setState({
				webhook: res.payload.data,
			}))
			.catch((error) => this.handleOnError(error));
	}

	handleErrorCancel = () => {
		if (this.formRef) {
			this.formRef.handleReset();
		}
		this.setState({
			warningModal: false,
		});
	}


	render() {
		const {
			deleteClicked,
			editClicked,
			btnText,
			deleteMessage,
			deactivateMessage,
			warningModal,
			message,
			footer,
			warningTitle,
			webhook,
			loading,
			events,
			deactivateClicked,
		} = this.state;
		const { match: { params: { applicationId } } } = this.props;
		const columns = [{
			title: 'DELIVERED AT',
			dataIndex: 'deliveredAt',
			key: 'deliveredAt',
			width: 272,
			render: (text: string) => (
				<span className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</span>
			),
		}, {
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 272,
			render: (text: string) => (
				<span className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</span>
			),
		}, {
			title: 'STATUS',
			dataIndex: 'status',
			key: 'status',
			width: 272,
			render: (text: string) => (
				<span className="wrap">
					{text}
				</span>
			),
		}];
		const detailsData = {
			endpoint: webhook.baseUrl,
			event: webhook.eventName,
		};
		return (
			<div>
				{!loading
					? (
						<div className="webhook">
							<Title
								title={webhook.baseUrl}
								tags={[!webhook.active && 'inactive', webhook.active && 'active']}
								buttons={webhook.active
									? [{
										action: this.handleEditClick,
										text: 'edit',
									}, {
										action: this.handleDeactivateClick,
										text: 'deactivate',
									}, {
										action: this.handleDeleteClick,
										text: 'delete',
										type: 'danger',
									}]
									: [{
										action: this.reactivateWebhook,
										text: 'reactivate webhook',
										color: 'red',
									}]}
								backAction={this.handleBackClick}
								place="webhook"
							/>
							<div className="page-container">
								<div className="webhook-cards">
									<TextCard data={detailsData} colNumber={1} left="left-2" title="Webhook Details" />
									<SecretCard secret={webhook.secret} />
								</div>
								<div>
									<Card className="table-card" bordered={false}>
										<div className="flex-container">
											<h2>Webhook Log</h2>
										</div>
										<CustomTable
											columns={columns}
											data={[]}
											total={0}
											currentPage={1}
											headerHeight={170}
											applicationId={applicationId}
											place="singleWebhookLogs"
										/>
									</Card>
								</div>
								<WarningModal
									title={warningTitle}
									visible={warningModal}
									cancelFunction={this.handleDeleteCancel}
									footer={footer}
									message={message}
								/>
								<FormModal
									title="Edit webhook"
									visible={editClicked}
									cancelFunction={this.handleEditCancel}
									form={(
										<WebhookForm
											submitChanges={this.submitEdit}
											handleCancelClick={this.handleEditCancel}
											events={events}
											defaultSecret={webhook.secret}
											defaultBaseUrl={webhook.baseUrl}
											defaultEvent={webhook.eventName}
											editForm
											wrappedComponentRef={editClicked}
										/>
									)}
								/>
								<DeleteModal
									okFunction={this.deleteWebhook}
									cancelFunction={this.handleDeleteCancel}
									visible={deleteClicked}
									btnText={btnText}
									message={deleteMessage}
								/>
								<DeleteModal
									okFunction={this.deactivateWebhook}
									cancelFunction={this.handleDeactivateCancel}
									visible={deactivateClicked}
									btnText={btnText}
									message={deactivateMessage}
								/>
							</div>
						</div>
					)
					: <Spin spinning={loading} />}
			</div>

		);
	}
}

const mapStateToProps = (state: State) => ({
	webhooks: webhooksMappedSelector(state),
	events: state.webhooks.events,
});

const mapDispatchToProps = {
	getWebhooks,
	updateWebhook,
	deleteWebhook,
	getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookPage);
