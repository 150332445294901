// @flow

import React, { useState, useEffect } from 'react';
//= import components
import Checkbox from '../../../components/UiElements/Checkbox';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';

type CountryType = {
	label: string,
	value: string,
}
type Props = {
	title: string,
	button: string,
	buttonAction: (Array<string>) => void,
	data: Array<CountryType>,
	icon: string,
}
function CountriesList(props: Props) {
	const {
		title,
		button,
		buttonAction,
		data,
		icon,
	} = props;

	const [checkedList, setCheckedList] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const CheckboxGroup = Checkbox.Group;

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		const results = data.filter((el) => el.label.toLowerCase().includes(searchValue.toLowerCase()));
		setFilteredData(results);
	}, [data, searchValue]);

	const handleCheck = (list: Array<string>) => {
		setCheckedList(list);
		setCheckAll(list.length === filteredData.length);
	};

	const onCheckAllChange = (e) => {
		const currValues = filteredData.map((el: CountryType) => el.value);
		setCheckedList(e.target.checked ? currValues : []);
		setCheckAll(e.target.checked);
	};

	return (
		<div className="country-check">
			<h2>{title}</h2>
			<Input.Search
				className="country-search"
				placeholder={`Search for ${title}`}
				allowClear
				onChange={handleSearch}
				value={searchValue}
			/>
			<div className="all">
				<Checkbox
					onChange={onCheckAllChange}
					checked={checkAll}
				>
					Check all
				</Checkbox>
				<CPButton
					text={button}
					action={() => buttonAction(checkedList)}
					disabled={!checkedList.length}
					icon={icon}
				/>
			</div>
			<div className="group">
				<CheckboxGroup
					options={filteredData}
					value={checkedList}
					onChange={handleCheck}
				/>
			</div>
		</div>
	);
}

export default CountriesList;
