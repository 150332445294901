// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Spin from '../../../components/UiElements/Spin';
import Filter from '../../../components/UiElements/Filter';
import CPButton from '../../../components/UiElements/Button';
import AccountsList from '../../Banking/components/AccountsList';
//= import actions
import { getAccountBankAccounts } from '../../../modules/actions/BankActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { BankAccountType } from '../../../modules/reducers/BankReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Props = {
	getAccountBankAccounts: (string, number, string) => void,
	setCurrentPage: (string, number) => void,
	accountId: string,
	applicationId: string,
	currentPage: number,
	accountAccountsFilters: { [string]: string },
	accountAccounts: Array<BankAccountType>,
	isFetchingUserAccounts: boolean,
	accountAccountsTotal: number,
	providers: Array<ProviderInstance>,
}

function BankTab(props: Props) {
	const {
		getAccountBankAccounts: getAccountBankAccountsAction,
		setCurrentPage: setCurrentPageAction,
		currentPage,
		accountAccountsFilters,
		accountAccounts,
		isFetchingUserAccounts,
		accountAccountsTotal,
		providers,
		accountId,
		applicationId,
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [date, setDate] = useState(false);

	useEffect(() => {
		getAccountBankAccountsAction(accountId, currentPage, queryString.stringify(accountAccountsFilters, { arrayFormat: 'comma' }));
	}, [accountId, getAccountBankAccountsAction, currentPage, accountAccountsFilters]);

	const handleUserAccountsPageChange = (page: number) => {
		setCurrentPageAction('accountAccountsPage', page);
	};

	const availableProviders = providers.filter((el) => el.provider.name === 'modulr').map((el) => ({
		id: el.provider.name,
		name: el.provider.name,
		displayName: el.provider.displayName,
	}));

	return (
		<div>
			<div className="logs">
				<CPButton
					action={() => { setFilterVisible(true); setDate(true); }}
					icon="CalendarDate"
					text="date"
				/>
				<CPButton
					action={() => setFilterVisible(true)}
					text="filter"
					icon="Funnel"
				/>
			</div>
			<Filter
				fields={{
					instrumentId: StatusHelpers.bankInstruments,
					provider: availableProviders,
					dateRange: 'time',
				}}
				date={date}
				visible={filterVisible}
				place="accountAccounts"
				filterMode="single"
				position={448}
				closeFunction={() => { setFilterVisible(false); setDate(false); }}
			/>
			{!isFetchingUserAccounts
				? (
					<AccountsList
						accounts={accountAccounts}
						accountsTotal={accountAccountsTotal}
						currentPage={currentPage}
						headerHeight={208}
						handlePageChange={handleUserAccountsPageChange}
						place="accountAccounts"
						applicationId={applicationId}
						loading={isFetchingUserAccounts}
					/>
				) : <Spin spinning={isFetchingUserAccounts} position="spin-relative" />}
		</div>
	);
}

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.accountAccountsPage,
	accountAccountsFilters: state.ui.accountAccountsFilters,
	accountAccounts: state.bank.accountAccounts,
	isFetchingAccountAccounts: state.bank.isFetchingAccountAccounts,
	accountAccountsTotal: state.bank.accountAccountsTotal,
	providers: state.providers.providersInstances,
});
const mapDispatchToProps = {
	getAccountBankAccounts,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankTab);
