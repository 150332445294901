// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
//= import components
import FilterTag from '../FilterTag';
import CPButton from '../Button';
import Select from '../Select';
import Input from '../Input';
import Modal from '../Modal';
import RangePicker from '../RangePicker';
//= import actions
import { setCurrentFilters, setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { FilterType } from '../../../modules/reducers/UiReducer';
import type { State } from '../../../modules/types/FlowTypes';
// import styles
import styles from './assets/filter.module.scss';

type Props = {
	fields: {
		[string]: Array<{
			id: string,
			name?: string,
			displayName?: string,
		}>
	},
	visible: boolean,
	place: string,
	position: number,
	closeFunction: () => void,
	setCurrentFilters: (string, FilterType) => void,
	setCurrentPage: (string, number) => void,
	currentFilters: FilterType,
	searchFunction: () => void,
	date: boolean,
	filterMode?: string,
}
const Filter = (props: Props) => {
	const {
		fields,
		visible,
		place,
		closeFunction,
		currentFilters,
		searchFunction,
		position,
		date,
		filterMode,
	} = props;

	const [property, setProperty] = useState('');
	const [filterValue, setFilterValue] = useState(currentFilters);

	const closeModal = () => {
		setProperty('');
		closeFunction();
	};

	const makeFilterValue = (value) => {
		if (filterMode === 'multiple') {
			const currValue = filterValue[property] || [];
			const newValue = uniq([...currValue, value]);
			const filter = { ...filterValue, [property]: newValue };
			setFilterValue(filter);
		} else {
			const newValue = [value];
			const filters = { ...filterValue, [property]: newValue };
			setFilterValue(filters);
		}
	};

	const removeFilterValue = (value) => {
		const currValue = filterValue[property] || [];
		const newValue = currValue.filter((el) => el !== value);
		const filters = { ...filterValue, [property]: newValue };
		setFilterValue(filters);
	};

	const makeTimeFilter = (value) => {
		const timeFilter = {
			...filterValue,
			from: value[0] ? [value[0].format('YYYY-MM-DD 00:00:00')] : [],
			to: value[1] ? [value[1].format('YYYY-MM-DD 23:59:59')] : [],
		};
		setFilterValue(timeFilter);
	};

	const makeInputFilterValue = (e) => {
		const newValue = [e.target.value];
		const filters = { ...filterValue, [property]: newValue };
		setFilterValue(filters);
	};

	const makeQuery = () => {
		props.setCurrentFilters(`${place}Filters`, filterValue);
		props.setCurrentPage(`${place}Page`, 1);
		closeModal();
	};

	const filterByTime = (value) => {
		const timeFilter = {
			...filterValue,
			from: value[0] ? [value[0].format('YYYY-MM-DD 00:00:00')] : [],
			to: value[1] ? [value[1].format('YYYY-MM-DD 23:59:59')] : [],
		};
		setFilterValue(timeFilter);
		props.setCurrentFilters(`${place}Filters`, timeFilter);
		props.setCurrentPage(`${place}Page`, 1);
		closeModal();
	};

	const onTagRemove = (key: string, value: string) => {
		const newFilterValue = filterValue[key].filter((el) => el !== value);
		const filter = { ...filterValue, [key]: newFilterValue };
		setFilterValue(filter);
		props.setCurrentFilters(`${place}Filters`, filter);
	};

	const clearAllFilters = () => {
		props.setCurrentFilters(`${place}Filters`, {});
		setFilterValue({});
	};

	const getNames = (field: string, id: string) => {
		if (field === 'time' || !fields[field] || fields[field] === 'input') {
			return id;
		}
		const selectedElement = fields[field].filter((el) => el.id === id);
		const name = selectedElement[0].name
			|| selectedElement[0].displayName
			|| selectedElement[0].id;
		return name;
	};

	const renderField = (values) => {
		if (values === 'time') {
			return (
				<RangePicker
					className={styles.date}
					dropdownClassName={styles.picker}
					onChange={(value) => makeTimeFilter(value)}
				/>
			);
		} if (values === 'input') {
			return (
				<Input
					className={styles.input}
					placeholder="Paste value"
					maxLength={100}
					onChange={(value) => makeInputFilterValue(value)}
					defaultValue={currentFilters?.[property]}
				/>
			);
		}
		return (
			<Select
				key={property}
				allowClear
				className={styles.select}
				mode={filterMode}
				showSearch
				onSearch={searchFunction}
				optionFilterProp="children"
				filterOption={
					(input, data) => data.props.children.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}
				onSelect={makeFilterValue}
				onDeselect={removeFilterValue}
				defaultValue={currentFilters?.[property]}
			>
				{values && values.map((option) => (
					<Option value={option.id} key={option.id} className={styles.options}>
						{
							option.name
							|| option.displayName
							|| option.id
						}
					</Option>
				))}
			</Select>
		);
	};

	const { Option } = Select;

	return (
		<>
			<div className={styles.tags}>
				{map(currentFilters, (value, key) => (
					value.map((val) => (
						<FilterTag
							closable
							onClose={() => onTagRemove(key, val)}
							key={val}
							className={styles.filterTags}
							color="#E3F0FF"
						>
							{`${key} is ${getNames(key, val)}`}
						</FilterTag>
					))
				))}
				<FilterTag
					closable
					onClose={clearAllFilters}
					key="clearAll"
					className={`${styles.filterTags} ${styles.clearAll}`}
					color="#416A9D"
				>
					clear all
				</FilterTag>
			</div>
			<Modal
				visible={visible && !date}
				footer=""
				closable={false}
				destroyOnClose
				width={540}
				style={{ top: position, marginRight: 24 }}
				bodyStyle={{ padding: 8 }}
				onCancel={closeModal}
			>
				<div className={styles.filter}>
					<Select
						allowClear
						showSearch
						className={styles.firstSelect}
						onChange={setProperty}
					>
						{Object.keys(fields).map((field) => (
							<Option value={field} key={field} className={styles.options}>
								{ActivityHelpers.transformToReadable(field)}
							</Option>
						))}
					</Select>
					<div className={styles.condition}>is</div>
					{renderField(fields[property])}
					<CPButton
						type="primary"
						text="filter"
						action={makeQuery}
					/>
				</div>
			</Modal>
			<Modal
				visible={visible && date}
				footer=""
				closable={false}
				destroyOnClose
				width={240}
				style={{ top: position, marginRight: 24 }}
				bodyStyle={{ padding: 0 }}
				onCancel={closeModal}
			>
				<RangePicker
					dropdownClassName={styles.picker}
					onChange={(value) => filterByTime(value)}
					open
				/>
			</Modal>
		</>
	);
};

Filter.defaultProps = {
	filterMode: 'multiple',
};

const mapStateToProps = (state: State, props) => ({
	currentFilters: state.ui[`${props.place}Filters`],
});

const mapDispatchToProps = {
	setCurrentFilters,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
