// @flow

import {
	GET_FEES,
	ADD_FEE,
	UPDATE_FEE,
	REMOVE_FEE,
	GET_FEE_QUOTE,
} from '../const/types';

export function getFees(applicationId: string, param: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_FEES,
		payload: {
			request: {
				url: `/applications/${applicationId}/fees?${param}`,
			},
		},
	});
}

export function addFee(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_FEE,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/fees`,
				data,
			},
		},
	});
}

export function updateFee(applicationId: string, feeId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_FEE,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/fees/${feeId}`,
				data,
			},
		},
	});
}

export function removeFee(applicationId: string, feeId: string) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_FEE,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/fees/${feeId}`,
			},
		},
	});
}

export function getFeeQuote(applicationId: string, parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_FEE_QUOTE,
		payload: {
			request: {
				url: `/applications/${applicationId}/fees/quote?${parametar}`,
			},
		},
	});
}
