// @flow

import React, {
	useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
//= import componnets
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
//= import actions
import { getDividendsPayouts } from '../../../modules/actions/DividendsAction';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { DividendPayout } from '../../../modules/reducers/DividendsReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type PayoutRes = {
	payload: {
		data: Array<DividendPayout>,
	}
}
type Props = {
	getDividendsPayouts: (string, number, string) => Promise<PayoutRes>,
	payoutIsFetching: boolean,
	match: {
		params: {
			applicationId: string,
			payoutId: string,
			userId: string,
		},
	},
	history: {
		goBack: () => void,
		length: number,
	}
}

const Payout = (props: Props) => {
	const {
		getDividendsPayouts: getDividendsPayoutsAction,
		payoutIsFetching,
		match: { params: { applicationId, payoutId, userId } },
		history,
	} = props;

	const [payout, setPayout] = useState({});

	useEffect(() => {
		getDividendsPayoutsAction(
			applicationId,
			1,
			queryString.stringify({ userId }),
		)
			.then((res: PayoutRes) => {
				const data: DividendPayout = res.payload.data.filter((el) => el.id === payoutId)[0];
				setPayout(data || {});
			});
	}, [
		applicationId, getDividendsPayoutsAction, userId, payoutId,
	]);

	const handleBackClick = () => {
		history.goBack();
	};

	const dateData = {
		createdAt: payout.createdAt ? moment(payout.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
		updatedAt: payout.updatedAt ? moment(payout.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '',
		announcementDate: payout.dividend ? moment(payout.dividend.announcementDate).format('YYYY-MM-DD HH:mm:ss') : '-',
		exDividendDate: payout.dividend ? moment(payout.dividend.exDividendDate).format('YYYY-MM-DD HH:mm:ss') : '-',
		recordDate: payout.dividend ? moment(payout.dividend.recordDate).format('YYYY-MM-DD HH:mm:ss') : '-',
		payoutDate: payout.dividend ? moment(payout.dividend.payoutDate).format('YYYY-MM-DD HH:mm:ss') : '-',
	};
	return (
		<>
			{!payoutIsFetching
				? (
					<div>
						<Title
							title={`${payout.dividend ? payout.dividend.dividendInstrumentId : ''} Dividend Payment`}
							backAction={history.length > 1 ? handleBackClick : undefined}
							tags={[
								payout.dividend ? payout.dividend.isExecuted && 'executed' : '',
							]}
						/>
						{payout.dividend
							? (
								<div className="page-container">
									<Row gutter={24}>
										<Col span={12} className={styles.payout}>
											<div className={styles.values}>
												{moment().isBefore(payout.dividend.recordDate)
													? (
														<div className={styles.shareInfo}>
															<h2>
																For the selected stock,
																Total Number of Shares will be available
																FROM the dividend record date.
															</h2>
														</div>
													)
													: (
														<InfoCard
															data={{ totalNumberOfShares: `${payout.quantity || ''} ${payout.dividend.dividendInstrumentId || ''}` }}
														/>
													)}
												<InfoCard
													data={{
														grossAmount: PriceHelpers.formatAmount(
															payout.grossAmount,
															payout.dividend.payoutInstrumentId,
														),
														taxAmount: PriceHelpers.formatAmount(
															payout.taxAmount,
															payout.dividend.payoutInstrumentId,
														),
														payoutInstrument: payout.dividend.payoutInstrumentId,
													}}
												/>
											</div>
										</Col>
										<Col span={12}>
											<TextCard data={dateData} colNumber={1} left="left-1" />
										</Col>
									</Row>
								</div>
							)
							: (
								<div className="empty-state">
									<UserNoChecks />
									<p>{`Payout with id ${payoutId} not found!`}</p>
								</div>
							)}
					</div>
				) : <Spin spinning={payoutIsFetching} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	payoutIsFetching: state.dividends.isFetchingPayouts,
});

const mapDispatchToProps = {
	getDividendsPayouts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payout);
