// @flow

import React from 'react';
import * as icons from './components';

type IconStyle = {
	type: string,
    width?: string,
    height?: string,
	color?: string,
	viewBox?: string,
	styles?: Object,
}

const MenuIcon = ({
	type = 'Bank',
	width = '16px',
	height = '16px',
	color = '#ffffff',
	viewBox = '0 0 24 24',
	styles = {},
}: IconStyle) => {
	const IconComponent = icons[type] || icons.Star;
	return (
		<svg width={width} height={height} viewBox={viewBox} version="1.1" fill={color} style={styles}>
			<IconComponent />
		</svg>
	);
};

MenuIcon.defaultProps = {
	width: '16px',
	height: '16px',
	color: '#ffffff',
	viewBox: '0 0 24 24',
	styles: {},
};
export default MenuIcon;
