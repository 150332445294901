import React from 'react';
// import components
import Icon from '../Icon';
// import styles
import styles from './assets/errorMessage.module.scss';

type Props = {
	message: string,
}
const ErrorMessage = (props: Props) => {
	const { message } = props;
	return (
		<div className={styles.errorMessage}>
			<Icon name="Warning" colorName="danger" />
			<h5>{message}</h5>
		</div>
	);
};
export default ErrorMessage;
