// @flow

import {
	KYT_REPORTS,
	SCHEDULE_KYT_CHECKS,
	UPDATE_COMPLIANCE_STATUS,
} from '../const/types';

export function getKytReports(applicationId: string, page: number, param: string, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: KYT_REPORTS,
		payload: {
			request: {
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
				url: `applications/${applicationId}/kyt-checks?${param}`,
			},
		},
	});
}

export function scheduleKYTChecks(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: SCHEDULE_KYT_CHECKS,
		payload: {
			request: {
				method: 'POST',
				url: `applications/${applicationId}/kyt-checks/schedule`,
				data,
			},
		},
	});
}

export function updateComplianceReviewStatus(paymentId: string, status: string) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_COMPLIANCE_STATUS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/payments/${paymentId}/complianceReview/${status}`,
			},
		},
	});
}
