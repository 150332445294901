// @flow
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import InputNumber from '../../../components/UiElements/InputNumber';
import Select from '../../../components/UiElements/Select';
//= import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type Props = {
	form: *,
	submitChanges: (value: Object) => void,
	handleCancelClick: () => void,
	edit: boolean,
	dividend: Object,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	fiatInstruments: Array<Instrument>,
	applicationId: string,
}

type LocalState = {
	grossAmount: number,
	errorMessage: string,
	symbol: string,
}

class Payout extends React.Component<Props, LocalState> {
	state = {
		grossAmount: this.props.dividend.grossAmount,
		symbol: '',
		errorMessage: '',
	}

	async componentDidMount() {
		const { getAppInstruments: getAppInstrumentsAction, applicationId, dividend } = this.props;
		const filterOptions: string = queryString.stringify({ type: 'fiat' });
		const res = await getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
		const instrument: Instrument = res.payload.data
			.find((el) => el.instrumentId === dividend.payoutInstrumentId);
		this.setState({
			symbol: instrument?.formatting?.grapheme || dividend.payoutInstrumentId,
		});
	}

	format = (value: number) => {
		const { symbol } = this.state;
		if (!value) {
			return '';
		}
		return `${symbol || ''} ${value}`;
	}

	setGrossAmount = (value) => {
		this.setState({
			grossAmount: value,
		});
	}

	setInstrument = (value: string) => {
		const { fiatInstruments } = this.props;
		const instrument = fiatInstruments.find((el) => el.instrumentId === value);
		const symbol = instrument?.formatting?.grapheme || value;
		this.setState({
			symbol,
		});
	}

	validateGrossRate = (rule, value, callback) => {
		if (!value) {
			callback('Gross Amount must be greater than 0');
		} else if (parseFloat(parseFloat(value).toFixed(2)) !== parseFloat(value)) {
			callback('The Gross Rate must have precision less than or equal to 2');
		}
		callback();
	}

	validateTax = (rule, value, callback) => {
		const { grossAmount } = this.state;
		if (value !== 0 && !value) {
			callback('Tax Amount must be greater than or equal to 0');
		} else {
			if (value > grossAmount) {
				callback('Tax Amount must be smaller than Gross Rate');
			}
			if (parseFloat(parseFloat(value).toFixed(2)) !== parseFloat(value)) {
				callback('The Tax must have precision less than or equal to 2');
			}
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					...values,
					grossAmount: values.grossAmount.toString(),
					taxAmount: values.taxAmount.toString(),
				};
				submitChanges(data);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	render() {
		const {
			dividend,
			handleCancelClick,
			fiatInstruments,
			form: {
				getFieldDecorator, isFieldsTouched,
			},
		} = this.props;

		const { errorMessage, symbol } = this.state;
		const { Option } = Select;
		const FormItem = Form.Item;
		const fixedLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<FormItem label="PAYOUT INSTRUMENT" {...fixedLayout} help="">
					{getFieldDecorator(
						'payoutInstrumentId', {
							initialValue: dividend.payoutInstrumentId,
							rules: [
								{
									required: true,
									message: 'Please select Payout Instrument Id!',
								},
							],
						},
					)(
						<Select
							allowClear
							showSearch
							className={styles.firstSelect}
							placeholder="Select the Payout Instrument"
							onChange={this.setInstrument}
						>
							{fiatInstruments.map((instrument) => (
								<Option
									value={instrument.instrumentId}
									key={instrument.id}
									className={styles.options}
								>
									{instrument.name}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<FormItem label="GROSS RATE" {...fixedLayout}>
					{getFieldDecorator(
						'grossAmount', {
							initialValue: dividend.grossAmount,
							rules: [{ validator: this.validateGrossRate }],
						},
					)(
						<InputNumber
							symbol={symbol}
							onChange={this.setGrossAmount}
							placeholder="Set Gross Rate"
						/>,
					)}
				</FormItem>
				<FormItem label="TAX" {...fixedLayout}>
					{getFieldDecorator(
						'taxAmount', {
							initialValue: dividend.taxAmount,
							rules: [{ validator: this.validateTax }],
						},
					)(
						<InputNumber
							symbol={symbol}
							placeholder="Set Tax"
						/>,
					)}
				</FormItem>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="edit dividend calendar" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const PayoutForm = Form.create()(Payout);

const mapStateToProps = (state) => ({
	fiatInstruments: state.instrument.instruments,
});
const mapDispatchToProps = {
	getAppInstruments,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutForm);
