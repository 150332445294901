// @flow

import React, {
	useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import StockSplitsList from '../components/StockSplitsList';
import StockSplitForm from '../components/StockSplitForm';
//= import actions
import { getStockSplits, addStockSplit } from '../../../modules/actions/StockSplitAction';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { StockSplit } from '../../../modules/reducers/StockSplitReducer';

type StockSplitResponse = {
	payload: {
		data: Array<StockSplit>,
		headers: {
			'total-count': number,
		},
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

type Props = {
	activeTab: string,
	applicationId: string,
	getStockSplits: (string, number, number, Object) => Promise<StockSplitResponse>;
	addStockSplit: (string, Object) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	isFetchingStockSplits: boolean,
	upcomingStockSplitsFilters: { [string]: string },
	executedStockSplitsFilters: { [string]: string },
	upcomingStockSplitsPage: number,
	executedStockSplitsPage: number,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		goBack: () => void,
	},
}

const StockSplits = (props: Props) => {
	const {
		getStockSplits: getStockSplitsAction,
		addStockSplit: addStockSplitAction,
		setCurrentPage: setCurrentPageAction,
		isFetchingStockSplits,
		activeTab,
		upcomingStockSplitsFilters,
		executedStockSplitsFilters,
		upcomingStockSplitsPage,
		executedStockSplitsPage,
		match: { params: { applicationId } },
	} = props;

	const [upcoming, setUpcoming] = useState([]);
	const [executed, setExecuted] = useState([]);
	const [upcomingTotal, setUpcomingTotal] = useState(0);
	const [executedTotal, setExecutedTotal] = useState(0);
	const [edit, setEdit] = useState(false);
	const [footer, setFooter] = useState([]);
	const [warningTitle, setWarningTitle] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [message, setMessage] = useState({});
	const [stockSplitAdded, setStockSplitAdded] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	const formRef = useRef(null);
	const filterRef = useRef(null);

	// executed
	useEffect(() => {
		async function getData() {
			const idsFilter = executedStockSplitsFilters.instrumentId?.[0]?.replace(/\s+/g, '').toUpperCase();
			const executedFilters = { ...executedStockSplitsFilters, instrumentId: idsFilter, status: ['executed'] };
			try {
				const res:StockSplitResponse = await getStockSplitsAction(
					applicationId,
					executedStockSplitsPage,
					StatusHelpers.paginationLimit,
					queryString.stringify(executedFilters, { arrayFormat: 'comma' }),
				);
				setExecuted(res.payload.data);
				setExecutedTotal(parseInt(res.payload.headers['total-count'], 10));
			} catch (err) {
				setExecuted([]);
			}
		}
		getData();
	}, [
		applicationId,
		getStockSplitsAction,
		executedStockSplitsFilters,
		executedStockSplitsPage,
		stockSplitAdded,
	]);

	// upcoming
	useEffect(() => {
		async function getData() {
			const idsFilter = upcomingStockSplitsFilters.instrumentId?.[0]?.replace(/\s+/g, '').toUpperCase();
			const upcomingFilters = {
				...upcomingStockSplitsFilters,
				instrumentId: idsFilter,
				...(upcomingStockSplitsFilters.status === undefined
					? { status: StatusHelpers.stockSplitStatuses.map(({ id }) => id).filter((name) => name !== 'executed') }
					: {}),
			};
			try {
				const res: StockSplitResponse = await getStockSplitsAction(
					applicationId,
					upcomingStockSplitsPage,
					StatusHelpers.paginationLimit,
					queryString.stringify(upcomingFilters, { arrayFormat: 'comma' }),
				);
				setUpcoming(res.payload.data);
				setUpcomingTotal(parseInt(res.payload.headers['total-count'], 10));
			} catch (err) {
				setUpcoming([]);
			}
		}
		getData();
	}, [
		applicationId,
		getStockSplitsAction,
		upcomingStockSplitsFilters,
		upcomingStockSplitsPage,
	]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
		// eslint-disable-next-line
	}, [`${activeTab}Filters`]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handlePageChange = (page: number) => {
		setCurrentPageAction(`${activeTab}Page`, page);
	};

	const handleSchedule = () => {
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response ? error.error.response.data?.message : 'Error',
			secondPart: printError(error.error.response?.data?.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const submitSchedule = (value) => {
		setStockSplitAdded(false);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		addStockSplitAction(applicationId, value)
			.then(() => {
				setWarningModal(false);
				setStockSplitAdded(true);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueSchedule = (value: {[string]: string}) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect StockSplits.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitSchedule(value), text: 'schedule stock split' },
		]);
	};

	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="stock splits"
					buttons={activeTab === 'upcomingStockSplits' ? [
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
						{
							icon: 'Plus',
							text: 'schedule stock split',
							action: handleSchedule,
						},
					] : [
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					tab="stockSplit"
					tabs={[
						{
							key: 'upcomingStockSplits',
							title: 'UPCOMING STOCK SPLITS',
						},
						{
							key: 'executedStockSplits',
							title: 'EXECUTED STOCK SPLITS',
						},
					]}
					applicationId={applicationId}
					place={activeTab}
					fields={{
						symbolId: 'input',
						dateRange: 'time',
						...(activeTab === 'upcomingStockSplits'
							? { status: StatusHelpers.stockSplitStatuses.filter(({ id }) => id !== 'executed') }
							: {}),
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
				/>
			</div>
			<div className="container">
				<StockSplitsList
					key={activeTab}
					activeTab={activeTab}
					isFetchingStockSplits={isFetchingStockSplits}
					stockSplits={activeTab === 'upcomingStockSplits' ? upcoming : executed}
					stockSpitTotal={activeTab === 'upcomingStockSplits' ? upcomingTotal : executedTotal}
					applicationId={applicationId}
					headerHeight={filterHeight}
					currentPage={activeTab === 'upcomingStockSplits' ? upcomingStockSplitsPage : executedStockSplitsPage}
					handlePageChange={handlePageChange}
				/>
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleEditCancel}
					footer={footer}
					message={message}
				/>
				<FormModal
					title="Schedule Stock Splits"
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<StockSplitForm
							submitChanges={continueSchedule}
							handleCancelClick={handleEditCancel}
							applicationId={applicationId}
							wrappedComponentRef={formRef}
							type="schedule"
						/>
					)}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	isFetchingStockSplits: state.stockSplit.isFetchingStockSplits,
	activeTab: state.ui.stockSplitTab,
	upcomingStockSplitsFilters: state.ui.upcomingStockSplitsFilters,
	executedStockSplitsFilters: state.ui.executedStockSplitsFilters,
	upcomingStockSplitsPage: state.ui.upcomingStockSplitsPage,
	executedStockSplitsPage: state.ui.executedStockSplitsPage,
});
const mapDispatchToProps = {
	getStockSplits,
	addStockSplit,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockSplits);
