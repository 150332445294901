// @flow
import React from 'react';
import { connect } from 'react-redux';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import InputNumber from '../../../components/UiElements/InputNumber';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getPayoutFields } from '../../../modules/actions/BankActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import styles
import styles from '../assets/banking.module.scss';

type Props = {
	form: *,
	submitChanges: (value: Object) => void,
	handleCancelClick: () => void,
	edit: boolean,
	accountId: string,
	provider: string,
	instrument: string,
	balance: number,
	type: string,
	schemes: Array<{[string]: string}>,
	getPayoutFields: (string, string) => Promise<Object>,
}
export type LocalState = {
	errorMessage: string,
	schema: string,
	fields: Array<Object>,
}

class Payout extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		schema: '',
		fields: [],
	};

	selectSchema = async (value: string) => {
		const {
			accountId, getPayoutFields: getPayoutFieldsAction, type, instrument,
		} = this.props;
		try {
			const fieldsRes = await getPayoutFieldsAction(accountId, value);
			this.setState({
				schema: value,
				fields: fieldsRes?.payload?.data || [],
			});
		} catch (err) {
			this.setState({
				schema: value,
				fields: StatusHelpers[type][instrument][value] || [],
			});
		}
	}

	validateAmount = (rule, value, callback) => {
		const { balance } = this.props;
		if (!value) {
			callback("Amount can't be left empty");
		}
		if (value < 0.01) {
			callback(`The field Amount must be between 0.01 and ${balance} (Account Balance).`);
		}
		if (parseFloat(value.toFixed(2)) !== value) {
			callback('Amount must have precision less than or equal to 2');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', ') || 'Error',
				});
			} else {
				const valueData = {
					...values,
					amount: values.amount.toString(),
				};
				const data = {
					schemeName: values.schema,
					data: valueData,
				};
				submitChanges(data);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			schema: '',
			fields: [],
		});
	}

	render() {
		const {
			provider,
			handleCancelClick,
			instrument,
			type,
			schemes,
			form: {
				getFieldDecorator, isFieldsTouched,
			},
		} = this.props;

		const {
			errorMessage, schema, fields,
		} = this.state;
		const { Option } = Select;
		const FormItem = Form.Item;
		const fixedLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};

		const availableSchemas = type === 'payout' ? schemes : Object.keys(StatusHelpers?.[type]?.[instrument] || {}) || schemes;

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className={styles.associate}>
				{type === 'payout' && (
					<FormItem label="PROVIDER" {...fixedLayout}>
						{getFieldDecorator(
							'provider', { initialValue: provider },
						)(
							<Input disabled />,
						)}
					</FormItem>
				)}
				{type === 'payout' && (
					<FormItem label="INSTRUMENT" {...fixedLayout}>
						{getFieldDecorator(
							'instrumentId', { initialValue: instrument },
						)(
							<Input disabled />,
						)}
					</FormItem>
				)}
				<FormItem label="SCHEMES" {...fixedLayout}>
					{getFieldDecorator(
						'schema', {
							rules: [
								{ required: true, message: "Schemes can't be left empty" },
							],
						},
					)(
						<Select
							showSearch
							placeholder="Select a Schemes"
							onChange={(value) => this.selectSchema(value)}
						>
							{availableSchemas.map((el) => (
								<Option value={el.name || el} key={el.name || el} className="capitalize">
									{el.name || el}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>

				{schema && (
					<div>
						{fields.map((el) => {
							if (el.type === 'string') {
								if (el.name === 'amount') {
									return (
										<FormItem label={ActivityHelpers.transformToReadable(el.name || '')} {...fixedLayout} key={el.name}>
											{getFieldDecorator(el.name, {
												rules: [
													{
														validator: this.validateAmount,
														required: true,
													},
												],
											})(
												<InputNumber
													symbol={instrument}
													placeholder={`Please Enter ${ActivityHelpers.transformToReadable(el.name || '')}`}
												/>,
											)}
										</FormItem>
									);
								}
								return (
									<FormItem label={ActivityHelpers.transformToReadable(el.name || '')} {...fixedLayout} key={el.name}>
										{getFieldDecorator(
											el.name, {
												rules: [{ required: el.isRequired, message: `${el.name} can't be left empty` }],
											},
										)(
											<Input
												placeholder={`Please Enter ${ActivityHelpers.transformToReadable(el.name || '')}`}
											/>,
										)}
									</FormItem>
								);
							}
							return (
								<>
									<div className={styles.subtitle}>
										<h2>{ActivityHelpers.transformToReadable(el.name || '')}</h2>
									</div>
									<>
										{el?.properties?.map((field) => {
											if (field.type === 'string') {
												return (
													<FormItem label={ActivityHelpers.transformToReadable(field.name || '')} {...fixedLayout} key={field.name}>
														{getFieldDecorator(
															`${el.name}.${field.name}`, {
																rules: [{ required: field.isRequired, message: `${field.name} can't be left empty` }],
															},
														)(
															<Input
																placeholder={`Please Enter ${ActivityHelpers.transformToReadable(field.name || '')}`}
															/>,
														)}
													</FormItem>
												);
											}
											if (field.type === 'object') {
												return (
													<>
														<div className={styles.subtitle}>
															<h4>{ActivityHelpers.transformToReadable(field.name || '')}</h4>
														</div>
														<>
															{field.properties.map((subField) => (
																<FormItem label={ActivityHelpers.transformToReadable(subField.name || '')} {...fixedLayout} key={subField.name}>
																	{getFieldDecorator(
																		`${el.name}.${field.name}.${subField.name}`, {
																			rules: [{ required: subField.isRequired, message: `${subField.name} can't be left empty` }],
																		},
																	)(
																		<Input
																			placeholder={`Please Enter ${ActivityHelpers.transformToReadable(subField.name || '')}`}
																		/>,
																	)}
																</FormItem>
															))}
														</>
														<div className={styles.placeholder} />
													</>
												);
											}
											return null;
										})}
									</>
								</>
							);
						})}
					</div>
				)}

				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text={type} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const PayoutForm = Form.create()(Payout);

const mapDispatchToProps = {
	getPayoutFields,
};

export default connect(null, mapDispatchToProps)(PayoutForm);
