// @flow

const initialState = {
	error: false,
	apiKeys: [],
	isFetchingApiKeys: false,
	isFetchedApiKeys: false,
	apiKey: {},
	isGeneratingApiKey: false,
	isGeneratedApiKey: false,
	isRevokingApiKey: false,
	isRevokedApiKey: false,
};

type ApiKey = {
	id: string,
	createdAt: string,
	token: string,
	hasBackend: string,
}

export type ApiKeyState = {
	error: Object | boolean,
	apiKeys: Array<ApiKey>,
	isFetchingApiKeys: boolean,
	isFetchedApiKeys: boolean,
	apiKey?: ApiKey,
	isGeneratingApiKey: boolean,
	isGeneratedApiKey: boolean,
	isRevokingApiKey: boolean,
	isRevokedApiKey: boolean,
};

type ApiKeysActions = {
	type:
	| 'GET_API_KEYS'
	| 'GET_API_KEYS_SUCCESS'
	| 'GET_API_KEYS_FAIL'
	| 'REVOKE_API_KEY'
	| 'REVOKE_API_KEY_SUCCESS'
	| 'REVOKE_API_KEY_FAIL';
	payload: {
		data: Array<ApiKey>,
	},
	error: {
		code: number,
		message: string,
	}
};

type ApiKeysGenerateActions = {
	type:
	| 'GENERATE_API_KEY'
	| 'GENERATE_API_KEY_SUCCESS'
	| 'GENERATE_API_KEY_FAIL';
	payload: {
		data: ApiKey,
	},
	error: {
		code: number,
		message: string,
	}
};

type Action = ApiKeysActions | ApiKeysGenerateActions;

export default function apiKeyReducer(
	state: ApiKeyState = initialState,
	action: Action,
): ApiKeyState {
	switch (action.type) {
	case 'GET_API_KEYS': {
		return {
			...state,
			error: false,
			isFetchingApiKeys: true,
			isFetchedApiKeys: false,
		};
	}
	case 'GET_API_KEYS_FAIL': {
		return {
			...state,
			isFetchingApiKeys: false,
			isFetchedApiKeys: false,
		};
	}
	case 'GET_API_KEYS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingApiKeys: false,
			isFetchedApiKeys: true,
			apiKeys: action.payload.data,
		};
	}
	case 'GENERATE_API_KEY': {
		return {
			...state,
			error: false,
			isGeneratingApiKey: true,
			isGeneratedApiKey: false,
		};
	}
	case 'GENERATE_API_KEY_FAIL': {
		return {
			...state,
			isGeneratingApiKey: false,
			isGeneratedApiKey: false,
		};
	}
	case 'GENERATE_API_KEY_SUCCESS': {
		return {
			...state,
			error: false,
			isGeneratingApiKey: false,
			isGeneratedApiKey: true,
			apiKey: action.payload.data,
		};
	}
	case 'REVOKE_API_KEY': {
		return {
			...state,
			error: false,
			isRevokingApiKey: true,
			isRevokedApiKey: false,
		};
	}
	case 'REVOKE_API_KEY_FAIL': {
		return {
			...state,
			isRevokingApiKey: false,
			isRevokedApiKey: false,
		};
	}
	case 'REVOKE_API_KEY_SUCCESS': {
		return {
			...state,
			error: false,
			isRevokingApiKey: false,
			isRevokedApiKey: true,
		};
	}
	default:
		return state;
	}
}
