/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import React from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '../../../Menu';

type Props = {
	actions: {
		openChangePassword: () => void,
		openTwoFactor: () => void,
	}
}

const PopoverContent = ({ actions: { openChangePassword, openTwoFactor } }: Props) => (
	<Menu className="popover-menu">
		<Menu.Item key="profile" disabled>
			<NavLink exact to="/profile">
				<span>View My Profile</span>
			</NavLink>
		</Menu.Item>
		<Menu.Item key="support" disabled>
			<NavLink exact to="/support">
				<span>Customer Support</span>
			</NavLink>
		</Menu.Item>
		<Menu.Item key="change-password">
			<a role="presentation" onClick={openChangePassword}>Change password</a>
		</Menu.Item>
		<Menu.Item key="two-factor">
			<a role="presentation" onClick={openTwoFactor}>2FA settings</a>
		</Menu.Item>
		<Menu.Item key="logout" className="logout">
			<NavLink exact to="/logout">
				<span>Log out</span>
			</NavLink>
		</Menu.Item>
	</Menu>
);

export default PopoverContent;
