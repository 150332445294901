// @flow

const initialState = {
	isAuthenticated: false,
	initialized: false,
	loading: false,
	isLoggedIn: false,
	loginLoading: false,
	error: false,
	customer: false,
};

export type CustomerType = {
	id: string,
	email: string,
	userInfo: {
		firstName: string,
	},
	referenceId: string,
}
export type OauthState = {
	isAuthenticated: boolean,
	initialized: boolean,
	loading: boolean,
	isLoggedIn: boolean,
	customer: CustomerType | boolean,
	error: Object | boolean,
	loginLoading: boolean,
}
export type OauthAction = {
	type: string,
	payload: {
		data: Object,
	},
	error: Object,
	data: boolean,
	response: Object,
}

export default function oauthReducer(
	state: OauthState = initialState,
	action: OauthAction,
): OauthState {
	switch (action.type) {
	case 'LOGIN':
		return {
			...state,
			loginLoading: true,
		};
	case 'LOGIN_FAIL':
		return {
			...state,
			loginLoading: false,
			isLoggedIn: false,
			error: action.error,
		};
	case 'LOGIN_SUCCESS':
		return {
			...state,
			loginLoading: false,
			isLoggedIn: true,
			error: false,
			oauth: action.response,
			isAuthenticated: true,
		};
	case 'FETCH_USER':
		return {
			...state,
			loading: true,
		};
	case 'FETCH_USER_SUCCESS':
		return {
			...state,
			customer: action.payload.data,
			loading: false,
		};
	case 'FETCH_USER_FAIL':
		return {
			...state,
			error: action.error,
			loading: false,
		};
	case 'INITIALIZED':
		return {
			...state,
			initialized: action.data,
		};
	case 'STORE_USER':
		return {
			...state,
			isLoggedIn: true,
		};
	default:
		return state;
	}
}
