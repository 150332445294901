// @flow

import React from 'react';
//= import components
import Tooltip from '../../../components/UiElements/Tooltip';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { Rates } from '../../../modules/reducers/StaticReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	accounts: Array<Account>,
	rates: Rates,
	reportingCurrency: ?string,
	moreThanFifty: boolean,
}

const TotalValueCard = (props: Props) => {
	const {
		accounts,
		rates,
		reportingCurrency,
		moreThanFifty,
	} = props;

	const profileValue = (type: string): string => {
		if (reportingCurrency && Object.keys(rates).length) {
			const value = PriceHelpers.getTotalValue(type, accounts, reportingCurrency, rates);
			return value;
		}
		return '-';
	};
	const totalProfileValue = {
		total: profileValue('total'),
		reserved: profileValue('reserved'),
		available: profileValue('available'),
	};
	return (
		<div
			className={styles.totalValue}
		>
			<div>
				<span>Available</span>
				{totalProfileValue.available === '-' || moreThanFifty
					? (
						<Tooltip
							title="We are currently unable to calculate your Available Profile Value"
						>
							<h4>
								-
							</h4>
						</Tooltip>
					)
					: (
						<h4>
							{totalProfileValue.available}
						</h4>
					)}
			</div>
			<div>
				<span>Reserved</span>
				{totalProfileValue.reserved === '-' || moreThanFifty
					? (
						<Tooltip
							title="We are currently unable to calculate your Reserved Profile Value"
						>
							<h4>
								-
							</h4>
						</Tooltip>
					)
					: (
						<h4>
							{totalProfileValue.reserved}
						</h4>
					)}
			</div>
			<div>
				<span>Total Profile Value</span>
				{totalProfileValue.total === '-' || moreThanFifty
					? (
						<Tooltip
							title="We are currently unable to calculate your Total Profile Value"
						>
							<h4>
								-
							</h4>
						</Tooltip>
					)
					: (
						<h4>
							{totalProfileValue.total}
						</h4>
					)}
			</div>
		</div>
	);
};

export default TotalValueCard;
