// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//= import methods
import { getApplications } from '../../modules/actions/ApplicationActions';
//= import components
import CPButton from '../../components/UiElements/Button';
import Spin from '../../components/UiElements/Spin';
//= import types
import type { CustomerType } from '../../modules/reducers/OauthReducer';
import type { Applications } from '../../modules/reducers/ApplicationReducer';
import type { State } from '../../modules/types/FlowTypes';
//= create constants
const appLogo = require('../../assets/img/company_icon_public.png');

type Res = {
	payload: {
		data: Applications,
	},
}
type Props = {
	customer: CustomerType,
	applications: Applications,
	isFetchingApplications: boolean,
	history: {
		push: (string) => void,
	},
	getApplications: string => Promise<Res>,
}

const Customer = (props: Props) => {
	const {
		applications,
		customer,
		isFetchingApplications = false,
		getApplications: getApplicationsAction,
	} = props;

	useEffect(() => {
		getApplicationsAction(customer.referenceId);
	}, [getApplicationsAction, customer.referenceId]);

	const handleClick = async (application) => {
		props.history.push(`/application/${application.id}/clients`);
	};

	const getIcon = (url) => {
		if (url) {
			const appIcon = url.split('?');
			if (appIcon.length > 1) {
				return appIcon[0];
			}
			return appLogo;
		}
		return appLogo;
	};

	return (
		<div className="layout">
			{!isFetchingApplications
				? (
					<div className="customer">
						<div className="content">
							<h1 style={{ color: '#666666' }}>Welcome back{customer.userInfo?.firstName ? `, ${customer.userInfo.firstName}!` : '!'}</h1>
							<h3>Your Applications</h3>
							<div>
								{
									applications.map((application) => (
										<div
											className="card"
											key={application.id}
											onClick={() => handleClick(application)}
											role="presentation"
										>
											<img
												src={application.applicationDetail?.icon ? getIcon(application.applicationDetail.icon) : appLogo}
												alt={application.name}
											/>
											<h3>{application.name}</h3>
											<CPButton ghost icon="ArrowRight" />
										</div>
									))
								}
							</div>
						</div>
					</div>
				)
				: <Spin spinning={isFetchingApplications} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	customer: state.oauth.customer,
	applications: state.application.applications,
	isFetchingApplications: state.application.isFetchingApplications,
});

const mapDispatchToProps = {
	getApplications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
