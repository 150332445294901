// @flow

import {
	APPLICATION_SYMBOLS,
	SYMBOL,
	UPDATE_SYMBOL,
	GROUP_SYMBOLS,
	GROUP_SYMBOL,
	UPDATE_GROUP_SYMBOL,
	INSTRUMENT_SYMBOLS,
	ADD_GROUP_SYMBOLS,
} from '../const/types';

export function getAppSymbols(
	appId: string,
	paginationType: string = 'numbered-pages',
	paginationOffset: string | number = 0,
	param: string,
) {
	return (dispatch: Function): void => dispatch({
		type: APPLICATION_SYMBOLS,
		payload: {
			request: {
				headers: {
					'pagination-type': paginationType,
					'pagination-limit': 50,
					'pagination-offset': paginationOffset,
				},
				url: `/applications/${appId}/symbols?${param}`,
			},
		},
	});
}

export function getSymbol(applicationId: string, symbolId: string) {
	return (dispatch: Function): void => dispatch({
		type: SYMBOL,
		payload: {
			request: {
				url: `/applications/${applicationId}/symbols/${symbolId}`,
			},
		},
	});
}

export function updateSymbol(applicationId: string, symbolId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_SYMBOL,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/symbols/${symbolId}`,
				data,
			},
		},
	});
}

export function getGroupSymbols(
	groupId: string,
	paginationType: string = 'numbered-pages',
	paginationOffset: string | number = 0,
	param: string,
) {
	return (dispatch: Function): void => dispatch({
		type: GROUP_SYMBOLS,
		payload: {
			request: {
				headers: {
					'pagination-type': paginationType,
					'pagination-limit': 50,
					'pagination-offset': paginationOffset,
				},
				url: `/groups/${groupId}/symbols?${param}`,
			},
		},
	});
}

export function getGroupSymbol(groupId: string, symbolId: string) {
	return (dispatch: Function): void => dispatch({
		type: GROUP_SYMBOL,
		payload: {
			request: {
				url: `/groups/${groupId}/symbols/${symbolId}`,
			},
		},
	});
}

export function updateGroupSymbol(groupId: string, symbolId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_GROUP_SYMBOL,
		payload: {
			request: {
				method: 'PATCH',
				url: `/groups/${groupId}/symbols/${symbolId}`,
				data,
			},
		},
	});
}

export function addGroupSymbols(groupId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_GROUP_SYMBOLS,
		payload: {
			request: {
				method: 'POST',
				url: `/groups/${groupId}/symbols`,
				data,
			},
		},
	});
}

export function getInstrumentSymbols(applicationId: string, instrumentId: string) {
	return (dispatch: Function): void => dispatch({
		type: INSTRUMENT_SYMBOLS,
		payload: {
			request: {
				url: `/applications/${applicationId}/instruments/${instrumentId}/symbols`,
			},
		},
	});
}
