// @flow

import React from 'react';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Card from '../../../components/UiElements/Card';
//= import types
import type {
	AuthorizationControl, TransactionTypes, MerchantScope, EntryModes,
} from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	authorizationControlsData: Array<AuthorizationControl>,
	title: string,
	applicationId: string,
	handleOpenDrawer: Function,
}

const AuthorizationControls = (props: Props) => {
	const {
		authorizationControlsData,
		title,
		applicationId,
		handleOpenDrawer,
	} = props;

	const columns = [
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 272,
			render: (text: string) => (
				<div className={styles.linkText}>
					{text}
				</div>
			),
		},
		{
			title: 'is active',
			dataIndex: 'isActive',
			key: 'isActive',
			width: 112,
			render: (text: boolean) => (
				<div style={text ? { maxWidth: 80 } : { maxWidth: 80, color: '#999999' }}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'transaction type strategy',
			dataIndex: 'transactionTypeStrategy',
			key: 'transactionTypeStrategy',
			width: 240,
			render: (text: string) => (
				<div className="wrap">
					{text}
				</div>
			),
		},
		{
			title: 'transaction types',
			dataIndex: 'transactionTypes',
			key: 'transactionTypes',
			width: 240,
			render: (text: Array<TransactionTypes>) => (
				<div className="wrap">
					{text.length ? text.map((el) => el.name).join(', ') : 'All'}
				</div>
			),
		},
		{
			title: 'entry mode strategy',
			dataIndex: 'entryModeStrategy',
			key: 'entryModeStrategy',
			width: 208,
			render: (text: string) => (
				<div className="wrap">
					{text}
				</div>
			),
		},
		{
			title: 'entry modes',
			dataIndex: 'entryModes',
			key: 'entryModes',
			width: 240,
			render: (text: Array<EntryModes>) => (
				<div className="wrap">
					{text.length ? text.map((el) => el.name).join(', ') : 'All'}
				</div>
			),
		},
		{
			title: 'merchant scope',
			dataIndex: 'merchantScope',
			key: 'merchantScope',
			width: 208,
			render: (text: MerchantScope) => (
				<div className="wrap">
					{text?.name || '-'}
				</div>
			),
		},
	];

	return (
		<Card className={styles.designCards} bordered={false}>
			<h2>{title}</h2>
			<CustomTable
				columns={columns}
				data={authorizationControlsData}
				total={authorizationControlsData.length}
				headerHeight={16}
				numberOnPage={authorizationControlsData.length}
				place="authorizationControls"
				applicationId={applicationId}
				rowClassName="clickable"
				onRowAction={handleOpenDrawer}
			/>
		</Card>
	);
};

export default AuthorizationControls;
