// @flow

import React from 'react';
//= import components
import Form from '../Form';
import CPButton from '../Button';
import Input from '../Input';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	name: string,
}
type LocalState = {
	viewName: string,
}
class Name extends React.Component<Props, LocalState> {
	state = {
		viewName: this.props.name || '',
	}

	handleReset = () => {
		this.props.form.resetFields();
	}

	saveView = () => {
		this.setState({
			viewName: '',
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (!err) {
				submitChanges(values);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
		} = this.props;
		const { viewName } = this.state;
		const FormItem = Form.Item;
		const groupLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className="group-form">
				<FormItem label="NAME" {...groupLayout} help="">
					{getFieldDecorator('name', { initialValue: viewName })(
						<Input
							name="name"
							placeholder="Define View Name"
							disabled={viewName}
						/>,
					)}
				</FormItem>
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						{!viewName && (
							<CPButton
								type="primary"
								action={this.submit}
								text="save view"
							/>
						)}
						{!!viewName && (
							<CPButton
								type="primary"
								action={this.submit}
								text="update view"
							/>
						)}
						{!!viewName && (
							<CPButton
								type="primary"
								action={this.saveView}
								text="save new view"
							/>
						)}
					</FormItem>
				</div>
			</Form>
		);
	}
}

const NameForm = Form.create()(Name);

export default NameForm;
