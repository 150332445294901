// @flow

const initialState = {
	stockSplits: [],
	isFetchingStockSplits: false,
	stockSplit: {},
	isFetchingStockSplit: false,
	accountStockSplits: [],
	accountStockSplitsTotal: 0,
	isFetchingAccountStockSplits: false,
	accountStockSplit: {},
	isFetchingAccountStockSplit: false,
};

export type StockSplit = {
	announcementDate: string,
	applicationId: string,
	applicationInstrumentId: string,
	applicationSymbolId: string,
	closingPrice: string,
	exDate: string,
	id: string,
	instrumentId: string,
	quotingInstrument: string,
	recordDate: string,
	splitRate: string,
	splitRatio: string,
	splitSide: string,
	status: string,
	symbolId: string,
	executionSummary: {
		inventoryWalletId: string,
		before: {
			total: string,
			available: string,
			allocated: string,
		},
		remainder: {
			baseAmount: string,
			quotingAmount: string,
		},
		after: {
			total: string,
			available: string,
			allocated: string,
		}
	}
}
export type AccountStockSplit = {
	accountId: string,
	announcementDate: string,
	applicationId: string,
	applicationInstrumentId: string,
	applicationSymbolId: string,
	closingPrice: string,
	exDate: string,
	id: string,
	instrumentId: string,
	quotingInstrument: string,
	recordDate: string,
	splitRate: string,
	splitRatio: string,
	splitSide: string,
	status: string,
	symbolId: string,
	createdAt: string,
	executionSummary: {
		balanceBefore: string,
		balanceAfter: string,
		remainder: {
			quantity: string,
			quotingAmount: string,
			payoutWalletInstrument: string,
			exchangeRate: string,
			payoutAmount: string,
		},
	}
}

export type StockSplitState = {
	stockSplits: Array<StockSplit>,
	isFetchingStockSplits: boolean,
	stockSplit: StockSplit,
	isFetchingStockSplit: boolean,
	accountStockSplits: Array<StockSplit>,
	accountStockSplitsTotal: number,
	isFetchingAccountStockSplits: boolean,
	accountStockSplit: StockSplit,
	isFetchingAccountStockSplit: boolean,
};

type CalendarsAction = {
	type:
	| 'GET_STOCK_SPLITS'
	| 'GET_STOCK_SPLITS_FAIL'
	| 'GET_STOCK_SPLITS_SUCCESS',
	payload: {
		data: Array<StockSplit>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}

type CalendarAction = {
	type:
	| 'GET_STOCK_SPLIT'
	| 'GET_STOCK_SPLIT_FAIL'
	| 'GET_STOCK_SPLIT_SUCCESS'
	| 'UPDATE_STOCK_SPLIT'
	| 'UPDATE_STOCK_SPLIT_FAIL'
	| 'UPDATE_STOCK_SPLIT_SUCCESS'
	| 'EXECUTE_STOCK_SPLIT'
	| 'EXECUTE_STOCK_SPLIT_FAIL'
	| 'EXECUTE_STOCK_SPLIT_SUCCESS',
	payload: {
		data: StockSplit,
	},
	error: {
		code: number,
		message: string,
	}
}

type AccountSplitsAction = {
	type:
	| 'GET_ACCOUNT_STOCK_SPLITS'
	| 'GET_ACCOUNT_STOCK_SPLITS_FAIL'
	| 'GET_ACCOUNT_STOCK_SPLITS_SUCCESS',
	payload: {
		data: Array<StockSplit>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}

type AccountSplitAction = {
	type:
	| 'GET_ACCOUNT_STOCK_SPLIT'
	| 'GET_ACCOUNT_STOCK_SPLIT_FAIL'
	| 'GET_ACCOUNT_STOCK_SPLIT_SUCCESS',
	payload: {
		data: StockSplit,
	},
	error: {
		code: number,
		message: string,
	}
}

type Action = CalendarAction | CalendarsAction | AccountSplitsAction | AccountSplitAction;

export default function stockSplitReducer(
	state: StockSplitState = initialState,
	action: Action,
): StockSplitState {
	switch (action.type) {
	case 'GET_STOCK_SPLITS':
		return {
			...state,
			isFetchingStockSplits: true,
			error: false,
			stockSplits: [],
		};
	case 'GET_STOCK_SPLITS_FAIL':
		return {
			...state,
			isFetchingStockSplits: false,
			error: action.error,
		};
	case 'GET_STOCK_SPLITS_SUCCESS':
		return {
			...state,
			isFetchingStockSplits: false,
			error: false,
			stockSplits: action.payload.data,
		};
	case 'GET_STOCK_SPLIT':
		return {
			...state,
			isFetchingStockSplit: true,
			error: false,
			stockSplit: {},
		};
	case 'GET_STOCK_SPLIT_FAIL':
		return {
			...state,
			isFetchingStockSplit: false,
			error: action.error,
		};
	case 'GET_STOCK_SPLIT_SUCCESS':
		return {
			...state,
			isFetchingStockSplit: false,
			error: false,
			stockSplit: action.payload.data,
		};
	case 'GET_ACCOUNT_STOCK_SPLITS':
		return {
			...state,
			isFetchingAccountStockSplits: true,
			error: false,
			accountStockSplits: [],
		};
	case 'GET_ACCOUNT_STOCK_SPLITS_FAIL':
		return {
			...state,
			isFetchingAccountStockSplits: false,
			error: action.error,
		};
	case 'GET_ACCOUNT_STOCK_SPLITS_SUCCESS':
		return {
			...state,
			isFetchingAccountStockSplits: false,
			error: false,
			accountStockSplits: action.payload.data,
			accountStockSplitsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'GET_ACCOUNT_STOCK_SPLIT':
		return {
			...state,
			isFetchingAccountStockSplit: true,
			error: false,
			accountStockSplit: {},
		};
	case 'GET_ACCOUNT_STOCK_SPLIT_FAIL':
		return {
			...state,
			isFetchingAccountStockSplit: false,
			error: action.error,
		};
	case 'GET_ACCOUNT_STOCK_SPLIT_SUCCESS':
		return {
			...state,
			isFetchingAccountStockSplit: false,
			error: false,
			accountStockSplit: action.payload.data,
		};
	case 'UPDATE_STOCK_SPLIT_SUCCESS':
		return {
			...state,
			isFetchingStockSplit: false,
			error: false,
			stockSplit: action.payload.data,
		};
	case 'EXECUTE_STOCK_SPLIT_SUCCESS':
		return {
			...state,
			isFetchingStockSplit: false,
			error: false,
			stockSplit: action.payload.data,
		};
	default:
		return state;
	}
}
