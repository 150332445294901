// @flow

import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//= import components
import Title from '../../components/UiElements/Title';
import CustomTable from '../../components/UiElements/CustomTable';
import Spin from '../../components/UiElements/Spin';
import Tag from '../../components/UiElements/Tag';
import CopyComponent from '../../components/UiElements/CopyComponent';
//= import methods
import { getKytReports } from '../../modules/actions/KYTActions';
import { getProvidersInstances } from '../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../lib/helpers/statusHelpers';
//= import types
import type { KytReport } from '../../modules/reducers/KytReducer';
import type { ProviderInstance } from '../../modules/reducers/ProviderReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	kytReports: Array<KytReport>,
	isFetchingKytReports: boolean,
	kytReportsTotal: number,
	getKytReports: (applicationId: string, page: number, params: string, pageSize: number) => void,
	getProvidersInstances: (string) => void,
	providerInstance: Array<ProviderInstance>,
	kytReportsPage: number,
	pageSize: number,
	kycReportsFilters: { [string]: string },
	kytReportsFilters: { [string]: string },
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	setCurrentPage: (string, number) => void,
}

function KYTReports(props: Props) {
	const {
		getKytReports: getKytReportsAction,
		setCurrentPage: setCurrentPageAction,
		getProvidersInstances: getProvidersInstancesAction,
		kytReports,
		isFetchingKytReports,
		kytReportsTotal,
		providerInstance,
		kytReportsFilters,
		kytReportsPage,
		pageSize,
		match: { params: { applicationId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		getKytReportsAction(applicationId, kytReportsPage, queryString.stringify(kytReportsFilters), pageSize);
	}, [kytReportsFilters, kytReportsPage, getKytReportsAction, applicationId, pageSize]);

	const handleKytPageChange = (page: number) => {
		setCurrentPageAction('kytPage', page);
	};

	const filterRef = useRef(null);
	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [kytReportsFilters]);

	const getProviderName = (id) => {
		const instance = providerInstance?.find((el) => el.id === id);
		return instance ? instance.provider.displayName : id;
	};

	const columns = [
		{
			title: 'created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => (
				<div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
			),
		},
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 332,
			render: (text: string, record: KytReport) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/payments/${record.paymentId}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'risk score',
			dataIndex: 'payload',
			key: 'riskscore',
			width: 144,
			render: (payload: Object) => (
				<span className="capitalize">
					{payload?.data?.riskscore || payload?.data?.riskscore === 0 ? payload?.data?.riskscore : '-'}
				</span>
			),
		},
		{
			title: 'payment id',
			dataIndex: 'paymentId',
			key: 'paymentId',
			width: 332,
			render: (text: string) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={`/application/${applicationId}/payments/${text}`}
						>
							{text}
						</Link>
					)}
				/>
			),
		},
		{
			title: 'address',
			dataIndex: 'address',
			key: 'address',
			width: 400,
			render: (text: string) => (
				<CopyComponent
					text={text}
					content={(<span>{text}</span>)}
				/>
			),
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 160,
			render: (text: string) => (
				<div>
					<Tag
						status={text}
					/>
				</div>
			),
		},
		{
			title: 'client',
			dataIndex: 'user',
			key: 'user',
			width: 208,
			render: (user: Object) => (
				<div>
					<Link to={`/application/${applicationId}/clients/${user.id}`}>
						{`${user.firstName || ''} ${user.lastName || ''}`}
					</Link>
				</div>
			),
		},
		{
			title: 'provider',
			dataIndex: 'providerInstanceId',
			key: 'providerInstanceId',
			width: 208,
			render: (data: string) => (
				<div>
					{getProviderName(data)}
				</div>
			),
		},
	];

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="KYT Reports"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="kyt"
					fields={{
						dateRange: 'time',
						status: StatusHelpers.kytReportsStatuses,
						userId: 'input',
						accountId: 'input',
						paymentId: 'input',
						riskScore: 'numberRange',
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
					filterMode="single"
				/>
			</div>
			<div className="container">
				{!isFetchingKytReports
					? (
						<CustomTable
							columns={columns}
							data={kytReports}
							total={kytReportsTotal}
							handlePageChange={handleKytPageChange}
							currentPage={kytReportsPage}
							headerHeight={filterHeight}
							place="kytReports"
							numberOnPage={pageSize}
							applicationId={applicationId}
						/>
					) : <Spin spinning={isFetchingKytReports} />}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	providerInstance: state.providers.providersInstances,
	kytReportsPage: state.ui.kytPage,
	kytReportsFilters: state.ui.kytFilters,
	kytReports: state.kyt.kytReports,
	kytReportsTotal: state.kyt.kytReportsTotal,
	isFetchingKytReports: state.kyt.isFetchingKytReports,
	pageSize: state.ui.kytReportsPageSize,
});

const mapDispatchToProps = {
	getKytReports,
	setCurrentPage,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(KYTReports);
