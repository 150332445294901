// @flow

import React from 'react';
import currencies from 'world-currencies';
//= import components
import Form from '../../../components/UiElements/Form';
import Input from '../../../components/UiElements/Input';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import InputNumber from '../../../components/UiElements/InputNumber';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import types
import type { FormProps } from '../../../modules/types/FormTypes';
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import StatusHelpers from '../../../lib/helpers/statusHelpers';

type Props = {
	form: FormProps,
	submitChanges: (Object) => void,
	handleCancelClick: () => void,
	symbol: Symbol,
	parentSymbol: Symbol,
	group: boolean,
	availableProviders: Array<ProviderInstance>,
}
type State = {
	errorMessage: string,
	precision: number,
}
type Values = {
	displayName: string,
	maxBaseQuantity: string,
	minBaseQuantity: string,
	maxQuotingQuantity: string,
	minQuotingQuantity: string,
	supportedOrderTypes: string,
	exchangeMinimum: string,
	exchangePrecision: number,
	offsetAsk: string,
	offsetBid: string,
}
class SymbolForm extends React.Component<Props, State> {
	state = {
		errorMessage: '',
		precision: this.props.symbol.exchangePrecision,
	}

	formatPercent = (value: string) => {
		if (!value) {
			return '';
		}
		return `${value}%`;
	}

	formatBase = (value) => {
		const { symbol } = this.props;
		const { grapheme } = symbol.baseInstrument.formatting;
		if (!value) {
			return '';
		}
		if (symbol.baseInstrument.type === 'fiat') {
			return `${grapheme} ${value}`;
		}
		return `${value} ${grapheme}`;
	}

	formatQuoting = (value) => {
		const { symbol } = this.props;
		const grapheme: string = currencies[symbol.quotingInstrumentId]
			? currencies[symbol.quotingInstrumentId].units.major.symbol
			: symbol.quotingInstrumentId;
		if (!value) {
			return '';
		}
		if (symbol.quotingInstrument.type === 'fiat') {
			return `${grapheme} ${value}`;
		}
		return `${value} ${grapheme}`;
	}

	setExchangePrecision = (value: number) => {
		this.setState({
			precision: value,
		});
	}

	validatePrecision = (rule, value: number, callback) => {
		if (value && (value < 0 || value > 18)) {
			callback('Precision must be greater than 0 and smaller than 18');
		}
		callback();
	}

	validateExchangeMinimum = (rule, value: number, callback) => {
		const { precision } = this.state;
		if (!value) {
			callback();
		}
		const decimalPart = value.toString().split('.')?.[1];
		if (decimalPart && (decimalPart.length > precision)) {
			callback(`Exchange Minimum must have less or equal decimal places then ${precision}.`);
		}
		callback();
	}

	validateMinBaseQuantity = (rule, value: number, callback) => {
		const { basePrecision } = this.props.symbol;
		if (!value) {
			callback();
		}
		const decimalPart = value.toString().split('.')?.[1];
		if (decimalPart && (decimalPart.length > basePrecision)) {
			callback(`Minimum possible quantity must have less or equal decimal places then symbol base precision: ${basePrecision}.`);
		}
		callback();
	}

	validateMinQuotingQuantity = (rule, value: number, callback) => {
		const { quotingPrecision } = this.props.symbol;
		if (!value) {
			callback();
		}
		const decimalPart = value.toString().split('.')?.[1];
		if (decimalPart && (decimalPart.length > quotingPrecision)) {
			callback(`Minimum possible quantity must have less or equal decimal places then symbol quoting precision: ${quotingPrecision}.`);
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					...values,
					displayName: form.isFieldTouched('displayName') && (values.displayName ? values.displayName : undefined),
					exchangeMinimum: form.isFieldTouched('exchangeMinimum') && (values.exchangeMinimum === null
						? values.exchangeMinimum
						: values.exchangeMinimum.toString()),
					maxBaseQuantity: form.isFieldTouched('maxBaseQuantity') && (values.maxBaseQuantity === null
						? values.maxBaseQuantity
						: values.maxBaseQuantity.toString()),
					minBaseQuantity: form.isFieldTouched('minBaseQuantity') && (values.minBaseQuantity === null
						? values.minBaseQuantity
						: values.minBaseQuantity.toString()),
					maxQuotingQuantity: form.isFieldTouched('maxQuotingQuantity') && (values.maxQuotingQuantity === null
						? values.maxQuotingQuantity
						: values.maxQuotingQuantity.toString()),
					minQuotingQuantity: form.isFieldTouched('minQuotingQuantity') && (values.minQuotingQuantity === null
						? values.minQuotingQuantity
						: values.minQuotingQuantity.toString()),
					offsetAsk: form.isFieldTouched('offsetAsk') && (values.offsetAsk === null
						? '0'
						: values.offsetAsk.toString()),
					// eslint-disable-next-line no-nested-ternary
					offsetBid: form.isFieldTouched('offsetBid') && (values.offsetBid === null
						? '0'
						: Number(values.offsetBid) < 0
							? values.offsetBid.toString()
							: (-values.offsetBid).toString()),
				};
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			symbol,
			parentSymbol,
			handleCancelClick,
			group,
			availableProviders,
		} = this.props;
		const minPossibleQuantity = parentSymbol.orderSize
			? parentSymbol.orderSize.minQuantity.baseInstrument
			: null;
		const maxPossibleQuantity = parentSymbol.orderSize
			? parentSymbol.orderSize.maxQuantity.baseInstrument
			: null;
		const minPossibleAmount = parentSymbol.orderSize
			? parentSymbol.orderSize.minQuantity.quotingInstrument
			: null;
		const maxPossibleAmount = parentSymbol.orderSize
			? parentSymbol.orderSize.maxQuantity.quotingInstrument
			: null;

		const maxQuantityProps = maxPossibleQuantity ? { max: maxPossibleQuantity } : null;
		const maxAmountProps = maxPossibleAmount ? { max: maxPossibleAmount } : null;

		const { errorMessage } = this.state;
		const marketOrderLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const detailLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 14 },
			},
		};
		const { Option } = Select;
		const FormItem = Form.Item;

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<FormItem label="DISPLAY NAME" {...detailLayout}>
					{getFieldDecorator('displayName', {
						initialValue: symbol.displayName,
					})(
						<Input name="displayName" placeholder="Set Display Name" />,
					)}
				</FormItem>
				{!group && (
					<>
						<FormItem label="ORDER TYPES" {...detailLayout}>
							{getFieldDecorator('supportedOrderTypes', {
								rules: [{ required: true, message: "Supported order types can't be left empty" }],
								initialValue: symbol.supportedOrderTypes,
							})(
								<Select mode="multiple" allowClear>
									{StatusHelpers.orderTypes.map((el) => (
										<Option value={el.id} key={el.id}>{el.name}</Option>
									))}
								</Select>,
							)}
						</FormItem>
						<FormItem label="TRADING PROVIDER" {...detailLayout}>
							{getFieldDecorator('tradingProviderId', {
								rules: [{ required: true, message: "Trading Provider can't be left empty" }],
								initialValue: symbol.tradingProviderId,
							})(
								<Select allowClear>
									{availableProviders.map((el) => (
										<Option value={el.id} key={el.id}>
											{el.provider.displayName}
										</Option>
									))}
								</Select>,
							)}
						</FormItem>
						<FormItem label="EXCHANGE PRECISION" {...marketOrderLayout} className="form-item-small">
							{getFieldDecorator('exchangePrecision', {
								initialValue: symbol.exchangePrecision,
								rules: [
									{ validator: this.validatePrecision },
									{ required: true, message: "Exchange Precision can't be left empty" },
								],
							})(
								<InputNumber
									onChange={this.setExchangePrecision}
									placeholder="Set Exchange Precision"
									type="number"
								/>,
							)}
						</FormItem>
						<FormItem label="EXCHANGE MINIMUM" {...marketOrderLayout} className="form-item-small">
							{getFieldDecorator('exchangeMinimum', {
								initialValue: symbol.exchangeMinimum,
								rules: [
									{ validator: this.validateExchangeMinimum },
									{ required: true, message: "Exchange Minimum can't be left empty" },
								],
							})(
								<InputNumber
									symbol={symbol.baseInstrument.id}
									placeholder="Set Exchange Minimum"
								/>,
							)}
						</FormItem>
					</>
				)}
				<h4>Market Order Size</h4>
				<p>
					Define the Quantity of one Order.
					The Minimum Quantity must be set higher than the Minimum Possible,
					and the Maximum Quantity lower than the Maximum Possible.
				</p>
				<FormItem label="MIN POSSIBLE QUANTITY" {...marketOrderLayout} className="form-item-small">
					<div className="right-align-div">{this.formatBase(minPossibleQuantity) || '-'}</div>
				</FormItem>
				<FormItem label="MIN QUANTITY SET" {...marketOrderLayout} className="form-item-small">
					{getFieldDecorator('minBaseQuantity', {
						initialValue: symbol.orderSize.minQuantity.baseInstrument,
						rules: [
							{ validator: this.validateMinBaseQuantity },
						],
					})(
						<InputNumber
							min={minPossibleQuantity || 0}
							symbol={symbol.baseInstrument.id}
							placeholder="Set Min Base Quantity"
						/>,
					)}
				</FormItem>
				<FormItem label="MAX POSSIBLE QUANTITY" {...marketOrderLayout} className="form-item-small">
					<div className="right-align-div">{this.formatBase(maxPossibleQuantity) || '-'}</div>
				</FormItem>
				<FormItem label="MAX QUANTITY SET" {...marketOrderLayout}>
					{getFieldDecorator('maxBaseQuantity', { initialValue: symbol.orderSize.maxQuantity.baseInstrument })(
						<InputNumber
							{...maxQuantityProps}
							symbol={symbol.baseInstrument.id}
							placeholder="Set Max Base Quantity"
						/>,
					)}
				</FormItem>
				<p>
					Define the Amount (Quoting Quantity) of one Order.
					The Minimum Amount must be set higher than the Minimum Possible,
					and the Maximum Amount lower than the Maximum Possible.
				</p>
				<FormItem label="MIN POSSIBLE AMOUNT" {...marketOrderLayout} className="form-item-small">
					<div className="right-align-div">{this.formatQuoting(minPossibleAmount) || '-'}</div>
				</FormItem>
				<FormItem label="MIN AMOUNT SET" {...marketOrderLayout} className="form-item-small">
					{getFieldDecorator('minQuotingQuantity', {
						initialValue: symbol.orderSize.minQuantity.quotingInstrument,
						rules: [{
							validator: this.validateMinQuotingQuantity,
						}],
					})(
						<InputNumber
							min={minPossibleAmount || 0}
							symbol={symbol.quotingInstrumentId}
							placeholder="Set Min Quoting Quantity"
						/>,
					)}
				</FormItem>
				<FormItem label="MAX POSSIBLE AMOUNT" {...marketOrderLayout} className="form-item-small">
					<div className="right-align-div">{this.formatQuoting(maxPossibleAmount) || '-'}</div>
				</FormItem>
				<FormItem label="MAX AMOUNT SET" {...marketOrderLayout}>
					{getFieldDecorator('maxQuotingQuantity', { initialValue: symbol.orderSize.maxQuantity.quotingInstrument })(
						<InputNumber
							{...maxAmountProps}
							symbol={symbol.quotingInstrumentId}
							placeholder="Set Max Quoting Quantity"
						/>,
					)}
				</FormItem>
				<FormItem label="ASK PRICE OFFSET" {...marketOrderLayout}>
					<div className="fee-info">
						{getFieldDecorator('offsetAsk', {
							initialValue: symbol.offset.ask,
						})(
							<InputNumber
								symbol={symbol.quotingInstrumentId}
								placeholder="Set Ask Price Offset"
							/>,
						)}
					</div>
				</FormItem>
				<FormItem label="BID PRICE OFFSET" {...marketOrderLayout}>
					<div className="fee-info">
						{getFieldDecorator('offsetBid', {
							initialValue: symbol.offset.bid,
						})(
							<InputNumber
								symbol={symbol.quotingInstrumentId}
								placeholder="Set Bid Price Offset (can only be negative value)"
							/>,
						)}
					</div>
				</FormItem>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="save changes" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EditSymbolForm = Form.create()(SymbolForm);

export default EditSymbolForm;
