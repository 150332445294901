// @flow

import React from 'react';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import styles
import styles from './assets/tag.module.scss';

type Props = {
	status: string,
}
const Tag = (props: Props) => {
	const { status } = props;
	const color = StatusHelpers.statusColor(status);
	const text = ActivityHelpers.transformToReadable(status || 'noStatus');
	return (
		<div className={styles.container}>
			<div style={{ backgroundColor: color }} className={styles.tag}>{text}</div>
			<div className={styles.text} style={{ color }}>{text}</div>
		</div>
	);
};

export default Tag;
