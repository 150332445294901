// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Drawer from '../../../components/UiElements/Drawer';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import Spin from '../../../components/UiElements/Spin';
import Title from '../../../components/UiElements/Title';
import CustomTable from '../../../components/UiElements/CustomTable';
import Table from '../../../components/UiElements/Table';
import Icon from '../../../components/UiElements/Icon';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import actions
import { getRiskiestBorrowers } from '../../../modules/actions/MarginActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import selectors
import { riskiestBorrowersSelector } from '../../../modules/selectors/MarginSelector';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { UserInfo } from '../../../modules/reducers/UsersReducer';
import type { RiskiestBorrowerWithCurrency } from '../../../modules/selectors/MarginSelector';
//= import styles
import styles from '../assets/borrowers.module.scss';

type Props = {
	setCurrentPage: (string, number) => void,
	getRiskiestBorrowers: (string) => Promise<Object>,
	history: {
		push: string => void,
	},
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	borrowersPage: number,
	riskiestBorrowers: Array<RiskiestBorrowerWithCurrency>,
	riskiestBorrowersTotal: number,
	isFetchingRiskiestBorrowers: boolean,
	applicationId: string,
	marginEnabled: boolean,
}

const BorrowersTab = (props: Props) => {
	const {
		borrowersPage,
		riskiestBorrowersTotal,
		riskiestBorrowers,
		marginEnabled,
		isFetchingRiskiestBorrowers,
		getRiskiestBorrowers: getRiskiestBorrowersAction,
		setCurrentPage: setCurrentPageAction,
		match: { params: { applicationId } },
	} = props;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [drawerData, setDrawerData] = useState({});
	const [drawerMarginData, setDrawerMarginData] = useState({});

	useEffect(() => {
		getRiskiestBorrowersAction(applicationId);
	}, [borrowersPage, marginEnabled, drawerOpen, applicationId, getRiskiestBorrowersAction]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('borrowersPage', page);
	};

	const handleOpenDrawer = (borrower: RiskiestBorrowerWithCurrency) => {
		setDrawerOpen(true);
		setDrawerData(borrower);
		const data = {
			instrumentSuitableForCollateral: borrower.borrowerCurrency || '-',
			marginCollateral: PriceHelpers.formatAmount(borrower.marginInfo.marginCollateral, borrower.borrowerCurrency || '', Numbers.sign(borrower.marginInfo.marginCollateral)),
			collateralMultiplier: borrower.marginInfo.collateralMultiplier,
			loanLimit: PriceHelpers.formatAmount(borrower.marginInfo.loanLimit, borrower.borrowerCurrency || '', Numbers.sign(borrower.marginInfo.loanLimit)),
			borrowedAmount: PriceHelpers.formatAmount(borrower.marginInfo.borrowedAmount, borrower.borrowerCurrency || ''),
			marginUtilization: `${borrower.marginInfo.marginUtilization}%`,
		};
		setDrawerMarginData(data);
	};

	const onClose = () => {
		setDrawerOpen(false);
	};
	const columns = [
		{
			title: 'name',
			dataIndex: 'user.userInfo',
			key: 'name',
			width: 224,
			onCell: () => ({ onClick: (e) => { e.stopPropagation(); } }),
			render: (userInfo: UserInfo, record: RiskiestBorrowerWithCurrency) => (
				<div>
					<span>
						{userInfo
							? `${userInfo.firstName || 'Name Not Set'} ${userInfo.lastName || ''} on `
							: 'Name Not Set on '}
					</span>
					<span>
						<a
							href={`/application/${applicationId}/clients/${record.userId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{record.name}
						</a>
					</span>
				</div>
			),
		},
		{
			title: 'utilization',
			dataIndex: 'marginInfo.marginUtilization',
			key: 'marginUtilization',
			width: 96,
			render: (text: string) => (
				<div className="flex-container-start">
					<span>
						{`${text}%`}
					</span>
					{Numbers.greaterThan(text, 90)
						&& (
							<Icon name="Warning" colorName="warning" style={{ marginLeft: 8 }} />
						)}
				</div>
			),
		},
		{
			title: 'borrowed amount',
			dataIndex: 'marginInfo.borrowedAmount',
			key: 'borrowedAmount',
			align: 'right',
			width: 160,
			render: (text: string, record: RiskiestBorrowerWithCurrency) => (
				<div>
					{PriceHelpers.formatAmount(text, record.borrowerCurrency || '')}
				</div>
			),
		},
		{
			title: 'loan limit',
			dataIndex: 'marginInfo.loanLimit',
			key: 'loanLimit',
			align: 'right',
			width: 160,
			render: (text: string, record: RiskiestBorrowerWithCurrency) => (
				<div>
					{PriceHelpers.formatAmount(text, record.borrowerCurrency || '', Numbers.sign(text))}
				</div>
			),
		},
		{
			title: 'multiplier',
			dataIndex: 'marginInfo.collateralMultiplier',
			key: 'collateralMultiplier',
			width: 96,
			render: (text: string) => (
				<div>
					{text || '-'}
				</div>
			),
		},
	];
	const walletColumns = [{
		title: 'wallet',
		dataIndex: 'walletInstrument',
		key: 'walletInstrument',
		width: 112,
		render: (text: string) => (
			<div>
				{`${text} Wallet`}
			</div>
		),
	},
	{
		title: 'wallet id',
		dataIndex: 'walletId',
		key: 'walletId',
		width: 160,
	},
	{
		title: 'available',
		dataIndex: 'walletAvailableBalance',
		key: 'walletAvailableBalance',
		align: 'right',
		width: 160,
		render: (text: string, record: Object) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					record.walletInstrument,
				)}
			</div>
		),
	},
	{
		title: 'haircut',
		dataIndex: 'haircut',
		key: 'haircut',
		width: 112,
		render: (text: string) => (
			<div>
				{text === null ? '-' : `${text}%`}
			</div>
		),
	},
	{
		title: 'exchange rate',
		dataIndex: 'exchangeRate',
		key: 'exchangeRate',
		width: 112,
	},
	{
		title: 'wallet collateral',
		dataIndex: 'walletCollateral',
		key: 'walletCollateral',
		align: 'right',
		width: 160,
		render: (text: string) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					drawerData.borrowerCurrency || '',
				)}
			</div>
		),
	}];
	return (
		<>
			<Title
				title="Borrowers"
				searchComponent={{
					placeholder: 'Search For Clients...',
					disabled: true,
				}}
				applicationId={applicationId}
				place="borrowers"
			/>
			<div className="container">
				{!isFetchingRiskiestBorrowers
					? (
						<CustomTable
							columns={columns}
							data={riskiestBorrowers}
							total={riskiestBorrowersTotal}
							handlePageChange={handlePageChange}
							currentPage={borrowersPage}
							onRowAction={handleOpenDrawer}
							headerHeight={128}
							place="borrowers"
							applicationId={applicationId}
							rowClassName="clickable"
						/>
					)
					: (
						<Spin spinning={isFetchingRiskiestBorrowers} />
					)}
				<Drawer
					width={600}
					placement="right"
					onClose={onClose}
					visible={drawerOpen}
					className={styles.margin}
					closable={false}
				>
					<div className={styles.title}>
						<a
							href={`/application/${applicationId}/clients/${drawerData.userId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<h2 className="capitalize">
								{drawerData.user
									? `${drawerData.user.userInfo.firstName || 'Name Not Set'} ${drawerData.user.userInfo.lastName || ''}`
									: 'Name Not Set'} on {drawerData.name}
							</h2>
							<Icon name="Open" />
						</a>
					</div>
					<div>
						<div className={styles.first}>
							<TextCard data={drawerMarginData} colNumber={1} />
						</div>
						<h4>Wallet Breakdown</h4>
						{drawerData.marginInfo && drawerData.marginInfo.walletsBreakdown && (
							<Table
								rowKey={(record) => record.walletId}
								columns={walletColumns}
								dataSource={drawerData.marginInfo.walletsBreakdown}
								locale={{ emptyText: 'No wallets!' }}
								scroll={{
									x: 816,
								}}
								pagination={false}
							/>
						)}
						<h4>Payload</h4>
						<div className={styles.response}>
							<ReactJson
								src={drawerData}
								theme="shapeshifter:inverted"
								enableClipboard={false}
								style={{
									backgroundColor: '#F0F6FA',
									padding: '16px',
								}}
								collapseStringsAfterLength={36}
							/>
						</div>
					</div>
					<div className={styles.close}>
						<CPButton
							text="close"
							action={onClose}
						/>
					</div>
				</Drawer>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	borrowersPage: state.ui.borrowersPage,
	isFetchingRiskiestBorrowers: state.margin.isFetchingRiskiestBorrowers,
	usersTotal: state.users.usersTotal,
	riskiestBorrowers: riskiestBorrowersSelector(state),
	riskiestBorrowersTotal: state.margin.riskiestBorrowersTotal,
});

const mapDispatchToProps = {
	getRiskiestBorrowers,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BorrowersTab);
