// @flow

import {
	GET_WEBHOOKS,
	ADD_WEBHOOK,
	UPDATE_WEBHOOK,
	GET_WEBHOOK_JOB,
	GET_WEBHOOK_JOBS,
	GET_EVENTS,
	GET_ACTIVE_WEBHOOKS,
	GET_INACTIVE_WEBHOOKS,
	DELETE_WEBHOOKS,
} from '../const/types';

export function getWebhooks(applicationId: string, page: number = 1, parametar: string = '') {
	return (dispatch: Function): void => dispatch({
		type: GET_WEBHOOKS,
		payload: {
			request: {
				url: `/applications/${applicationId}/webhooks?${parametar}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}


export function deleteWebhook(applicationId: string, webhookId: string) {
	return (dispatch: Function): void => dispatch({
		type: DELETE_WEBHOOKS,
		payload: {
			request: {
				url: `/applications/${applicationId}/webhooks/${webhookId}`,
				method: 'delete',
			},
		},
	});
}

// TODO: did not need two separate actions for fetching active and inactive webhooks
export function getActiveWebhooks(applicationId: string, page: number = 1, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACTIVE_WEBHOOKS,
		payload: {
			request: {
				url: `/applications/${applicationId}/webhooks?active=true`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function getInactiveWebhooks(applicationId: string, page: number = 1, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: GET_INACTIVE_WEBHOOKS,
		payload: {
			request: {
				url: `/applications/${applicationId}/webhooks?active=false`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function addWebhook(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_WEBHOOK,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/webhooks`,
				data,
			},
		},
	});
}

export function updateWebhook(applicationId: string, webhookId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_WEBHOOK,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/webhooks/${webhookId}`,
				data,
			},
		},
	});
}

export function getWebhookJob(applicationId: string, webhookId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_WEBHOOK_JOB,
		payload: {
			request: {
				url: `/applications/${applicationId}/webhook-history/${webhookId}`,
			},
		},
	});
}

export function getWebhookJobs(applicationId: string, page: number, parametar: string, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: GET_WEBHOOK_JOBS,
		payload: {
			request: {
				url: `/applications/${applicationId}/webhook-history?${parametar}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function getEvents() {
	return (dispatch: Function): void => dispatch({
		type: GET_EVENTS,
		payload: {
			request: {
				url: '/events',
			},
		},
	});
}
