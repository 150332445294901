// @flow

import * as React from 'react';
// import components
import { text } from 'd3';
import Modal from '../../../components/UiElements/Modal';
// import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
// import types
import type { Statement } from '../../../modules/reducers/StatementsReducer';

type Props = {
    cancelFunction: () => void,
    visible: boolean,
	title: string,
	statement: Statement,
	details: {
		companyName: string,
		streetName: string,
		streetNumber: string,
		addressLine2: string,
		zipCode: string,
		state: string,
		country: string,
		contactEmail: string,
		phone: string,
		faxNumber: string,
		website: string,
		},
	logoValue: string,
	}
const PreviewModal = (props: Props) => {
	const {
		cancelFunction,
		visible,
		title,
		statement: {
			headerCompanyAddress,
			headerCompanyEmail,
			headerCompanyLogo,
			headerCompanyPhoneNumber,
			headerCompanyWebsite,
			footer,
			statementAdditionalContent,
		},
		details: {
			streetName,
			streetNumber,
			state,
			country,
			contactEmail,
			phone,
			website,
		},
		logoValue,
	} = props;

	const result = ActivityHelpers.devideText(statementAdditionalContent && statementAdditionalContent.body ? statementAdditionalContent.body : '');

	return (
		<Modal
			title={(
				<div className="flex capitalize">
					<h2>{ActivityHelpers.transformToReadable(title)}</h2>
				</div>
			)}
			visible={visible}
			onCancel={cancelFunction}
			wrapClassName="preview-modal"
			footer={null}
			closable={false}
			centered
		>
			<div className="preview">
				<div>
					{headerCompanyLogo && !!logoValue && <img src={logoValue} alt="logo" />}
					<div className="header">
						<div>
							{headerCompanyAddress && <p>{`${streetName || ''} ${streetNumber || ''} ${state || ''} ${country || ''}`}</p>}
							{headerCompanyEmail && <p>{contactEmail}</p>}
							{headerCompanyPhoneNumber && <p>{phone}</p>}
							{headerCompanyWebsite && <p>{website}</p>}
						</div>
						<div>
							<p>From 2019-01-01 to 2019-01-31</p>
							<p>Isabelle Iglesias</p>
							<p>Account ID: d2cb2ce7</p>
							<p>Water Lane 13, SK9 5AF, United Kingdom</p>
							<p>+1 234 56 78 90</p>
						</div>
					</div>
				</div>
				<div className="table-body" />
				<div className="footer">{footer}</div>
			</div>
			{statementAdditionalContent && statementAdditionalContent.enabled
			&& (
				<div className="preview">
					<div>
						{headerCompanyLogo && !!logoValue && <img src={logoValue} alt="logo" />}
						<div className="header">
							<div>
								{headerCompanyAddress && <p>{`${streetName || ''} ${streetNumber || ''} ${state || ''} ${country || ''}`}</p>}
								{headerCompanyEmail && <p>{contactEmail}</p>}
								{headerCompanyPhoneNumber && <p>{phone}</p>}
								{headerCompanyWebsite && <p>{website}</p>}
							</div>
							<div>
								<p>From 2019-01-01 to 2019-01-31</p>
								<p>Isabelle Iglesias</p>
								<p>Account ID: d2cb2ce7</p>
								<p>Water Lane 13, SK9 5AF, United Kingdom</p>
								<p>+1 234 56 78 90</p>
							</div>
						</div>
					</div>
					<div className="body">
						<h2>{statementAdditionalContent && statementAdditionalContent.title}</h2>
						<div className="body-text">
							<div>{result.firstPart}</div>
							<div>{result.secondPart}</div>
						</div>
					</div>
					<div className="footer">{footer}</div>
				</div>
			)}
			{statementAdditionalContent && text.thirdPart
			&& (
				<div className="preview">
					<div>
						{headerCompanyLogo && !!logoValue && <img src={logoValue} alt="logo" />}
						<div className="header">
							<div>
								{headerCompanyAddress && <p>{`${streetName || ''} ${streetNumber || ''} ${state || ''} ${country || ''}`}</p>}
								{headerCompanyEmail && <p>{contactEmail}</p>}
								{headerCompanyPhoneNumber && <p>{phone}</p>}
								{headerCompanyWebsite && <p>{website}</p>}
							</div>
							<div>
								<p>From 2019-01-01 to 2019-01-31</p>
								<p>Isabelle Iglesias</p>
								<p>Account ID: d2cb2ce7</p>
								<p>Water Lane 13, SK9 5AF, United Kingdom</p>
								<p>+1 234 56 78 90</p>
							</div>
						</div>
					</div>
					<div className="body">
						<h2>{statementAdditionalContent && statementAdditionalContent.title}</h2>
						<div className="body-text">
							<div>{result.thirdPart}</div>
							<div>{result.fourthPart}</div>
						</div>
					</div>
					<div className="footer">{footer}</div>
				</div>
			)}
		</Modal>
	);
};

export default PreviewModal;
