// @flow

import * as React from 'react';
import { connect } from 'react-redux';
//= import componnets
import Tabs from './index';
//= import methods
import { setActiveTab } from '../../../modules/actions/UiActions';
//= import types
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	activeTab: string,
	setActiveTab: (string, string) => void,
	tabs: Array<{
		key: string,
		title: string,
		component: React.Node,
		disabled: boolean,
	}>,
	place: string,
	extraContent: React.Node,
	tabBarStyle: { string: string }
}

const CustomTabs = (props: Props) => {
	const {
		tabs, place, extraContent, activeTab, tabBarStyle,
	} = props;

	const changeActiveTab = (activeKey: string) => {
		props.setActiveTab(`${place}Tab`, activeKey);
	};

	return (
		<Tabs
			tabBarExtraContent={extraContent}
			className="title-tabs"
			activeKey={activeTab}
			onChange={changeActiveTab}
			tabBarStyle={tabBarStyle}
		>
			{tabs.map((tab) => (
				<Tabs.TabPane
					tab={tab.title}
					key={tab.key}
					disabled={tab.disabled}
				>
					{tab.component}
				</Tabs.TabPane>
			))}
		</Tabs>
	);
};

const mapStateToProps = (state: State, props) => ({
	activeTab: state.ui[`${props.place}Tab`],
});

const mapDispatchToProps = {
	setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTabs);
