// @flow
import React, { useState, useCallback, useEffect } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from  'framer-motion/dist/framer-motion';

//= import components
import Form from '../../../../Form';
import CPButton from '../../../../Button';
import Switch from '../../../../Switch';
import QR from './QR';
import OTPInput from './OTPInput';
import { Spin } from 'antd';

//= import actions
import { turnOn2FA, turnOff2FA, check2FA } from '../../../../../../modules/actions/OauthActions';
import FormModal from '../../../../Modal/FormModal';
import Button from '../../../../Button';

type Props = {
	turnOn2FA: () => Promise<Object>,
	turnOff2FA: (string) => Promise<Object>,
	check2FA: () => Promise<Object>,
	handleCancelClick: () => void,
}

const TwoFactorForm = ({
	turnOn2FA: turnOn2FAAction,
	turnOff2FA: turnOff2FAAction,
	check2FA: check2FAAction,
	handleCancelClick,
}: Props) => {
	
	const [otpActiveState, setOtpActiveState] = useState('');
	const [secret, setSecret] = useState('');
	const [isSecretGenerated, setIsSecretGenerated] = useState(false);
	const [input, setInput] = useState('');
	const [isOTPInputDisabled, setIsOTPInputDisabled] = useState(false);
	const [hasErrored, setHasErrored] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [visibleModal, setVisibleModal] = useState(false);

	const checkTwoFactorStatus = useCallback(async () => {
		setIsLoading(true)
		try {
		const response = await check2FAAction()
		response.payload.data.active === true
			? setOtpActiveState('active')
			: setOtpActiveState('inactive');
		setIsLoading(false)
		} catch (error) {
			setOtpActiveState('inactive');
			setIsLoading(false);
		}
	}, [check2FAAction]);

	useEffect(()=> {
		checkTwoFactorStatus()
	}, [checkTwoFactorStatus])

	const closeSuccess = useCallback(() => {
		handleCancelClick();
		setVisibleModal(false);

	}, [handleCancelClick])

	const collectInput = useCallback(async (input) => {
		setInput(input)
		setErrorMessage('');
		setIsOTPInputDisabled(false);
		setHasErrored(false);
		if (input.length === 6) {
			setIsOTPInputDisabled(true);
			try {
        		await turnOff2FAAction(input)
				setVisibleModal(true);
			} catch(error) {
					const TOKEN_FORMAT_ERROR = error.error.response.data.errors?.[0].messages?.[0].message;
					const INVALID_TOKEN_ERROR = error.error.response.data.message;
					if (TOKEN_FORMAT_ERROR) setErrorMessage(TOKEN_FORMAT_ERROR);
					if (INVALID_TOKEN_ERROR) setErrorMessage(INVALID_TOKEN_ERROR);
					setHasErrored(true);
					setInput('');
					setIsOTPInputDisabled(false);
				}
		}
	}, [turnOff2FAAction]);

	const generateSecret = useCallback(async () => {
			if (!isSecretGenerated) {
				const res = await turnOn2FAAction()
				setSecret(res.payload.data.secret);
				setIsSecretGenerated(true);
				return;
			}
			setIsSecretGenerated(false);
	}, [isSecretGenerated, turnOn2FAAction]);
	return (
		<Form onSubmit={() => {}} layout="horizontal" hideRequiredMark labelalign="left">
			{otpActiveState === 'active'
			&& (
				<AnimatePresence>
				<motion.div
					animate={{ height: '260px'}}
					exit={{height: '0px', opacity: 0}}
					initial={{height: '0px'}}>
					<div className='active-2fa'>
						<h3 className='p'>Two-factor authentication active!</h3>
						<p className='p'>Enter your six-digit token to turn off</p>
						<OTPInput
							collectInput={collectInput}
							token={input}
							isDisabled={isOTPInputDisabled}
							hasErrored={hasErrored}
							errorMessage={errorMessage}
							inputStyle='otp-deactivate'
							containerStyle='otp-deactivate-container'
						/>
					</div>
					<div className='cancel-2fa'>
						<CPButton
							text='Cancel'
							type='default'
							action={handleCancelClick}
						/>
					</div>
					</motion.div>
				</AnimatePresence>
			)}

			{ (otpActiveState === 'inactive' && !isSecretGenerated)
			&& (
				<AnimatePresence>
					<motion.div
						className='toggle'
						animate={{ height: '150px'}}
						exit={{height: '0px', opacity: 0}}
						initial={{height: '0px'}}>
						<h3>Two-factor authentication inactive</h3>
						<div className='otp-generate-switch'>
							<p className='p'>Generate QR Code? </p>
							<Switch
								checked={isSecretGenerated || false}
								onChange={generateSecret}
								checkedChildren={<CheckOutlined />}
								unCheckedChildren={<CloseOutlined />}
							/>
						</div>
						<div className='cancel-2fa'>
							<CPButton
								text='Cancel'
								type='default'
								action={handleCancelClick}
							/>
						</div>
					</motion.div>
				</AnimatePresence>
			)}
			{isLoading &&
			<Spin wrapperClassName='two-factor-spin'>
					<div className='two-factor-spin'>
					</div>
				</Spin>
			}
            <FormModal
                    title='Two-factor authentication turned off!'
                    visible={visibleModal}
                    form={
                        <Button
                            type='primary'
                            text='Dismiss'
                            action={closeSuccess}
                        />
                    }
                    cancelFunction={closeSuccess}
            />
			<AnimatePresence>
				{isSecretGenerated && (
					<motion.div
						className='qr-code-wrapper'
						animate={{ height: '300px'}}
						exit={{height: '0px', opacity: 0}}
						initial={{height: '0px'}}>
						<QR secret={secret} closeModal={handleCancelClick}/>
					</motion.div>
				)}
			</AnimatePresence>
		</Form>
	);
};

const mapDispatchToProps = {
	turnOn2FA,
	turnOff2FA,
	check2FA,
}
export default Form.create()(connect(null, mapDispatchToProps)(withRouter(TwoFactorForm)));
