// @flow

import React from 'react';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Card from '../../../components/UiElements/Card';
import Avatar from '../../../components/UiElements/Avatar';
//= import images
import b2c2 from '../../../assets/img/providers/provider_b2c2.svg';
import bitstamp from '../../../assets/img/providers/provider_bitstamp.svg';
import blockCypher from '../../../assets/img/providers/provider_blockcypher.svg';
import branch from '../../../assets/img/providers/provider_branch.svg';
import coinapiio from '../../../assets/img/providers/provider_coinapi.svg';
import coinpulse from '../../../assets/img/providers/provider_coinpulse.png';
import complyAdvantage from '../../../assets/img/providers/provider_complyadvantage.svg';
import cryptoCompare from '../../../assets/img/providers/provider_cryptocompare.svg';
import currencyLayer from '../../../assets/img/providers/provider_currencylayer.svg';
import customerIo from '../../../assets/img/providers/provider_customerio.svg';
import iex from '../../../assets/img/providers/provider_iexcloud.svg';
import interactivebrokers from '../../../assets/img/providers/provider_interactivebrokers.svg';
import onesignal from '../../../assets/img/providers/provider_onesignal.svg';
import onfido from '../../../assets/img/providers/provider_onfido.svg';
import quickpay from '../../../assets/img/providers/provider_quickpay.svg';
import s3 from '../../../assets/img/providers/provider_s3.svg';
import safecharge from '../../../assets/img/providers/provider_safecharge.svg';
import segment from '../../../assets/img/providers/provider_segment.svg';
import bcLogo from '../../../assets/img/company_icon_dark.png';
import tipRanks from '../../../assets/img/providers/provider_tipRanks.jpg';
import interactiveBrokers from '../../../assets/img/providers/provider_interactiveBrokers.jpg';
import modulr from '../../../assets/img/providers/provider_modulr.png';
import trapets from '../../../assets/img/providers/provider_trapets.png';
import tribe from '../../../assets/img/providers/provider_tribe.png';
import fireblocks from '../../../assets/img/providers/provider_fireblocks.png';
import clearJunction from '../../../assets/img/providers/provider_clearjunction.png';
import bitFuryCrystal from '../../../assets/img/providers/provider_bitFuryCrystal.png';
import reyn from '../../../assets/img/providers/provider_reyn.png';
//= imort styles
import styles from '../assets/providers.module.scss';

type Props = {
	displayName: string,
	name: string,
	providerInstanceTypes: Array<string>,
	configure: () => void,
	cancelFunction: () => void,
}
const imageMap = {
	b2c2,
	bitstamp,
	blockCypher,
	branch,
	coinapiio,
	complyAdvantage,
	cryptoCompare,
	currencyLayer,
	customerIo,
	iex,
	interactivebrokers,
	onesignal,
	onfido,
	quickpay,
	s3,
	safecharge,
	segment,
	coinpulse,
	tipRanks,
	interactiveBrokers,
	modulr,
	trapets,
	fireblocks,
	clearJunction,
	bitFuryCrystal,
	reyn,
	tribe,
};

const SharedCard = (props: Props) => {
	const {
		name,
		displayName,
		cancelFunction,
		configure,
		providerInstanceTypes,
	} = props;
	const { Meta } = Card;
	const title = (
		<div className={styles.logo}>
			<div className={styles.center}>
				<img src={bcLogo} alt="bc" className={styles.bcLarge} />
				<span>+</span>
				{imageMap[name]
					? <Avatar src={imageMap[name]} size={45} className={styles.dot} />
					: <img src={bcLogo} alt="bc" className={styles.bcLarge} />}
			</div>
			<div className={styles.center}>
				<h2>BrokerCloud</h2>
				<h2>{displayName}</h2>
			</div>
		</div>
	);
	const text = (
		<div>
			<p>{` We always aim to bring you the best experience.
				That's why we partnered up with ${displayName} to get some incredible deals for our loyal customers.`}
			</p>
			<p>
				{`Contact Support to find out how to configure BrokerCloud ${displayName} and save money.`}
			</p>
		</div>
	);
	return (
		<div>
			<Card
				bordered={false}
				className={styles.shared}
			>
				<Meta
					title={title}
					description={text}
				/>
			</Card>
			{providerInstanceTypes.includes('application')
				&& (
					<div className={styles.action}>
						<p>{`Or you can configure ${displayName} on your own`}</p>
						<CPButton
							ghost
							action={configure}
							text="here"
						/>
					</div>
				)}

			<div className={styles.cancel}>
				<CPButton
					ghost
					action={cancelFunction}
					text="cancel"
				/>
			</div>
		</div>
	);
};

export default SharedCard;
