// @flow

import React, {
	useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import CPButton from '../../../components/UiElements/Button';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Tabs from '../../../components/UiElements/Tabs';
// import Popover from '../../../components/UiElements/Popover';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import UsersTab from '../components/UsersTab';
import SymbolsTab from '../components/SymbolsTab';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import GroupForm from '../components/GroupForm';
import TransferForm from '../components/TransferForm';
import AddSymbolForm from '../components/AddSymbolForm';
//= import methods
import {
	getGroup, getGroups, deleteGroup, updateGroup, moveUsersToGroup,
} from '../../../modules/actions/GroupAction';
import { getUsers } from '../../../modules/actions/UsersActions';
import { getGroupSymbols, addGroupSymbols, updateGroupSymbol } from '../../../modules/actions/SymbolActions';
import { setActiveTab } from '../../../modules/actions/UiActions';
//= import types
import type { Group } from '../../../modules/reducers/GroupReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getGroup: (string, string) => Promise<Object>,
	getGroups: (string) => Promise<Object>,
	deleteGroup: (string, string) => Promise<Object>,
	updateGroup: (string, string, Object) => Promise<Object>,
	getGroupSymbols: (string) => Promise<Object>,
	setActiveTab: (string, string) => void,
	getUsers: (string, number, number, string) => Promise<Object>,
	moveUsersToGroup: (string, string) => Promise<Object>,
	addGroupSymbols: (string, Object) => Promise<Object>,
	group: Group,
	isFetchingGroup: boolean,
	groups: Array<Group>,
	activeTab: string,
	match: {
		params: {
			applicationId: string,
			groupId: string,
		},
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}
type GroupRes = {
	payload: {
		data: Array<Group>,
	}
}
type TotalRes = {
	payload: {
		headers: {
			'total-count': number,
		},
		data: Array<Symbol>,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}
const GroupDetails = (props: Props) => {
	const {
		group,
		isFetchingGroup,
		activeTab,
		groups,
		getGroups: getGroupsAction,
		getGroup: getGroupAction,
		getUsers: getUsersAction,
		setActiveTab: setActiveTabAction,
		deleteGroup: deleteGroupAction,
		updateGroup: updateGroupAction,
		getGroupSymbols: getGroupSymbolsAction,
		addGroupSymbols: addGroupSymbolsAction,
		moveUsersToGroup: moveUsersToGroupAction,
		match: { params: { applicationId, groupId } },
		history,
	} = props;

	const [deleteMessage, setDeleteMessage] = useState('');
	const [footer, setFooter] = useState([]);
	const [deleteClicked, setDeleteClicked] = useState(false);
	const [btnText, setBtnText] = useState('');
	const [message, setMessage] = useState({});
	const [warningTitle, setWarningTitle] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [edit, setEdit] = useState(false);
	const [defaultGroup, setDefaultGroup] = useState('');
	const [transfer, setTransfer] = useState(false);
	const [resetForm, setResetForm] = useState(false);
	const [addSymbol, setAddSymbol] = useState(false);
	const [groupUsersTotal, setGroupUsersTotal] = useState();
	const [groupSymbolsTotal, setGroupSymbolsTotal] = useState();
	const [userMoved, setUserMoved] = useState(false);
	const [symbolAdded, setSymbolAdded] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getGroupsAction(applicationId)
			.then((res: GroupRes) => {
				const groupName: string = res.payload.data.filter((el) => el.default)[0].name;
				setDefaultGroup(groupName);
			});
	}, [applicationId, getGroupsAction]);

	useEffect(() => {
		getGroupAction(applicationId, groupId);
		return () => setActiveTabAction('groupTab', 'users');
	}, [applicationId, getGroupAction, groupId, setActiveTabAction]);

	useEffect(() => {
		getUsersAction(applicationId, 1, 50, queryString.stringify({ groupId }))
			.then((res: TotalRes) => setGroupUsersTotal(res.payload.headers['total-count']));
		getGroupSymbolsAction(groupId)
			.then((res: TotalRes) => setGroupSymbolsTotal(res.payload.headers['total-count']));
	}, [applicationId, groupId, getUsersAction, getGroupSymbolsAction]);

	const formRef = useRef(null);
	const transferFormRef = useRef(null);
	const addSymbolFormRef = useRef(null);

	const handleBackClick = () => {
		history.goBack();
	};

	const changeActiveTab = (activeKey: string) => {
		setActiveTabAction('groupTab', activeKey);
	};

	// Delete group

	const handleDeleteClick = () => {
		setDeleteClicked(true);
		setDeleteMessage(`Once you delete the ${group.name} Group
			you won't be able to retrieve it.`);
		setBtnText('Delete Group');
	};

	const handleTransferErrorCancel = () => {
		setWarningModal(false);
		setResetForm(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response ? error.error.response.data.message : 'Error' });
		setFooter([{ type: 'cancel', action: handleTransferErrorCancel, text: 'close' }]);
	};

	const deleteUserGroup = () => {
		setButtonLoading(true);
		setDeleteClicked(false);
		deleteGroupAction(applicationId, groupId)
			.then(() => history.push(`/application/${applicationId}/system/groups`))
			.catch((error: ErrorType) => handleOnError(error));
	};

	const handleDeleteCancel = () => {
		setDeleteClicked(false);
		setWarningModal(false);
	};

	// Edit group

	const handleEditClick = () => {
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const submitEditGroup = (data: string) => {
		setButtonLoading(true);
		updateGroupAction(applicationId, group.id, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const continueEditing = (value: string) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect ${group.name} Group.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEditGroup(value), text: 'edit group' },
		]);
	};

	// Move Users

	const handleTransferClicked = () => {
		setTransfer(true);
		setResetForm(true);
	};

	const handleWarningTransferCancel = () => {
		setTransfer(true);
		setWarningModal(false);
	};

	const handleTransferCancel = () => {
		if (transferFormRef.current && transferFormRef.current.props.form.isFieldsTouched()) {
			setTransfer(false);
			setWarningModal(true);
			setWarningTitle('Warning');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleTransferErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningTransferCancel, text: 'continue transfer' },
			]);
		} else {
			setTransfer(false);
			setWarningModal(false);
			setResetForm(false);
		}
	};

	const submitTransfer = (userId: string, data: string) => {
		setButtonLoading(true);
		setUserMoved(false);
		moveUsersToGroupAction(userId, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
				setResetForm(false);
				setUserMoved(true);
				setGroupUsersTotal(parseInt(groupUsersTotal, 10) - 1);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueTransfer = (userId: string, userName: string, groupName: string, data: string) => {
		setTransfer(false);
		setWarningModal(true);
		setWarningTitle('Are you sure?');
		setMessage({ firstPart: `${userName} will be transferred from ${group.name} to ${groupName} Group.` });
		setFooter([
			{ type: 'cancel', action: handleWarningTransferCancel, text: 'go back' },
			{ type: 'continue', action: () => submitTransfer(userId, data), text: 'transfer client' },
		]);
	};

	// Add Symbol);
	const handleAddSymbolClicked = () => {
		setAddSymbol(true);
		setResetForm(true);
	};

	const handleAddSymbolErrorCancel = () => {
		setWarningModal(false);
		setResetForm(false);
	};

	const handleWarningAddSymbolCancel = () => {
		setAddSymbol(true);
		setWarningModal(false);
	};

	const handleAddSymbolCancel = () => {
		if ((addSymbolFormRef.current && addSymbolFormRef.current.props.form.isFieldsTouched())
			|| (addSymbolFormRef.current && addSymbolFormRef.current.state.selectedKey.length)) {
			setAddSymbol(false);
			setWarningModal(true);
			setWarningTitle('Warning');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleAddSymbolErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningAddSymbolCancel, text: 'continue editing' },
			]);
		} else {
			setAddSymbol(false);
			setWarningModal(false);
			setResetForm(false);
		}
	};

	const handleOnAddSymbolError = (error: ErrorType) => {
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response ? error.error.response.data.message : 'Error' });
		setFooter([{ type: 'cancel', action: handleAddSymbolErrorCancel, text: 'close' }]);
	};

	const submitAddSymbol = (symbolId) => {
		setButtonLoading(true);
		setSymbolAdded(false);
		addGroupSymbolsAction(group.id, { symbolId })
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
				setResetForm(false);
				setSymbolAdded(true);
				setGroupSymbolsTotal(parseInt(groupSymbolsTotal, 10) + 1);
			})
			.catch((error: ErrorType) => handleOnAddSymbolError(error));
	};

	const continueAddSymbol = (symbolId: string) => {
		setAddSymbol(false);
		setWarningModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `New Group Symbol ${symbolId} will be created in Group ${group.name}.`,
			secondPart: 'Go to Symbol Details to continue editing the group symbol.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningAddSymbolCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAddSymbol(symbolId), text: 'add group symbol' },
		]);
	};

	const descriptionData = {
		groupDescription: group.description || '-',
	};
	const usersData = {
		clientsInGroup: groupUsersTotal || '-',
	};
	const symbolsData = {
		symbolsInGroup: groupSymbolsTotal || '-',
	};
	const usersButton = (
		<CPButton
			ghost
			action={handleTransferClicked}
			icon="InternalTransaction"
			text="transfer group clients"
		/>
	);

	const symbolsButton = (
		<CPButton
			ghost
			action={handleAddSymbolClicked}
			icon="Plus"
			text="add group symbol"
		/>
	);

	return (
		<>
			{!isFetchingGroup
				? (
					<div>
						<Title
							backAction={handleBackClick}
							title={group.name}
							tags={group.default && ['default']}
							buttons={[
								{
									action: handleEditClick,
									text: 'edit group details',
								},
								{
									action: handleDeleteClick,
									disabled: group.default || parseInt(groupUsersTotal, 10) !== 0,
									type: 'danger',
									text: 'delete group',
								},
							]}
						/>
						<div className="page-container">
							<div className="group-cards">
								<TextCard data={descriptionData} colNumber={1} left="left-2" />
								{activeTab === 'users'
									? <InfoCard data={usersData} />
									: <InfoCard data={symbolsData} />}
							</div>
							<div>
								<Tabs
									tabBarExtraContent={activeTab === 'users' ? usersButton : symbolsButton}
									type="card"
									className="tabs"
									activeKey={activeTab}
									onChange={changeActiveTab}
								>
									<Tabs.TabPane
										tab="CLIENTS"
										key="users"
									>
										<UsersTab
											groupId={group.id}
											applicationId={applicationId}
											update={userMoved}
											groupUsersTotal={groupUsersTotal}
										/>
									</Tabs.TabPane>
									<Tabs.TabPane
										tab="SYMBOLS"
										key="symbols"
									>
										<SymbolsTab
											groupId={group.id}
											applicationId={applicationId}
											symbolAdded={symbolAdded}
											groupSymbolsTotal={groupSymbolsTotal}
										/>
									</Tabs.TabPane>
								</Tabs>
							</div>
							<WarningModal
								title={warningTitle}
								visible={warningModal}
								cancelFunction={transfer ? handleTransferCancel : handleDeleteCancel}
								footer={footer}
								message={message}
								loading={buttonLoading}
							/>
							<DeleteModal
								okFunction={deleteUserGroup}
								cancelFunction={handleDeleteCancel}
								visible={deleteClicked}
								btnText={btnText}
								message={deleteMessage}
							/>
							{resetForm && (
								<FormModal
									title="Transfer Group Client"
									visible={transfer}
									cancelFunction={handleTransferCancel}
									form={(
										<TransferForm
											submitChanges={continueTransfer}
											handleCancelClick={handleTransferCancel}
											applicationId={applicationId}
											groupId={group.id}
											wrappedComponentRef={transferFormRef}
											groups={groups}
										/>
									)}
								/>
							)}
							{resetForm && (
								<FormModal
									title="Add New Group Symbol"
									visible={addSymbol}
									cancelFunction={handleAddSymbolCancel}
									form={(
										<AddSymbolForm
											submitChanges={continueAddSymbol}
											handleCancelClick={handleAddSymbolCancel}
											applicationId={applicationId}
											wrappedComponentRef={addSymbolFormRef}
											groupName={group.name}
											groupId={groupId}
										/>
									)}
								/>
							)}
							<FormModal
								title={`Edit Group ${group.name}`}
								visible={edit}
								cancelFunction={handleEditCancel}
								form={(
									<GroupForm
										submitChanges={continueEditing}
										handleCancelClick={handleEditCancel}
										applicationId={applicationId}
										wrappedComponentRef={formRef}
										group={group}
										defaultGroup={defaultGroup}
									/>
								)}
							/>
						</div>
					</div>
				)
				: <Spin spinning={isFetchingGroup} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	group: state.group.group,
	isFetchingGroup: state.group.isFetchingGroup,
	groups: state.group.groups,
	activeTab: state.ui.groupTab,
});

const mapDispatchToProps = {
	getGroup,
	getGroupSymbols,
	setActiveTab,
	deleteGroup,
	updateGroup,
	getGroups,
	moveUsersToGroup,
	getUsers,
	addGroupSymbols,
	updateGroupSymbol,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
