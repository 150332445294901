// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
//= import componnets
import FormModal from '../../components/UiElements/Modal/FormModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
import Title from '../../components/UiElements/Title';
import ReasonsList from './components/ReasonsList';
import ReasonForm from './components/ReasonForm';
//= import methods
import {
	getSuspendReasons, addSuspendReason, updateSuspendReason,
} from '../../modules/actions/UsersActions';
//= import types
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	suspendReasons: Array<Object>,
	getSuspendReasons: (string) => void,
	updateSuspendReason: (string, string, Object) => Promise<Object>,
	addSuspendReason: (string, Object) => Promise<Object>,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

const SuspendReasons = (props: Props) => {
	const {
		match: { params: { applicationId } },
		history: { goBack },
		suspendReasons,
		getSuspendReasons: getSuspendReasonsAction,
		updateSuspendReason: updateSuspendReasonAction,
		addSuspendReason: addSuspendReasonAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [type, setType] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [reasonToEdit, setReasonToEdit] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		getSuspendReasonsAction(applicationId);
	}, [
		applicationId,
		getSuspendReasonsAction,
	]);

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	// create new fee

	const handleCreateClick = () => {
		setEdit(true);
		setType('add');
		setReasonToEdit({});
	};

	const submitAddReason = (data: Object) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		const feeData = { ...data };
		addSuspendReasonAction(applicationId, feeData)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const submitEditReason = (data) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		updateSuspendReasonAction(applicationId, reasonToEdit.id, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueEditing = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect Suspend Reason.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => (type === 'add' ? submitAddReason(value) : submitEditReason(value)), text: `${type} Suspend Reason` },
		]);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const setReason = (reason: Object) => {
		setReasonToEdit(reason);
		setType('edit');
		setEdit(true);
	};

	const handleBackClick = () => {
		goBack();
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Suspend Reasons"
				buttons={[
					{
						text: 'Add New Suspend Reasons',
						icon: 'Plus',
						action: handleCreateClick,
					},
				]}
			/>
			<div className="container">
				<ReasonsList
					reasons={suspendReasons}
					reasonsTotal={suspendReasons.length}
					setReason={setReason}
					applicationId={applicationId}
					currentPage={1}
				/>
				<FormModal
					title={`${type} Suspend Reason ${reasonToEdit.title || ''}`}
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<ReasonForm
							submitChanges={continueEditing}
							handleCancelClick={handleEditCancel}
							reason={reasonToEdit}
							key={reasonToEdit.id}
							wrappedComponentRef={formRef}
							applicationId={applicationId}
							type={type}
						/>

					)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	suspendReasons: state.users.suspendReasons,
});

const mapDispatchToProps = {
	getSuspendReasons,
	updateSuspendReason,
	addSuspendReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuspendReasons);
