// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import componnets
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import Card from '../../../components/UiElements/Card';
//= import types
import type { ApplicationSettings } from '../../../modules/reducers/ApplicationReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	appSettings: ApplicationSettings,
}

const MarginInterest = (props: Props) => {
	const { appSettings } = props;

	const interestRate = {
		annualInterestRate: `${appSettings.annualInterestRate}%`,
	};
	const accrualPeriod = {
		accrualPeriod: appSettings.accrualPeriod ? appSettings.accrualPeriod.toUpperCase() : '-',
	};
	const compoundPeriod = {
		compoundPeriod: appSettings.compoundPeriod ? appSettings.compoundPeriod.toUpperCase() : '-',
	};

	return (
		<div>
			<div className="page-container">
				<div className="fee-cards trading-fee-cards">
					<InfoCard data={interestRate} />
					<InfoCard data={accrualPeriod} />
					<InfoCard data={compoundPeriod} />
				</div>
				<div>
					<Card bordered={false} className="fee-rules">
						<h2>Set Application Margin Interest Rate</h2>
						<p>
							Annual interest rate value has to be between 0 and 100 (%).
						</p>
						<p>
							Accrual period per which interest will be accrued,
							has to be set to Day, Week or Month.
						</p>
						<p>
							Compound period per which the accrued interest will be applied to the principal,
							has to be set to Day, Week, Month.
							(Compound period can not be lesser than the Accrual period).
						</p>
						<p>
							You can set margin interest rate per Client, from the Client settings.
							If it is not set for a single Client,
							it will be inherited from the application margin interest rate.
						</p>
					</Card>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	appSettings: state.application.applicationSettings,
});

export default connect(mapStateToProps)(MarginInterest);
