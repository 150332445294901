// @flow

import type { State } from '../types/FlowTypes';
import type { RiskiestBorrower, AccountMargin } from '../reducers/MarginReducer';

export type RiskiestBorrowerWithCurrency = {
    ...RiskiestBorrower,
	borrowerCurrency: string,
}

export type AccountMarginWithCurrency = {
    ...AccountMargin,
	accountCurrency: string,
}

export const riskiestBorrowersSelector = (state: State): Array<RiskiestBorrower> => (
	state.margin.riskiestBorrowers
		.map((borrower) => ({
			...borrower,
			borrowerCurrency: borrower.marginInfo.walletsBreakdown
				.filter((wallet) => wallet.exchangeRate === '1').length
				? borrower.marginInfo.walletsBreakdown
					.filter((wallet) => wallet.exchangeRate === '1')[0].walletInstrument
				: '',
		})));

export const accountMarginSelector = (state: State): AccountMarginWithCurrency => ({
	...state.margin.accountMargin,
	accountCurrency: state.margin.accountMargin.walletsBreakdown
		? state.margin.accountMargin.walletsBreakdown
			.filter((wallet) => wallet.exchangeRate === '1')[0].walletInstrument
		: '',
	walletsBreakdown: state.margin.accountMargin.walletsBreakdown
		? state.margin.accountMargin.walletsBreakdown.map((wallet) => ({
			...wallet,
			id: wallet.walletId,
		}))
		: [],
});
