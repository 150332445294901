// @flow

import { createSelector } from 'reselect';
import type { State } from '../types/FlowTypes';
import type { User, FullUser } from '../reducers/UsersReducer';

export type UserMapped = {
	id: string,
	name: string,
	email: string,
	status: string,
	country: string,
	registrationDate: string,
	groupName: string,
	createdAt: string,
	country: string,
};

const fullUserSelector = (state: State): FullUser => state.users.fullUser;
export const countryMappedSelector = (state: State) => state.static.countries.reduce(
	(prev, curr) => ({
		...prev,
		[curr.id]: curr,
	}), {},
);

export const fullUserMappedSelector = createSelector(
	fullUserSelector,
	(fullUser: User): User => ({
		...fullUser,
		name: fullUser.userInfo
			? `${(fullUser.userInfo.firstName || fullUser.userInfo.lastName) ? (fullUser.userInfo.firstName || '') : 'Name Not Set'} ${fullUser.userInfo.lastName || ' '}`
			: 'Name Not Set',
		initials: fullUser.userInfo && (fullUser.userInfo.firstName || fullUser.userInfo.lastName)
			? `${fullUser.userInfo.firstName ? fullUser.userInfo.firstName.charAt(0) : ''}${fullUser.userInfo.lastName ? fullUser.userInfo.lastName.charAt(0) : ''}`
			: '',
	}),
);
