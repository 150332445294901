// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Card from '../../../components/UiElements/Card';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import Filter from '../../../components/UiElements/Filter';
import CPButton from '../../../components/UiElements/Button';
import AssociateForm from '../components/AssociateForm';
import PayoutForm from '../components/PayoutForm';
import BankPayments from '../components/BankPayments';
import BankInfoCard from '../components/BankInfoCard';
//= import actions
import {
	getCustomerBankAccount,
	editCustomerBankAccount,
	getSchemes,
	customerPayout,
	getClientBankAccount,
	getBankAccountPayments,
	simulateCustomerPayment,
} from '../../../modules/actions/BankActions';
import { getUser } from '../../../modules/actions/UsersActions';
import { getWallet } from '../../../modules/actions/WalletActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { BankAccountType, PaymentType } from '../../../modules/reducers/BankReducer';
import type { FullUser } from '../../../modules/reducers/UsersReducer';
import type { Wallet } from '../../../modules/reducers/WalletReducer';
//= import styles
import styles from '../assets/banking.module.scss';

type Props = {
	getCustomerBankAccount: (string) => void,
	editCustomerBankAccount: (string, Object) => Promise<Object>,
	getSchemes: (string: string) => Promise<Object>,
	customerPayout: (string, string, Object) => Promise<Object>,
	getBankAccountPayments: (string, number, string) => void,
	simulateCustomerPayment: (string, string, Object) => Promise<Object>,
	bankAccount: BankAccountType,
	isFetchingBankAccount: boolean,
	payments: Array<PaymentType>,
	accountPaymentsTotal: number,
	isFetchingBankAccountPayments: boolean,
	getClientBankAccount: (string) => Promise<Object>,
	getUser: (string) => void,
	getWallet: (string) => void,
	user: FullUser,
	wallet: Wallet,
	currentFilters: number,
	currentPage: number,
	clientFilters: {[string]: string},
	setCurrentPage: (string, number) => void,
	match: {
		params: {
			applicationId: string,
			accountId: string,
		}
	},
	location: {
		search: string,
	},
}
type FormValue = {
	provider: string,
	externalId: string,
    name: string,
    description: string,
    instrumentId: string,
    isCollection: true,
	isPayout: true,
	data: {[string]: string},
	schemeName: string,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

function AccountPage(props: Props) {
	const {
		getCustomerBankAccount: getCustomerBankAccountActions,
		getClientBankAccount: getClientBankAccountActions,
		editCustomerBankAccount: editCustomerBankAccountAction,
		getSchemes: getSchemesAction,
		customerPayout: customerPayoutAction,
		getBankAccountPayments: getBankAccountPaymentsAction,
		setCurrentPage: setCurrentPageAction,
		simulateCustomerPayment: simulateCustomerPaymentAction,
		getUser: getUserAction,
		getWallet: getWalletAction,
		bankAccount,
		isFetchingBankAccount,
		payments,
		accountPaymentsTotal,
		isFetchingBankAccountPayments,
		user,
		wallet,
		currentPage,
		currentFilters,
		match: { params: { applicationId, accountId } },
		location: { search },
	} = props;

	const [openModal, setOpenModal] = useState(false);
	const [type, setType] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [success, setSuccess] = useState(false);
	const [payoutSubmitted, setPayoutSubmitted] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [date, setDate] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [schemes, setSchemes] = useState([]);

	const formRef = useRef(null);
	const place: string = search ? queryString.parse(search).place : '';

	useEffect(() => {
		async function getClientData() {
			const accRes = await getClientBankAccountActions(accountId);
			getUserAction(accRes.payload.data.userBankAccount.bankUser?.userId);
			getWalletAction(accRes.payload.data.userBankAccount.walletId);
		}
		if (place === 'customer') {
			getCustomerBankAccountActions(accountId);
		} else {
			getClientData();
		}
	}, [
		accountId,
		applicationId,
		place,
		getCustomerBankAccountActions,
		getClientBankAccountActions,
		getBankAccountPaymentsAction,
		getUserAction,
		getWalletAction,
	]);

	useEffect(() => {
		getBankAccountPaymentsAction(accountId, currentPage, queryString.stringify(currentFilters, { arrayFormat: 'comma' }));
	}, [
		getBankAccountPaymentsAction,
		accountId,
		currentPage,
		currentFilters,
		payoutSubmitted,
	]);

	useEffect(() => {
		async function getData() {
			const res = await getSchemesAction(accountId);
			setSchemes(res?.payload?.data);
		}
		getData();
	}, [
		getSchemesAction,
		accountId,
	]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('bankAccountPaymentsPage', page);
	};

	const handleEditClick = () => {
		setOpenModal(true);
		setSuccess(false);
		setType('edit');
	};

	const handlePayout = () => {
		setOpenModal(true);
		setSuccess(false);
		setType('payout');
	};

	const handlePayin = () => {
		setOpenModal(true);
		setSuccess(false);
		setType('payin');
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response.data?.message,
			secondPart: printError(error.error.response.data?.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleOnSuccess = () => {
		setWarningModal(true);
		setWarningTitle('Success');
		setMessage({
			firstPart: type === 'edit'
				? "Congrats. You've successfully edited bank accounts."
				: 'The payment has been sent to the execution.',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setOpenModal(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setOpenModal(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setOpenModal(false);
			setWarningModal(false);
		}
	};

	const submitEdit = async (value: FormValue) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		if (type === 'edit') {
			const data = {
				externalId: value.externalId,
				name: value.name,
				description: value.description,
				instrumentId: value.instrumentId,
				isCollection: value.isCollection,
				isPayout: value.isPayout,
			};
			try {
				await editCustomerBankAccountAction(accountId, data);
				handleOnSuccess();
				setButtonLoading(false);
				setSuccess(true);
			} catch (error) {
				setSuccess(false);
				handleOnError(error);
			}
		} else if (type === 'payout') {
			const { data } = value;
			const { schemeName } = value;
			try {
				await customerPayoutAction(accountId, schemeName, data);
				handleOnSuccess();
				setButtonLoading(false);
				setSuccess(true);
			} catch (error) {
				setSuccess(false);
				handleOnError(error);
			} finally {
				setPayoutSubmitted(!payoutSubmitted);
			}
		} else {
			const { data } = value;
			const { schemeName } = value;
			try {
				await simulateCustomerPaymentAction(accountId, schemeName, data);
				handleOnSuccess();
				setButtonLoading(false);
				setSuccess(true);
			} catch (error) {
				setSuccess(false);
				handleOnError(error);
			} finally {
				setPayoutSubmitted(!payoutSubmitted);
			}
		}
	};

	const continueEdit = (value: FormValue) => {
		setWarningModal(true);
		setOpenModal(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect Customer Bank Account.`,
			secondPart: 'It is advised to proceed with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{
				type: 'continue',
				action: () => submitEdit(value),
				text: type === 'edit' ? 'associate customer bank account' : type,
			},
		]);
	};

	const additionalBtn = process.env.REACT_APP_ENV === 'sandbox'
		? [{
			action: handlePayin,
			text: 'simulate payment',
			icon: 'ArrowDown',
			disabled: (!['GBP', 'EUR'].includes(bankAccount.instrumentId) && !(bankAccount.provider?.name === 'modulr')) || !bankAccount.id,
		}]
		: [];
	const userData = bankAccount.userBankAccount && user.id
		? [{
			client: (
				<a
					href={`/application/${applicationId}/clients/${bankAccount.userBankAccount.bankUser?.userId}?tab=user_bank_accounts`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{`${user.userInfo.firstName} ${user.userInfo.lastName}`}
				</a>
			),
			account: (
				<a
					href={`/application/${applicationId}/clients/${bankAccount.userBankAccount.bankUser?.userId}?accountId=${bankAccount.userBankAccount.accountId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{user.accounts.find((acc) => acc.id === bankAccount.userBankAccount.accountId)?.name}
				</a>
			),
			wallet: (
				<a
					href={`/application/${applicationId}/clients/${bankAccount.userBankAccount.bankUser?.userId}/user_wallets/${bankAccount.userBankAccount.walletId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{bankAccount.userBankAccount.walletId}
				</a>
			),
		}] : [{
			client: (
				<a
					href={`/application/${applicationId}/clients/${bankAccount.userBankAccount?.bankUser.userId}?tab=user_bank_accounts`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{bankAccount.userBankAccount?.bankUser.userId}
				</a>
			),
			account: (
				<a
					href={`/application/${applicationId}/clients/${bankAccount.userBankAccount?.bankUser.userId}?accountId=${bankAccount.userBankAccount?.accountId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{bankAccount.userBankAccount?.accountId}
				</a>
			),
			wallet: (
				<a
					href={`/application/${applicationId}/clients/${bankAccount.userBankAccount?.bankUser.userId}/user_wallets/${bankAccount.userBankAccount?.walletId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{bankAccount.userBankAccount?.walletId}
				</a>
			),
		}];

	return (
		<>
			{!isFetchingBankAccount
				? (
					<>
						<Title
							title={`${bankAccount.name || ''} ${place} bank account`}
							buttons={place === 'customer'
								? [{
									action: handleEditClick,
									text: 'edit bank account',
									icon: 'Edit',
									disabled: true,
								}, {
									action: handlePayout,
									text: 'payout',
									icon: 'ArrowUp',
									disabled: !bankAccount.id,
								}, ...additionalBtn]
								: [{
									action: handleEditClick,
									text: 'edit bank account',
									icon: 'Edit',
									disabled: true,
								}, ...additionalBtn]}
							applicationId={applicationId}
							tags={
								bankAccount.customerBankAccount
									? [bankAccount.customerBankAccount.isCollection && 'collection', bankAccount.customerBankAccount.isPayOut && 'payout']
									: []
							}
						/>
						<div className="page-container">
							<div>
								<Row gutter={24} type="flex">
									<Col span={4} className={styles.card}>
										<InfoCard
											data={{
												balance: PriceHelpers.formatAmount(
													bankAccount.balance,
													bankAccount.instrumentId || '',
													Numbers.sign(bankAccount.balance || '0'),
												),
											}}
										/>
									</Col>
									{place !== 'customer' && (
										<Col span={4} className={styles.card}>
											<InfoCard
												data={{
													walletBalance: PriceHelpers.formatAmount(
														wallet.available || '0',
														wallet.instrumentId || bankAccount.instrumentId || '',
														Numbers.sign(wallet.available || '0'),
													),
												}}
											/>
										</Col>
									)}
									{place !== 'customer' && (
										<Col span={8} className={styles.card}>
											<BankInfoCard
												data={userData}
											/>
										</Col>
									)}
									{bankAccount?.accountDetails?.map((el) => (
										<Col span={place === 'customer' ? 12 : 8} className={styles.card}>
											<BankInfoCard
												data={[el.details]}
											/>
										</Col>
									))}
									{place === 'customer' && (
										<Col span={8} className={styles.card}>
											<Card bordered={false} className={styles.text}>
												{bankAccount.description}
											</Card>
										</Col>
									)}
								</Row>
								<Row>
									<Card bordered={false} className={styles.payments}>
										<h2>Account Payments</h2>
										<div className={styles.filter}>
											<CPButton
												action={() => { setFilterVisible(true); setDate(true); }}
												icon="CalendarDate"
												text="date"
											/>
											<CPButton
												action={() => setFilterVisible(true)}
												text="filter"
												icon="Funnel"
											/>
										</div>
										<Filter
											fields={{
												dateRange: 'time',
												status: StatusHelpers.paymentStatus,
												paymentMethodCategory: schemes,
											}}
											date={date}
											visible={filterVisible}
											place="bankAccountPayments"
											position={320}
											closeFunction={() => { setFilterVisible(false); setDate(false); }}
											filterMode="single"
										/>
										<BankPayments
											payments={payments}
											applicationId={applicationId}
											paymentsTotal={accountPaymentsTotal}
											place="bankAccountPayments"
											headerHeight={272}
											currentPage={currentPage}
											isFetchingPayments={isFetchingBankAccountPayments}
											handlePageChange={handlePageChange}
										/>
									</Card>
								</Row>
							</div>
							<WarningModal
								title={warningTitle}
								visible={warningModal}
								cancelFunction={handleEditCancel}
								footer={footer}
								message={message}
								success={success}
								loading={buttonLoading}
							/>
							<FormModal
								title={type}
								visible={openModal}
								cancelFunction={handleEditCancel}
								form={type === 'edit'
									? (
										<AssociateForm
											submitChanges={continueEdit}
											handleCancelClick={handleEditCancel}
											applicationId={applicationId}
											providers={bankAccount.provider
												? [bankAccount.provider]
												: []}
											wrappedComponentRef={formRef}
										/>
									) : (
										<PayoutForm
											accountId={accountId}
											type={type}
											submitChanges={continueEdit}
											handleCancelClick={handleEditCancel}
											applicationId={applicationId}
											wrappedComponentRef={formRef}
											provider={bankAccount.provider?.name}
											instrument={bankAccount.instrumentId}
											balance={bankAccount.balance || 0}
											schemes={schemes}
										/>
									)}
							/>
						</div>
					</>
				) : <Spin spinning={isFetchingBankAccount} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	bankAccount: state.bank.bankAccount,
	isFetchingBankAccount: state.bank.isFetchingBankAccount,
	payments: state.bank.bankAccountPayments,
	paymentsTotal: state.bank.bankAccountPaymentsTotal,
	isFetchingBankAccountPayments: state.bank.isFetchingBankAccountPayments,
	currentPage: state.ui.bankAccountPaymentsPage,
	currentFilters: state.ui.bankAccountPaymentsFilters,
	user: state.users.fullUser,
	wallet: state.wallet.wallet,
});

const mapDispatchToProps = {
	getCustomerBankAccount,
	editCustomerBankAccount,
	customerPayout,
	getBankAccountPayments,
	setCurrentPage,
	getClientBankAccount,
	simulateCustomerPayment,
	getUser,
	getWallet,
	getSchemes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
