// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import components
import Title from '../../../components/UiElements/Title';
import List from '../../../components/UiElements/List';
import Checkbox from '../../../components/UiElements/Checkbox';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import CPButton from '../../../components/UiElements/Button';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import AddHandlerForm from '../components/AddHandlerForm';
//= import actions
import {
	getEventSubscription,
	addEventSubscriptionsEvents,
	removeEventSubscriptionsEvents,
	removeEventSubscription,
	getEventHandlers,
	addEventSubscriptionsEventHandlers,
	removeEventSubscriptionsEventHandlers,
} from '../../../modules/actions/EventSubscriptionAction';
import { getEvents } from '../../../modules/actions/WebhooksActions';
//= import types
import type { EventSubscription, EventHandler } from '../../../modules/reducers/EventSubscriptionReducer';
import type { Event } from '../../../modules/reducers/WebhooksReducer';
//= import styles
import styles from '../assets/subscription.module.scss';

type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

type Props = {
	match: {
		params: {
			applicationId: string,
			eventSubscriptionId: string,
		}
	},
	history: {
		push: (Object) => void,
		goBack: () => void,
	},
	eventSubscription: EventSubscription,
	events: Array<Event>,
	eventHandlers: Array<EventHandler>,
	getEvents: () => void,
	getEventSubscription: (string) => void,
	addEventSubscriptionsEvents: (string, string, Object) => Promise<Object>,
	removeEventSubscriptionsEvents: (string, string, string) => Promise<Object>,
	getEventHandlers: () => void,
	addEventSubscriptionsEventHandlers: (string, string, Object) => void,
	removeEventSubscriptionsEventHandlers: (string, string, string) => Promise<Object>,
	removeEventSubscription: (string) => Promise<Object>,
	isFetchingEventSubscription: boolean,
}

function EventSubscriptionPage(props: Props) {
	const {
		match: { params: { applicationId, eventSubscriptionId } },
		history,
		getEvents: getEventsAction,
		getEventSubscription: getEventSubscriptionAction,
		addEventSubscriptionsEvents: addEventSubscriptionsEventsAction,
		removeEventSubscriptionsEvents: removeEventSubscriptionsEventsAction,
		removeEventSubscription: removeEventSubscriptionAction,
		addEventSubscriptionsEventHandlers: addEventSubscriptionsEventHandlersAction,
		getEventHandlers: getEventHandlersAction,
		removeEventSubscriptionsEventHandlers: removeEventSubscriptionsEventHandlersAction,
		eventSubscription,
		events,
		eventHandlers,
		isFetchingEventSubscription,
	} = props;

	const [subscriptionChanged, setSubscriptionChanged] = useState(false);
	const [warning, setWarning] = useState(false);
	const [message, setMessage] = useState({});
	const [deleteClicked, setDeleteClicked] = useState(false);
	const [addClicked, setAddClicked] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [footer, setFooter] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		getEventsAction();
		getEventHandlersAction();
	}, [getEventsAction, getEventHandlersAction]);

	useEffect(() => {
		getEventSubscriptionAction(eventSubscriptionId);
	}, [getEventSubscriptionAction, subscriptionChanged, eventSubscriptionId]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setAddClicked(false);
		setDeleteClicked(false);
		setWarning(false);
	};

	const handleWarningCancel = () => {
		setAddClicked(true);
		setWarning(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setAddClicked(false);
		setDeleteClicked(false);
		setWarning(true);
		setWarningTitle('Error!');
		setMessage({
			firstPart: error.error.response.data.message,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	// Add and remove event

	const removeEvent = (value: string) => {
		removeEventSubscriptionsEventsAction(applicationId, eventSubscription.id, value)
			.then(() => setSubscriptionChanged(!subscriptionChanged))
			.catch((err: ErrorType) => handleOnError(err));
	};

	const changeEvent = (e) => {
		const id: string = e.target.value;
		const action: boolean = e.target.checked;
		if (action) {
			addEventSubscriptionsEventsAction(applicationId, eventSubscription.id, { eventId: id })
				.then(() => setSubscriptionChanged(!subscriptionChanged))
				.catch((err: ErrorType) => handleOnError(err));
		} else {
			removeEventSubscriptionsEventsAction(applicationId, eventSubscription.id, id)
				.then(() => setSubscriptionChanged(!subscriptionChanged))
				.catch((err: ErrorType) => handleOnError(err));
		}
	};

	// Delete subscription

	const handleDeleteClicked = () => {
		setDeleteClicked(true);
	};

	const handleDeleteCancel = () => {
		setWarning(false);
		setDeleteClicked(false);
	};

	const deleteEventSubscription = async () => {
		setDeleteClicked(false);
		try {
			await removeEventSubscriptionAction(eventSubscription.id);
			history.goBack();
		} catch (error) { handleOnError(error); }
	};

	// Add handler

	const handleAddHandler = () => {
		setAddClicked(true);
	};

	const handleAddCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setAddClicked(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setAddClicked(false);
			setWarning(false);
		}
	};

	const submitAddHandler = async (data) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		try {
			await addEventSubscriptionsEventHandlersAction(applicationId, eventSubscription.id, data);
			setSubscriptionChanged(!subscriptionChanged);
			setButtonLoading(false);
			setWarning(false);
		} catch (error) { handleOnError(error); }
	};

	const continueAdding = (value) => {
		setWarning(true);
		setAddClicked(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect this Subscription.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAddHandler(value), text: 'add event handler' },
		]);
	};

	// remove handler

	const removeHandler = (handlerId) => {
		removeEventSubscriptionsEventHandlersAction(applicationId, eventSubscription.id, handlerId)
			.then(() => setSubscriptionChanged(!subscriptionChanged))
			.catch((err: ErrorType) => handleOnError(err));
	};

	const subscriptionData = {
		name: eventSubscription.name,
		type: eventSubscription.type,
		createdAt: moment(eventSubscription.createdAt).format('YYYY-MM-DD HH:mm:ss'),
		updatedAt: moment(eventSubscription.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
	};

	const checkedOptions = eventSubscription.events
		? eventSubscription.events.map((el) => el.id)
		: [];

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Event Subscription"
				buttons={[
					{
						text: 'remove event subscription',
						type: 'danger',
						action: handleDeleteClicked,
					},
				]}
			/>
			{!isFetchingEventSubscription
				? (
					<div className="page-container">
						<TextCard data={subscriptionData} colNumber={1} left="left-2" />
						<Row gutter={24}>
							<Col span={12}>
								<div className={styles.listButton}>
									<h2>Event Handlers</h2>
									<CPButton
										action={handleAddHandler}
										icon="Plus"
										text="add event handler"
									/>
								</div>
								<div className={styles.list}>
									<List
										dataSource={eventSubscription.eventHandlers}
										renderItem={(item) => (
											<List.Item
												actions={[
													<CPButton
														action={() => removeHandler(item.id)}
														icon="Close"
														ghost
														type="default"
													/>,
												]}
												key={item.id}
											>
												<List.Item.Meta
													title={item.description}
													description={item.name}
												/>
											</List.Item>
										)}
									/>
								</div>
							</Col>
						</Row>
						<h2>Events</h2>
						<Row gutter={24}>
							<Col span={12}>
								<div className={styles.list}>
									<List
										dataSource={eventSubscription.events}
										renderItem={(item) => (
											<List.Item
												actions={[
													<CPButton
														action={() => removeEvent(item.id)}
														icon="Close"
														ghost
														type="default"
													/>,
												]}
												key={item.id}
											>
												<List.Item.Meta
													title={item.description}
													description={item.name}
												/>
											</List.Item>
										)}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className={styles.checkGroup}>
									{events.map((el) => (
										<Checkbox
											key={el.id}
											onChange={changeEvent}
											value={el.id}
											checked={checkedOptions.includes(el.id)}
										>
											{el.name}
										</Checkbox>
									))}
								</div>
							</Col>
						</Row>
						<FormModal
							title="Add Event Handler"
							visible={addClicked}
							cancelFunction={handleAddCancel}
							form={(
								<AddHandlerForm
									handlers={eventHandlers}
									submitChanges={continueAdding}
									handleCancelClick={handleAddCancel}
									wrappedComponentRef={formRef}
								/>
							)}
						/>
						<WarningModal
							title={warningTitle}
							visible={warning}
							cancelFunction={handleErrorCancel}
							footer={footer}
							message={message}
							loading={buttonLoading}
						/>
						<DeleteModal
							okFunction={deleteEventSubscription}
							cancelFunction={handleDeleteCancel}
							visible={deleteClicked}
							btnText="Delete Event Subscription"
							message={`Once you delete this Event Subscription
									you won't be able to retrieve it.`}
						/>
					</div>
				)
				: <Spin spinning={isFetchingEventSubscription} />}
		</>
	);
}

const mapStateToProps = (state) => ({
	eventSubscription: state.eventSubscriptions.eventSubscription,
	isFetchingEventSubscription: state.eventSubscriptions.isFetchingEventSubscription,
	events: state.webhooks.events,
	eventHandlers: state.eventSubscriptions.eventHandlers,
});

const mapDispatchToProps = {
	getEventSubscription,
	addEventSubscriptionsEvents,
	removeEventSubscriptionsEvents,
	removeEventSubscription,
	getEvents,
	getEventHandlers,
	addEventSubscriptionsEventHandlers,
	removeEventSubscriptionsEventHandlers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSubscriptionPage);
