// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import types
import type { Event } from '../../../modules/reducers/WebhooksReducer';
//= import types
import type { Group } from '../../../modules/reducers/GroupReducer';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	events: Array<Event>,
	applicationId: string,
	group: Group,
	defaultGroup: string,
	type: string,
	editForm: Boolean,
	defaultEvent: String,
	defaultSecret: String,
	defaultBaseUrl: String,
}

type LocalState = {
	errorMessage: string,
}
type Values = {
	event: string,
	url: string,
}
class AddWebhook extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			events,
			editForm = false,
			defaultEvent = '',
			defaultSecret = '',
			defaultBaseUrl = '',
		} = this.props;
		const {
			errorMessage,
		} = this.state;
		const FormItem = Form.Item;
		const { Option } = Select;
		const webhookLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className="group-form">
				<Form.Item label="ENDPOINT URL" {...webhookLayout} help="">
					{getFieldDecorator('baseUrl', {
						rules: [{
							type: 'url',
							message: 'Please input Endpoint Url!',
							required: true,
						}],
						initialValue: defaultBaseUrl,
					})(
						<Input
							name="baseUrl"
							placeholder="http://"
							allowClear
						/>,
					)}
				</Form.Item>
				<Form.Item label="SECRET KEY" {...webhookLayout} help="">
					{getFieldDecorator('secret', {
						rules: [{
							required: true,
							message: 'Please input Secret Key!',
						}],
						initialValue: defaultSecret,
					})(
						<Input
							name="secret"
							placeholder="Enter Secret Key"
							allowClear
						/>,
					)}
				</Form.Item>
				<Form.Item label="EVENT" {...webhookLayout} help="">
					{getFieldDecorator('eventId', {
						rules: [{
							required: true,
							message: 'Please select a event for this webhook URL!\n',
						}],
						initialValue: defaultEvent,
					})(
						<Select
							allowClear
							showSearch
							placeholder="Select Event"
							optionFilterProp="children"
							filterOption={(input, option) => (
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							)}
						>
							{events.map((event) => (
								<Option value={event.id} key={event.id}>{event.name}</Option>
							))}
						</Select>,
					)}
				</Form.Item>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text={editForm ? 'edit webhook' : 'add webhook'} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const WebhookForm = Form.create()(AddWebhook);

export default WebhookForm;
