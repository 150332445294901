// @flow

import React from 'react';
import type { Node } from 'react';
import map from 'lodash/map';
//= import components
import Card from '../../../components/UiElements/Card';
import List from '../../../components/UiElements/List';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import styles
import styles from '../assets/banking.module.scss';

type Props = {
	data: Array<{ [string]: Node }>,
}
const BankInfoCard = (props: Props) => {
	const {
		data,
	} = props;

	const renderObject = (value): Node => map(value, (valueValue: string, valueKey: string) => {
		if (valueKey === 'accountHolderAddress') {
			return '';
		}
		if (valueKey !== null) {
			return (
				<div className={styles.item} key={valueKey}>
					<p className={styles.key}>{ActivityHelpers.transformToReadable(valueKey)}</p>
					<p className={styles.value}>{valueValue}</p>
				</div>
			);
		}
		return '';
	});

	const returnItem = (item: {[string]: Node}): Node => map(item, (value: Node, key: string) => {
		if (key === 'id') { return ''; }
		return (
			<div className={styles.item} key={key}>
				<p className={styles.key}>{ActivityHelpers.transformToReadable(key || '')}</p>
				<p className={styles.value}>
					{typeof value === 'string' || React.isValidElement(value) ? value : renderObject(value)}
				</p>
			</div>
		);
	});

	return (
		<Card bordered={false} className={styles.bankInfo}>
			<List
				itemLayout="horizontal"
				split={false}
				dataSource={data}
				renderItem={
					(item: {[string]: Node}) => returnItem(item)
				}
			/>
		</Card>
	);
};

export default BankInfoCard;
