// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import actions
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles

type SymbolRes = {
	payload: {
		data: Array<Symbol>,
	}
}
type Quantity = {
	baseInstrument: string,
	quotingInstrument: string,
}
type Props = {
	getAppSymbols: (string, string, number, string) => Promise<SymbolRes>,
	setCurrentPage: (string, number) => void,
	symbols: Array<Symbol>,
	closeOnlySearch: string,
	applicationId: string,
	isFetchingSymbols: boolean,
	currentPage: number,
}
const CloseOnlySymbols = (props: Props) => {
	const {
		getAppSymbols: getAppSymbolsAction,
		setCurrentPage: setCurrentPageAction,
		symbols,
		applicationId,
		closeOnlySearch,
		isFetchingSymbols,
		currentPage,
	} = props;

	useEffect(() => {
		const filters = { closeOnly: true, search: closeOnlySearch };
		getAppSymbolsAction(applicationId, 'numbered-pages', (currentPage - 1) * StatusHelpers.paginationLimit, queryString.stringify(filters));
	}, [getAppSymbolsAction, closeOnlySearch, applicationId, currentPage]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('closeOnlyPage', page);
	};

	const columns = [
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 112,
			render: (text: string, record: Symbol) => (
				<a
					href={`/application/${applicationId}/system/symbols/${record.id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{text}
				</a>
			),
		},
		{
			title: 'name',
			dataIndex: 'displayName',
			key: 'name',
			width: 240,
		},
		{
			title: 'tradable',
			dataIndex: 'isTradable',
			key: 'isTradable',
			width: 112,
			render: (text: boolean) => (
				<div style={text ? { maxWidth: 80 } : { maxWidth: 80, color: '#999999' }}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'visible',
			dataIndex: 'isVisible',
			key: 'isVisible',
			width: 112,
			render: (text: boolean) => (
				<div style={text ? { maxWidth: 80 } : { maxWidth: 80, color: '#999999' }}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'close only',
			dataIndex: 'closeOnly',
			key: 'closeOnly',
			width: 112,
			render: (text: boolean) => (
				<div style={text ? { maxWidth: 80 } : { maxWidth: 80, color: '#999999' }}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'min quantity set',
			dataIndex: 'orderSize.minQuantity',
			key: 'minQuantity',
			align: 'right',
			width: 176,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.baseInstrument
						? PriceHelpers.formatAmount(
							quantity.baseInstrument,
							record.baseInstrumentId,
						)
						: '-'}
				</div>
			),
		},
		{
			title: 'max quantity set',
			dataIndex: 'orderSize.maxQuantity',
			key: 'maxQuantity',
			align: 'right',
			width: 176,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.baseInstrument
						? PriceHelpers.formatAmount(quantity.baseInstrument, record.baseInstrumentId)
						: '-'}
				</div>
			),
		},
		{
			title: 'min amount set',
			dataIndex: 'orderSize.minQuantity',
			key: 'minAmount',
			align: 'right',
			width: 160,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.quotingInstrument
						? PriceHelpers.formatAmount(
							quantity.quotingInstrument,
							record.quotingInstrumentId,
						)
						: '-'}
				</div>
			),
		},
		{
			title: 'max amount set',
			dataIndex: 'orderSize.maxQuantity',
			key: 'maxAmount',
			align: 'right',
			width: 160,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.quotingInstrument
						? PriceHelpers.formatAmount(quantity.quotingInstrument, record.quotingInstrumentId)
						: '-'}
				</div>
			),
		},
		{
			title: 'exchange',
			dataIndex: 'exchange.name',
			key: 'exchange',
			width: 192,
			render: (text: string) => (
				<div>
					{ActivityHelpers.transformSnakeCase(text)}
				</div>
			),
		},
		{
			title: 'is open',
			dataIndex: 'isOpen',
			key: 'isOpen',
			width: 96,
			render: (text: string) => (
				<div>
					{text ? 'True' : 'False'}
				</div>
			),
		},
		{
			title: 'exchange minimum',
			dataIndex: 'exchangeMinimum',
			key: 'exchangeMinimum',
			align: 'right',
			width: 176,
		},
		{
			title: 'exchange precision',
			dataIndex: 'exchangePrecision',
			key: 'exchangePrecision',
			width: 176,
		},
		{
			title: 'ask price',
			dataIndex: 'price.ask',
			key: 'askPrice',
			align: 'right',
			width: 160,
		},
		{
			title: 'bid price',
			dataIndex: 'price.bid',
			key: 'bidPrice',
			align: 'right',
			width: 160,
		},
		{
			title: 'base precision',
			dataIndex: 'basePrecision',
			key: 'basePrecision',
			width: 144,
		},
		{
			title: 'quoting precision',
			dataIndex: 'quotingPrecision',
			key: 'quotingPrecision',
			width: 160,
		},
	];

	return (
		<div>
			{!isFetchingSymbols
				? (
					<CustomTable
						columns={columns}
						data={symbols}
						total={symbols.length}
						headerHeight={16}
						numberOnPage={symbols.length}
						place="closeOnlySymbols"
						applicationId={applicationId}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
					/>
				)
				: <Spin spinning={isFetchingSymbols} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	isFetchingSymbols: state.symbol.isFetchingSymbols,
	symbols: state.symbol.symbols,
	closeOnlySearch: state.ui.closeOnlySearch,
	currentPage: state.ui.closeOnlyPage,
});

const mapDispatchToProps = {
	getAppSymbols,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseOnlySymbols);
