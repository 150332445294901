// @flow
import * as React from 'react';
import { connect } from 'react-redux';
// import components
import Input from '../Input';
//= import actions
import { setCurrentSearch, setCurrentPage } from '../../../modules/actions/UiActions';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
// import styles
import styles from './assets/search.module.scss';

type Props = {
	place: string,
	placeholder: string,
	currentSearch: string,
	setCurrentSearch: (string, string) => void,
	setCurrentPage: (string, number) => void,
	disabled: boolean,
}

const Search = (props: Props) => {
	const {
		placeholder,
		currentSearch,
		place,
		disabled,
	} = props;

	const handleSearch = (value: string) => {
		props.setCurrentPage(`${place}Page`, 1);
		props.setCurrentSearch(`${place}Search`, value.toLowerCase());
	};

	return (
		<div className={styles.search}>
			<Input.Search
				placeholder={placeholder}
				allowClear
				onSearch={handleSearch}
				defaultValue={currentSearch}
				disabled={disabled}
			/>
		</div>
	);
};

const mapStateToProps = (state: State, props) => ({
	currentSearch: state.ui[`${props.place}Search`],
});

const mapDispatchToProps = {
	setCurrentSearch,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
