// @flow

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
//= import components
import Icon from '../../components/UiElements/Icon';
import Spin from '../../components/UiElements/Spin';
import Title from '../../components/UiElements/Title';
import Switch from '../../components/UiElements/Switch';
import DeleteModal from '../../components/UiElements/Modal/DeleteModal';
import PreviewModal from './components/StatementPreview';
//= import methods
import { getStatements, getStatement, updateStatement } from '../../modules/actions/StatementsActions';
import { getApplication } from '../../modules/actions/ApplicationActions';
import getFile from '../../modules/actions/FilesActions';
// import helpers
import ActivityHelpers from '../../lib/helpers/activityHelpers';
//= import types
import type { SingleStatement, Statement } from '../../modules/reducers/StatementsReducer';
import type { Application } from '../../modules/reducers/ApplicationReducer';
import type { State } from '../../modules/types/FlowTypes';

type StatementRes = {
	payload: {
		data: Statement,
	}
}
type FileRes = {
	payload: {
		data: {
			url: string,
		}
	}
}
type DetailsRes = {
	payload: {
		data: Application,
	}
}
type Props = {
	statements: Array<SingleStatement>,
	isFetchingStatements: boolean,
	getApplication: (string, string) => Promise<DetailsRes>,
	application: Application,
	getStatements: (string) => void,
	getStatement: (string, string) => Promise<StatementRes>,
	updateStatement: (string, string, Object) => Promise<Object>,
	getFile: (string) => Promise<FileRes>,
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
	customer: { referenceId: string },
}

function Statements(props: Props) {
	const {
		statements,
		isFetchingStatements,
		getStatements: getStatementsAction,
		getStatement: getStatementAction,
		updateStatement: updateStatementAction,
		getApplication: getApplicationAction,
		application,
		getFile: getFileAction,
		history: { push },
		match: { params: { applicationId } },
		customer: { referenceId },
	} = props;

	const [data, setData] = useState({});
	const [toggle, setToggle] = useState(false);
	const [switchKey, setSwitchKey] = useState('first');
	const [statement, setStatement] = useState({});
	const [statementId, setStatementId] = useState('');
	const [previewVisible, setPreviewVisible] = useState(false);
	const [logoValue, setLogoValue] = useState('');

	useEffect(() => {
		getStatementsAction(applicationId);
	}, [getStatementsAction, applicationId]);

	const viewStatement = (value) => {
		push(`/application/${applicationId}/system/statements/${value.id}`);
	};

	const submitUpdate = useCallback(() => {
		updateStatementAction(applicationId, statementId, data)
			.then(() => {
				setData({});
				setToggle(false);
			})
			.catch(() => {
				setSwitchKey('changed');
				setToggle(false);
			});
	}, [applicationId, data, updateStatementAction, statementId]);

	const handleToggle = (value: boolean, selected: string): void => {
		const updateData = { enabled: value };
		setData(updateData);
		setStatementId(selected);
	};

	useEffect(() => {
		if (data.enabled) {
			submitUpdate();
		} else if (data.enabled === false) {
			setToggle(true);
		}
	}, [data, submitUpdate]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleTogleCancel = () => {
		setToggle(false);
		setSwitchKey('changed');
		setData({});
	};

	const viewPreview = async (previewStatement) => {
		const res: StatementRes = await getStatementAction(applicationId, previewStatement.id);
		setStatement(res.payload.data);
		const detailsData: DetailsRes = await getApplicationAction(referenceId, applicationId);
		setPreviewVisible(true);
		if (detailsData.payload.data.applicationDetail.logo) {
			const logoUrl = new URL(detailsData.payload.data.applicationDetail.logo);
			const logoId = logoUrl.pathname;
			const fileRes: FileRes = await getFileAction(logoId);
			setLogoValue(fileRes.payload.data.url);
		}
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="statements"
				applicationId={applicationId}
				place="statements"
			/>
			{!isFetchingStatements
				? (
					<div className="page-container">
						{statements.map((el) => (
							<div className="statement-card" key={el.id}>
								<div className="body" onClick={() => viewStatement(el)} role="presentation">
									<div className="title">
										<h2 className="capitalize">{ActivityHelpers.transformToReadable(el.type || '')}</h2>
										<Switch
											key={el.id + switchKey}
											defaultChecked={el.enabled}
											onChange={(value: boolean) => handleToggle(value, el.id)}
											onClick={(checked, event) => event.stopPropagation()}
										/>
									</div>
									<p>
										{el.type === 'orderConfirmation'
											? 'This Statement will be sent to Client on every Order Execution, so he have conformation of his transactions and new account balance.'
											: 'This Statement will be sent on the 1st every month, so that Client can view all changes on his account during that period.'}
									</p>
								</div>
								<div className="view" onClick={() => viewPreview(el)} role="presentation">
									<Icon name="View" size={20} />
								</div>
							</div>
						))}
					</div>
				)
				: <Spin spinning={isFetchingStatements} />}
			{!!application.applicationDetail && (
				<PreviewModal
					visible={previewVisible}
					title={`${statement.type} Statement Preview`}
					statement={statement}
					details={application.applicationDetail}
					logoValue={logoValue}
					cancelFunction={() => setPreviewVisible(false)}
				/>
			)}
			<DeleteModal
				okFunction={submitUpdate}
				cancelFunction={handleTogleCancel}
				visible={toggle}
				btnText="DISABLE"
				message="You&apos;re about to disable sending of the Order Execution or Account Overall template for this application."
			/>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	statements: state.statements.statements,
	isFetchingStatements: state.statements.isFetchingStatements,
	application: state.application.application,
	customer: state.oauth.customer,
});

const mapDispatchToProps = {
	getStatements,
	getStatement,
	updateStatement,
	getApplication,
	getFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statements);
