// @flow

import { notification } from 'antd';
import {
	UPDATE_STATUS,
} from '../const/types';

export default function updateStatus(userId: string, status: string) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_STATUS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}/status/${status}`,
			},
		},
	})
		.then(() => {
			notification.success({
				message: 'Success',
				description: 'Status successfully changed',
				duration: 5,
				placement: 'bottomLeft',
			});
		},
		(error) => {
			notification.error({
				message: 'Error',
				description: error.error.response.data.message,
				duration: 5,
				placement: 'bottomLeft',
			});
		});
}
