// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Switch from '../../../components/UiElements/Switch';
import Popover from '../../../components/UiElements/Popover';
import Icon from '../../../components/UiElements/Icon';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import types
import type { Group } from '../../../modules/reducers/GroupReducer';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	applicationId: string,
	group: Group,
	defaultGroup: string,
	type: string,
}

type LocalState = {
	errorMessage: string,
	selected: boolean,
	isTouched: boolean,
	isDefault: boolean,
	infoMessage: string,
}
type Values = {
	name: string,
	description: string,
	default: boolean,
}
class AddAndEditForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		selected: false,
		isTouched: false,
		isDefault: false,
		infoMessage: '',
	}

	componentDidMount() {
		this.setState({
			isDefault: this.props.group.default,
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			selected: false,
			isTouched: false,
			isDefault: false,
		});
	}

	handleDefaultChange = (value) => {
		this.setState({
			selected: value,
			infoMessage: `This Group will be set as default group. Every new Client will be automatically added to this group.
			${this.props.defaultGroup} will no longer be a default group.`,
			isTouched: true,
			isDefault: value,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					name: values.name !== this.props.group.name ? values.name : undefined,
					description: values.description,
					default: values.default !== this.props.group.default ? values.default : undefined,
				};
				submitChanges(data);
				this.setState({
					errorMessage: '',
					isTouched: false,
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			type,
			group,
		} = this.props;
		const {
			errorMessage, selected, infoMessage, isTouched, isDefault,
		} = this.state;
		const FormItem = Form.Item;
		const groupLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className="group-form">
				{group.default
					? (
						<Form.Item label="DEFAULT" {...groupLayout}>
							<Popover
								content="Set another group as default in order to switch this one off."
								overlayClassName="button-popover"
							>
								<div className="tooltip-visible" />
							</Popover>
							{getFieldDecorator('default', {
								initialValue: true,
							})(
								<Switch
									defaultChecked
									checkedChildren="Yes"
									unCheckedChildren="No"
								/>,
							)}
						</Form.Item>
					)
					: (
						<Form.Item label="DEFAULT" {...groupLayout}>
							{getFieldDecorator('default', {
								initialValue: isDefault,
							})(
								<Switch
									checked={isDefault}
									onChange={this.handleDefaultChange}
									checkedChildren="Yes"
									unCheckedChildren="No"
								/>,
							)}
						</Form.Item>
					)}
				<Form.Item label="NAME" {...groupLayout} help="">
					{getFieldDecorator('name', {
						rules: [{
							required: true,
							message: 'Please input Group Name!',
						}],
						initialValue: type === 'create' ? undefined : group.name,
					})(
						<Input
							name="name"
							placeholder="Define Group Name"
						/>,
					)}
				</Form.Item>
				<Form.Item label="DESCRIPTION" {...groupLayout} help="">
					{getFieldDecorator('description', {
						initialValue: type === 'create' ? undefined : group.description,
					})(
						<Input.TextArea
							name="description"
							placeholder="Add Group Description"
							autoSize={{ minRows: 1, maxRows: 6 }}
						/>,
					)}
				</Form.Item>
				{selected && (
					<div className="info-message">
						<Icon name="Info" colorName="warning" />
						<h4>{infoMessage}</h4>
					</div>
				)}
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton
							type="primary"
							action={this.submit}
							disabled={!isFieldsTouched() && !isTouched}
							text={type === 'create ' ? 'create group' : 'save changes'}
						/>
					</FormItem>
				</div>
			</Form>
		);
	}
}

const GroupForm = Form.create()(AddAndEditForm);

export default GroupForm;
