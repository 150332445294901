// @flow
import React from 'react';
//= import components
import FormModal from '../../../../Modal/FormModal';
import ChangePasswordForm from './ChangePasswordForm';

type Props = {
	visible: bool,
	closeModal: () => void,
};

const ChangePasswordModal = ({ visible, closeModal } : Props) => {
	return (
		<FormModal
			title="Change password"
			visible={visible}
			cancelFunction={closeModal}
			form={<ChangePasswordForm handleCancelClick={closeModal} />}
		/>
	);
};

export default ChangePasswordModal;
