// @flow

import React from 'react';
import { NavLink } from 'react-router-dom';
//= import components
import MenuIcon from '../../../components/UiElements/Icon/MenuIcon';
//= import styles
import styles from '../assets/system.module.scss';

type Props = {
	icon: string,
	title: string,
	place: string,
	applicationId: string,
	disabled?: boolean,
}

const MenuItemCard = (props: Props) => {
	const {
		icon, title, place, applicationId, disabled,
	} = props;

	return (
		<div className={styles.card}>
			<NavLink
				to={disabled ? `/application/${applicationId}/system` : `/application/${applicationId}/system/${place}`}
				disabled={disabled}
			>
				<div className={styles.systemIcon}>
					<MenuIcon type={icon} />
				</div>
				<h4>{title}</h4>
			</NavLink>

		</div>
	);
};

MenuItemCard.defaultProps = {
	disabled: false,
};

export default MenuItemCard;
