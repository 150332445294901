// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../../components/UiElements/Title';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import Spin from '../../../components/UiElements/Spin';
import ProviderForm from '../components/ProviderForm';
import ProviderCard from '../components/ProviderCard';
import SharedCard from '../components/SharedCard';
//= import methods
import {
	getProvidersInstances,
	updateProviderInstance,
	getProviderInstance,
	getProviders,
	addProviderInstance,
	deleteProviderInstance,
} from '../../../modules/actions/ProviderActions';
//= import helpers
import ProviderHelpers from '../../../lib/helpers/providerHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { ProviderInstance, Provider } from '../../../modules/reducers/ProviderReducer';
//= import styles
import styles from '../assets/providers.module.scss';

type ProviderInstancesResponse = {
	payload: {
		data: Array<ProviderInstance>,
	}
}
type ProvidersResponse = {
	payload: {
		data: Array<Provider>,
	}
}
type ProviderResponse = {
	payload: {
		data: ProviderInstance,
	}
}
type Props = {
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
	getProvidersInstances: (string) => Promise<ProviderInstancesResponse>,
	updateProviderInstance: (string, string, Object) => Promise<ProviderResponse>,
	getProviderInstance: (string, string) => Promise<ProviderResponse>,
	providerInstances: Array<ProviderInstance>,
	getProviders: () => Promise<ProvidersResponse>,
	addProviderInstance: (string, Object) => Promise<ProviderResponse>,
	deleteProviderInstance: (string, string) => void,
	providers: Array<Provider>,
	currentFilters: { type: Array<string> },
	currentSearch: string,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

function Providers(props: Props) {
	const {
		match: { params: { applicationId } },
		providerInstances,
		providers,
		currentFilters,
		currentSearch,
		getProvidersInstances: getProvidersInstancesAction,
		updateProviderInstance: updateProviderInstanceAction,
		getProviderInstance: getProviderInstanceAction,
		getProviders: getProvidersAction,
		addProviderInstance: addProviderInstanceAction,
		deleteProviderInstance: deleteProviderInstanceAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [type, setType] = useState('');
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [switchKey, setSwitchKey] = useState(true);
	const [filteredProviders, setFilteredProviders] = useState([]);
	const [filteredProvidersInstances, setFilteredProvidersInstances] = useState([]);
	const [provider, setProvider] = useState({});
	const [providerInstance, setProviderInstance] = useState({});
	const [filterVisible, setFilterVisible] = useState(false);
	const [configure, setConfigure] = useState(false);
	const [loading, setLoading] = useState(true);

	const filterProviders = (
		allProviderInstances: Array<ProviderInstance>,
		allProviders: Array<Provider>,
		searchValue: string,
		filterValue: { [string]: Array<string> },
	) => {
		const result1: Array<ProviderInstance> = allProviderInstances.filter((el) => {
			const name = el.provider.displayName.toLowerCase();
			const values = filterValue.type;
			if (name.includes(searchValue)
				&& (!values || !values.length || (values.includes(el.provider.type)))) {
				return true;
			}
			return false;
		});
		const result2: Array<Provider> = allProviders.filter((el) => {
			const name = el.displayName.toLowerCase();
			const values = filterValue.type;
			if (name.includes(searchValue)
				&& (!values || !values.length || (values.includes(el.type)))) {
				return true;
			}
			return false;
		});
		setFilteredProvidersInstances(result1);
		setFilteredProviders(result2);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
		getProvidersAction();
	}, [
		submitted,
		getProvidersInstancesAction,
		getProvidersAction,
		applicationId,
	]);

	useEffect(() => {
		setLoading(true);
		filterProviders(providerInstances, providers, currentSearch, currentFilters);
	}, [currentFilters, currentSearch, providerInstances, providers]);

	const formRef = useRef(null);

	const handleWarningCancel = () => {
		setEdit(true);
		setWarning(false);
		setSwitchKey(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarning(false);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setEdit(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setProvider({});
			setProviderInstance({});
			setEdit(false);
			setWarning(false);
			setConfigure(false);
			setType('');
		}
	};

	const handleOnError = (error: ErrorType) => {
		setEdit(false);
		setConfigure(false);
		setWarning(true);
		setProvider({});
		setProviderInstance({});
		setSwitchKey(true);
		setWarningTitle('Error!');
		setMessage({
			firstPart: error.error ? error.error.response.data.message : 'Error!',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
		]);
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const editProviderInstance = async (providerInstanceId: string) => {
		const res = await getProviderInstanceAction(applicationId, providerInstanceId);
		setProviderInstance(res.payload.data);
		const providerToSet = providers.find((el) => el.id === res.payload.data.provider.id);
		setProvider(providerToSet || {});
		setSwitchKey(false);
		setType('update');
		setEdit(true);
	};

	const submitChanges = async (data: { [string]: string }) => {
		try {
			await updateProviderInstanceAction(applicationId, providerInstance.id, data);
			setSubmitted(!submitted);
			setSwitchKey(true);
			setEdit(false);
			setWarning(false);
			setProvider({});
			setProviderInstance({});
			if (formRef.current) {
				formRef.current.handleReset();
			}
		} catch (error) { handleOnError(error); }
	};

	const configureApp = () => {
		setConfigure(true);
	};

	const configureProviderInstance = (providerId: string) => {
		const providerToSet = providers.find((el) => el.id === providerId);
		if (providerToSet) {
			setProvider(providerToSet);
		}
		setType('create');
		setEdit(true);
	};

	const addNewProviderInstance = async (value) => {
		const data = { ...value, providerId: provider.id };
		const updateData = { active: true, primary: true };
		try {
			const res = await addProviderInstanceAction(applicationId, data);
			await updateProviderInstanceAction(applicationId, res.payload.data.id, updateData);

			setEdit(false);
			setWarning(false);
			setSubmitted(!submitted);
			setProvider({});
			setConfigure(false);
			if (formRef.current) {
				formRef.current.handleReset();
			}
		} catch (error) {
			handleOnError(error);
		}
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const deleteInstance = (instanceId: string) => {
		deleteProviderInstanceAction(applicationId, instanceId);
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="providers"
				buttons={[
					{
						action: () => setFilterVisible(true),
						text: 'filter',
						icon: 'Funnel',
					},
				]}
				searchComponent={{
					placeholder: 'Search For Provider...',
					disabled: false,
				}}
				place="providers"
				fields={{
					type: ProviderHelpers.providerTypes,
				}}
				visible={filterVisible}
				closeFunction={() => setFilterVisible(false)}
				filter
			/>
			<div className="page-container">
				{loading
					? <Spin spinning={loading} />
					: (
						<div>
							<div className={styles.providers}>
								<h2>My Providers</h2>
								<div className={styles.cardContainer}>
									{filteredProvidersInstances.map((el: ProviderInstance) => (
										<ProviderCard
											key={el.id}
											name={el.provider.name}
											displayName={el.provider.displayName}
											type={el.provider.type}
											id={el.id}
											action={editProviderInstance}
											providerInstanceTypes={[el.type]}
											primary={el.primary}
											active={el.active}
											instance
											deleteProviderInstance={deleteInstance}
										/>
									))}
								</div>
							</div>
							<div className={styles.providers}>
								<h2>All Providers</h2>
								<div className={styles.cardContainer}>
									{filteredProviders.map((el: Provider) => (
										<ProviderCard
											key={el.id}
											name={el.name}
											displayName={el.displayName}
											type={el.type}
											id={el.id}
											action={configureProviderInstance}
											providerInstanceTypes={el.providerInstanceTypes}
										/>
									))}
								</div>
							</div>
						</div>
					)}
				{type === 'update' && (
					<FormModal
						title={`Edit ${providerInstance.provider ? providerInstance.provider.displayName : ''} Settings`}
						visible={edit}
						cancelFunction={handleEditCancel}
						// key={switchKey.toString()}
						destroyOnClose={switchKey}
						form={(
							<ProviderForm
								submitChanges={submitChanges}
								handleCancelClick={handleEditCancel}
								wrappedComponentRef={formRef}
								providerInstance={providerInstance}
								formTemplate={provider.formTemplate}
								type={type}
							/>
						)}
					/>
				)}
				{type === 'create'
					&& (
						<FormModal
							title={`Enable ${provider.displayName || ''} Provider`}
							visible={edit}
							cancelFunction={handleEditCancel}
							form={provider?.providerInstanceTypes?.includes('shared') && !configure ? (
								<SharedCard
									key={provider.id}
									name={provider.name}
									displayName={provider.displayName}
									cancelFunction={handleEditCancel}
									configure={configureApp}
									providerInstanceTypes={provider.providerInstanceTypes}
								/>
							) : (
								<ProviderForm
									submitChanges={addNewProviderInstance}
									handleCancelClick={handleEditCancel}
									wrappedComponentRef={formRef}
									formTemplate={provider.formTemplate}
									type={type}
									switchKey={switchKey}
								/>
							)}
						/>
					)}
				<WarningModal
					title={warningTitle}
					visible={warning}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	providerInstances: state.providers.providersInstances,
	providers: state.providers.providers,
	currentFilters: state.ui.providersFilters,
	currentSearch: state.ui.providersSearch,
});

const mapDespatchToProps = {
	getProvidersInstances,
	updateProviderInstance,
	getProviderInstance,
	getProviders,
	addProviderInstance,
	deleteProviderInstance,
};
export default connect(mapStateToProps, mapDespatchToProps)(Providers);
