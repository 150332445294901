// @flow

import {
	STATIC_COUNTRIES,
	RATES,
} from '../const/types';

export function getStaticCountries() {
	return (dispatch: Function): void => dispatch({
		type: STATIC_COUNTRIES,
		payload: {
			request: {
				url: '/static/countries',
			},
		},
	});
}

export function getExchangeRates(applicationId: string, baseInstrument: string, quotingInstruments: string) {
	return (dispatch: Function): void => dispatch({
		type: RATES,
		payload: {
			request: {
				url: `applications/${applicationId}/rates/${baseInstrument}?${quotingInstruments}`,
			},
		},
	});
}
