// @flow

import React from 'react';
//= import components
import Radio from '../../../components/UiElements/Radio';
import Checkbox from '../../../components/UiElements/Checkbox';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	name: string,
	radioValues: Array<string>,
	radioValue: string,
	checkboxValues: Array<{[string]: string}>,
	idValues?: Array<Object>,
}

const StrategyComponent = (props: Props) => {
	const {
		name,
		radioValues,
		radioValue,
		checkboxValues,
		idValues,
	} = props;

	return (
		<div>
			<h4>{name}</h4>
			{!!radioValues.length && (
				<Radio.Group buttonStyle="solid" value={radioValue}>
					{radioValues.map((strategyValue) => (
						<Radio.Button
							key={strategyValue}
							value={strategyValue}
						>
							{ActivityHelpers.transformToReadable(strategyValue)}
						</Radio.Button>
					))}
				</Radio.Group>
			)}
			{!!checkboxValues.length && (
				<div className={styles.checkGroup}>
					{checkboxValues.map((el) => (
						<Checkbox
							key={el.id}
							checked
						>
							{el.name}
						</Checkbox>
					))}
				</div>
			)}
			{!!idValues?.length && (
				<div className={styles.code}>
					{idValues.map((el) => (
						<div
							key={el.code || el}
							checked
						>
							{`${el.code || el} ${el.description || ''}`}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

StrategyComponent.defaultProps = {
	idValues: [],
};

export default StrategyComponent;
