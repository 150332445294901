// @flow

const initialState = {
	programs: [],
	isFetchingPrograms: false,
	program: {},
	isFetchingProgram: false,
	cardDesign: [],
	entryModes: [],
	transactionTypes: [],
	merchantCategoryCodes: [],
	cardCurrencies: [],
	product: {},
	isFetchingProduct: false,
	products: [],
	isFetchingProducts: false,
	cards: [],
	cardsTotal: 0,
	isFetchingCards: false,
	card: {},
	isFetchingCard: false,
	cardPayments: [],
	cardPaymentsTotal: 0,
	isFetchingCardPayments: false,
	accountCards: [],
	isFetchingAccountCards: false,
	accountCardPayments: [],
	accountCardsPaymentsPerPage: 0,
	accountCardsPaymentsTotal: 0,
	isFetchingAccountCardPayments: false,
	cardPayment: {},
	isFetchingCardPayment: false,
	spendControls: {},
};

export type MerchantScope = {
	id: string,
		name: string,
		merchantCategoryStrategy: string,
		merchantCategoryCodes: Array<{
			code: string,
			description: string,
			}>,
		merchantStrategy: string,
		merchantIds: Array<string>,
		isActive: boolean,
}
export type AuthorizationControl = {
	id: string,
    name: string,
    isActive: boolean,
    merchantScope: MerchantScope,
    transactionTypeStrategy: string,
    transactionTypes: Array<{
        id: string,
        name: string,
        operationSign: string,
        description:string,
      }>,
    entryModeStrategy: string,
    entryModes: Array<{
        id: string,
        externalId: string,
        name: string,
        description: string,
      }>,
    createdAt: string,
	updatedAt: string,
}
export type TransactionTypes = {
	id: number,
	name: string,
	description: string,
	code: string,
}
export type EntryModes = {
	id: number,
	name: string,
	description: string,
	code: string,
}
export type MerchantCategoryCodes = {
	code: string,
	name: string,
	description: string,
	id: number,
}
export type CardDesign = {
	id: number,
	name: string,
	imageUrl: string,
	isActive: boolean,
	externalId: string,
}
export type Currency = {
	id: number,
	name: string,
	isoNumber: number,
	isoCode: string,
}
export type LimitControlType = {
	id: string,
	name: string,
	isActive: boolean,
	amountLimit: number,
	usageLimit: number,
	currency: Currency,
	velocityWindow: string,
	merchantScope: MerchantScope,
	transactionTypeStrategy: string,
	transactionTypes: Array<TransactionTypes>,
	entryModeStrategy: string,
	entryModes: Array<EntryModes>,
	createdAt: string,
	updatedAt: string,
}
export type Program = {
	rootProgramId: string,
    cardDesignIds: Array<number>,
    productIds: Array<number>,
    name: string,
    description: string,
    cardScheme: string,
    binType: string,
    binRange: string,
    cardType: string,
    cardPurpose: string,
    loopType: string,
    maxCardsPerUser: number,
    physicalCardsEnabled: boolean,
    virtualCardsEnabled: boolean,
    externalWalletsEnabled: boolean,
    cardEmbossingStrategy: string,
    multiCurrencyEnabled: boolean,
    autoConversionEnabled: boolean,
    cardValidityPeriodInMonths: number,
    threeDomainSecureEnabled: boolean,
    defaultCurrency: {
      id: number,
      name: string,
      isoNumber: number,
      isoCode: string
    },
    currencies: Array<{
        id: number,
        name: string,
        isoNumber: number,
        isoCode: string
      }>,
    countries: Array<{
        id: number,
        name: string,
        isoAlpha3Code: number,
        isoNumericCode: number,
	}>,
	deliveryMethods: Array<{
		id: string,
        name: string,
        description: string,
	}>,
    id: number,
    authorizationControl: AuthorizationControl,
    limitControls: Array<{
		level: string,
		limitControl: LimitControlType
	}>,
    createdAt: string,
    updatedAt: string,
}
export type Product = {
	programInstance: Program,
	programInstanceId: String,
	isActive: boolean,
	cardCreationEnabled: boolean,
	startDate: string,
	endDate: string,
	cardDesigns: Array<CardDesign>,
	name: string,
	description: string,
	cardScheme: string,
	binType: string,
	binRange: string,
	cardType: string,
	cardPurpose: string,
	loopType: string,
	maxCardsPerUser: number,
	physicalCardsEnabled: boolean,
	virtualCardsEnabled: boolean,
	externalWalletsEnabled: boolean,
	cardEmbossingStrategy: string,
	multiCurrencyEnabled: boolean,
	autoConversionEnabled: boolean,
	cardValidityPeriodInMonths: number,
	threeDomainSecureEnabled: boolean,
	defaultCurrency: {
		id: string,
		name: string,
		isoNumber: number,
		isoCode: string
	},
	currencies: Array<{
		id: number,
		name: string,
		isoNumber: number,
		isoCode: string
	}>,
	countries: Array<{
		id: number,
		name: string,
		isoAlpha3Code: string,
		isoNumericCode: number,
	}>,
	deliveryMethods: Array<{
		id: string,
        name: string,
        description: string,
	}>,
	id: string,
	authorizationControl: AuthorizationControl,
	limitControls: Array<{
		level: string,
		limitControl: LimitControlType
	}>,
	createdAt: string,
	updatedAt: string,
}
export type CardType = {
	name: string,
	accountPlatformId: string,
	cardDesignId: string,
	externalAccountId: string,
	externalId: string,
	country: {
		name: string,
		isoNumericCode: string,
	},
	createdAt: string,
	currency: Currency,
	id: string,
	primaryCardInstance: {
		id: string,
		cardNameLine: string,
		pan: string,
		expiresYear: string,
		expiresMonth: string,
		isActive: boolean,
		isVirtual: boolean,
		createdAt: string,
		deliveryInfo: {
			firstName: string,
			lastName: string,
			addressLine1: string,
			zipCode: string,
			city: string,
		},
		deliveryMethod: {
			name: string,
		}
	},
	primaryWalletPlatformId: string,
	productId: string,
	status: string,
	user: {
		platformId: string,
		firstName: string,
		lastName: string,
		addressLine1: string,
		city: string,
	},
	deliveryInfos: Array<{
		addressLine1: string,
		city: string,
		zipCode: string,
	}>,
	account: {
		name: string,
		platformId: string,
	},
	primaryWallet: {
		platformId: string,
		instrumentPlatformId: string,
	},
	userPlatformId: string,
	cardDesign: {
		imageUrl: string,
	},
	authorizationControl: AuthorizationControl,
	limitControls: Array<{
		level: string,
		limitControl: LimitControlType
	}>,
	cardInstances: Array<{
		cardNameLine: string,
		expiresMonth: string,
		expiresYear: string,
		isActive: boolean,
		isVirtual: boolean,
		pan: string,
	}>
}
export type Payment = {
	id: string,
    transLink: string,
    cardId: string,
    status: string,
    responseCode: string,
	originalAmount: number,
	userPlatformId: string,
    originalCurrency: {
      id: string,
      name: string,
      digits: number,
      isoNumber: string,
      isoCode: string,
    },
    billingAmount: number,
    billingCurrency: {
      id: string,
      name: string,
      digits: number,
      isoNumber: string,
      isoCode: string,
    },
    settlementAmount: number,
    settlementCurrency: {
      id: string,
      name: string,
      digits: number,
      isoNumber: string,
      isoCode: string
    },
    entryMode: {
      id: string,
      name: string,
      description: string
    },
    transactionType: {
      id: string,
      name: string,
      operationSign: string,
      description: string
    },
    transactionCountry: {
      id: string,
      name: string,
      isoAlpha3Code: string,
      isoNumericCode: string,
    },
    mcc: {
      code: string,
      description: string
    },
    isSuspicious: boolean,
    rejectionReason: {
      code: string,
      description: string,
      referenceId: string,
      referenceType: string,
    },
    createdAt: string,
	updatedAt: string,
	user: {
		id: string,
		firstName: string,
		lastName: string,
	},
	primaryCardInstance: {
		expiresMonth: string,
	},
	acquirerId: string,
	merchantId: string,
	merchantName: string,
	traceLogId: string,
	rawMessage: string,
	paymentActions: Array<{
		id: string,
		actionType: string,
		createdAt: string,
		payload: Object,
	}>,
	securityChecks: {
		'3DSecure': number,
		avs: number,
		cardExpirationDatePresent: number,
		chipData: number,
		cvv2: number,
		magneticStripe: number,
		offlinePin: number,
		onlinePin: number,
		phoneNumber: number,
	},
	riskActions: {
		changeAccountStatusToSuspended: number,
		changeCardStatusToRisk: number,
		markTransactionAsSuspicious: number,
		notifyCardholderBySendingTaisNotification: number,
		rejectTransaction: number,
	}
  }
export type CardsState = {
	programs: Array<Program>,
	isFetchingPrograms: boolean,
	program: Program,
	isFetchingProgram: boolean,
	cardDesign: Array<CardDesign>,
	entryModes: Array<EntryModes>,
	transactionTypes: Array<TransactionTypes>,
	merchantCategoryCodes: Array<MerchantCategoryCodes>,
	cardCurrencies: Array<Currency>,
	product: Product,
	isFetchingProduct: boolean,
	products:Array<Product>,
	isFetchingProducts: boolean,
	cards: Array<CardType>,
	isFetchingCards: boolean,
	card: CardType,
	isFetchingCard: boolean,
	cardPayments: Array<Payment>,
	cardsTotal: Number,
	cardPaymentsTotal: Number,
	cardPaymentsPerPage: Number,
	isFetchingCardPayments: boolean,
	accountCards: Array<CardType>,
	accountCardsPaymentsTotal: Number,
	isFetchingAccountCards: boolean,
	accountCardPayments: Array<Payment>,
	isFetchingAccountCardPayments: boolean,
	cardPayment: Payment,
	isFetchingCardPayment: boolean,
	spendControls: {
		authorizationControl: Array<AuthorizationControl>,
		limitControls: Array<LimitControlType>
	}
}

type ProgramAction = {
	type:
	| 'GET_PROGRAM'
	| 'GET_PROGRAM_FAIL'
	| 'GET_PROGRAM_SUCCESS',
	payload: {
		data: Program,
	},
	error: {
		code: number,
		message: string,
	}
}
type ProgramsAction = {
	type:
	| 'GET_PROGRAMS'
	| 'GET_PROGRAMS_FAIL'
	| 'GET_PROGRAMS_SUCCESS',
	payload: {
		data: Array<Program>,
	},
	error: {
		code: number,
		message: string,
	}
}
type ProductAction = {
	type:
	| 'GET_PRODUCT'
	| 'GET_PRODUCT_FAIL'
	| 'GET_PRODUCT_SUCCESS'
	| 'CREATE_PRODUCT'
	| 'CREATE_PRODUCT_FAIL'
	| 'CREATE_PRODUCT_SUCCESS'
	| 'UPDATE_PRODUCT'
	| 'UPDATE_PRODUCT_FAIL'
	| 'UPDATE_PRODUCT_SUCCESS',
	payload: {
		data: Product,
	},
	error: {
		code: number,
		message: string,
	}
}
type ProductsAction = {
	type:
	| 'GET_PRODUCTS'
	| 'GET_PRODUCTS_FAIL'
	| 'GET_PRODUCTS_SUCCESS',
	payload: {
		data: Array<Product>,
	},
	error: {
		code: number,
		message: string,
	}
}
type DesignAction = {
	type:
	| 'GET_DESIGN'
	| 'GET_DESIGN_FAIL'
	| 'GET_DESIGN_SUCCESS',
	payload: {
		data: CardDesign,
	},
	error: {
		code: number,
		message: string,
	}
}
type CardAction = {
	type:
	| 'GET_CARD'
	| 'GET_CARD_FAIL'
	| 'GET_CARD_SUCCESS'
	| 'CHANGE_CARD_STATUS_SUCCESS'
	| 'ISSUE_CARD_SUCCESS',
	payload: {
		data: CardType,
		headers: {
			'x-count': string,
			'total-count': string,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
type CardsAction = {
	type:
	| 'GET_CARDS'
	| 'GET_CARDS_FAIL'
	| 'GET_CARDS_SUCCESS'
	| 'GET_ACCOUNT_CARDS'
	| 'GET_ACCOUNT_CARDS_FAIL'
	| 'GET_ACCOUNT_CARDS_SUCCESS',
	payload: {
		data: Array<CardType>,
		headers: {
			'x-count': string,
			'total-count': string,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
type PaymentAction = {
	type:
	| 'GET_ACCOUNT_CARD_PAYMENT'
	| 'GET_ACCOUNT_CARD_PAYMENT_SUCCESS'
	| 'GET_ACCOUNT_CARD_PAYMENT_FAIL'
	| 'GET_CARD_PAYMENT'
	| 'GET_CARD_PAYMENT_SUCCESS'
	| 'GET_CARD_PAYMENT_FAIL',
	payload: {
		data: Payment,
	},
	error: {
		code: number,
		message: string,
	}
}
type PaymentsAction = {
	type:
	| 'GET_CARD_PAYMENTS'
	| 'GET_CARD_PAYMENTS_SUCCESS'
	| 'GET_CARD_PAYMENTS_FAIL'
	| 'GET_ACCOUNT_CARD_PAYMENTS'
	| 'GET_ACCOUNT_CARD_PAYMENTS_SUCCESS'
	| 'GET_ACCOUNT_CARD_PAYMENTS_FAIL',
	payload: {
		data: Array<Payment>,
		headers: {
			'x-count': string,
			'total-count': string,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
type UtilityAction = {
	type:
	| 'GET_ENTRY_MODE_SUCCESS'
	| 'GET_TRANSACTION_TYPES_SUCCESS'
	| 'GET_MERCHANT_CATEGORY_SUCCESS'
	| 'GET_CURRENCIES_SUCCESS',
	payload: {
		data: Array<EntryModes | TransactionTypes | MerchantCategoryCodes | Currency>,
	},
	error: {
		code: number,
		message: string,
	}
}

type SpendControlAction = {
	type:
	| 'GET_CARD_SPEND_CONTROLS'
	| 'GET_CARD_SPEND_CONTROLS_SUCCESS'
	| 'GET_CARD_SPEND_CONTROLS_FAIL',
	payload: {
		data: {
			authorizationControl: Array<AuthorizationControl>,
			limitControls: Array<LimitControlType>
		}
	},
	error: {
		code: number,
		message: string,
	}
}

type Action = ProgramAction
| ProgramsAction
| ProductAction
| ProductsAction
| DesignAction
| UtilityAction
| CardAction
| CardsAction
| PaymentsAction
| PaymentAction
| SpendControlAction;

export default function cardsReducer(
	state: CardsState = initialState,
	action: Action,
) {
	switch (action.type) {
	case 'GET_PROGRAMS':
		return {
			...state,
			isFetchingPrograms: true,
			programs: [],
			error: false,
		};
	case 'GET_PROGRAMS_FAIL':
		return {
			...state,
			isFetchingPrograms: false,
			error: action.error,
		};
	case 'GET_PROGRAMS_SUCCESS':
		return {
			...state,
			isFetchingPrograms: false,
			error: false,
			programs: action.payload.data,
		};
	case 'GET_PROGRAM':
		return {
			...state,
			isFetchingProgram: true,
			program: {},
			error: false,
		};
	case 'GET_PROGRAM_FAIL':
		return {
			...state,
			isFetchingProgram: false,
			error: action.error,
		};
	case 'GET_PROGRAM_SUCCESS':
		return {
			...state,
			isFetchingProgram: false,
			error: false,
			program: action.payload.data,
		};
	case 'GET_DESIGN':
		return {
			...state,
			error: false,
			cardDesign: [],
		};
	case 'GET_DESIGN_FAIL':
		return {
			...state,
			error: action.error,
		};
	case 'GET_DESIGN_SUCCESS':
		return {
			...state,
			error: false,
			cardDesign: action.payload.data,
		};
	case 'GET_PRODUCTS':
		return {
			...state,
			isFetchingProducts: true,
			products: [],
			error: false,
		};
	case 'GET_PRODUCTS_FAIL':
		return {
			...state,
			isFetchingProducts: false,
			error: action.error,
		};
	case 'GET_PRODUCTS_SUCCESS':
		return {
			...state,
			isFetchingProducts: false,
			error: false,
			products: action.payload.data,
		};
	case 'GET_PRODUCT':
		return {
			...state,
			isFetchingProduct: true,
			product: {},
			error: false,
		};
	case 'GET_PRODUCT_FAIL':
		return {
			...state,
			isFetchingProduct: false,
			error: action.error,
		};
	case 'GET_PRODUCT_SUCCESS':
	case 'UPDATE_PRODUCT_SUCCESS':
	case 'CREATE_PRODUCT_SUCCESS':
		return {
			...state,
			isFetchingProduct: false,
			error: false,
			product: action.payload.data,
		};
	case 'GET_CARDS':
		return {
			...state,
			isFetchingCards: true,
			cards: [],
		};
	case 'GET_CARDS_FAIL':
		return {
			...state,
			isFetchingCards: false,
		};
	case 'GET_CARDS_SUCCESS':
		return {
			...state,
			isFetchingCards: false,
			cards: action.payload.data,
			cardsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'GET_CARD':
		return {
			...state,
			isFetchingCard: true,
			card: {},
		};
	case 'GET_CARD_FAIL':
		return {
			...state,
			isFetchingCard: false,
		};
	case 'GET_CARD_SUCCESS':
		return {
			...state,
			isFetchingCard: false,
			card: action.payload.data,
		};
	case 'GET_CARD_PAYMENTS':
		return {
			...state,
			isFetchingCardPayments: true,
			cardPayments: [],
		};
	case 'GET_CARD_PAYMENTS_SUCCESS':
		return {
			...state,
			isFetchingCardPayments: false,
			cardPayments: action.payload.data,
			cardPaymentsTotal: parseInt(action.payload.headers['total-count'], 10),
			cardPaymentsPerPage: parseInt(action.payload.headers['x-count'], 10),
		};
	case 'GET_CARD_PAYMENTS_FAIL':
		return {
			...state,
			isFetchingCardPayments: false,
		};
	case 'GET_CARD_PAYMENT':
		return {
			...state,
			isFetchingCardPayment: true,
			cardPayment: {},
		};
	case 'GET_CARD_PAYMENT_SUCCESS':
		return {
			...state,
			isFetchingCardPayment: false,
			cardPayment: action.payload.data,
		};
	case 'GET_CARD_PAYMENT_FAIL':
		return {
			...state,
			isFetchingCardPayment: false,
		};
	case 'GET_ACCOUNT_CARDS':
		return {
			...state,
			isFetchingAccountCards: true,
			accountCards: [],
		};
	case 'GET_ACCOUNT_CARDS_SUCCESS':
		return {
			...state,
			isFetchingAccountCards: false,
			accountCards: action.payload.data,
		};
	case 'GET_ACCOUNT_CARDS_FAIL':
		return {
			...state,
			isFetchingAccountCards: false,
		};
	case 'GET_ACCOUNT_CARD_PAYMENTS':
		return {
			...state,
			isFetchingAccountCardPayments: true,
			accountCardPayments: [],
		};
	case 'GET_ACCOUNT_CARD_PAYMENTS_SUCCESS':
		return {
			...state,
			isFetchingAccountCardPayments: false,
			accountCardPayments: action.payload.data,
			accountCardsPaymentsTotal: parseInt(action.payload.headers['total-count'], 10),
			accountCardsPaymentsPerPage: parseInt(action.payload.headers['x-count'], 10),
		};
	case 'GET_ACCOUNT_CARD_PAYMENTS_FAIL':
		return {
			...state,
			isFetchingAccountCardPayments: false,
		};
	case 'GET_ACCOUNT_CARD_PAYMENT':
		return {
			...state,
			isFetchingCardPayment: true,
			cardPayment: {},
		};
	case 'GET_ACCOUNT_CARD_PAYMENT_SUCCESS':
		return {
			...state,
			isFetchingCardPayment: false,
			cardPayment: action.payload.data,
		};
	case 'GET_ACCOUNT_CARD_PAYMENT_FAIL':
		return {
			...state,
			isFetchingCardPayment: false,
			cardPayment: action.payload.data,
		};
	case 'GET_ENTRY_MODE_SUCCESS':
		return {
			...state,
			entryModes: action.payload.data,
		};
	case 'GET_TRANSACTION_TYPES_SUCCESS':
		return {
			...state,
			transactionTypes: action.payload.data,
		};
	case 'GET_MERCHANT_CATEGORY_SUCCESS':
		return {
			...state,
			merchantCategoryCodes: action.payload.data,
		};
	case 'GET_CURRENCIES_SUCCESS':
		return {
			...state,
			cardCurrencies: action.payload.data,
		};
	case 'CHANGE_CARD_STATUS_SUCCESS':
		return {
			...state,
			isFetchingCard: false,
			card: action.payload.data,
		};
	case 'ISSUE_CARD_SUCCESS':
		return {
			...state,
			accountCards: [...state.accountCards, action.payload.data],
		};
	case 'GET_CARD_SPEND_CONTROLS':
		return {
			...state,
			spendControls: {},
		};
	case 'GET_CARD_SPEND_CONTROLS_SUCCESS':
		return {
			...state,
			spendControls: action.payload.data,
		};
	case 'GET_CARD_SPEND_CONTROLS_FAIL':
		return {
			...state,
			spendControls: {},
		};
	default:
		return state;
	}
}
