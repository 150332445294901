// @flow

import type { User } from './UsersReducer';

const initialState = {
	accountMargin: {},
	isFetchingAccountMargin: false,
	accountMarginIsFetched: false,
	isFetchingRiskiestBorrowers: false,
	riskiestBorrowersIsFetched: false,
	riskiestBorrowers: [],
	riskiestBorrowersTotal: 0,
	error: {},
	accountMarginInterest: [],
	isFetchingAccountMarginInterest: false,
	accountMarginInterestIsFetched: false,
	accountMarginCharges: [],
	isFetchingAccountMarginCharges: false,
	accountMarginChargesIsFetched: false,
	accountMarginChargesTotal: 0,
};

export type WalletBreakdown = {
	walletId: string,
	walletInstrument: string,
	walletAvailableBalance: string,
	haircut: string,
	walletCollateral: string,
	exchangeRate: string,
}
export type AccountMargin = {
	marginCollateral: string,
	collateralMultiplier: string,
	loanLimit: string,
	marginUtilization: string,
	borrowedAmount: string,
	instrument: string,
	walletsBreakdown: Array<WalletBreakdown>,
}
export type RiskiestBorrower = {
	id: string,
	name: string,
	status: string,
	primary: boolean,
	userId: string,
	createdAt: string,
	updatedAt: string,
	reportingCurrency: string,
	user: User,
	marginInfo: AccountMargin,
}
export type MarginInterest = {
	principal: string,
	annualInterestRate: string,
	accrualPeriod: string,
	compoundPeriod: string,
	nextAccrualDate: string,
	nextBillingDate: string,
	estimatedNextAccrualAmount: string,
	estimatedNextCompoundAmount: string,
	instrument: {
		formatting: {
			grapheme: string,
		}
	}
}
export type MarginCharges = {
	id: string,
	interestRate: string,
	accrualPeriod: string,
	compoundPeriod: string,
	billingDate: string,
	amount: string,
	createdAt: string,
	updatedAt: string,
	wallet: {
		id: string,
		instrumentId: string,
	}
}

export type MarginState = {
	accountMargin: AccountMargin,
	isFetchingAccountMargin: boolean,
	accountMarginIsFetched: boolean,
	isFetchingRiskiestBorrowers: boolean,
	riskiestBorrowersIsFetched: boolean,
	riskiestBorrowers: Array<RiskiestBorrower>,
	riskiestBorrowersTotal: number,
	error: boolean | Object,
	accountMarginInterest: Array<MarginInterest>,
	isFetchingAccountMarginInterest: boolean,
	accountMarginInterestIsFetched: boolean,
	accountMarginCharges: Array<MarginCharges>,
	isFetchingAccountMarginCharges: boolean,
	accountMarginChargesIsFetched: boolean,
	accountMarginChargesTotal: number,
}
type MarginAction = {
	type: string,
	payload: {
		data: Object,
		headers: {
			'total-count': number,
		}
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}

export default function marginReducer(
	state: MarginState = initialState,
	action: MarginAction,
): MarginState {
	switch (action.type) {
	case 'ACCOUNT_MARGIN':
		return {
			...state,
			isFetchingAccountMargin: true,
			accountMarginIsFetched: false,
		};
	case 'ACCOUNT_MARGIN_FAIL':
		return {
			...state,
			isFetchingAccountMargin: false,
			accountMarginIsFetched: false,
			error: action.error,
			accountMargin: {},
		};
	case 'ACCOUNT_MARGIN_SUCCESS':
		return {
			...state,
			isFetchingAccountMargin: false,
			accountMarginIsFetched: true,
			error: false,
			accountMargin: action.payload.data,
		};
	case 'RISKIEST_BORROWERS':
		return {
			...state,
			isFetchingRiskiestBorrowers: true,
			riskiestBorrowersIsFetched: false,
		};
	case 'RISKIEST_BORROWERS_FAIL':
		return {
			...state,
			isFetchingRiskiestBorrowers: false,
			riskiestBorrowersIsFetched: false,
			error: action.error,
		};
	case 'RISKIEST_BORROWERS_SUCCESS':
		return {
			...state,
			isFetchingRiskiestBorrowers: false,
			riskiestBorrowersIsFetched: true,
			error: false,
			riskiestBorrowers: action.payload.data.reverse(),
			riskiestBorrowersTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'ACCOUNT_MARGIN_INTEREST':
		return {
			...state,
			isFetchingAccountMarginInterest: true,
			accountMarginInterestIsFetched: false,
		};
	case 'ACCOUNT_MARGIN_INTEREST_FAIL':
		return {
			...state,
			isFetchingAccountMarginInterest: false,
			accountMarginInterestIsFetched: false,
			error: action.error,
		};
	case 'ACCOUNT_MARGIN_INTEREST_SUCCESS':
		return {
			...state,
			isFetchingAccountMarginInterest: false,
			accountMarginInterestIsFetched: true,
			error: false,
			accountMarginInterest: action.payload.data,
		};
	case 'ACCOUNT_MARGIN_CHARGES':
		return {
			...state,
			isFetchingAccountMarginCharges: true,
			accountMarginChargesIsFetched: false,
		};
	case 'ACCOUNT_MARGIN_CHARGES_FAIL':
		return {
			...state,
			isFetchingAccountMarginCharges: false,
			accountMarginChargesIsFetched: false,
			error: action.error,
			accountMargin: {},
		};
	case 'ACCOUNT_MARGIN_CHARGES_SUCCESS':
		return {
			...state,
			isFetchingAccountMarginCharges: false,
			accountMarginChargesIsFetched: true,
			error: false,
			accountMarginCharges: action.payload.data,
			accountMarginChargesTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	default:
		return state;
	}
}
