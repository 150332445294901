import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../modules/actions/OauthActions';

type Props = {
    logout: () => void,
}
const Logout = (props: Props) => {
	props.logout();
	return (
		<Redirect
			to={{
				pathname: '/sign-in',
			}}
		/>
	);
};

const mapDispatchToProps = {
	logout,
};
export default connect(null, mapDispatchToProps)(Logout);
