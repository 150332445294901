// @flow

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import TableInfoComponent from '../../../components/UiElements/TableInfoComponent';
import Tag from '../../../components/UiElements/Tag';
import Icon from '../../../components/UiElements/Icon';
import styles from '../assets/banking.module.scss';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { PaymentType } from '../../../modules/reducers/BankReducer';

type Props = {
	payments: Array<PaymentType>,
	applicationId: string,
	paymentsTotal: number,
	place: string,
	headerHeight: number,
	currentPage: number,
	isFetchingPayments: boolean,
	handlePageChange: Function,
	pageSize: number,
}

type MatchPayments = {
	direction: string,
	walletId: string,
}

function BankPayments(props: Props) {
	const {
		payments,
		applicationId,
		paymentsTotal,
		place,
		headerHeight,
		currentPage,
		isFetchingPayments,
		handlePageChange,
		pageSize,
	} = props;

	const paymentsColumns = [
		{
			title: 'createdAt',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		}, {
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string, { direction, walletId }: MatchPayments) => (
				<div className={styles.flex}>
					{direction.toLowerCase() === 'inbound'
					&& !walletId
					&& (
						<Tooltip
							title="This payment is unmatched"
							arrowPointAtCenter
							placement="left"
							overlayClassName="tooltip-overlay"
							zIndex={9999}
						>
							<span className={styles.warn}>
								<Icon name="Warning" colorName="amber" />
							</span>
						</Tooltip>
					)}
					<CopyComponent
						text={text}
						content={(
							<Link
								to={`/application/${applicationId}/bank_payments/${text}`}
							>
								{text}
							</Link>
						)}
					/>
				</div>
			),
		}, {
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 160,
			render: (text: string) => (
				<Tag status={text} />
			),
		}, {
			title: 'direction',
			dataIndex: 'direction',
			key: 'direction',
			width: 160,
			render: (text: string) => (
				<div className="flex">
					<Icon
						name={text === 'Inbound' ? 'ArrowDown' : 'ArrowUp'}
						colorName={text === 'Inbound' ? 'info' : 'danger'}
						size={14}
					/>
					{text || '-'}
				</div>
			),
		}, {
			title: 'amount',
			dataIndex: 'amount',
			key: 'amount',
			width: 160,
			render: (text: string, record: PaymentType) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
						Numbers.sign(text || '0'),
					)}
				</div>
			),
		}, {
			title: 'scheme',
			dataIndex: 'scheme',
			key: 'scheme',
			width: 160,
			render: (text: string) => (
				<div className="capitalize">
					{text || '-'}
				</div>
			),
		}, {
			title: 'counterparty',
			dataIndex: 'counterparty',
			key: 'from',
			width: 336,
			render: (text: Object) => (
				<TableInfoComponent
					content={text.details}
					text={text.details.accountHolderName || 'Information'}
					name="from"
				/>
			),
		},
	];
	return (
		<>
			{!isFetchingPayments
				? (
					<CustomTable
						columns={paymentsColumns}
						data={payments}
						total={paymentsTotal}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						numberOnPage={pageSize}
						headerHeight={headerHeight}
						place={place}
						applicationId={applicationId}
					/>
				)
				: <Spin spinning={isFetchingPayments} />}
		</>
	);
}

export default BankPayments;
