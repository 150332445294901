// @flow

import React, {
	useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import Title from '../../../components/UiElements/Title';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import DividendsFilters from '../components/DividendsFilters';
import CalendarForm from '../components/CalendarForm';
import Calendar from '../components/DividendsCalendar';
import Payouts from '../components/Payouts';
//= import actions
import { getDividendsCalendars, addDividendCalendar, getDividendsPayouts } from '../../../modules/actions/DividendsAction';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { FilterType } from '../../../modules/reducers/UiReducer';
import type { DividendPayout, DividendsCalendar } from '../../../modules/reducers/DividendsReducer';

type DividendRes = {
	payload: {
		data: DividendsCalendar,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

type Props = {
	activeTab: string,
	applicationId: string,
	getDividendsCalendars: (string, string) => void;
	addDividendCalendar: (string, Object) => Promise<DividendRes>,
	getDividendsPayouts: (string, number) => void,
	setCurrentPage: (string, number) => void,
	filters: Array<FilterType>,
	dividendsCalendars: Array<DividendsCalendar>,
	calendarIsFetching: boolean,
	payouts: Array<DividendPayout>,
	payoutsTotal: number,
	payoutIsFetching: boolean,
	currentPage: number,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		goBack: () => void,
	},
}

const Dividends = (props: Props) => {
	const {
		getDividendsCalendars: getDividendsCalendarsAction,
		addDividendCalendar: addDividendCalendarAction,
		getDividendsPayouts: getDividendsPayoutsAction,
		setCurrentPage: setCurrentPageAction,
		activeTab,
		filters,
		dividendsCalendars,
		calendarIsFetching,
		payouts,
		payoutsTotal,
		payoutIsFetching,
		currentPage,
		match: { params: { applicationId } },
	} = props;

	const [edit, setEdit] = useState(false);
	const [footer, setFooter] = useState([]);
	const [warningTitle, setWarningTitle] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [message, setMessage] = useState({});
	const [calendarAdded, setCalendarAdded] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);
	const [date, setDate] = useState(false);

	const formRef = useRef(null);
	const filterRef = useRef(null);

	useEffect(() => {
		getDividendsCalendarsAction(applicationId, queryString.stringify(filters, { arrayFormat: 'comma' }));
	}, [
		applicationId,
		getDividendsCalendarsAction,
		filters,
		calendarAdded,
	]);

	useEffect(() => {
		getDividendsPayoutsAction(applicationId, currentPage);
	}, [
		getDividendsPayoutsAction,
		applicationId,
		currentPage,
	]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [filters]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handlePageChange = (page: number) => {
		setCurrentPageAction('payouts', page);
	};

	const handleSchedule = () => {
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleOnError = (error: ErrorType) => {
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const submitSchedule = (value) => {
		setCalendarAdded(false);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		addDividendCalendarAction(applicationId, value)
			.then(() => {
				setWarningModal(false);
				setCalendarAdded(true);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueSchedule = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect Dividends.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitSchedule(value), text: 'schedule dividend payout' },
		]);
	};

	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="Dividends"
					buttons={activeTab === 'dividends' ? [
						{
							action: () => { setFilterVisible(true); setDate(true); },
							text: 'date',
							icon: 'CalendarDate',
						},
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
						{
							icon: 'Plus',
							text: 'schedule dividend payout',
							action: handleSchedule,
						},
					] : []}
					tab="dividends"
					tabs={[
						{
							key: 'dividends',
							title: 'CALENDAR',
						},
						{
							key: 'payouts',
							title: 'PAYOUTS',
						},
					]}
				/>
			</div>
			<DividendsFilters
				visible={filterVisible}
				position={64}
				closeFunction={() => { setFilterVisible(false); setDate(false); }}
				place={activeTab}
				date={date}
			/>
			<div className="container">
				{activeTab === 'dividends'
					? (
						<Calendar
							applicationId={applicationId}
							headerHeight={filterHeight}
							dividendsCalendars={dividendsCalendars}
							calendarIsFetching={calendarIsFetching}
						/>
					)
					: (
						<Payouts
							applicationId={applicationId}
							handlePageChange={handlePageChange}
							payouts={payouts}
							payoutsTotal={payoutsTotal}
							payoutIsFetching={payoutIsFetching}
							currentPage={currentPage}
						/>
					)}
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleEditCancel}
					footer={footer}
					message={message}
				/>
				<FormModal
					title="Schedule Dividends"
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<CalendarForm
							submitChanges={continueSchedule}
							handleCancelClick={handleEditCancel}
							applicationId={applicationId}
							wrappedComponentRef={formRef}
						/>
					)}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	activeTab: state.ui.dividendsTab,
	filters: state.ui.dividendsFilters,
	dividendsCalendars: state.dividends.dividendsCalendars,
	calendarIsFetching: state.dividends.isFetchingDividendsCalendars,
	payouts: state.dividends.payouts,
	payoutsTotal: state.dividends.payoutsTotal,
	payoutIsFetching: state.dividends.isFetchingPayouts,
	currentPage: state.ui.payoutsPage,
});
const mapDispatchToProps = {
	getDividendsCalendars,
	addDividendCalendar,
	getDividendsPayouts,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dividends);
