// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
//= import actions
import {
	getAccountStockSplit,
} from '../../../modules/actions/StockSplitAction';
import { getUser } from '../../../modules/actions/UsersActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { AccountStockSplit } from '../../../modules/reducers/StockSplitReducer';
import type { FullUser } from '../../../modules/reducers/UsersReducer';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type Props = {
	getAccountStockSplit: (string, string) => Promise<Object>,
	getUser: (string) => void,
	isFetchingAccountStockSplit: boolean,
	accountStockSplit: AccountStockSplit,
	user: FullUser,
	match: {
		params: {
			applicationId: string,
			userId: string,
			stockSplitId: string,
		},
	},
	history: {
		goBack: () => void,
	},
	location: {
		search: string,
	},
}

const StockSplitPage = (props: Props) => {
	const {
		getAccountStockSplit: getAccountStockSplitAction,
		getUser: getUserAction,
		isFetchingAccountStockSplit,
		accountStockSplit,
		user,
		match: { params: { applicationId, stockSplitId, userId } },
		history,
		location: { search },
	} = props;

	const [accountId, setAccountId] = useState('');

	useEffect(() => {
		const params = queryString.parse(search);
		const { accountId: accId } = params;
		getAccountStockSplitAction(accId, stockSplitId);
		getUserAction(userId);
		setAccountId(accId);
	}, [
		search, stockSplitId, getAccountStockSplitAction, getUserAction, userId,
	]);

	const handleBackClick = () => {
		history.goBack();
	};

	const accountData = [{
		title: 'Account',
		data: {
			clientName: (
				<a
					href={`/application/${applicationId}/clients/${userId}?tab=stockSplits`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{`${user.userInfo?.firstName} ${user.userInfo?.lastName}`}
				</a>
			),
			accountName: (
				<a
					href={`/application/${applicationId}/clients/${userId}?accountId=${accountId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{user.accounts?.find((acc) => acc.id === accountId)?.name}
				</a>
			),
		},
	}];

	const generalInfo = [{
		title: 'General Info',
		data: {
			symbol: accountStockSplit.symbolId,
			splitRate: accountStockSplit.splitRate,
			announcementDate: accountStockSplit.announcementDate ? moment(accountStockSplit.announcementDate).format('YYYY-MM-DD') : '-',
			recordDate: accountStockSplit.recordDate ? moment(accountStockSplit.recordDate).format('YYYY-MM-DD') : '-',
			exDate: accountStockSplit.exDate ? moment(accountStockSplit.exDate).format('YYYY-MM-DD') : '-',
		},
	}];
	const executionSummary = [{
		title: 'Execution Summary',
		data: {
			closingPrice: PriceHelpers.formatAmount(
					accountStockSplit?.closingPrice,
					accountStockSplit?.quotingInstrument,
			),
			baseBefore: PriceHelpers.formatAmount(
					accountStockSplit?.executionSummary?.balanceBefore,
					accountStockSplit?.instrumentId,
			),
			baseAfter: PriceHelpers.formatAmount(
					accountStockSplit?.executionSummary?.balanceAfter,
					accountStockSplit?.instrumentId,
			),
			reminder: PriceHelpers.formatAmount(
					accountStockSplit?.executionSummary?.remainder?.quantity,
					accountStockSplit?.instrumentId,
			),
			payoutAmount: PriceHelpers.formatAmount(
					accountStockSplit?.executionSummary?.remainder?.payoutAmount,
					accountStockSplit?.executionSummary?.remainder?.payoutWalletInstrument,
			),
		},
	}];
	return (
		<div>
			<Title
				title={`${accountStockSplit.symbolId || ''} ${accountStockSplit.splitRate} Account Stock Split`}
				backAction={handleBackClick}
				tags={[
					accountStockSplit.status,
				]}
			/>
			{!isFetchingAccountStockSplit
				? (
					<div className="page-container">
						<Row gutter={24}>
							<Col span={12} className={styles.payout}>
								<DataCard parts={accountData} />
								<DataCard parts={generalInfo} />
							</Col>
							<Col span={12}>
								<DataCard parts={executionSummary} />
							</Col>
						</Row>
					</div>
				) : <Spin spinning={isFetchingAccountStockSplit} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	accountStockSplit: state.stockSplit.accountStockSplit,
	isFetchingAccountStockSplit: state.stockSplit.isFetchingAccountStockSplit,
	user: state.users.fullUser,
});

const mapDispatchToProps = {
	getAccountStockSplit,
	getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockSplitPage);
