class Api {
	//= store oauth data
	static async storeOAuth(oauth, appName) {
		this.oauth = oauth;
		try {
			if (oauth) {
				await localStorage.setItem('@Auth:appName', appName);
				await localStorage.setItem('@Auth:auth', JSON.stringify(this.oauth));
			}
		} catch (error) {
			console.error(error);
		}
	}

	//= store user data
	static storeUserData(data) {
		this.user = data;
	}
}

export default Api;
