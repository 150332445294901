// @flow

const initialState = {
	error: false,
	isFetchingWallets: false,
	isFetchedWallets: false,
	wallets: [],
	walletsTotal: 0,
	isCreditingWallet: false,
	walletCredited: false,
	makeDebit: false,
	debited: false,
	wallet: {},
	isFetchingWallet: false,
	isFetchedWallet: false,
	walletTransactions: [],
	isFetchingWalletTransaction: false,
	isFetchedWalletTransactions: false,
	walletTransactionsTotal: 0,
	conversions: [],
	conversionsTotal: 0,
	isFetchingConversions: false,
};

export type Wallet = {
	id: string,
	accountId: string,
	address: string,
	available: string,
	reserved: string,
	total: string,
	instrumentId: string,
	updatedAt: string,
	accountId: string,
	createdAt: string,
	primary: boolean,
	instrument: {
		precision: number,
		type: string,
		formatting: {
			grapheme: string,
		},
	},
	delisted: boolean,
	userId: string,
	debt: string,
}
export type WalletTransaction = {
	amount: string,
	available: string,
	createdAt: string,
	debtBalanceAfter: string,
	id: string,
	instrumentId: string,
	reference: string,
	referenceId: string,
	reserved: string,
	total: string,
	type: string,
	updatedAt: string,
	walletId: string,
}

export type Conversion = {
	accountId: string,
	applicationId: string,
	conversionRate: string,
	createdAt: string,
	deletedAt: string,
	fixedRate: string,
	fixedSide: string,
	fromAmount: string,
	fromCurrencyId: string,
	fromWalletId: string,
	id: string,
	price: string,
	priceOffset: string,
	reference: string,
	referenceId: string,
	toAmount: string,
	toCurrencyId: string,
	toWalletId: string,
	updatedAt: string,
	userId: string,
}
export type WalletState = {
	error: boolean | Object,
	isFetchingWallets: boolean,
	isFetchedWallets: boolean,
	wallets: Array<Wallet>,
	walletsTotal: number,
	isCreditingWallet: boolean,
	walletCredited: boolean,
	makeDebit: boolean,
	debited: boolean,
	wallet: Wallet,
	isFetchingWallet: boolean,
	isFetchedWallet: boolean,
	walletTransactions: Array<WalletTransaction>,
	isFetchingWalletTransaction: boolean,
	isFetchedWalletTransactions: boolean,
	walletTransactionsTotal: number,
	conversions: Array<Conversion>,
	conversionsTotal: number,
	isFetchingConversions: boolean,
}

export type WalletAction = {
	type:
	| 'GET_WALLET_SUCCESS'
	| 'GET_WALLET'
	| 'GET_WALLET_FAIL'
	| 'CREATE_WALLETS'
	| 'CREATE_WALLETS_FAIL'
	| 'CREATE_WALLETS_SUCCESS'
	| 'CREDIT_WALLETS'
	| 'CREDIT_WALLETS_FAIL'
	| 'CREDIT_WALLETS_SUCCESS'
	| 'DEBIT_WALLETS'
	| 'DEBIT_WALLETS_FAIL'
	| 'DEBIT_WALLETS_SUCCESS',
	payload: {
		data: Wallet,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
export type WalletsAction = {
	type:
	| 'WALLETS_SUCCESS'
	| 'WALLETS_FAIL'
	| 'WALLETS',
	payload: {
		data: Array<Wallet>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
export type TransactionActions = {
	type:
	| 'GET_WALLET_TRANSACTIONS'
	| 'GET_WALLET_TRANSACTIONS_SUCCESS'
	| 'GET_WALLET_TRANSACTIONS_FAIL',
	payload: {
		data: Array<WalletTransaction>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
export type ConversionActions = {
	type:
	| 'GET_CONVERSIONS'
	| 'GET_CONVERSIONS_SUCCESS'
	| 'GET_CONVERSIONS_FAIL',
	payload: {
		data: Array<Conversion>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
export type Actions = WalletAction | WalletsAction | TransactionActions | ConversionActions;

export default function walletReducer(
	state: WalletState = initialState,
	action: Actions,
): WalletState {
	switch (action.type) {
	case 'WALLETS': {
		return {
			...state,
			isFetchingWallets: true,
			isFetchedWallets: false,
			wallets: [],
			error: false,
		};
	}
	case 'WALLETS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWallets: false,
			isFetchedWallets: true,
			wallets: action.payload.data,
			walletsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'WALLETS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingWallets: false,
			isFetchedWallets: false,
		};
	}
	case 'CREDIT_WALLETS': {
		return {
			...state,
			isCreditingWallet: true,
			walletCredited: false,
			error: false,
		};
	}
	case 'CREDIT_WALLETS_SUCCESS': {
		return {
			...state,
			error: false,
			isCreditingWallet: false,
			walletCredited: true,
			wallet: action.payload.data,
		};
	}
	case 'CREDIT_WALLETS_FAIL': {
		return {
			...state,
			error: action.error,
			isCreditingWallet: false,
			walletCredited: false,
		};
	}
	case 'DEBIT_WALLETS': {
		return {
			...state,
			makeDebit: true,
			debited: false,
			error: false,
		};
	}
	case 'DEBIT_WALLETS_SUCCESS': {
		return {
			...state,
			error: false,
			makeDebit: false,
			debited: true,
			wallet: action.payload.data,
		};
	}
	case 'DEBIT_WALLETS_FAIL': {
		return {
			...state,
			error: action.error,
			makeDebit: false,
			debited: false,
		};
	}
	case 'GET_WALLET': {
		return {
			...state,
			isFetchingWallet: true,
			isFetchedWallet: false,
			error: false,
		};
	}
	case 'GET_WALLET_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWallet: false,
			isFetchedWallet: true,
			wallet: action.payload.data,
		};
	}
	case 'GET_WALLET_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingWallet: false,
			isFetchedWallet: false,
		};
	}
	case 'GET_WALLET_TRANSACTIONS': {
		return {
			...state,
			isFetchingWalletTransactions: true,
			isFetchedWalletTransactions: false,
			error: false,
		};
	}
	case 'GET_WALLET_TRANSACTIONS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWalletTransactions: false,
			isFetchedWalletTransactions: true,
			walletTransactions: action.payload.data,
			walletTransactionsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'GET_WALLET_TRANSACTIONS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingWalletTransactions: false,
			isFetchedWalletTransactions: false,
		};
	}
	case 'CREATE_WALLETS': {
		return {
			...state,
			isCreatingWallet: true,
			walletCreated: false,
			error: false,
		};
	}
	case 'CREATE_WALLETS_SUCCESS': {
		return {
			...state,
			error: false,
			isCreatingWallet: false,
			walletCreated: true,
			wallet: action.payload.data,
		};
	}
	case 'CREATE_WALLETS_FAIL': {
		return {
			...state,
			error: action.error,
			isCreatingWallet: false,
			walletCreated: false,
		};
	}
	case 'GET_CONVERSIONS': {
		return {
			...state,
			isFetchingConversions: true,
			conversions: [],
			conversionsTotal: 0,
			error: false,
		};
	}
	case 'GET_CONVERSIONS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingConversions: false,
			conversions: action.payload.data,
			conversionsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'GET_CONVERSIONS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingConversions: false,
		};
	}
	default:
		return state;
	}
};