// @flow

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
//= import components
import Card from '../../../components/UiElements/Card';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Drawer from '../../../components/UiElements/Drawer';
import CPButton from '../../../components/UiElements/Button';
import Table from '../../../components/UiElements/Table';
//= import actions
import {
	getAccountMarginInterest,
	getAccountMarginCharges,
} from '../../../modules/actions/MarginActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import selectors
import { accountMarginSelector } from '../../../modules/selectors/MarginSelector';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { AccountMarginWithCurrency } from '../../../modules/selectors/MarginSelector';
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { MarginCharges, MarginInterest } from '../../../modules/reducers/MarginReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	accountMargin: AccountMarginWithCurrency,
	activeAccount: Account,
	accountMarginCharges: Array<MarginCharges>,
	accountMarginInterest: Array<MarginInterest>,
	isFetchingAccountMarginCharges: boolean,
	isFetchingAccountMarginInterest: boolean,
	isFetchingAccountMargin: boolean,
	currentPage: number,
	accountMarginChargesTotal: number,
	getAccountMarginCharges: (string, number) => void,
	getAccountMarginInterest: (string) => void,
	setCurrentPage: (string, number) => void,
	applicationId: string,
}

const MarginTab = (props: Props) => {
	const {
		accountMargin: {
			borrowedAmount,
			collateralMultiplier,
			loanLimit,
			marginCollateral,
			marginUtilization,
			walletsBreakdown,
			accountCurrency,
		},
		activeAccount,
		isFetchingAccountMargin,
		accountMarginCharges,
		isFetchingAccountMarginCharges,
		accountMarginInterest,
		isFetchingAccountMarginInterest,
		currentPage,
		accountMarginChargesTotal,
		getAccountMarginInterest: getAccountMarginInterestAction,
		getAccountMarginCharges: getAccountMarginChargesAction,
		setCurrentPage: setCurrentPageAction,
		applicationId,
	} = props;

	const { id, name } = activeAccount;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [drawerData, setDrawerData] = useState({});
	const [accrualsData, setAccrualsData] = useState([]);

	useEffect(() => {
		getAccountMarginInterestAction(id);
	}, [getAccountMarginInterestAction, id]);

	useEffect(() => {
		getAccountMarginChargesAction(id, currentPage);
	}, [getAccountMarginChargesAction, id, currentPage]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('marginChargesPage', page);
	};

	const handleOpenDrawer = (chargesData) => {
		const data = {
			id: chargesData.id,
			interestRate: `${chargesData.interestRate}%`,
			billingDate: chargesData.billingDate ? moment(chargesData.billingDate).format('YYYY-MM-DD') : '-',
			amount: PriceHelpers.formatAmount(
				chargesData.amount,
				chargesData.wallet.instrumentId,
			),
			instrumentId: chargesData.wallet.instrumentId,
		};
		setDrawerOpen(true);
		setDrawerData(data);
		setAccrualsData(chargesData.accruals);
	};

	const onClose = () => {
		setDrawerOpen(false);
	};

	const collateralInfo = {
		tradingAccountId: id,
		collateralMultiplier: collateralMultiplier || '-',
		tradingAccountName: name,
		collateralUtilization: marginUtilization
			? `${marginUtilization}%`
			: '-',
		tradingAccountInstrumnt: accountCurrency || '-',
		borrowedAmount: PriceHelpers.formatAmount(
			borrowedAmount,
			accountCurrency || '',
		),
		marginCollateralAmount: PriceHelpers.formatAmount(
			marginCollateral || '0',
			accountCurrency || '',
			Numbers.sign(marginCollateral || '0'),
		),
		loanLimit: PriceHelpers.formatAmount(
			loanLimit || '0',
			accountCurrency || '',
			Numbers.sign(loanLimit || '0'),
		),
	};

	const walletColumns = [{
		title: 'WALLET',
		dataIndex: 'walletInstrument',
		key: 'walletInstrument',
		width: 224,
		render: (text: string) => (
			<div>
				{text ? `${text} Wallet` : ''}
			</div>
		),
	},
	{
		title: 'wallet id',
		dataIndex: 'walletId',
		key: 'walletId',
		width: 160,
		render: (text: string) => (
			<div>
				{text.substr(0, 8)}
			</div>
		),
	},
	{
		title: 'available',
		dataIndex: 'walletAvailableBalance',
		key: 'walletAvailableBalance',
		align: 'right',
		width: 176,
		render: (text: string, record: Object) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					record.walletInstrument,
					Numbers.sign(text),
				)}
			</div>
		),
	},
	{
		title: 'haircut',
		dataIndex: 'haircut',
		key: 'haircut',
		width: 160,
		render: (text: string) => (
			<div>
				{text ? `${text}%` : '-'}
			</div>
		),
	},
	{
		title: 'exchange rate',
		dataIndex: 'exchangeRate',
		key: 'exchangeRate',
		width: 176,
	},
	{
		title: 'wallet collateral',
		dataIndex: 'walletCollateral',
		key: 'walletCollateral',
		align: 'right',
		width: 160,
		render: (text: string) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					accountCurrency || '',
					Numbers.sign(text),
				)}
			</div>
		),
	}];

	const interestColumns = [{
		title: 'principal',
		dataIndex: 'principal',
		key: 'principal',
		width: 160,
		render: (text: string, record: MarginInterest) => <div>{`${record.instrument.formatting.grapheme}${text}`}</div>,
	},
	{
		title: 'annual interest rate',
		dataIndex: 'annualInterestRate',
		key: 'annualInterestRate',
		width: 176,
		render: (text: string) => <div>{`${text}%`}</div>,
	},
	{
		title: 'accrual period',
		dataIndex: 'accrualPeriod',
		key: 'accrualPeriod',
		width: 144,
		render: (text: string) => <div className="capitalize">{text}</div>,
	},
	{
		title: 'compound period',
		dataIndex: 'compoundPeriod',
		key: 'compoundPeriod',
		width: 160,
		render: (text: string) => <div className="capitalize">{text}</div>,
	},
	{
		title: 'next accrual date',
		dataIndex: 'nextAccrualDate',
		key: 'nextAccrualDate',
		width: 160,
		render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
	},
	{
		title: 'next billing date',
		dataIndex: 'nextBillingDate',
		key: 'nextBillingDate',
		width: 160,
		render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
	},
	{
		title: 'estimated next accrual amount',
		dataIndex: 'estimatedNextAccrualAmount',
		key: 'estimatedNextAccrualAmount',
		align: 'right',
		width: 264,
		render: (text: string, record: MarginInterest) => <div>{`${record.instrument.formatting.grapheme}${text}`}</div>,
	},
	{
		title: 'estimated next compound amount',
		dataIndex: 'estimatedNextCompoundAmount',
		key: 'estimatedNextCompoundAmount',
		align: 'right',
		width: 264,
		render: (text: string, record: MarginInterest) => <div>{`${record.instrument.formatting.grapheme}${text}`}</div>,
	}];

	const chargesColumns = [{
		title: 'interest rate',
		dataIndex: 'interestRate',
		key: 'interestRate',
		width: 160,
		render: (text: string) => <div>{`${text}%`}</div>,
	},
	{
		title: 'accrual period',
		dataIndex: 'accrualPeriod',
		key: 'accrualPeriod',
		width: 176,
		render: (text: string) => <div className="capitalize">{text}</div>,
	},
	{
		title: 'compound period',
		dataIndex: 'compoundPeriod',
		key: 'compoundPeriod',
		width: 176,
		render: (text: string) => <div className="capitalize">{text}</div>,
	},
	{
		title: 'billing date',
		dataIndex: 'billingDate',
		key: 'billingDate',
		width: 176,
		render: (text: string) => <div>{text ? moment(text).format('YYYY-MM-DD') : '-'}</div>,
	},
	{
		title: 'amount',
		dataIndex: 'amount',
		key: 'amount',
		align: 'right',
		width: 176,
		render: (text: string, record: MarginCharges) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					record.wallet.instrumentId,
				)}
			</div>
		),
	},
	{
		title: 'createdAt',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 176,
		render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
	}];

	const drawerColumns = [{
		title: 'principal',
		dataIndex: 'principal',
		key: 'principal',
		width: 144,
		render: (text: string) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					drawerData.instrumentId || '',
				)}
			</div>
		),
	},
	{
		title: 'amount',
		dataIndex: 'amount',
		key: 'amount',
		width: 144,
		align: 'right',
		render: (text: string) => (
			<div>
				{PriceHelpers.formatAmount(
					text,
					drawerData.instrumentId || '',
				)}
			</div>
		),
	},
	{
		title: 'created at',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 144,
		render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
	}];

	return (
		<Card className="margin-tab" bordered={false}>
			<h4>Collateral Info</h4>
			<TextCard data={collateralInfo} colNumber={2} border grid left="left-2" />
			{!isFetchingAccountMargin ? (
				<div className="margin-part">
					<h4>Wallet Breakdown</h4>
					<CustomTable
						columns={walletColumns}
						data={walletsBreakdown}
						total={walletsBreakdown?.length}
						headerHeight={544}
						place="marginTab"
						applicationId={applicationId}
					/>
				</div>
			) : <Spin spinning={isFetchingAccountMargin} />}
			{!isFetchingAccountMarginInterest
				? (
					<div className="margin-part">
						<h4>Margin Interest</h4>
						<CustomTable
							columns={interestColumns}
							data={accountMarginInterest}
							total={accountMarginInterest.length}
							headerHeight={544}
							place="marginInterest"
							applicationId={applicationId}
						/>
					</div>
				)
				: <Spin spinning={isFetchingAccountMarginInterest} position="spin-relative" />}
			{!isFetchingAccountMarginCharges
				? (
					<div className="margin-part">
						<h4>Margin Charges</h4>
						<CustomTable
							columns={chargesColumns}
							data={accountMarginCharges}
							total={accountMarginChargesTotal}
							headerHeight={544}
							place="marginCharges"
							handlePageChange={handlePageChange}
							currentPage={currentPage}
							onRowAction={handleOpenDrawer}
							applicationId={applicationId}
							rowClassName="clickable"
						/>
					</div>
				)
				: <Spin spinning={isFetchingAccountMarginCharges} position="spin-relative" />}
			<Drawer
				width={600}
				placement="right"
				onClose={onClose}
				visible={drawerOpen}
				closable={false}
			>
				<div className="drawer">
					<h2>Margin charges</h2>
					<TextCard data={drawerData} colNumber={1} />
					<h4>Accruals</h4>
					<Table
						rowKey={(record) => record.id}
						columns={drawerColumns}
						dataSource={accrualsData}
						locale={{ emptyText: 'No accruals!' }}
						scroll={{
							x: 432,
						}}
						pagination={false}
					/>
				</div>
				<div className="close">
					<CPButton
						text="close"
						action={onClose}
					/>
				</div>
			</Drawer>
		</Card>

	);
};

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.marginChargesPage,
	accountMarginCharges: state.margin.accountMarginCharges,
	isFetchingAccountMarginCharges: state.margin.isFetchingAccountMarginCharges,
	accountMarginInterest: state.margin.accountMarginInterest,
	isFetchingAccountMarginInterest: state.margin.isFetchingAccountMarginInterest,
	accountMarginChargesTotal: state.margin.accountMarginChargesTotal,
	accountMargin: accountMarginSelector(state),
	isFetchingAccountMargin: state.margin.isFetchingAccountMargin,
});

const mapDispatchToProps = {
	setCurrentPage,
	getAccountMarginCharges,
	getAccountMarginInterest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarginTab);
