// @flow

import type { DividendsCalendar } from './DividendsReducer';

const initialState = {
	symbols: [],
	isFetchingSymbols: false,
	isFetchedSymbols: false,
	symbolsTotal: 0,
	symbol: {},
	isFetchingSymbol: false,
	isFetchedSymbol: false,
	groupSymbols: [],
	isFetchingGroupSymbols: false,
	isFetchedGroupSymbols: false,
	groupSymbolsTotal: 0,
	instrumentSymbols: [],
	isFetchingInstrumentSymbols: false,
	isFetchedInstrumentSymbols: false,
	groupSymbol: {},
	isFetchingGroupSymbol: false,
	isFetchedGroupSymbol: false,
	error: false,
};

export type Symbol = {
	id: string,
	baseInstrument: {
		id: string,
		type: string,
		precision: number,
		formatting: {
			grapheme: string,
		}
	},
	baseInstrumentId: string,
	basePrecision: number,
	closeOnly: boolean,
	displayName: string,
	isTradable: boolean,
	isVisible: boolean,
	meta: Object,
	exchangeId: string,
	quotingProviderId: string,
	snapshotProviderId: string,
	offset: {
		bid: string,
		ask: string,
	},
	price: {
		ask: string,
		bid: string,
	},
	quotingInstrument: {
		type: string,
	},
	quotingInstrumentId: string,
	quotingPrecision: number,
	supportedOrderTypes: Array<string>,
	tradingProviderId: string,
	exchangePrecision: number,
	exchangeMinimum: string,
	orderSize: {
		increment: string,
		limitPriceOffset: string,
		maxQuantity: {
			baseInstrument: string,
			quotingInstrument: string,
		},
		minQuantity: {
			baseInstrument: string,
			quotingInstrument: string,
		},
	},
	isPostTradeSettlementAllowed: ?boolean,
	upcomingEvents: {
		previousDividend: DividendsCalendar,
		nextDividend: DividendsCalendar,
	},
	group?: {
		id: string,
	},
}
export type SymbolState = {
	symbols: Array<Symbol>,
	isFetchingSymbols: boolean,
	isFetchedSymbols: boolean,
	symbolsTotal: number,
	symbol: Symbol,
	isFetchingSymbol: boolean,
	isFetchedSymbol: boolean,
	groupSymbols: Array<Symbol>,
	isFetchingGroupSymbols: boolean,
	isFetchedGroupSymbols: boolean,
	groupSymbolsTotal: number,
	instrumentSymbols: Array<Symbol>,
	isFetchingInstrumentSymbols: boolean,
	isFetchedInstrumentSymbols: boolean,
	groupSymbol: Symbol,
	isFetchingGroupSymbol: boolean,
	isFetchedGroupSymbol: boolean,
	error: Object | boolean,
}
type SymbolsAction = {
	type:
	| 'APPLICATION_SYMBOLS'
	| 'APPLICATION_SYMBOLS_FAIL'
	| 'APPLICATION_SYMBOLS_SUCCESS'
	| 'GROUP_SYMBOLS'
	| 'GROUP_SYMBOLS_FAIL'
	| 'GROUP_SYMBOLS_SUCCESS'
	| 'INSTRUMENT_SYMBOLS'
	| 'INSTRUMENT_SYMBOLS_FAIL'
	| 'INSTRUMENT_SYMBOLS_SUCCESS',
	payload: {
		data: Array<Symbol>,
		headers: {
			'total-count': number,
		},
	},
	error: {
		code: number,
		message: string,
	}
}
type SymbolAction = {
	type:
	| 'SYMBOL'
	| 'SYMBOL_FAIL'
	| 'SYMBOL_SUCCESS'
	| 'GROUP_SYMBOL'
	| 'GROUP_SYMBOL_FAIL'
	| 'GROUP_SYMBOL_SUCCESS'
	| 'UPDATE_SYMBOL'
	| 'UPDATE_SYMBOL_FAIL'
	| 'UPDATE_SYMBOL_SUCCESS'
	| 'UPDATE_GROUP_SYMBOL'
	| 'UPDATE_GROUP_SYMBOL_FAIL'
	| 'UPDATE_GROUP_SYMBOL_SUCCESS',
	payload: {
		data: Symbol,
	},
	error: {
		code: number,
		message: string,
	}
}
type Actions = SymbolAction | SymbolsAction;

function symbolReducer(state: SymbolState = initialState, action: Actions): SymbolState {
	switch (action.type) {
	case 'APPLICATION_SYMBOLS':
		return {
			...state,
			isFetchingSymbols: true,
			isFetchedSymbols: false,
			symbols: [],
		};
	case 'APPLICATION_SYMBOLS_FAIL':
		return {
			...state,
			isFetchingSymbols: false,
			isFetchedSymbols: false,
			symbolsTotal: 0,
			error: action.error,
		};
	case 'APPLICATION_SYMBOLS_SUCCESS':
		return {
			...state,
			isFetchingSymbols: false,
			isFetchedSymbols: true,
			error: false,
			symbols: action.payload.data,
			symbolsTotal: action.payload.headers['total-count'] ? parseInt(action.payload.headers['total-count'], 10) : state.symbolsTotal,
		};
	case 'SYMBOL':
		return {
			...state,
			isFetchingSymbol: true,
			isFetchedSymbol: false,
		};
	case 'SYMBOL_FAIL':
		return {
			...state,
			isFetchingSymbol: false,
			isFetchedSymbol: false,
			error: action.error,
		};
	case 'SYMBOL_SUCCESS':
		return {
			...state,
			isFetchingSymbol: false,
			isFetchedSymbol: true,
			error: false,
			symbol: action.payload.data,
		};
	case 'UPDATE_SYMBOL':
		return {
			...state,
			isUpdatingSymbol: true,
			isUpdatedSymbol: false,
		};
	case 'UPDATE_SYMBOL_FAIL':
		return {
			...state,
			isUpdatingSymbol: false,
			isUpdatedSymbol: false,
			error: action.error,
		};
	case 'UPDATE_SYMBOL_SUCCESS':
		return {
			...state,
			isUpdatingSymbol: false,
			isUpdatedSymbol: true,
			error: false,
			symbol: action.payload.data,
			symbols: state.symbols.map((symbol) => {
				if (symbol.id === action.payload.data.id) {
					return action.payload.data;
				}
				return symbol;
			}),
		};
	case 'GROUP_SYMBOLS':
		return {
			...state,
			isFetchingGroupSymbols: true,
			isFetchedGroupSymbols: false,
			groupSymbols: [],
		};
	case 'GROUP_SYMBOLS_FAIL':
		return {
			...state,
			isFetchingGroupSymbols: false,
			isFetchedGroupSymbols: false,
			groupSymbolsTotal: 0,
			error: action.error,
		};
	case 'GROUP_SYMBOLS_SUCCESS':
		return {
			...state,
			isFetchingGroupSymbols: false,
			isFetchedGroupSymbols: true,
			error: false,
			groupSymbols: action.payload.data,
			groupSymbolsTotal: action.payload.headers['total-count'] ? parseInt(action.payload.headers['total-count'], 10) : state.groupSymbolsTotal,
		};
	case 'GROUP_SYMBOL':
		return {
			...state,
			isFetchingGroupSymbol: true,
			isFetchedGroupSymbol: false,
		};
	case 'GROUP_SYMBOL_FAIL':
		return {
			...state,
			isFetchingGroupSymbol: false,
			isFetchedGroupSymbol: false,
			error: action.error,
		};
	case 'GROUP_SYMBOL_SUCCESS':
		return {
			...state,
			isFetchingGroupSymbol: false,
			isFetchedGroupSymbol: true,
			error: false,
			groupSymbol: action.payload.data,
		};
	case 'UPDATE_GROUP_SYMBOL':
		return {
			...state,
			isUpdatingGroupSymbol: true,
			isUpdatedGroupSymbol: false,
		};
	case 'UPDATE_GROUP_SYMBOL_FAIL':
		return {
			...state,
			isUpdatingGroupSymbol: false,
			isUpdatedGroupSymbol: false,
			error: action.error,
		};
	case 'UPDATE_GROUP_SYMBOL_SUCCESS':
		return {
			...state,
			isUpdatingGroupSymbol: false,
			isUpdatedGroupSymbol: true,
			error: false,
			groupSymbol: action.payload.data,
			groupSymbols: state.groupSymbols.map((symbol) => {
				if (symbol.id === action.payload.data.id) {
					return action.payload.data;
				}
				return symbol;
			}),
		};
	case 'INSTRUMENT_SYMBOLS':
		return {
			...state,
			isFetchingInstrumentSymbols: true,
			isFetchedInstrumentSymbols: false,
			instrumentSymbols: [],
		};
	case 'INSTRUMENT_SYMBOLS_FAIL':
		return {
			...state,
			isFetchingInstrumentSymbols: false,
			isFetchedInstrumentSymbols: false,
			error: action.error,
		};
	case 'INSTRUMENT_SYMBOLS_SUCCESS':
		return {
			...state,
			isFetchingInstrumentSymbols: false,
			isFetchedInstrumentSymbols: true,
			error: false,
			instrumentSymbols: action.payload.data,
		};
	default:
		return state;
	}
}

export default symbolReducer;
