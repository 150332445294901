// @flow

import React from 'react';
import { Table } from 'antd';
import DragableBodyRow from './BodyRow';
import ResizeableTitle from './ResizeableTitle';
import Icon from '../Icon';

type Props = {
	allOptions: Array<Object>,
	selectedOptions: Array<Object>,
	selectFunction: Function,
	setColumnOrder: Function,
}
type LocalState = {
	selectedRowKeys: Array<Object>,
}
class ColumnChooser extends React.Component<Props, LocalState> {
	state = {
		selectedRowKeys: this.props.selectedOptions, // Check here to configure the default column
	};

	components = {
		header: {
			cell: ResizeableTitle,
		},
		body: {
			row: DragableBodyRow,
		},
	};

	componentDidMount() {
		const { selectedOptions } = this.props;
		this.setState({
			selectedRowKeys: selectedOptions,
		});
	}

	moveRow = (dragIndex: number, hoverIndex: number) => {
		this.props.setColumnOrder(dragIndex, hoverIndex);
	};

	onSelectChange = (rowKeys: Array<string>) => {
		this.setState({ selectedRowKeys: rowKeys });
		this.props.selectFunction(rowKeys);
	};

	render() {
		const {
			allOptions,
		} = this.props;
		const { selectedRowKeys } = this.state;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
			fixed: false,
			columnWidth: 96,
		};
		const options = allOptions.map((el) => ({
			title: el,
		}));
		const columns = [{
			title: '',
			dataIndex: '',
			key: 'dragIcon',
			fixed: 'left',
			render: () => (
				<Icon name="Sort" />
			),
		}, {
			title: 'select columns',
			dataIndex: 'title',
			key: 'title',
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		}];
		const { moveRow } = this;
		return (
			<div
				className="chooser-container"
			>
				<Table
					bordered={false}
					components={this.components}
					columns={columns}
					dataSource={options}
					rowSelection={rowSelection}
					rowKey={(record) => record.title}
					pagination={false}
					onRow={(record, index) => ({
						index,
						moveRow,
					})}
				/>
			</div>
		);
	}
}

export default ColumnChooser;
