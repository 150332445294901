// @flow

import React from 'react';
//= import components
import Card from '../../../components/UiElements/Card';
//= import images
import bcLogo from '../../../assets/img/company_logo.svg';
//= import types
import type { CardDesign } from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	cardDesign: Array<CardDesign>,
}

const DesignCard = (props: Props) => {
	const {
		cardDesign,
	} = props;

	return (
		<Card className={styles.designCards} bordered={false}>
			<h2>Card Designs</h2>
			{cardDesign.map((el) => (
				<div key={el.id}>
					{el.imageUrl
						? <img src={el.imageUrl} className={styles.bcSmall} alt="bc" />
						: <img src={bcLogo} className={styles.bcSmall} alt="bc" />					}
				</div>
			))}
		</Card>
	);
};

export default DesignCard;
