// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import types
import type { Event } from '../../../modules/reducers/WebhooksReducer';
import type { EventHandler } from '../../../modules/reducers/EventSubscriptionReducer';
import type { FormProps } from '../../../modules/types/FormTypes';

type Values = {
	name: string,
	events: Array<string>,
	handler: string,
}
type Props = {
	form: FormProps,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	events: Array<Event>,
	handlers: Array<EventHandler>,
}
type LocalState = {
	errorMessage: string,
}

class SubscriptionForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
		});
	}


	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
		} = this.props;
		const {
			errorMessage,
		} = this.state;

		const FormItem = Form.Item;
		const feeLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<p>
					Add an Event Subscription,
					then go to the newly added subscription to connect with handler and add Events
				</p>
				<FormItem label="NAME" {...feeLayout} help="">
					{getFieldDecorator('name', {
						rules: [
							{
								required: true,
								message: 'Define Subscription Name!',
							},
						],
					})(
						<Input
							name="name"
							placeholder="Define Subscription Name"
						/>,
					)}
				</FormItem>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} text="create events subscription" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const AddSubscriptionForm = Form.create()(SubscriptionForm);

export default AddSubscriptionForm;
