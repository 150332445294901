/* eslint-disable import/prefer-default-export */
// @flow

import {
	GET_API_KEYS,
	GENERATE_API_KEY,
	REVOKE_API_KEY,
} from '../const/types';

export function getApiKeys(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_API_KEYS,
		payload: {
			request: {
				url: `/applications/${applicationId}/access-tokens`,
			},
		},
	});
}

export function generateApiKey(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GENERATE_API_KEY,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/access-tokens`,
				data: {
					hasBackend: true,
				},
			},
		},
	});
}

export function revokeApiKey(applicationId: string, tokenId: string) {
	return (dispatch: Function): void => dispatch({
		type: REVOKE_API_KEY,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/access-tokens/${tokenId}`,
			},
		},
	});
}
