// @flow

const initialState = {
	webhooks: [],
	activeWebhooks: [],
	inactiveWebhooks: [],
	activeWebhooksTotal: 0,
	inactiveWebhooksTotal: 0,
	isFetchingWebhooks: false,
	isFetchedWebhooks: false,
	webhooksTotal: 0,
	error: false,
	webhook: {},
	isFetchingWebhook: false,
	isFetchedWebhook: false,
	addingWebhook: false,
	webhookAdded: false,
	events: [],
	isFetchingEvents: false,
	isFetchedEvents: false,
	isFetchingWebhookJobs: false,
	isFetchedWebhookJobs: true,
	webhookJobs: [],
	webhookJobsTotal: 0,
};

export type Webhook = {
	id: string,
	applicationId: string,
	eventId: string,
	baseUrl: string,
	updatedAt: string,
	createdAt: string,
	active: boolean,
}
export type Event = {
	id: string,
	name: String,
}
export type WebhookJob = {
	id: string,
	lastResponseData: null,
	ried: string,
	failed: string,
	retryRounds: string,
	retryIntervalMs: string,
	urlWithHash: string,
	status: string,
	deliveredAt: string,
	createdAt: string,
	updatedAt: string,
	deletedAt: string,
	applicationWebhookId: string,
	applicationWebhook: {
		id: string,
		baseUrl: string,
		createdAt: string,
		updatedAt: string,
		deletedAt: string,
		applicationId: string,
		eventId: string,
	}
}
export type WebhookState = {
	webhooks: Array<Webhook>,
	activeWebhooks: Array<Webhook>,
	inactiveWebhooks: Array<Webhook>,
	activeWebhooksTotal: number,
	inactiveWebhooksTotal: number,
	isFetchingWebhooks: boolean,
	isFetchedWebhooks: boolean,
	webhooksTotal: number,
	webhook: Webhook,
	isFetchingWebhook: boolean,
	isFetchedWebhook: boolean,
	error: boolean,
	addingWebhook: boolean,
	webhookAdded: boolean,
	events: Array<Event>,
	isFetchingEvents: boolean,
	isFetchedEvents: boolean,
	isFetchingWebhookJobs: boolean,
	isFetchedWebhookJobs: boolean,
	webhookJobs: Array<WebhookJob>,
	webhookJobsTotal: number,
}
type WebhooksAction = {
	type:
	| 'GET_WEBHOOKS'
	| 'GET_WEBHOOKS_FAIL'
	| 'GET_WEBHOOKS_SUCCESS',
	payload: {
		data: Array<Webhook>,
		headers: {
			'total-count': number,
		}
	},
	error: Object,
}
type WebhookAction = {
	type:
	| 'GET_WEBHOOK'
	| 'GET_WEBHOOK_FAIL'
	| 'GET_ACTIVE_WEBHOOKS'
	| 'GET_WEBHOOK_SUCCESS'
	| 'GET_ACTIVE_WEBHOOKS_SUCCESS'
	| 'GET_INACTIVE_WEBHOOKS_SUCCESS'
	| 'ADD_WEBHOOK'
	| 'ADD_WEBHOOK_FAIL'
	| 'ADD_WEBHOOK_SUCCESS',
	payload: {
		data: Webhook[],
		headers: {
			'total-count': number,
		}
	},
	error: Object,
}
type EventAction = {
	type:
	| 'GET_EVENTS'
	| 'GET_EVENTS_FAIL'
	| 'GET_EVENTS_SUCCESS',
	payload: {
		data: Array<Event>,
		headers: {
			'total-count': number,
		}
	},
	error: Object,
}
type WebhookJobAction = {
	type:
	| 'GET_WEBHOOK_JOBS'
	| 'GET_WEBHOOK_JOBS_FAIL'
	| 'GET_WEBHOOK_JOBS_SUCCESS',
	payload: {
		data: Array<WebhookJob> | Object,
		headers: {
			'total-count': number,
		}
	},
	error: Object,
}
type Action = WebhooksAction | WebhookAction | WebhookJobAction | EventAction;

export default function WebhooksReducer(
	state: WebhookState = initialState,
	action: Action,
): WebhookState {
	switch (action.type) {
	case 'GET_WEBHOOKS': {
		return {
			...state,
			isFetchingWebhooks: true,
			isFetchedWebhooks: false,
		};
	}
	case 'GET_WEBHOOKS_FAIL': {
		return {
			...state,
			error: false,
			isFetchingWebhooks: false,
			isFetchedWebhooks: false,
		};
	}
	case 'GET_WEBHOOKS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWebhooks: false,
			isFetchedWebhooks: true,
			webhooks: action.payload.data,
			webhooksTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'GET_WEBHOOK': {
		return {
			...state,
			isFetchingWebhook: true,
			isFetchedWebhook: false,
		};
	}
	case 'GET_WEBHOOK_FAIL': {
		return {
			...state,
			error: false,
			isFetchingWebhook: false,
			isFetchedWebhook: false,
		};
	}
	case 'GET_WEBHOOK_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWebhook: false,
			isFetchedWebhook: true,
			webhook: action.payload.data,
		};
	}
	case 'GET_ACTIVE_WEBHOOKS': {
		return {
			...state,
			isFetchingWebhooks: true,
			isFetchedWebhooks: false,
		};
	}
	case 'GET_ACTIVE_WEBHOOKS_FAIL': {
		return {
			...state,
			error: false,
			isFetchingWebhooks: false,
			isFetchedWebhooks: false,
		}
	}
	case 'GET_ACTIVE_WEBHOOKS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWebhooks: false,
			isFetchedWebhooks: true,
			activeWebhooks: action.payload.data,
			activeWebhooksTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'GET_INACTIVE_WEBHOOKS': {
		return {
			...state,
			isFetchingWebhooks: true,
			isFetchedWebhooks: false,
		}
	}
	case 'GET_INACTIVE_WEBHOOKS_FAIL': {
		return {
			...state,
			error: false,
			isFetchingWebhooks: false,
			isFetchedWebhooks: false,
		};
	}
	case 'GET_INACTIVE_WEBHOOKS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWebhooks: false,
			isFetchedWebhooks: true,
			inactiveWebhooks: action.payload.data,
			inactiveWebhooksTotal: action.payload.headers['total-count'],
		};
	}
	case 'ADD_WEBHOOK': {
		return {
			...state,
			addingWebhook: true,
			webhookAdded: false,
		};
	}
	case 'ADD_WEBHOOK_FAIL': {
		return {
			...state,
			error: true,
			addingWebhook: false,
			webhookAdded: false,
		};
	}
	case 'ADD_WEBHOOK_SUCCESS': {
		return {
			...state,
			error: false,
			addingWebhook: false,
			webhookAdded: true,
			webhooks: [action.payload.data, ...state.webhooks],
		};
	}
	case 'GET_EVENTS': {
		return {
			...state,
			isFetchingEvents: false,
			isFetchedEvents: false,
		};
	}
	case 'GET_EVENTS_FAIL': {
		return {
			...state,
			error: false,
			isFetchingEvents: true,
			isFetchedEvents: false,
		};
	}
	case 'GET_EVENTS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingEvents: false,
			isFetchedEvents: true,
			events: action.payload.data,
		};
	}
	case 'GET_WEBHOOK_JOBS': {
		return {
			...state,
			isFetchingWebhookJobs: true,
			isFetchedWebhookJobs: false,
			webhookJobs: [],
			webhookJobsTotal: 0,
		};
	}
	case 'GET_WEBHOOK_JOBS_FAIL': {
		return {
			...state,
			error: false,
			isFetchingWebhookJobs: false,
			isFetchedWebhookJobs: false,
		};
	}
	case 'GET_WEBHOOK_JOBS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingWebhookJobs: false,
			isFetchedWebhookJobs: true,
			webhookJobs: action.payload.data,
			webhookJobsTotal: action.payload.headers['total-count'],
		};
	}
	default:
		return state;
	}
}
