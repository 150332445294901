// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ReactGA from 'react-ga';
//= import components
import Spin from '../../components/UiElements/Spin';
import FormModal from '../../components/UiElements/Modal/FormModal';
import WarningModal from '../../components/UiElements/Modal/WarningModal';
import EmptyWallet from '../../components/UiElements/Illustrations/EmptyWallet';
import AccountsTabContent from './components/AccountTabContent';
import UserInfo from './components/UserInfo';
import SettingsInfo from './components/SettingsInfo';
import KYC from './components/KYC';
import Activity from './components/Activity';
import CreditDebitForm from './components/CreditDebitForm';
import SettingsForm from './components/SettingsForm';
import KycForm from './components/KycForm';
import AccountsPage from '../Accounts/index';
import UserTitle from './components/UserTitle';
import IssueCardForm from './components/IssueCardForm';
import CreateBankAccountForm from './components/CreateBankAccountForm';
import ConversionForm from './components/ConversionForm';
import AllAccounts from './components/AllAccounts';
import SuspendUserForm from './components/SuspendUserForm';
import UploadForm from './components/UploadForm';
import OrderForm from './components/OrderForm';
//= import actions
import { getExchangeRates } from '../../modules/actions/StaticActions';
import { getAppFeatures } from '../../modules/actions/FeaturesAction';
import {
	getUser,
	updateUser,
	setUserReportingCurrency,
	getUserSettings,
	updateUserSettings,
	createApplicant,
	triggerCheck,
	suspendUser,
	unsuspendUser,
} from '../../modules/actions/UsersActions';
import { moveUsersToGroup } from '../../modules/actions/GroupAction';
import updateStatus from '../../modules/actions/UpdateStatusActions';
import { getApplicationPayments, cryptoPayout } from '../../modules/actions/PaymentActions';
import {
	getAccounts, setActiveAccount, getAccountStatistics, getAccount,
} from '../../modules/actions/AccountsActions';
import {
	getWallets, creditWallet, debitWallet, createWallet, transferToWallet, executeConversion,
} from '../../modules/actions/WalletActions';
import { setActiveTab, setCurrentFilters } from '../../modules/actions/UiActions';
import {
	updateUserMarginInterest,
	getAccountMargin,
	getAccountMarginInterest,
} from '../../modules/actions/MarginActions';
import { addFee } from '../../modules/actions/FeeActions';
import { issueCard } from '../../modules/actions/CardAction';
import { createClientBankAccount } from '../../modules/actions/BankActions';
import { placeOrder } from '../../modules/actions/OrderActions';
//= import selectors
import { fullUserMappedSelector } from '../../modules/selectors/UsersSelector';
import { accountMarginSelector } from '../../modules/selectors/MarginSelector';
//= import helpers
import ActivityHelpers from '../../lib/helpers/activityHelpers';
//= import types
import type {
	FullUser, UserSettings,
} from '../../modules/reducers/UsersReducer';
import type { Account, Statistics } from '../../modules/reducers/AccountsReducer';
import type { Wallet } from '../../modules/reducers/WalletReducer';
import type { AccountMargin } from '../../modules/reducers/MarginReducer';
import type { Feature } from '../../modules/reducers/FeatureReducer';
import type { Rates } from '../../modules/reducers/StaticReducer';
import type { Payment } from '../../modules/reducers/PaymentReducer';
import type { State } from '../../modules/types/FlowTypes';
//= import styles
import styles from './assets/user.module.scss';
import StatusHelpers from '../../lib/helpers/statusHelpers';

type KYCReport = {
	id: string,
}

type PaymentRes = {
	payload: {
		data: Array<Payment>,
	}
}
type UserRes = {
	payload: {
		data: FullUser,
	}
}
type AccRes = {
	payload: {
		data: Array<Account>,
	}
}
type WalletRes = {
	payload: {
		data: Wallet,
	}
}
type WalletsRes = {
	payload: {
		data: Array<Wallet>,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}
type Props = {
	getUser: (string) => Promise<UserRes>,
	updateUser: (string, Object) => Promise<UserRes>,
	setUserReportingCurrency: (string) => void,
	getAppFeatures: (string) => Promise<Object>,
	getAccounts: (string) => Promise<AccRes>,
	getAccount: (string) => Promise<Object>,
	getExchangeRates: (string, ?string, string) => Promise<Object>,
	getApplicationPayments: (string, number, string) => Promise<PaymentRes>,
	updateStatus: (string, string) => void,
	getWallets: (string, number, number) => Promise<WalletsRes>,
	createWallet: (string, { [string]: string }) => Promise<WalletRes>,
	creditWallet: (string, string, Object) => Promise<WalletRes>,
	debitWallet: (string, string, Object) => Promise<WalletRes>,
	transferToWallet: (string, Object) => Promise<WalletRes>,
	moveUsersToGroup: (string, Object) => Promise<Object>,
	setActiveAccount: (string) => void,
	setActiveTab: (string, string) => void,
	getUserSettings: (string) => void,
	updateUserSettings: (string, Object) => Promise<Object>,
	updateUserMarginInterest: (string, Object) => Promise<Object>,
	getAccountMargin: (string) => void,
	addFee: (string, Object) => Promise<Object>,
	getAccountStatistics: (string) => void,
	cryptoPayout: (string, Object) =>Promise<Object>,
	createApplicant: (string, string) =>Promise<Object>,
	triggerCheck: (string, string, string, string) =>Promise<Object>,
	issueCard: (string, string, Object) => void,
	createClientBankAccount: (Object) => void,
	executeConversion: (string, Object) =>Promise<Object>,
	suspendUser: (string, string, Object) => Promise<Object>,
	unsuspendUser: (string, string, Object) => Promise<Object>,
	placeOrder: (string, string, Object) => Promise<Object>,
	match: {
		params: {
			userId: string,
			applicationId: string,
		},
	},
	history: {
		push: (string) => void,
		goBack: () => void,
		length: number,
	},
	location: {
		search: string,
		pathname: string,
	},
	fullUser: FullUser,
	accounts: Array<Account>,
	activeAccount: string,
	account: Account,
	activeTab: string,
	accountActiveTab: string,
	wallets: Array<Wallet>,
	isFetchingWallets: boolean,
	userSettings: UserSettings,
	accountMargin: AccountMargin,
	appFeature: Array<Feature>,
	rates: Rates,
	userReportingCurrency: string,
	accountStatistics: Statistics,
};
type Values = {
	netFee: number,
	amount: number,
	wallet: string,
	destinationWalletId?: string,
	destinationAddress: string,
	message: string,
}
type Value = {
	wallet: Wallet,
	instrumentId: string,
	fee: number,
	amount: number,
	values: Values,
	checkType: Array<string>,
	provider?: Array<string>,
	fromWallet: string,
	toWallet: string,
	toCurrency: string,
	fixedRate: string,
	fixedSide: string,
}
type SettingsData = {|
	groupId: string,
	collateralMultiplier: number,
	marginTrading: string,
	annualInterestRate: number,
	accrualPeriod: string,
	compoundPeriod: string,
	postTradeSettlement: boolean,
	maximumRiskExposureInstrument: string,
	maximumRiskExposureAmount: number,
	providerInstanceId: string,
	instrumentId: string,
	symbolId: string,
	orderType: string,
	orderSide: string,
	percentageFee: number,
	minimumFee: number,
	netFee: number,
	maximumFee: number,
|}

function FullUserPage(props: Props) {
	const {
		getUser: getUserAction,
		updateUser: updateUserAction,
		setUserReportingCurrency: setUserReportingCurrencyAction,
		getAppFeatures: getAppFeaturesAction,
		getAccounts: getAccountsAction,
		getAccount: getAccountAction,
		getExchangeRates: getExchangeRatesAction,
		getApplicationPayments: getApplicationPaymentsAction,
		updateStatus: updateStatusAction,
		getWallets: getWalletsAction,
		createWallet: createWalletAction,
		creditWallet: creditWalletAction,
		debitWallet: debitWalletAction,
		transferToWallet: transferToWalletAction,
		moveUsersToGroup: moveUsersToGroupAction,
		setActiveAccount: setActiveAccountAction,
		setActiveTab: setActiveTabAction,
		getUserSettings: getUserSettingsAction,
		updateUserSettings: updateUserSettingsAction,
		updateUserMarginInterest: updateUserMarginInterestAction,
		getAccountMargin: getAccountMarginAction,
		addFee: addFeeAction,
		getAccountStatistics: getAccountStatisticsAction,
		cryptoPayout: cryptoPayoutAction,
		createApplicant: createApplicantAction,
		triggerCheck: triggerCheckAction,
		issueCard: issueCardAction,
		createClientBankAccount: createClientBankAccountAction,
		executeConversion: executeConversionAction,
		suspendUser: suspendUserAction,
		unsuspendUser: unsuspendUserAction,
		placeOrder: placeOrderAction,
		match: {
			params: {
				userId,
				applicationId,
			},
		},
		history,
		location: {
			search,
		},
		fullUser,
		accounts,
		activeAccount,
		account,
		activeTab,
		accountActiveTab,
		wallets,
		isFetchingWallets,
		userSettings,
		accountMargin,
		appFeature,
		rates,
		accountStatistics,
		userReportingCurrency,
	} = props;
	const localData = localStorage.getItem('customer');
	const data = localData ? JSON.parse(localData) : null;
	const reportingCurrency : string = data ? data.reportingCurrency : '';

	const [openModal, setOpenModal] = useState(false);
	const [type, setType] = useState('');
	const [message, setMessage] = useState({});
	const [title, setTitle] = useState('');
	const [subtitle, setSubtitle] = useState('');
	const [footer, setFooter] = useState([]);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [pendingPayments, setPendingPayments] = useState([]);
	const [updateForm, setUpdateForm] = useState(false);
	const [part, setPart] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [kycUpdated, setKycUpdated] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [report, setReport] = useState(null);

	const formRef = useRef(null);
	const { userTab, tab, accountId } = queryString.parse(search);

	useEffect(() => {
		if (userTab) {
			setActiveTabAction('userTab', userTab);
		}
		if (tab) {
			setActiveTabAction('accountTab', tab);
		}
	}, [
		userTab,
		tab,
		setActiveTabAction,
	]);

	useEffect(() => {
		async function getUserData() {
			const userRes: UserRes = await getUserAction(userId);
			const userAcc = userRes.payload.data.accounts;
			if (userAcc.length) {
				// set user reporting currency
				const primaryAcc = userAcc.find((acc) => acc.primary);
				const userCurrency = primaryAcc?.reportingCurrency || reportingCurrency || '';
				setUserReportingCurrencyAction(userCurrency);
				// set active account
				const acAcc: string = accountId || primaryAcc?.id || userAcc[0].id;
				setActiveAccountAction(acAcc);
			}
		}
		getUserData();
		return function cleanup() {
			setActiveAccountAction('');
			setActiveTabAction('userTab', 'accounts');
			setActiveTabAction('accountTab', 'user_wallets');
		};
	}, [
		accountId,
		reportingCurrency,
		userId,
		getUserAction,
		setActiveAccountAction,
		setUserReportingCurrencyAction,
		setActiveTabAction,
	]);

	useEffect(() => {
		if (activeAccount) {
			getWalletsAction(activeAccount, 1);
		}
	}, [
		getWalletsAction,
		activeAccount,
		submitted,
	]);

	useEffect(() => {
		async function getAccountsData() {
			if (fullUser.accounts?.length) {
				// user has accounts, get users accounts
				const accountsRes: AccRes = await getAccountsAction(userId);
				const allAccounts = accountsRes.payload.data;
				// wallets instruments
				const allInstruments = allAccounts.reduce((instrumentIds, acc) => {
					const accInstruments = acc.wallets.map((wallet) => wallet.instrumentId);
					instrumentIds.push(...accInstruments);
					return instrumentIds;
				}, []);
				// all users instrument
				const allUserInstruments = [...new Set(allInstruments)];
				if (allUserInstruments.length) {
				// get exchange rate
					if (userReportingCurrency === reportingCurrency) {
						getExchangeRatesAction(applicationId, reportingCurrency, queryString.stringify({ quotingInstruments: allUserInstruments }, { arrayFormat: 'bracket' }));
					} else {
						Promise.all([
							getExchangeRatesAction(applicationId, reportingCurrency, queryString.stringify({ quotingInstruments: allUserInstruments }, { arrayFormat: 'bracket' })),
							getExchangeRatesAction(applicationId, userReportingCurrency, queryString.stringify({ quotingInstruments: allUserInstruments }, { arrayFormat: 'bracket' })),
						]);
					}
				}
			}
		}
		getAccountsData();
	},
	// eslint-disable-next-line
	[
		submitted,
		applicationId,
		userId,
		getAccountsAction,
		getExchangeRatesAction,
		userReportingCurrency,
		reportingCurrency,
	]);

	useEffect(() => {
		async function getData() {
			// general info data
			getAppFeaturesAction(applicationId);
			getUserSettingsAction(userId);
		}
		getData();
	}, [applicationId,
		userId,
		getAppFeaturesAction,
		getUserSettingsAction,
	]);
	useEffect(() => {
		if (activeAccount) {
			getAccountAction(activeAccount);
		}
	}, [
		activeAccount,
		getAccountAction,
	]);

	useEffect(() => {
		if (activeAccount) {
			getAccountStatisticsAction(activeAccount);
		}
	}, [
		getAccountStatisticsAction,
		activeAccount,
		submitted,
	]);

	useEffect(() => {
		const marginFeature = appFeature.find((features) => features.featureId === 'margin_trading') || {};
		if (marginFeature.enabled && activeAccount) {
			getAccountMarginAction(activeAccount);
		}
	}, [
		getAccountMarginAction,
		activeAccount,
		submitted,
		appFeature,
	]);

	useEffect(() => {
		async function getPaymentData() {
			// accounts wallets
			if (activeAccount) {
				const parameter = {
					accountId: activeAccount,
					status: ['pendingNew', 'pending'],
				};
					// pending payments
				const paymentRes: PaymentRes = await getApplicationPaymentsAction(applicationId, 1, queryString.stringify(parameter, { arrayFormat: 'comma' }));
				setPendingPayments(paymentRes.payload.data);
			}
		}
		getPaymentData();
	}, [
		applicationId,
		getApplicationPaymentsAction,
		activeAccount,
	]);

	const handleBackClick = () => {
		history.goBack();
	};

	const openSingleFee = (fee: Object):void => {
		history.push(`/application/${applicationId}/clients/${userId}/user_fees/${fee.id}`);
	};

	const updateUserStatus = (status: string) => {
		updateStatusAction(userId, status);
	};

	const createSubtitle = (actionType: string) => {
		if (actionType === 'internalTransfer') {
			return 'Enter destination Wallet Id';
		} if (actionType === 'cryptoPayout') {
			return 'Select Wallet from the list and Enter destination Address';
		}
		return 'You can search Wallets by Wallet ID (Reference Number on Payment Slip)';
	};

	const openSettings = (settingsPart) => {
		ReactGA.event({
			category: 'Full User',
			action: 'Settings Button Clicked',
			label: 'Open User Settings',
		});
		setOpenModal(true);
		setTitle(`Client ${settingsPart} Settings`);
		setType('settings');
		setUpdateForm(true);
		setPart(settingsPart);
	};

	const handleWarningCancel = () => {
		setOpenModal(true);
		setWarningModal(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setOpenModal(false);
		setWarningModal(false);
		setUpdateForm(false);
		setType('');
	};

	const handleEditCancel = () => {
		if (formRef.current) {
			if (formRef.current.props.form.isFieldsTouched() || formRef.current.state.quantity) {
				setOpenModal(false);
				setWarningModal(true);
				setWarningTitle('Warning!');
				setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
					{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
				]);
			} else {
				setOpenModal(false);
				setWarningModal(false);
				setUpdateForm(false);
				setType('');
			}
		} else {
			setOpenModal(false);
		}
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType): void => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		const testString = error.error?.response?.data?.message;
		let errorMessage;

		if (testString.includes('incomplete_checks')) errorMessage = JSON.parse(testString)?.error?.message;
		else if (testString) errorMessage = testString;
		else errorMessage = 'Something went wrong. Try again, or ask for help from the TradeCore customer support team.';

		setMessage({
			firstPart: errorMessage,
			secondPart: printError(error.error?.response?.data?.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleOnSuccess = () => {
		setWarningModal(true);
		setWarningTitle('Success');
		setMessage({
			firstPart: "You've successfully placed the order",
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const createMessage = (value) => {
		if (type === 'internalTransfer') {
			return `You're about to transfer ${value.amount} from ${fullUser.name}'s ${value.wallet.instrumentId || value.instrumentId}
			Wallet on ${account.name}.`;
		} if (type === 'cryptoPayout') {
			return `You're about to pay out ${value.amount} from ${value.wallet.instrumentId || value.instrumentId}
			Wallet to the following Address ${value.values.destinationAddress}.`;
		} if (type === 'issueCard') {
			return `You're about to Issue Card for ${fullUser.name}.`;
		} if (type === 'createClientBankAccount') {
			return `You're about to create Bank Account for Client ${fullUser.name}`;
		} if (type === 'conversion') {
			return `You're about to execute conversion between Wallet with Id ${value.fromWallet}
			and ${value.toCurrency} Wallet.`;
		} if (type === 'suspend') {
			return `You're about to Suspend client ${fullUser.name}`;
		} if (type === 'credit' || type === 'debit') {
			return `You're about to ${type} ${fullUser.name}'s ${value.wallet.instrumentId || value.instrumentId}
			Wallet on ${account.name} with ${value.amount}.
			A ${value.fee} fee amount will also be applied.`;
		}
		return `You're about to ${type} client ${fullUser.name}`;
	};

	const submitUpload = async (uploadMessage) => {
		setOpenModal(false);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		if (uploadMessage === 'success') {
			await getUserAction(userId);
			setKycUpdated(!kycUpdated);
			if (activeTab !== 'kycAndDocumentation') {
				setActiveTabAction('userTab', 'kycAndDocumentation');
			}
		} else {
			handleOnError(uploadMessage);
		}
	};

	const submitUserActions = async (value) => {
		setButtonLoading(true);
		if (type === 'check' || type === 'checkProofOfAddress') {
			try {
				setWarningModal(false);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				if (value.provider && value.provider.includes('onfido') && !fullUser.meta.kyc?.applicantId) {
					await createApplicantAction(userId, 'onfido');
				}
				await Promise.all(
					value.checkType.map((checkType) => triggerCheckAction(
						userId,
						StatusHelpers.checkProviders[checkType],
						checkType,
						type==='checkProofOfAddress' ? report?.id : null,
					)),
				);
			} catch (error) {
				handleOnError(error);
			} finally {
				setButtonLoading(false);
				setReport(null);
				setKycUpdated(!kycUpdated);
				if (activeTab !== 'kycAndDocumentation') {
					setActiveTabAction('userTab', 'kycAndDocumentation');
				}
			}
		} else if (type === 'issueCard') {
			try {
				await issueCardAction(userId, account.id, value);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
			} catch (error) {
				handleOnError(error);
			} finally {
				if (activeTab !== 'accounts') {
					setActiveTabAction('userTab', 'accounts');
				}
				if (accountActiveTab !== 'user_cards') {
					setActiveTabAction('accountTab', 'user_cards');
				}
			}
		} else if (type === 'suspend') {
			try {
				await suspendUserAction(applicationId, userId, value);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
			} catch (error) {
				handleOnError(error);
			} finally {
				if (activeTab !== 'userInfo') {
					setActiveTabAction('userTab', 'userInfo');
				}
			}
		} else if (type === 'reactivate') {
			try {
				await unsuspendUserAction(applicationId, userId);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
			} catch (error) {
				handleOnError(error);
			} finally {
				if (activeTab !== 'userInfo') {
					setActiveTabAction('userTab', 'userInfo');
				}
			}
		} else {
			try {
				await createClientBankAccountAction(value);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
			} catch (error) {
				handleOnError(error);
			} finally {
				if (activeTab !== 'accounts') {
					setActiveTabAction('userTab', 'accounts');
				}
				if (accountActiveTab !== 'user_bank_accounts') {
					setActiveTabAction('accountTab', 'user_bank_accounts');
				}
			}
		}
	};

	const submitCreditOrDebit = async (value: Value) => {
		setButtonLoading(true);
		ReactGA.event({
			category: 'Full User',
			action: `${type} User`,
			value,
		});
		if (type === 'credit') {
			const creditData = {
				amount: value.values.amount.toString(),
				netFee: value.values.netFee.toString(),
			};
			if (value.instrumentId) {
				const instrumentData = { instrumentId: value.instrumentId };
				try {
					const createdWalletRes = await createWalletAction(account.id, instrumentData);
					getExchangeRatesAction(applicationId, reportingCurrency, queryString.stringify({ quotingInstruments: [value.instrumentId] }, { arrayFormat: 'bracket' }));
					getExchangeRatesAction(applicationId, userReportingCurrency, queryString.stringify({ quotingInstruments: [value.instrumentId] }, { arrayFormat: 'bracket' }));
					await creditWalletAction(applicationId, createdWalletRes.payload.data.id, creditData);
					setButtonLoading(false);
					setWarningModal(false);
					setSubmitted(!submitted);
				} catch (error) { handleOnError(error); }
			} else {
				const wallet = value.wallet.id;
				try {
					await creditWalletAction(applicationId, wallet, creditData);
					setButtonLoading(false);
					setWarningModal(false);
					setSubmitted(!submitted);
				} catch (error) { handleOnError(error); }
			}
		} else if (type === 'debit') {
			const wallet = value.wallet.id;
			const debitData = {
				amount: value.values.amount.toString(),
				netFee: value.values.netFee.toString(),
			};
			try {
				await debitWalletAction(applicationId, wallet, debitData);
				setButtonLoading(false);
				setWarningModal(false);
				setSubmitted(!submitted);
			} catch (error) { handleOnError(error); }
		} else if (type === 'cryptoPayout') {
			const wallet = value.wallet.id;
			const cryptoPayoutData = {
				amount: value.values.amount.toString(),
				destinationAddress: value.values.destinationAddress,
			};
			try {
				await cryptoPayoutAction(wallet, cryptoPayoutData);
				setButtonLoading(false);
				setWarningModal(false);
				setSubmitted(!submitted);
				const parameter = {
					accountId: activeAccount,
					status: ['pendingNew', 'pending'],
				};
					// pending payments
				const paymentRes: PaymentRes = await getApplicationPaymentsAction(applicationId, 1, queryString.stringify(parameter, { arrayFormat: 'comma' }));
				setPendingPayments(paymentRes.payload.data);
			} catch (error) { handleOnError(error); }
		} else if (type === 'internalTransfer') {
			const wallet = value.wallet.id;
			const internalTransferData = {
				amount: value.values.amount.toString(),
				message: value.values.message,
				destinationWalletId: value.values.destinationWalletId,
				userId: fullUser.id,
			};
			try {
				await transferToWalletAction(wallet, internalTransferData);
				setButtonLoading(false);
				setWarningModal(false);
				setSubmitted(!submitted);
			} catch (error) { handleOnError(error); }
		} else {
			const toWallet = wallets.find((el) => el.instrumentId === value.toCurrency);
			let conversionData;
			if (toWallet) {
				conversionData = {
					accountId: account.id,
					amount: value.amount.toString(),
					fromWallet: value.fromWallet,
					toWallet: toWallet.id,
					fixedSide: value.fixedSide,
					fixedRate: value.fixedRate ? value.fixedRate.toString() : undefined,
				};
			} else {
				const instrumentData = { instrumentId: value.toCurrency };
				const walletRes = await createWalletAction(account.id, instrumentData);
				conversionData = {
					accountId: account.id,
					amount: value.amount.toString(),
					fromWallet: value.fromWallet,
					toWallet: walletRes.payload.data.id,
					fixedSide: value.fixedSide,
					fixedRate: value.fixedRate ? value.fixedRate.toString() : undefined,
				};
			}
			try {
				await executeConversionAction(applicationId, conversionData);
				setButtonLoading(false);
				setWarningModal(false);
				setSubmitted(!submitted);
			} catch (error) { handleOnError(error); }
		}
		if (formRef.current) {
			formRef.current.handleReset();
		}
		if (activeTab !== 'accounts') {
			setActiveTabAction('userTab', 'accounts');
		}
	};

	const submitPlaceOrder = async (accId, orderValue) => {
		setButtonLoading(true);
		const parametar = orderValue.type === 'previouslyQuoted' ? '?quote' : '';
		try {
			await placeOrderAction(accId, orderValue, parametar);
			await setActiveAccountAction(accId);
			handleOnSuccess();
			setButtonLoading(false);
			setSuccess(true);
			setSubmitted(!submitted);
			setActiveTabAction('userTab', 'accounts');
			setActiveTabAction('accountTab', 'user_orders');
		} catch (error) {
			setSuccess(false);
			handleOnError(error);
		}
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const submitUserUpdate = async (riskLevel: string) => {
		setButtonLoading(true);
		const riskLevelData = { riskLevel };
		try {
			await updateUserAction(userId, riskLevelData);
			setButtonLoading(false);
			setWarningModal(false);
		} catch (error) {
			handleOnError(error);
			setKycUpdated(!kycUpdated);
		}
	};

	const continueChanges = (value: Value) => {
		setOpenModal(false);
		setWarningModal(true);
		setWarningTitle('Confirmation');
		setMessage({
			firstPart: type === 'check' || type === 'checkProofOfAddress'
				? 'Perform verification checks'
				: createMessage(value),
		});
		setFooter([
			{
				type: 'cancel',
				action: handleWarningCancel,
				text: 'go back',
			},
			{
				type: 'continue',
				action: type === 'check' || type === 'checkProofOfAddress' || type === 'issueCard' || type === 'createClientBankAccount' || type === 'suspend' || type === 'reactivate'
					? () => submitUserActions(value)
					: () => submitCreditOrDebit(value),
				text: 'confirm',
			},
		]);
	};

	const continuePlacingOrder = (accId, value) => {
		setOpenModal(false);
		setWarningModal(true);
		setWarningTitle('Confirmation');
		setMessage({
			firstPart: `Place Order For client ${fullUser.name}`,
		});
		setFooter([
			{
				type: 'cancel',
				action: handleWarningCancel,
				text: 'go back',
			},
			{
				type: 'continue',
				action: () => submitPlaceOrder(accId, value),
				text: 'confirm',
			},
		]);
	};

	const handleUpdateCancel = () => {
		setWarningModal(false);
		setKycUpdated(!kycUpdated);
	};

	const continueUserUpdating = (value: string) => {
		setOpenModal(false);
		setWarningModal(true);
		setWarningTitle('Confirmation');
		setMessage({
			firstPart: `Update Risk Level for client ${fullUser.name}`,
		});
		setFooter([
			{
				type: 'cancel',
				action: handleUpdateCancel,
				text: 'go back',
			},
			{
				type: 'continue',
				action: () => submitUserUpdate(value),
				text: 'confirm',
			},
		]);
	};
	const handleOpenModal = (actionType: string, currentReport: KYCReport) => {
		setSuccess(false);
		setType(actionType);
		setOpenModal(true);
		setReport(currentReport);
		setTitle(`${ActivityHelpers.transformToReadable(actionType)} ${fullUser.name}`);
		setSubtitle(createSubtitle(actionType));
	};

	const handleSettings = async (settingsData: SettingsData) => {
		setButtonLoading(true);
		setOpenModal(false);
		setUpdateForm(false);
		if (part === 'group') {
			try {
				await moveUsersToGroupAction(userId, settingsData);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
			} catch (error) { handleOnError(error); }
		}
		if (part === 'margin') {
			const marginData = settingsData.collateralMultiplier
				? {
					marginTrading: settingsData.marginTrading,
					collateralMultiplier: parseFloat(settingsData.collateralMultiplier),
				}
				: { marginTrading: settingsData.marginTrading };
			const interestData = {
				annualInterestRate: parseFloat(settingsData.annualInterestRate),
				accrualPeriod: settingsData.accrualPeriod,
				compoundPeriod: settingsData.compoundPeriod,
			};
			try {
				await updateUserSettingsAction(userId, marginData);
				getAccountMarginAction(account.id);
				await updateUserMarginInterestAction(userId, interestData);
				getUserSettingsAction(userId);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
				if (activeTab !== 'settings') {
					setActiveTabAction('userTab', 'settings');
				}
			} catch (error) { handleOnError(error); }
		}
		if (part === 'post-trade settlement') {
			const value = {
				postTradeSettlement: settingsData.postTradeSettlement,
				maximumRiskExposureInstrument: settingsData.maximumRiskExposureInstrument,
				maximumRiskExposureAmount: settingsData.maximumRiskExposureAmount.toString(),
			};
			try {
				await updateUserSettingsAction(userId, value);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
				if (activeTab !== 'settings') {
					setActiveTabAction('userTab', 'settings');
				}
			} catch (error) { handleOnError(error); }
		}
		if (part === 'fee') {
			const value = {
				userId,
				...settingsData,
				priority: '1',
				minimumFee: settingsData.minimumFee
					? settingsData.minimumFee.toString()
					: undefined,
				maximumFee: settingsData.maximumFee
					? settingsData.maximumFee.toString()
					: undefined,
				netFee: settingsData.netFee
					? settingsData.netFee.toString()
					: undefined,
				percentageFee: settingsData.percentageFee
					? settingsData.percentageFee.toString()
					: undefined,
			};
			try {
				await addFeeAction(applicationId, value);
				if (formRef.current) {
					formRef.current.handleReset();
				}
				setButtonLoading(false);
				setWarningModal(false);
				if (activeTab !== 'settings') {
					setActiveTabAction('userTab', 'settings');
				}
			} catch (error) { handleOnError(error); }
		}
	};

	const continueSettings = (settingsData: SettingsData) => {
		setWarningModal(true);
		setOpenModal(false);
		setWarningTitle('Confirmation');
		setMessage({
			firstPart: `You're about to change client ${fullUser.name} ${part} setting`,
		});
		setFooter([
			{
				type: 'cancel',
				action: handleWarningCancel,
				text: 'go back',
			},
			{
				type: 'continue',
				action: () => handleSettings(settingsData),
				text: 'confirm',
			},
		]);
	};

	const changeActiveAccount = (id: string) => {
		if (id) {
			setActiveAccountAction(id);
		}
	};

	const marginFeature = appFeature.find((features) => features.featureId === 'margin_trading') || {};
	const settlement = appFeature.find((features) => features.featureId === 'post_trade_settlement') || {};
	const cardEnabled = appFeature.find((features) => features.featureId === 'card_module')?.enabled || false;
	const pendingDeposit: number = pendingPayments.filter((payment: Payment) => payment.type === 'deposit').length;
	const pendingWithdrawal: number = pendingPayments.filter((payment: Payment) => payment.type === 'withdrawal').length;
	const userAccounts = fullUser.accounts;
	const accountTab = userAccounts?.length > 1
		? (
			<AccountsTabContent
				accounts={userAccounts}
				accountsTotal={userAccounts?.length}
				activeAccountId={activeAccount}
				action={changeActiveAccount}
				openModal={handleOpenModal}
			/>
		)
		: 'ACCOUNTS';
	const tags = [
		fullUser.status,
		userSettings.marginTrading ? 'marginEnabled' : '',
		userSettings.postTradeSettlement ? 'postTradeSettlementEnabled' : '',
		fullUser.suspended ? 'suspended' : '',
	];

	return (
		<>
			{fullUser.id
				? 	(
					<>
						<UserTitle
							accountId={account.id}
							applicationId={applicationId}
							backAction={history.length > 1 ? handleBackClick : undefined}
							tags={tags}
							user={fullUser}
							accounts={accounts}
							openModal={handleOpenModal}
							openSettings={openSettings}
							appMarginEnabled={marginFeature.enabled}
							postTradeSettlementEnabled={settlement.enabled}
							updateUserStatus={updateUserStatus}
							rates={rates}
							reportingCurrency={reportingCurrency}
							pendingDeposit={pendingDeposit}
							pendingWithdrawal={pendingWithdrawal}
							cardEnabled={cardEnabled}
							tabs={[
								{
									title: accountTab,
									key: 'accounts',
								},
								{
									title: 'CLIENT INFO',
									key: 'userInfo',
								},
								{
									title: 'ACTIVITY LOG',
									key: 'activityLog',
								},
								{
									title: 'KYC & DOCUMENTS',
									key: 'kycAndDocumentation',
								},
								{
									title: 'SETTINGS INFO',
									key: 'settings',
								},
							]}
						/>
						<div className={styles.userContainer}>
							{activeTab === 'accounts' && (
							userAccounts?.length ? (
								<AccountsPage
									applicationId={applicationId}
									account={account}
									wallets={wallets}
									isFetchingWallets={isFetchingWallets}
									rates={rates}
									reportingCurrency={reportingCurrency}
									accountStatistics={accountStatistics}
									accountsTotal={userAccounts?.length}
								/>
							) : (
								<div className="empty-state">
									<EmptyWallet />
									<p>This client has no account.</p>
								</div>
							)
							)}
							{activeTab === 'userInfo' && (
								<UserInfo
									fullUser={fullUser}
								/>
							)}
							{activeTab === 'activityLog' && (
								<Activity
									applicationId={applicationId}
									userId={userId}
								/>
							)}
							{activeTab === 'kycAndDocumentation' && (
								<KYC
									status={fullUser.status}
									userId={userId}
									userAddress={fullUser.userAddress}
									applicationId={applicationId}
									name={fullUser.name}
									updateUserStatus={(status: string) => updateUserStatus(status)}
									documents={fullUser.documents}
									key={kycUpdated.toString()}
									handleDocumentUpload={() => handleOpenModal('uploadDocumentFor')}
									handlePerformCheck={ (report) => handleOpenModal('checkProofOfAddress', report)}
									userRiskLevel={fullUser.riskLevel}
									setUserRiskLevel={continueUserUpdating}
								/>
							)}
							{activeTab === 'settings' && (
								<SettingsInfo
									userSettings={userSettings}
									applicationId={applicationId}
									userId={userId}
									viewFee={openSingleFee}
									appMarginEnabled={marginFeature.enabled}
									postTradeSettlementEnabled={settlement.enabled}
								/>
							)}
							{type === 'settings' && updateForm
						&& (
							<FormModal
								title={title}
								visible={openModal}
								cancelFunction={handleEditCancel}
								form={(
									<SettingsForm
										part={part}
										submitChanges={continueSettings}
										handleCancelClick={handleEditCancel}
										userSettings={userSettings}
										accountMargin={accountMargin}
										group={fullUser.groupId}
										wrappedComponentRef={formRef}
										applicationId={applicationId}
									/>
								)}
							/>
						)}
							{(type === 'credit' || type === 'debit' || type === 'internalTransfer' || type === 'cryptoPayout' || type === 'conversion')
						&& (
							<FormModal
								title={title}
								visible={openModal}
								cancelFunction={handleEditCancel}
								form={(
									type === 'conversion'
										? (
											<ConversionForm
												submitChanges={continueChanges}
												handleCancelClick={handleEditCancel}
												wallets={wallets}
												place="client"
												wrappedComponentRef={formRef}
												accountId={activeAccount}
												applicationId={applicationId}
											/>
										)
										: (
											<CreditDebitForm
												key={type}
												subtitle={subtitle}
												submitChanges={continueChanges}
												handleCancelClick={handleEditCancel}
												wallets={wallets}
												type={type}
												wrappedComponentRef={formRef}
												accounts={accounts}
												place="client"
												applicationId={applicationId}
											/>
										)
								)}
							/>
						)}
							{(type === 'check' || type === 'checkProofOfAddress')
						&& (
							<FormModal
								title={title}
								visible={openModal}
								cancelFunction={handleEditCancel}
								form={(
									<KycForm
										submitChanges={continueChanges}
										handleCancelClick={handleEditCancel}
										wrappedComponentRef={formRef}
										applicationId={applicationId}
										documents={fullUser.documents}
										type={type}
									/>
								)}
							/>
						)}
							{type === 'issueCard'
						&& (
							<FormModal
								title={title}
								visible={openModal}
								cancelFunction={handleEditCancel}
								form={(
									<IssueCardForm
										submitChanges={continueChanges}
										handleCancelClick={handleEditCancel}
										wrappedComponentRef={formRef}
										user={fullUser}
										wallets={wallets}
										applicationId={applicationId}
									/>
								)}
							/>
						)}
							{type === 'createClientBankAccount'
						&& (
							<FormModal
								title={title}
								visible={openModal}
								cancelFunction={handleEditCancel}
								form={(
									<CreateBankAccountForm
										submitChanges={continueChanges}
										handleCancelClick={handleEditCancel}
										wrappedComponentRef={formRef}
										wallets={wallets}
										applicationId={applicationId}
										account={account}
									/>
								)}
							/>
						)}
							{type === 'account' && (
								<FormModal
									title={title}
									visible={openModal}
									cancelFunction={handleEditCancel}
									form={(
										<AllAccounts userAccounts={userAccounts} handleCancelClick={handleEditCancel} />
									)}
								/>
							)}
							{type === 'suspend' && (
								<FormModal
									title={title}
									visible={openModal}
									cancelFunction={handleEditCancel}
									form={(
										<SuspendUserForm
											submitChanges={continueChanges}
											handleCancelClick={handleEditCancel}
											wrappedComponentRef={formRef}
											applicationId={applicationId}
											type={type}
										/>
									)}
								/>
							)}
							{type === 'reactivate' && (
								<FormModal
									title={title}
									visible={openModal}
									cancelFunction={handleEditCancel}
									form={(
										<SuspendUserForm
											submitChanges={continueChanges}
											handleCancelClick={handleEditCancel}
											wrappedComponentRef={formRef}
											type={type}
										/>
									)}
								/>
							)}
							{type === 'uploadDocumentFor' && (
								<FormModal
									title={title}
									visible={openModal}
									cancelFunction={handleEditCancel}
									form={(
										<UploadForm
											submitChanges={submitUpload}
											handleCancelClick={handleEditCancel}
											wrappedComponentRef={formRef}
											type={type}
											userId={userId}
										/>
									)}
								/>
							)}
							{type === 'placeOrderFor' && (
								<FormModal
									title={title}
									visible={openModal}
									cancelFunction={handleEditCancel}
									form={(
										<OrderForm
											key={type}
											submitChanges={continuePlacingOrder}
											handleCancelClick={handleEditCancel}
											wrappedComponentRef={formRef}
											applicationId={applicationId}
											userId={userId}
											userName={fullUser.name}
											accounts={accounts}
											activeAccount={activeAccount}
										/>
									)}
								/>
							)}
							<WarningModal
								title={warningTitle}
								visible={warningModal}
								cancelFunction={handleErrorCancel}
								footer={footer}
								message={message}
								loading={buttonLoading}
								success={success}
							/>
						</div>
					</>
				)
				: <Spin spinning={!fullUser.id} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	fullUser: fullUserMappedSelector(state),
	userIsFetched: state.users.userIsFetched,
	isFetchingUser: state.users.isFetchingUser,
	rates: state.static.rates,
	activeTab: state.ui.userTab,
	accountActiveTab: state.ui.accountTab,
	accounts: state.account.accounts,
	activeAccount: state.account.activeAccount,
	account: state.account.account,
	wallets: state.wallet.wallets,
	isFetchingWallets: state.wallet.isFetchingWallets,
	userSettings: state.users.userSettings,
	accountMargin: accountMarginSelector(state),
	appFeature: state.feature.appFeatures,
	userReportingCurrency: state.users.userReportingCurrency,
	accountStatistics: state.account.accountStatistics,
	userTags: state.tags.systemTags,
});

const mapDispatchToProps = {
	getUser,
	moveUsersToGroup,
	updateStatus,
	getExchangeRates,
	getAccounts,
	getAccount,
	setActiveAccount,
	getApplicationPayments,
	getWallets,
	creditWallet,
	debitWallet,
	transferToWallet,
	createWallet,
	setActiveTab,
	setCurrentFilters,
	getUserSettings,
	updateUserSettings,
	getAccountMargin,
	setUserReportingCurrency,
	getAppFeatures,
	updateUserMarginInterest,
	getAccountMarginInterest,
	addFee,
	getAccountStatistics,
	cryptoPayout,
	createApplicant,
	triggerCheck,
	issueCard,
	createClientBankAccount,
	executeConversion,
	suspendUser,
	unsuspendUser,
	placeOrder,
	updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(FullUserPage);
