// @flow

import {
	GET_EXPLORE_GROUPS,
	GET_EXPLORE_GROUP,
	ADD_EXPLORE_GROUP,
	UPDATE_EXPLORE_GROUP,
	REMOVE_EXPLORE_GROUP,
	ADD_SYMBOL_TO_EXPLORE,
	REMOVE_SYMBOL_FROM_EXPLORE,
} from '../const/types';

export function getExploreGroups(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_EXPLORE_GROUPS,
		payload: {
			request: {
				url: `/applications/${applicationId}/explore/groups`,
			},
		},
	});
}

export function getExploreGroup(applicationId: string, exploreGroupId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_EXPLORE_GROUP,
		payload: {
			request: {
				url: `/applications/${applicationId}/explore/groups/${exploreGroupId}`,
			},
		},
	});
}

export function addExploreGroup(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_EXPLORE_GROUP,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/explore/groups`,
				data,
			},
		},
	});
}

export function updateExploreGroup(applicationId: string, exploreGroupId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_EXPLORE_GROUP,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/explore/groups/${exploreGroupId}`,
				data,
			},
		},
	});
}

export function removeExploreGroup(applicationId: string, exploreGroupId: string) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_EXPLORE_GROUP,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/explore/groups/${exploreGroupId}`,
			},
		},
	});
}

export function addSymbolToExploreGroup(applicationId: string, exploreGroupId: string, symbolId: string) {
	return (dispatch: Function): void => dispatch({
		type: ADD_SYMBOL_TO_EXPLORE,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/explore/groups/${exploreGroupId}/add-symbol/${symbolId}`,
			},
		},
	});
}

export function removeSymbolFromExploreGroup(applicationId: string, exploreGroupId: string, symbolId: string) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_SYMBOL_FROM_EXPLORE,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/explore/groups/${exploreGroupId}/remove-symbol/${symbolId}`,
			},
		},
	});
}
