import React from 'react';
import { render } from 'react-dom';
import moment from 'moment';
import 'moment/locale/en-ie';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './registerServiceWorker';
import DashApp from './dashApp';

const target = document.querySelector('#root');

moment.locale('en-ie');
if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://23db9cd77c95471fa03862136b0a11f0@sentry.tradecore.io/214',
	});
}

render(
	<ConfigProvider locale={enUS}>
		<DashApp />
	</ConfigProvider>,
	target,
);

// Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./dashApp.js', () => {
		const NextApp = DashApp.default;
		render(
			<NextApp />,
			document.getElementById('root'),
		);
	});
}
serviceWorker.unregister();
