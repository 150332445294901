// @flow

import React from 'react';
//= import components
import Card from '../../../components/UiElements/Card';
import IconPlaceholder from '../../../components/UiElements/Illustrations/IconPlaceholder';
import CoverPlaceholder from '../../../components/UiElements/Illustrations/CoverPlaceholder';

type Props = {
	name: string,
	icon: string,
	logo: string,
}
const ApplicationInfo = (props: Props) => {
	const { name, icon, logo } = props;

	return (
		<div>
			<Card className="custom-card" bordered={false}>
				<h2>Application Details</h2>
				<div className="app-name">
					<h5>APP NAME</h5>
					<span>{name}</span>
				</div>
				<h4>App Icon</h4>
				<p>
					Appears on the Application List in your Panel.
					It&apos;s recommended to use your Application&apos;s Launch Icon.
				</p>
				<div className="icon">
					{icon
						? <img src={icon} alt="icon" />
						: <IconPlaceholder />}
				</div>
				<h4>App Logo</h4>
				<p>
					Appears on Statements, Terms &amp; Conditions documents and Emails you send to your client.
					Use the full version of your Application Logo.
				</p>
				<div className="cover">
					{logo
						? <img src={logo} alt="cover" />
						: <CoverPlaceholder />}
				</div>
			</Card>
		</div>
	);
};
export default ApplicationInfo;
