// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../../components/UiElements/Title';
import FeatureCard from '../components/FeatureCard';
//= import actions
import { getAppFeatures } from '../../../modules/actions/FeaturesAction';
//= import types
import type { Feature } from '../../../modules/reducers/FeatureReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	match: {
		params: {
			applicationId: string,
		}
	},
	history: {
		push: (Object) => void,
		goBack: () => void,
	},
	features: Array<Feature>,
	getAppFeatures: (string) => void,
}

function AppFeatures(props: Props) {
	const {
		match: { params: { applicationId } },
		history,
		features,
		getAppFeatures: getAppFeaturesAction,
	} = props;

	useEffect(() => {
		getAppFeaturesAction(applicationId);
	}, [getAppFeaturesAction, applicationId]);

	const handleSelect = (place: string): void => {
		history.push({
			pathname: `features/${place}`,
		});
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="features"
			/>
			<div className="page-container">
				<p>
					Here you can see all the Features available in Broker Cloud’s system.
					If you would like a particular Feature enabled for your Application,
					you may request it by clicking on the &apos;Request this Feature&apos; button.
				</p>
				{features.map((feature) => (
					<FeatureCard
						key={feature.id}
						feature={feature}
						handleSelect={handleSelect}
					/>
				))}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	features: state.feature.appFeatures,
});

const mapDispatchToProps = {
	getAppFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppFeatures);
