// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
// import components
import Icon from '../Icon';
import Popover from '../Popover';

const COPY_STYLE = {
	wordBreak: 'break-word',
};

type Props = {
	text: string,
	content: Node,
}
const CopyComponent = (props: Props) => {
	const {
		text, content,
	} = props;

	const [visible, setVisible] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(text);
	};

	return (
		<div className="flex-container" style={COPY_STYLE}>
			{content}
			<Popover
				content="Copied!"
				placement="right"
				visible={visible}
				onClick={() => setVisible(true)}
				onMouseLeave={() => setVisible(false)}
			>
				<div>
					<Icon name="Duplicate" onClick={copyToClipboard} size={14} />
				</div>
			</Popover>
		</div>
	);
};

export default CopyComponent;
