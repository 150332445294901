// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
//= import componnets
import CPButton from '../../../components/UiElements/Button';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Card from '../../../components/UiElements/Card';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import Table from '../../../components/UiElements/Table';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import Block from '../../../components/UiElements/Illustrations/Block';
import Carousel from '../../../components/UiElements/Illustrations/Carousel';
import List from '../../../components/UiElements/Illustrations/List';
import IconPlaceholder from '../../../components/UiElements/Illustrations/IconPlaceholder';
import CoverPlaceholder from '../../../components/UiElements/Illustrations/CoverPlaceholder';
import AddToForm from '../../../components/UiElements/Form/AddToForm';
import GroupAndSectionForm from '../components/GroupAndSectionForm';
//= import methods
import {
	getExploreGroup,
	updateExploreGroup,
	removeExploreGroup,
	addSymbolToExploreGroup,
	removeSymbolFromExploreGroup,
} from '../../../modules/actions/ExploreGroupActions';
import getFile from '../../../modules/actions/FilesActions';
//= import types
import type { ExploreGroup } from '../../../modules/reducers/ExploreGroupReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getExploreGroup: (string, string) => Promise<Object>,
	removeExploreGroup: (string, string) => Promise<Object>,
	updateExploreGroup: (string, string, Object) => Promise<Object>,
	getFile: (string) => Promise<Object>,
	removeSymbolFromExploreGroup: (string, string, string) => Promise<Object>,
	addSymbolToExploreGroup: (string, string, string) => Promise<Object>,
	exploreGroup: ExploreGroup | Object,
	match: {
		params: {
			applicationId: string,
			groupId: string,
		},
	},
	location: {
		search: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}
type LocalState = {
	edit: boolean,
	warningModal: boolean,
	warningTitle: string,
	message: Object,
	deleteMessage: string,
	footer: Array<Object>,
	deleteClicked: boolean,
	icon: string,
	coverPhoto: string,
	symbolData: Array<Object>,
	addSymbol: boolean,
	btnText: string,
	remove: boolean,
	symbolId: string,
	resetForm: boolean,
	buttonLoading: boolean,
}
type Response = {
	payload: {
		data: {
			icon: string,
			coverPhoto: string,
			url: string,
			exploreSymbols: Array<Object>,
		}
	}
}
class ExploreGroupDetails extends React.Component<Props, LocalState> {
	state = {
		edit: false,
		warningModal: false,
		warningTitle: '',
		message: {},
		deleteMessage: '',
		footer: [],
		deleteClicked: false,
		icon: '',
		coverPhoto: '',
		symbolData: [],
		addSymbol: false,
		btnText: '',
		remove: false,
		symbolId: '',
		resetForm: false,
		buttonLoading: false,
	}

	formRef: ?GroupAndSectionForm;

	componentDidMount() {
		const { match: { params: { applicationId, groupId } } } = this.props;
		this.props.getExploreGroup(applicationId, groupId)
			.then((exploreGroupData: Response) => {
				if (exploreGroupData.payload.data.icon) {
					const iconUrl = new URL(exploreGroupData.payload.data.icon);
					const iconId = iconUrl.pathname;
					this.props.getFile(iconId)
						.then((res: Response) => this.setState({
							icon: res.payload.data.url,
						}));
				}
				if (exploreGroupData.payload.data.coverPhoto) {
					const coverUrl = new URL(exploreGroupData.payload.data.coverPhoto);
					const coverId = coverUrl.pathname;
					this.props.getFile(coverId)
						.then((res) => this.setState({
							coverPhoto: res.payload.data.url,
						}));
				}
				this.setState({
					symbolData: exploreGroupData.payload.data.exploreSymbols.slice(0, 10),
				});
			});
	}

	handleBackClick = () => {
		this.props.history.goBack();
	}

	// Delete fee rule

	handleDeleteClick = () => {
		this.setState({
			deleteClicked: true,
			deleteMessage: `Once you remove ${this.props.exploreGroup.name} Group
			you won't be able to retrieve it.`,
			btnText: 'Delete Group',
		});
	}

	deleteExploreGroup = () => {
		const { match: { params: { applicationId, groupId } } } = this.props;
		this.setState({
			deleteClicked: false,
		});
		this.props.removeExploreGroup(applicationId, groupId)
			.then(() => this.props.history.push(`/application/${applicationId}/system/explore`))
			.catch((error) => this.handleOnError(error));
	}

	handleDeleteCancel = () => {
		this.setState({
			warningModal: false,
			deleteClicked: false,
		});
	}

	// Edit group

	handleEditClick = () => {
		this.setState({
			edit: true,
			addSymbol: false,
			resetForm: true,
		});
	}

	handleEditCancel = () => {
		if (this.formRef && this.formRef.props.form.isFieldsTouched()) {
			this.setState({
				edit: false,
				warningModal: true,
				warningTitle: 'Warning!',
				message: {
					firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
				},
				footer: [
					{ type: 'cancel', action: this.handleErrorCancel, text: 'cancel edit' },
					{ type: 'continue', action: this.handleWarningCancel, text: 'continue editing' },
				],
			});
		} else {
			if (this.formRef) {
				this.formRef.handleReset();
			}
			this.setState({
				edit: false,
				warningModal: false,
				resetForm: false,
			});
		}
	}

	continueEditing = (value) => {
		this.setState({
			warningModal: true,
			edit: false,
			warningTitle: 'Are you sure?',
			message: {
				firstPart: `Once you confirm these changes,
						they will affect ${this.props.exploreGroup.name} Explore Group.`,
				secondPart: 'It is advised to proceed editing with caution.',
			},
			footer: [
				{ type: 'cancel', action: this.handleWarningCancel, text: 'go back' },
				{ type: 'continue', action: () => this.submitEditGroup(value), text: 'edit group' },
			],
		});
	}

	submitEditGroup = (data) => {
		this.setState({
			buttonLoading: true,
		});
		const { match: { params: { applicationId } }, exploreGroup } = this.props;
		this.props.updateExploreGroup(applicationId, exploreGroup.id, data)
			.then((groupData: Response) => {
				if (groupData.payload.data.icon) {
					const iconUrl = new URL(groupData.payload.data.icon);
					const iconId = iconUrl.pathname;
					this.props.getFile(iconId)
						.then((res: Response) => this.setState({
							icon: res.payload.data.url,
						}));
				}
				if (groupData.payload.data.coverPhoto) {
					const coverUrl = new URL(groupData.payload.data.coverPhoto);
					const coverId = coverUrl.pathname;
					this.props.getFile(coverId)
						.then((res: Response) => this.setState({
							coverPhoto: res.payload.data.url,
						}));
				}
				this.setState({
					buttonLoading: false,
					warningModal: false,
					resetForm: false,
				});
			})
			.catch((error) => this.handleOnError(error));
		if (this.formRef) {
			this.formRef.handleReset();
		}
	}

	// Add to group

	handleAddToGroup = () => {
		this.setState({
			addSymbol: true,
			edit: true,
			resetForm: true,
		});
	}

	submitAddToGroup = (data) => {
		this.setState({
			buttonLoading: true,
		});
		const { match: { params: { applicationId } }, exploreGroup } = this.props;
		this.setState({
			edit: false,
		});
		this.props.addSymbolToExploreGroup(applicationId, exploreGroup.id, data)
			.then((res) => {
				this.setState({
					symbolData: res.payload.data.exploreSymbols.slice(0, 10),
					addSymbol: false,
					buttonLoading: false,
				});
			})
			.catch((error) => this.handleOnError(error));
	}

	// Remove from group

	handleRemoveFromGroup = (symbol) => {
		this.setState({
			deleteClicked: true,
			deleteMessage: `You want to remove ${symbol.displayName} Symbol
				from ${this.props.exploreGroup.name} Explore Group`,
			btnText: 'Remove symbol',
			remove: true,
			symbolId: symbol.id,
		});
	}

	removeFromGroup = () => {
		const { match: { params: { applicationId, groupId } } } = this.props;
		this.setState({
			deleteClicked: false,
			remove: false,
			buttonLoading: true,
		});
		this.props.removeSymbolFromExploreGroup(applicationId, groupId, this.state.symbolId)
			.then((response) => {
				this.setState({
					symbolData: response.payload.data.exploreSymbols.slice(0, 10),
					buttonLoading: false,
				});
			})
			.catch((error) => {
				this.handleOnError(error);
			});
	}
	// Edit and Delete

	printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	handleOnError = (error) => {
		this.setState({
			buttonLoading: false,
			warningModal: true,
			warningTitle: 'Error',
			message: {
				firstPart: error.error.response ? error.error.response.data.message : 'Error',
				secondPart: this.printError(error.error.response.data.errors),
			},
			footer: [
				{ type: 'cancel', action: this.handleDeleteCancel, text: 'close' },
			],
		});
	}

	handleErrorCancel = () => {
		if (this.formRef) {
			this.formRef.handleReset();
		}
		this.setState({
			warningModal: false,
			addSymbol: false,
			resetForm: false,
		});
	}

	handleWarningCancel = () => {
		this.setState({
			edit: true,
			warningModal: false,
			addSymbol: false,
		});
	}

	viewAll = () => {
		this.setState({
			symbolData: this.props.exploreGroup.exploreSymbols,
		});
	}

	render() {
		const {
			warningModal,
			warningTitle,
			message,
			footer,
			deleteClicked,
			icon,
			coverPhoto,
			symbolData,
			edit,
			addSymbol,
			btnText,
			deleteMessage,
			remove,
			resetForm,
			buttonLoading,
		} = this.state;
		const { exploreGroup, match: { params: { applicationId } } } = this.props;

		const localData = localStorage.getItem(applicationId);
		const data = localData ? JSON.parse(localData) : null;
		const symbols = data ? data.symbols : [];

		const groups = exploreGroup && {
			symbolsInGroup: exploreGroup.exploreSymbols ? exploreGroup.exploreSymbols.length : 0,
		};
		const description = exploreGroup && {
			groupDescription: exploreGroup.description ? exploreGroup.description : '-',
		};
		const display = exploreGroup && {
			displayType: exploreGroup.display,
		};
		const displayComponent = {
			blocks: <Block />,
			list: <List />,
			carousel: <Carousel />,
		};
		const symbolsNotAdded = differenceBy(symbols, exploreGroup.exploreSymbols, 'id');
		const columns = [{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 192,
			render: (text: string) => (
				<a
					href={`/application/${applicationId}/system/symbols/${text}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{text}
				</a>
			),
		}, {
			title: 'name',
			dataIndex: 'displayName',
			key: 'name',
			width: 256,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		}, {
			title: 'remove symbol',
			dataIndex: '',
			key: 'remove',
			width: 160,
			render: () => (
				<span className="button-text">Remove Symbol</span>
			),
			onCell: (record) => ({
				onClick: (e) => {
					e.stopPropagation();
					this.handleRemoveFromGroup(record);
				},
			}),
		}];
		return (
			<>
				{Object.keys(exploreGroup).length
					? (
						<div>
							<Title
								backAction={this.handleBackClick}
								title={exploreGroup.name}
								buttons={[{
									action: this.handleEditClick,
									text: 'edit group details',
								},
								{
									action: this.handleDeleteClick,
									text: 'delete group',
									type: 'danger',
								}]}
							/>
							<div className="container">
								<Row gutter={24} type="flex" className="section">
									<Col span={16} className="col-margin description">
										<TextCard data={description} colNumber={1} left="left-2" />
									</Col>
									<Col span={8} className="col-margin">
										<InfoCard data={groups} />
									</Col>
								</Row>
								<Row gutter={24} type="flex" className="section">
									<Col span={16} className="col-margin">
										<Card className="table-card" bordered={false}>
											<div className="flex-container">
												<h2>Symbols in Group</h2>
												<CPButton
													ghost
													action={this.handleAddToGroup}
													icon="Plus"
													text="add symbol to group"
												/>
											</div>
											<Table
												rowKey={(record) => record.id}
												columns={columns}
												dataSource={symbolData}
												locale={{ emptyText: 'No groups!' }}
												pagination={false}
											/>
											{exploreGroup.exploreSymbols.length > 10
												&& (
													<CPButton
														ghost
														text="view all"
														action={this.viewAll}
													/>
												)}
										</Card>
									</Col>
									<Col span={8}>
										<Row gutter={24}>
											<Col span={24}>
												<TextCard
													title="Group Display"
													data={display}
													colNumber={1}
													image={displayComponent[exploreGroup.display]}
												/>
											</Col>
											<Col span={24}>
												<Card className="custom-card" bordered={false}>
													<h2>Group Images</h2>
													<h4>Group Icon</h4>
													<p>Simple icon that appears next to your Group&apos;s name.</p>
													<div className="icon">
														{icon
															? <img src={icon} alt="icon" />
															: <IconPlaceholder />}
													</div>
													<h4>Cover Photo</h4>
													<p>
														More complex graphic, appears on the top of Group Screen.
													</p>
													<div className="cover">
														{coverPhoto
															? <img src={coverPhoto} alt="cover" />
															: <CoverPlaceholder width="270" height="84" />}
													</div>
												</Card>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						</div>
					)
					: <Spin spinning={!Object.keys(exploreGroup)} />}
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={this.handleDeleteCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
				<DeleteModal
					okFunction={remove ? this.removeFromGroup : this.deleteExploreGroup}
					cancelFunction={this.handleDeleteCancel}
					visible={deleteClicked}
					btnText={btnText}
					message={deleteMessage}
				/>
				{resetForm
					&& (
						<FormModal
							title={`Edit ${exploreGroup.name} Group`}
							visible={edit}
							cancelFunction={this.handleEditCancel}
							form={addSymbol ? (
								<AddToForm
									submitChanges={this.submitAddToGroup}
									handleCancelClick={this.handleEditCancel}
									data={symbolsNotAdded}
									btnText="add to group"
									columnsData={['id', 'displayName']}
								/>
							)
								: (
									<GroupAndSectionForm
										submitChanges={this.continueEditing}
										handleCancelClick={this.handleEditCancel}
										wrappedComponentRef={(element) => { this.formRef = element; }}
										data={exploreGroup}
										icon={icon}
										coverPhoto={coverPhoto}
										place="Group"
									/>
								)}
						/>
					)}
			</>
		);
	}
}

const mapStateToProps = (state: State) => ({
	exploreGroup: state.exploreGroup.exploreGroup,
});

const mapDispatchToProps = {
	getExploreGroup,
	updateExploreGroup,
	removeExploreGroup,
	getFile,
	addSymbolToExploreGroup,
	removeSymbolFromExploreGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreGroupDetails);
