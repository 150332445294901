// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../../components/UiElements/Title';
import CloseOnlySymbols from '../components/CloseOnlySymbols';
import DelistedInstruments from '../components/DelistedInstruments';
import DelistedInventory from '../components/DelistedInventory';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	activeTab: string,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		goBack: () => void,
		push: string => void,
	},
}
const Delistings = (props: Props) => {
	const {
		match: { params: { applicationId } },
		activeTab,
		history: { push },
	} = props;

	const handleBackClick = () => {
		props.history.goBack();
	};

	const viewSymbol = (symbol: Symbol) => {
		push(`/application/${applicationId}/system/symbols/${symbol.id}`);
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Delistings"
				place={activeTab}
				tab="delistings"
				tabs={[
					{
						key: 'closeOnly',
						title: 'CLOSE ONLY SYMBOLS',
					},
					{
						key: 'instruments',
						title: 'DELISTED INSTRUMENTS',
					},
					{
						key: 'inventory',
						title: 'DELISTED SYMBOL INVENTORY',
					},
				]}
				searchComponent={activeTab === 'closeOnly'
					? {
						placeholder: 'Search...',
					}
					: ''}
			/>
			<div className="container">
				{activeTab === 'closeOnly' && <CloseOnlySymbols applicationId={applicationId} viewSymbol={viewSymbol} />}
				{activeTab === 'instruments' && <DelistedInstruments applicationId={applicationId} />}
				{activeTab === 'inventory' && <DelistedInventory applicationId={applicationId} />}
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	activeTab: state.ui.delistingsTab,
});

export default connect(mapStateToProps)(Delistings);
