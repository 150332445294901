// @flow

import * as React from 'react';
import Modal from './index';
import CPButton from '../Button';
import Icon from '../Icon';

type Props = {
	okFunction: Function,
	cancelFunction: Function,
	visible: boolean,
	btnText: string,
	message: string,
	loading?: boolean,
};
const DeleteModal = (props: Props) => {
	const {
		okFunction, cancelFunction, visible, btnText, message, loading,
	} = props;
	return (
		<Modal
			title={(
				<div className="flex">
					<Icon name="Delete" colorName="danger" style={{ marginRight: '8px' }} />
					<h4>Are you sure?</h4>
				</div>
			)}
			visible={visible}
			onOk={okFunction}
			onCancel={cancelFunction}
			maskClosable={false}
			closable={false}
			centered
			wrapClassName="warning-modal"
			footer={[
				<CPButton
					ghost
					key="cancel"
					action={cancelFunction}
					text="cancel"
				/>,
				<CPButton
					type="danger"
					key="continue"
					action={okFunction}
					text={btnText}
					loading={loading}
				/>,
			]}
		>
			<div className="warning-message">
				<p>{message}</p>
			</div>
		</Modal>
	);
};

DeleteModal.defaultProps = {
	loading: false,
};

export default DeleteModal;
