// @flow
import React, {useCallback} from 'react';
//= import components
import FormModal from '../../../../Modal/FormModal';
import TwoFactorForm from './TwoFactorForm';

type Props = {
	visible: bool,
	closeModal: (string) => void,
};

const TwoFactorSettingsModal = ({ visible, closeModal } : Props) => {
	const close2FA = useCallback(() => {
		closeModal('two-factor');
	}, [closeModal]);
	return (
		<>
		<FormModal
			title="Two-factor authenticator settings"
			visible={visible}
			cancelFunction={close2FA}
			form={<TwoFactorForm handleCancelClick={close2FA} />}
		/>
	</>
	);
};

export default TwoFactorSettingsModal;
