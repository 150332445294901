// @flow

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import map from 'lodash/map';
//= import components
import DatePicker from '../DatePicker';
import CPButton from '../Button';
import Select from '../Select';
import Input from '../Input';
import Form from '../Form';
// import styles
import styles from './assets/export.module.scss';

type Values = {
	from: ?string,
	to: ?string,
	type: ?string,
	side: ?string,
}
type ExportOptions = {
	dateRange: {
		options: string,
		initialValue: Array<Object>,
	},
	fromCurrency: { options: Array<Object>, initialValue: Array<string>, },
	toCurrency: { options: Array<Object>, initialValue: Array<string>, },
	userId: { options: string, initialValue: Array<string>, },
	accountId: { options: string, initialValue: Array<string>, },
	fromWalletId: { options: string, initialValue: Array<string>, },
	toWalletId: { options: string, initialValue: Array<string>, },
	reference: { options: Array<Object>, initialValue: Array<string>, },
	referenceId: { options: string, initialValue: Array<string>, },
}
type Props = {
	form: *,
	cancelFunction: () => void,
	visible: boolean,
	title: string,
	submitFunction: (Values) => void,
	exportOptions: ExportOptions,
	mode?: string,
}
const ExportForm = (props: Props) => {
	const {
		cancelFunction, submitFunction, exportOptions, mode, form, form: { getFieldDecorator },
	} = props;

	const [selectedDate, setDate] = useState([]);

	useEffect(() => {
		setDate(exportOptions.dateRange.initialValue);
	}, [exportOptions.dateRange.initialValue]);

	const chooseDate = (value) => {
		setDate(value);
		form.setFieldsValue({ dateRange: value });
	};

	const submitData = (e) => {
		e.preventDefault();
		form.validateFields((err: Array<Object>, values) => {
			if (!err) {
				const data = {
					...values,
					from: selectedDate[0].format('YYYY-MM-DD 00:00:00'),
					to: selectedDate[1].format('YYYY-MM-DD 23:59:59'),
					dateRange: undefined,
				};
				submitFunction(data);
			}
		});
	};

	const { Option } = Select;
	const FormItem = Form.Item;
	const { RangePicker } = DatePicker;
	const groupLayout = {
		labelCol: {
			xs: { span: 9 },
		},
		wrapperCol: {
			xs: { span: 15 },
		},
	};

	const buttons = [
		{
			action: () => chooseDate([moment(), moment()]),
			text: 'today',
		},
		{
			action: () => chooseDate([moment().startOf('month'), moment()]),
			text: 'this month',
		},
		{
			action: () => chooseDate([moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]),
			text: 'last month',
		}];

	const renderFormItem = (value, key) => {
		if (value.options === 'input') {
			return (
				<Input
					placeholder={`Paste value ${value.label}`}
					maxLength={100}
				/>
			);
		} if (value.options === 'time') {
			return (
				<RangePicker
					className={styles.date}
					dropdownClassName={styles.picker}
					onChange={(data) => setDate(data)}
				/>
			);
		}
		return (
			<Select
				showSearch
				allowClear
				mode={mode}
			>
				{value.options.map((type) => (
					<Option
						value={type.id || type}
						key={type.id || type}
						className="capitalize"
					>
						{type.id || type}
					</Option>
				))}
			</Select>
		);
	};

	return (
		<Form onSubmit={submitData} layout="horizontal" hideRequiredMark labelalign="left">
			<div className={styles.buttons}>
				{buttons.map((button) => (
					<CPButton
						key={button.text}
						action={button.action}
						text={button.text}
					/>
				))}
			</div>
			{map(exportOptions, (value, key) => (
				<FormItem label={value.label || ''} {...groupLayout} help="" key={key}>
					{getFieldDecorator(key, {
						initialValue: value.options === 'input' ? value.initialValue?.[0] : value.initialValue,
					})(
						renderFormItem(value, key),
					)}
				</FormItem>
			))}
			<div className="form-buttons">
				<FormItem>
					<CPButton
						ghost
						text="cancel"
						action={cancelFunction}
					/>
				</FormItem>
				<FormItem>
					<CPButton
						type="primary"
						text="export"
						disabled={!selectedDate.length}
						action={submitData}
					/>
				</FormItem>
			</div>
		</Form>
	);
};

ExportForm.defaultProps = {
	mode: 'multiple',
};

const Export = Form.create()(ExportForm);

export default Export;
