import { createStore, applyMiddleware, compose } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import queryString from 'query-string';
import createRootReducer from '../../modules/reducers';

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];

const client = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/v1`,
	transformRequest: [(data, headers) => {
		const authData = localStorage.getItem('@Auth:auth');
		const accessToken = authData ? JSON.parse(authData).accessToken : undefined;
		const nameData = localStorage.getItem('@Auth:appName');
		headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
		headers['Content-Type'] = 'application/json';
		headers['X-Tradecore-App-Name'] = nameData;
		return typeof data === 'string' ? data : JSON.stringify(data);
	}],
	responseType: 'json',
});

const oauthClient = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/v1`,
	transformRequest: [(data, headers) => {
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		return queryString.stringify(data);
	}],
	responseType: 'json',
});

const cardClient = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/cards/v1`,
	transformRequest: [(data, headers) => {
		const authData = localStorage.getItem('@Auth:auth');
		const accessToken = authData ? JSON.parse(authData).accessToken : undefined;
		const nameData = localStorage.getItem('@Auth:appName');
		headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
		headers['Content-Type'] = 'application/json';
		headers['X-Tradecore-App-Name'] = nameData;
		return typeof data === 'string' ? data : JSON.stringify(data);
	}],
	responseType: 'json',
});

const v2client = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/v2`,
	transformRequest: [(data, headers) => {
		const authData = localStorage.getItem('@Auth:auth');
		const accessToken = authData ? JSON.parse(authData).accessToken : undefined;
		const nameData = localStorage.getItem('@Auth:appName');
		headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
		headers['Content-Type'] = 'application/json';
		headers['X-Tradecore-App-Name'] = nameData;
		return typeof data === 'string' ? data : JSON.stringify(data);
	}],
	responseType: 'json',
});

const bankClient = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/digital-bank`,
	transformRequest: [(data, headers) => {
		const authData = localStorage.getItem('@Auth:auth');
		const accessToken = authData ? JSON.parse(authData).accessToken : undefined;
		const nameData = localStorage.getItem('@Auth:appName');
		headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
		headers['Content-Type'] = 'application/json';
		headers['X-Tradecore-App-Name'] = nameData;
		return typeof data === 'string' ? data : JSON.stringify(data);
	}],
	responseType: 'json',
});

const getNewToken = () => new Promise((resolve, reject) => {
	const authData = localStorage.getItem('@Auth:auth');
	const data = authData ? JSON.parse(authData) : {};
	const { accessToken, refreshToken } = data;
	const nameData = localStorage.getItem('@Auth:appName');
	oauthClient.post(
		`${process.env.REACT_APP_API_URL}/v1/auth/token/refresh`,
		{
			refreshToken,
		},
		{
			headers: {
				'X-Tradecore-App-Name': nameData,
				Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
			},
		},
	)
		.then((response) => {
			const newAuthData = { ...data, accessToken: response.data.accessToken };
			localStorage.setItem('@Auth:auth', JSON.stringify(newAuthData));
			const newToken = response.data.accessToken;
			resolve(newToken);
		})
		.catch((error) => {
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
			reject(error);
		});
});

cardClient.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config;
	const authData = localStorage.getItem('@Auth:auth');
	const data = authData ? JSON.parse(authData) : {};
	const { refreshToken } = data;
	if (error.response.status === 401) {
		if (refreshToken) {
			if (!originalRequest.retry) {
				originalRequest.retry = true;
				originalRequest.retry = true;
				return getNewToken()
					.then((newToken) => {
						originalRequest.Authorization = `Bearer ${newToken}`;
						return client(originalRequest).catch((err) => { throw err; });
					});
			}
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		} else {
			// As user does not have valid refresh token, take user to /sign-in
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		}
	}
	return Promise.reject(error);
});

bankClient.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config;
	const authData = localStorage.getItem('@Auth:auth');
	const data = authData ? JSON.parse(authData) : {};
	const { refreshToken } = data;
	if (error.response.status === 401) {
		if (refreshToken) {
			if (!originalRequest.retry) {
				originalRequest.retry = true;
				return getNewToken()
					.then((newToken) => {
						originalRequest.Authorization = `Bearer ${newToken}`;
						return client(originalRequest).catch((err) => { throw err; });
					});
			}
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		} else {
			// As user does not have valid refresh token, take user to /sign-in
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		}
	}
	return Promise.reject(error);
});


client.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config;
	const authData = localStorage.getItem('@Auth:auth');
	const data = authData ? JSON.parse(authData) : {};
	const { refreshToken } = data;
	if (error.response.status === 401) {
		if (refreshToken) {
			if (!originalRequest.retry) {
				originalRequest.retry = true;
				return getNewToken()
					.then((newToken) => {
						originalRequest.Authorization = `Bearer ${newToken}`;
						return client(originalRequest).catch((err) => { throw err; });
					});
			}
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		} else {
			// As user does not have valid refresh token, take user to /sign-in
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		}
	}
	return Promise.reject(error);
});

v2client.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config;
	const authData = localStorage.getItem('@Auth:auth');
	const data = authData ? JSON.parse(authData) : {};
	const { refreshToken } = data;
	if (error.response.status === 401) {
		if (refreshToken) {
			if (!originalRequest.retry) {
				originalRequest.retry = true;
				return getNewToken()
					.then((newToken) => {
						originalRequest.Authorization = `Bearer ${newToken}`;
						return client(originalRequest).catch((err) => { throw err; });
					});
			}
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		} else {
			// As user does not have valid refresh token, take user to /sign-in
			localStorage.removeItem('@Auth:appName');
			localStorage.removeItem('@Auth:auth');
			window.location.reload();
		}
	}
	return Promise.reject(error);
});

let middleware = [
	routerMiddleware(history),
	multiClientMiddleware(
		{
			default: {
				client,
			},
			card: {
				client: cardClient,
			},
			bank: {
				client: bankClient,
			},
			oauth: {
				client: oauthClient,
			},
			v2client: {
				client: v2client,
			},
		},
		{
			returnRejectedPromiseOnError: true,
		},
	),
	thunk,
];

if (process.env.NODE_ENV === 'development') {
	middleware = [...middleware, logger];
}

if (process.env.NODE_ENV === 'development') {
	if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
	}
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers,
);

export const store = createStore(
	createRootReducer(history),
	initialState,
	composedEnhancers,
);

export default store;
