class ProviderHelpers {
static actionOptions = [
	{ id: 'authenticate' },
	{ id: 'authorizePayment' },
	{ id: 'buyMarketOrder' },
	{ id: 'cancelOrder' },
	{ id: 'cardAuthorization' },
	{ id: 'createCard' },
	{ id: 'createCardToken' },
	{ id: 'createProfile' },
	{ id: 'createWebhook' },
	{ id: 'completePayment' },
	{ id: 'deleteCard' },
	{ id: 'deposit' },
	{ id: 'deriveAddress' },
	{ id: 'generateHDWallet' },
	{ id: 'getTransaction' },
	{ id: 'requestAuthorizationUrl' },
	{ id: 'sellMarketOrder' },
	{ id: 'sendMessage' },
	{ id: 'trade' },
	{ id: 'uploadFile' },
	{ id: 'verifyUser' },
	{ id: 'verifyDocuments' },
	{ id: 'webhook' },
	{ id: 'proxy' },
	{ id: 'downloadDocument' },
	{ id: 'getDocument' },
	{ id: 'getReports' },
	{ id: 'handleWebhook' },
	{ id: 'txUpdated' },
	{ id: 'txCreated' },
	{ id: 'createExternalWallet' },
	{ id: 'generateNewAddress' },
	{ id: 'createVaultAsset' },
	{ id: 'createVaultAccount' },
	{ id: 'quote' },
	{ id: 'snapshot' },
	{ id: 'checkUser' },
	{ id: 'checkDocuments' },
	{ id: 'getNews' },
	{ id: 'process' },
	{ id: 'saveProfile' },
	{ id: 'getProfile' },
	{ id: 'saveCard' },
	{ id: 'withdraw' },
	{ id: 'deleteHDWallet' },
	{ id: 'deleteWebhook' },
	{ id: 'sendEmail' },
	{ id: 'deleteExternalWallet' },
	{ id: 'callbackHandler' },
	{ id: 'callbackHandlerNonExistentPayment' },
];

static referenceOptions = [
	{ id: 'push' },
	{ id: 'transaction' },
	{ id: 'card' },
	{ id: 'profile' },
	{ id: 'authentication' },
	{ id: 'kyc' },
	{ id: 'order' },
	{ id: 'deposit' },
	{ id: 'user' },
	{ id: 'providerInstance' },
	{ id: 'payment' },
	{ id: 'providerBlockCypherBitcoinApplication' },
];

static allPaymentType = [
	{ id: 'deposit' },
	{ id: 'withdrawal' },
	{ id: 'credit' },
	{ id: 'debit' },
	{ id: 'internalTransferCredit' },
	{ id: 'internalTransferDebit' },
];

static paymentMethod = ['creditCard', 'wireTransfer', 'manual'];

static paymentStatus = [
	{ id: 'pendingNew' },
	{ id: 'pending' },
	{ id: 'executed' },
	{ id: 'rejected' },
	{ id: 'cancelled' },
];

static providerTypes = [
	{ id: 'market' },
	{ id: 'analytics' },
	{ id: 'kyc' },
	{ id: 'payment' },
	{ id: 'upload' },
	{ id: 'push' },
	{ id: 'news' }];

static paymentMethods = [
	{ id: 'card' },
	{ id: 'wire' },
	{ id: 'manual' },
]

static instrumentIds = [
	{ id: 'USD' },
	{ id: 'GBP' },
	{ id: 'EUR' },
	{ id: 'AVT' },
	{ id: 'BTC' },
	{ id: 'ETH' },
	{ id: 'BCH' },
	{ id: 'LTC' },
	{ id: 'XRP' },
	{ id: 'REP' },
	{ id: 'DASH' },
	{ id: 'XLM' },
	{ id: 'XMR' },
	{ id: 'ZEC' },
	{ id: 'GOOG' },
	{ id: 'AAPL' },
	{ id: 'EOS' },
	{ id: 'ETC' },
	{ id: 'IOT' },
	{ id: 'USDT' },
	{ id: 'FB' },
	{ id: 'MSFT' },
	{ id: 'JPM' },
	{ id: 'TXN' },
	{ id: 'FDX' },
	{ id: 'X' },
	{ id: 'RSD' },
	{ id: 'CHF' },
];
}

export default ProviderHelpers;
