// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import components
import Checkbox from '../../../components/UiElements/Checkbox';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Input from '../../../components/UiElements/Input';
import Radio from '../../../components/UiElements/Radio';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getProducts, getProduct } from '../../../modules/actions/CardAction';
// = import types
import type { Product } from '../../../modules/reducers/CardsReducer';
import type { FullUser } from '../../../modules/reducers/UsersReducer';
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Values = {
	name: string,
	productId: string,
	cardDesignId: string,
	cardNameLine: string,
	countryCode: string,
	currencyCode: string,
	isVirtual: boolean,
	userFirstName: string,
	userLastName: string,
	userAddressLine1: string,
	userCity: string,
	userZipCode: string,
	userCountry: string,
	userPhoneNumber: string,
	userState: string,
	deliveryFirstName: string,
	deliveryLastName: string,
	deliveryCountry: string,
	deliveryCity: string,
	deliveryZipCode: string,
	deliveryAddressLine: string,
	primaryWalletPlatformId: string,
	deliveryMethod: string,
}
type Data = {
	name: string,
	productId: string,
	cardDesignId: string,
	cardNameLine: string,
	countryCode: string,
	currencyCode: string,
	isVirtual: boolean,
	userFirstName: string,
	userLastName: string,
	userAddressLine1: string,
	userCity: string,
	userZipCode: string,
	userCountry: string,
	userPhoneNumber: string,
	userState: string,
	deliveryInfo: {
		firstName: string,
		lastName: string,
		countryCode: string,
		city: string,
		zipCode: string,
		addressLine1: string,
	},
	primaryWalletPlatformId: string,
	deliveryMethodId: string,
}

type Props = {
	form: *,
	submitChanges: (Data) => void,
	handleCancelClick: () => void,
	products: Array<Product>,
	applicationId: string,
	user: FullUser,
	wallets: Array<Wallet>,
	getProduct: (string, string) => Promise<Object>,
	getProducts: (string) => Promise<Object>,
}
type LocalState = {
	errorMessage: string,
	product: Product,
}

class IssueCard extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		product: {},
	}

	componentDidMount() {
		const { getProducts: getProductsAction, applicationId, products } = this.props;
		if (!products.length) {
			getProductsAction(applicationId);
		}
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			product: {},
		});
	}

	selectProduct = async (value) => {
		const { getProduct: getProductAction, applicationId } = this.props;
		const productRes = await getProductAction(applicationId, value);
		this.setState({
			product: productRes.payload.data,
		});
	}

	validateCurrency = (rule, value, callback) => {
		const { wallets } = this.props;
		const walletCurrency = wallets.find((el) => el.primary)?.instrumentId;
		if (!value) {
			callback('Please Select Card Currency');
		}
		if (value !== walletCurrency) {
			callback('The card currency must be the same as the primary wallet currency used for transactions on this card. Select another currency, or if the selected product does not support that currency, select another product.');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data: Data = {
					name: values.name,
					productId: values.productId,
					cardDesignId: values.cardDesignId,
					cardNameLine: values.cardNameLine,
					countryCode: values.countryCode,
					currencyCode: values.currencyCode,
					isVirtual: values.isVirtual,
					userFirstName: values.userFirstName,
					userLastName: values.userLastName,
					userAddressLine1: values.userAddressLine1,
					userCity: values.userCity,
					userZipCode: values.userZipCode,
					userCountry: values.userCountry,
					userPhoneNumber: values.userPhoneNumber,
					userState: values.userState,
					deliveryInfo: {
						firstName: values.deliveryFirstName,
						lastName: values.deliveryLastName,
						countryCode: values.deliveryCountry,
						city: values.deliveryCity,
						zipCode: values.deliveryZipCode,
						addressLine1: values.deliveryAddressLine,
					},
					primaryWalletPlatformId: values.primaryWalletPlatformId,
					deliveryMethodId: values.deliveryMethod,
				};
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			products,
			user,
			wallets,
		} = this.props;
		const {
			errorMessage,
			product,
		} = this.state;

		const { Option } = Select;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};
		return (
			<>
				{products.length
					? (
						<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
							<FormItem label="PRODUCT" {...formItemLayout}>
								{getFieldDecorator('productId', {
									rules: [{ required: true, message: 'Please Select Product' }],
								})(
									<Select
										showSearch
										placeholder="The product on which the Card will be created."
										onChange={this.selectProduct}
									>
										{products.map((el) => (
											<Option
												value={el.id}
												key={el.id}
												className={styles.options}
											>
												{el.name}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							{product.id
								&& 	(
									<>
										<FormItem label="CARD DESIGN">
											{getFieldDecorator('cardDesignId', {
												initialValue: product.cardDesigns ? product.cardDesigns[0]?.id : '',
												rules: [{
													required: true, message: 'Please Select Card Design!',
												}],
											})(
												<Radio.Group className={styles.cardDesign}>
													{product.cardDesigns && product.cardDesigns.map((el) => (
														<Radio value={el.id} key={el.id}>
															<div>
																<img
																	src={el.imageUrl}
																	alt="design"
																/>
															</div>
															<span>
																{el.name}
															</span>
														</Radio>
													))}
												</Radio.Group>,
											)}
										</FormItem>
										<FormItem label="CARD NAME" {...formItemLayout}>
											{getFieldDecorator('name', {
												rules: [{
													required: true, message: 'Please Enter Card Name',
												}],
											})(
												<Input
													name="name"
													placeholder="The Name of the Card "
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="CURRENCY" {...formItemLayout}>
											{getFieldDecorator('currencyCode', {
												initialValue: product.currencies[0]?.isoCode,
												rules: [{ validator: this.validateCurrency }],
											})(
												<Select
													showSearch
													placeholder="Selected from the list of available product currencies."
												>
													{product.currencies && product.currencies.map((el) => (
														<Option
															value={el.isoCode}
															key={el.id}
															className={styles.options}
														>
															{el.name}
														</Option>
													))}
												</Select>,
											)}
										</FormItem>
										<FormItem label="COUNTRY" {...formItemLayout}>
											{getFieldDecorator('countryCode', {
												initialValue: product.countries[0]?.isoAlpha3Code,
												rules: [{ required: true, message: 'Please select Country' }],
											})(
												<Select
													showSearch
													placeholder="The country where the Card will be issued."
												>
													{product.countries && product.countries.map((el) => (
														<Option
															value={el.isoAlpha3Code}
															key={el.id}
															className={styles.options}
														>
															{el.name}
														</Option>
													))}
												</Select>,
											)}
										</FormItem>
										<FormItem label="IS VIRTUAL" {...formItemLayout}>
											{getFieldDecorator('isVirtual', {
												valuePropName: 'checked',
												initialValue: product.virtualCardsEnabled,
												rules: [{ required: true, message: "Field can't be left empty" }],
											})(
												<Checkbox disabled={!product.virtualCardsEnabled}>
													<span className="capitalize">
														Determines if the card Is virtual.
														This field is sourced from the Product.
													</span>
												</Checkbox>,
											)}
										</FormItem>
										<FormItem label="CARD NAME LINE" {...formItemLayout}>
											{getFieldDecorator('cardNameLine', {
												initialValue: user.name,
												rules: [{
													required: true, message: 'Please Enter Card Name Line',
												}],
											})(
												<Input
													name="cardNameLine"
													placeholder="The name of the Client that will be written on the Card."
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="CLIENT FIRST NAME" {...formItemLayout}>
											{getFieldDecorator('userFirstName', {
												initialValue: user.userInfo?.firstName,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT LAST NAME" {...formItemLayout}>
											{getFieldDecorator('userLastName', {
												initialValue: user.userInfo?.lastName,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT ADDRESS LINE" {...formItemLayout}>
											{getFieldDecorator('userAddressLine1', {
												initialValue: user.userAddress?.addressLine1,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT COUNTRY" {...formItemLayout}>
											{getFieldDecorator('userCountry', {
												initialValue: user.userAddress?.country.a3,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT ZIP CODE" {...formItemLayout}>
											{getFieldDecorator('userZipCode', {
												initialValue: user.userAddress?.zipCode,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT CITY" {...formItemLayout}>
											{getFieldDecorator('userCity', {
												initialValue: user.userAddress?.city,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT STATE" {...formItemLayout}>
											{getFieldDecorator('userState', {
												initialValue: user.userAddress?.state,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="CLIENT PHONE NUMBER" {...formItemLayout}>
											{getFieldDecorator('userPhoneNumber', {
												initialValue: `${user.userInfo?.dialingPrefix || ''} ${user.userInfo?.phone || ''} `,
											})(
												<Input disabled />,
											)}
										</FormItem>
										<FormItem label="DELIVERY METHOD" {...formItemLayout}>
											{getFieldDecorator('deliveryMethod', {
												initialValue: product.deliveryMethods[0]?.id,
												rules: [{ required: true, message: 'Please select Delivery Method' }],
											})(
												<Select
													showSearch
													placeholder="Delivery Method'."
												>
													{product.deliveryMethods && product.deliveryMethods.map((el) => (
														<Option
															value={el.id}
															key={el.id}
															className={styles.options}
														>
															{el.name}
														</Option>
													))}
												</Select>,
											)}
										</FormItem>
										<FormItem label="DELIVERY FIRST NAME" {...formItemLayout}>
											{getFieldDecorator('deliveryFirstName', {
												initialValue: user.userInfo?.firstName,
												rules: [{
													required: true, message: 'Please Enter Delivery First Name',
												}],
											})(
												<Input
													name="deliveryFirstName"
													placeholder="The first name of the person that accepts the delivery of the card. Delivery fields are not required if the card is virtual"
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="DELIVERY LAST NAME" {...formItemLayout}>
											{getFieldDecorator('deliveryLastName', {
												initialValue: user.userInfo?.lastName,
												rules: [{
													required: true, message: 'Please Enter Delivery Last Name',
												}],
											})(
												<Input
													name="deliveryLastName"
													placeholder="The last name of the person that accepts the delivery of the card."
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="DELIVERY ADDRESS LINE" {...formItemLayout}>
											{getFieldDecorator('deliveryAddressLine', {
												initialValue: user.userAddress?.addressLine1,
												rules: [{
													required: true, message: 'Please Enter Delivery Address Line',
												}],
											})(
												<Input
													name="deliveryAddressLine"
													placeholder="The delivery address where the Card will be sent to Client."
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="DELIVERY COUNTRY" {...formItemLayout}>
											{getFieldDecorator('deliveryCountry', {
												initialValue: user.userAddress?.country.a3,
												rules: [{
													required: true, message: 'Please Enter Delivery Country Code',
												}],
											})(
												<Input
													name="deliveryCountry"
													placeholder="The Country where the Card will be delivered."
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="DELIVERY ZIP CODE" {...formItemLayout}>
											{getFieldDecorator('deliveryZipCode', {
												initialValue: user.userAddress?.zipCode,
												rules: [{
													required: true, message: 'Please Enter Delivery Zip Code',
												}],
											})(
												<Input
													name="deliveryZipCode"
													placeholder="The Zip code of the delivery address."
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="DELIVERY CITY" {...formItemLayout}>
											{getFieldDecorator('deliveryCity', {
												initialValue: user.userAddress?.city,
												rules: [{
													required: true, message: 'Please Enter Delivery City',
												}],
											})(
												<Input
													name="deliveryCity"
													placeholder="The City of the delivery."
													maxLength={50}
												/>,
											)}
										</FormItem>
										<FormItem label="PRIMARY WALLET" {...formItemLayout}>
											{getFieldDecorator('primaryWalletPlatformId', {
												initialValue: wallets.find((el) => el.primary)?.id,
												rules: [{
													required: true, message: 'Please Enter Primary Wallet Platform Id',

												}],
											})(
												<Select
													showSearch
													placeholder="Platform Id of the primary wallet used for transactions on this card. IT HAS TO BE IN THE SAME CURRENCY AS THE CARD."
												>
													<Option
														value={wallets.find((el) => el.primary)?.id}
														key={wallets.find((el) => el.primary)?.id}
														className={styles.options}
													>
														{`${wallets.find((el) => el.primary)?.instrumentId || ''} Wallet` }
													</Option>
												</Select>,
											)}
										</FormItem>
									</>
								)}
							{errorMessage && (
								<ErrorMessage message={errorMessage} />
							)}
							<div className="form-buttons">
								<FormItem>
									<CPButton ghost action={handleCancelClick} text="cancel" />
								</FormItem>
								<FormItem>
									<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="issue card" />
								</FormItem>
							</div>
						</Form>
					)
					: (
						<div className={styles.noProvider}>
							<h4>
								You are currently unable to issue card for this user,
								as there is no Card Product for this application.
								Please create Card Product for your application.
							</h4>
						</div>
					)}
			</>
		);
	}
}

const IssueCardForm = Form.create()(IssueCard);

const mapStateToProps = (state: State) => ({
	products: state.cards.products,
	product: state.cards.product,
});
const mapDispatchToProps = {
	getProducts,
	getProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueCardForm);
