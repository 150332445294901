// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Result } from 'antd';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import Spin from '../../../components/UiElements/Spin';
// import components
import MenuIcon from '../../../components/UiElements/Icon/MenuIcon';
import Avatar from '../../../components/UiElements/Avatar';
import Menu from '../../../components/UiElements/Menu';
import Tag from '../../../components/UiElements/Tag';
import ManageUserTags from './ManageUserTags';
import Dropdown from '../../../components/UiElements/Dropdown';
import CPButton from '../../../components/UiElements/Button';
import CustomTabs from '../../../components/UiElements/Tabs/CustomTabs';
import Icon from '../../../components/UiElements/Icon';
import TotalValueCard from './TotalValueCard';
import PendingCard from './PendingCard';
import FormModal from '../../../components/UiElements/Modal/FormModal';
// import styles
import styles from '../assets/user.module.scss';
// import types
import type { FullUser } from '../../../modules/reducers/UsersReducer';
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { Rates } from '../../../modules/reducers/StaticReducer';
import { getOnfidoSDKToken } from '../../../modules/actions/UsersActions';

type Providers = {
	active: Boolean,
	provider: {
		name: string
	}
}

type SDKResponse = {
	payload: {
		data: {
			token: String,
		},
	},
}

type Props = {
	tags: Array<string>,
	backAction: ?() => void,
	user: FullUser,
	accounts: Array<Account>,
	rates: Rates,
	reportingCurrency: ?string,
	pendingDeposit: number,
	pendingWithdrawal: number,
	tabs: Array<Object>,
	openModal: (string) => void,
	openSettings: (string) => void,
	updateUserStatus: (string) => void,
	appMarginEnabled: boolean,
	postTradeSettlementEnabled: boolean,
	applicationId: string,
	accountId: string,
	cardEnabled: boolean,
	providers: Array<Providers>,
	getOnfidoSDKToken: (userId: string) => SDKResponse,
}

const UserTitle = (props: Props) => {
	const {
		tags,
		backAction,
		user,
		accounts,
		rates,
		reportingCurrency,
		pendingWithdrawal,
		pendingDeposit,
		tabs,
		openModal,
		openSettings,
		updateUserStatus,
		appMarginEnabled,
		postTradeSettlementEnabled,
		applicationId,
		accountId,
		cardEnabled,
		providers,
		getOnfidoSDKToken: getOnfidoSDKTokenAction,
	} = props;

	const [onfidoModal, setOnfidoModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [onfidoSDKURL, setOnfidoSDKURL] = useState('');

	const handleOnfidoModal = async () => {
		setOnfidoModal(true);
		setLoading(true);
		try {
			const response: SDKResponse = await getOnfidoSDKTokenAction(user.id);
			const SDKToken = response.payload.data.token;
			const SDKURL = `${process.env.REACT_APP_ONFIDO_WEB_SDK_URL}?token=${SDKToken}`;
			setOnfidoSDKURL(SDKURL);
		} catch (error) {
			setOnfidoModal(false);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	// eslint-disable-next-line consistent-return
	const isOnfidoEnabled = providers.filter((provider) => provider.provider?.name === 'onfido' && provider.active);

	function generateOnfidoDashboardLink(): string {
		const applicantId = user.meta?.kyc?.applicantId;
		if (isOnfidoEnabled && applicantId) {
			return `https://dashboard.onfido.com/applicant/${applicantId}/details`;
		}
		return '';
	}

	const onfidoDashboardLink = generateOnfidoDashboardLink();

	const hasAccount = user.accounts.length > 0;
	const moreThanFifty = user.accounts.length > 50;

	const phoneNumber = (!!user.userInfo && !!user.userInfo.phone && `${user.userInfo.dialingPrefix} ${user.userInfo.phone}`) || '-';

	return (
		<div className={styles.userTitle}>
			<div className={styles.title}>
				<div className={styles.start}>
					<div className={styles.avatar}>
						{backAction && (
							<CPButton
								ghost
								action={backAction}
								icon="ArrowLeft"
							/>
						)}
						<Avatar
							className="avatar"
							size={52}
							style={{ backgroundImage: 'linear-gradient(#081d3e, #006a9f)', fontSize: '20px' }}
							icon={user.initials ? '' : (
								<MenuIcon
									type="User"
									width="26"
									height="40"
									viewBox="0 0 24 12"
								/>
							)}
						>
							{user.initials}
						</Avatar>
					</div>
					<div className={styles.info}>
						<div>
							<h2>{user.name}</h2>
							{tags.map((tag) => (
								!!tag && <Tag status={tag} key={tag} />))}
						</div>
						<ManageUserTags
							applicationId={applicationId}
							userId={user.id}
							width={100}

						/>
						<div>
							<div>
								{!onfidoDashboardLink && <span>{user.id}</span>}
								{onfidoDashboardLink
								&& <span><a href={onfidoDashboardLink} target="_blank" rel="noopener noreferrer">View on Onfido dashboard</a></span>}
								<span>|</span>
							</div>
							<div>
								<span>
									<a href={`mailto:${user.email}`}>{user.email}</a>
								</span>
								<span>|</span>
							</div>
							<div>
								<span>
									<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
								</span>
								<span>|</span>
							</div>
							<div>
								<span>{user.group ? user.group.name : '-'}</span>
							</div>
						</div>
						<div>
							<TotalValueCard
								accounts={accounts}
								rates={rates}
								reportingCurrency={reportingCurrency}
								moreThanFifty={moreThanFifty}
							/>
							<PendingCard
								title="deposit"
								data={pendingDeposit}
								accountId={accountId}
								applicationId={applicationId}
								userId={user.id}
							/>
							<PendingCard
								title="withdrawal"
								data={pendingWithdrawal}
								accountId={accountId}
								applicationId={applicationId}
								userId={user.id}
							/>
						</div>
					</div>
				</div>
				<div className={styles.buttons}>
					<Dropdown
						overlay={(
							<Menu className={styles.actions}>
								<Menu.Item key="group" onClick={() => openSettings('group')}>
									<Icon name="Teams" colorName="text" size={14} />
									<span>Edit Group</span>
								</Menu.Item>
								{appMarginEnabled && (
									<Menu.Item key="margin" onClick={() => openSettings('margin')}>
										<Icon name="Marketing" colorName="text" size={14} />
										<span>Edit Margin Settings</span>
									</Menu.Item>
								)}
								{postTradeSettlementEnabled && (
									<Menu.Item key="pts" onClick={() => openSettings('post-trade settlement')}>
										<Icon name="Star" colorName="text" size={14} />
										<span>Edit Post-Trade Settlement</span>
									</Menu.Item>
								)}
								<Menu.Item key="fee" onClick={() => openSettings('fee')}>
									<Icon name="USD" colorName="text" size={14} />
									<span>Set Client Fees</span>
								</Menu.Item>
							</Menu>
						)}
						placement="bottomRight"
						trigger={['click']}
					>
						<span>
							<CPButton
								icon="Settings"
								text="settings"
								type="primary"
							/>
						</span>
					</Dropdown>
					<Dropdown
						InternalTransaction
						overlay={(
							<Menu className={styles.actions}>
								<Menu.Item key="credit" disabled={!hasAccount || user.status !== 'verified'} onClick={() => openModal('credit')}>
									<Icon name="Credit" colorName={!hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Credit Client</span>
								</Menu.Item>
								<Menu.Item key="debit" disabled={!hasAccount || user.status !== 'verified'} onClick={() => openModal('debit')}>
									<Icon name="Debit" colorName={!hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Debit Client</span>
								</Menu.Item>
								<Menu.Item key="internalTransfer" disabled={!hasAccount || user.status !== 'verified'} onClick={() => openModal('internalTransfer')}>
									<Icon name="InternalTransaction" colorName={!hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Internal Transfer</span>
								</Menu.Item>
								<Menu.Item key="conversion" disabled={!hasAccount || user.status !== 'verified'} onClick={() => openModal('conversion')}>
									<Icon name="EUR" colorName={!hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Fiat Conversion</span>
								</Menu.Item>
								<Menu.Item key="cryptoPayout" disabled={!hasAccount || user.status !== 'verified'} onClick={() => openModal('cryptoPayout')}>
									<Icon name="BTC" colorName={!hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Crypto Payout</span>
								</Menu.Item>
								<Menu.Item key="placeOrder" disabled={!hasAccount || user.status !== 'verified'} onClick={() => openModal('placeOrderFor')}>
									<Icon name="Dashboard" colorName={!hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Place Order</span>
								</Menu.Item>
								<Menu.Item key="issueCard" disabled={!cardEnabled || !hasAccount || user.status !== 'verified'} onClick={() => openModal('issueCard')}>
									<Icon name="Payments" colorName={!cardEnabled || !hasAccount || user.status !== 'verified' ? 'ghost' : 'text'} size={14} />
									<span>Issue Card</span>
								</Menu.Item>
								<Menu.Item key="createBankAccount" disabled={(!hasAccount || user.status !== 'verified')} onClick={() => openModal('createClientBankAccount')}>
									<Icon name="Bank" colorName={(!hasAccount || user.status !== 'verified') ? 'ghost' : 'text'} size={14} />
									<span>Create Bank Account</span>
								</Menu.Item>
								<Menu.Item key="kycCheck" disabled={user.status === 'incomplete'} onClick={() => openModal('check')}>
									<Icon name="KYC" colorName={user.status === 'incomplete' ? 'ghost' : 'text'} size={14} />
									<span>KYC Check</span>
								</Menu.Item>
								{ isOnfidoEnabled
								&& (
									<Menu.Item key="onfiddle" disabled={false} onClick={() => handleOnfidoModal()}>
										<Icon name="KYC" colorName="text" size={14} />
										<span>Get KYC Docs Submission Link</span>
									</Menu.Item>
								)}
								<Menu.Item key="verify" disabled={user.status === 'verified'} onClick={() => updateUserStatus('verified')}>
									<Icon name="Approve" colorName={user.status === 'verified' ? 'ghost' : 'info'} size={14} />
									<span className={user.status === 'verified' ? styles.disabled : styles.verify}>Verify Client</span>
								</Menu.Item>
								<Menu.Item key="reject" disabled={user.status === 'verified' || user.status === 'rejected'} onClick={() => updateUserStatus('rejected')}>
									<Icon name="Reject" colorName={user.status === 'verified' || user.status === 'rejected' ? 'ghost' : 'danger'} size={14} />
									<span className={user.status === 'verified' || user.status === 'rejected' ? styles.disabled : styles.reject}>Reject Client</span>
								</Menu.Item>
								<Menu.Item key={user.suspended ? 'reactivateClient' : 'suspendClient'} onClick={() => openModal(user.suspended ? 'reactivate' : 'suspend')}>
									<Icon name="Reject" colorName={user.suspended ? 'info' : 'danger'} size={14} />
									<span className={user.suspended ? styles.verify : styles.reject}>
										{user.suspended ? 'Reactivate Client' : 'Suspend Client'}
									</span>
								</Menu.Item>
							</Menu>
						)}
						placement="bottomRight"
						trigger={['click']}
					>
						<span>
							<CPButton
								icon="Play"
								text="actions"
								type="primary"
							/>
						</span>
					</Dropdown>
				</div>
			</div>
			{
				tabs && !!tabs.length
				&& <CustomTabs tabs={tabs} place="user" />
			}
			{onfidoModal
			&& (
				<FormModal
					title=""
					visible
					destroyOnClose
					cancelFunction={() => setOnfidoModal(false)}
					form={loading
						? (
							<Spin
								position="center"
							/>
						)
						: (
							<Result
								status="success"
								title="KYC Documents Submission URL"
								subTitle={`Link expires in 90 minutes. Copy and send to ${user.name || 'the client'}!`}
								extra={[
									<CopyComponent text={onfidoSDKURL} content="Click the clipboard icon on the right to copy the generated link" />,
								]}
							/>
						)}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	providers: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getOnfidoSDKToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTitle);
