// @flow

import React, {
	useRef, useState, useEffect, useCallback,
} from 'react';
import debounce from 'lodash/debounce';
import Modal from './index';
import useWindowSize from '../../../lib/hook/SizeHook';

type Props = {
    cancelFunction: () => void,
    visible: boolean,
    title: string,
	form: Object,
	destroyOnClose?: boolean,
}
const FormModal = (props: Props) => {
	const {
		cancelFunction, visible, title, form, destroyOnClose,
	} = props;

	const modalContent = document.querySelector('.ant-modal-body');
	const formEnd = useRef(null);

	const size: number = useWindowSize();

	const [hasScroll, setHasScroll] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	const isInViewport = (offset = 0) => {
		const topPosition: number = formEnd?.current?.getBoundingClientRect()?.top || 0;
		return (topPosition + offset) >= 0 && (topPosition - offset) <= window.innerHeight;
	};

	const handleEvent = () => {
		if (formEnd.current) {
			setHasScroll(!isInViewport(-124));
		}
	};

	const debounceHandleEvent = useCallback(debounce(handleEvent, 100), []);

	const handleScroll = useCallback(
		() => {
			const currentPosition = modalContent?.scrollTop;
			if (scrollPosition !== currentPosition) {
				setScrollPosition(currentPosition);
			}
		},
		// eslint-disable-next-line
		[modalContent],
	);

	useEffect(() => {
		// Add event listener
		if (modalContent) {
			modalContent.addEventListener('scroll', handleScroll);
		}
		handleScroll();
		// Remove event listener on cleanup
		return () => {
			if (modalContent) {
				modalContent.removeEventListener('scroll', handleScroll);
				modalContent.scrollTop = 0;
			}
		};
	}, [modalContent, handleScroll]);

	useEffect(() => {
		debounceHandleEvent();
	}, [size, scrollPosition, debounceHandleEvent]);

	return (
		<Modal
			title={(
				<div className="flex capitalize">
					<h2>{title}</h2>
				</div>
			)}
			visible={visible}
			onCancel={cancelFunction}
			wrapClassName="modal-form"
			footer={null}
			closable={false}
			centered
			destroyOnClose={destroyOnClose}
			className={hasScroll ? 'hasScroll' : ''}
		>
			{form}
			<div
				className="formEnd"
				ref={formEnd}
			/>
		</Modal>
	);
};

FormModal.defaultProps = {
	destroyOnClose: true,
};

export default FormModal;
