// @flow

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import difference from 'lodash/difference';
//= import components
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Title from '../../../components/UiElements/Title';
import Form from '../../../components/UiElements/Form';
import Spin from '../../../components/UiElements/Spin';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import Menu from '../../../components/UiElements/Menu';
import Icon from '../../../components/UiElements/Icon';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import InstrumentsApplicationSymbols from '../components/InstrumentsApplicationSymbols';
import MetaComponent from '../components/MetaComponent';
import EditInstrumentForm from '../components/EditInstrumentForm';
import DelistForm from '../components/DelistForm';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import methods
import {
	getInstrument,
	updateAppInstrument,
	addPaymentMethods,
	removePaymentMethods,
	delistInstrument,
} from '../../../modules/actions/InstrumentAction';
import { getInstrumentSymbols } from '../../../modules/actions/SymbolActions';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { State } from '../../../modules/types/FlowTypes';

type ErrorMessageType = {
	response: {
		data: {
			message: string,
			errors: Array<Object>,
		}
	}
}
type ErrorType = {
	error: ErrorMessageType,
}
type Props = {
	getInstrument: (string, string) => Promise<Object>,
	getInstrumentSymbols: (string, string) => void,
	updateAppInstrument: (string, string, Object) => Promise<Object>,
	removePaymentMethods: (string, string, Object) => Promise<Object>,
	addPaymentMethods: (string, string, Object) => Promise<Object>,
	delistInstrument: (string, string, Object) => Promise<Object>,
	instrument: Instrument,
	isFetchingInstrument: boolean,
	instrumentSymbols: Array<Symbol>,
	isFetchingInstrumentSymbols: boolean,
	errorMessage: ErrorMessageType,
	match: {
		params: {
			instrumentId: string,
			applicationId: string,
		},
	},
	location: {
		search: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}
const printError = (error: Array<Object>) => {
	if (!error) {
		return '';
	}
	const text = error.length
		? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
		: [];
	return text.map((el: string) => (<div key={el}>{el}</div>));
};

const InstrumentDetails = (props: Props) => {
	const {
		instrument,
		isFetchingInstrument,
		errorMessage,
		instrumentSymbols,
		isFetchingInstrumentSymbols,
		match: { params: { applicationId, instrumentId } },
		getInstrument: getInstrumentAction,
		getInstrumentSymbols: getInstrumentSymbolsAction,
		updateAppInstrument: updateAppInstrumentAction,
		addPaymentMethods: addPaymentMethodsAction,
		removePaymentMethods: removePaymentMethodsAction,
		delistInstrument: delistInstrumentAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [message, setMessage] = useState({});
	const [title, setTitle] = useState('');
	const [footer, setFooter] = useState([]);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [type, setType] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);


	const formRef = useRef(null);

	useEffect(() => {
		getInstrumentAction(applicationId, instrumentId);
		getInstrumentSymbolsAction(applicationId, instrumentId);
	}, [getInstrumentAction, getInstrumentSymbolsAction, applicationId, instrumentId]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleEditClick = () => {
		setEdit(true);
		setType('edit');
		setTitle(`Edit ${instrument.name} ${instrument.instrumentId}`);
	};

	const handleWarningCancel = () => {
		setEdit(true);
		setWarningModal(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setEdit(false);
		setWarningModal(false);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setEdit(false);
			setWarningModal(false);
		}
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error?.response ? error.error.response.data.message : 'Error',
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitChanges = (value) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		if (value.default !== instrument.default || value.rank !== instrument.rank) {
			const defaultData = {
				default: value.default !== instrument.default ? value.default : undefined,
				rank: value.rank !== instrument.rank ? value.rank : undefined,
			};
			updateAppInstrumentAction(applicationId, instrumentId, defaultData)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else {
			setButtonLoading(false);
			setWarningModal(false);
		}
		const depositPaymentMethods = value.depositPaymentMethods.includes('Not Depositable') ? [] : value.depositPaymentMethods;
		const withdrawalPaymentMethods = value.withdrawalPaymentMethods.includes('Not Withdrawable') ? [] : value.withdrawalPaymentMethods;
		if (difference(instrument.depositPaymentMethods, depositPaymentMethods).length) {
			const data = {
				depositPaymentMethods: difference(
					instrument.depositPaymentMethods,
					depositPaymentMethods,
				),
			};
			removePaymentMethodsAction(applicationId, instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
		if (difference(
			instrument.withdrawalPaymentMethods,
			withdrawalPaymentMethods,
		).length) {
			const data = {
				withdrawalPaymentMethods: difference(
					instrument.withdrawalPaymentMethods,
					withdrawalPaymentMethods,
				),
			};
			removePaymentMethodsAction(applicationId, instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
		if (difference(depositPaymentMethods, instrument.depositPaymentMethods).length) {
			const data = {
				depositPaymentMethods: difference(
					depositPaymentMethods,
					instrument.depositPaymentMethods,
				),
			};
			addPaymentMethodsAction(applicationId, instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
		if (difference(
			withdrawalPaymentMethods,
			instrument.withdrawalPaymentMethods,
		).length) {
			const data = {
				withdrawalPaymentMethods: difference(
					withdrawalPaymentMethods,
					instrument.withdrawalPaymentMethods,
				),
			};
			addPaymentMethodsAction(applicationId, instrumentId, data)
				.then(() => {
					setButtonLoading(false);
					setWarningModal(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
	};

	const submitAction = async (value) => {
		setButtonLoading(true);
		if (type === 'Delist') {
			try {
				setWarningModal(false);
				await delistInstrumentAction(applicationId, instrumentId, value);
				setButtonLoading(false);
			} catch (error) { handleOnError(error); }
		}
	};

	const continueChanges = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: "Once you confirm these changes, they will affect this Instrument and all it's Symbols.",
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: type === 'edit' ? () => submitChanges(value) : () => submitAction(value), text: 'save changes' },
		]);
	};

	const openAction = (value: string) => {
		setType(value);
		setEdit(true);
		setTitle(`Delist ${instrument.name} ${instrument.instrumentId} instrument`);
	};

	const instrumentDetailsData = !!Object.keys(instrument).length && {
		depositMethods: instrument.depositPaymentMethods.length
			? instrument.depositPaymentMethods.map((method) => StatusHelpers.paymentMethods(method)).join(', ')
			: 'Not Depositable',
		withdrawalMethods: instrument.withdrawalPaymentMethods.length
			? instrument.withdrawalPaymentMethods.map((method) => StatusHelpers.paymentMethods(method)).join(', ')
			: 'Not Withdrawable',
		keywords: instrument.keywords.join(', '),
		description: instrument.description,
	};
	const isinData = instrument.rank
		? {
			isin: instrument.isin || '-',
			precision: instrument.precision,
			haircut: instrument.haircut === null ? '-' : `${instrument.haircut}%`,
			rank: instrument.rank,
		}
		: {
			isin: instrument.isin || '-',
			precision: instrument.precision,
			haircut: instrument.haircut === null ? '-' : `${instrument.haircut}%`,
		};
	const overlayComponent = (
		<Menu className="wallet-actions">
			<Menu.Item key="delist" onClick={() => openAction('Delist')} disabled={instrument.delisted}>
				<Icon name="Reject" colorName="text" size={14} />
				<span>Delist Instrument</span>
			</Menu.Item>
			<Menu.Item key="split" onClick={() => openAction('Split')} disabled>
				<Icon name="SendGrid" colorName="text" size={14} />
				<span>Split Instrument</span>
			</Menu.Item>
		</Menu>
	);

	const quotingInstruments = instrumentSymbols.map((el) => el.quotingInstrumentId);

	return (
		<>
			{!isFetchingInstrument
				? (
					<div>
						<Title
							title={`${instrument.name || ''} ${instrument.instrumentId || ''}`}
							backAction={handleBackClick}
							tags={[
								instrument.default && 'default',
								instrument.haircut && instrument.haircut !== null && 'collateral',
								instrument.delisted && 'delisted',
							]}
							buttons={[
								{
									action: handleEditClick,
									text: 'edit details',
								},
							]}
							actions={{
								overlay: overlayComponent,
							}}
						/>
						{instrument.id
							? (
								<div className="page-container">
									<Row gutter={24}>
										<Col xs={16}>
											{instrumentDetailsData
										&& <TextCard data={instrumentDetailsData} colNumber={1} left="left-2" />}
											<MetaComponent data={instrument.meta} edit={edit} />
										</Col>
										<Col xs={8}>
											<TextCard data={isinData} colNumber={1} />
											<InstrumentsApplicationSymbols
												symbols={instrumentSymbols}
												isFetchingInstrumentSymbols={isFetchingInstrumentSymbols}
												applicationId={applicationId}
											/>
										</Col>
									</Row>
									<FormModal
										title={title}
										visible={edit}
										cancelFunction={handleEditCancel}
										form={(type === 'edit'
											? (
												<EditInstrumentForm
													submitChanges={continueChanges}
													handleCancelClick={handleEditCancel}
													instrument={instrument}
													wrappedComponentRef={formRef}
												/>
											)
											: (
												<DelistForm
													submitChanges={continueChanges}
													handleCancelClick={handleEditCancel}
													quotingInstruments={quotingInstruments}
													wrappedComponentRef={formRef}
												/>
											)
										)}
									/>
									<WarningModal
										title={warningTitle}
										visible={warningModal}
										cancelFunction={handleErrorCancel}
										footer={footer}
										message={message}
										loading={buttonLoading}
									/>
								</div>
							)
							: (
								<div className="empty-state">
									<UserNoChecks />
									<p>{errorMessage.response?.data?.message}</p>
								</div>
							)						}
					</div>
				)
				: <Spin spinning={isFetchingInstrument} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	instrument: state.instrument.instrument,
	isFetchingInstrument: state.instrument.isFetchingInstrument,
	errorMessage: state.instrument.error,
	instrumentSymbols: state.symbol.instrumentSymbols,
	isFetchingInstrumentSymbols: state.symbol.isFetchingInstrumentSymbols,
});

const mapDispatchToProps = {
	getInstrument,
	updateAppInstrument,
	removePaymentMethods,
	addPaymentMethods,
	getInstrumentSymbols,
	delistInstrument,
};

const EditInstrument = Form.create()(InstrumentDetails);

export default connect(mapStateToProps, mapDispatchToProps)(EditInstrument);
