// @flow

import React from 'react';
//= import components
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
//= import types
import type { ApplicationDetail } from '../../../modules/reducers/ApplicationReducer';

type Props = {
    companyDetails: ApplicationDetail,
}
const CompanyInfo = (props: Props) => {
	const { companyDetails } = props;
	const companyData = {
		companyName: companyDetails.companyName,
		phone: companyDetails.phone,
		fax: companyDetails.faxNumber,
		email: companyDetails.contactEmail,
		website:
	<a
		href={companyDetails.website}
		target="_blank"
		rel="noopener noreferrer"
	>
		{companyDetails.website}
	</a>,
		vatNumber: companyDetails.vatNumber,
		registrationNumber: companyDetails.registrationNumber,
	};
	const addressData = {
		street: companyDetails.streetName,
		number: companyDetails.streetNumber,
		zipCode: companyDetails.zipCode,
		state: companyDetails.state,
		country: companyDetails.country,
		addressLine_2: companyDetails.addressLine2,
	};
	return (
		<div className="company-info">
			<TextCard data={companyData} colNumber={1} left="left-2" title="Company Details" />
			<TextCard data={addressData} colNumber={1} left="left-2" subtitle="Address" />
		</div>
	);
};
export default CompanyInfo;
