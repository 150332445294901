import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function withAuth(WrappedComponent, protectedRoute) {
	class Wrapper extends PureComponent {
		render() {
			if (this.props.customer) {
				if (this.props.isAuthenticated && !protectedRoute) {
					return (
						<Redirect
							to={{
								pathname: '/',
								state: { from: this.props.location },
							}}
						/>
					);
				}
			}
			if (!this.props.isAuthenticated && protectedRoute) {
				return (
					<Redirect
						to={{
							pathname: '/sign-in',
							state: { from: this.props.location },
						}}
					/>
				);
			}
			return <WrappedComponent {...this.props} />;
		}
	}
	Wrapper.propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		location: PropTypes.shape({}).isRequired,
	};

	Wrapper.defaultProps = {
		protected: false,
	};

	function mapStateToProps(state) {
		return {
			isAuthenticated: state.oauth.isAuthenticated,
			initialized: state.oauth.initialized,
			customer: state.oauth.customer,
		};
	}
	return connect(mapStateToProps)(Wrapper);
}

export default withAuth;
