// @flow

import * as React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
// = import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';

type Values = { status: string }
type Props = {
	form: *,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	status: string,
}

class Status extends React.Component<Props> {
	handleReset = () => {
		this.props.form.resetFields();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (!err) {
				submitChanges(values);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			status,
		} = this.props;

		const FormItem = Form.Item;
		const { Option } = Select;

		const formItemLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<FormItem label="CARD STATUS" {...formItemLayout}>
					{getFieldDecorator('status', {
						initialValue: status,
						rules: [{
							required: true,
							message: 'Please select Status!',
						}],
					})(
						<Select
							showSearch
							placeholder="Select Card Status"
							notFoundContent={StatusHelpers.cardStatuses[status].length ? 'Not Found' : 'This status cannot be changed'}
						>
							{StatusHelpers.cardStatuses[status].map((el) => (
								<Option
									value={el}
									key={el}
								>
									{el}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<div className="form-buttons">
					<Form.Item>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</Form.Item>
					<Form.Item>
						<CPButton
							type="primary"
							action={this.submit}
							disabled={!this.props.form.isFieldsTouched()}
							text="save changes"
						/>
					</Form.Item>
				</div>
			</Form>
		);
	}
}

const StatusForm = Form.create()(Status);

export default StatusForm;
