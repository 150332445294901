// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//= import components
import BasicWalletsTable from './BasicWalletsTable';
import WalletsTable from './WalletsTable';
import { getWallets } from '../../../modules/actions/WalletActions';
import { setCurrentPageSize } from '../../../modules/actions/UiActions';
//= import types
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { Rates } from '../../../modules/reducers/StaticReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	wallets: Array<Wallet>,
	isFetchingWallets: boolean,
	accountCurrency: string,
	rates: Rates,
	applicationId: string,
	displayInCurrency: boolean,
	getWallets: Function,
	accountId: string,
	basicUserWalletsPageSize: number,
	userWalletsPageSize: number,
}

const WalletsTab = (props: Props) => {
	const {
		wallets,
		rates,
		displayInCurrency,
		accountCurrency,
		applicationId,
		isFetchingWallets,
		getWallets: getWalletsAction,
		basicUserWalletsPageSize,
		userWalletsPageSize,
		accountId,
	} = props;

	const [userWallets, setUserWallets] = useState(wallets);
	const [page, setPage] = useState(1);


	useEffect(() => {
		setUserWallets(wallets);
	}, [wallets]);


	const handlePageChange = async (newPage: number, newPageSize: number) => {
		setPage(newPage);
		await getWalletsAction(accountId, newPage, newPageSize);
	};
	return (
		<div>
			{displayInCurrency
				?						(
					<WalletsTable
						data={userWallets}
						loading={isFetchingWallets}
						accountCurrency={accountCurrency}
						handlePageChange={handlePageChange}
						currentPage={page}
						numberOnPage={userWalletsPageSize}
						place="appOrdersPageSize"
						rates={rates}
						applicationId={applicationId}
					/>
				)
				: (
					<BasicWalletsTable
						data={userWallets}
						handlePageChange={handlePageChange}
						loading={isFetchingWallets}
						place="appOrdersPageSize"
						applicationId={applicationId}
						numberOnPage={basicUserWalletsPageSize}
						currentPage={page}
					/>
				)}
		</div>

	);
};

const mapStateToProps = (state: State) => ({
	accountId: state.account.activeAccount,
	basicUserWalletsPageSize: state.ui.basicUserWalletsPageSize,
	userWalletsPageSize: state.ui.userWalletsPageSize,
});

const mapDispatchToProps = {
	getWallets,
	setCurrentPageSize,
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletsTab);
