// @flow

import {
	GET_PROVIDERS,
	GET_PROVIDERS_INSTANCES,
	GET_PROVIDER_INSTANCE,
	ADD_PROVIDER_INSTANCE,
	UPDATE_PROVIDER_INSTANCE,
} from '../const/types';

export function getProviders() {
	return (dispatch: Function): void => dispatch({
		type: GET_PROVIDERS,
		payload: {
			request: {
				url: '/providers',
			},
		},
	});
}

export function getProvidersInstances(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PROVIDERS_INSTANCES,
		payload: {
			request: {
				url: `/applications/${applicationId}/provider-instances`,
			},
		},
	});
}

export function getProviderInstance(applicationId: string, providerInstanceId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PROVIDER_INSTANCE,
		payload: {
			request: {
				url: `/applications/${applicationId}/provider-instances/${providerInstanceId}`,
			},
		},
	});
}

export function addProviderInstance(applicationId: string, data: {[string]: string}) {
	return (dispatch: Function): void => dispatch({
		type: ADD_PROVIDER_INSTANCE,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/provider-instances`,
				data,
			},
		},
	});
}

export function updateProviderInstance(
	applicationId: string,
	providerInstanceId: string,
	data: {[string]: string},
) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_PROVIDER_INSTANCE,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/provider-instances/${providerInstanceId}`,
				data,
			},
		},
	});
}

export function deleteProviderInstance(
	applicationId: string,
	providerInstanceId: string,
) {
	return (dispatch: Function): void => dispatch({
		type: 'DELETE_PROVIDER_INSTANCE',
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/provider-instances/${providerInstanceId}`,
			},
		},
	});
}
