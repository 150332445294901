// @flow

import React from 'react';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Icon from '../../../components/UiElements/Icon';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { Group } from '../../../modules/reducers/GroupReducer';

type Props = {
	symbols: Array<Symbol>,
	loading: boolean,
	handleEditSettlement: (Symbol) => void,
	applicationId: string,
	groupId: string,
	currentPage: number,
	handlePageChange: Function,
	symbolsTotal: number,
}

const PostTradeSettlementsSymbols = (props: Props) => {
	const {
		symbols,
		handleEditSettlement,
		loading,
		applicationId,
		groupId,
		currentPage,
		handlePageChange,
		symbolsTotal,
	} = props;

	const getLink = (id) => {
		if (groupId === 'applicationSymbols') {
			return (
				<a
					href={`/application/${applicationId}/system/symbols/${id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{id}
				</a>
			);
		}
		return (
			<a
				href={`/application/${applicationId}/system/groups/${groupId}/${id}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{id}
			</a>
		);
	};

	const columns = [
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 112,
			render: (text: string) => getLink(text),
		},
		{
			title: 'name',
			dataIndex: 'displayName',
			key: 'name',
			width: 160,
			render: (text: string, record: Symbol) => (
				<div>
					{text || record.id}
				</div>
			),

		},
		{
			title: 'symbol type',
			dataIndex: 'group',
			key: 'type',
			width: 144,
			render: (text: Group) => (
				<div>
					{text ? `in ${text.name} Group` : 'App'}
				</div>
			),
		},
		{
			title: 'settlement symbols',
			dataIndex: 'isPostTradeSettlementAllowed',
			key: 'isPostTradeSettlementAllowed',
			width: 176,
			render: (text: boolean) => (
				<div className="capitalize">
					{text.toString()}
				</div>
			),
		},
		{
			title: 'edit',
			dataIndex: '',
			key: 'action',
			width: 80,
			render: () => (
				<Icon name="Edit" />
			),
			onCell: (record: Symbol) => ({
				onClick: (e) => {
					e.stopPropagation();
					handleEditSettlement(record);
				},
			}),
		},
	];

	return (
		<div>
			{!loading
				? (
					<CustomTable
						columns={columns}
						data={symbols}
						total={symbolsTotal}
						headerHeight={128}
						place="postTradeSettlementsSymbols"
						applicationId={applicationId}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
					/>
				)
				: <Spin spinning={loading} />}
		</div>
	);
};

export default PostTradeSettlementsSymbols;
