import React from 'react';
import OtpInput from 'react-otp-input';

type Props = {
    token: string,
    collectInput: (string) => void,
    isDisabled: boolean,
    hasErrored: boolean,
    errorMessage: string,
    inputStyle?: string,
    containerStyle?: string,
    otpInputWrapperStyle?: string,
}

const OTPInput = ({
    token,
    collectInput,
    isDisabled,
    hasErrored,
    errorMessage,
    inputStyle,
    containerStyle,
    otpInputWrapperStyle,
}: Props) => {
    return (
        <>
            <OtpInput
                value={token}
                onChange={collectInput}
                numInputs={6}
                containerStyle={containerStyle}
                inputStyle={inputStyle}
                isInputNum={true}
                shouldAutoFocus={true}
                isDisabled={isDisabled}
                hasErrored={hasErrored}
                errorStyle='two-factor-error'
            />
            <div className={otpInputWrapperStyle}>
            {errorMessage && <p className='otp-error'>{errorMessage}</p>}
            </div>
        </>
    )
}

OTPInput.defaultProps = {
    inputStyle: 'otp-input',
    containerStyle: 'otp-container',
    otpInputWrapperStyle: 'otp-input-wrapper',
}

export default OTPInput;