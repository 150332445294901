// @flow

import React from 'react';
import { Link } from 'react-router-dom';
//= import componnets
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Icon from '../../../components/UiElements/Icon';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { MappedFee } from '../../../modules/selectors/FeeSelector';

type Props = {
	withdrawalFees: Array<MappedFee>,
	editFeeRank: (MappedFee, number) => void,
	setFeeToEdit: (MappedFee) => void,
	applicationId: string,
	place: string,
}

const WithdrawalFees = (props: Props) => {
	const {
		withdrawalFees, editFeeRank, place, setFeeToEdit, applicationId,
	} = props;

	const columns = [{
		title: 'sort',
		dataIndex: '',
		key: 'dragIcon',
		width: 80,
		render: () => (
			<Icon name="Sort" />
		),
	}, {
		title: 'rule',
		dataIndex: 'feeName',
		key: 'rule',
		width: 272,
		render: (text: string, record: MappedFee) => (
			<div className="capitalize">
				{place === 'appFees'
					? (
						<Link
							to={`/application/${applicationId}/system/fees/${record.id}`}
						>
							{ActivityHelpers.transformSnakeCase(text)}
						</Link>
					)
					: (
						<Link
							to={`/application/${applicationId}/clients/${record.userId}/user_fees/${record.id}`}
						>
							{ActivityHelpers.transformSnakeCase(text)}
						</Link>
					)}

			</div>
		),
	}, {
		title: 'fee(%)',
		dataIndex: 'percentageFee',
		key: 'fee',
		align: 'right',
		width: 80,
		render: (text: string) => (
			<div>{text ? `${PriceHelpers.formatNumber(text, 2)}%` : '-'}</div>
		),
	}, {
		title: 'net fee',
		dataIndex: 'netFeeCal',
		key: 'netFee',
		align: 'right',
		width: 128,
	}, {
		title: 'min fee',
		dataIndex: 'minimumFeeCal',
		key: 'minFee',
		align: 'right',
		width: 128,
	}, {
		title: 'mex fee',
		dataIndex: 'maximumFeeCal',
		key: 'maxFee',
		align: 'right',
		width: 128,
	}, {
		title: 'edit',
		dataIndex: '',
		key: 'action',
		width: 80,
		render: () => (
			<Icon name="Edit" />
		),
		onCell: (record: MappedFee) => (
			{ onClick: (e) => { e.stopPropagation(); setFeeToEdit(record); } }
		),
	}];
	return (
		<div>
			{withdrawalFees
				? (
					<CustomTable
						columns={columns}
						data={withdrawalFees}
						total={withdrawalFees.length}
						headerHeight={136}
						place="withdrawalFees"
						applicationId={applicationId}
						moveAction={editFeeRank}
					/>
				) : <Spin spinning={!withdrawalFees} />}
		</div>
	);
};

export default WithdrawalFees;
