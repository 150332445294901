// @flow

import React from 'react';
import moment from 'moment';
//= import components
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import Table from '../../../components/UiElements/Table';
import Tag from '../../../components/UiElements/Tag';
//= import types
import type { RecurringPayment, Payment } from '../../../modules/reducers/PaymentReducer';

type Props = {
	recurringPayments: RecurringPayment,
	applicationId: string,
}

const RecurringPaymentsCard = (props: Props) => {
	const { recurringPayments, applicationId } = props;
	const ordersColumns = [
		{
			title: 'TIME CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 192,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'TYPE',
			dataIndex: 'type',
			key: 'type',
			width: 144,
			render: (text: string, record: Payment) => (
				<a
					href={`/application/${applicationId}/payments/${record.id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="flex">
						<div className={`${text} box`} />
						<span className="capitalize primary-tag">
							{text}
						</span>
					</div>
				</a>
			),
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			key: 'status',
			width: 112,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
	];
	return (
		<>
			{recurringPayments.payments && recurringPayments.payments.length > 1 && (
				<DataCard parts={[{
					title: 'Recurring Payments',
					component: (
						<Table
							rowKey={(record: Payment) => record.id}
							columns={ordersColumns}
							dataSource={recurringPayments.payments}
							locale={{ emptyText: 'No payments!' }}
							pagination={false}
						/>
					),
				}]}
				/>
			)}
		</>
	);
};

export default RecurringPaymentsCard;
