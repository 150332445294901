// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Tag from '../../../components/UiElements/Tag';
import Icon from '../../../components/UiElements/Icon';
import Spin from '../../../components/UiElements/Spin';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { BankAccountType, AccountDetailsType } from '../../../modules/reducers/BankReducer';

type Props = {
	applicationId: string,
	accounts: Array<BankAccountType>,
	accountsTotal: number,
	currentPage: number,
	headerHeight: number,
	pageSize: number,
	handlePageChange: Function,
	place: string,
	loading: boolean,
}

function AccountsList(props: Props) {
	const {
		applicationId,
		accounts,
		accountsTotal,
		currentPage,
		headerHeight,
		handlePageChange,
		pageSize,
		place,
		loading,
	} = props;

	const getLink = (bankAccountId, userId, accountId) => {
		switch (place) {
		case 'customerAccounts':
			return `/application/${applicationId}/banking/${bankAccountId}?place=customer`;
		case 'clientAccounts':
			return `/application/${applicationId}/client_bank_accounts/${bankAccountId}?place=client`;
		case 'accountAccounts':
			return `/application/${applicationId}/clients/${userId}/client_bank_accounts/${bankAccountId}?accountId=${accountId}`;
		case 'userAccounts':
			return `/application/${applicationId}/clients/${userId}/client_bank_accounts/${bankAccountId}?accountId=${accountId}`;

		default:
			return `/application/${applicationId}/banking/${bankAccountId}?place=customer`;
		}
	};

	const idColumns = [
		{
			title: 'time created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		}, {
			title: 'account id',
			dataIndex: 'id',
			key: 'id',
			width: 336,
			render: (text: string, record: BankAccountType) => (
				<CopyComponent
					text={text}
					content={(
						<Link
							to={getLink(
								text,
								record.userBankAccount?.bankUser.userId,
								record.userBankAccount?.accountId,
							)}
						>
							{text}
						</Link>
					)}
				/>
			),
		}, {
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 336,
			render: (text: string, record: BankAccountType) => (
				<div className="flex">
					<span className="primary-tag">{text || '-'}</span>
					{record.customerBankAccount?.isCollection && <Tag status="collection" />}
					{record.customerBankAccount?.isPayOut && <Tag status="payout" />}
				</div>
			),
		}];

	const clientColumns = [{
		title: 'client',
		dataIndex: 'userBankAccount',
		key: 'client',
		width: 336,
		render: (user: Object) => (
			<CopyComponent
				text={user.bankUser.userId}
				content={(
					<Link
						to={`/application/${applicationId}/clients/${user.bankUser.userId}?accountId=${user.accountId}&tab=user_bank_accounts`}
					>
						{user.bankUser.userId}
					</Link>
				)}
			/>
		),
	}];

	const restColumns = [{
		title: 'instrument',
		dataIndex: 'instrumentId',
		key: 'instrument',
		width: 128,
		render: (text: string) => (
			<Tag status={text} />
		),
	}, {
		title: 'account details',
		dataIndex: 'accountDetails',
		key: 'accountDetails',
		width: 256,
		render: (text: AccountDetailsType) => (
			<div>
				{text.map((el) => (el.details.iban || `${el.details.sortCode || ''} / ${el.details.accountNumber || ''}`
				)).toString()}
			</div>
		),
	}, {
		title: 'provider',
		dataIndex: 'provider',
		key: 'provider',
		width: 160,
		render: (text: {[string]: string}) => (
			<div className="capitalize">
				{text.name}
			</div>
		),
	}, {
		title: 'balance',
		dataIndex: 'balance',
		key: 'balance',
		width: 160,
		render: (text: string, record: BankAccountType) => (
			<div>
				{text
					? (
						<span>{PriceHelpers.formatAmount(
							text,
							record.instrumentId,
							Numbers.sign(text || '0'),
						)}
						</span>
					)
					: <span className="coming-soon">Coming soon</span>}
			</div>
		),
	}];
	const editColumn = {
		title: 'edit',
		dataIndex: '',
		key: 'action',
		width: 96,
		render: () => (
			<Icon name="Edit" colorName="ghost" />
		),
		onCell: () => ({
			onClick: (e) => {
				e.stopPropagation();
			},
		}),
	};

	const columns = place === 'customerAccounts'
		? [...idColumns, ...restColumns, editColumn]
		: [...idColumns, ...clientColumns, ...restColumns];

	return (
		<>
			{!loading
				? (
					<CustomTable
						columns={columns}
						data={accounts}
						total={accountsTotal}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						numberOnPage={pageSize}
						headerHeight={headerHeight}
						place={place}
						applicationId={applicationId}
					/>
				) : <Spin spinning={loading} />}
		</>
	);
}

export default AccountsList;
