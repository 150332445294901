// @flow

import React from 'react';

type IconStyle = {
    width?: string,
    height?: string,
}

const EmptyGroup = ({
	width = '238px',
	height = '238px',
}: IconStyle) => (
	<svg width={width} height={height} viewBox="0 0 238 238" version="1.1">
		<desc>Created with Sketch.</desc>
		<defs>
			<path d="M19.6458219,0 L101.955056,18.9594121 L81.5908278,49.031925 C79.9190042,51.5007609 76.8668069,52.6396239 73.9863827,51.8693631 L2.98956079,32.8839411 C2.68530618,32.8025797 2.39576095,32.6738158 2.13156327,32.5023802 C0.74168056,31.6004977 0.346078224,29.742655 1.24796073,28.3527722 L19.6458219,0 Z" id="path-1" />
			<filter x="-12.4%" y="-18.2%" width="128.7%" height="155.7%" filterUnits="objectBoundingBox" id="filter-3">
				<feOffset dx="2" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
				<feColorMatrix values="0 0 0 0 0.756862745   0 0 0 0 0.756862745   0 0 0 0 0.796078431  0 0 0 0.369427448 0" type="matrix" in="shadowBlurOuter1" />
			</filter>
			<path d="M0,26.7567776 C10.3585535,19.6902181 15.7889029,10.8860496 16.2910483,0.344272035 C19.0110049,-0.0611341696 24.2650261,0.444706225 37.447441,2.73507466 C38.6750688,2.94836791 38.7836175,4.89430515 37.7730869,8.5728864 C42.2175577,10.201467 44.2540225,11.7527823 43.8824812,13.2268324 C40.6612244,26.0068239 36.3996214,31.8335691 29.9678848,34.5716596 C22.5901238,35.5075778 12.6008289,32.9026172 0,26.7567776 Z" id="path-4" />
			<filter x="-12.5%" y="-15.9%" width="125.0%" height="163.7%" filterUnits="objectBoundingBox" id="filter-5">
				<feOffset dx="0" dy="11" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feColorMatrix values="0 0 0 0 0.105882353   0 0 0 0 0.207843137   0 0 0 0 0.329411765  0 0 0 0.272192029 0" type="matrix" in="shadowOffsetOuter1" />
			</filter>
		</defs>
		<g id="empty_group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-24">
				<rect id="Rectangle" x="0" y="0" width="238" height="238" />
				<g id="Group-2" transform="translate(27.000000, 59.000000)">
					<g id="Box">
						<polygon id="side" fill="#D1D1D6" points="81.1944973 0 80.4941228 76.9364162 19.3108614 94.0907876 19.3108614 21.0571355" />
						<polygon id="side" fill="#C1C1CB" points="80.9438202 0 163.011142 18.9588696 163.011142 92.7499328 80.9438202 76.9364162" />
						<path d="M19.3108614,20.982659 L101.378183,39.9415286 L101.378183,120.375055 L23.7273016,99.1275402 C21.1193354,98.4139256 19.3108614,96.0441204 19.3108614,93.3402836 L19.3108614,20.982659 Z" id="side" fill="#E5E5E6" />
						<path d="M163.838692,18.8843931 L163.175044,91.7863917 C163.152837,94.2258854 161.655767,96.4090572 159.388151,97.3088036 L101.254682,120.375055 L101.254682,39.9415286 L163.838692,18.8843931 Z" id="side" fill="#CECED4" />
						<path d="M163.578713,18.8277607 L183.211159,46.1596197 C184.177759,47.5052984 183.870455,49.3797704 182.524776,50.34637 C182.323579,50.4908897 182.105256,50.6099329 181.874794,50.7007809 L126.840505,72.39526 C124.117007,73.4688613 121.016325,72.4202918 119.503435,69.9140543 L101.086588,39.4049122 L163.578713,18.8277607 Z" id="lid" fill="#E5E5E6" />
						<g id="lid" transform="translate(0.000000, 20.283237)">
							<mask id="mask-2" fill="white">
								<use xlinkHref="#path-1" />
							</mask>
							<g id="Mask">
								<use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-1" />
								<use fill="#E5E5E6" fillRule="evenodd" xlinkHref="#path-1" />
							</g>
						</g>
					</g>
					<g id="yellow" transform="translate(45.000000, 9.000000)">
						<path d="M37.7655876,2.88053748 C39.1816496,4.27222124 40.3887605,5.62171292 41.3869201,6.92901254 C43.4648238,9.65046343 44.0852249,13.4120973 43.9407456,13.5071004 C43.1525302,14.0253955 38.6459367,11.0355288 37.7655876,8.60716378 C37.2378179,7.15135802 37.2378179,5.24248259 37.7655876,2.88053748 Z" id="Path-12" fill="#E5E5E6" />
						<g id="Path-9">
							<use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-4" />
							<use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-4" />
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

EmptyGroup.defaultProps = {
	width: '238px',
	height: '192px',
};

export default EmptyGroup;
