// @flow

import React from 'react';

const UserNoChecks = () => (
	<svg width="238px" height="238px" viewBox="0 0 238 238">
		<title>user_no_ckecks</title>
		<desc>Created with Sketch.</desc>
		<defs>
			<filter x="-34.6%" y="-34.6%" width="169.2%" height="169.2%" filterUnits="objectBoundingBox" id="filter-1">
				<feGaussianBlur stdDeviation="9" in="SourceGraphic" />
			</filter>
		</defs>
		<g id="user_no_ckecks" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-24">
				<rect id="Rectangle" x="0" y="0" width="238" height="238" />
				<g id="Group-22" transform="translate(46.000000, 34.000000)">
					<g id="Group-21" transform="translate(72.500000, 84.500000) rotate(-15.000000) translate(-72.500000, -84.500000) translate(17.000000, 12.000000)">
						<path d="M4,0 L107,0 C109.209139,-4.05812251e-16 111,1.790861 111,4 L111,141 C111,143.209139 109.209139,145 107,145 L4,145 C1.790861,145 2.705415e-16,143.209139 0,141 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z" id="Rectangle" fill="#C4C4C8" opacity="0.451241629" />
						<path d="M56,20 L92,20 C94.209139,20 96,21.790861 96,24 L96,25 C96,27.209139 94.209139,29 92,29 L56,29 C53.790861,29 52,27.209139 52,25 L52,24 C52,21.790861 53.790861,20 56,20 Z" id="Rectangle" fill="#C1C1CB" />
						<path d="M54.5,35 L93.5,35 C94.8807119,35 96,36.1192881 96,37.5 C96,38.8807119 94.8807119,40 93.5,40 L54.5,40 C53.1192881,40 52,38.8807119 52,37.5 C52,36.1192881 53.1192881,35 54.5,35 Z" id="Rectangle-Copy" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M35.5,55 L93.5,55 C94.8807119,55 96,56.1192881 96,57.5 C96,58.8807119 94.8807119,60 93.5,60 L35.5,60 C34.1192881,60 33,58.8807119 33,57.5 C33,56.1192881 34.1192881,55 35.5,55 Z" id="Rectangle-Copy-2" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M17.5,55 L25.5,55 C26.8807119,55 28,56.1192881 28,57.5 C28,58.8807119 26.8807119,60 25.5,60 L17.5,60 C16.1192881,60 15,58.8807119 15,57.5 C15,56.1192881 16.1192881,55 17.5,55 Z" id="Rectangle-Copy-2" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M17.5,65 L93.5,65 C94.8807119,65 96,66.1192881 96,67.5 C96,68.8807119 94.8807119,70 93.5,70 L17.5,70 C16.1192881,70 15,68.8807119 15,67.5 C15,66.1192881 16.1192881,65 17.5,65 Z" id="Rectangle-Copy-3" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M35.5,85 L93.5,85 C94.8807119,85 96,86.1192881 96,87.5 C96,88.8807119 94.8807119,90 93.5,90 L35.5,90 C34.1192881,90 33,88.8807119 33,87.5 C33,86.1192881 34.1192881,85 35.5,85 Z" id="Rectangle-Copy-2" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M17.5,85 L25.5,85 C26.8807119,85 28,86.1192881 28,87.5 C28,88.8807119 26.8807119,90 25.5,90 L17.5,90 C16.1192881,90 15,88.8807119 15,87.5 C15,86.1192881 16.1192881,85 17.5,85 Z" id="Rectangle-Copy-2" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M17.5,95 L93.5,95 C94.8807119,95 96,96.1192881 96,97.5 C96,98.8807119 94.8807119,100 93.5,100 L17.5,100 C16.1192881,100 15,98.8807119 15,97.5 C15,96.1192881 16.1192881,95 17.5,95 Z" id="Rectangle-Copy-3" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M35.5,115 L93.5,115 C94.8807119,115 96,116.119288 96,117.5 C96,118.880712 94.8807119,120 93.5,120 L35.5,120 C34.1192881,120 33,118.880712 33,117.5 C33,116.119288 34.1192881,115 35.5,115 Z" id="Rectangle-Copy-2" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M17.5,115 L25.5,115 C26.8807119,115 28,116.119288 28,117.5 C28,118.880712 26.8807119,120 25.5,120 L17.5,120 C16.1192881,120 15,118.880712 15,117.5 C15,116.119288 16.1192881,115 17.5,115 Z" id="Rectangle-Copy-2" fill="#C1C1CB" opacity="0.543829055" />
						<path d="M17.5,125 L93.5,125 C94.8807119,125 96,126.119288 96,127.5 C96,128.880712 94.8807119,130 93.5,130 L17.5,130 C16.1192881,130 15,128.880712 15,127.5 C15,126.119288 16.1192881,125 17.5,125 Z" id="Rectangle-Copy-3" fill="#C1C1CB" opacity="0.543829055" />
						<circle id="Oval" fill="#C1C1CB" cx="28.5" cy="28.5" r="13.5" />
					</g>
					<g id="Group-18" transform="translate(70.000000, 59.000000)">
						<circle id="Oval" fill="#C1C1CB" filter="url(#filter-1)" cx="39" cy="39" r="39" />
						<circle id="Oval" fill="#C1C1CB" cx="40" cy="38" r="36" />
						<path d="M35.24,45.14 C35.039999,43.7799932 35.0899985,42.5400056 35.39,41.42 C35.6900015,40.2999944 36.1199972,39.2600048 36.68,38.3 C37.2400028,37.3399952 37.8699965,36.460004 38.57,35.66 C39.2700035,34.859996 39.9399968,34.0900037 40.58,33.35 C41.2200032,32.6099963 41.7499979,31.8900035 42.17,31.19 C42.5900021,30.4899965 42.8,29.7600038 42.8,29 C42.8,27.7599938 42.4300037,26.8200032 41.69,26.18 C40.9499963,25.5399968 39.980006,25.22 38.78,25.22 C37.6599944,25.22 36.6700043,25.4799974 35.81,26 C34.9499957,26.5200026 34.120004,27.179996 33.32,27.98 L28.46,23.54 C29.860007,21.8999918 31.5299903,20.580005 33.47,19.58 C35.4100097,18.579995 37.5599882,18.08 39.92,18.08 C41.520008,18.08 43.0099931,18.279998 44.39,18.68 C45.7700069,19.080002 46.9699949,19.6999958 47.99,20.54 C49.0100051,21.3800042 49.8099971,22.4499935 50.39,23.75 C50.9700029,25.0500065 51.26,26.5799912 51.26,28.34 C51.26,29.5800062 51.0400022,30.6899951 50.6,31.67 C50.1599978,32.6500049 49.6100033,33.5599958 48.95,34.4 C48.2899967,35.2400042 47.5900037,36.0499961 46.85,36.83 C46.1099963,37.6100039 45.4300031,38.4199958 44.81,39.26 C44.1899969,40.1000042 43.7000018,40.9899953 43.34,41.93 C42.9799982,42.8700047 42.8599994,43.939994 42.98,45.14 L35.24,45.14 Z M39.08,59.72 C37.5599924,59.72 36.300005,59.1900053 35.3,58.13 C34.299995,57.0699947 33.8,55.7600078 33.8,54.2 C33.8,52.599992 34.299995,51.2700053 35.3,50.21 C36.300005,49.1499947 37.5599924,48.62 39.08,48.62 C40.6000076,48.62 41.8699949,49.1499947 42.89,50.21 C43.9100051,51.2700053 44.42,52.599992 44.42,54.2 C44.42,55.7600078 43.9100051,57.0699947 42.89,58.13 C41.8699949,59.1900053 40.6000076,59.72 39.08,59.72 Z" id="?" fill="#FFFFFF" fillRule="nonzero" />
					</g>
				</g>
			</g>
		</g>
	</svg>);

export default UserNoChecks;
