// @flow

import React, {
	useEffect, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CPButton from '../../../components/UiElements/Button';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Title from '../../../components/UiElements/Title';
import Card from '../../../components/UiElements/Card';
import List from '../../../components/UiElements/List';
import Checkbox from '../../../components/UiElements/Checkbox';
import Drawer from '../../../components/UiElements/Drawer';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import LimitControls from '../components/LimitControls';
import ControlComponent from '../components/ControlComponent';
import LimitControlForm from '../components/LimitControlForm';
//= import actions
import {
	getProduct,
	getCardDesign,
	updateProduct,
	addMerchantScope,
	addLimitControl,
	getEntryMode,
	getTransactionTypes,
	getMerchantCategoryCodes,
} from '../../../modules/actions/CardAction';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type {
	Product, EntryModes, TransactionTypes, MerchantCategoryCodes, LimitControlType,
} from '../../../modules/reducers/CardsReducer';
//= import images
import master from '../../../assets/img/cards/master.png';
import visa from '../../../assets/img/cards/visa.png';
import bcLogo from '../../../assets/img/company_logo.svg';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	product: Product,
	isFetchingProduct: boolean,
	getProduct: (string, string) => void,
	updateProduct: (string, string, Object) => void,
	getCardDesign: (string, string) => void,
	getEntryMode: () => void,
	getTransactionTypes: () => void,
	getMerchantCategoryCodes: () => void,
	addLimitControl: (string, Object) => Promise<Object>,
	addMerchantScope: (string, Object) => Promise<Object>,
	entryModes: Array<EntryModes>,
	transactionTypes: Array<TransactionTypes>,
	merchantCategoryCodes: Array<MerchantCategoryCodes>,
	match: {
		params: {
			applicationId: string,
			productId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				title: string,
				errors: {[string]: Array<string>}
			}
		}
	}
}

function ProductPage(props: Props) {
	const {
		product,
		isFetchingProduct,
		match: { params: { applicationId, productId } },
		getProduct: getProductAction,
		getCardDesign: getCardDesignAction,
		addLimitControl: addLimitControlAction,
		addMerchantScope: addMerchantScopeAction,
		updateProduct: updateProductAction,
		getEntryMode: getEntryModeAction,
		getTransactionTypes: getTransactionTypesAction,
		getMerchantCategoryCodes: getMerchantCategoryCodesAction,
		entryModes,
		transactionTypes,
		merchantCategoryCodes,
	} = props;

	const [add, setAdd] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [drawerData, setDrawerData] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		getProductAction(applicationId, productId);
		getCardDesignAction(applicationId, productId);
		getEntryModeAction();
		getTransactionTypesAction();
		getMerchantCategoryCodesAction();
	}, [
		applicationId,
		productId,
		getProductAction,
		getCardDesignAction,
		getEntryModeAction,
		getTransactionTypesAction,
		getMerchantCategoryCodesAction,
	]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleOpenDrawer = (limitControl: LimitControlType) => {
		setDrawerOpen(true);
		const data = {
			name: 'Limit Control',
			details: [
				{ key: 'Amount Limit', value: limitControl.amountLimit },
				{ key: 'Currency', value: limitControl.currency.name },
				{ key: 'Usage Limit', value: limitControl.usageLimit },
				{ key: 'Velocity Window', value: limitControl.velocityWindow },
			],
			control: limitControl,
		};
		setDrawerData(data);
	};

	const onClose = () => {
		setDrawerOpen(false);
	};

	const addLimit = () => {
		setAdd(true);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setAdd(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error ? error.error.response.data.title : 'Error',
			secondPart: error.error && error.error.response.data
				? printError(error.error.response.data.errors)
				: '',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleAddLimitCancel = () => {
		setButtonLoading(true);
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setAdd(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setAdd(false);
			setButtonLoading(false);
			setWarningModal(false);
		}
	};

	const submitAddLimit = async (limitData, merchantData) => {
		setButtonLoading(true);
		const productData = {
			programInstanceId: product.programInstanceId,
			name: product.name,
			description: product.name,
			maxCardsPerUser: product.maxCardsPerUser,
			physicalCardsEnabled: product.physicalCardsEnabled,
			virtualCardsEnabled: product.virtualCardsEnabled,
			externalWalletsEnabled: product.externalWalletsEnabled,
			cardEmbossingStrategy: product.cardEmbossingStrategy,
			multiCurrencyEnabled: product.multiCurrencyEnabled,
			autoConversionEnabled: product.autoConversionEnabled,
			cardValidityPeriodInMonths: product.cardValidityPeriodInMonths,
			threeDomainSecureEnabled: product.threeDomainSecureEnabled,
			defaultCurrencyCode: product.defaultCurrency.isoCode,
			currencyCodes: product.currencies.map((el) => el.isoCode),
			countryCodes: product.countries.map((el) => el.isoAlpha3Code),
			cardDesignIds: product.cardDesigns.map((el) => el.id),
			isActive: product.isActive,
			cardCreationEnabled: product.cardCreationEnabled,
			authorizationControlId: product.authorizationControl.id,
		};
		try {
			const merchantRes = merchantData
				? await addMerchantScopeAction(applicationId, merchantData)
				: null;
			const data = {
				...limitData,
				merchantScopeId: merchantRes ? merchantRes.payload.data.id : null,
			};
			const limitRes = await addLimitControlAction(applicationId, data);
			const updateProductData = {
				...productData,
				limitControls: [
					...product.limitControls.map((el) => (
						{ level: el.level, limitControlId: el.limitControl.id }
					)),
					{
						level: 'Card',
						limitControlId: limitRes.payload.data.id,
					}],
			};
			await updateProductAction(applicationId, product.id, updateProductData);
			setButtonLoading(false);
			setWarningModal(false);
			if (formRef.current) {
				formRef.current.handleReset();
			}
		} catch (error) { handleOnError(error); }
	};

	const continueAddingLimit = (limitData, merchantData) => {
		setWarningModal(true);
		setAdd(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect this Product.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAddLimit(limitData, merchantData), text: 'add limit control' },
		]);
	};

	const handleEditClick = () => {
		props.history.push(`/application/${applicationId}/system/card/update_product/${product.id}`);
	};

	const imageMap = {
		MasterCard: master,
		Visa: visa,
	};

	const infoData = [
		{ key: 'Is Active', value: product.isActive ? 'Yes' : 'No' },
		{ key: 'Card Creation Enabled', value: product.cardCreationEnabled ? 'Enable' : 'Disable' },
		{ key: 'Start Date', value: moment(product.startDate).format('YYYY-MM-DD') },
		{ key: 'End Date', value: moment(product.endDate).format('YYYY-MM-DD') },
	];

	const detailsData = product.name ? [
		{ key: 'Name', value: product.name },
		{ key: 'Card Scheme', value: product.cardScheme },
		{ key: 'Card Type', value: product.cardType },
		{ key: 'Card Purpose', value: product.cardPurpose },
		{ key: 'Description', value: product.description },
		{ key: 'Default Currency', value: product.defaultCurrency.name },
		{ key: 'Bin Type', value: product.binType },
		{ key: 'Card Embossing Strategy', value: product.cardEmbossingStrategy },
		{ key: 'Card Validity Period In Months', value: product.cardValidityPeriodInMonths },
		{ key: 'Loop Type', value: product.loopType },
		{ key: 'Max Cards Per Client', value: product.maxCardsPerUser },
		{ key: 'Delivery Methods', value: product.deliveryMethods.map((el) => el.name).join(', ') },
		{ key: 'Created At', value: moment(product.createdAt).format('YYYY-MM-DD HH:mm:ss') },
		{ key: 'Updated At', value: moment(product.updatedAt).format('YYYY-MM-DD HH:mm:ss') },
	] : [];
	const checkData = product.name ? [
		{ key: 'Physical Cards Enabled', value: product.physicalCardsEnabled },
		{ key: 'Virtual Cards Enabled', value: product.virtualCardsEnabled },
		{ key: 'External Wallets Enabled', value: product.externalWalletsEnabled },
		{ key: 'Auto Conversion Enabled', value: product.autoConversionEnabled },
		{ key: 'Multi Currency Enabled', value: product.multiCurrencyEnabled },
		{ key: 'Three Domain Secure Enabled', value: product.threeDomainSecureEnabled },
	] : [];
	const controlData = product.authorizationControl ? {
		name: 'Authorization Control',
		control: product.authorizationControl,
	} : {};

	return (
		<>
			{!isFetchingProduct ? (
				<>
					<Title
						backAction={handleBackClick}
						title={product.name}
						place="product"
						buttons={[
							{
								text: 'edit card product',
								action: handleEditClick,
							},
						]}
					>
						{imageMap[product.cardScheme]
							? <img src={imageMap[product.cardScheme]} className={styles.titleImg} alt="bc" />
							: <img src={bcLogo} className={styles.titleImg} alt="bc" />}
					</Title>
					<div className="page-container">
						<Row gutter={24}>
							<Col span={12}>
								<Card bordered={false} className={styles.productCard}>
									<div className={styles.list}>
										<List
											itemLayout="horizontal"
											dataSource={infoData}
											split={false}
											renderItem={
												(item: {[string]: string}) => (
													<List.Item key={item.key}>
														<List.Item.Meta
															title={item.key}
														/>
														<div className={styles.listValue}>{item.value}</div>
													</List.Item>
												)
											}
										/>
									</div>
								</Card>
								<Card bordered={false} className={styles.productCard}>
									<h2>Product Details</h2>
									<div className={styles.list}>
										<List
											itemLayout="horizontal"
											dataSource={detailsData}
											split={false}
											renderItem={
												(item: {[string]: string}) => (
													<List.Item key={item.key}>
														<List.Item.Meta
															title={item.key}
														/>
														<div className={styles.listValue}>{item.value}</div>
													</List.Item>
												)
											}
										/>
									</div>
									<div className={styles.checkGroup}>
										{checkData.map((el) => (
											<Checkbox
												key={el.key}
												checked={el.value}
											>
												{el.key}
											</Checkbox>
										))}
									</div>
									{product.cardDesigns && (
										<div>
											<h4>Card Designs</h4>
											{product.cardDesigns.map((el) => (
												<img src={el.imageUrl} alt="design" key={el.imageUrl} />
											))}
										</div>
									)}
								</Card>
							</Col>
							{!!product.authorizationControl && (
								<Col span={12} className="col-margin">
									<Card bordered={false}>
										<ControlComponent controlData={controlData} />
									</Card>
								</Col>
							)}
						</Row>
						{!!product.limitControls && (
							<Row>
								<LimitControls
									limitControlsData={product.limitControls}
									title="Product Limit Controls"
									applicationId={applicationId}
									handleOpenDrawer={handleOpenDrawer}
									addLimitControl={addLimit}
								/>
							</Row>
						)}
					</div>
				</>
			) : <Spin spinning={isFetchingProduct} />}
			<FormModal
				title="Create New Limit Control"
				visible={add}
				cancelFunction={handleAddLimitCancel}
				form={(
					<LimitControlForm
						submitChanges={continueAddingLimit}
						handleCancelClick={handleAddLimitCancel}
						entryModes={entryModes}
						transactionTypes={transactionTypes}
						merchantCategoryCodes={merchantCategoryCodes}
						currencies={product.currencies}
						wrappedComponentRef={formRef}
					/>
				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
			<Drawer
				width={600}
				placement="right"
				onClose={onClose}
				visible={drawerOpen}
				closable={false}
			>
				<ControlComponent controlData={drawerData} />
				<div className={styles.close}>
					<CPButton
						text="close"
						action={onClose}
					/>
				</div>
			</Drawer>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	product: state.cards.product,
	isFetchingProduct: state.cards.isFetchingProduct,
	cardDesign: state.cards.cardDesign,
	entryModes: state.cards.entryModes,
	transactionTypes: state.cards.transactionTypes,
	merchantCategoryCodes: state.cards.merchantCategoryCodes,
});
const mapDispatchToProps = {
	getProduct,
	getCardDesign,
	updateProduct,
	getEntryMode,
	getTransactionTypes,
	getMerchantCategoryCodes,
	addMerchantScope,
	addLimitControl,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
