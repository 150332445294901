// @flow

import React, {
	useEffect, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import uniq from 'lodash/uniq';
//= import components
import Title from '../../../components/UiElements/Title';
import ActivityLog from '../../Logs/containers/ActivityLog';
//= import actions
import { getActivityLog } from '../../../modules/actions/LogsActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
import { getEvents } from '../../../modules/actions/WebhooksActions';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Event } from '../../../modules/reducers/WebhooksReducer';

type Props = {
	setCurrentPage: (string, number) => void,
	getActivityLog: (string, number, number, string) => void,
	getEvents: () => Promise<Object>,
	events: Array<Event>,
	filters: { [string]: Array<string> },
	currentPage: number,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	location: {
		pathname: string,
		search: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}

const Logs = (props: Props) => {
	const {
		match: { params: { applicationId, userId } },
		location: { search },
		filters,
		currentPage,
		events,
		setCurrentPage: setCurrentPageAction,
		getActivityLog: getActivityLogAction,
		getEvents: getEventsAction,
	} = props;

	const [active, setActive] = useState('');
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		getEventsAction();
	}, [getEventsAction]);

	useEffect(() => {
		const affectedModels = uniq(filters.eventName?.map((el) => el.split('.')[0]));
		const eventNames = uniq(filters.eventName?.map((el) => el.split('.')[1]));
		const filterParam = {
			...filters,
			eventName: eventNames,
			affectedModel: affectedModels,
			ownedBy: userId,
		};

		getActivityLogAction(applicationId, 50, currentPage, queryString.stringify(filterParam, { arrayFormat: 'comma' }));
		setActive(queryString.parse(search).active);
	}, [applicationId, search, filters, currentPage, getActivityLogAction, userId]);

	const filterRef = useRef(null);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [filters]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('activityLogPage', page);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const eventNames = events.map((el) => ({
		id: el.name,
		name: el.name,
	}));

	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="Activity Log"
					place="activityLog"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					fields={{
						eventName: eventNames,
						dateRange: 'time',
					}}
					date
					tab="log"
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					applicationId={applicationId}
					filter
				/>
			</div>
			<div className="container">
				<ActivityLog
					applicationId={applicationId}
					handlePageChange={handlePageChange}
					filterHeight={filterHeight}
					active={active}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.activityLogPage,
	filters: state.ui.activityLogFilters,
	events: state.webhooks.events,
});

const mapDispatchToProps = {
	setCurrentPage,
	getActivityLog,
	getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
