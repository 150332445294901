// @flow

import React, {
	useEffect, useState, useRef, useCallback,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../components/UiElements/CustomTable';
import Title from '../../components/UiElements/Title';
import Spin from '../../components/UiElements/Spin';
import Tag from '../../components/UiElements/Tag';
import CopyComponent from '../../components/UiElements/CopyComponent';
//= import helpers
import StatusHelpers from '../../lib/helpers/statusHelpers';
//= import actions
import { getUsers, searchUsers } from '../../modules/actions/UsersActions';
import { getStaticCountries } from '../../modules/actions/StaticActions';
import { getGroups } from '../../modules/actions/GroupAction';
import { setCurrentPage, setCurrentPageSize } from '../../modules/actions/UiActions';
import { fetchUserTags } from '../../modules/actions/UserTagsActions';
//= import types
import type { State } from '../../modules/types/FlowTypes';
import type { User, UserAddress, UserInfo } from '../../modules/reducers/UsersReducer';
import type { Country } from '../../modules/reducers/StaticReducer';
import type { Group } from '../../modules/reducers/GroupReducer';
import type { FilterType } from '../../modules/reducers/UiReducer';

type UserResponse = {
	payload: {
		data: Array<User>,
	}
}

type Props = {
	getUsers: (string, number, number, string) => Promise<UserResponse>,
	setCurrentPage: (string, number) => void,
	setCurrentPageSize: (string, number) => void,
	searchUsers: (string, string) => Promise<UserResponse>,
	getStaticCountries: () => Promise<Object>,
	getGroups: (string) => void,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	currentPage: number,
	users: Array<User>,
	usersTotal: number,
	countries: Array<Country>,
	groups: Array<Group>,
	filters: Array<FilterType>,
	search: string,
	userTags: Array,
	pageSize: numner,
}

function UsersPage(props: Props) {
	const {
		users,
		currentPage,
		usersTotal,
		countries,
		groups,
		filters,
		pageSize,
		search,
		systemTags,
		getUsers: getUsersAction,
		searchUsers: searchUsersAction,
		getStaticCountries: getStaticCountriesAction,
		getGroups: getGroupsAction,
		setCurrentPage: setCurrentPageAction,
		setCurrentPageSize: setCurrentPageSizeAction,
		fetchUserTags: fetchUserTagsAction,
		match: { params: { applicationId } },
	} = props;
	const [dataFetched, setDataFetched] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		setDataFetched(false);
		async function getUsersData() {
			try {
				if (search) {
					await searchUsersAction(applicationId, search);
					setDataFetched(true);
				} else {
					await getUsersAction(applicationId, currentPage, pageSize, queryString.stringify(filters, { arrayFormat: 'comma' }));
					setDataFetched(true);
				}
			} catch (error) {
				setDataFetched(true);
			}
		}
		getUsersData();
	}, [applicationId, currentPage, filters, search, pageSize, getUsersAction, searchUsersAction]);

	const fetchTags = useCallback(async () => {
		await fetchUserTagsAction(applicationId);
	},[fetchUserTagsAction, applicationId]);

	useEffect(() => {
		fetchTags();
	}, [fetchTags]);

	const handlePageChange = (page: number, pageSize: number) => {
		setCurrentPageAction('appUsersPage', page);
		setCurrentPageSizeAction('appUsersPageSize', pageSize);
	};

	const filterRef = useRef(null);
	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [filters]);

	const handleFilter = async () => {
		if (!countries.length) {
			getStaticCountriesAction();
		}
		if (!groups.length) {
			getGroupsAction(applicationId);
		}
		setFilterVisible(true);
	};

	const columns = [
		{
			title: 'date joined',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 144,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
		{
			title: 'name',
			dataIndex: 'userInfo',
			key: 'name',
			width: 340,
			render: (userInfo: UserInfo, record: User) => (
				<div className="flex-container">
					<span className="primary-tag wrap">
						<Link to={`/application/${applicationId}/clients/${record.id}`}>
							{userInfo ? `${userInfo.firstName || 'Name Not Set'} ${userInfo.lastName || ' '}` : 'Name Not Set'}
						</Link>
					</span>
					{!!record.suspended && (
						<Tag status="suspended" />
					)}
				</div>
			),
		},
		{
			title: 'e-mail',
			dataIndex: 'email',
			key: 'email',
			width: 320,
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 208,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
		{
			title: 'country',
			dataIndex: 'userAddress',
			key: 'country',
			width: 160,
			render: (userAddress: UserAddress) => (
				<div>
					{userAddress?.countryCode || '-'}
				</div>
			),
		},
		{
			title: 'city',
			dataIndex: 'userAddress',
			key: 'city',
			width: 160,
			render: (userAddress: UserAddress) => (
				<div>
					{userAddress?.city || '-'}
				</div>
			),
		},
		{
			title: 'group',
			dataIndex: 'group',
			key: 'group',
			width: 160,
			render: (group: {name: string}) => (
				<div>
					{group?.name || '-'}
				</div>
			),
		},
		{
			title: 'risk level',
			dataIndex: 'riskLevel',
			key: 'riskLevel',
			width: 160,
			render: (text: string) => (
				<div className="capitalize">
					{text || 'not set'}
				</div>
			),
		},
		{
			title: 'dob',
			dataIndex: 'userInfo',
			key: 'birthday',
			width: 144,
			render: (userInfo: UserInfo) => (
				<div>
					{userInfo?.birthday || '-'}
				</div>
			),
		},
		{
			title: 'phone',
			dataIndex: 'userInfo',
			key: 'phone',
			width: 144,
			render: (userInfo: UserInfo) => (
				<div>
					{userInfo?.phone || '-'}
				</div>
			),
		},
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 340,
			render: (text: string) => (
				<CopyComponent text={text} content={text} />
			),
		},
	];
	return (
		<>
			<div ref={filterRef}>
				<Title
					title="clients list"
					buttons={[
						{
							action: handleFilter,
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					searchComponent={{
						placeholder: 'Search For Clients...',
						disabled: false,
					}}
					applicationId={applicationId}
					place="appUsers"
					fields={{
						country: countries,
						status: StatusHelpers.statuses,
						suspended: StatusHelpers.suspendedStatuses,
						groupId: groups,
						dateRange: 'time',
						riskLevel: StatusHelpers.riskLevelsFilterOptions,
						dateOfBirth: 'dob',
						tagIds: systemTags,
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
				/>
			</div>
			<div className="container">
				{dataFetched
					? (
						<CustomTable
							columns={columns}
							data={users}
							total={usersTotal}
							handlePageChange={handlePageChange}
							currentPage={currentPage}
							numberOnPage={pageSize}
							headerHeight={filterHeight}
							place="appUsers"
							pageSizeOptions={[10,20,30,40,50]}
							applicationId={applicationId}
						/>
					) : <Spin spinning={!dataFetched} />}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.appUsersPage,
	users: state.users.users,
	usersTotal: state.users.usersTotal,
	countries: state.static.countries,
	groups: state.group.groups,
	filters: state.ui.appUsersFilters,
	search: state.ui.appUsersSearch,
	systemTags: state.tags.systemTags,
	pageSize: state.ui.appUsersPageSize,
});

const mapDispatchToProps = {
	getUsers,
	setCurrentPage,
	setCurrentPageSize,
	searchUsers,
	getStaticCountries,
	getGroups,
	fetchUserTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
