// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

//= import components
import Title from '../../../components/UiElements/Title';
import CustomTable from '../../../components/UiElements/CustomTable';
import Button from '../../../components/UiElements/Button';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import TagForm from '../components/TagForm';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';

// Import usertag actions
import {
	createUserTag,
	editUserTag,
	deleteUserTag,
	fetchUserTags,
} from '../../../modules/actions/UserTagsActions';

type Props = {
	match: {
		params: {
			applicationId: string,
		}
	},
	history: {
		push: (Object) => void,
		goBack: () => void,
	},
	createUserTag: (Object, string) => Promise<Object>,
	editUserTag: (string, string, Object) => Promise<Object>,
	deleteUserTag: (string, string) => Promise<Object>,
	fetchUserTags: (string) => Promise<Object>,
	userTags: Array<Object>,
}

function UserTags(props: Props) {
	const {
		userTags,
		match: { params: { applicationId } },
		fetchUserTags: fetchUserTagsActions,
		editUserTag: editUserTagActions,
		deleteUserTag: deleteUserTagActions,
		createUserTag: createUserTagActions,
	} = props;

	const [add, setAdd] = useState(false);
	const [edit, setEdit] = useState(false);
	const [tagIdToUpdate, setTagIdToUpdate] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [placeholder, setPlaceholder] = useState('');
	const formRef = useRef(null);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleAddCancel = () => {
		setErrorMessage('');
		setAdd(false);
	};

	const handleCancel = () => {
		setErrorMessage('');
		setAdd(false);
	};

	const handleEditCancel = () => {
		setErrorMessage('');
		setEdit(false);
	};

	const handleDeleteCancel = () => {
		setErrorMessage('');
		setWarningModal(false);
	};

	const handleSubmit = async (value) => {
		const tag = {
			value: value.value,
		};
		try {
			await createUserTagActions(tag, applicationId);
			setAdd(false);
			fetchUserTagsActions(applicationId);
		} catch (error) {
			if (error.type === 'CREATE_USER_TAG_FAIL') {
				setErrorMessage('Sorry - you already added this tag!');
			}
		}
	};

	const handleAddNew = () => {
		setAdd(true);
	};

	const handleEditUserTag = (record) => {
		setPlaceholder(record.value);
		setTagIdToUpdate(record.id);
		setEdit(true);
	};

	const handleSubmitEdit = async (value) => {
		await editUserTagActions(applicationId, tagIdToUpdate, value)
			.then(() => fetchUserTagsActions(applicationId))
			.then(() => setEdit(false))
			.catch((error) => {
				if (error.type === 'EDIT_USER_TAG_FAIL') {
					setErrorMessage('Sorry - you already added this tag. Try using a unique name!');
				}
			});
	};

	const handleModalOpen = (record) => {
		setWarningModal(true);
		setTagIdToUpdate(record.id);
	};

	const handleDeleteUserTag = async () => {
		await deleteUserTagActions(applicationId, tagIdToUpdate);
		await fetchUserTagsActions(applicationId);
		setWarningModal(false);
	};

	const button = [{ type: 'continue' }];

	useEffect(() => {
		fetchUserTagsActions(applicationId);
	}, [applicationId, fetchUserTagsActions]);

	const columns = [
		{
			title: 'Tag name',
			dataIndex: 'value',
			editable: true,
			key: 'value',
			width: 160,
			render: (text: String) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'Created by',
			dataIndex: 'createdBy',
			key: 'createdBy',
			width: 160,
			render: (text: String) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'Last modified',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 208,
			render: (text: string) => (
				<div className="capitalize">
					{moment(text).format('MMMM Do YYYY, h:mm:ss a')}
				</div>
			),
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: 'actions',
			width: 208,
			render: (_, record: Object) => (
				<div className="capitalize">
					<Button
						type="primary"
						text="Edit"
						action={() => handleEditUserTag(record)}
					/>

					<Button
						type="link"
						text="Delete"
						action={() => handleModalOpen(record)}
					/>
				</div>
			),
		},
	];

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="User Tags"
				buttons={[
					{
						text: 'add new tag',
						icon: 'Plus',
						action: handleAddNew,
					},
				]}
			/>
			<div className="container">
				<CustomTable
					columns={columns}
					data={userTags}
					total={userTags.length || 0}
					currentPage={1}
					place="userTags"
					applicationId={applicationId}
					headerHeight={16}
					width={736}
				/>
				<FormModal
					title="Add New Tag"
					visible={add}
					cancelFunction={handleAddCancel}
					form={(
						<TagForm
							submitChanges={handleSubmit}
							handleCancelClick={handleCancel}
							wrappedComponentRef={formRef}
							errorState={errorMessage}
							title="Create Tag"
						/>
					)}
				/>
				<FormModal
					title="Update tag"
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<TagForm
							submitChanges={handleSubmitEdit}
							handleCancelClick={handleEditCancel}
							wrappedComponentRef={formRef}
							errorState={errorMessage}
							placeholder={placeholder}
							title="Update Tag"
						/>
					)}
				/>

				<DeleteModal
					footer={button}
					btnText="Delete"
					message="When you delete this tag, it will also be deleted for all users who currently have the tag. Proceed?"
					visible={warningModal}
					onCancel={handleDeleteCancel}
					cancelFunction={handleDeleteCancel}
					okFunction={handleDeleteUserTag}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	userTags: state.tags.systemTags,
	admin: state.application.application.name,
	state,

});

const mapDispatchToProps = {
	createUserTag,
	fetchUserTags,
	editUserTag,
	deleteUserTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTags);
