// @flow

import React from 'react';
import map from 'lodash/map';
//= import components
import Card from '../../../components/UiElements/Card';
import Switch from '../../../components/UiElements/Switch';

type Props = {
	values: {
		visible: {
			name: string;
			value: any;
			parent: any;
		},
		tradable: {
			name: string;
			value: any;
			parent: any;
		},
		closeOnly: {
			name: string;
			value: any;
			parent: any;
		}
	},
	handleToggle: Function,
	group: boolean,
	switchKey: boolean,
}

const SwitchComponent = (props: Props) => {
	const {
		values, handleToggle, group, switchKey,
	} = props;

	return (
		<div className="switch-component" key={switchKey.toString()}>
			{map(values, (value, key) => (
				<Card bordered={false} key={key}>
					<h2 className="capitalize">{key}</h2>
					<Switch
						defaultChecked={value.value}
						onChange={(data) => handleToggle(value.name, data)}
						disabled={
							(group && value.name === 'closeOnly')
						}
					/>
				</Card>
			))}
		</div>
	);
};

export default SwitchComponent;
