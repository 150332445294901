// @flow
import React from 'react';
import { connect } from 'react-redux';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Spin from '../../../components/UiElements/Spin';
import Table from '../../../components/UiElements/Table';
//= import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
//= import styles
import styles from '../assets/features.module.scss';

type Props = {
	form: *,
	applicationId: string,
	getAppInstruments: (string, string, string, string) => Promise<Object>,
	submitChanges: Function,
	handleCancelClick: Function,
}

type LocalState = {
	selectedKey: Array<string>,
	haircut: number | null,
	instruments: Array<Instrument>,
	loading: boolean,
}

class AddCollateral extends React.Component<Props, LocalState> {
	state = {
		selectedKey: [],
		haircut: 0,
		instruments: [],
		loading: false,
	}

	loadingRef = React.createRef();

	componentDidMount() {
		const {
			applicationId,
			getAppInstruments: getAppInstrumentsAction,
		} = this.props;
		this.setState({
			loading: true,
		});
		const date = new Date();

		getAppInstrumentsAction(applicationId, 'infinite-scroll', date.toISOString(), '')
			.then((res) => {
				const instrumentsForCollateral = res.payload.data
					.filter((el) => el.haircut === null);
				this.setState({
					instruments: instrumentsForCollateral,
					loading: false,
				});
			});
		const tableContent = document.querySelector('.ant-table-tbody');
		const type:string = 'scroll';
		tableContent.addEventListener(type, this.handleEvent);
	}

	handleEvent = (e:Event) => {
		const {
			applicationId,
			getAppInstruments: getAppInstrumentsAction,
		} = this.props;
		const { instruments } = this.state;
		const maxScroll = e.target.scrollHeight - e.target.clientHeight;
		const currentScroll = e.target.scrollTop;
		if (currentScroll === maxScroll) {
			const paginationOffset = instruments[instruments.length - 1].createdAt;
			getAppInstrumentsAction(applicationId, 'infinite-scroll', paginationOffset, '')
				.then((res) => {
					const instrumentsForCollateral = res.payload.data
						.filter((el) => el.haircut === null);
					this.setState((prevState) => ({
						instruments: [...prevState.instruments, ...instrumentsForCollateral],
					}));
				});
		}
	}

	onInstrumentSelect = (key) => {
		this.setState({
			selectedKey: key,
		});
	}

	handleHaircutChange = (value) => {
		this.setState({
			haircut: value,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		const { selectedKey, haircut, instruments } = this.state;
		form.validateFields((err: Array<Object>) => {
			if (!err) {
				const instrument = instruments.find((el) => el.id === selectedKey[0]);
				const haircutData = { haircut };
				submitChanges(instrument, haircutData);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			selectedKey: [],
			haircut: null,
		});
	}

	render() {
		const {
			handleCancelClick,
		} = this.props;
		const {
			selectedKey, instruments, loading,
		} = this.state;
		const columns = [
			{
				title: 'ID',
				dataIndex: 'instrumentId',
				key: 'id',
				width: 90,
			},
			{
				title: 'NAME',
				dataIndex: 'name',
				key: 'name',
				width: 240,
			},
			{
				title: 'HAIRCUT',
				dataIndex: 'haircut',
				key: 'haircut',
				width: 144,
				render: (text, record) => (
					<InputNumber
						max={100}
						symbol="%"
						onChange={this.handleHaircutChange}
						disabled={selectedKey[0] !== record.id}
						placeholder="Set Haircut"
						type="number"
					/>
				),
			},
		];
		const rowSelection = {
			onChange: this.onInstrumentSelect,
			type: 'radio',
			selectedRowKeys: selectedKey,
		};
		const FormItem = Form.Item;
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<Input.Search
					disabled
					className="search transfer-search"
					placeholder="Search For Instruments..."
					allowClear
				/>
				<div ref={this.loadingRef} onScroll={this.handleEvent} className={`scroll-div ${styles.collateral}`}>
					{!loading ? (
						<Table
							rowSelection={rowSelection}
							rowKey={(record) => record.id}
							columns={columns}
							dataSource={instruments}
							pagination={false}
							scroll={{ y: (window.innerHeight - 512) }}
							rowClassName="editable-row"
						/>
					) : <Spin spinning={loading} />}
				</div>
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!selectedKey.length} text="add collateral" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const AddCollateralForm = Form.create()(AddCollateral);

const mapDispatchToProps = {
	getAppInstruments,
};

export default connect(null, mapDispatchToProps)(AddCollateralForm);
