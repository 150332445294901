// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import componnets
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import Icon from '../../../components/UiElements/Icon';
//= import types
import type { Section } from '../../../modules/reducers/SectionReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	sections: Array<Section>,
	isFetchingSections: boolean,
	handleEditClick: (Section) => void,
	applicationId: string,
}

const SectionsPage = (props: Props) => {
	const {
		sections,
		isFetchingSections,
		applicationId,
		handleEditClick,
	} = props;

	const columns = [{
		title: 'created at',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 208,
		render: (text: string) => (
			<div>
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'order',
		dataIndex: 'order',
		key: 'order',
		width: 112,
		render: (text: number) => (
			<div>{`# ${text}`}</div>
		),
	}, {
		title: 'name',
		dataIndex: 'name',
		key: 'name',
		width: 304,
		render: (text: string, record: Section) => (
			<Link
				to={`/application/${applicationId}/system/explore/section/${record.id}`}
			>
				{text}
			</Link>
		),
	}, {
		title: 'display',
		dataIndex: 'display',
		key: 'display',
		width: 208,
		render: (text: string) => (
			<div className="capitalize">
				{text}
			</div>
		),
	}, {
		title: 'edit',
		dataIndex: '',
		key: 'action',
		width: 80,
		render: () => (
			<Icon name="Edit" />
		),
		onCell: (record: Section) => ({
			onClick: (e) => {
				e.stopPropagation();
				handleEditClick(record);
			},
		}),
	}];
	return (
		<div className="container">
			{!isFetchingSections
				? (
					<CustomTable
						columns={columns}
						data={sections}
						total={sections.length}
						currentPage={1}
						headerHeight={136}
						place="exploreSections"
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingSections} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	sections: state.section.sections,
	isFetchingSections: state.section.isFetchingSections,
});

export default connect(mapStateToProps)(SectionsPage);
