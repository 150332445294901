// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import moment from 'moment';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Radio from '../../../components/UiElements/Radio';
import Spin from '../../../components/UiElements/Spin';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
import { getFeeQuote } from '../../../modules/actions/FeeActions';
import { getQuoteForOrder } from '../../../modules/actions/OrderActions';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
import PriceHelpers from '../../../lib/helpers/priceHelpers';
// = import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { Account } from '../../../modules/reducers/AccountsReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	form: *,
	submitChanges: (Object, Object, Object, Object) => void,
	handleCancelClick: () => void,
	userId: string,
	userName: string,
	accounts: Array<Account>,
	activeAccount: string,
	applicationId: string,
	getAppSymbols: (string, string, number, string) => Promise<Object>,
	getFeeQuote: (string, string) => Promise<Object>,
	getQuoteForOrder: (string, string, {[string]: string}) => Promise<Object>,
}
type LocalState = {
	errorMessage: string,
	symbolSelected: Symbol,
	symbolData: Array<Symbol>,
	symbolFetching: boolean,
	notFoundContent: string,
	accountSelected: Account,
	walletSelected: Object,
	review: boolean,
	side: string,
	type: ?string,
	inputMode: ?string,
	fee: string,
	quantity: ?string,
	price: ?string,
	amount: ?string,
	quoteId: ?string,
	buttonLoading: boolean,
	expiration: string,
}

const calculateQuantity = (
	amount: string,
	walletPrecision: number,
	price: string,
	basePrecision: number,
) => Numbers.floor(Numbers.div(Numbers.floor(amount, walletPrecision), price), basePrecision);

const calculateAmount = (
	side: string,
	quantity: string,
	walletPrecision: number,
	price: string,
	basePrecision: number,
) => {
	if (side === 'buy') {
		return Numbers.ceil(Numbers.mul(Numbers.floor(quantity || '0', basePrecision || 2), price), walletPrecision || 2);
	}
	return Numbers.floor(Numbers.mul(Numbers.floor(quantity || '0', basePrecision || 2), price), walletPrecision || 2);
};
const getButtonText = (review, type) => {
	if (review) {
		return 'place order';
	}
	if (type === 'previouslyQuoted') {
		return 'request for quote';
	}
	return 'review order';
};
class Order extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		symbolSelected: {},
		symbolData: [],
		symbolFetching: false,
		notFoundContent: '',
		accountSelected: this.props.accounts.find((el) => el.id === this.props.activeAccount) || {},
		walletSelected: {},
		review: false,
		type: undefined,
		side: '',
		inputMode: undefined,
		quantity: undefined,
		price: undefined,
		amount: undefined,
		fee: '0',
		quoteId: undefined,
		buttonLoading: false,
		expiration: '',
	}

	componentDidMount() {
		const { accounts, activeAccount } = this.props;
		this.setState({
			accountSelected: accounts.find((el) => el.id === activeAccount) || {},
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			symbolSelected: {},
			accountSelected: {},
			errorMessage: '',
			review: false,
			type: undefined,
			side: '',
			inputMode: undefined,
			quantity: undefined,
			price: undefined,
			amount: undefined,
			quoteId: undefined,
			expiration: '',
		});
	}

	handleSymbolSearch = debounce((symbolValue: string) => {
		const { getAppSymbols: getAppSymbolsAction, applicationId } = this.props;
		this.setState({ symbolFetching: true });
		if (symbolValue) {
			const filterOptions: string = queryString.stringify({ search: symbolValue });
			getAppSymbolsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							symbolData: data,
							symbolFetching: false,
						});
					} else {
						this.setState({
							symbolData: [],
							symbolFetching: false,
							notFoundContent: `Symbol with id ${symbolValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						symbolFetching: false,
						symbolData: [],
						notFoundContent: 'Symbol not found',
					});
				});
		} else {
			this.setState({
				symbolFetching: false,
				symbolData: [],
				notFoundContent: 'Enter Id to find the Symbol',
			});
		}
	}, 500);

	selectSymbol = (value: string) => {
		const { form: { setFieldsValue } } = this.props;
		const { symbolData, accountSelected } = this.state;
		setFieldsValue({
			orderType: undefined, orderSide: undefined, inputMode: undefined, quantity: 0,
		});
		const symbol = symbolData.find((el) => el.id === value);
		const wallet = accountSelected.wallets?.find(
			(el) => el.instrumentId === symbol?.quotingInstrumentId,
		);
		this.setState({
			symbolSelected: symbol || {},
			walletSelected: wallet || {},
			type: undefined,
			side: '',
			inputMode: undefined,
			errorMessage: wallet ? '' : 'Orders with conversion are not supported yet',
		});
	}

	selectAccount = (value: string) => {
		const { form: { setFieldsValue } } = this.props;
		const { symbolSelected } = this.state;
		setFieldsValue({ orderType: undefined, orderSide: undefined, inputMode: undefined });
		const acc = this.props.accounts.find((el) => el.id === value);
		if (symbolSelected.id) {
			const wallet = acc?.wallets?.find(
				(el) => el.instrumentId === symbolSelected?.quotingInstrumentId,
			);
			this.setState({
				accountSelected: acc,
				errorMessage: wallet ? '' : 'Orders with conversion are not supported yet',
				walletSelected: wallet || {},
			});
		} else {
			this.setState({
				accountSelected: acc,
			});
		}
	}

	selectType = (e) => {
		this.setState({
			type: e.target.value,
		});
	}

	selectSide = (e) => {
		const { walletSelected } = this.state;
		this.setState((prevState) => ({
			side: e.target.value,
			price: e.target.value === 'buy' ? prevState.symbolSelected.price.ask : prevState.symbolSelected.price.bid,
			errorMessage: e.target.value === 'buy' && walletSelected.available === '0'
				? 'Insufficient funds. Deposit money to your wallet and try again.'
				: '',
		}));
	}

	selectInputMode = (e) => {
		this.setState({
			inputMode: e.target.value,
		});
	}

	validateAmount = (rule, value, callback) => {
		const { symbolSelected, accountSelected } = this.state;
		const precision = symbolSelected.quotingPrecision;
		const quotingWallets = accountSelected?.wallets
			? accountSelected
				.wallets.filter((el) => el.instrumentId === symbolSelected.quotingInstrumentId)
			: [];
		if (!value) {
			callback("Value can't be left empty");
		}
		if (parseFloat(value.toFixed(precision)) !== value) {
			callback(`Precision must be less than or equal to the ${precision}`);
		}
		if (Numbers.greaterThan(value.toString(), quotingWallets[0]?.available)) {
			callback(`You cannot buy for more than ${quotingWallets[0]?.available} ${quotingWallets[0]?.instrumentId}`);
		}
		callback();
	}

	validateQuantity = (rule, value, callback) => {
		const {
			side, symbolSelected, walletSelected, accountSelected,
		} = this.state;
		const precision = symbolSelected.basePrecision;
		const baseWallets = accountSelected?.wallets
			? accountSelected.wallets.filter((el) => el.instrumentId === symbolSelected.baseInstrumentId)
			: [];
		if (!value) {
			callback("Value can't be left empty");
		}
		if (parseFloat(value.toFixed(precision)) !== value) {
			callback(`Precision must be less than or equal to the ${precision}`);
		}
		if (walletSelected.available === '0' && side === 'buy') {
			callback('Insufficient funds. Deposit money to your wallet and try again.');
		}
		if (side === 'sell' && Numbers.greaterThan(value.toString(), baseWallets[0]?.available || '0')) {
			callback(`You cannot sell more than ${baseWallets[0]?.available} ${baseWallets[0]?.instrumentId}`);
		}
		callback();
	}

	validatePrice = (rule, value, callback) => {
		const { symbolSelected } = this.state;
		if (!value) {
			callback("Value can't be left empty");
		}
		if (parseFloat(value.toFixed(symbolSelected.quotingPrecision)) !== value) {
			callback(`Precision must be less than or equal to the ${symbolSelected.quotingPrecision}`);
		}
		callback();
	}

	setQuantity = (value: number) => {
		if (value) {
			this.setState((prevState) => ({
				quantity: value.toString(),
				amount: calculateAmount(
					prevState.side,
					value.toString(),
					prevState.walletSelected.instrument?.precision,
					prevState.price || '0',
					prevState.symbolSelected.basePrecision,
				),
			}));
		}
	}

	setPrice = (value: number) => {
		if (value) {
			this.setState((prevState) => ({
				price: value.toString(),
				amount: calculateAmount(
					prevState.side,
					prevState.quantity ? prevState.quantity.toString() : '0',
					prevState.walletSelected.instrument?.precision,
					value.toString(),
					prevState.symbolSelected.basePrecision,
				),
			}));
		}
	}

	setAmount = (value) => {
		if (value) {
			this.setState((prevState) => ({
				amount: value.toString(),
				quantity: calculateQuantity(
					value.toString(),
					prevState.walletSelected.instrument?.precision,
					prevState.price || '0',
					prevState.symbolSelected.basePrecision,
				),
			}));
		}
	}

	selectWallet = (value) => {
		this.setState((prevState) => ({
			walletSelected: prevState.accountSelected.wallets.find((el) => el.id === value),
		}));
	}

	reviewOrder = (status) => {
		if (status) {
			const {
				form,
				applicationId,
				userId,
				getFeeQuote: getFeeQuoteAction,
				getQuoteForOrder: getQuoteForOrderAction,
			} = this.props;
			const {
				type, side, quantity, amount, symbolSelected, accountSelected, walletSelected,
			} = this.state;
			form.validateFields(async (err: Array<Object>) => {
				if (err) {
					const formErr = (Object.values(err).map((error: Object) => error?.errors?.[0]?.message));
					this.setState({
						errorMessage: formErr.join(', '),
					});
				} else {
					this.setState({
						buttonLoading: true,
					});
					const feeParameter = {
						type: 'trading',
						walletInstrumentId: walletSelected.instrumentId,
						amount,
						orderSide: side,
						orderType: type,
						symbolId: symbolSelected.id,
						userId,
						providerInstanceId: symbolSelected.tradingProviderId,
					};
					if (type === 'previouslyQuoted' && quantity) {
						const quoteData = {
							userId,
							symbolId: symbolSelected.id,
							quantity: quantity?.toString(),
							side,
						};
						try {
							const quoteForOrder = await getQuoteForOrderAction(accountSelected.id, 'nortide', quoteData);
							const feeQuote = await getFeeQuoteAction(applicationId, queryString.stringify(feeParameter, { arrayFormat: 'comma' }));
							this.setState({
								review: true,
								price: quoteForOrder.payload.data.price,
								quoteId: quoteForOrder.payload.data.quoteId,
								fee: feeQuote.payload.data.feeAmount,
								buttonLoading: false,
								expiration: quoteForOrder.payload.data.expiration,
							});
						} catch (error) {
							const errMsg = error.error?.response?.data?.message
								|| error.error?.response?.data?.errors[0]?.messages[0]?.message;
							this.setState({
								errorMessage: `Can't get Quote for Order, ${errMsg}`,
								buttonLoading: false,
							});
						}
					} else {
						try {
							const feeQuote = await getFeeQuoteAction(applicationId, queryString.stringify(feeParameter, { arrayFormat: 'comma' }));
							this.setState({
								review: true,
								fee: feeQuote.payload.data.feeAmount,
								buttonLoading: false,
							});
						} catch (error) {
							const errMsg = error.error?.response?.data?.errors[0]?.messages[0]?.message;
							this.setState({
								errorMessage: `Can't get fee Quote for Order, ${errMsg}`,
								buttonLoading: false,
							});
						}
					}
				}
			});
		} else {
			this.setState({ review: false });
		}
	};

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges, userId } = this.props;
		const {
			type, side, symbolSelected, quantity, accountSelected, amount, price, inputMode, quoteId,
		} = this.state;
		form.validateFields((err: Array<Object>) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const quotingWallets = accountSelected
					.wallets?.find((el) => el.instrumentId === symbolSelected.quotingInstrumentId);
				const data = type === 'previouslyQuoted'
					? {
						type,
						side,
						symbolId: symbolSelected.id,
						walletId: quotingWallets?.id,
						userId,
						quoteId,
					} : {
						type,
						side,
						symbolId: symbolSelected.id,
						walletId: quotingWallets?.id,
						userId,
						quantity: inputMode === 'quantity' ? quantity?.toString() : undefined,
						amount: inputMode === 'amount' ? amount?.toString() : undefined,
						price: price?.toString(),
					};
				submitChanges(accountSelected.id, data);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			accounts,
			activeAccount,
			userName,
		} = this.props;

		const {
			errorMessage,
			symbolSelected,
			symbolData,
			symbolFetching,
			notFoundContent,
			review,
			accountSelected,
			walletSelected,
			side,
			type,
			inputMode,
			quantity,
			fee,
			amount,
			price,
			buttonLoading,
			expiration,
		} = this.state;

		const quotingWallets = accountSelected?.wallets
			? accountSelected
				.wallets.filter((el) => el.instrumentId === symbolSelected.quotingInstrumentId)
			: [];
		const baseWallets = accountSelected?.wallets
			? accountSelected.wallets.filter((el) => el.instrumentId === symbolSelected.baseInstrumentId)
			: [];

		const userPart = [
			{ key: 'clientName', value: userName },
			{ key: 'account', value: accountSelected.name },
		];

		const detailsPart = [
			{ key: 'symbol', value: symbolSelected.id },
			{ key: 'side', value: side },
			{ key: 'orderType', value: type },
		];

		const quantityPart = quantity && price
			? [
				{ key: 'quantity', value: PriceHelpers.formatAmount(quantity, symbolSelected.baseInstrumentId) },
				{ key: 'estimatedPrice', value: PriceHelpers.formatAmount(price, symbolSelected.quotingInstrumentId) },
				{ key: 'wallet', value: `${walletSelected?.instrumentId} Wallet` },
			]
			: [];

		const summaryPart = amount
			? [
				{ key: 'estimatedSubtotal', value: PriceHelpers.formatAmount(amount, symbolSelected.quotingInstrumentId) },
				{ key: 'estimatedFee', value: PriceHelpers.formatAmount(fee, walletSelected?.instrumentId) },
			]
			: [];

		const FormItem = Form.Item;
		const { Option } = Select;

		const formLayout = {
			labelCol: {
				xs: { span: 11 },
			},
			wrapperCol: {
				xs: { span: 13 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className={styles.settings}>
				{review
					? (
						<div className={styles.orderCard}>
							{userPart.map((el) => (
								<div className={styles.data} key={el.key}>
									<div className={styles.key}>{ActivityHelpers.transformToReadable(el.key)}</div>
									<div className={styles.value}>{el.value}</div>
								</div>
							))}
							<h4>Order Details</h4>
							{detailsPart.map((el) => (
								<div className={styles.data} key={el.key}>
									<div className={styles.key}>{ActivityHelpers.transformToReadable(el.key)}</div>
									<div className={styles.value}>{el.value}</div>
								</div>
							))}
							<h4>Quantity</h4>
							{quantityPart.map((el) => (
								<div className={styles.data} key={el.key}>
									<div className={styles.key}>{ActivityHelpers.transformToReadable(el.key)}</div>
									<div className={styles.value}>{el.value}</div>
								</div>
							))}
							<h4>Summary</h4>
							{summaryPart.map((el) => (
								<div className={styles.data} key={el.key}>
									<div className={styles.key}>{ActivityHelpers.transformToReadable(el.key)}</div>
									<div className={styles.value}>{el.value}</div>
								</div>
							))}
							{expiration && type === 'previouslyQuoted'
							&& (
								<div className={styles.data}>
									<div className={styles.key}>expiration</div>
									<div className={styles.value}>
										{`Quote for Order will expire at ${moment(expiration).format('YYYY-MM-DD HH:mm:ss')}`}
									</div>
								</div>
							)}
						</div>
					) : (
						<>
							<div className={styles.orderInfo}>
								<div className={styles.key}>client name</div>
								<div className={styles.value}>{userName}</div>
							</div>
							<FormItem label="ACCOUNT" {...formLayout}>
								{getFieldDecorator('account', {
									initialValue: activeAccount,
								})(
									<Select
										showSearch
										placeholder="Select Account"
										onChange={this.selectAccount}
									>
										{accounts.map((el) => (
											<Option value={el.id} key={el.id}>
												{el.name}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<h4 className="subtitle">Order Details</h4>
							<FormItem label="SYMBOL" {...formLayout} help="">
								{getFieldDecorator('symbolId', {
									initialValue: symbolSelected.id,
								})(
									<Select
										allowClear
										showSearch
										placeholder="All Symbols"
										notFoundContent={symbolFetching ? <Spin size={16} /> : notFoundContent}
										filterOption={false}
										onSearch={this.handleSymbolSearch}
										onChange={this.selectSymbol}
									>
										{symbolData.map((el) => (
											<Option
												value={el.id}
												key={el.id}
											>
												{el.id}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							{symbolSelected.id && (
								<>
									<FormItem label="ORDER TYPE" {...formLayout} help="">
										{getFieldDecorator('orderType', { initialValue: type })(
											<Radio.Group
												buttonStyle="solid"
												onChange={this.selectType}
											>
												{symbolSelected.supportedOrderTypes.map((el) => (
													<Radio.Button
														key={el}
														value={el}
													>
														{el}
													</Radio.Button>
												))}
											</Radio.Group>,
										)}
									</FormItem>
									<FormItem label="ORDER SIDE" {...formLayout} help="">
										{getFieldDecorator('orderSide', { initialValue: side })(
											<Radio.Group
												buttonStyle="solid"
												onChange={this.selectSide}
											>
												<Radio.Button key="buy" value="buy">
													buy
												</Radio.Button>
												{!!baseWallets.length && Numbers.greaterThan(baseWallets[0].available, '0')
												&& (
													<Radio.Button key="sell" value="sell">
														sell
													</Radio.Button>
												)}
											</Radio.Group>,
										)}
									</FormItem>
									<FormItem label="INPUT MODE" {...formLayout} help="">
										{getFieldDecorator('inputMode', { initialValue: inputMode })(
											<Radio.Group
												buttonStyle="solid"
												onChange={this.selectInputMode}
											>
												<Radio.Button key="quantity" value="quantity">
													quantity
												</Radio.Button>
												{type === 'market' && side === 'buy'
												&& (
													<Radio.Button key="amount" value="amount">
														amount
													</Radio.Button>
												)}
											</Radio.Group>,
										)}
									</FormItem>
								</>
							)}
							{symbolSelected.id && (
								<>
									<h4 className="subtitle">Wallets</h4>
									<div className={styles.orderInfo}>
										<div className={styles.key}>
											{`${symbolSelected?.baseInstrumentId} wallet balance:`}
										</div>
										<div className={styles.value}>
											{PriceHelpers.formatAmount(
												baseWallets[0]?.available,
												symbolSelected?.baseInstrumentId,
											)}
										</div>
									</div>
									<div className={styles.orderInfo}>
										<div className={styles.key}>
											{`${symbolSelected?.quotingInstrumentId} wallet balance:`}
										</div>
										<div className={styles.value}>
											{PriceHelpers.formatAmount(
												quotingWallets[0]?.available,
												symbolSelected?.quotingInstrumentId,
											)}
										</div>
									</div>
									<h4 className="subtitle">Quantity</h4>
									{inputMode === 'quantity' && (
										<FormItem label="QUANTITY" {...formLayout}>
											{getFieldDecorator('quantity', {
												initialValue: quantity,
												rules: [{ validator: this.validateQuantity }],
											})(
												<InputNumber
													placeholder="Enter Quantity"
													onChange={this.setQuantity}
													symbol={symbolSelected.baseInstrumentId}
													min={0}
												/>,
											)}
										</FormItem>
									)}
									{inputMode === 'amount' && type === 'market' && (
										<FormItem label="AMOUNT" {...formLayout}>
											{getFieldDecorator('amount', {
												initialValue: amount,
												rules: [{ validator: this.validateAmount }],
											})(
												<InputNumber
													placeholder="Enter Amount"
													onChange={this.setAmount}
													symbol={symbolSelected.quotingInstrumentId}
													min={0}
												/>,
											)}
										</FormItem>
									)}
									{(type === 'limit' || type === 'stop') && (
										<>
											<div className={styles.orderInfo}>
												<div className={styles.key}>current price:</div>
												<div className={styles.value}>
													{PriceHelpers.formatAmount(
														side === 'buy' ? symbolSelected.price.ask : symbolSelected.price.bid,
														symbolSelected.quotingInstrumentId,
													)}
												</div>
											</div>
											<FormItem label="PRICE" {...formLayout}>
												{getFieldDecorator('price', {
													rules: [{ validator: this.validatePrice }],
												})(
													<InputNumber
														placeholder="Enter Price"
														onChange={this.setPrice}
														symbol={symbolSelected.quotingInstrumentId}
														min={0}
													/>,
												)}
											</FormItem>
										</>
									)}
									{side && (
										<>
											<FormItem label="WALLET" {...formLayout}>
												{getFieldDecorator('wallet', { initialValue: walletSelected?.id })(
													<Select
														showSearch
														placeholder="Select Wallet"
														onChange={this.selectWallet}
													>
														{quotingWallets.map((el) => (
															<Option value={el.id} key={el.id}>
																{`${el.instrumentId} Wallet`}
															</Option>
														))}
													</Select>,
												)}
											</FormItem>
										</>
									)}
								</>
							)}
							{errorMessage && (
								<ErrorMessage message={errorMessage} />
							)}
						</>
					)}
				<div className="form-buttons">
					<Form.Item>
						<CPButton
							ghost
							action={review ? () => this.reviewOrder(false) : handleCancelClick}
							text={review ? 'go back' : 'cancel'}
						/>
					</Form.Item>
					<Form.Item>
						<CPButton
							type="primary"
							action={review ? this.submit : () => this.reviewOrder(true)}
							disabled={!quantity || !amount || !walletSelected.id}
							text={getButtonText(review, type)}
							loading={buttonLoading}
						/>
					</Form.Item>
				</div>
			</Form>
		);
	}
}

const OrderForm = Form.create()(Order);

const mapDispatchToProps = {
	getAppSymbols,
	getFeeQuote,
	getQuoteForOrder,
};

export default connect(null, mapDispatchToProps)(OrderForm);
