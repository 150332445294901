// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
//= import components
import Title from '../../components/UiElements/Title';
import MenuItemCard from './components/MenuItemCard';
//= import actions
import { getAppFeatures } from '../../modules/actions/FeaturesAction';
//= import types
import type { Feature } from '../../modules/reducers/FeatureReducer';
import type { State } from '../../modules/types/FlowTypes';
//= import helpers
import ActivityHelpers from '../../lib/helpers/activityHelpers';
//= import styles
import styles from './assets/system.module.scss';

type Props = {
	features: Array<Feature>,
	getAppFeatures: (string) => void,
	match: {
		params: {
			applicationId: string
		}
	},
	location: {
		pathname: string,
	},
	history: {
		push: string => void,
	},
}

const System = (props: Props) => {
	const {
		match: { params: { applicationId } },
		features,
		getAppFeatures: getAppFeaturesAction,
	} = props;

	useEffect(() => {
		getAppFeaturesAction(applicationId);
	}, [getAppFeaturesAction, applicationId]);

	const featureIcons = (place) => {
		if (place === 'fractional_trading') {
			return 'Reports';
		}
		if (place === 'margin_trading') {
			return 'Marketing';
		}
		return 'Star';
	};

	const featureMenu = features.length
		? features.filter((el) => el.featureId !== 'card_module').map((feature) => ({
			place: feature.feature.id,
			icon: featureIcons(feature.feature.id),
			title: feature.feature.name,
		}))
		: [];

	const cardFeature = features.find((el) => el.featureId === 'card_module')
		? {
			cards: [{
				place: 'card',
				icon: 'Payments',
				title: 'Cards',
			}],
		}
		: {};

	const system = {
		core: [
			{
				place: 'details',
				icon: 'KYC',
				title: 'Application',
			},
			{
				place: 'features',
				icon: 'Star',
				title: 'Features',
			},
			{
				place: 'providers',
				icon: 'Integrations',
				title: 'Providers',
			},
			{
				place: 'instruments',
				icon: 'BTC',
				title: 'Instruments',
			},
			{
				place: 'event_subscriptions',
				icon: 'CalendarCreate',
				title: 'Events',
			},
			{
				place: 'webhooks',
				icon: 'Code',
				title: 'Webhooks',
			},
			{
				place: 'logs',
				icon: 'ActivityStream',
				title: 'Logs',
			},
			{
				place: 'statements',
				icon: 'Notes',
				title: 'Statements ',
			},
			{
				place: 'fees',
				icon: 'USD',
				title: 'Fees',
			},
			{
				place: 'suspend_reasons',
				icon: 'Lock',
				title: 'Suspend Reasons',
			},
			{
				place: 'api_keys',
				icon: 'ApiKey',
				title: 'Api Keys',
			},
			{
				place: 'user_tags',
				icon: 'UserTags',
				title: 'User Tags',
			},
		],
		investing: [
			{
				place: 'groups',
				icon: 'Teams',
				title: 'Groups',
			},
			{
				place: 'symbols',
				icon: 'Asset',
				title: 'Symbols',
			},
			{
				place: 'explore',
				icon: 'BrowserDefault',
				title: 'Explore',
			},
			{
				place: 'dividends',
				icon: 'Action',
				title: 'Dividends',
			},
			{
				place: 'delistings',
				icon: 'Reject',
				title: 'Delistings',
			},
			{
				place: 'stock_splits',
				icon: 'SendGrid',
				title: 'Splits',
			},
			{
				place: 'conversions',
				icon: 'EUR',
				title: 'Fiat Conversions',
			},
			...featureMenu,
		],
		...cardFeature,
	};

	return (
		<>
			<Title
				title="System"
			/>
			<div className="page-container">
				{map(system, (value, key) => (
					<div className={styles.control} key={key}>
						<h2>{ActivityHelpers.transformToReadable(key)}</h2>
						<div className={styles.cardContainer}>
							{value.length
								? value.map((el) => (
									<MenuItemCard
										key={el.title}
										icon={el.icon}
										title={el.title}
										place={el.place}
										applicationId={applicationId}
										disabled={el.disabled || false}
									/>
								))
								: <p>Coming Soon...</p>}
						</div>
					</div>
				))}
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	features: state.feature.appFeatures,
});

const mapDispatchToProps = {
	getAppFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
