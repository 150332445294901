// @flow

const initialState = {
	error: false,
	eventSubscriptions: [],
	isFetchingEventSubscriptions: false,
	isFetchedEventSubscriptions: false,
	eventSubscription: {},
	isFetchingEventSubscription: false,
	isFetchedEventSubscription: false,
	eventHandlers: [],
	isFetchingEventHandlers: false,
	isFetchedEventHandlers: false,
};

export type Event = {
	id: string,
	name: string,
	description: string,
	active: boolean,
	public: boolean,
	createdAt: string,
	updatedAt: string,
}
export type EventHandler = {
	id: string,
	name: string,
	description: string,
	createdAt: string,
	updatedAt: string,
	providerId?: string,
}
export type EventSubscription = {
	id: string,
	name: string,
	type: string,
	active: boolean,
	createdAt: string,
	updatedAt: string,
	applicationId: string,
	events: Array<Event>,
	eventHandlers: Array<EventHandler>,
}

export type EventSubscriptionState = {
	error: Object | boolean,
	eventSubscriptions: Array<EventSubscription>,
	isFetchingEventSubscriptions: boolean,
	isFetchedEventSubscriptions: boolean,
	eventSubscription: EventSubscription,
	isFetchingEventSubscription: boolean,
	isFetchedEventSubscription: boolean,
};

type SubscriptionActions = {
	type:
	| 'GET_EVENT_SUBSCRIPTIONS'
	| 'GET_EVENT_SUBSCRIPTIONS_SUCCESS'
	| 'GET_EVENT_SUBSCRIPTIONS_FAIL',
	payload: {
		data: Array<EventSubscription>,
	},
	error: {
		code: number,
		message: string,
	}
}
type SubscriptionAction = {
	type:
	| 'GET_EVENT_SUBSCRIPTION'
	| 'GET_EVENT_SUBSCRIPTION_SUCCESS'
	| 'GET_EVENT_SUBSCRIPTION_FAIL',
	payload: {
		data: EventSubscription,
	},
	error: {
		code: number,
		message: string,
	}
}
type HandlerAction = {
	type:
	| 'GET_EVENT_HANDLERS'
	| 'GET_EVENT_HANDLERS_SUCCESS'
	| 'GET_EVENT_HANDLERS_FAIL',
	payload: {
		data: Array<EventHandler>,
	},
	error: {
		code: number,
		message: string,
	}
}

type Action = SubscriptionAction | SubscriptionActions | HandlerAction;

export default function eventSubscriptionReducer(
	state: EventSubscriptionState = initialState,
	action: Action,
): EventSubscriptionState {
	switch (action.type) {
	case 'GET_EVENT_SUBSCRIPTIONS': {
		return {
			...state,
			error: false,
			isFetchingEventSubscriptions: true,
			isFetchedEventSubscriptions: false,
		};
	}
	case 'GET_EVENT_SUBSCRIPTIONS_FAIL': {
		return {
			...state,
			isFetchingEventSubscriptions: false,
			isFetchedEventSubscriptions: false,
		};
	}
	case 'GET_EVENT_SUBSCRIPTIONS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingEventSubscriptions: false,
			isFetchedEventSubscriptions: true,
			eventSubscriptions: action.payload.data,
		};
	}
	case 'GET_EVENT_SUBSCRIPTION': {
		return {
			...state,
			error: false,
			isFetchingEventSubscription: true,
			isFetchedEventSubscription: false,
		};
	}
	case 'GET_EVENT_SUBSCRIPTION_FAIL': {
		return {
			...state,
			isFetchingEventSubscription: false,
			isFetchedEventSubscription: false,
		};
	}
	case 'GET_EVENT_SUBSCRIPTION_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingEventSubscription: false,
			isFetchedEventSubscription: true,
			eventSubscription: action.payload.data,
		};
	}
	case 'GET_EVENT_HANDLERS': {
		return {
			...state,
			error: false,
			isFetchingEventHandlers: true,
			isFetchedEventHandlers: false,
		};
	}
	case 'GET_EVENT_HANDLERS_FAIL': {
		return {
			...state,
			isFetchingEventHandlers: false,
			isFetchedEventHandlers: false,
		};
	}
	case 'GET_EVENT_HANDLERS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingEventHandlers: false,
			isFetchedEventHandlers: true,
			eventHandlers: action.payload.data,
		};
	}
	default:
		return state;
	}
}
