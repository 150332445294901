// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Upload from '../../../components/UiElements/Upload';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= Import Types
import type { FormProps } from '../../../modules/types/FormTypes';
import type { Application } from '../../../modules/reducers/ApplicationReducer';

type Props = {
	form: FormProps,
	submitChanges: ({ [string]: string }) => void,
	handleCancelClick: () => void,
	data: Application,
	icon: string,
	logo: string,
}
type LocalState = {
	iconList: Array<Object>,
	logoList: Array<Object>,
	iconError: string,
	logoError: string,
}
type FileType = {
	type: string,
	size: number,
}
type InfoType = {
	fileList: Array<FileType>,
}

class EditDetails extends React.Component<Props, LocalState> {
	state = {
		iconList: [],
		logoList: [],
		iconError: '',
		logoError: '',
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			iconList: [],
			logoList: [],
			iconError: '',
			logoError: '',
		});
	}

	beforeUploadIcon = (file: FileType) => {
		if (file.size > 1300000) {
			this.setState({
				iconError: 'Selected image is too large, please choose a different one',
			});
			return false;
		}
		if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
			this.setState({
				iconError: 'Upload Icon in a PNG, JPG or GIF format',
			});
			return false;
		}
		this.setState({
			iconError: '',
		});
		return true;
	};

	beforeUploadLogo = (file: FileType) => {
		if (file.size > 1300000) {
			this.setState({
				logoError: 'Selected image is too large, please choose a different one',
			});
			return false;
		}
		if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
			this.setState({
				logoError: 'Upload Icon in a PNG, JPG or GIF format',
			});
			return false;
		}
		this.setState({
			logoError: '',
		});
		return true;
	};

	handleIconChange = (info: InfoType) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		this.setState({
			iconList: fileList,
		});
	};

	handleLogoChange = (info: InfoType) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		this.setState({
			logoList: fileList,
		});
	};

	handleRemoveIcon = () => {
		this.setState({
			iconError: '',
		});
	}

	handleRemoveLogo = () => {
		this.setState({
			logoError: '',
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (!err) {
				const data = {
					...values,
					icon: form.isFieldTouched('icon') && (values.icon
						? values.icon.file.response.url
						: this.props.data.applicationDetail.icon),
					logo: form.isFieldTouched('logo') && (values.logo
						? values.logo.file.response.url
						: this.props.data.applicationDetail.logo),
				};
				submitChanges(data);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			data,
			icon,
			logo,
		} = this.props;
		const {
			iconList, logoList, iconError, logoError,
		} = this.state;
		const FormItem = Form.Item;
		const formLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const oAuthData = localStorage.getItem('@Auth:auth');
		const accessToken: ?string = oAuthData ? JSON.parse(oAuthData).accessToken : undefined;
		const nameData = localStorage.getItem('@Auth:appName');
		const uploadProps = {
			name: 'file',
			action: process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1/files` : '',
			data: { type: 'image' },
			headers: {
				Authorization: accessToken ? `Bearer ${accessToken}` : '',
				'X-Requested-With': null,
				'X-Tradecore-App-Name': nameData,
			},
			listType: 'picture',
			accept: '.jpg, .jpeg, .png',
		};
		const companyFields = [
			{ name: 'companyName', type: '' },
			{ name: 'phone', type: '' },
			{ name: 'faxNumber', type: '' },
			{ name: 'contactEmail', type: 'email' },
			{ name: 'website', type: '' },
			{ name: 'vatNumber', type: '' },
			{ name: 'registrationNumber', type: '' },
		];
		const addressFields = [
			{ name: 'streetName', type: '' },
			{ name: 'streetNumber', type: '' },
			{ name: 'zipCode', type: '' },
			{ name: 'state', type: '' },
			{ name: 'country', type: '' },
			{ name: 'addressLine2', type: '' },
		];
		const { labelCol, wrapperCol } = formLayout;
		return (
			<Form onSubmit={this.submit} layout="horizontal" labelalign="left" className="details-form">
				<h4 className="subtitle">Application Details</h4>
				<FormItem label="APP NAME" labelCol={labelCol} wrapperCol={wrapperCol}>
					{getFieldDecorator('name', {
						initialValue: data.name,
					})(
						<Input
							name="name"
							placeholder="Define App Name"
						/>,
					)}
				</FormItem>
				<div className="upload">
					<h4>App Icon</h4>
					<p>
						Appears on Application List in your Panel. It&apos;s recommended to use
						Application&apos;s Launch Icon.
					</p>
					<p>
						Upload Icon in a PNG, JPG or GIF format, with maximum size of 4MB.
					</p>
					<div className="upload-img">
						<FormItem>
							{getFieldDecorator('icon', {
								valuePropName: 'icon',
							})(
								<Upload
									{...uploadProps}
									fileList={iconList}
									onChange={this.handleIconChange}
									beforeUpload={this.beforeUploadIcon}
									className={iconError ? 'upload-error' : ''}
									onRemove={this.handleRemoveIcon}
								>
									<CPButton
										ghost
										icon="Upload"
										text="upload new icon"
									/>
								</Upload>,
							)}
						</FormItem>
						{icon && <img src={icon} alt="icon" className="icon" />}
					</div>
					{iconError && (
						<div className="upload-error">
							{`${iconError} or previous selection will remain.`}
						</div>
					)}
					<h4>App Logo</h4>
					<p>
						Appears on Statements, Terms &amp; Conditions documents and E-mails you send
						to your client. Use full version of your Application Logo.
					</p>
					<p>
						Upload Logo in a PNG, JPG or GIF format, with maximum size of 4MB.
					</p>
					<div className="upload-img">
						<FormItem>
							{getFieldDecorator('logo', {
								valuePropName: 'logo',
							})(
								<Upload
									{...uploadProps}
									fileList={logoList}
									onChange={this.handleLogoChange}
									beforeUpload={this.beforeUploadLogo}
									className={logoError ? 'upload-error' : ''}
									onRemove={this.handleRemoveLogo}
								>
									<CPButton
										ghost
										icon="Upload"
										text="upload new logo"
									/>
								</Upload>,
							)}
						</FormItem>
						{logo && <img src={logo} alt="logo" className="cover" />}
					</div>
					{logoError && (
						<div className="upload-error">
							{`${logoError} or previous selection will remain.`}
						</div>
					)}
				</div>
				<h4 className="subtitle">Company Details</h4>
				{companyFields.map((field) => (
					<FormItem
						label={ActivityHelpers.transformToReadable(field.name)}
						labelCol={labelCol}
						wrapperCol={wrapperCol}
						key={field.name}
					>
						{getFieldDecorator(field.name, {
							initialValue: data.applicationDetail?.[field.name],
							rules: [
								{
									transform: (value) => value.trim(),
									type: `${field.type}`,
									message: `The input is not valid ${field.type}!`,
								},
							],
						})(
							<Input
								name={field.name}
								placeholder={ActivityHelpers.transformToReadable(`Define ${field.name}`)}
							/>,
						)}
					</FormItem>
				))}
				<div className="upload">
					<h4 className="subtitle">Address</h4>
					{addressFields.map((field) => (
						<FormItem
							label={ActivityHelpers.transformToReadable(field.name)}
							labelCol={labelCol}
							wrapperCol={wrapperCol}
							key={field.name}
						>
							{getFieldDecorator(field.name, {
								initialValue: data?.applicationDetail?.[field.name],
							})(
								<Input
									name={field.name}
									placeholder={ActivityHelpers.transformToReadable(`Define ${field.name}`)}
								/>,
							)}
						</FormItem>
					))}
				</div>
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched() || !!iconError || !!logoError} text="save changes" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const DetailsForm = Form.create()(EditDetails);

export default DetailsForm;
