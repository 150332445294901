// @flow

import React from 'react';

type IconStyle = {
    width?: string,
    height?: string,
}

const EmptyWallet = ({
	width = '238px',
	height = '192px',
}: IconStyle) => (
	<svg width={width} height={height} viewBox="0 0 238 192" version="1.1">
		<title>order_empty_wallet</title>
		<desc>Created with Sketch.</desc>
		<defs>
			<filter x="-28.6%" y="-54.5%" width="157.1%" height="209.1%" filterUnits="objectBoundingBox" id="filter-1">
				<feGaussianBlur stdDeviation="4" in="SourceGraphic" />
			</filter>
		</defs>
		<g id="order_empty_wallet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-24">
				<rect id="Rectangle" x="0" y="0" width="238" height="192" />
				<g id="Group-3" transform="translate(53.000000, 50.000000)">
					<path d="M4,0 L128,0 C130.209139,-4.05812251e-16 132,1.790861 132,4 L132,88 C132,90.209139 130.209139,92 128,92 L4,92 C1.790861,92 2.705415e-16,90.209139 0,88 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z" id="Rectangle" fill="#E5E5E6" />
					<path d="M102,36 L130,36 C131.656854,36 133,37.3431458 133,39 L133,55 C133,56.6568542 131.656854,58 130,58 L102,58 C95.9248678,58 91,53.0751322 91,47 L91,47 C91,40.9248678 95.9248678,36 102,36 Z" id="Rectangle" fill="#C1C1CB" filter="url(#filter-1)" />
					<path d="M104,33 L132,33 C133.656854,33 135,34.3431458 135,36 L135,52 C135,53.6568542 133.656854,55 132,55 L104,55 C97.9248678,55 93,50.0751322 93,44 C93,37.9248678 97.9248678,33 104,33 Z" id="Rectangle" fill="#C1C1CB" />
					<circle id="Oval" fill="#FFFFFF" cx="104" cy="44" r="6" />
					<path d="M7,20.7493557 C5.06700338,20.7493557 3.5,19.1823523 3.5,17.2493557 C3.5,15.3163591 5.06700338,13.7493557 7,13.7493557 L20,13.7493557 C21.9329966,13.7493557 23.5,15.3163591 23.5,17.2493557 C23.5,19.1823523 21.9329966,20.7493557 20,20.7493557 L7,20.7493557 Z M33,20.7493557 C31.0670034,20.7493557 29.5,19.1823523 29.5,17.2493557 C29.5,15.3163591 31.0670034,13.7493557 33,13.7493557 L46,13.7493557 C47.9329966,13.7493557 49.5,15.3163591 49.5,17.2493557 C49.5,19.1823523 47.9329966,20.7493557 46,20.7493557 L33,20.7493557 Z M59,20.7493557 C57.0670034,20.7493557 55.5,19.1823523 55.5,17.2493557 C55.5,15.3163591 57.0670034,13.7493557 59,13.7493557 L72,13.7493557 C73.9329966,13.7493557 75.5,15.3163591 75.5,17.2493557 C75.5,19.1823523 73.9329966,20.7493557 72,20.7493557 L59,20.7493557 Z M85,20.7493557 C83.0670034,20.7493557 81.5,19.1823523 81.5,17.2493557 C81.5,15.3163591 83.0670034,13.7493557 85,13.7493557 L98,13.7493557 C99.9329966,13.7493557 101.5,15.3163591 101.5,17.2493557 C101.5,19.1823523 99.9329966,20.7493557 98,20.7493557 L85,20.7493557 Z M111,20.7493557 C109.067003,20.7493557 107.5,19.1823523 107.5,17.2493557 C107.5,15.3163591 109.067003,13.7493557 111,13.7493557 L124,13.7493557 C125.932997,13.7493557 127.5,15.3163591 127.5,17.2493557 C127.5,19.1823523 125.932997,20.7493557 124,20.7493557 L111,20.7493557 Z" id="Path-2" fill="#D1D1D6" fillRule="nonzero" />
					<path d="M7,78.25 C5.06700338,78.25 3.5,76.6829966 3.5,74.75 C3.5,72.8170034 5.06700338,71.25 7,71.25 L20,71.25 C21.9329966,71.25 23.5,72.8170034 23.5,74.75 C23.5,76.6829966 21.9329966,78.25 20,78.25 L7,78.25 Z M33,78.25 C31.0670034,78.25 29.5,76.6829966 29.5,74.75 C29.5,72.8170034 31.0670034,71.25 33,71.25 L46,71.25 C47.9329966,71.25 49.5,72.8170034 49.5,74.75 C49.5,76.6829966 47.9329966,78.25 46,78.25 L33,78.25 Z M59,78.25 C57.0670034,78.25 55.5,76.6829966 55.5,74.75 C55.5,72.8170034 57.0670034,71.25 59,71.25 L72,71.25 C73.9329966,71.25 75.5,72.8170034 75.5,74.75 C75.5,76.6829966 73.9329966,78.25 72,78.25 L59,78.25 Z M85,78.25 C83.0670034,78.25 81.5,76.6829966 81.5,74.75 C81.5,72.8170034 83.0670034,71.25 85,71.25 L98,71.25 C99.9329966,71.25 101.5,72.8170034 101.5,74.75 C101.5,76.6829966 99.9329966,78.25 98,78.25 L85,78.25 Z M111,78.25 C109.067003,78.25 107.5,76.6829966 107.5,74.75 C107.5,72.8170034 109.067003,71.25 111,71.25 L124,71.25 C125.932997,71.25 127.5,72.8170034 127.5,74.75 C127.5,76.6829966 125.932997,78.25 124,78.25 L111,78.25 Z" id="Path-2" fill="#D1D1D6" fillRule="nonzero" />
				</g>
			</g>
		</g>
	</svg>
);

EmptyWallet.defaultProps = {
	width: '238px',
	height: '192px',
};

export default EmptyWallet;
