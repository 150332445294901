// @flow

import React from 'react';
import moment from 'moment';
//= import components
import Card from '../../../components/UiElements/Card';
import Table from '../../../components/UiElements/Table';
import Tag from '../../../components/UiElements/Tag';
//= import types
import type { RecurringOrder, Order } from '../../../modules/reducers/OrderReducer';

type Props = {
	recurringOrder: RecurringOrder,
	applicationId: string,
}

const RecurringOrdersCard = (props: Props) => {
	const { recurringOrder, applicationId } = props;
	const ordersColumns = [
		{
			title: 'TIME CREATED',
			dataIndex: 'orderCommand.createdAt',
			key: 'createdAt',
			width: 144,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'DESCRIPTION',
			dataIndex: 'type',
			key: 'type',
			width: 256,
			render: (text: string, record: Order) => (
				<a
					href={`/application/${applicationId}/orders/${record.id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="flex">
						<div className={`${record.side} box`} />
						<div className="capitalize">
							{`${text} ${record.side} ${record.symbolId}`}
						</div>
					</div>
				</a>
			),
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			key: 'status',
			width: 112,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
	];
	return (
		<>
			{recurringOrder.orders && recurringOrder.orders.length > 1 && (
				<Card bordered={false}>
					<h2>Recurring Orders</h2>
					<Table
						rowKey={(record: Order) => record.id}
						columns={ordersColumns}
						dataSource={recurringOrder.orders}
						locale={{ emptyText: 'No orders!' }}
						pagination={false}
					/>
				</Card>
			)}
		</>
	);
};


export default RecurringOrdersCard;
