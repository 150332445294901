// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Table from '../../../components/UiElements/Table';
import Tag from '../../../components/UiElements/Tag';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import actions
import {
	setActiveAccount,
} from '../../../modules/actions/AccountsActions';
//= import types
import type { Account } from '../../../modules/reducers/AccountsReducer';

type Props = {
	handleCancelClick: () => void,
	setActiveAccount: (string) => void,
	userAccounts: Array<Account>,
}

function AllAccounts(props: Props) {
	const {
		handleCancelClick,
		userAccounts,
		setActiveAccount: setActiveAccountAction,
	} = props;

	const onAccountSelect = (key) => {
		setActiveAccountAction(key);
		handleCancelClick();
	};

	const columns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			width: 256,
			render: (text: string, record: Account) => (
				<div className="flex">
					<span className="primary-tag">
						{text}
					</span>
					{record.primary && <Tag status="primary" />}
				</div>
			),
		},
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 336,
			render: (text: string) => (
				<CopyComponent text={text} content={text} />
			),
		},
	];
	const rowSelection = {
		onChange: onAccountSelect,
		type: 'radio',
	};

	return (

		<div>
			<Table
				rowSelection={rowSelection}
				rowKey={(record) => record.id}
				columns={columns}
				dataSource={userAccounts}
				pagination={{
					hideOnSinglePage: true,
					pageSize: 50,
					total: userAccounts.length,
					position: 'bottom',
					size: 'small',
				}}
				scroll={{ y: (window.innerHeight - 512) }}
			/>
			<div className="form-buttons">
				<CPButton ghost action={handleCancelClick} text="cancel" />
			</div>
		</div>
	);
}

const mapDispatchToProps = {
	setActiveAccount,
};

export default connect(null, mapDispatchToProps)(AllAccounts);
