// @flow

import {
	GET_PROGRAMS,
	GET_PROGRAM,
	GET_DESIGN,
	GET_AUTHORIZATION_CONTROL,
	GET_LIMIT_CONTROL,
	GET_MERCHANT_SCOPE,
	GET_CURRENCIES,
	ADD_MERCHANT_SCOPE,
	ADD_AUTHORIZATION_CONTROL,
	ADD_LIMIT_CONTROL,
	UPDATE_MERCHANT_SCOPE,
	UPDATE_AUTHORIZATION_CONTROL,
	UPDATE_LIMIT_CONTROL,
	CREATE_PRODUCT,
	UPDATE_PRODUCT,
	GET_PRODUCTS,
	GET_PRODUCT,
	GET_CARDS,
	GET_CARD,
	ADD_CARD,
	GET_ACCOUNT_CARDS,
	GET_ACCOUNT_CARD_PAYMENTS,
	GET_ACCOUNT_CARD_PAYMENT,
	GET_CARD_PAYMENTS,
	GET_CARD_PAYMENT,
	ACTIVATE_CARD,
	CHANGE_CARD_STATUS,
	GET_ENTRY_MODE,
	GET_TRANSACTION_TYPES,
	GET_MERCHANT_CATEGORY,
	ISSUE_CARD,
	SIMULATE_CARD_PAYMENT,
	GET_CARD_SPEND_CONTROLS,
} from '../const/types';

export function getPrograms(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PROGRAMS,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/programInstances`,
			},
		},
	});
}

export function getProgram(applicationId: string, programId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PROGRAM,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/programInstances/${programId}`,
			},
		},
	});
}

export function getCardDesign(applicationId: string, programInstanceId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_DESIGN,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/programInstances/${programInstanceId}/cardDesigns`,
			},
		},
	});
}

export function getAuthorizationControls(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_AUTHORIZATION_CONTROL,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/authorizationControls`,
			},
		},
	});
}

export function getLimitControls(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_LIMIT_CONTROL,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/limitControls`,
			},
		},
	});
}

export function getMerchantScopes(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_MERCHANT_SCOPE,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/merchantScopes`,
			},
		},
	});
}

export function addMerchantScope(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_MERCHANT_SCOPE,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `applications/${applicationId}/merchantScopes`,
				data,
			},
		},
	});
}

export function addAuthorizationControl(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_AUTHORIZATION_CONTROL,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `applications/${applicationId}/authorizationControls`,
				data,
			},
		},
	});
}

export function addLimitControl(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_LIMIT_CONTROL,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `applications/${applicationId}/limitControls`,
				data,
			},
		},
	});
}

export function updateMerchantScope(applicationId: string, merchantScopeId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_MERCHANT_SCOPE,
		payload: {
			client: 'card',
			request: {
				method: 'PUT',
				url: `applications/${applicationId}/merchantScopes/${merchantScopeId}`,
				data,
			},
		},
	});
}

export function updateAuthorizationControl(appId: string, authorizationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_AUTHORIZATION_CONTROL,
		payload: {
			client: 'card',
			request: {
				method: 'PUT',
				url: `applications/${appId}/authorizationControls/${authorizationId}`,
				data,
			},
		},
	});
}

export function updateLimitControl(applicationId: string, limitControlId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_LIMIT_CONTROL,
		payload: {
			client: 'card',
			request: {
				method: 'PUT',
				url: `applications/${applicationId}/limitControls/${limitControlId}`,
				data,
			},
		},
	});
}

export function createProduct(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: CREATE_PRODUCT,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/products`,
				data,
			},
		},
	});
}

export function updateProduct(applicationId: string, productId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_PRODUCT,
		payload: {
			client: 'card',
			request: {
				method: 'PUT',
				url: `/applications/${applicationId}/products/${productId}`,
				data,
			},
		},
	});
}

export function getProducts(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PRODUCTS,
		payload: {
			client: 'card',
			request: {
				url: `/applications/${applicationId}/products`,
			},
		},
	});
}

export function getProduct(applicationId: string, productId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_PRODUCT,
		payload: {
			client: 'card',
			request: {
				url: `/applications/${applicationId}/products/${productId}`,
			},
		},
	});
}

export function getCards(applicationId: string, page: number = 1, pageSize: number = 50, queryString: string = '') {
	return (dispatch: Function): void => dispatch({
		type: GET_CARDS,
		payload: {
			client: 'card',
			request: {
				url: `/applications/${applicationId}/cards?${queryString ? `filters=${queryString}` : ''}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': page,
					'pagination-type': 'numbered-pages',
				},
			},
		},
	});
}

export function getCard(applicationId: string, cardId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CARD,
		payload: {
			client: 'card',
			request: {
				url: `/applications/${applicationId}/cards/${cardId}`,
			},
		},
	});
}

export function addCard(userId: string, accountId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_CARD,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `user/${userId}/account/${accountId}/cards`,
				data,
			},
		},
	});
}

export function getAccountCards(accountId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_CARDS,
		payload: {
			client: 'card',
			request: {
				url: `accounts/${accountId}/cards`,
			},
		},
	});
}

export function getAccountCardPayments(accountId: string, cardId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_CARD_PAYMENTS,
		payload: {
			client: 'card',
			request: {
				url: `accounts/${accountId}/cards/${cardId}/cardPayments`,
			},
		},
	});
}

export function getAccountCardPayment(accountId: string, cardId: string, paymentId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_CARD_PAYMENT,
		payload: {
			client: 'card',
			request: {
				url: `accounts/${accountId}/cards/${cardId}/cardPayments/${paymentId}`,
			},
		},
	});
}

export function getCardPayments(
	applicationId: string,
	paginationLimit: number = 50,
	paginationOffset: number = 1,
	queryString: string = '',
) {
	return (dispatch: Function): void => dispatch({
		type: GET_CARD_PAYMENTS,
		payload: {
			client: 'card',
			request: {
				url: `/applications/${applicationId}/cardPayments?${queryString ? `filters=${queryString}` : ''}`,
				headers: {
					'pagination-type': 'numbered-pages',
					'pagination-limit': paginationLimit,
					'pagination-offset': paginationOffset,
				},
			},
		},
	});
}

export function getCardPayment(applicationId: string, paymentId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CARD_PAYMENT,
		payload: {
			client: 'card',
			request: {
				url: `/applications/${applicationId}/cardPayments/${paymentId}`,
			},
		},
	});
}

export function getEntryMode() {
	return (dispatch: Function): void => dispatch({
		type: GET_ENTRY_MODE,
		payload: {
			client: 'card',
			request: {
				url: 'entryModes',
			},
		},
	});
}
export function getTransactionTypes() {
	return (dispatch: Function): void => dispatch({
		type: GET_TRANSACTION_TYPES,
		payload: {
			client: 'card',
			request: {
				url: '/transactionTypes',
			},
		},
	});
}
export function getMerchantCategoryCodes() {
	return (dispatch: Function): void => dispatch({
		type: GET_MERCHANT_CATEGORY,
		payload: {
			client: 'card',
			request: {
				url: '/merchantCategoryCodes',
			},
		},
	});
}

export function changeStatus(applicationId: string, cardId: string, data: {status: string}) {
	return (dispatch: Function): void => dispatch({
		type: CHANGE_CARD_STATUS,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `applications/${applicationId}/cards/${cardId}/changeStatus`,
				data,
			},
		},
	});
}

export function activateCard(accountId: string, cardId: string, data: {cardInstanceId: string}) {
	return (dispatch: Function): void => dispatch({
		type: ACTIVATE_CARD,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `accounts/${accountId}/cards/${cardId}/activateCard`,
				data,
			},
		},
	});
}

export function issueCard(userId: string, accountId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ISSUE_CARD,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: `users/${userId}/accounts/${accountId}/cards`,
				data,
			},
		},
	});
}

export function simulateCardPayment(data: Object) {
	return (dispatch: Function): void => dispatch({
		type: SIMULATE_CARD_PAYMENT,
		payload: {
			client: 'card',
			request: {
				method: 'POST',
				url: 'transactionAuthorization/simulator',
				data,
			},
		},
	});
}

export function getCurrencies() {
	return (dispatch: Function): void => dispatch({
		type: GET_CURRENCIES,
		payload: {
			client: 'card',
			request: {
				url: 'currencies',
			},
		},
	});
}

export function getCardSpendControls(applicationId: string, cardId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CARD_SPEND_CONTROLS,
		payload: {
			client: 'card',
			request: {
				url: `applications/${applicationId}/cards/${cardId}/spendControls`,
			},
		},
	});
}
