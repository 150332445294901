/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Tag, Select } from 'antd';
import styles from '../assets/user.module.scss';
import {
	fetchUserTags,
	getUserTags,
	removeUserTag,
	addTagToUser,
} from '../../../modules/actions/UserTagsActions';

type Props = {
	fetchUserTags: (string) => Promise,
	getUserTags: (string) => Promise,
	removeUserTag: (string) => Promise,
	addTagToUser: (string) => Promise,
	userId: string,
	applicationId: string,
	systemTags: Array,
	singleUserTags: Array,

}

function ManageUserTags(props: Props) {
	const {
		fetchUserTags: fetchUserTagsAction,
		getUserTags: getUserTagsAction,
		removeUserTag: removeUserTagAction,
		addTagToUser: addTagToUserAction,
		userId,
		applicationId,
		systemTags,
		singleUserTags: userTags,
	} = props;

	const [fetchMyTags, setFetchMyTags] = useState(false);

	const fetchAllTags = useCallback(async () => {
		await fetchUserTagsAction(applicationId);
		await getUserTagsAction(userId, applicationId);
	}, [fetchUserTagsAction, getUserTagsAction, userId, applicationId]);

	useEffect(() => {
		fetchAllTags();
	}, [fetchAllTags]);

	useEffect(() => {
		if (fetchMyTags) {
			fetchAllTags();
		}
		setFetchMyTags(false);
	}, [fetchMyTags, fetchAllTags]);

	const { Option } = Select;

	const filterFunc = (arr1, arr2) => arr1.filter(
		(el) => !arr2.find((element) => element.id === el.id),
	);

	const filteredTags = useMemo(() => filterFunc(systemTags, userTags), [systemTags, userTags]);

	const addTag = async (_: string, option: Object) => {
		const tagId = option.title;
		await addTagToUserAction(userId, tagId, applicationId);
		setFetchMyTags(true);
	};

	const handleClose = async (tagId: string) => {
		await removeUserTagAction(userId, applicationId, tagId);
		setFetchMyTags(true);
	};

	return (
		<div className={styles.userTags}>
			<Select
				className={styles.select}
				showSearch
				autoClearSearchValue
				showArrow={false}
				dropdownClassName={styles.option}
				value="Add User Tag"
				onSelect={(_, option) => addTag(_, option)}
			>
				{
					filteredTags.map((item: string) => (
						<Option
							className={styles.option}
							key={item.value}
							value={item.value}
							title={item.id}
						>{item.value}
						</Option>
					))
				}
			</Select>
			<div className={styles.tagWrapper}>
				{userTags ? userTags.map((tag: Array<Object>) => (
					!!tag
					&& (
						<Tag
							className={styles.tag}
							key={tag.id}
							closable
							color="magenta"
							onClose={() => handleClose(tag.id)}
						>
							{tag.value}
						</Tag>
					)
				)) : null}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	systemTags: state.tags.systemTags,
	singleUserTags: state.tags.userTags,
});

const mapDispatchToProps = {
	fetchUserTags,
	addTagToUser,
	removeUserTag,
	getUserTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserTags);
