// @flow

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//= import components
import Spin from '../../../components/UiElements/Spin';
import Filter from '../../../components/UiElements/Filter';
import CPButton from '../../../components/UiElements/Button';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import methods
import { setCurrentPage } from '../../../modules/actions/UiActions';
import { getAccountStockSplits } from '../../../modules/actions/StockSplitAction';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { AccountStockSplit } from '../../../modules/reducers/StockSplitReducer';

type StockSplitResponse = {
	payload: {
		data: Array<AccountStockSplit>,
		headers: {
			'total-count': number,
		},
	}
}

type Props = {
	accountStockSplits: Array<AccountStockSplit>,
	accountStockSplitsTotal: number,
	isFetchingAccountStockSplits: boolean,
	currentPage: number,
	accountStockSplitsFilters: {[string]: string},
	setCurrentPage: (string, number) => void,
	getAccountStockSplits: (string, number, number, Object) => Promise<StockSplitResponse>;
	applicationId: string,
	accountId: string,
	userId: string,
}

const StockSplitsTab = (props: Props) => {
	const {
		accountStockSplits,
		accountStockSplitsTotal,
		isFetchingAccountStockSplits,
		currentPage,
		accountStockSplitsFilters,
		applicationId,
		accountId,
		userId,
		setCurrentPage: setCurrentPageAction,
		getAccountStockSplits: getAccountStockSplitsAction,
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [date, setDate] = useState(false);

	useEffect(() => {
		const idsFilter = accountStockSplitsFilters.instrumentId?.[0]?.replace(/\s+/g, '').toUpperCase();
		const filters = { ...accountStockSplitsFilters, instrumentId: idsFilter };
		getAccountStockSplitsAction(
			accountId,
			currentPage,
			StatusHelpers.paginationLimit,
			queryString.stringify(filters, { arrayFormat: 'comma' }),
		);
	}, [
		getAccountStockSplitsAction,
		accountId,
		currentPage,
		accountStockSplitsFilters,
	]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('accountStockSplitPage', page);
	};

	const columns = [
		{
			title: 'date & time',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
		{
			title: 'symbol',
			dataIndex: 'symbolId',
			key: 'symbolId',
			width: 176,
			render: (text: string, record: AccountStockSplit) => (
				<Link
					to={`/application/${applicationId}/clients/${userId}/stock_splits/${record.id}?accountId=${accountId}`}
				>
					{text}
				</Link>
			),
		},
		{
			title: 'split rate',
			dataIndex: 'splitRate',
			key: 'splitRate',
			width: 176,
		},
		{
			title: 'split side',
			dataIndex: 'splitSide',
			key: 'splitSide',
			width: 176,
			render: (text: string) => (
				<div className="capitalize">{text}</div>
			),
		},
		{
			title: 'record date',
			dataIndex: 'recordDate',
			key: 'recordDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
		{
			title: 'ex-date',
			dataIndex: 'exDate',
			key: 'exDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
		{
			title: 'base before',
			dataIndex: 'executionSummary.balanceBefore',
			key: 'balanceBefore',
			width: 176,
			render: (text: string, record: AccountStockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'base after',
			dataIndex: 'executionSummary.balanceAfter',
			key: 'balanceAfter',
			width: 176,
			render: (text: string, record: AccountStockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'remainder',
			dataIndex: 'executionSummary.remainder.quantity',
			key: 'remainderQuantity',
			width: 176,
			render: (text: string, record: AccountStockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'remainder payout',
			dataIndex: 'executionSummary.remainder.payoutAmount',
			key: 'remainderPayout',
			width: 176,
			render: (text: string, record: AccountStockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record?.executionSummary?.remainder?.payoutWalletInstrument,
					)}
				</div>
			),
		},
	];

	return (
		<div>
			<div className="logs">
				<CPButton
					action={() => { setFilterVisible(true); setDate(true); }}
					icon="CalendarDate"
					text="date"
				/>
				<CPButton
					action={() => setFilterVisible(true)}
					text="filter"
					icon="Funnel"
				/>
			</div>
			<Filter
				fields={{
					symbolId: 'input',
					dateRange: 'time',
				}}
				date={date}
				visible={filterVisible}
				place="accountStockSplits"
				position={432}
				closeFunction={() => { setFilterVisible(false); setDate(false); }}
			/>
			{!isFetchingAccountStockSplits
				? (
					<CustomTable
						columns={columns}
						data={accountStockSplits}
						total={accountStockSplitsTotal}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={208}
						place="accountStockSplits"
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingAccountStockSplits} position="spin-relative" />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	accountStockSplits: state.stockSplit.accountStockSplits,
	isFetchingAccountStockSplits: state.stockSplit.isFetchingAccountStockSplits,
	accountStockSplitsTotal: state.stockSplit.accountStockSplitsTotal,
	currentPage: state.ui.accountStockSplitsPage,
	accountStockSplitsFilters: state.ui.accountStockSplitsFilters,
});

const mapDispatchToProps = {
	setCurrentPage,
	getAccountStockSplits,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockSplitsTab);
