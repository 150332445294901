// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CardList from '../../Cards/components/CardList';
//= import actions
import { getAccountCards } from '../../../modules/actions/CardAction';
//= import types
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	applicationId: string,
	accountId: string,
	getAccountCards: (string) => void,
	cards: Array<Object>,
	isFetchingCards: boolean,
}

function CardsTab(props: Props) {
	const {
		applicationId,
		accountId,
		getAccountCards: getAccountCardsAction,
		cards,
		isFetchingCards,
	} = props;

	useEffect(() => {
		getAccountCardsAction(accountId);
	}, [accountId, getAccountCardsAction]);

	return (
		<>
			{!isFetchingCards
				? (
					<CardList
						applicationId={applicationId}
						cards={cards}
						isFetchingCards={isFetchingCards}
						place="userCards"
					/>
				) : <Spin spinning={isFetchingCards} position="spin-relative" />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	cards: state.cards.accountCards,
	isFetchingCards: state.cards.isFetchingAccountCards,
});
const mapDispatchToProps = {
	getAccountCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsTab);
