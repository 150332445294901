// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
//= import components
import Tabs from '../../components/UiElements/Tabs';
import Switch from '../../components/UiElements/Switch';
import Tag from '../../components/UiElements/Tag';
import ValueCard from '../../components/UiElements/CustomCards/ValueCard';
import WalletTab from './components/WalletsTab';
import PaymentsTab from './components/PaymentsTabs';
import OrdersTab from './components/OrdersTab';
import AccountMarginCard from './components/AccountMarginCard';
import MarginTab from './components/MarginTab';
import PayoutsTab from './components/PayoutsTab';
import CardsTab from './components/CardsTab';
import BankTab from './components/BankTab';
import PositionsTab from './components/PositionsTab';
import StockSplitsTab from './components/StockSplitsTab';
//= import methods
import { setActiveTab } from '../../modules/actions/UiActions';
//= import selectors
import { accountMarginSelector } from '../../modules/selectors/MarginSelector';
//= import helpers
import PriceHelpers from '../../lib/helpers/priceHelpers';
import Numbers from '../../lib/helpers/numbersHelpers';
//= import types
import type { State } from '../../modules/types/FlowTypes';
import type { Account, Statistics, Statistic } from '../../modules/reducers/AccountsReducer';
import type { Wallet } from '../../modules/reducers/WalletReducer';
import type { Provider } from '../../modules/reducers/ProviderReducer';
import type { AccountMarginWithCurrency } from '../../modules/selectors/MarginSelector';
import type { Rates } from '../../modules/reducers/StaticReducer';
import CopyComponent from '../../components/UiElements/CopyComponent';

type Props = {
	applicationId: string,
	account: Account,
	wallets: Array<Wallet>,
	isFetchingWallets: boolean,
	rates: Rates,
	reportingCurrency: string,
	providers: Array<Provider>,
	accountStatistics: Statistics,
	accountMargin: AccountMarginWithCurrency,
	activeTab: string,
	setActiveTab: (string, string) => void,
	isFetchingRates: boolean,
	userReportingCurrency: string,
	history: {
		push: (string) => void,
	}
}

const AccountsPage = (props: Props) => {
	const changeActiveTab = (activeKey: string) => {
		props.setActiveTab('accountTab', activeKey);
	};

	const {
		applicationId,
		account,
		wallets,
		isFetchingWallets,
		accountMargin,
		activeTab,
		rates,
		userReportingCurrency,
		reportingCurrency,
		providers,
		isFetchingRates,
		accountStatistics
	} = props;

	const [displayInCurrency, setDisplayInCurrency] = useState(false);

	const data = accountStatistics.statistics
		? accountStatistics.statistics.map((el: Statistic) => ({
			instrumentId: el.instrumentId,
			netDeposits: el.walletNet.netDeposits,
			totalDeposits: el.walletNet.totalDeposits,
			totalWithdrawals: el.walletNet.totalWithdrawals,
		}))
		: [];
	return (
		<div>
			<div className="account-title">
				<h2>{account ? account.name : 'No Account'}</h2>
				{account.primary && <Tag status="primary" />}
				<CopyComponent
					text={account.id}
					content={account.id}
				/>
				<div>
					<span>{displayInCurrency ? 'Hide values in reporting currency' : 'Show values in reporting currency'}</span>
					<Switch
						className="account-title-switch"
						defaultChecked={displayInCurrency}
						onChange={(value: boolean) => setDisplayInCurrency(value)}
					/>
				</div>
			</div>
			<div className="value-cards">
				<ValueCard
					additionalValue={PriceHelpers.getAccountValue(
						'available',
						wallets,
						reportingCurrency,
						rates,
					)}
					value={PriceHelpers.getAccountValue(
						'available',
						wallets,
						userReportingCurrency,
						rates,
					)}
					title="Available Balance"
					styles={{ backgroundColor: '#6EBA1A' }}
					loading={isFetchingWallets}
				/>
				<ValueCard
					additionalValue={PriceHelpers.getAccountValue(
						'reserved',
						wallets,
						reportingCurrency,
						rates,
					)}
					value={PriceHelpers.getAccountValue(
						'reserved',
						wallets,
						userReportingCurrency,
						rates,
					)}
					title="Reserved Balance"
					styles={{ backgroundColor: '#FAAD14' }}
					loading={isFetchingWallets}
				/>
				<ValueCard
					additionalValue={PriceHelpers.getAccountValue(
						'total',
						wallets,
						reportingCurrency,
						rates,
					)}
					value={PriceHelpers.getAccountValue(
						'total',
						wallets,
						userReportingCurrency,
						rates,
					)}
					title="Total Balance"
					styles={{ backgroundColor: '#7266BA' }}
					loading={isFetchingWallets}
				/>
				<AccountMarginCard
					value1={{
						name: 'Loan Limit',
						type: 'loan',
						value: PriceHelpers.formatAmount(
							accountMargin.loanLimit,
							accountMargin.accountCurrency || '',
							Numbers.sign(accountMargin.loanLimit || '0'),
						),
					}}
					value2={{
						name: 'Borrowed',
						type: 'borrowed',
						value: PriceHelpers.formatAmount(
							accountMargin.borrowedAmount,
							accountMargin.accountCurrency || '',
							Numbers.sign(accountMargin.borrowedAmount || '0'),
						),
					}}
					marginUtilization={accountMargin.marginUtilization || '0'}
					styles={{ backgroundColor: '#E64B79' }}
				/>
				<AccountMarginCard
					value1={{
						name: 'Total Deposit',
						type: 'deposit',
						value: PriceHelpers.getAccountValue(
							'totalDeposits',
							data,
							reportingCurrency,
							rates,
						),
					}}
					value2={{
						name: 'Total Withdrawal',
						type: 'withdrawal',
						value: PriceHelpers.getAccountValue(
							'totalWithdrawals',
							data,
							reportingCurrency,
							rates,
						),
					}}
					marginUtilization=""
					styles={{ backgroundColor: '#999999' }}
					loading={!accountStatistics.statistics}
				/>
			</div>
			<div>
				<Tabs
					type="card"
					className="tabs"
					activeKey={activeTab}
					onChange={changeActiveTab}
				>
					<Tabs.TabPane
						tab="WALLETS"
						key="user_wallets"
					>
						<WalletTab
							wallets={wallets}
							isFetchingWallets={isFetchingWallets}
							accountCurrency={userReportingCurrency}
							rates={rates}
							applicationId={applicationId}
							isFetchingRates={isFetchingRates}
							displayInCurrency={displayInCurrency}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="ORDERS"
						key="user_orders"
					>
						<OrdersTab
							applicationId={applicationId}
							accountId={account.id}
							providers={providers}
						/>

					</Tabs.TabPane>
					<Tabs.TabPane
						tab="POSITIONS"
						key="positions"
					>
						<PositionsTab
							applicationId={applicationId}
							accountId={account.id}
						/>

					</Tabs.TabPane>
					<Tabs.TabPane
						tab="PAYMENTS"
						key="user_payments"
					>
						<PaymentsTab
							wallets={wallets}
							accountId={account.id}
							applicationId={applicationId}
							providers={providers}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="MARGIN"
						key="margin"
					>
						<MarginTab
							accountMargin={accountMargin}
							wallets={wallets}
							activeAccount={account}
							applicationId={applicationId}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="CARDS"
						key="user_cards"
					>
						<CardsTab
							accountId={account.id}
							applicationId={applicationId}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="BANK ACCOUNTS"
						key="user_bank_accounts"
					>
						<BankTab
							accountId={account.id}
							applicationId={applicationId}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="DIVIDENDS PAYOUTS"
						key="payouts"
					>
						<PayoutsTab
							accountId={account.id}
							applicationId={applicationId}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="STOCK SPLITS"
						key="stockSplits"
					>
						<StockSplitsTab
							accountId={account.id}
							applicationId={applicationId}
							userId={account.userId}
						/>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	activeTab: state.ui.accountTab,
	userReportingCurrency: state.users.userReportingCurrency,
	accountMargin: accountMarginSelector(state),
	isFetchingRates: state.static.isFetchingRates,
});

const mapDispatchToProps = {
	setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPage);
