// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import ReactJson from 'react-json-view';
//= import components
import Title from '../../../components/UiElements/Title';
import CPButton from '../../../components/UiElements/Button';
import Card from '../../../components/UiElements/Card';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Spin from '../../../components/UiElements/Spin';
import Drawer from '../../../components/UiElements/Drawer';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import OrderTimeline from './OrderTimeline';
import RecurringOrdersCard from './RecurringOrders';
import OrderTimeTable from './OrderTimeTable';
//= import methods
import {
	getOrder, cancelOrder, getRecurringOrder,
} from '../../../modules/actions/OrderActions';
import { getAccount } from '../../../modules/actions/AccountsActions';
import { getProviders } from '../../../modules/actions/ProviderActions';
//= import selectors
import { orderFormattedSelector } from '../../../modules/selectors/OrdersSelector';
//= import types
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type {
	FullOrder, OrderTransaction, RecurringOrder,
} from '../../../modules/reducers/OrderReducer';
import type { FormattedOrder, MappedWalletTransactions } from '../../../modules/selectors/OrdersSelector';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	account: Account,
	orderPayload: FullOrder,
	order: FormattedOrder,
	getOrder: (string) => Promise<Object>,
	cancelOrder: (string, string) => void,
	getRecurringOrder: (string, string) => void,
	getAccount: (string) => Promise<Object>,
	getProviders: () => Promise<Object>,
	recurringOrder: RecurringOrder,
	history: {
		goBack: () => void,
		length: number,
	},
	match: { params: { userId: string, applicationId: string, orderId: string, accountId: string } },
}

const OrderPage = (props: Props) => {
	const {
		match: {
			params: {
				applicationId, orderId,
			},
		},
		history,
		account,
		orderPayload,
		order,
		recurringOrder,
		getOrder: getOrderAction,
		cancelOrder: cancelOrderAction,
		getRecurringOrder: getRecurringOrderAction,
		getAccount: getAccountAction,
		getProviders: getProvidersAction,
	} = props;

	const [payloadData, setPayloadData] = useState({});
	const [title, setTitle] = useState('');
	const [drawer, setDrawer] = useState(false);
	const [activeItem, setActiveItem] = useState('');
	const [drawerData, setDrawerData] = useState({});
	const [drawerDataId, setDrawerDataId] = useState({});
	const [drawerDataTime, setDrawerDataTime] = useState({});
	const [dataFetched, setDataFetched] = useState(false);
	const [orderProvider, setProvider] = useState('');
	const [message, setMessage] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [cancelClicked, setCancelClicked] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		async function fetchData() {
			try {
				const orderRes = await getOrderAction(orderId);
				getAccountAction(orderRes.payload.data.accountId);

				if (orderRes.payload.data.recurrenceId) {
					getRecurringOrderAction(
						orderRes.payload.data.accountId,
						orderRes.payload.data.recurrenceId,
					);
				}
				if (orderRes.payload.data.tradingProviderId) {
					const providerRes = await getProvidersAction();
					const provider = providerRes.payload.data.find((el) => (
						el.id === orderRes.payload.data.tradingProviderId));
					setProvider(provider ? provider.displayName : '');
				}
			} catch (error) {
				setMessage(error.error.response ? error.error.response.data.message : 'Error');
			} finally { setDataFetched(true); }
		}
		fetchData();
	}, [
		getAccountAction,
		getOrderAction,
		getProvidersAction,
		getRecurringOrderAction,
		orderId,
	]);

	const wallets = groupBy(order.mappedWalletTransactions, 'instrumentId');
	const firstWallet: Array<MappedWalletTransactions> = order.wallet && order.side === 'buy'
		? orderBy(wallets[order.wallet.instrumentId], 'createdAt')
		: orderBy(wallets[order.baseInstrumentId], 'createdAt');

	const secondWallet: Array<MappedWalletTransactions> = order.wallet
	&& order.wallet.instrumentId !== order.quotingInstrumentId
		? orderBy(wallets[order.quotingInstrumentId], 'createdAt')
		: [];

	const thirdWallet: Array<MappedWalletTransactions> = order.wallet && (order.side === 'buy'
		? wallets[order.baseInstrumentId]
		: wallets[order.wallet.instrumentId]);

	const viewUser = () => {
		props.history.goBack();
	};

	const printError = (error: {[string]: Array<string>}) => {
		if (!error) {
			return '';
		}
		return error.map((el) => {
			const { field } = el;
			const msg = el.messages.map((text) => text.message).join(' ');
			return <p>{`${field}: ${msg}`}</p>;
		});
	};

	const handleOnError = (error) => {
		setCancelClicked(false);
		setButtonLoading(false);
		setWarningModal(true);
		setMessage({
			firstPart: error.error.response ? error.error.response.data?.message : 'Error',
			secondPart: printError(error.error.response?.data?.errors),
		});
	};

	const handleCancelCancel = () => {
		setWarningModal(false);
		setCancelClicked(false);
	};

	const handleCancelOrder = () => {
		setCancelClicked(true);
	};

	const submitCancelOrder = async () => {
		setButtonLoading(true);
		const data = {
			userId: orderPayload.user.id,
		};
		try {
			await cancelOrderAction(orderPayload.accountId, orderId, data);
			setButtonLoading(false);
			setCancelClicked(false);
		} catch (err) { handleOnError(err); }
	};

	const handleOpenDrawer = (place: string, id: string, drawerTitle: string, walletInstrument: string = '') => {
		if (place === 'orderPlaced') {
			setPayloadData(orderPayload.orderCommand);
			setDrawerDataId({ id: order.id });
			setDrawerDataTime({
				timeZone: 'Local Time Zone',
				createdAt: order.createdAt,
			});
			setDrawerData({
				quantity: order.commandFormatted.quantity,
				amount: order.commandFormatted.amount,
				price: order.commandFormatted.price,
				expectedCost: order.commandFormatted.expectedCost,
			});
		} else if (place === 'trades') {
			setPayloadData(...orderPayload.orderTransactions
				.filter((trade: OrderTransaction) => trade.id === id));
			setDrawerDataId({ id: order.mappedOrders[0].id });
			setDrawerDataTime({
				timeZone: 'Local Time Zone',
				createdAt: order.executedAt || order.logTime || firstWallet[0].time,
			});
			setDrawerData({
				quantity: order.mappedOrders[0].quantity,
				price: order.mappedOrders[0].price,
				expectedCost: order.mappedOrders[0].cost,
			});
		} else if (place === 'wallet') {
			const [walletPayload] = orderPayload.walletTransactions
				.filter((transaction) => transaction.id === id);
			setPayloadData(walletPayload);
			const selectedWallet = order.mappedWalletTransactions
				.filter((transaction) => transaction.id === id)[0];
			setDrawerDataId({
				id: walletPayload.id,
				wallet: `${walletInstrument} Wallet`,
			});
			setDrawerDataTime({
				timeZone: 'Local Time Zone',
				createdAt: selectedWallet.time,
				updatedAt: selectedWallet.timeUpdated,
			});
			setDrawerData({
				available: selectedWallet.availableFormatted,
				reserved: selectedWallet.reservedFormatted,
				total: selectedWallet.totalFormatted,
			});
		} else if (place === 'failed') {
			setPayloadData(orderPayload.providerLogs[0] || orderPayload.executionSummary);
			setDrawerDataId({
				id: order.id,
			});
			setDrawerDataTime({
				timeZone: 'Local Time Zone',
				createdAt: order.logTime || firstWallet[0]?.time || '',
			});
			setDrawerData({
				error: orderPayload.errorMessage || '-',
			});
		} else {
			setPayloadData(orderPayload.executionSummary);
			setDrawerDataId({
				id: order.id,
			});
			setDrawerDataTime({
				timeZone: 'Local Time Zone',
				createdAt: order.executedAt || order.logTime || '-',
			});
			setDrawerData({
				averageTransactionPrice: order.summaryFormatted.averageTransactionPrice,
				orderAmount: order.summaryFormatted.orderAmount,
				orderFee: order.summaryFormatted.orderFee,
				quantityExecuted: order.summaryFormatted.quantityExecuted,
			});
		}
		setTitle(drawerTitle);
		setDrawer(true);
		setActiveItem(id);
	};

	const onClose = () => {
		setDrawer(false);
		setActiveItem('');
	};

	const orderDetailsData = orderPayload.orderCommand && {
		orderId: (
			<CopyComponent
				text={orderPayload.id}
				content={orderPayload.id}
			/>),
		createdAt: order.createdAt,
		placedOnExchangeAt: order.placedOnExchangeAt,
		executedAt: order.executedAt,
		placedBy: (
			<a href={`/application/${applicationId}/clients/${order.user.id}`} target="_blank" rel="noopener noreferrer">
				{`${order.user.firstName || 'Name Not Set'} ${order.user.lastName || ''}`}
			</a>
		),
		tradingAccount: (
			<a href={`/application/${applicationId}/clients/${order.user.id}?accountId=${order.accountId}&tab=user_orders`} target="_blank" rel="noopener noreferrer">
				{account.name}
			</a>),
	};

	return (
		<>
			{dataFetched
				? (
					<div>
						<Title
							backAction={history.length > 1 ? viewUser : undefined}
							title={`${orderPayload.type || ''} ${orderPayload.side || ''} ${orderPayload.symbolId || ''}`}
							tags={[orderPayload.status, !!order.recurrenceId && 'recurring']}
							buttons={[{
								type: 'danger',
								disabled: !((orderPayload.type === 'limit' || orderPayload.type === 'stop')
									&& (orderPayload.status === 'new' || orderPayload.status === 'pendingNew' || orderPayload.status === 'partiallyFilled')),
								action: handleCancelOrder,
								text: 'cancel order',
							}]}
						/>
						{orderPayload.id
							? (
								<div className="page-container">
									<div className="order-details">
										<Row>
											<Col className="col-margin col-margin-100">
												<Card bordered={false}>
													<h2>Order Execution</h2>
													<div className="timeline">
														<OrderTimeline
															firstWallet={firstWallet}
															secondWallet={secondWallet}
															thirdWallet={thirdWallet}
															order={order}
															orderPayload={orderPayload}
															place="first"
															action="ACTION"
															handleOpenDrawer={handleOpenDrawer}
															activeItem={activeItem}
															provider={orderProvider}
														/>
														<OrderTimeline
															firstWallet={firstWallet}
															secondWallet={secondWallet}
															thirdWallet={thirdWallet}
															order={order}
															orderPayload={orderPayload}
															place="second"
															action={firstWallet.length ? `${firstWallet[0].instrumentId} WALLET` : ''}
															handleOpenDrawer={handleOpenDrawer}
															activeItem={activeItem}
															provider={orderProvider}
														/>
														{secondWallet && !!secondWallet.length
													&& (
														<OrderTimeline
															firstWallet={firstWallet}
															secondWallet={secondWallet}
															thirdWallet={thirdWallet}
															order={order}
															orderPayload={orderPayload}
															place="third"
															action={secondWallet ? `${secondWallet[0].instrumentId} WALLET` : ''}
															handleOpenDrawer={handleOpenDrawer}
															activeItem={activeItem}
															provider={orderProvider}
														/>
													)}
														<OrderTimeline
															firstWallet={firstWallet}
															secondWallet={secondWallet}
															thirdWallet={thirdWallet}
															order={order}
															orderPayload={orderPayload}
															place="fourth"
															action={thirdWallet ? `${thirdWallet[0].instrumentId} WALLET` : ''}
															handleOpenDrawer={handleOpenDrawer}
															activeItem={activeItem}
															provider={orderProvider}
														/>
													</div>
													<div className="capitalize summary">
														{`Order ${order.status === 'filled' ? 'Executed Successfully!' : order.status}`}
														<CPButton
															type="link"
															action={() => handleOpenDrawer('summary', orderPayload.id, `Order ${order.status}`)}
															text="view order summary"
														/>
													</div>
												</Card>
											</Col>
										</Row>
										<Row gutter={24}>
											<Col span={12}>
												<TextCard data={orderDetailsData} colNumber={1} left="left-2" />
												<OrderTimeTable
													order={orderPayload}
													firstWallet={firstWallet}
													secondWallet={secondWallet}
													thirdWallet={thirdWallet}
												/>
												{!!orderPayload.recurrenceId
											&& (
												<RecurringOrdersCard
													recurringOrder={recurringOrder}
													applicationId={applicationId}
												/>
											)}
												{!!orderPayload.errorMessage && <TextCard data={{ errorMessage: orderPayload.errorMessage }} colNumber={1} left="left-2" />}
											</Col>
											<Col span={12}>
												<Card className="payload" bordered={false}>
													<h2>Order Payload</h2>
													<div className="raw-response">
														<ReactJson
															src={orderPayload}
															theme="shapeshifter:inverted"
															enableClipboard={false}
															style={{
																backgroundColor: '#F0F6FA',
																padding: '16px',
															}}
															collapseStringsAfterLength={36}
														/>
													</div>
												</Card>
											</Col>
										</Row>
									</div>
									<Drawer
										width={600}
										placement="right"
										closable
										onClose={onClose}
										visible={drawer}
										className="drawer"
									>
										<h2 className="capitalize">{title}</h2>
										<div className="drawer-data">
											<TextCard data={drawerDataId} colNumber={1} />
											<TextCard data={drawerDataTime} colNumber={1} />
											<TextCard data={drawerData} colNumber={1} />
										</div>
										<h2>Payload</h2>
										<div className="raw-response">
											<ReactJson
												src={payloadData}
												theme="shapeshifter:inverted"
												enableClipboard={false}
												style={{
													backgroundColor: '#F0F6FA',
													padding: '16px',
												}}
												collapseStringsAfterLength={36}
											/>
										</div>
										<div className="drawer-button">
											<CPButton
												action={onClose}
												text="close"
											/>
										</div>
									</Drawer>
								</div>
							)
							: (
								<div className="empty-state">
									<UserNoChecks />
									<p>{message}</p>
								</div>
							)}
						<WarningModal
							title="Error"
							visible={warningModal}
							cancelFunction={handleCancelCancel}
							footer={[
								{ type: 'cancel', action: handleCancelCancel, text: 'close' },
							]}
							message={message}
						/>
						<DeleteModal
							okFunction={submitCancelOrder}
							cancelFunction={handleCancelCancel}
							visible={cancelClicked}
							btnText="Cancel Order"
							message="Are you sure you want to cancel order?"
							loading={buttonLoading}
						/>
					</div>
				)
				: <Spin spinning={!dataFetched} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	account: state.account.account,
	orderPayload: state.orders.order,
	order: orderFormattedSelector(state),
	recurringOrder: state.orders.recurringOrder,
});

const mapDispatchToprops = {
	getAccount,
	getOrder,
	cancelOrder,
	getRecurringOrder,
	getProviders,
};

export default connect(mapStateToProps, mapDispatchToprops)(OrderPage);
