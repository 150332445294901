// @flow

const initialState = {
	error: false,
	isFetching: null,
	systemTags: [],
	userTags: [],
};

export default function userTagsReducer(state:Object = initialState, action:Object) {
	switch (action.type) {
	case 'FETCH_USER_TAGS': {
		return {
			...state,
			isFetching: true,
			error: false,
		};
	}

	case 'FETCH_USER_TAGS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetching: false,
			systemTags: action.payload.data,
		};
	}

	case 'ADD_USER_TAG': {
		return {
			...state,
			error: false,
		};
	}

	case 'ADD_USER_TAG_SUCCESS': {
		return {
			...state,
			error: false,
		};
	}

	case 'EDIT_USER_TAG': {
		return {
			...state,
			error: false,
		};
	}

	case 'EDIT_USER_TAG_SUCCESS': {
		return {
			...state,
			error: false,
		};
	}

	case 'REMOVE_USER_TAG': {
		return {
			...state,
			error: false,
		};
	}

	case 'GET_USER_TAGS_SUCCESS': {
		return {
			...state,
			error: false,
			userTags: action.payload.data,
		};
	}

	default:
		return state;
	}
}
