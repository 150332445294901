// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import InputNumber from '../../../components/UiElements/InputNumber';
import Switch from '../../../components/UiElements/Switch';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	instrument: Instrument,
}

type State = {
	errorMessage: string,
	haircut: number | null,
	inputDisabled: boolean,
}

class EditCollateral extends React.Component<Props, State> {
	state = {
		errorMessage: '',
		haircut: this.props.instrument.haircut,
		inputDisabled: false,
	}

	componentDidMount() {
		this.setState({
			haircut: this.props.instrument.haircut,
		});
	}

	handleCollateralChange = (value) => {
		if (value) {
			this.setState({
				inputDisabled: false,
				haircut: this.props.form.getFieldValue('haircut'),
			});
		} else {
			this.setState({
				haircut: null,
				inputDisabled: true,
			});
		}
	}

	handleHaircutChange = (value) => {
		this.setState({
			haircut: value,
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			haircut: this.props.instrument.haircut,
			inputDisabled: false,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges, instrument } = this.props;
		form.validateFields((err: Object) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = { haircut: this.state.haircut };
				submitChanges(instrument, data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator }, instrument, handleCancelClick,
		} = this.props;
		const {
			errorMessage, haircut, inputDisabled,
		} = this.state;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 9 },
			},
			labelAlign: 'left',
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<Form.Item label="COLLATERAL" {...formItemLayout}>
					{getFieldDecorator('collateral')(
						<Switch
							defaultChecked={haircut !== null}
							onChange={this.handleCollateralChange}
							checkedChildren="Yes"
							unCheckedChildren="No"
						/>,
					)}
				</Form.Item>
				<Form.Item label="HAIRCUT" {...formItemLayout} help="">
					{getFieldDecorator('haircut', {
						rules: [
							{ required: true, message: "Haircut can't be left empty" },
							{ validator: (rule, value, cb) => (value > 100 ? cb('Value has to be between 0 and 100 (%)!') : cb()) },
						],
						initialValue: instrument.haircut,
					})(
						<InputNumber
							disabled={inputDisabled}
							symbol="%"
							onChange={this.handleHaircutChange}
							placeholder="Set Haircut"
							type="number"
						/>,
					)}
				</Form.Item>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!this.props.form.isFieldsTouched()} text="edit collateral" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EditCollateralForm = Form.create()(EditCollateral);

export default EditCollateralForm;
