// @flow

import React from 'react';

type IconStyle = {
    width?: string,
    height?: string,
	color?: string,
	background?: string,
}

const Block = ({
	width = '162px',
	height = '87px',
	color = '#E3E4E5',
	background = 'none',
}: IconStyle) => (
	<svg width={width} height={height} viewBox="0 0 162 87" version="1.1">
		<title>blocks</title>
		<desc>Created with Sketch.</desc>
		<g id="blocks" stroke="none" strokeWidth="1" fill={background} fillRule="evenodd">
			<g id="Group-Copy-2">
				<rect id="Rectangle" x="0" y="0" width="162" height="87" rx="4" />
				<path d="M12.2903226,10 L81.916129,10 C83.1810393,10 84.2064516,11.0254123 84.2064516,12.2903226 L84.2064516,19.2614016 C84.2064516,20.5263118 83.1810393,21.5517241 81.916129,21.5517241 L12.2903226,21.5517241 C11.0254123,21.5517241 10,20.5263118 10,19.2614016 L10,12.2903226 C10,11.0254123 11.0254123,10 12.2903226,10 Z M14,31 L70,31 C72.209139,31 74,32.790861 74,35 L74,73 C74,75.209139 72.209139,77 70,77 L14,77 C11.790861,77 10,75.209139 10,73 L10,35 C10,32.790861 11.790861,31 14,31 Z M92,31 L148,31 C150.209139,31 152,32.790861 152,35 L152,73 C152,75.209139 150.209139,77 148,77 L92,77 C89.790861,77 88,75.209139 88,73 L88,35 C88,32.790861 89.790861,31 92,31 Z" id="Combined-Shape" fill={color} />
			</g>
		</g>
	</svg>
);

Block.defaultProps = {
	width: '162px',
	height: '87px',
	color: '#E3E4E5',
	background: 'none',
};
export default Block;
