// @flow

import React, { useEffect } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import methods
import { setCurrentPage } from '../../../modules/actions/UiActions';
import { getDividendsPayouts } from '../../../modules/actions/DividendsAction';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { DividendPayout } from '../../../modules/reducers/DividendsReducer';

type Props = {
	payouts: Array<DividendPayout>,
	payoutsTotal: number,
	isFetchingPayouts: boolean,
	payoutPage: number,
	setCurrentPage: (string, number) => void,
	getDividendsPayouts: (string, number, string) => void,
	applicationId: string,
	accountId: string,
}

const PayoutsTab = (props: Props) => {
	const {
		payouts,
		payoutsTotal,
		isFetchingPayouts,
		payoutPage,
		applicationId,
		accountId,
		setCurrentPage: setCurrentPageAction,
		getDividendsPayouts: getDividendsPayoutsAction,
	} = props;

	useEffect(() => {
		getDividendsPayoutsAction(
			applicationId,
			payoutPage,
			queryString.stringify({ accountId }),
		);
	}, [
		accountId,
		payoutPage,
		applicationId,
		getDividendsPayoutsAction,
	]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('accountPayoutPage', page);
	};

	const columns = [
		{
			title: 'dividend instrument',
			dataIndex: 'dividend.dividendInstrumentId',
			key: 'dividendInstrumentId',
			width: 176,
		},
		{
			title: 'payout instrument',
			dataIndex: 'dividend.payoutInstrumentId',
			key: 'payoutInstrumentId',
			width: 176,
		},
		{
			title: 'amount',
			dataIndex: 'quantity',
			key: 'quantity',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendPayout) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.dividend.dividendInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'gross rate',
			dataIndex: 'grossAmount',
			key: 'grossAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendPayout) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.dividend.payoutInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'tax',
			dataIndex: 'taxAmount',
			key: 'taxAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendPayout) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.dividend.payoutInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'executed at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'announcement date',
			dataIndex: 'dividend.announcementDate',
			key: 'announcementDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'ex dividend date',
			dataIndex: 'dividend.exDividendDate',
			key: 'exDividendDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'record date',
			dataIndex: 'dividend.recordDate',
			key: 'recordDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'payout date',
			dataIndex: 'dividend.payoutDate',
			key: 'payoutDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
	];

	return (
		<div>
			{!isFetchingPayouts
				? (
					<CustomTable
						columns={columns}
						data={payouts}
						total={payoutsTotal}
						handlePageChange={handlePageChange}
						currentPage={payoutPage}
						headerHeight={208}
						place="accountPayouts"
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingPayouts} position="spin-relative" />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	payouts: state.dividends.payouts,
	isFetchingPayouts: state.dividends.isFetchingPayouts,
	payoutsTotal: state.dividends.payoutsTotal,
	payoutPage: state.ui.accountPayoutPage,
});

const mapDispatchToProps = {
	setCurrentPage,
	getDividendsPayouts,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutsTab);
