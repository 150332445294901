// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import moment from 'moment';
//= import components
import FilterTag from '../../../components/UiElements/FilterTag';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Modal from '../../../components/UiElements/Modal';
import RangePicker from '../../../components/UiElements/RangePicker';
//= import actions
import { setCurrentFilters, setCurrentPage } from '../../../modules/actions/UiActions';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
// import styles
import styles from '../assets/corporateAction.module.scss';

type Props = {
	visible: boolean,
	closeFunction: () => void,
	setCurrentFilters: (string, {[string]: string}) => void,
	currentFilters: {[string]: string},
	date: boolean,
}
const DividendsFilter = (props: Props) => {
	const {
		visible, closeFunction, currentFilters, date,
	} = props;

	const [property, setProperty] = useState('');
	const [filterValue, setFilterValue] = useState(currentFilters);
	const [forDate, setForDate] = useState('');

	const closeModal = () => {
		setProperty('');
		closeFunction();
	};

	const setTime = (value) => {
		setForDate(value[0]);
	};

	const makeFilterValue = (value) => {
		const filter = { ...filterValue, eventTypes: value };
		setFilterValue(filter);
	};

	const makeTimeFilter = (value) => {
		const filter = {
			...filterValue,
			from: value[0] ? value[0].format('YYYY-MM-DD 00:00:00') : '',
			to: value[1] ? value[1].format('YYYY-MM-DD 23:59:59') : '',
		};
		setFilterValue(filter);
	};

	const makeQuery = () => {
		props.setCurrentFilters('dividendsFilters', filterValue);
		closeModal();
	};

	const filterByTime = (value) => {
		const timeFilter = {
			...filterValue,
			from: value[0] ? [value[0].format('YYYY-MM-DD 00:00:00')] : [],
			to: value[1] ? [value[1].format('YYYY-MM-DD 23:59:59')] : [],
		};
		setFilterValue(timeFilter);
		props.setCurrentFilters('dividendsFilters', timeFilter);
		closeModal();
	};

	const onTagRemove = (key: string) => {
		const filter = { ...filterValue, [key]: '' };
		setFilterValue(filter);
		props.setCurrentFilters('dividendsFilters', filter);
	};

	const eventTypes = ['announcementDate', 'exDividendDate', 'recordDate', 'payoutDate'];
	const { Option } = Select;

	return (
		<>
			<div className={styles.tags}>
				{map(currentFilters, (value, key) => (
					<FilterTag
						closable={key === 'eventTypes'}
						onClose={() => onTagRemove(key)}
						key={key}
						className={styles.filterTags}
						color="#E3F0FF"
					>
						{`${key} is ${value}`}
					</FilterTag>
				))}
			</div>
			<Modal
				visible={visible && !date}
				footer=""
				closable={false}
				destroyOnClose
				width={496}
				style={{ top: 64, marginRight: 24 }}
				bodyStyle={{ padding: 8 }}
				onCancel={closeModal}
			>
				<div className={styles.filter}>
					<Select
						allowClear
						showSearch
						className={styles.firstSelect}
						onChange={(value) => setProperty(value)}
					>
						<Option value="eventTypes" key="eventTypes" className={styles.options}>
							Event Types
						</Option>
						<Option value="date" key="date" className={styles.options}>
							Date
						</Option>
					</Select>
					<div className={styles.condition}>is</div>
					{property === 'date'
						? (
							<RangePicker
								className={styles.date}
								dropdownClassName={styles.picker}
								onCalendarChange={(value) => setTime(value)}
								onChange={(value) => makeTimeFilter(value)}
								disabledDate={forDate ? (current) => current > moment(forDate).add(30, 'day') : () => false}
							/>
						)
						: (
							<Select
								allowClear
								className={styles.select}
								style={{ width: '100%' }}
								showSearch
								optionFilterProp="children"
								onChange={(value) => makeFilterValue(value)}
							>
								{eventTypes.map((el) => (
									<Option value={el} key={el} className={styles.options}>
										{el}
									</Option>
								))}
							</Select>
						)}
					<CPButton
						type="primary"
						text="filter"
						action={makeQuery}
					/>
				</div>
			</Modal>
			<Modal
				visible={visible && date}
				footer=""
				closable={false}
				destroyOnClose
				width={240}
				style={{ top: 64, marginRight: 24 }}
				bodyStyle={{ padding: 0 }}
				onCancel={closeModal}
			>
				<RangePicker
					className={styles.date}
					dropdownClassName={styles.picker}
					onCalendarChange={(value) => setTime(value)}
					disabledDate={forDate ? (current) => current > moment(forDate).add(30, 'day') : () => false}
					onChange={(value) => filterByTime(value)}
					open
				/>
			</Modal>
		</>
	);
};
const mapStateToProps = (state: State, props) => ({
	currentFilters: state.ui[`${props.place}Filters`],
});

const mapDispatchToProps = {
	setCurrentFilters,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DividendsFilter);
