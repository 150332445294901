// @flow

import React, { useState } from 'react';
import ReactJson from 'react-json-view';
// import components
import Icon from '../Icon';
import Popover from '../Popover';
//= import styles
import styles from './assets/tableInfo.module.scss';

type Props = {
	content: Object,
	text: string,
	name: string,
}
const TableInfoComponent = (props: Props) => {
	const {
		content,
		text,
		name,
	} = props;

	const [visible, setVisible] = useState(false);

	const contentComponent = 	(
		<div className="raw-response">
			<div className={styles.close}>
				<Icon name="Close" onClick={() => setVisible(false)} />
			</div>
			<ReactJson
				src={content}
				theme="shapeshifter:inverted"
				name={name}
				style={{
					backgroundColor: '#F0F6FA',
					padding: '16px',
				}}
			/>
		</div>
	);

	return (
		<div>
			<Popover
				content={contentComponent}
				placement="right"
				visible={visible}
			>
				<div className={styles.tableInfo}>
					<p>
						{text}
					</p>
					<Icon name="Expand" onClick={() => setVisible(!visible)} size={14} />
				</div>
			</Popover>
		</div>
	);
};

export default TableInfoComponent;
