// @flow

import {
	APPLICATIONS_INSTRUMENTS,
	INSTRUMENT,
	ADD_PAYMENT_METHODS,
	REMOVE_PAYMENT_METHODS,
	UPDATE_INSTRUMENT,
	DELIST_INSTRUMENT,
} from '../const/types';

export function getAppInstruments(
	appId: string,
	paginationType: string = 'numbered-pages',
	paginationOffset: string | number = 0,
	param: string = '',
) {
	return (dispatch: Function): void => dispatch({
		type: APPLICATIONS_INSTRUMENTS,
		payload: {
			request: {
				url: `/applications/${appId}/instruments?${param}`,
				headers: {
					'pagination-type': paginationType,
					'pagination-limit': 50,
					'pagination-offset': paginationOffset,
				},
			},
		},
	});
}

export function getInstrument(applicationId: string, instrumentId: string) {
	return (dispatch: Function): void => dispatch({
		type: INSTRUMENT,
		payload: {
			request: {
				url: `/applications/${applicationId}/instruments/${instrumentId}`,
			},
		},
	});
}

export function addPaymentMethods(applicationId: string, instrumentId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_PAYMENT_METHODS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/instruments/${instrumentId}/add-payment-methods`,
				data,
			},
		},
	});
}

export function removePaymentMethods(applicationId: string, instrumentId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_PAYMENT_METHODS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/instruments/${instrumentId}/remove-payment-methods`,
				data,
			},
		},
	});
}

export function updateAppInstrument(applicationId: string, instrumentId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_INSTRUMENT,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/instruments/${instrumentId}`,
				data,
			},
		},
	});
}

export function delistInstrument(applicationId: string, instrumentId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: DELIST_INSTRUMENT,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/instruments/${instrumentId}/delist`,
				data,
			},
		},
	});
}
