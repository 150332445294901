// @flow
import React, { useState } from 'react';
import { Tooltip } from 'antd';
import styles from './../assets/twoFactorForm.scss';
import MenuIcon from '../../../components/UiElements/Icon/MenuIcon';
import CPButton from '../../../components/UiElements/Button';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import { loginWith2FA } from '../../../modules/actions/OauthActions';
import Icon from '../../../components/UiElements/Icon/';

type Values = {
	username: string,
	password: string,
	appName: string,
	otp: string,
}

type Props = {
	handleSubmit: Values => Promise<void>,
    credentials: {
        username: string,
        password: string,
        appName: string,
    },
	loginWith2FA: (string, string, string, string) => Promise<void>,
	visible: boolean,
	backAction: () => void,
}

function TwoFactorInputModal(props: Props) {
	const { loginWith2FA: loginWith2FAAction,
		credentials: {
			username,
			password,
			appName,
		},
		backAction, visible} = props;
	const [otp, setOtp] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [hasErrored, setHasErrored] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const getHelp = <span>Lost access to your authenticator? <br/> Send an email to support@tradecore.com from the email address associated with this account.</span>;

	const handleSubmit = async (values: Values) => {
		const REQUIRED_TOKEN_LENGTH = 6;
		if (!values.otp || values.otp.length !== REQUIRED_TOKEN_LENGTH) return;
		if (values.otp.length === 6) await loginWith2FAAction(values.username, values.password, values.appName, values.otp);
	};

	const goBack = () => {
		setOtp('');
		setDisabled(false);
		setHasErrored(false);
		setErrorMessage('');
		backAction();
	}
	const submit = (otp:string) => {
		setOtp(otp);
		setHasErrored(false)
		setErrorMessage('')
		if (otp.length >= 6) {
			setDisabled(true);
			setIsLoading(true);

			handleSubmit({username, password, appName, otp })
				.catch((error) => {
					const errorMessage = error.error.response.data.message;
					setErrorMessage(errorMessage);
					setHasErrored(true);
					setDisabled(false);
					setIsLoading(false);
					setOtp('');
				});
		}
	}

		return (
			<FormModal
				title=''
				visible={visible}
				cancelFunction={goBack}
				form={
					<div className='two-factor'>
					<div className="two-factor-nav">
						<CPButton
							action={goBack}
							icon="ArrowLeft"
						/>
						<div>
							<MenuIcon type="CompanyLogo" styles={{ margin: '0px 0px 0px 30px' }} width="120px" height="38px" color="#7C7C7C" viewBox="0 0 174 40" />
						</div>
					</div>
					<div className='tooltip'>
						<h2>Enter six-digit OTP code</h2>
						<Tooltip
							title={getHelp}
							arrowPointAtCenter={true}
							placement="top"
							overlayClassName="tooltip-overlay"
							zIndex={9999}
						>
							<span className='icon'>
								<Icon name='Info'/>
							</span>
						</Tooltip>
					</div>
					<OtpInput
						value={otp}
						onChange={submit}
						numInputs={6}
						containerStyle={styles['two-factor-container']}
						inputStyle='two-factor-input'
						isInputNum={true}
						shouldAutoFocus={true}
						isDisabled={disabled}
						hasErrored={hasErrored}
						errorStyle='two-factor-error'
						isInputSecure={true}
					/>
					<div className='two-factor-error-message'>
						{hasErrored && errorMessage}
					</div>
					{isLoading
						&& !hasErrored
						&&	<Spin
								wrapperClassName='two-factor-spinner'>
								<div className='two-factor-spinner'>
								</div>
							</Spin>
					}
				</div>
				}/>
		);
}
const mapDispatchToProps = {
	loginWith2FA,
}
export default connect(null, mapDispatchToProps)(TwoFactorInputModal);
