// @flow

const initialState = {
	exploreGroups: [],
	isFetchingExploreGroups: false,
	isFetchedExploreGroups: false,
	error: false,
	exploreGroup: {},
	isFetchingExploreGroup: false,
	isFetchedExploreGroup: false,
	addingExploreGroup: false,
	exploreGroupAdded: false,
	removingExploreGroup: false,
	removedExploreGroup: false,
	updatingExploreGroup: false,
	updatedExploreGroup: false,
};

export type ExploreGroup = {
	id: string,
	name: string,
	nameSlug: string,
	description: string,
	display: string,
	icon: string,
	coverPhoto: string,
	applicationId: string,
	createdAt: string,
	updatedAt: string,
	deletedAt: string,
	order: string,
	exploreSymbols: Array<string>,
}
export type ExploreGroups = Array<ExploreGroup>
export type ExploreGroupState = {
	exploreGroups: ExploreGroups,
	isFetchingExploreGroups: boolean,
	isFetchedExploreGroups: boolean,
	exploreGroup: ExploreGroup,
	isFetchingExploreGroup: boolean,
	isFetchedExploreGroup: boolean,
	error: boolean,
	addingExploreGroup: boolean,
	exploreGroupAdded: boolean,
	removingExploreGroup: boolean,
	removedExploreGroup: boolean,
	updatingExploreGroup: boolean,
	updatedExploreGroup: boolean,
}
type ExploreGroupsAction = {
	type:
	| 'GET_EXPLORE_GROUPS'
	| 'GET_EXPLORE_GROUPS_FAIL'
	| 'GET_EXPLORE_GROUPS_SUCCESS',
	payload: {
		data: Array<ExploreGroup>,
	},
	error: {
		code: number,
		message: string,
	}
}
type ExploreGroupAction = {
	type:
	| 'GET_EXPLORE_GROUP'
	| 'GET_EXPLORE_GROUP_FAIL'
	| 'GET_EXPLORE_GROUP_SUCCESS'
	| 'ADD_EXPLORE_GROUP'
	| 'ADD_EXPLORE_GROUP_FAIL'
	| 'ADD_EXPLORE_GROUP_SUCCESS'
	| 'UPDATE_EXPLORE_GROUP'
	| 'UPDATE_EXPLORE_GROUP_FAIL'
	| 'UPDATE_EXPLORE_GROUP_SUCCESS'
	| 'ADD_SYMBOL_TO_EXPLORE'
	| 'ADD_SYMBOL_TO_EXPLORE_FAIL'
	| 'ADD_SYMBOL_TO_EXPLORE_SUCCESS'
	| 'REMOVE_SYMBOL_FROM_EXPLORE'
	| 'REMOVE_SYMBOL_FROM_EXPLORE_FAIL'
	| 'REMOVE_SYMBOL_FROM_EXPLORE_SUCCESS',
	payload: {
		data: ExploreGroup,
	},
	error: {
		code: number,
		message: string,
	}
}
type Action = ExploreGroupAction | ExploreGroupsAction;

export default function exploreGroupReducer(
	state: ExploreGroupState = initialState,
	action: Action,
): ExploreGroupState {
	switch (action.type) {
	case 'GET_EXPLORE_GROUPS': {
		return {
			...state,
			isFetchingExploreGroups: true,
			isFetchedExploreGroups: false,
		};
	}
	case 'GET_EXPLORE_GROUPS_FAIL': {
		return {
			...state,
			error: true,
			isFetchingExploreGroups: false,
			isFetchedExploreGroups: false,
		};
	}
	case 'GET_EXPLORE_GROUPS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingExploreGroups: false,
			isFetchedExploreGroups: true,
			exploreGroups: action.payload.data,
		};
	}
	case 'GET_EXPLORE_GROUP': {
		return {
			...state,
			isFetchingExploreGroup: true,
			isFetchedExploreGroup: false,
		};
	}
	case 'GET_EXPLORE_GROUP_FAIL': {
		return {
			...state,
			error: true,
			isFetchingExploreGroup: false,
			isFetchedExploreGroup: false,
		};
	}
	case 'GET_EXPLORE_GROUP_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingExploreGroup: false,
			isFetchedExploreGroup: true,
			exploreGroup: action.payload.data,
		};
	}
	case 'ADD_EXPLORE_GROUP': {
		return {
			...state,
			addingExploreGroup: true,
			exploreGroupAdded: false,
		};
	}
	case 'ADD_EXPLORE_GROUP_FAIL': {
		return {
			...state,
			error: true,
			addingExploreGroup: false,
			exploreGroupAdded: false,
		};
	}
	case 'ADD_EXPLORE_GROUP_SUCCESS': {
		return {
			...state,
			error: false,
			addingExploreGroup: false,
			exploreGroupAdded: true,
			exploreGroups: [...state.exploreGroups, action.payload.data],
		};
	}
	case 'UPDATE_EXPLORE_GROUP': {
		return {
			...state,
			updatingExploreGroup: true,
			updatedExploreGroup: false,
		};
	}
	case 'UPDATE_EXPLORE_GROUP_FAIL': {
		return {
			...state,
			error: true,
			updatingExploreGroup: false,
			updatedExploreGroup: false,
		};
	}
	case 'UPDATE_EXPLORE_GROUP_SUCCESS': {
		return {
			...state,
			error: false,
			updatingExploreGroup: false,
			updatedExploreGroup: true,
			exploreGroup: action.payload.data,
			exploreGroups: state.exploreGroups.map((group) => {
				if (group.id === action.payload.data.id) {
					return action.payload.data;
				}
				return group;
			}),
		};
	}
	case 'ADD_SYMBOL_TO_EXPLORE': {
		return {
			...state,
			updatingExploreGroup: true,
			updatedExploreGroup: false,
		};
	}
	case 'ADD_SYMBOL_TO_EXPLORE_FAIL': {
		return {
			...state,
			error: true,
			updatingExploreGroup: false,
			updatedExploreGroup: false,
		};
	}
	case 'ADD_SYMBOL_TO_EXPLORE_SUCCESS': {
		return {
			...state,
			error: false,
			updatingExploreGroup: false,
			updatedExploreGroup: true,
			exploreGroup: action.payload.data,
		};
	}
	case 'REMOVE_SYMBOL_FROM_EXPLORE': {
		return {
			...state,
			updatingExploreGroup: true,
			updatedExploreGroup: false,
		};
	}
	case 'REMOVE_SYMBOL_FROM_EXPLORE_FAIL': {
		return {
			...state,
			error: true,
			updatingExploreGroup: false,
			updatedExploreGroup: false,
		};
	}
	case 'REMOVE_SYMBOL_FROM_EXPLORE_SUCCESS': {
		return {
			...state,
			error: false,
			updatingExploreGroup: false,
			updatedExploreGroup: true,
			exploreGroup: action.payload.data,
		};
	}
	default:
		return state;
	}
}
