// @flow

import React, {
	useState, useEffect, useRef, useCallback,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import Switch from '../../../components/UiElements/Switch';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import EditCollateralForm from '../components/EditCollateralForm';
import AddCollateralForm from '../components/AddCollateralForm';
import MarginInterest from '../components/MarginInterest';
import CollateralInstruments from '../components/CollateralInstruments';
import EditSettingsForm from '../components/EditSettingsForm';
//= import actions
import { getAppFeatures, updateAppFeatures } from '../../../modules/actions/FeaturesAction';
import { getAppInstruments, updateAppInstrument } from '../../../modules/actions/InstrumentAction';
import { getApplicationSettings, updateApplicationSettings } from '../../../modules/actions/ApplicationActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { Feature } from '../../../modules/reducers/FeatureReducer';
import type { ApplicationSettings } from '../../../modules/reducers/ApplicationReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/features.module.scss';

type Props = {
	activeTab: string,
	getAppFeatures: (string) => Promise<Object>,
	updateAppFeatures: (string, string, Object) => Promise<Object>,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	updateAppInstrument: (string, string, Object) => Promise<Object>,
	getApplicationSettings: (string) => void,
	updateApplicationSettings: (string, Object) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	currentPage: number,
	applicationId: string,
	appSettings: ApplicationSettings,
	instrumentsFilters: {[string]: string},
	collateralInstruments: Array<Instrument>,
	instrumentsTotal: number,
	isFetchingInstruments: boolean,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		goBack: () => void,
	},
}
type FeaturesResponse = {
	payload: {
		data: Array<Feature>,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

const MarginTrading = (props: Props) => {
	const {
		match: { params: { applicationId } },
		activeTab,
		getAppFeatures: getAppFeaturesAction,
		getAppInstruments: getAppInstrumentsAction,
		updateAppFeatures: updateAppFeaturesAction,
		updateAppInstrument: updateAppInstrumentAction,
		getApplicationSettings: getApplicationSettingsAction,
		updateApplicationSettings: updateApplicationSettingsAction,
		setCurrentPage: setCurrentPageAction,
		appSettings,
		currentPage,
		instrumentsFilters,
		collateralInstruments,
		instrumentsTotal,
		isFetchingInstruments,
	} = props;

	const [margin, setMargin] = useState({});
	const [toggle, setToggle] = useState(false);
	const [switchKey, setSwitchKey] = useState(true);
	const [data, setData] = useState({});
	const [edit, setEdit] = useState(false);
	const [title, setTitle] = useState('');
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [instrument, setInstrument] = useState({});
	const [add, setAdd] = useState(false);
	const [resetForm, setResetForm] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		async function fetchData() {
			const res: FeaturesResponse = await getAppFeaturesAction(applicationId);
			const featureData: ?Feature = res.payload.data.find((feature: Feature) => feature.featureId === 'margin_trading');
			if (featureData) {
				setMargin(featureData);
			}
		}
		fetchData();
	}, [
		applicationId,
		getAppFeaturesAction,
		getApplicationSettingsAction,
		data,
	]);

	useEffect(() => {
		getApplicationSettingsAction(applicationId);
	}, [
		applicationId,
		getApplicationSettingsAction,
	]);

	useEffect(() => {
		const idsFilter = instrumentsFilters.ids?.[0]?.replace(/\s+/g, '').toUpperCase();
		const filterOptions: string = queryString.stringify({ ...instrumentsFilters, ids: idsFilter });
		getAppInstrumentsAction(applicationId, 'numbered-pages', (currentPage - 1) * StatusHelpers.paginationLimit, filterOptions);
	}, [
		applicationId,
		getAppInstrumentsAction,
		currentPage,
		instrumentsFilters,
	]);

	const filterRef = useRef(null);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [instrumentsFilters]);

	const editFormRef = useRef(null);
	const addFormRef = useRef(null);
	const editSettingsFormRef = useRef(null);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('marginPage', page);
	};

	const handleEditClick = (ins: Instrument) => {
		setEdit(true);
		setTitle(`Edit ${ins.name} Collateral`);
		setResetForm(true);
		setInstrument(ins);
	};

	const handleEditInterest = () => {
		setAdd(true);
		setTitle('Edit Margin Interest Rate');
		setResetForm(true);
	};

	const handleWarningCancel = () => {
		setEdit(true);
		setWarning(false);
	};

	const handleAddWarningCancel = () => {
		setAdd(true);
		setWarning(false);
	};

	const handleErrorCancel = () => {
		if (editFormRef.current) {
			editFormRef.current.handleReset();
		}
		if (addFormRef.current) {
			addFormRef.current.handleReset();
		}
		if (editSettingsFormRef.current) {
			editSettingsFormRef.current.handleReset();
		}
		setWarning(false);
		setResetForm(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningTitle('Error');
		setWarning(true);
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleEditCancel = () => {
		if ((editFormRef.current && editFormRef.current.props.form.isFieldsTouched())
			|| (editFormRef.current && editFormRef.current.state.isTouched)) {
			setEdit(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setEdit(false);
			setWarning(false);
			setResetForm(false);
		}
	};

	const handleAddCancel = () => {
		if ((addFormRef.current && addFormRef.current.state.selectedKey.length)
		|| (editSettingsFormRef.current && editSettingsFormRef.current.props.form.isFieldsTouched())) {
			setAdd(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleAddWarningCancel, text: 'continue adding' },
			]);
		} else {
			setAdd(false);
			setWarning(false);
			setResetForm(false);
		}
	};

	const submitChanges = (value, haircut) => {
		setButtonLoading(true);
		if (editFormRef.current) {
			editFormRef.current.handleReset();
		}
		if (addFormRef.current) {
			addFormRef.current.handleReset();
		}
		if (editSettingsFormRef.current) {
			editSettingsFormRef.current.handleReset();
		}
		if (activeTab === 'collateral') {
			updateAppInstrumentAction(applicationId, value.instrumentId, haircut)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setResetForm(false);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else {
			updateApplicationSettingsAction(applicationId, value)
				.catch((error: ErrorType) => handleOnError(error));
		}
		setButtonLoading(false);
		setWarning(false);
		setAdd(false);
	};

	const continueChanges = (value, haircut) => {
		setWarning(true);
		setEdit(false);
		setAdd(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect this Instrument.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(instrument, haircut), text: 'save changes' },
		]);
	};

	const continueAdding = (value, haircut) => {
		setWarning(true);
		setAdd(false);
		setWarningTitle('Are you sure?');
		setMessage(activeTab === 'collateral'
			? {
				firstPart: `${value.name} will be set as a Collateral Instrument. `,
				secondPart: 'Your clients will be able to use it as collateral for a loan.',
			}
			: {
				firstPart: 'Margin Interest Rate will be changed.',
			});
		setFooter([
			{ type: 'cancel', action: handleAddWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(value, haircut), text: 'save changes' },
		]);
	};

	const submitUpdate = useCallback(() => {
		setButtonLoading(true);
		updateAppFeaturesAction(applicationId, margin.featureId, data)
			.then(() => {
				setData({});
				setButtonLoading(false);
				setToggle(false);
			})
			.catch(() => setSwitchKey(!switchKey));
	}, [applicationId, data, margin.featureId, updateAppFeaturesAction, switchKey]);

	const handleToggle = (value: boolean): void => {
		const updateData = { enabled: value };
		setData(updateData);
	};

	useEffect(() => {
		if (data.enabled) {
			submitUpdate();
		} else if (data.enabled === false) {
			setToggle(true);
		}
	}, [data, submitUpdate]);

	const handleToggleCancel = () => {
		setToggle(false);
		setSwitchKey(!switchKey);
		setData({});
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const buttons = activeTab === 'collateral'
		? [{
			action: () => setFilterVisible(true),
			text: 'filter',
			icon: 'Funnel',
		}]
		: [{
			icon: 'Edit',
			text: 'edit interest rate',
			action: handleEditInterest,
		}];

	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="Margin Trading"
					buttons={buttons}
					tab="margin"
					tabs={[
						{
							key: 'collateral',
							title: 'COLLATERAL SETTINGS',
						},
						{
							key: 'marginInterest',
							title: 'MARGIN INTEREST',
						},
					]}
					place="margin"
					fields={{
						ids: 'input',
					}}
					filterMode="single"
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter={activeTab === 'collateral'}
					switchComponent={{
						key: switchKey.toString(),
						defaultChecked: margin.enabled,
						action: handleToggle,
					}}
				>
					<div className={styles.feature}>
						<Switch
							key={switchKey.toString()}
							checked={margin.enabled}
							onChange={(value: boolean) => handleToggle(value)}
						/>
					</div>
				</Title>
			</div>
			<>
				{margin.featureId && (
					<div className="container">
						{activeTab === 'collateral'
							&& (
								<CollateralInstruments
									collateralInstruments={collateralInstruments}
									handleEditClick={handleEditClick}
									applicationId={applicationId}
									handlePageChange={handlePageChange}
									currentPage={currentPage}
									headerHeight={filterHeight}
									instrumentsTotal={instrumentsTotal}
									isFetchingInstruments={isFetchingInstruments}
								/>
							)}
						{activeTab === 'marginInterest'
							&& (
								<MarginInterest />
							)}
						<DeleteModal
							okFunction={submitUpdate}
							cancelFunction={handleToggleCancel}
							visible={toggle}
							btnText="DISABLE"
							message="You&apos;re about to disable Margin Trading feature for all your Clients across entire Application."
						/>
						{resetForm && (
							<FormModal
								title={title}
								visible={edit}
								cancelFunction={handleEditCancel}
								form={(
									<EditCollateralForm
										key={instrument.id}
										submitChanges={continueChanges}
										handleCancelClick={handleEditCancel}
										instrument={instrument}
										wrappedComponentRef={editFormRef}
									/>
								)}
							/>
						)}
						{resetForm && (
							<FormModal
								title={title}
								visible={add}
								cancelFunction={handleAddCancel}
								form={activeTab === 'collateral'
									? (
										<AddCollateralForm
											key={instrument.id}
											submitChanges={continueAdding}
											handleCancelClick={handleAddCancel}
											wrappedComponentRef={addFormRef}
											applicationId={applicationId}
										/>
									)
									: (
										<EditSettingsForm
											submitChanges={continueAdding}
											handleCancelClick={handleAddCancel}
											appSettings={appSettings}
											wrappedComponentRef={editSettingsFormRef}
										/>
									)}
							/>
						)}
						<WarningModal
							title={warningTitle}
							visible={warning}
							cancelFunction={handleErrorCancel}
							footer={footer}
							message={message}
							loading={buttonLoading}
						/>
					</div>
				)}
			</>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	activeTab: state.ui.marginTab,
	appSettings: state.application.applicationSettings,
	currentPage: state.ui.marginPage,
	instrumentsTotal: state.instrument.instrumentsTotal,
	collateralInstruments: state.instrument.instruments,
	isFetchingInstruments: state.instrument.isFetchingInstruments,
	instrumentsFilters: state.ui.marginFilters,
});

const mapDispatchToProps = {
	getAppFeatures,
	updateAppFeatures,
	getAppInstruments,
	updateAppInstrument,
	getApplicationSettings,
	updateApplicationSettings,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarginTrading);
