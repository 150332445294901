// @flow

import React from 'react';
//= import components
import Card from '../../../components/UiElements/Card';
import Popover from '../../../components/UiElements/Popover';
import Icon from '../../../components/UiElements/Icon';

type Props = {
	secret: string,
}
const SecretCard = (props: Props) => {
	const { secret } = props;
	return (
		<Card bordered={false}>
			<div className="secret-title">
				<h2>Signing Secret Key</h2>
				<Popover
					overlayClassName="button-popover"
					content="Verify the events that are sent to your webhook endpoints."
				>
					<span>
						<Icon name="Info" />
					</span>
				</Popover>
			</div>
			<p>{secret}</p>
		</Card>
	);
};

export default SecretCard;
