// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
//= import componnets
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DepositFees from '../components/DepositFees';
import WithdrawalFees from '../components/WithdrawalFees';
import TradingFees from '../components/TradingFees';
import CardFxFees from '../components/CardFxFees';
import AddFeeForm from '../components/AddFeeForm';
import EditFeeForm from '../components/EditFeeForm';
import Title from '../../../components/UiElements/Title';
//= import methods
import { getFees, updateFee, addFee } from '../../../modules/actions/FeeActions';
import { feesMappedSelector } from '../../../modules/selectors/FeeSelector';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
//= import types
import type { MappedFee } from '../../../modules/selectors/FeeSelector';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	fees: Array<MappedFee>,
	activeTab: string,
	getFees: (string, string) => void,
	updateFee: (string, string, Object) => Promise<Object>,
	addFee: (string, Object) => Promise<Object>,
	getProvidersInstances: (string) => Promise<Object>,
	providers: Array<ProviderInstance>,
	match: {
		params: {
			applicationId: string,
		},
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

const AllFees = (props: Props) => {
	const {
		match: { params: { applicationId } },
		history: { goBack },
		getProvidersInstances: getProvidersInstancesAction,
		getFees: getFeesAction,
		updateFee: updateFeeAction,
		addFee: addFeeAction,
		fees,
		activeTab,
		providers,
	} = props;

	const [edit, setEdit] = useState(false);
	const [title, setTitle] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [feeToEdit, setFeeToEdit] = useState({});
	const [rankUpdated, setRankUpdated] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		const param = 'userId=null';
		getProvidersInstancesAction(applicationId)
			.then(() => getFeesAction(applicationId, param));
	}, [
		rankUpdated,
		applicationId,
		getProvidersInstancesAction,
		getFeesAction,
	]);

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitRankChanges = (feeId: string, rankData) => {
		setButtonLoading(true);
		setRankUpdated(false);
		updateFeeAction(applicationId, feeId, rankData)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
				setRankUpdated(true);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const editFeeRank = (fee: MappedFee, hoverIndex: number) => {
		const rankData = { priority: hoverIndex + 1 };
		setWarningModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes, ${fee.feeName} Fee Rule rank will be changed to rank #${hoverIndex + 1},
					 and all fee ranks below will be affected. It is advised to proceed editing with caution.`,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
			{ type: 'continue', action: () => submitRankChanges(fee.id, rankData), text: 'save changes' },
		]);
	};

	// create new fee

	const handleCreateClick = () => {
		setEdit(true);
		setTitle(`Create New ${props.activeTab} Fee`);
		setFeeToEdit({});
	};

	const submitAddFee = (data: MappedFee) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		const feeData = { ...data, type: activeTab };
		addFeeAction(applicationId, feeData)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueAdding = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect all Fee Rules below.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAddFee(value), text: 'create fee' },
		]);
	};

	const submitEditFee = (data) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		updateFeeAction(applicationId, feeToEdit.id, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueEditing = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect all Fee Rules below.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEditFee(value), text: 'edit fee' },
		]);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const setFee = (fee: MappedFee) => {
		setFeeToEdit(() => fee);
		setTitle(`Edit Fee Rule ${fee.feeName}`);
		setEdit(true);
	};

	const handleBackClick = () => {
		goBack();
	};

	const allFees = {
		deposit: fees && orderBy(fees.filter((fee) => fee.type === 'deposit'), 'priority'),
		withdrawal: fees && orderBy(fees.filter((fee) => fee.type === 'withdrawal'), 'priority'),
		trading: fees && orderBy(fees.filter((fee) => fee.type === 'trading'), 'priority'),
		cardFx: fees && orderBy(fees.filter((fee) => fee.type === 'cardFx'), 'priority'),
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Fee list"
				buttons={[
					{
						text: `Create New ${activeTab} fee Rule`,
						icon: 'Plus',
						action: handleCreateClick,
					},
				]}
				tab="fee"
				tabs={[
					{
						key: 'deposit',
						title: 'DEPOSIT FEES',
					},
					{
						key: 'withdrawal',
						title: 'WITHDRAWAL FEES',
					},
					{
						key: 'trading',
						title: 'TRADING FEES',
					},
					{
						key: 'cardFx',
						title: 'CARD FX FEES',
					},
				]}
			/>
			<div className="container">
				{activeTab === 'deposit' && (
					<DepositFees
						editFeeRank={editFeeRank}
						depositFees={allFees.deposit}
						setFeeToEdit={setFee}
						applicationId={applicationId}
						place="appFees"
					/>
				)}
				{activeTab === 'withdrawal' && (
					<WithdrawalFees
						editFeeRank={editFeeRank}
						withdrawalFees={allFees.withdrawal}
						setFeeToEdit={setFee}
						applicationId={applicationId}
						place="appFees"
					/>
				)}
				{activeTab === 'cardFx' && (
					<CardFxFees
						editFeeRank={editFeeRank}
						cardFxFees={allFees.cardFx}
						setFeeToEdit={setFee}
						applicationId={applicationId}
						place="appFees"
					/>
				)}
				{activeTab === 'trading' && (
					<TradingFees
						editFeeRank={editFeeRank}
						tradingFees={allFees.trading}
						setFeeToEdit={setFee}
						applicationId={applicationId}
						place="appFees"
					/>
				)}
				<FormModal
					title={title}
					visible={edit}
					cancelFunction={handleEditCancel}
					form={Object.keys(feeToEdit).length
						? (
							<EditFeeForm
								submitChanges={continueEditing}
								handleCancelClick={handleEditCancel}
								fee={feeToEdit}
								providers={providers}
								key={feeToEdit.id}
								wrappedComponentRef={formRef}
								applicationId={applicationId}
							/>

						)
						: (
							<AddFeeForm
								submitChanges={continueAdding}
								handleCancelClick={handleEditCancel}
								applicationId={applicationId}
								activeTab={activeTab}
								lowestRank={allFees[activeTab].length + 1}
								providers={providers}
								wrappedComponentRef={formRef}
							/>
						)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	fees: feesMappedSelector(state),
	activeTab: state.ui.feeTab,
	providers: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getFees,
	updateFee,
	addFee,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllFees);
