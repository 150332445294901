// @flow

import React, {
	useEffect, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import bcLogo from '../../../assets/img/company_logo.svg';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Card from '../../../components/UiElements/Card';
import Drawer from '../../../components/UiElements/Drawer';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import LimitControls from '../../Card/components/LimitControls';
import ControlComponent from '../../Card/components/ControlComponent';
import AuthorizationControls from '../components/AuthorizationControls';
import CardInstances from '../components/CardInstances';
import CardPaymentsList from '../../CardPayments/components/CardPaymentsList';
import StatusForm from '../components/StatusForm';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import actions
import {
	getCard, getAccountCardPayments, activateCard, changeStatus, getCardSpendControls,
} from '../../../modules/actions/CardAction';
//= import types
import type {
	CardType, Payment, LimitControlType,
} from '../../../modules/reducers/CardsReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	card: CardType,
	isFetchingCard: boolean,
	setCurrentPage: (string, number) => void,
	accountCardPayments: Array<Payment>,
	spendControls: Object,
	isFetchingAccountCardPayments: boolean,
	getCard: (string, string) => Promise<Object>,
	accountCardsPaymentsTotal: number,
	getAccountCardPayments: (string, string) => void,
	activateCard: (string, string, Object) => Promise<Object>,
	changeStatus: (string, string, Object) => Promise<Object>,
	getCardSpendControls: (string, string) => Promise<Object>,
	match: {
		params: {
			applicationId: string,
			cardId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}
type Value = {
	status: string,
}
type CardRes = {
	payload: {
		data: CardType,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				title: string,
				message: string,
				errors: {[string]: Array<string>}
			}
		}
	}
}

function CardPage(props: Props) {
	const {
		card,
		isFetchingCard,
		accountCardPayments,
		setCurrentPage: setCurrentPageAction,
		isFetchingAccountCardPayments,
		spendControls,
		accountCardsPaymentsTotal,
		getCard: getCardAction,
		getAccountCardPayments: getAccountCardPaymentsAction,
		activateCard: activateCardAction,
		changeStatus: changeStatusAction,
		getCardSpendControls: getCardSpendControlsAction,
		match: { params: { applicationId, cardId } },
		history,
	} = props;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [drawerData, setDrawerData] = useState({});
	const [edit, setEdit] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [success, setSuccess] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [pageSize, setPageSize] = useState(50);

	const formRef = useRef(null);

	useEffect(() => {
		async function fetchData() {
			const cardRes: CardRes = await getCardAction(applicationId, cardId);
			getAccountCardPaymentsAction(cardRes.payload.data.account.platformId, cardId);
			getCardSpendControlsAction(applicationId, cardId);
		}
		fetchData();
	}, [
		applicationId,
		cardId,
		getCardAction,
		getAccountCardPaymentsAction,
		getCardSpendControlsAction,
	]);

	const handleBackClick = () => {
		history.goBack();
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handlePageChange = (page: number, pageSize: number) => {
		setCurrentPageAction('cardPage', page);
		setPageSize(pageSize);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error && error.error.response.data ? error.error.response.data.title || error.error.response.data.message : 'Error',
			secondPart: error.error && error.error.response.data
				? printError(error.error.response.data.errors)
				: '',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleOnSuccess = () => {
		setWarningModal(true);
		setWarningTitle('Success');
		setMessage({ firstPart: "You've successfully changed the Status of the Card." });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const changeCardStatus = async (value: Value) => {
		setButtonLoading(true);
		try {
			await changeStatusAction(applicationId, cardId, value);
			setButtonLoading(false);
			setWarningModal(false);
			setSuccess(true);
			handleOnSuccess();
		} catch (error) {
			setSuccess(false);
			handleOnError(error);
		}
	};

	const activate = async () => {
		setButtonLoading(true);
		const data = { cardInstanceId: card.primaryCardInstance.id };
		try {
			await activateCardAction(card.account.platformId, cardId, data);
			setButtonLoading(false);
			setWarningModal(false);
			setSuccess(true);
			handleOnSuccess();
			getCardAction(applicationId, cardId);
		} catch (error) {
			setSuccess(false);
			handleOnError(error);
		}
	};

	const continueEdit = (value: Value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect the user\'s card.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => changeCardStatus(value), text: 'change card status' },
		]);
	};

	const handleActivateClick = () => {
		setWarningModal(true);
		setSuccess(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `You're about to activate ${card.name} card.`,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
			{ type: 'continue', action: activate, text: 'activate' },
		]);
	};

	const handleChangeStatuses = () => {
		setSuccess(false);
		setEdit(true);
	};

	const handleOpenDrawer = (control: LimitControlType) => {
		setDrawerOpen(true);
		const data = {
			name: control.amountLimit ? 'Limit Control' : 'Authorization Control',
			details: control.amountLimit
				? [
					{ key: 'Amount Limit', value: control.amountLimit },
					{ key: 'Currency', value: control.currency.name },
					{ key: 'Usage Limit', value: control.usageLimit },
					{ key: 'Velocity Window', value: control.velocityWindow },
				]
				: [
					{ key: 'Is Active', value: control.isActive ? 'Yes' : 'No' },
				],
			control,
		};
		setDrawerData(data);
	};

	const onClose = () => {
		setDrawerOpen(false);
	};

	const cardDetailsData = card.id ? {
		id: (
			<CopyComponent
				text={card.id}
				content={card.id}
			/>),
		name: card.name,
		status: card.status,
		createdAt: moment(card.createdAt).format('YYYY-MM-DD HH:mm:ss'),
		currency: card.currency.name,
		country: card.country.name,
		product: (
			<a href={`/application/${applicationId}/system/card/products/${card.productId}`} target="_blank" rel="noopener noreferrer">
				{card.productId}
			</a>
		),
		client: (
			<a href={`/application/${applicationId}/clients/${card.user.platformId}?accountId=${card.account.platformId}&tab=user_cards`} target="_blank" rel="noopener noreferrer">
				{`${card.user.firstName || 'Name Not Set'} ${card.user.lastName || ''}`}
			</a>
		),
		clientAddress: `${card.user.addressLine1} ${card.user.city}`,
		deliveryMethod: card.primaryCardInstance.deliveryMethod?.name,
		deliveryInfos: card.primaryCardInstance.deliveryInfo ? (
			<div>
				<p>{`${card.primaryCardInstance.deliveryInfo.firstName || ''} ${card.primaryCardInstance.deliveryInfo.lastName || ''}`}</p>
				<p>{`${card.primaryCardInstance.deliveryInfo.addressLine1 || ''} ${card.primaryCardInstance.deliveryInfo.zipCode || ''} ${card.primaryCardInstance.deliveryInfo.city || ''}`}</p>
			</div>
		) : '-',
		tradingAccount: (
			<a href={`/application/${applicationId}/clients/${card.user.platformId}?accountId=${card.account.platformId}`} target="_blank" rel="noopener noreferrer">
				{card.account.name}
			</a>),
		wallet: (
			<a href={`/application/${applicationId}/clients/${card.user.platformId}/user_wallets/${card.primaryWallet.platformId}`} target="_blank" rel="noopener noreferrer">
				{`${card.primaryWallet.instrumentPlatformId} Wallet`}
			</a>),
	} : {};

	const primaryCardData = card.primaryCardInstance ? {
		cardNameLine: card.primaryCardInstance.cardNameLine,
		createdAt: moment(card.primaryCardInstance.createdAt).format('YYYY-MM-DD HH:mm:ss'),
		expiresMonth: card.primaryCardInstance.expiresMonth,
		expiresYear: card.primaryCardInstance.expiresYear,
		isActive: card.primaryCardInstance.isActive ? 'Yes' : 'No',
		isVirtual: card.primaryCardInstance.isVirtual ? 'Yes' : 'No',
		pan: card.primaryCardInstance.pan,
	} : {};

	return (
		<>
			{!isFetchingCard && card.id
				? (
					<div>
						<Title
							backAction={handleBackClick}
							title={card.name}
							tags={[card.status]}
							buttons={card.status === 'NotActivated'
								? [{
									text: 'activate card',
									icon: 'Approve',
									action: handleActivateClick,
								}, {
									text: 'change status',
									icon: 'Automation',
									action: handleChangeStatuses,
								}]
								: [{
									text: 'change status',
									icon: 'Automation',
									action: handleChangeStatuses,
								}]}
						/>
						<div className="page-container">
							<Row gutter={24}>
								<Col span={12}>
									<div className={styles.cardContainer}>
										<div className={styles.title}>
											<h2>Card Details</h2>
											{card.cardDesign ? <img src={card.cardDesign.imageUrl} alt="bc" /> : <img src={bcLogo} alt="bc" />}
										</div>
										<TextCard data={cardDetailsData} colNumber={1} left="left-2" />
									</div>
								</Col>
								<Col span={12}>
									<div className={styles.cardContainer}>
										<div className={styles.title}>
											<h2>Primary Card Instance</h2>
										</div>
										<TextCard data={primaryCardData} colNumber={1} left="left-2" />
									</div>
								</Col>
							</Row>
							<Row>
								<CardInstances
									cardInstancesData={card.cardInstances}
									title="Card Instances"
									applicationId={applicationId}
								/>
							</Row>
							<Row>
								<LimitControls
									limitControlsData={spendControls.limitControls
										? spendControls.limitControls.map((el) => ({
											level: 'Card',
											limitControl: el,
										}))
										: []}
									title="Card Limit Controls"
									applicationId={applicationId}
									handleOpenDrawer={handleOpenDrawer}
								/>
							</Row>
							<Row>
								<AuthorizationControls
									authorizationControlsData={
										spendControls.authorizationControls
											? spendControls.authorizationControls
											: []
									}
									title="Card Authorization Controls"
									applicationId={applicationId}
									handleOpenDrawer={handleOpenDrawer}
								/>
							</Row>
							<Row>
								<Card className={styles.designCards} bordered={false}>
									<h2>Card Payments</h2>
									{!isFetchingAccountCardPayments
										? (
											<CardPaymentsList
												cardPayments={accountCardPayments}
												applicationId={applicationId}
												place="cardPage"
												totalCardPayments={accountCardsPaymentsTotal}
												handlePageChange={handlePageChange}
												pageSize={pageSize}
											/>
										) : <Spin spinning={isFetchingAccountCardPayments} />}
								</Card>
							</Row>
						</div>
						<Drawer
							width={600}
							placement="right"
							onClose={onClose}
							visible={drawerOpen}
							closable={false}
						>
							<ControlComponent controlData={drawerData} />
							<div className={styles.close}>
								<CPButton
									text="close"
									action={onClose}
								/>
							</div>
						</Drawer>
						<WarningModal
							title={warningTitle}
							visible={warningModal}
							cancelFunction={handleErrorCancel}
							footer={footer}
							message={message}
							success={success}
							loading={buttonLoading}
						/>
						<FormModal
							title="Change Card Status"
							visible={edit}
							cancelFunction={handleEditCancel}
							form={(
								<StatusForm
									submitChanges={continueEdit}
									handleCancelClick={handleEditCancel}
									wrappedComponentRef={formRef}
									status={card.status}
								/>
							)}
						/>
					</div>
				)
				: <Spin spinning={!isFetchingCard} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	card: state.cards.card,
	isFetchingCard: state.cards.isFetchingCard,
	accountCardPayments: state.cards.accountCardPayments,
	isFetchingAccountCardPayments: state.cards.isFetchingAccountCardPayments,
	spendControls: state.cards.spendControls,
	accountCardsPaymentsTotal: state.cards.accountCardsPaymentsTotal,
});
const mapDispatchToProps = {
	getCard,
	getAccountCardPayments,
	activateCard,
	changeStatus,
	getCardSpendControls,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPage);
