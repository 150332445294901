// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import Card from '../../../components/UiElements/Card';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import EditFeeForm from '../components/EditFeeForm';
//= import methods
import { getFees, updateFee, removeFee } from '../../../modules/actions/FeeActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { setActiveTab } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { Fee } from '../../../modules/reducers/FeeReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';

type FeesRes = {
	payload: {
		data: Array<Fee>,
	}
}
type FeeRes = {
	payload: {
		data: Fee,
	}
}

type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}
type Props = {
	getFees: (string, string) => Promise<Object>,
	updateFee: (string, string, Object) => Promise<Object>,
	removeFee: (string, string) => Promise<Object>,
	getProvidersInstances: (string) => Promise<Object>,
	providers: Array<ProviderInstance>,
	setActiveTab: (string, string) => void,
	match: {
		params: {
			applicationId: string,
			feeId: string,
			userId: string,
		},
	},
	location: {
		search: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}

const FeeDetails = (props: Props) => {
	const {
		match: { params: { applicationId, feeId, userId } },
		getProvidersInstances: getProvidersInstancesAction,
		getFees: getFeesAction,
		removeFee: removeFeeAction,
		updateFee: updateFeeAction,
		setActiveTab: setActiveTabAction,
		providers,
		history,
	} = props;

	const [fee, setFee] = useState({});
	const [edit, setEdit] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('');
	const [message, setMessage] = useState({ firtPart: 'Once you delete the Fee Rule you won&apos:t be able to retreive it.' });
	const [footer, setFooter] = useState([]);
	const [deleteClicked, setDeleteClicked] = useState(false);
	const [feeSet, setFeeSet] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		async function fetchData() {
			const param = queryString.stringify({ userId });
			await getProvidersInstancesAction(applicationId);
			const res: FeesRes = await getFeesAction(applicationId, param);
			const feeData: Fee = res.payload.data.filter((element) => element.id === feeId)[0];
			setFee(feeData);
			setFeeSet(true);
		}
		fetchData();
	}, [
		getProvidersInstancesAction,
		getFeesAction,
		applicationId,
		userId,
		feeId,
	]);

	const handleBackClick = () => {
		setActiveTabAction('userTab', 'settings');
		history.goBack();
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleWarningCancel = () => {
		setEdit(true);
		setWarningModal(false);
	};

	const handleDeleteCancel = () => {
		setWarningModal(false);
		setDeleteClicked(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response ? error.error.response.data.message : 'Error' });
		setFooter([
			{ type: 'cancel', action: handleDeleteCancel, text: 'close' },
		]);
	};

	// Delete fee rule

	const handleDeleteClick = () => {
		setDeleteClicked(true);
	};

	const deleteFeeRule = async () => {
		setDeleteClicked(false);
		try {
			await removeFeeAction(applicationId, feeId);
			history.goBack();
		} catch (error) { handleOnError(error); }
	};

	// Edit fee rule

	const handleEditClick = () => {
		setEdit(true);
	};

	const submitEditFee = async (data) => {
		setButtonLoading(true);
		const values = { ...data, userId };
		try {
			const res: FeeRes = await updateFeeAction(applicationId, fee.id, values);

			setFee(res.payload.data);
			setButtonLoading(false);
			setWarningModal(false);
		} catch (error) { handleOnError(error); }
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const handleEditCancel = () => {
		if (formRef.current) {
			if (formRef.current.props.form.isFieldsTouched()) {
				setEdit(false);
				setWarningModal(true);
				setWarningTitle('Warning!');
				setMessage({
					firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
				});
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
					{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
				]);
			} else {
				setEdit(false);
				setWarningModal(false);
			}
		}
	};

	const continueEditing = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect all Fee Rules below.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEditFee(value), text: 'edit fee' },
		]);
	};

	// Edit and Delete

	const feeSymbol = fee && (fee.instrumentId ? `All ${fee.instrumentId} Symbols` : 'All Symbols');
	const feeProvider = fee && (fee.providerInstanceId
			&& providers.find((el) => el.id === fee.providerInstanceId));
	const feeProviderName = feeProvider ? feeProvider.provider.displayName : 'All Payment Providers';
	const feeName = fee && (fee.type === 'trading'
		? `${fee.orderType || ''} ${fee.orderSide || ''} ${fee.symbolId ? fee.symbolId : feeSymbol}`
		: `${feeProviderName} & ${fee.instrumentId || 'All Instruments'}`);
	const percentageFee = fee && {
		percentageFee: fee.percentageFee ? `${PriceHelpers.formatNumber(fee.percentageFee, 2)}%` : '0.00%',
	};
	const netFee = fee && {
		netFee: fee.netFee ? PriceHelpers.formatAmount(
			fee.netFee,
			fee.instrumentId,
		) : '-',
	};
	const minFee = fee && {
		minFee: fee.minimumFee ? PriceHelpers.formatAmount(
			fee.minimumFee,
			fee.instrumentId,
		) : '-',
	};
	const maxFee = {
		maxFee: fee.maximumFee ? PriceHelpers.formatAmount(
			fee.maximumFee,
			fee.instrumentId,
		) : '-',
	};
	const instrumentData = {
		instrument: fee.instrumentId || 'All Instruments',
	};
	const symbolData = {
		symbol: fee.symbolId || 'All Symbols',
	};
	const liquidityProvider = {
		liquidityProvider: feeProviderName,
	};

	return (
		<>
			{feeSet
				? (
					<div>
						<Title
							backAction={handleBackClick}
							title={feeName}
							buttons={[
								{
									text: 'edit rule details',
									action: handleEditClick,
								},
								{
									type: 'danger',
									text: 'delete fee rule',
									action: handleDeleteClick,
								},
							]}
						/>
						<div className="page-container">
							{fee.userId
								&& (
									<Card bordered={false} className="fee-rules">
										<h2>User id</h2>
										<p>
											{fee.userId}
										</p>
									</Card>
								)}
							{fee.type === 'trading'
								? (
									<div>
										<div className="fee-cards trading-fee-cards">
											<InfoCard data={percentageFee} />
											<InfoCard data={fee.symbolId ? symbolData : instrumentData} />
											<InfoCard data={liquidityProvider} />
										</div>
										<div>
											<Row gutter={24} type="flex">
												<Col span={18} className="col-margin">
													<Card bordered={false} className="fee-rules">
														<h2>Defining Fee Rules</h2>
														<p>
															You can define only a Percentage Fee.
															A Net Fee is not allowed in any context for trading fees.
														</p>
														<p>
															The Instrument must be defined
															and is applied to all Base Instruments.
														</p>
														<p>
															There is no Minimum Fee set in any context for trading fees.
														</p>
														<p>
															There is no Maximum Fee set in any context for trading fees.
														</p>
													</Card>
												</Col>
											</Row>
										</div>
									</div>
								)
								: (
									<div>
										<div className="fee-cards">
											<InfoCard data={percentageFee} />
											<InfoCard data={netFee} />
											<InfoCard data={minFee} />
											<InfoCard data={maxFee} />
										</div>
										<div>
											<Row gutter={24} type="flex">
												<Col span={18} className="col-margin">
													<Card bordered={false} className="fee-rules">
														<h2>Defining Fee Rules</h2>
														<p>
															If both Percentage and Net Fee are defined, both are applied.
														</p>
														<p>
															If Instrument is not defined, you can only set Percentage Fee.
														</p>
														<p>
															When Minimum Fee is set,
															if the calculated Fee is less then Minimum set,
															Fee will be set to minimum value.
														</p>
														<p>
															When Maximum Fee is set, if the calculated Fee is greater
															then Maximum set, Fee will be set to maximum value.
														</p>
													</Card>
												</Col>
											</Row>
										</div>
									</div>
								)}
							<FormModal
								title={`Edit ${feeName}`}
								visible={edit}
								cancelFunction={handleEditCancel}
								form={(
									<EditFeeForm
										submitChanges={continueEditing}
										handleCancelClick={handleEditCancel}
										fee={fee}
										providers={providers}
										wrappedComponentRef={formRef}
										applicationId={applicationId}
									/>
								)}
							/>
							<WarningModal
								title={warningTitle}
								visible={warningModal}
								cancelFunction={handleDeleteCancel}
								footer={footer}
								message={message}
								loading={buttonLoading}
							/>
							<DeleteModal
								okFunction={deleteFeeRule}
								cancelFunction={handleDeleteCancel}
								visible={deleteClicked}
								btnText="Delete Fee Rule"
								message={`Once you delete the ${feeName} Fee Rule
									you won't be able to retrieve it.`}
							/>
						</div>
					</div>
				)
				: <Spin spinning={!feeSet} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	fees: state.fees.fees,
	providers: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getFees,
	updateFee,
	removeFee,
	getProvidersInstances,
	setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeeDetails);
