// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import components
import Spin from '../../../components/UiElements/Spin';
import ProgramCards from '../components/ProgramCards';
//= import actions
import { getPrograms, getProducts } from '../../../modules/actions/CardAction';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Program } from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	programs: Array<Program>,
	isFetchingPrograms: boolean,
	viewProgram: () => void,
	applicationId: string,
}

function Programs(props: Props) {
	const {
		programs,
		isFetchingPrograms,
		viewProgram,
	} = props;

	return (
		<div className={styles.cardContainer}>
			{!isFetchingPrograms
				? (
					<div>
						{programs.length
							? programs.map((el) => (
								<ProgramCards
									key={el.id}
									name={el.name}
									description={el.description}
									cardScheme={el.cardScheme}
									id={el.id}
									action={viewProgram}
								/>
							))
							: (
								<div className={styles.card}>
									<div className={styles.title}>
										<h2>
										Card module does not have any card programs associated with it.
										</h2>
									</div>
								</div>
							)}
					</div>
				)
				: <Spin spinning={isFetchingPrograms} />}
		</div>
	);
}

const mapStateToProps = (state: State) => ({
	programs: state.cards.programs,
	isFetchingPrograms: state.cards.isFetchingPrograms,
});
const mapDispatchToProps = {
	getProducts,
	getPrograms,
};

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
