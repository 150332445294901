// @flow

import {
	PAYMENTS,
	PAYMENT,
	MAKE_PAYMENT,
	APP_PAYMENTS,
	APPROVE_WITHDRAWAL_REQUEST,
	REJECT_WITHDRAWAL_REQUEST,
	WALLET_PAYMENTS,
	RECURRING_PAYMENT,
	RECURRING_PAYMENTS,
	CRYPTO_PAYOUT,
} from '../const/types';

export function getAccountPayments(accountId: string, page: number, parametar: string, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: PAYMENTS,
		payload: {
			request: {
				url: `/accounts/${accountId}/payments?${parametar}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function getWalletPayments(walletId: string, page: number, parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: WALLET_PAYMENTS,
		payload: {
			request: {
				url: `/wallets/${walletId}/payments?${parametar || ''}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getApplicationPayments(appId: string, page: number, parametar: string, orderBy?: string, asc?: string, pageSize?: number = 50) {
	const sort = orderBy || 'createdAt';
	const ascending = asc || 'false';
	return (dispatch: Function): void => dispatch({
		type: APP_PAYMENTS,
		payload: {
			request: {
				url: `/applications/${appId}/payments?${parametar}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
					'order-by': sort,
					'pagination-ascending': ascending,
				},
			},
		},
	});
}

export function getPayment(applicationId: string, paymentId: string) {
	return (dispatch: Function): void => dispatch({
		type: PAYMENT,
		payload: {
			request: {
				url: `/applications/${applicationId}/payments/${paymentId}`,
			},
		},
	});
}

export function makePayment(walletId: string, amount: string) {
	return (dispatch: Function): void => dispatch({
		type: MAKE_PAYMENT,
		payload: {
			request: {
				method: 'POST',
				url: `/wallets/${walletId}/deposit`,
				data: amount,
			},
		},
	});
}


export function approveWithdrawalRequest(withdrawalRequestId: string) {
	return (dispatch: Function): void => dispatch({
		type: APPROVE_WITHDRAWAL_REQUEST,
		payload: {
			request: {
				method: 'PATCH',
				url: `/withdrawal-request/${withdrawalRequestId}/approve`,
			},
		},
	});
}

export function rejectWithdrawalRequest(withdrawalRequestId: string) {
	return (dispatch: Function): void => dispatch({
		type: REJECT_WITHDRAWAL_REQUEST,
		payload: {
			request: {
				method: 'PATCH',
				url: `/withdrawal-request/${withdrawalRequestId}/reject`,
			},
		},
	});
}

export function getRecurringPayment(accountId: string, recurrenceId: string) {
	return (dispatch: Function): void => dispatch({
		type: RECURRING_PAYMENT,
		payload: {
			request: {
				url: `/accounts/${accountId}/recurring-deposits/${recurrenceId}`,
			},
		},
	});
}

export function getRecurringPayments(accountId: string) {
	return (dispatch: Function): void => dispatch({
		type: RECURRING_PAYMENTS,
		payload: {
			request: {
				url: `/accounts/${accountId}/recurring-deposits`,
			},
		},
	});
}

export function cryptoPayout(walletId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: CRYPTO_PAYOUT,
		payload: {
			request: {
				method: 'POST',
				url: `/wallets/${walletId}/crypto-payout`,
				data,
			},
		},
	});
}
