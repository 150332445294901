// @flow

import React, { useState } from 'react';
//= import components
import CPButton from '../../../Button';
import Dropdown from '../../../Dropdown';
import PopoverContent from './PopoverContent';
import ChangePasswordModal from './ChangePasswordModal';
import TwoFactorSettingsModal from './TwoFactorSettingsModal';
//= create constants
const appLogo = require('../../../../../assets/img/company_icon_dark.png');

type Props = {
	userName: string,
	oneApp: boolean,
}

function TopBarActions(props: Props) {
	const [down, setDown] = useState(true);
	const [visibleModal, setVisibleModal] = useState(false);
	const [visible2FA, setVisible2FA] = useState(false);
	
	const closeModal = (type) => {
		type === 'two-factor'
			? setVisible2FA(false)
			: setVisibleModal(false)
	}
	const openModal = (type) => {
		type === 'two-factor'
			? setVisible2FA(true)
			: setVisibleModal(true)
	}

	const { userName, oneApp } = props;

	const openChangePassword = () => {
		openModal();
		setDown(true);
	};

	const openTwoFactor = () => {
		openModal('two-factor');
		setDown(true);
	}

	return (
		<div className="topbar-actions">
			<Dropdown
				overlay={<PopoverContent oneApp={oneApp} actions={{ openChangePassword, openTwoFactor }} />}
				trigger={['click']}
				visible={!down}
				onVisibleChange={() => setDown(!down)}
			>
				<div className="dropdown-content">
					<span>{userName}</span>
					<img src={appLogo} alt={userName} className="app-logo" />
					<CPButton ghost icon={down ? 'CaretDown' : 'CaretUp'} />
				</div>
			</Dropdown>
			<ChangePasswordModal visible={visibleModal} closeModal={closeModal} />
			<TwoFactorSettingsModal visible={visible2FA} closeModal={closeModal} />
		</div>
	);
}
export default TopBarActions;
