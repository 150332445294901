// @flow

const initialState = {
	group: {},
	isFetchingGroup: false,
	isFetchedGroup: false,
	groups: [],
	isFetchingGroups: false,
	isFetchedGroups: false,
	addingGroup: false,
	groupAdded: false,
	updatingGroup: false,
	groupUpdated: false,
	isMoving: false,
	isMoved: false,
	groupUsersTotal: 0,
};

export type Group = {
	id: string,
	name: string,
	description: string,
	default: boolean,
	applicationId: string,
	createdAt: string,
	updatedAt: string,
	deletedAt: string,
}

export type GroupState = {
	group: Group,
	isFetchingGroup: boolean,
	isFetchedGroup: boolean,
	groups: Array<Group>,
	isFetchingGroups: boolean,
	isFetchedGroups: boolean,
	addingGroup: boolean,
	groupAdded: boolean,
	updatingGroup: boolean,
	groupUpdated: boolean,
	isMoving: boolean,
	isMoved: boolean,
	groupUsersTotal: number,
}
type GroupAction = {
	type:
	| 'GROUP'
	| 'GROUP_FAIL'
	| 'GROUP_SUCCESS'
	| 'ADD_GROUP'
	| 'ADD_GROUP_FAIL'
	| 'ADD_GROUP_SUCCESS'
	| 'UPDATE_GROUP'
	| 'UPDATE_GROUP_FAIL'
	| 'UPDATE_GROUP_SUCCESS'
	| 'MOVE_USER_TO_GROUP'
	| 'MOVE_USER_TO_GROUP_FAIL'
	| 'MOVE_USER_TO_GROUP_SUCCESS',
	payload: {
		data: Group,
		headers: {
			'total-count': number,
		}
	},
	error: Object,
}
type GroupsAction = {
	type:
	| 'GROUPS'
	| 'GROUPS_FAIL'
	| 'GROUPS_SUCCESS',
	payload: {
		data: Array<Group>,
	},
	error: {
		code: number,
		message: string,
	}
}
type Action = GroupAction | GroupsAction;

function groupReducer(state: GroupState = initialState, action: Action): GroupState {
	switch (action.type) {
	case 'GROUP':
		return {
			...state,
			isFetchingGroup: true,
			isFetchedGroup: false,
		};
	case 'GROUP_FAIL':
		return {
			...state,
			isFetchingGroup: false,
			isFetchedGroup: false,
		};
	case 'GROUP_SUCCESS':
		return {
			...state,
			isFetchingGroup: false,
			isFetchedGroup: true,
			group: action.payload.data,
		};
	case 'GROUPS':
		return {
			...state,
			isFetchingGroups: true,
			isFetchedGroups: false,
		};
	case 'GROUPS_FAIL':
		return {
			...state,
			isFetchingGroup: false,
			isFetchedGroup: false,
		};
	case 'GROUPS_SUCCESS':
		return {
			...state,
			isFetchingGroups: false,
			isFetchedGroups: true,
			groups: action.payload.data,
		};
	case 'ADD_GROUP':
		return {
			...state,
			addingGroup: true,
			groupAdded: false,
		};
	case 'ADD_GROUP_FAIL':
		return {
			...state,
			addingGroup: false,
			groupAdded: false,
			error: true,
		};
	case 'ADD_GROUP_SUCCESS':
		return {
			...state,
			addingGroup: false,
			groupAdded: true,
			group: action.payload.data,
			groups: [...state.groups, action.payload.data],
		};
	case 'UPDATE_GROUP':
		return {
			...state,
			updatingGroup: true,
			groupUpdated: false,
		};
	case 'UPDATE_GROUP_FAIL':
		return {
			...state,
			updatingGroup: false,
			groupUpdated: false,
		};
	case 'UPDATE_GROUP_SUCCESS':
		return {
			...state,
			updatingGroup: false,
			groupUpdated: true,
			group: action.payload.data,
			groups: state.groups.map((group) => {
				if (group.id === action.payload.data.id) {
					return action.payload.data;
				}
				return group;
			}),
		};
	case 'MOVE_USER_TO_GROUP':
		return {
			...state,
			isMoving: true,
			isMoved: false,
		};
	case 'MOVE_USER_TO_GROUP_FAIL':
		return {
			...state,
			isMoving: false,
			isMoved: false,
		};
	case 'MOVE_USER_TO_GROUP_SUCCESS':
		return {
			...state,
			isMoving: false,
			isMoved: true,
		};
	default:
		return state;
	}
}

export default groupReducer;
