// @flow

const initialState = {
	error: false,
	isFetchingOrders: false,
	isFetchedOrders: false,
	orders: [],
	ordersTotal: 0,
	canceling: false,
	isCanceled: false,
	isFetchingOrder: false,
	isFetchedOrder: false,
	order: {},
	isFetchingAppOrders: false,
	isFetchedAppOrders: true,
	appOrders: [],
	appOrdersTotal: 0,
	isFetchingRecurringOrders: false,
	isFetchedRecurringOrders: false,
	recurringOrders: [],
	isFetchingRecurringOrder: false,
	isFetchedRecurringOrder: false,
	recurringOrder: {},
};

export type WalletOrderTransaction = {
	amount: string,
	available: string,
	availableBefore: string,
	createdAt: string,
	debt: string,
	debtBefore: string,
	id: string,
	instrumentId: string,
	reference: string,
	referenceId: string,
	reserved: string,
	reservedBefore: string,
	total: string,
	totalBefore: string,
	type: string,
	updatedAt: string,
	walletId: string,
	type: string,
}
export type OrderTransaction = {
	createdAt: string,
	id: string,
	orderId: string,
	price: string,
	quantity: string,
	updatedAt: string,
}
export type FullOrder = {
	id: string,
	status: string,
	type: string,
	side: string,
	recurrenceId: ?string,
	accountId: string,
	symbolId: string,
	baseInstrumentId: string,
	quotingInstrumentId: string,
	tradingProviderId: string,
	createdAt: string,
	placedOnExchangeAt: string,
	executedAt: string,
	user: {
		id: string,
		firstName: string,
		lastName: string,
	},
	orderCommand: {
		id: string,
		quantity: string,
		createdAt: string,
		updatedAt: string,
		amount: string,
		price: string,
		pricePrecision: number,
	},
	executionSummary: {
		quantityExecuted: string,
		averageTransactionPrice: string,
		orderFee: string,
		orderAmount: string,
		id: string,
	},
	reservationInfo: {
		price: string,
		quantity: string,
	},
	wallet: {
		id: string,
		instrumentId: string,
	},
	walletId: string,
	walletTransactions: Array<WalletOrderTransaction>,
	orderTransactions: Array<OrderTransaction>,
	errorMessage: ?string,
	providerLogs: Array<Object>,
}
export type Order = {
	id: string,
	status: string,
	type: string,
	side: string,
	recurrenceId: ?string,
	accountId: string,
	symbolId: string,
	baseInstrumentId: string,
	quotingInstrumentId: string,
	createdAt: string,
	executedAt: string,
	user: {
		id: string,
		firstName: string,
		lastName: string,
	},
	orderCommand: {
		id: string,
		quantity: string,
		createdAt: string,
		updatedAt: string,
		amount: string,
		price: string,
		pricePrecision: number,
	},
	executionSummary: {
		quantityExecuted: string,
		averageTransactionPrice: string,
		orderFee: string,
		orderAmount: string,
		id: string,
	},
	reservationInfo: {
		price: string,
		quantity: string,
	},
	wallet: {
		id: string,
		instrumentId: string,
	},
}
export type RecurringOrder = {
	accountId: string,
	billingCycleAnchor: string,
	canceledAt: string,
	createdAt: string,
	currentPeriodEnd: string,
	currentPeriodStart: string,
	endedAt: string,
	id: string,
	interval: string,
	intervalCount: number,
	orders: Array<Order>,
	reference: string,
	referenceId: string,
	repeatCount: number,
	repeatUntil: string,
	repeatedSoFar: number,
	updatedAt: string,
}
export type RecurringOrders = Array<RecurringOrder>
export type Orders = Array<Order>

export type OrderState = {
	error: Object | boolean,
	isFetchingOrders: boolean,
	isFetchedOrders: boolean,
	orders: Orders,
	ordersTotal: number,
	canceling: boolean,
	isCanceled: boolean,
	isFetchingOrder: boolean,
	isFetchedOrder: boolean,
	order: Order,
	isFetchingAppOrders: boolean,
	isFetchedAppOrders: boolean,
	appOrders: Orders,
	appOrdersTotal: number,
	isFetchingRecurringOrders: boolean,
	isFetchedRecurringOrders: boolean,
	recurringOrders: Array<RecurringOrder>,
	isFetchingRecurringOrder: boolean,
	isFetchedRecurringOrder: boolean,
	recurringOrder: RecurringOrder,
}

type OrderAction = {
	type:
	| 'ORDER'
	| 'ORDER_SUCCESS'
	| 'ORDER_FAIL'
	| 'CANCEL_ORDER'
	| 'CANCEL_ORDER_SUCCESS'
	| 'CANCEL_ORDER_FAIL'
	| 'PLACE_ORDER'
	| 'PLACE_ORDER_SUCCESS'
	| 'PLACE_ORDER_FAIL',
	payload: {
		data: FullOrder,
	},
	error: {
		code: number,
		message: string,
	}
}
type OrdersAction = {
	type:
	| 'ORDERS'
	| 'ORDERS_SUCCESS'
	| 'ORDERS_FAIL'
	| 'APPLICATION_ORDERS'
	| 'APPLICATION_ORDERS_SUCCESS'
	| 'APPLICATION_ORDERS_FAIL',
	payload: {
		data: Orders,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
type RecuringOrderAction = {
	type:
	| 'RECURRING_ORDER'
	| 'RECURRING_ORDER_SUCCESS'
	| 'RECURRING_ORDER_FAIL',
	payload: {
		data: RecurringOrder,
	},
	error: {
		code: number,
		message: string,
	}
}
export type Action = OrderAction | OrdersAction | RecuringOrderAction;

export default function orderReducer(
	state: OrderState = initialState,
	action: Action,
): OrderState {
	switch (action.type) {
	case 'ORDERS':
		return {
			...state,
			isFetchingOrders: true,
			isFetchedOrders: false,
			error: false,
		};
	case 'ORDERS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingOrders: false,
			isFetchedOrders: true,
			isFetchedAppOrders: true,
			orders: action.payload.data,
			ordersTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'ORDERS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingOrder: false,
			isFetchedOrder: false,
		};
	}
	case 'ORDER': {
		return {
			...state,
			isFetchingOrder: true,
			isFetchedOrder: false,
			error: false,
		};
	}
	case 'ORDER_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingOrder: false,
			isFetchedOrder: true,
			order: action.payload.data,
		};
	}
	case 'ORDER_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingOrders: false,
			isFetchedOrders: false,
		};
	}
	case 'CANCEL_ORDER': {
		return {
			...state,
			canceling: true,
			isCanceled: false,
			error: false,
		};
	}
	case 'CANCEL_ORDER_SUCCESS': {
		return {
			...state,
			canceling: false,
			isCanceled: true,
			error: false,
			order: action.payload.data,
		};
	}
	case 'CANCEL_ORDER_FAIL': {
		return {
			...state,
			canceling: false,
			isCanceled: false,
			error: action.error,
		};
	}
	case 'APPLICATION_ORDERS': {
		return {
			...state,
			isFetchingAppOrders: true,
			isFetchedAppOrders: false,
			error: false,
		};
	}
	case 'APPLICATION_ORDERS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingAppOrders: false,
			isFetchedAppOrders: true,
			appOrders: action.payload.data,
			appOrdersTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'APPLICATION_ORDERS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingAppOrders: false,
			isFetchedAppOrdera: false,
		};
	}
	case 'RECURRING_ORDER':
		return {
			...state,
			isFetchingRecurringOrder: true,
			isFetchedRecurringOrder: false,
			error: false,
		};
	case 'RECURRING_ORDER_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingRecurringOrder: false,
			isFetchedRecurringOrder: true,
			recurringOrder: action.payload.data,
		};
	}
	case 'RECURRING_ORDER_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingRecurringOrder: false,
			isFetchedRecurringOrder: false,
		};
	}
	case 'PLACE_ORDER': {
		return {
			...state,
			isFetchingOrder: true,
			order: {},
			error: false,
		};
	}
	case 'PLACE_ORDER_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingOrder: false,
			order: action.payload.data,
			orders: [...state.orders, action.payload.data],
		};
	}
	case 'PLACE_ORDER_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingOrders: false,
		};
	}
	default:
		return state;
	}
}
