// @flow

import React from 'react';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Radio from '../../../components/UiElements/Radio';
import Select from '../../../components/UiElements/Select';
import Upload from '../../../components/UiElements/Upload';
import Block from '../../../components/UiElements/Illustrations/Block';
import Carousel from '../../../components/UiElements/Illustrations/Carousel';
import List from '../../../components/UiElements/Illustrations/List';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import types
import type { Section } from '../../../modules/reducers/SectionReducer';
import type { ExploreGroup } from '../../../modules/reducers/ExploreGroupReducer';

type Props = {
	form: *,
	place: string,
	submitChanges: Function,
	handleCancelClick: Function,
	applicationId: string,
	lowestRank: number,
	data: Section | ExploreGroup,
	sections: Array<Section>,
	type: string,
	icon: string,
	coverPhoto: string,
}
type LocalState = {
	errorMessage: string,
	iconList: Array<Object>,
	coverList: Array<Object>,
	selected: string,
	iconError: string,
	coverError: string,
	showOldIcon: boolean,
	showOldCover: boolean,
}
type Values = {
	name: string,
	description: string,
	coverPhoto: {
		file: {
			response: {
				url: string,
			}
		}
	},
	icon: {
		file: {
			response: {
				url: string,
			}
		}
	},
	display: string,
	order: number,
	sectionId?: string,
}

class GroupAndSection extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		iconList: [],
		coverList: [],
		selected: this.props.data.display,
		iconError: '',
		coverError: '',
		showOldIcon: true,
		showOldCover: true,
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			iconList: [],
			coverList: [],
			selected: this.props.data.display,
			iconError: '',
			coverError: '',
			showOldIcon: true,
			showOldCover: true,
		});
	}

	onChangeDisplay = (e) => {
		this.setState({
			selected: e.target.value,
		});
	}

	beforeUploadIcon = (file) => {
		if (file.size > 1300000) {
			this.setState({
				iconError: 'Selected image is too large, please choose a different one',
				showOldIcon: false,
			});
			return false;
		}
		if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
			this.setState({
				iconError: 'Upload Icon in a PNG, JPG or GIF format',
				showOldIcon: false,
			});
			return false;
		}
		this.setState({
			iconError: '',
			showOldIcon: false,
		});
		return true;
	};

	beforeUploadCover = (file) => {
		if (file.size > 1300000) {
			this.setState({
				coverError: 'Selected image is too large, please choose a different one',
				showOldCover: false,
			});
			return false;
		}
		if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
			this.setState({
				coverError: 'Upload Icon in a PNG, JPG or GIF format',
				showOldCover: false,
			});
			return false;
		}
		this.setState({
			coverError: '',
			showOldCover: false,
		});
		return true;
	};

	handleIconChange = (info) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		this.setState({
			iconList: fileList,
		});
	};

	handleCoverChange = (info) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		this.setState({
			coverList: fileList,
		});
	};

	handleRemoveIcon = () => {
		this.setState({
			showOldIcon: true,
			iconError: '',
		});
	}

	handleRemoveCover = () => {
		this.setState({
			showOldCover: true,
			coverError: '',
		});
	}

	validateOrder = (rule, value, callback) => {
		const { sections } = this.props;
		if (!value) {
			callback();
		}
		if (value > sections.length + 1 || value < 1) {
			callback(`The Explore Section Order must be less than or equal to ${sections.length + 1} and greater than or equal to 1`);
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const {
			form, submitChanges, sections, place,
		} = this.props;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					name: form.isFieldTouched('name') && values.name,
					description: form.isFieldTouched('description') && values.description,
					display: values.display || 'list',
					icon: form.isFieldTouched('icon') && (values.icon
						? values.icon.file?.response?.url
						: this.props.data.icon),
					coverPhoto: form.isFieldTouched('coverPhoto') && (values.coverPhoto
						? values.coverPhoto.file?.response?.url
						: this.props.data.coverPhoto),
					order: place === 'Section' ? values.order || sections.length + 1 : undefined,
				};
				const { sectionId } = values;
				submitChanges(data, sectionId);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched },
			handleCancelClick,
			type,
			data,
			icon,
			coverPhoto,
			place,
			sections,
		} = this.props;
		const {
			errorMessage, selected, iconList, coverList, iconError, coverError, showOldIcon, showOldCover,
		} = this.state;
		const FormItem = Form.Item;
		const { Option } = Select;
		const formLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
			},
		};
		const orderLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const oAuthData = localStorage.getItem('@Auth:auth');
		const accessToken = oAuthData ? JSON.parse(oAuthData).accessToken : undefined;
		const nameData = localStorage.getItem('@Auth:appName');
		const uploadProps = {
			name: 'file',
			action: process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1/files` : '',
			data: { type: 'image' },
			headers: {
				Authorization: accessToken ? `Bearer ${accessToken}` : '',
				'X-Requested-With': null,
				'X-Tradecore-App-Name': nameData,
			},
			listType: 'picture',
			accept: '.jpg, .jpeg, .png',
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left" className="group-form">
				<div>
					<Form.Item label="NAME" {...formLayout} help="">
						{getFieldDecorator('name', {
							rules: [{
								required: true,
								message: `Please input ${place} Name!`,
							}],
							initialValue: type === 'create' ? '' : data.name,
						})(
							<Input
								name="name"
								placeholder={`Define ${place} Name`}
							/>,
						)}
					</Form.Item>
					<Form.Item label="DESCRIPTION" {...formLayout} help="">
						{getFieldDecorator('description', {
							initialValue: type === 'create' ? '' : data.description,
						})(
							<Input.TextArea
								name="description"
								placeholder={`Add ${place} Description`}
								maxLength={400}
							/>,
						)}
					</Form.Item>
					{type === 'create' && place === 'Group' && (
						<Form.Item label="BELONGS TO SECTION" {...formLayout}>
							{getFieldDecorator('sectionId')(
								<Select
									allowClear
									showSearch
									placeholder="Select Section"
								>
									{sections.map((section) => (
										<Option value={section.id} key={section.id}>{section.name}</Option>
									))}
								</Select>,
							)}
						</Form.Item>
					)}
				</div>
				<h4>
					{`${place} Display`}
				</h4>
				<p>
					{`Choose how items of your ${place} will be displayed. `}
					If You don&apos;t choose anything, the List View will be displayed by default.
				</p>
				<Form.Item>
					{getFieldDecorator('display', {
						initialValue: data.display,
					})(
						<Radio.Group onChange={this.onChangeDisplay}>
							<Radio value="carousel">
								<div>
									<Carousel
										color={selected === 'carousel' ? '#7266BA' : '#E3E4E5'}
										background={selected === 'carousel' ? '#F9F9FB' : 'none'}
									/>
								</div>
								<span>
									Carousel
								</span>
							</Radio>
							<Radio value="list">
								<div>
									<List
										color={selected === 'list' ? '#7266BA' : '#E3E4E5'}
										background={selected === 'list' ? '#F9F9FB' : 'none'}
									/>
								</div>
								<span>
									List (Default)
								</span>
							</Radio>
							<Radio value="blocks">
								<div>
									<Block
										color={selected === 'blocks' ? '#7266BA' : '#E3E4E5'}
										background={selected === 'blocks' ? '#F9F9FB' : 'none'}
									/>
								</div>
								<span>
									Block
								</span>
							</Radio>
						</Radio.Group>,
					)}
				</Form.Item>
				<h4 className="capitalize">
					{`${place} Images`}
				</h4>
				<p>
					{`You can add images to your ${place}. (optional).`}
				</p>
				<div className="upload">
					<h4>
						{`${place} Icon`}
					</h4>
					<p>
						{`A simple icon that appears next to your ${place}'s name.`}
					</p>
					<p>
						Upload an Icon in PNG, JPG or GIF format, with a maximum size of 4MB.
					</p>
					<div className="upload-img">
						<Form.Item>
							{getFieldDecorator('icon', {
								valuePropName: 'icon',
							})(
								<Upload
									{...uploadProps}
									fileList={iconList}
									onChange={this.handleIconChange}
									beforeUpload={this.beforeUploadIcon}
									className={iconError ? 'upload-error' : ''}
									onRemove={this.handleRemoveIcon}
								>
									<CPButton
										ghost
										icon="Upload"
										text="upload new icon"
									/>
								</Upload>,
							)}
						</Form.Item>
						{type !== 'create' && icon && showOldIcon && <img src={icon} alt="icon" className="icon" />}
					</div>
					{iconError && (
						<div className="upload-error">
							{type === 'create' ? iconError : `${iconError} or previous selection will remain.`}
						</div>
					)}
					<h4>
						Cover Photo
					</h4>
					<p>
						{`A more complex graphic that appears on the top of the ${place} Screen.`}
					</p>
					<p>
						Upload an Icon in PNG or JPG format, with a maximum size of 4MB.
					</p>
					<div className="upload-img">
						<Form.Item>
							{getFieldDecorator('coverPhoto', {
								valuePropName: 'coverPhoto',
							})(
								<Upload
									{...uploadProps}
									fileList={coverList}
									onChange={this.handleCoverChange}
									beforeUpload={this.beforeUploadCover}
									className={coverError ? 'upload-error' : ''}
									onRemove={this.handleRemoveCover}
								>
									<CPButton
										ghost
										icon="Upload"
										text="upload new cover"
									/>
								</Upload>,
							)}
						</Form.Item>
						{type !== 'create' && coverPhoto && showOldCover && <img src={coverPhoto} alt="cover" className="cover" />}
					</div>
					{coverError && (
						<div className="upload-error">
							{type === 'create' ? coverError : `${coverError} or previous selection will remain.`}
						</div>
					)}
				</div>
				{place === 'Section' && (
					<div>
						<h4>
							Explore Section Order
						</h4>
						<p>
							Set the order of a Section&apos;s appearance in the Explore page.
							If you don&apos;t set the order, the Section will appear last.
							If another Section has the same order as the one you set,
							change this or the order of that Section.
						</p>
						<FormItem label="ORDER" {...orderLayout}>
							{getFieldDecorator('order', {
								initialValue: data.order,
								rules: [
									{ validator: this.validateOrder },
								],
							})(
								<InputNumber
									placeholder="Set Explore Section Order"
									type="number"
								/>,
							)}
						</FormItem>
					</div>
				)}
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton
							type="primary"
							action={this.submit}
							disabled={!isFieldsTouched()}
							text={type === 'create' ? `create ${place}` : `edit ${place}`}
						/>
					</FormItem>
				</div>
			</Form>
		);
	}
}

const GroupAndSectionForm = Form.create()(GroupAndSection);

export default GroupAndSectionForm;
