// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import componnets
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import Icon from '../../../components/UiElements/Icon';
//= import types
import type { ExploreGroup } from '../../../modules/reducers/ExploreGroupReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	handleEditClick: (ExploreGroup) => void,
	exploreGroups: Array<ExploreGroup>,
	isFetchingExploreGroups: boolean,
	applicationId: string,
}

const ExploreGroupsPage = (props: Props) => {
	const {
		exploreGroups,
		isFetchingExploreGroups,
		applicationId,
		handleEditClick,
	} = props;

	const columns = [{
		title: 'created at',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 288,
		render: (text: string) => (
			<div>
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'name',
		dataIndex: 'name',
		key: 'name',
		width: 288,
		render: (text: string, record: ExploreGroup) => (
			<Link
				to={`/application/${applicationId}/system/explore/group/${record.id}`}
			>
				{text}
			</Link>
		),
	}, {
		title: 'display',
		dataIndex: 'display',
		key: 'display',
		width: 288,
		render: (text: string) => (
			<div className="capitalize">
				{text}
			</div>
		),
	}, {
		title: 'type',
		dataIndex: 'type',
		key: 'type',
		width: 288,
		render: (text: string) => (
			<div className="capitalize">
				{text}
			</div>
		),
	}, {
		title: 'updated at',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		width: 288,
		render: (text: string) => (
			<div>
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'edit',
		dataIndex: '',
		key: 'action',
		width: 80,
		render: () => (
			<Icon name="Edit" />
		),
		onCell: (record: ExploreGroup) => ({
			onClick: (e) => {
				e.stopPropagation();
				handleEditClick(record);
			},
		}),
	}];

	return (
		<div className="container">
			{!isFetchingExploreGroups
				? (
					<CustomTable
						columns={columns}
						data={exploreGroups}
						total={exploreGroups.length}
						currentPage={1}
						headerHeight={136}
						place="exploreGroups"
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingExploreGroups} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	exploreGroups: state.exploreGroup.exploreGroups,
	isFetchingExploreGroups: state.exploreGroup.isFetchingExploreGroups,
});

export default connect(mapStateToProps)(ExploreGroupsPage);
