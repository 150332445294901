import React from 'react';
import * as icons from './components';
// import styles
import styles from './assets/icon.module.scss';

type PropTypes = {
	name?: string,
	colorName?: string,
	size?: number,
	onClick?: Function,
	style?: { [string]: string }
};

const Icon = (props: PropTypes) => {
	const {
		name, size, colorName, onClick, style,
	} = props;

	const IconComponent = icons[name] || icons.Star;

	return (
		<span
			onClick={onClick}
			role="presentation"
			className={`${styles.icon} ${styles[colorName]}`}
			style={style}
		>
			<svg width={size} height={size} viewBox="0 0 24 24" version="1.1">
				<defs />
				<IconComponent />
			</svg>
		</span>

	);
};

Icon.defaultProps = {
	name: 'Star',
	size: 16,
	colorName: 'default',
	onClick: null,
	style: {},
};

export default Icon;
