// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Spin from '../../../components/UiElements/Spin';
import Filter from '../../../components/UiElements/Filter';
import CPButton from '../../../components/UiElements/Button';
import ApplicationOrdersTable from '../../Orders/components/OrdersTable';
//= import methods
import { setCurrentPage } from '../../../modules/actions/UiActions';
import { getOrders } from '../../../modules/actions/OrderActions';
import { getProviders } from '../../../modules/actions/ProviderActions';
//= import selectors
import { ordersFormattedSelector } from '../../../modules/selectors/OrdersSelector';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { FormattedOrders } from '../../../modules/selectors/OrdersSelector';
import type { Provider } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	orders: Array<FormattedOrders>,
	ordersTotal: number,
	isFetchingOrders: boolean,
	orderPage: number,
	accountId: string,
	applicationId: string,
	ordersFilters: { [string]: Array<string> },
	getOrders: (string, number, number, string) => void,
	setCurrentPage: (string, number) => void,
	getProviders: () => Promise<Object>,
	providers: Array<Provider>,
	pageSize: string,
}

const OrderTab = (props: Props) => {
	const {
		orders,
		ordersTotal,
		orderPage,
		accountId,
		applicationId,
		ordersFilters,
		isFetchingOrders,
		providers,
		setCurrentPage: setCurrentPageAction,
		getOrders: getOrdersAction,
		getProviders: getProvidersAction,
		pageSize,
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [date, setDate] = useState(false);

	useEffect(() => {
		if (!providers.length) {
			getProvidersAction();
		}
	}, [getProvidersAction, applicationId, providers]);

	const symbolFilter: Array<string> = ordersFilters.symbolId;
	const fixedSymbols: Array<string> = symbolFilter?.map((el) => el.toUpperCase());
	const filters = { ...ordersFilters, symbolId: fixedSymbols };

	useEffect(() => {
		getOrdersAction(accountId, orderPage, pageSize, queryString.stringify(filters, { arrayFormat: 'comma' }));
		// eslint-disable-next-line
	}, [orderPage, accountId, getOrdersAction, pageSize]);

	const handleOrdersPageChange = (page: number, pageSize: number) => {
		setCurrentPageAction('orderPage', page);
		getOrdersAction(accountId, orderPage, pageSize, queryString.stringify(filters, { arrayFormat: 'comma' }));
	};

	return (
		<div>
			<div className="logs">
				<CPButton
					action={() => { setFilterVisible(true); setDate(true); }}
					icon="CalendarDate"
					text="date"
				/>
				<CPButton
					action={() => setFilterVisible(true)}
					text="filter"
					icon="Funnel"
				/>
			</div>
			<Filter
				fields={{
					type: StatusHelpers.orderTypes,
					status: StatusHelpers.orderStatus,
					side: StatusHelpers.orderSide,
					symbolId: 'input',
					dateRange: 'time',
				}}
				date={date}
				visible={filterVisible}
				place="orders"
				position={432}
				closeFunction={() => { setFilterVisible(false); setDate(false); }}
			/>
			{!isFetchingOrders
				? (
					<ApplicationOrdersTable
						orders={orders}
						ordersTotal={ordersTotal}
						currentPage={orderPage}
						handleOrdersPageChange={handleOrdersPageChange}
						headerHeight={208}
						applicationId={applicationId}
						pageSize={pageSize}
						place="userOrders"
						providers={providers}
					/>
				) : <Spin spinning={isFetchingOrders} position="spin-relative" />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	isFetchingOrders: state.orders.isFetchingOrders,
	orders: ordersFormattedSelector(state, 'orders'),
	ordersTotal: state.orders.ordersTotal,
	orderPage: state.ui.orderPage,
	ordersFilters: state.ui.ordersFilters,
	symbols: state.symbol.symbols,
	providers: state.providers.providers,
	pageSize: state.ui.userOrdersPageSize,
});

const mapDispatchToprops = {
	getOrders,
	setCurrentPage,
	getProviders,
};

export default connect(mapStateToProps, mapDispatchToprops)(OrderTab);
