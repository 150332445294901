// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
//= import componnets
import CPButton from '../../../components/UiElements/Button';
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Card from '../../../components/UiElements/Card';
import Table from '../../../components/UiElements/Table';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import Block from '../../../components/UiElements/Illustrations/Block';
import Carousel from '../../../components/UiElements/Illustrations/Carousel';
import List from '../../../components/UiElements/Illustrations/List';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import IconPlaceholder from '../../../components/UiElements/Illustrations/IconPlaceholder';
import CoverPlaceholder from '../../../components/UiElements/Illustrations/CoverPlaceholder';
import AddToForm from '../../../components/UiElements/Form/AddToForm';
import GroupAndSectionForm from '../components/GroupAndSectionForm';
//= import methods
import {
	getSection, getSections, updateSection, removeSection, addGroupToSection, removeGroupFromSection,
} from '../../../modules/actions/SectionActions';
import { getExploreGroups } from '../../../modules/actions/ExploreGroupActions';
import getFile from '../../../modules/actions/FilesActions';
//= import types
import type { Section } from '../../../modules/reducers/SectionReducer';
import type { ExploreGroup } from '../../../modules/reducers/ExploreGroupReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getSection: (string, string) => Promise<Object>,
	getSections: (string) => Promise<Object>,
	updateSection: (string, string, Object) => Promise<Object>,
	removeSection: (string, string) => Promise<Object>,
	addGroupToSection: (string, string, string) => Promise<Object>,
	removeGroupFromSection: (string, string, string) => Promise<Object>,
	getFile: (string) => Promise<Object>,
	getExploreGroups: (string) => void,
	section: Section,
	sections: Array<Section>,
	exploreGroups: Array<ExploreGroup>,
	match: {
		params: {
			applicationId: string,
			sectionId: string,
		},
	},
	location: {
		search: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}
type LocalState = {
	edit: boolean,
	warningModal: boolean,
	warningTitle: string,
	message: { [string]: Object },
	deleteMessage: string,
	footer: Array<Object>,
	deleteClicked: boolean,
	icon: string,
	coverPhoto: string,
	groupData: Array<Object>,
	addGroup: boolean,
	btnText: string,
	remove: boolean,
	groupId: string,
	resetForm: boolean,
	buttonLoading: boolean,
}
type Response = {
	payload: {
		data: {
			icon: string,
			coverPhoto: string,
			url: string,
			exploreGroups: Array<Object>,
		}
	}
}
type Error = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

class SectionDetails extends React.Component<Props, LocalState> {
	state = {
		edit: false,
		warningModal: false,
		warningTitle: '',
		message: {},
		deleteMessage: '',
		footer: [],
		deleteClicked: false,
		icon: '',
		coverPhoto: '',
		groupData: [],
		addGroup: false,
		btnText: '',
		remove: false,
		groupId: '',
		resetForm: false,
		buttonLoading: false,
	}

	formRef: ?GroupAndSectionForm;

	componentDidMount() {
		const { match: { params: { applicationId, sectionId } } } = this.props;
		this.props.getExploreGroups(applicationId);
		this.props.getSections(applicationId);
		this.props.getSection(applicationId, sectionId)
			.then((sectionData: Response) => {
				if (sectionData.payload.data.icon) {
					const iconUrl = new URL(sectionData.payload.data.icon);
					const iconId = iconUrl.pathname;
					this.props.getFile(iconId)
						.then((res: Response) => this.setState({
							icon: res.payload.data.url,
						}));
				}
				if (sectionData.payload.data.coverPhoto) {
					const coverUrl = new URL(sectionData.payload.data.coverPhoto);
					const coverId = coverUrl.pathname;
					this.props.getFile(coverId)
						.then((res: Response) => this.setState({
							coverPhoto: res.payload.data.url,
						}));
				}
				this.setState({
					groupData: sectionData.payload.data.exploreGroups.slice(0, 10),
				});
			});
	}

	handleBackClick = () => {
		this.props.history.goBack();
	}

	// Delete section

	handleDeleteClick = () => {
		this.setState({
			deleteClicked: true,
			deleteMessage: `Once you delete the ${this.props.section.name} Section
			you won't be able to retrieve it.`,
			btnText: 'Delete Section',
		});
	}

	deleteSection = () => {
		const { match: { params: { applicationId, sectionId } } } = this.props;
		this.setState({
			deleteClicked: false,
		});
		this.props.removeSection(applicationId, sectionId)
			.then(() => this.props.history.push(`/application/${applicationId}/system/explore`))
			.catch((error) => this.handleOnError(error));
	}

	handleDeleteCancel = () => {
		this.setState({
			warningModal: false,
			deleteClicked: false,
		});
	}

	// Edit section

	handleEditClick = () => {
		this.setState({
			edit: true,
			addGroup: false,
			resetForm: true,
		});
	}

	handleEditCancel = () => {
		if (this.formRef && this.formRef.props.form.isFieldsTouched()) {
			this.setState({
				edit: false,
				warningModal: true,
				warningTitle: 'Warning!',
				message: {
					firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
				},
				footer: [
					{ type: 'cancel', action: this.handleErrorCancel, text: 'cancel edit' },
					{ type: 'continue', action: this.handleWarningCancel, text: 'continue editing' },
				],
			});
		} else {
			if (this.formRef) {
				this.formRef.handleReset();
			}
			this.setState({
				edit: false,
				warningModal: false,
				resetForm: false,
			});
		}
	}

	continueEditing = (value) => {
		this.setState({
			warningModal: true,
			edit: false,
			warningTitle: 'Are you sure?',
			message: {
				firstPart: `Once you confirm these changes,
						they will affect ${this.props.section.name}.`,
				secondPart: 'It is advised to proceed editing with caution.',
			},
			footer: [
				{ type: 'cancel', action: this.handleWarningCancel, text: 'go back' },
				{ type: 'continue', action: () => this.submitEditSection(value), text: 'edit section' },
			],
		});
	}

	submitEditSection = (data) => {
		this.setState({
			buttonLoading: true,
		});
		const { match: { params: { applicationId } }, section } = this.props;
		this.props.updateSection(applicationId, section.id, data)
			.then((sectionData: Response) => {
				if (sectionData.payload.data.icon) {
					const iconUrl = new URL(sectionData.payload.data.icon);
					const iconId = iconUrl.pathname;
					this.props.getFile(iconId)
						.then((res: Response) => this.setState({
							icon: res.payload.data.url,
						}));
				}
				if (sectionData.payload.data.coverPhoto) {
					const coverUrl = new URL(sectionData.payload.data.coverPhoto);
					const coverId = coverUrl.pathname;
					this.props.getFile(coverId)
						.then((res: Response) => this.setState({
							coverPhoto: res.payload.data.url,
						}));
				}
				this.setState({
					buttonLoading: false,
					warningModal: false,
					resetForm: false,
				});
			})
			.catch((error) => this.handleOnError(error));
		if (this.formRef) {
			this.formRef.handleReset();
		}
	}

	// Add to section

	handleAddToSection = () => {
		this.setState({
			addGroup: true,
			edit: true,
			resetForm: true,
		});
	}

	submitAddToSection = (data) => {
		const { match: { params: { applicationId } }, section } = this.props;
		this.setState({
			edit: false,
			buttonLoading: true,
		});
		this.props.addGroupToSection(applicationId, section.id, data)
			.then((res) => {
				this.setState({
					groupData: res.payload.data.exploreGroups.slice(0, 10),
					addGroup: false,
					buttonLoading: false,
				});
			})
			.catch((error) => this.handleOnError(error));
	}

	// Remove from section

	handleRemoveFromSection = (group) => {
		this.setState({
			deleteClicked: true,
			deleteMessage: `You want to remove ${group.name} Group
				from ${this.props.section.name} Explore Section`,
			btnText: 'Remove group',
			remove: true,
			groupId: group.id,
		});
	}

	removeFromSection = () => {
		const { match: { params: { applicationId, sectionId } } } = this.props;
		this.setState({
			deleteClicked: false,
			remove: false,
			buttonLoading: true,
		});
		this.props.removeGroupFromSection(applicationId, sectionId, this.state.groupId)
			.then((response) => {
				this.setState({
					groupData: response.payload.data.exploreGroups.slice(0, 10),
					buttonLoading: false,
				});
			})
			.catch((error) => this.handleOnError(error));
	}

	// Edit and Delete

	printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	handleOnError = (error: Error) => {
		this.setState({
			buttonLoading: false,
			warningModal: true,
			warningTitle: 'Error',
			message: {
				firstPart: error.error.response ? error.error.response.data.message : 'Error',
				secondPart: this.printError(error.error.response.data.errors),
			},
			footer: [
				{ type: 'cancel', action: this.handleDeleteCancel, text: 'close' },
			],
		});
	}

	handleErrorCancel = () => {
		if (this.formRef) {
			this.formRef.handleReset();
		}
		this.setState({
			warningModal: false,
			addGroup: false,
			resetForm: false,
		});
	}

	handleWarningCancel = () => {
		this.setState({
			edit: true,
			warningModal: false,
			addGroup: false,
		});
	}

	viewAll = () => {
		this.setState({
			groupData: this.props.section.exploreGroups,
		});
	}

	render() {
		const {
			warningModal,
			warningTitle,
			message,
			footer,
			deleteClicked,
			icon,
			coverPhoto,
			groupData,
			edit,
			addGroup,
			btnText,
			deleteMessage,
			remove,
			resetForm,
			buttonLoading,
		} = this.state;
		const {
			section, sections, exploreGroups, match: { params: { applicationId } },
		} = this.props;
		const groups = section && {
			groupsInSection: section.exploreGroups ? section.exploreGroups.length : 0,
		};
		const order = {
			order: section ? `#${section.order}` : '-',
		};
		const description = section && {
			sectionDescription: section.description ? section.description : '-',
		};
		const display = section && {
			displayType: section.display,
		};
		const displayComponent = {
			blocks: <Block />,
			list: <List />,
			carousel: <Carousel />,
		};
		const groupsNotAdded = differenceBy(exploreGroups, section.exploreGroups, 'id');
		const columns = [{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 192,
			render: (text: string, record: Section) => (
				<a
					href={`/application/${applicationId}/system/explore/group/${record.id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className="capitalize">
						{text}
					</span>
				</a>
			),
		}, {
			title: 'description',
			dataIndex: 'description',
			key: 'description',
			width: 256,
			render: (text: string) => (
				<span className="capitalize">
					{text || '-'}
				</span>
			),
		}, {
			title: 'remove group',
			dataIndex: '',
			key: 'remove',
			width: 160,
			render: () => (
				<span className="button-text">Remove Group</span>
			),
			onCell: (record) => ({
				onClick: (e) => {
					e.stopPropagation();
					this.handleRemoveFromSection(record);
				},
			}),
		}];
		return (
			<>
				{Object.keys(section).length
					? (
						<div>
							<Title
								backAction={this.handleBackClick}
								title={section.name}
								buttons={[
									{
										action: this.handleEditClick,
										text: 'edit section details',
									},
									{
										action: this.handleDeleteClick,
										text: 'delete section',
										type: 'danger',
									}]}
							/>
							<div className="container">
								<Row gutter={24} type="flex" className="section">
									<Col span={16} className="col-margin description">
										<TextCard data={description} colNumber={1} left="left-2" />
									</Col>
									<Col span={4} className="col-margin">
										<InfoCard data={groups} />
									</Col>
									<Col span={4} className="col-margin">
										<InfoCard data={order} />
									</Col>
								</Row>
								<Row gutter={24} type="flex" className="section">
									<Col span={16} className="col-margin">
										<Card className="table-card" bordered={false}>
											<div className="flex-container">
												<h2>Section&apos;s Groups</h2>
												<CPButton
													ghost
													text="add group to section"
													icon="Plus"
													action={this.handleAddToSection}
												/>
											</div>
											<Table
												bordered
												rowKey={(record) => record.id}
												columns={columns}
												dataSource={groupData}
												locale={{ emptyText: 'No groups!' }}
												pagination={false}
											/>
											{section.exploreGroups.length > 10
												&& (
													<CPButton
														ghost
														text="view all"
														action={this.viewAll}
													/>
												)}
										</Card>
									</Col>
									<Col span={8}>
										<Row gutter={24}>
											<Col span={24}>
												<TextCard
													title="Section Display"
													data={display}
													colNumber={1}
													image={displayComponent[section.display]}
												/>
											</Col>
											<Col span={24}>
												<Card className="custom-card" bordered={false}>
													<h2>Section Images</h2>
													<h4>Section Icon</h4>
													<p>Simple icon that appears next to your Section&apos;s name.</p>
													<div className="icon">
														{icon
															? <img src={icon} alt="icon" />
															: <IconPlaceholder />}
													</div>
													<h4>Cover Photo</h4>
													<p>More complex graphic, appears on the top of Section Screen.</p>
													<div className="cover">
														{coverPhoto
															? <img src={coverPhoto} alt="cover" />
															: <CoverPlaceholder width="270" height="84" />}
													</div>
												</Card>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						</div>
					)
					: <Spin spinning={!Object.keys(section)} />}
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={this.handleDeleteCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
				<DeleteModal
					okFunction={remove ? this.removeFromSection : this.deleteSection}
					cancelFunction={this.handleDeleteCancel}
					visible={deleteClicked}
					btnText={btnText}
					message={deleteMessage}
				/>
				{resetForm
					&& (
						<FormModal
							title={addGroup ? 'Add Group to Section' : `Edit ${section.name} Section`}
							visible={edit}
							cancelFunction={this.handleEditCancel}
							form={addGroup ? (
								<AddToForm
									submitChanges={this.submitAddToSection}
									handleCancelClick={this.handleEditCancel}
									data={groupsNotAdded}
									btnText="add to section"
									columnsData={['name', 'description']}
								/>
							)
								: (
									<GroupAndSectionForm
										submitChanges={this.continueEditing}
										handleCancelClick={this.handleEditCancel}
										data={section}
										icon={icon}
										coverPhoto={coverPhoto}
										wrappedComponentRef={(element) => { this.formRef = element; }}
										place="Section"
										sections={sections}
									/>
								)}
							place="Section"
							name={section.name}
						/>
					)}
			</>
		);
	}
}

const mapStateToProps = (state: State) => ({
	section: state.section.section,
	exploreGroups: state.exploreGroup.exploreGroups,
	sections: state.section.sections,
});

const mapDispatchToProps = {
	getSection,
	getSections,
	updateSection,
	removeSection,
	getFile,
	getExploreGroups,
	addGroupToSection,
	removeGroupFromSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetails);
