// @flow

import React, {
	useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import componnets
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import CPButton from '../../../components/UiElements/Button';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import PayoutForm from '../components/PayoutForm';
//= import actions
import {
	getDividendsCalendar,
	updateDividendCalendar,
	deleteDividendCalendar,
	getOwnedInstrument,
	executePayout,
} from '../../../modules/actions/DividendsAction';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { DividendsCalendar, OwnedInstrument } from '../../../modules/reducers/DividendsReducer';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type DividendRes = {
	payload: {
		data: DividendsCalendar,
	}
}
type Props = {
	getDividendsCalendar: (string, string) => Promise<DividendRes>,
	updateDividendCalendar: (string, string, Object) => Promise<DividendRes>,
	deleteDividendCalendar: (string, string) => Promise<Object>,
	getOwnedInstrument: (string, string, string) => void,
	executePayout: (string, string) => Promise<DividendRes>,
	dividendIsFetching: boolean,
	dividend: DividendsCalendar,
	ownedInstrument: OwnedInstrument,
	isFetchingOwnedInstrument: boolean,
	match: {
		params: {
			applicationId: string,
			dividendId: string,
		},
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

const ScheduledPayout = (props: Props) => {
	const {
		getDividendsCalendar: getDividendsCalendarAction,
		updateDividendCalendar: updateDividendCalendarAction,
		deleteDividendCalendar: deleteDividendCalendarAction,
		getOwnedInstrument: getOwnedInstrumentAction,
		executePayout: executePayoutAction,
		dividendIsFetching,
		isFetchingOwnedInstrument,
		ownedInstrument,
		dividend,
		match: { params: { applicationId, dividendId } },
		history,
	} = props;

	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [edit, setEdit] = useState(false);
	const [deleteClicked, setDeleteClicked] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		getDividendsCalendarAction(applicationId, dividendId)
			.then((res: DividendRes) => {
				const instrument = res.payload.data.dividendInstrumentId;
				const date = res.payload.data.exDividendDate;
				getOwnedInstrumentAction(applicationId, instrument, date);
			});
	}, [
		applicationId, getDividendsCalendarAction, getOwnedInstrumentAction, dividendId,
	]);

	const handleBackClick = () => {
		history.goBack();
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
		setDeleteClicked(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setDeleteClicked(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const submitAction = () => {
		setWarningModal(false);
		executePayoutAction(applicationId, dividend.id)
			.catch((error: ErrorType) => handleOnError(error));
	};

	const submitDelete = () => {
		setWarningModal(false);
		deleteDividendCalendarAction(applicationId, dividendId)
			.then(() => history.goBack())
			.catch((error: ErrorType) => handleOnError(error));
	};

	const openAction = () => {
		setWarningModal(true);
		setWarningTitle('Execute Dividend Payout');
		setMessage({
			firstPart: 'Once you confirm this action, it will affect Wallets.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAction(), text: 'execute dividend payout' },
		]);
	};

	const deleteAction = () => {
		setDeleteClicked(true);
	};

	const editPayout = () => {
		setEdit(true);
	};

	const submitEdit = (value) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		updateDividendCalendarAction(applicationId, dividendId, value)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueEdit = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect this Dividend.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEdit(value), text: 'edit dividend' },
		]);
	};

	const dateData = {
		announcementDate: dividend.announcementDate ? moment(dividend.announcementDate).format('YYYY-MM-DD HH:mm:ss') : '-',
		exDividendDate: dividend.exDividendDate ? moment(dividend.exDividendDate).format('YYYY-MM-DD HH:mm:ss') : '-',
		recordDate: dividend.recordDate ? moment(dividend.recordDate).format('YYYY-MM-DD HH:mm:ss') : '-',
		payoutDate: dividend.payoutDate ? moment(dividend.payoutDate).format('YYYY-MM-DD HH:mm:ss') : '-',
	};

	return (
		<div>
			<Title
				title={`${dividend.dividendInstrumentId || ''} Dividend`}
				backAction={handleBackClick}
				tags={[
					dividend.isExecuted && 'executed',
				]}
				buttons={[
					{
						action: openAction,
						text: 'Execute Dividend Payout',
						disabled: dividend.isExecuted || moment().isBefore(dividend.payoutDate),
					},
					{
						action: deleteAction,
						text: 'Delete Dividend',
						disabled: dividend.isExecuted,
						type: 'danger',
					},
				]}
			/>
			{!dividendIsFetching && !isFetchingOwnedInstrument
				? (
					<div className="page-container">
						<Row gutter={24}>
							<Col span={12} className={styles.payout}>
								<div className={styles.values}>
									{moment().isBefore(dividend.recordDate)
										? (
											<div className={styles.shareInfo}>
												<h2>
													For the selected stock,
													Total Number of Shares will be available FROM the dividend record date.
												</h2>
											</div>
										)
										: (
											<InfoCard
												data={{ totalNumberOfShares: `${ownedInstrument.totalShares || ''} ${dividend.dividendInstrumentId || ''}` }}
											/>
										)}
									<InfoCard
										data={{
											grossAmount: PriceHelpers.formatAmount(
												dividend.grossAmount,
												dividend.payoutInstrumentId,
											),
											taxAmount: PriceHelpers.formatAmount(
												dividend.taxAmount,
												dividend.payoutInstrumentId,
											),
											payoutInstrument: dividend.payoutInstrumentId,
										}}
									/>
									<CPButton ghost type="default" icon="Edit" action={editPayout} disabled={dividend.isExecuted} />
								</div>
							</Col>
							<Col span={12}>
								<TextCard data={dateData} colNumber={1} left="left-1" />
							</Col>
						</Row>
					</div>
				) : <Spin spinning={dividendIsFetching || isFetchingOwnedInstrument} />}
			<FormModal
				title="Schedule Dividends"
				visible={edit}
				cancelFunction={handleEditCancel}
				form={(
					<PayoutForm
						submitChanges={continueEdit}
						handleCancelClick={handleEditCancel}
						dividend={dividend}
						wrappedComponentRef={formRef}
						applicationId={applicationId}
					/>
				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
			<DeleteModal
				okFunction={submitDelete}
				cancelFunction={handleErrorCancel}
				visible={deleteClicked}
				btnText="DELETE"
				message="Once you delete the Dividend Calendar Entry
				you won't be able to retrieve it."
			/>
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	dividend: state.dividends.dividendsCalendar,
	dividendIsFetching: state.dividends.isFetchingDividendsCalendar,
	ownedInstrument: state.dividends.ownedInstrument,
	isFetchingOwnedInstrument: state.dividends.isFetchingOwnedInstrument,
});

const mapDispatchToProps = {
	getDividendsCalendar,
	updateDividendCalendar,
	deleteDividendCalendar,
	getOwnedInstrument,
	executePayout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPayout);
