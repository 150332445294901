import React, { PureComponent } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import WithAuth from '../../lib/hocs/WithAuth';
//= import methods
import { checkOauth } from '../actions/OauthActions';
//= import components
import SignIn from '../../containers/Auth/SignIn';
import App from '../../containers/App';
import InApplication from '../../containers/Application';
import Logout from '../../containers/Logout';
//= import types
import type { State } from '../types/FlowTypes';

type Props = {
	initialized: boolean,
	checkOauth: () => void,
}

class Auth extends PureComponent <Props> {
	componentDidMount() {
		this.props.checkOauth();
	}

	render() {
		const { initialized } = this.props;
		if (!initialized) {
			return (
				<BrowserRouter>
					<Switch>
						<Route
							path="/sign-in"
							component={WithAuth(SignIn, false)}
						/>
					</Switch>
				</BrowserRouter>
			);
		}
		return (
			<BrowserRouter>
				<Switch>
					<Route
						path="/sign-in"
						component={WithAuth(SignIn, false)}
					/>
					<Route
						path="/logout"
						component={Logout}
					/>
					<Route
						path="/application/:applicationId"
						component={WithAuth(InApplication, true)}
					/>
					<Route
						path="/"
						component={WithAuth(App, true)}
					/>
				</Switch>
			</BrowserRouter>
		);
	}
}

function mapStateToProps(state: State) {
	return {
		initialized: state.oauth.initialized,
	};
}

const mapDispatchToProps = {
	checkOauth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
