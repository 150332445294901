// @flow

import React, {
	useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import componnets
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import StockSplitForm from '../components/StockSplitForm';
//= import actions
import {
	getStockSplit,
	updateStockSplit,
	deleteStockSplit,
	executeStockSplit,
} from '../../../modules/actions/StockSplitAction';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { StockSplit } from '../../../modules/reducers/StockSplitReducer';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type Props = {
	getStockSplit: (string, string) => Promise<Object>,
	updateStockSplit: (string, string, Object) => Promise<Object>,
	deleteStockSplit: (string, string) => Promise<Object>,
	executeStockSplit: (string, string, {[string]: string}) => Promise<Object>,
	isFetchingStockSplit: boolean,
	stockSplit: StockSplit,
	match: {
		params: {
			applicationId: string,
			stockSplitId: string,
		},
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

const StockSplitPage = (props: Props) => {
	const {
		getStockSplit: getStockSplitAction,
		updateStockSplit: updateStockSplitAction,
		deleteStockSplit: deleteStockSplitAction,
		executeStockSplit: executeStockSplitAction,
		isFetchingStockSplit,
		stockSplit,
		match: { params: { applicationId, stockSplitId } },
		history,
	} = props;

	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [edit, setEdit] = useState(false);
	const [type, setType] = useState('');
	const [deleteClicked, setDeleteClicked] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		getStockSplitAction(applicationId, stockSplitId);
	}, [
		applicationId, stockSplitId, getStockSplitAction,
	]);

	const handleBackClick = () => {
		history.goBack();
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
		setDeleteClicked(false);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setDeleteClicked(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response ? error.error.response.data?.message : 'Error',
			secondPart: printError(error.error.response?.data?.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const submitExecute = (data) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		executeStockSplitAction(applicationId, stockSplitId, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const submitDelete = () => {
		setWarningModal(false);
		deleteStockSplitAction(applicationId, stockSplitId)
			.then(() => history.goBack())
			.catch((error: ErrorType) => handleOnError(error));
	};

	const submitEdit = (value) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		updateStockSplitAction(applicationId, stockSplitId, value)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const deleteAction = () => {
		setDeleteClicked(true);
	};

	const handleEdit = () => {
		setEdit(true);
		setType('edit');
	};

	const handleExecute = () => {
		setEdit(true);
		setType('execute');
	};

	const continueExecute = (value: {[string]: string}) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Stock Split Execution will affect the portfolios of all clients that hold Symbol.',
			secondPart: 'Please double-check the data below before execution and processed with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitExecute(value), text: 'execute stock split' },
		]);
	};

	const continueEdit = (value: {[string]: string}) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes,
						they will affect this Stock Split.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEdit(value), text: 'edit stock split' },
		]);
	};

	const generalInfo = [{
		title: 'General Info',
		data: {
			symbol: stockSplit.symbolId,
			splitRate: stockSplit.splitRate,
			announcementDate: stockSplit.announcementDate ? moment(stockSplit.announcementDate).format('YYYY-MM-DD') : '-',
			recordDate: stockSplit.recordDate ? moment(stockSplit.recordDate).format('YYYY-MM-DD') : '-',
			exdDate: stockSplit.exDate ? moment(stockSplit.exDate).format('YYYY-MM-DD') : '-',
		},
	}];
	const executionSummary = [
		{
			title: 'Execution Summary',
			data: {
				closingPrice: PriceHelpers.formatAmount(
					stockSplit?.closingPrice,
					stockSplit.quotingInstrument || '',
				),
			},
		}, {
			title: 'Before',
			data: {
				available: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.before?.available,
					stockSplit.instrumentId,
				),
				allocated: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.before?.allocated,
					stockSplit.instrumentId,
				),
				total: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.before?.total,
					stockSplit.instrumentId,
				),
			},
		}, {
			title: 'Reminder',
			data: {
				baseAmount: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.remainder?.baseAmount,
					stockSplit.instrumentId,
				),
				quotingAmount: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.remainder?.quotingAmount,
					stockSplit.instrumentId,
				),
			},
		}, {
			title: 'After',
			data: {
				available: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.after?.available,
					stockSplit.instrumentId,
				),
				allocated: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.after?.allocated,
					stockSplit.instrumentId,
				),
				total: PriceHelpers.formatAmount(
					stockSplit?.executionSummary?.after?.total,
					stockSplit.instrumentId,
				),
			},
		},
	];

	return (
		<div>
			<Title
				title={`${stockSplit.instrumentId || ''} ${stockSplit.splitRate} Stock Split`}
				backAction={handleBackClick}
				tags={[
					stockSplit.status,
				]}
				buttons={[
					{
						action: handleExecute,
						text: 'Execute Stock Split',
						disabled: stockSplit.status !== 'new',
					},
					{
						action: handleEdit,
						text: 'Edit Stock Split',
						disabled: stockSplit.status !== 'new',
					},
					{
						action: deleteAction,
						text: 'Delete Stock Split',
						disabled: stockSplit.status !== 'new',
						type: 'danger',
					},
				]}
			/>
			{!isFetchingStockSplit
				? (
					<div className="page-container">
						<Row gutter={24}>
							<Col span={12} className={styles.payout}>
								<DataCard parts={generalInfo} />
							</Col>
							<Col span={12}>
								<DataCard parts={executionSummary} />
							</Col>
						</Row>
					</div>
				) : <Spin spinning={isFetchingStockSplit} />}
			<FormModal
				title={`${type} Stock Split`}
				visible={edit}
				cancelFunction={handleEditCancel}
				form={(
					<StockSplitForm
						submitChanges={type === 'edit' ? continueEdit : continueExecute}
						handleCancelClick={handleEditCancel}
						applicationId={applicationId}
						wrappedComponentRef={formRef}
						stockSplit={stockSplit}
						type={type}
					/>
				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
			<DeleteModal
				okFunction={submitDelete}
				cancelFunction={handleErrorCancel}
				visible={deleteClicked}
				btnText="delete stock split"
				message="Please confirm the deletion of the scheduled Stock Split."
			/>
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	stockSplit: state.stockSplit.stockSplit,
	isFetchingStockSplit: state.stockSplit.isFetchingStockSplit,
});

const mapDispatchToProps = {
	getStockSplit,
	updateStockSplit,
	deleteStockSplit,
	executeStockSplit,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockSplitPage);
