// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import selectors
import { activityMappedSelector } from '../../../modules/selectors/ActivitySelector';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import types
import type { ActivityLog } from '../../../modules/reducers/LogsReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	activityLog: Array<ActivityLog>,
	activityLogTotal: number,
	fetchingActivityLog: boolean,
	currentPage: number,
	filters: { [string]: string },
	history: {
		push: (string) => void,
		goBack: () => void,
	},
	applicationId: string,
	handlePageChange: (number) => void,
	filterHeight: number,
	active: string,
}

function ActivityPage(props: Props) {
	const {
		activityLog,
		fetchingActivityLog,
		activityLogTotal,
		currentPage,
		applicationId,
		handlePageChange,
		filterHeight,
		active,
	} = props;

	const columns = [
		{
			title: 'performed at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'event',
			dataIndex: 'eventName',
			key: 'eventName',
			width: 240,
			render: (text: string, record: ActivityLog) => (
				<div className="capitalize">
					{ActivityHelpers.createActivityName(record).event}
				</div>
			),
		},
		{
			title: 'amount',
			dataIndex: 'affectedObjectNew',
			key: 'amount',
			align: 'right',
			width: 160,
			render: (text: string, record: ActivityLog) => (
				<div>
					{ActivityHelpers.createActivityName(record).amount}
				</div>
			),
		},
		{
			title: 'performed by',
			dataIndex: 'performer.email',
			key: 'performer',
			width: 320,
			render: (text: string, record: ActivityLog) => (
				<div>
					{record?.performer?.email}
				</div>
			),
		},
		{
			title: 'owner',
			dataIndex: 'owner.email',
			key: 'owner',
			width: 320,
			render: (text: string, record: ActivityLog) => (
				<div>
					{record?.owner?.email}
				</div>
			),
		},
		{
			title: 'affected object',
			dataIndex: 'affectedModel',
			key: 'affectedModel',
			width: 144,
			render: (text: string) => (
				<div className="bold">
					{ActivityHelpers.transformToReadable(text)}
				</div>
			),
		},
	];
	return (
		<div>
			{!fetchingActivityLog
				? (
					<CustomTable
						columns={columns}
						data={activityLog}
						total={activityLogTotal}
						expandRowByClick
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={filterHeight}
						place="activityLogs"
						applicationId={applicationId}
						defaultExpandedRowKeys={[active]}
					/>
				)
				: <Spin spinning={fetchingActivityLog} />}
		</div>
	);
}

const mapStateToProps = (state: State) => ({
	activityLog: activityMappedSelector(state),
	fetchingActivityLog: state.logs.fetchingActivityLog,
	activityLogTotal: state.logs.activityLogTotal,
	currentPage: state.ui.activityLogPage,
});

export default connect(mapStateToProps)(ActivityPage);
