// @flow

import React from 'react';
import map from 'lodash/map';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import Card from '../../../components/UiElements/Card';
import List from '../../../components/UiElements/List';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import types
import type { FullUser } from '../../../modules/reducers/UsersReducer';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	fullUser: FullUser,
}
const UserInfo = (props: Props) => {
	const {
		fullUser: {
			userInfo, userAddress, email, suspensions, suspended,
		},
	} = props;

	const userInfoData = {
		eMail: `${email}` || '-',
		phone: (!!userInfo && !!userInfo.phone && `${userInfo.dialingPrefix} ${userInfo.phone}`) || '-',
		dateOfBirth: (!!userInfo && !!userInfo.birthday && `${userInfo.birthday}`) || '-',
		country: (!!userAddress && !!userAddress.country && `${userAddress.country.name}`) || '-',
		postalCode: (!!userAddress && !!userAddress.zipCode && `${userAddress.zipCode}`) || '-',
		residentalCity: (!!userAddress && !!userAddress.city && `${userAddress.city}`) || '-',
		address: (!!userAddress && !!userAddress.street && `${userAddress.street || '-'} ${userAddress.streetNumber || ''}`) || '-',
		addressLine2: (!!userAddress && !!userAddress.addressLine2 && `${userAddress.addressLine2}`) || '-',
	};
	const suitabilityQuestionsList = !!userInfo
		&& !!userInfo.suitabilityQuestions
		? map(userInfo.suitabilityQuestions, (value, key) => ({
			questionKey: key,
			question: value,
		})).filter((el) => el.question !== 'complete')
		: [];
	return (
		<Card className={styles.userInfo} bordered={false}>
			<Row gutter={24}>
				<Col span={12}>
					<h4>Basic Info</h4>
					<TextCard data={userInfoData} colNumber={1} border left="left-1" />
					{suspended && <h4>Suspensions</h4>}
					{suspensions.map((el) => (
						<TextCard
							key={el.id}
							data={{
								updatedAt: moment(el.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
								suspendReasonId: (
									<CopyComponent
										text={el.suspendReasonId}
										content={(
											<Link
												to={`/application/${el.suspendReason.applicationId}/system/suspend_reasons/${el.suspendReasonId}`}
											>
												{el.suspendReasonId}
											</Link>
										)}
									/>),
								suspendReasonTitle: el.suspendReason.title,
								suspendReasonDescription: el.suspendReason.description,
								note: el.note || '-',
							}}
							colNumber={1}
							border
							left="left-1"
						/>
					))}
				</Col>
				<Col span={12}>
					<h4>Suitability Questions</h4>
					<div className={styles.list}>
						<List
							itemLayout="vertical"
							dataSource={suitabilityQuestionsList}
							renderItem={
								(item) => (
									<List.Item key={item.questionKey} className={styles.item}>
										<List.Item.Meta
											description={typeof item.question === 'string' ? item.question : item.question.question}
											className={styles.question}
										/>
										<div>{typeof item.question === 'string' ? item.question : item.question.answer}</div>
									</List.Item>
								)
							}
						/>
					</div>
				</Col>
			</Row>
		</Card>
	);
};

export default UserInfo;
