import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

const TopBanner = (props: {
    announcement: string,
    showBanner: boolean,
}) => {
	const { announcement, showBanner = '' } = props;
	return (
		showBanner && (
			<motion.div
				className="topBanner"
				animate={{
					opacity: 1,
				}}
				initial={{
					opacity: 0,
				}}
				transition={{ type: 'spring', stiffness: 100 }}
			>
				<span>
					<span>{announcement}</span>
				</span>
			</motion.div>
		)
	);
};

export default TopBanner;
