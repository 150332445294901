// @flow

const initialState = {
	statements: [],
	isFetchingStatements: false,
	isFetchedStatements: false,
	statement: {},
	isFetchingStatement: false,
	isFetchedStatement: false,
	updatingStatement: false,
	updatedStatement: false,
};

export type Statement = {
	id: string,
	type: string,
	enabled: boolean,
	headerCompanyLogo: boolean,
	headerCompanyAddress: boolean,
	headerCompanyEmail: boolean,
	headerCompanyPhoneNumber: boolean,
	headerCompanyWebsite: boolean,
	footer: string,
	statementAdditionalContent: {
		id: string,
		body: string,
		title: string,
		enabled: boolean,
	},
	createdAt: string,
	updatedAt: string,
	deletedAt: string,
}

export type SingleStatement = {
	id: string,
	type: string,
	enabled: boolean,
}

export type StatementsState = {
	statements: Array<SingleStatement>,
	isFetchingStatements: boolean,
	isFetchedStatements: boolean,
	statement: Statement,
	isFetchingStatement: boolean,
	isFetchedStatement: boolean,
	updatingStatement: boolean,
	updatedStatement: boolean,
}

type StatementsAction = {
	type:
	| 'GET_STATEMENTS'
	| 'GET_STATEMENTS_FAIL'
	| 'GET_STATEMENTS_SUCCESS',
	payload: {
		data: Array<SingleStatement>,
	},
	error: Object,
}
type StatementAction = {
	type:
	| 'GET_STATEMENT'
	| 'GET_STATEMENT_FAIL'
	| 'GET_STATEMENT_SUCCESS'
	| 'UPDATE_STATEMENT'
	| 'UPDATE_STATEMENT_FAIL'
	| 'UPDATE_STATEMENT_SUCCESS',
	payload: {
		data: Statement,
	},
	error: Object,
}
type Action = StatementsAction | StatementAction;

export default function statementReducer(
	state: StatementsState = initialState,
	action: Action,
): StatementsState {
	switch (action.type) {
	case 'GET_STATEMENTS': {
		return {
			...state,
			isFetchingStatements: true,
			isFetchedStatements: false,
			statements: [],
		};
	}
	case 'GET_STATEMENTS_FAIL': {
		return {
			...state,
			isFetchingStatements: false,
			isFetchedStatements: false,
			error: action.error,
		};
	}
	case 'GET_STATEMENTS_SUCCESS': {
		return {
			...state,
			isFetchingStatements: false,
			isFetchedStatements: true,
			statements: action.payload.data,
		};
	}
	case 'GET_STATEMENT': {
		return {
			...state,
			isFetchingStatement: true,
			isFetchedStatement: false,
			statement: {},
		};
	}
	case 'GET_STATEMENT_FAIL': {
		return {
			...state,
			isFetchingStatement: false,
			isFetchedStatement: false,
			error: action.error,
		};
	}
	case 'GET_STATEMENT_SUCCESS': {
		return {
			...state,
			isFetchingStatement: false,
			isFetchedStatement: true,
			statement: action.payload.data,
		};
	}
	case 'UPDATE_STATEMENT': {
		return {
			...state,
			updatingStatement: true,
			updatedStatement: false,
			statement: {},
		};
	}
	case 'UPDATE_STATEMENT_FAIL': {
		return {
			...state,
			updatingStatement: false,
			updatedStatement: false,
			error: action.error,
		};
	}
	case 'UPDATE_STATEMENT_SUCCESS': {
		return {
			...state,
			updatingStatement: false,
			updatedStatement: true,
			statement: {
				...state.statement,
				enabled: !state.statement.enabled,
			},
		};
	}
	default:
		return state;
	}
}
