// @flow

import React from 'react';
//= import components
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Card from '../../../components/UiElements/Card';
import List from '../../../components/UiElements/List';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';

type Props = {
	data: Symbol,
	opacity: boolean,
	parentSymbol: Symbol,
}
type Item = {
	title: string,
	data: string,
}
const OrderComponent = (props: Props) => {
	const {
		data, opacity, parentSymbol,
	} = props;
	const quantityData = [{
		title: 'min possible quantity',
		data: parentSymbol.orderSize && parentSymbol.orderSize.minQuantity.baseInstrument
			? PriceHelpers.formatAmount(
				parentSymbol.orderSize.minQuantity.baseInstrument,
				parentSymbol.baseInstrumentId,
			) : '-',
	}, {
		title: 'max possible quantity',
		data: parentSymbol.orderSize && parentSymbol.orderSize.maxQuantity.baseInstrument
			? PriceHelpers.formatAmount(
				parentSymbol.orderSize.maxQuantity.baseInstrument,
				parentSymbol.baseInstrumentId,
			) : '-',
	}, {
		title: 'min quantity set',
		data: data.orderSize.minQuantity.baseInstrument
			? PriceHelpers.formatAmount(
				data.orderSize.minQuantity.baseInstrument,
				data.baseInstrumentId,
			) : '-',
	}, {
		title: 'max quantity set',
		data: data.orderSize.maxQuantity.baseInstrument
			? PriceHelpers.formatAmount(
				data.orderSize.maxQuantity.baseInstrument,
				data.baseInstrumentId,
			)
			: '-',
	}];
	const amountData = [{
		title: 'min possible amount',
		data: parentSymbol.orderSize
			&& parentSymbol.orderSize.minQuantity.quotingInstrument
			? PriceHelpers.formatAmount(
				parentSymbol.orderSize.minQuantity.quotingInstrument,
				parentSymbol.quotingInstrumentId,
			) : '-',
	}, {
		title: 'max possible amount',
		data: parentSymbol.orderSize
			&& parentSymbol.orderSize.maxQuantity.quotingInstrument
			? PriceHelpers.formatAmount(
				parentSymbol.orderSize.maxQuantity.quotingInstrument,
				parentSymbol.quotingInstrumentId,
			) : '-',
	}, {
		title: 'min amount set',
		data: data.orderSize.minQuantity.quotingInstrument
			? PriceHelpers.formatAmount(
				data.orderSize.minQuantity.quotingInstrument,
				data.quotingInstrumentId,
			) : '-',
	}, {
		title: 'max amount set',
		data: data.orderSize.maxQuantity.quotingInstrument
			? PriceHelpers.formatAmount(
				data.orderSize.maxQuantity.quotingInstrument,
				data.quotingInstrumentId,
			)
			: '-',
	}];

	return (
		<Card className="order-component-card" style={opacity ? { opacity: 0.7 } : { opacity: 1 }} bordered={false}>
			<h2>Order Rules</h2>
			<h4>Market Order Size</h4>
			<Row gutter={24} className="order-size">
				<Col span={10}>
					<div className="order-component-text">
						<p>
							Define the Quantity of one Order.
							The Minimum Quantity must be set higher than the Minimum Possible,
							and the Maximum Quantity lower than the Maximum Possible.
						</p>
					</div>
				</Col>
				<Col span={14}>
					<List
						itemLayout="horizontal"
						dataSource={quantityData}
						renderItem={
							(item: Item) => (
								<List.Item key={item.title} className="item">
									<List.Item.Meta
										description={item.title}
									/>
									<div>
										{item.data}
									</div>
								</List.Item>
							)
						}
					/>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={10}>
					<div className="order-component-text">
						<p>
							Define the Amount (Quoting Quantity) of one Order.
							The Minimum Amount must be set higher than the Minimum Possible,
							and the Maximum Amount lower than the Maximum Possible.
						</p>
					</div>
				</Col>
				<Col span={14}>
					<List
						itemLayout="horizontal"
						dataSource={amountData}
						renderItem={
							(item: Item) => (
								<List.Item key={item.title} className="item">
									<List.Item.Meta
										description={item.title}
									/>
									<div>
										{item.data}
									</div>
								</List.Item>
							)
						}
					/>
				</Col>
			</Row>
		</Card>
	);
};

export default OrderComponent;
