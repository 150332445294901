// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import AccountsList from '../components/AccountsList';
//= import actions
import { getUserBankAccounts } from '../../../modules/actions/BankActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { BankAccountType } from '../../../modules/reducers/BankReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Props = {
	getUserBankAccounts: (string, number, string) => void,
	currentPage: number,
	userAccountsFilters: { [string]: string },
	userAccounts: Array<BankAccountType>,
	isFetchingUserAccounts: boolean,
	userAccountsTotal: number,
	setCurrentPage: (string, number) => void,
	getProvidersInstances: (string) => void,
	providers: Array<ProviderInstance>,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
	location: {
		pathname: string,
		search: string,
	},
}

function UserBankAccounts(props: Props) {
	const {
		getUserBankAccounts: getUserBankAccountsActions,
		currentPage,
		userAccountsFilters,
		setCurrentPage: setCurrentPageAction,
		getProvidersInstances: getProvidersInstancesAction,
		providers,
		userAccounts,
		isFetchingUserAccounts,
		userAccountsTotal,
		match: { params: { applicationId, userId } },
	} = props;

	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	const filterRef = useRef(null);

	useEffect(() => {
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, applicationId]);

	useEffect(() => {
		getUserBankAccountsActions(userId, currentPage, queryString.stringify(userAccountsFilters, { arrayFormat: 'comma' }));
	}, [getUserBankAccountsActions, userId, currentPage, userAccountsFilters]);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [userAccountsFilters]);

	const handleClientAccountsPageChange = (page: number) => {
		setCurrentPageAction('userAccounts', page);
	};

	const availableProviders = providers.filter((el) => el.provider.name === 'modulr').map((el) => ({
		id: el.provider.name,
		name: el.provider.name,
		displayName: el.provider.displayName,
	}));

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="client bank accounts"
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="userAccounts"
					fields={{
						instrumentId: StatusHelpers.bankInstruments,
						provider: availableProviders,
						dateRange: 'time',
					}}
					date
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					filter
					filterMode="single"
				/>
			</div>
			<div className="container">
				<AccountsList
					applicationId={applicationId}
					accounts={userAccounts}
					accountsTotal={userAccountsTotal}
					currentPage={currentPage}
					headerHeight={filterHeight}
					handlePageChange={handleClientAccountsPageChange}
					place="userAccounts"
					loading={isFetchingUserAccounts}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	currentPage: state.ui.userAccountsPage,
	userAccountsFilters: state.ui.userAccountsFilters,
	providers: state.providers.providersInstances,
	userAccounts: state.bank.userAccounts,
	isFetchingUserAccounts: state.bank.isFetchingUserAccounts,
	userAccountsTotal: state.bank.userAccountsTotal,
});

const mapDispatchToProps = {
	setCurrentPage,
	getProvidersInstances,
	getUserBankAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBankAccounts);
