// @flow

import * as React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Input from '../../../components/UiElements/Input';
import InputNumber from '../../../components/UiElements/InputNumber';
// = import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
import type {
	Payment, EntryModes, TransactionTypes, Currency, MerchantCategoryCodes,
} from '../../../modules/reducers/CardsReducer';

type Values = {
	transactionAmount: number,
	billingAmount: number,
	transactionType: number,
	entryModeType: number,
 }
type Props = {
	form: *,
	type: string,
	payment: Payment,
	entryModes: Array<EntryModes>,
	transactionTypes: Array<TransactionTypes>,
	merchantCategoryCodes: Array<MerchantCategoryCodes>,
	cardCurrencies: Array<Currency>,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
}

class Simulation extends React.Component<Props> {
	handleReset = () => {
		this.props.form.resetFields();
	}

	validateAmount = (rule, value, callback) => {
		const { payment } = this.props;
		if (value !== 0 && !value) {
			callback('Please Enter Amount To Be Reversed!');
		}
		if (value < 0 || value > payment.originalAmount) {
			callback('Amount To Be Reversed must be smaller than Transaction Amount');
		}
		if (parseFloat(value.toFixed(2)) !== value) {
			callback('Value must have precision less than or equal to 2');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Array<Object>, values: Values) => {
			if (!err) {
				const data = {
					...values,
					transactionAmount: 0 - values.transactionAmount,
					billingAmount: 0 - values.billingAmount,
					transactionType: values.transactionType - 1,
					entryModeType: values.entryModeType - 1,
				};
				submitChanges(data);
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			entryModes,
			transactionTypes,
			merchantCategoryCodes,
			payment,
			type,
		} = this.props;

		const FormItem = Form.Item;
		const { Option } = Select;

		const formItemLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		const staticData = [
			{ name: 'entryModeType', options: entryModes, initialValue: payment.entryMode?.id },
			{ name: 'transactionType', options: transactionTypes, initialValue: payment.transactionType?.id },
			{ name: 'mccCode', options: merchantCategoryCodes, initialValue: payment.mcc?.code },
		];

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{(type === 'repeat' || type === 'create')
				&& (
					<>
						<FormItem label="SCHEME" {...formItemLayout}>
							{getFieldDecorator('scheme', {
								initialValue: StatusHelpers.scheme[0].id,
								rules: [{
									required: true,
									message: 'Please select Scheme!',
								}],
							})(
								<Select
									showSearch
									placeholder="Select Scheme"
									disabled={type === 'repeat'}
								>
									{StatusHelpers.scheme.map((el) => (
										<Option
											value={el.id}
											key={el.id}
										>
											{el.name}
										</Option>
									))}
								</Select>,
							)}
						</FormItem>
						<FormItem label="AUTHORIZATION TYPE" {...formItemLayout}>
							{getFieldDecorator('authorizationType', {
								initialValue: StatusHelpers.authorizationTypes[0].id,
								rules: [{
									required: true,
									message: 'Please select Authorization Type!',
								}],
							})(
								<Select
									showSearch
									placeholder="Select Authorization Type"
									optionFilterProp="children"
									filterOption={
										(input, data) => data.props.children.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									disabled={type === 'repeat'}
								>
									{StatusHelpers.authorizationTypes.map((el) => (
										<Option
											value={el.id}
											key={el.id}
										>
											{el.name}
										</Option>
									))}
								</Select>,
							)}
						</FormItem>
						<FormItem label="CARD UUID" {...formItemLayout}>
							{getFieldDecorator('cardId', {
								initialValue: payment?.cardId,
								rules: [{
									required: true, message: 'Please Enter Card Id',
								}],
							})(
								<Input
									name="name"
									placeholder="Paste Card Id"
									maxLength={50}
									minLength={3}
									disabled={type === 'repeat'}
								/>,
							)}
						</FormItem>
					</>
				)}
				{type !== 'settle'
				&& (
					<FormItem label="TRANSACTION AMOUNT" {...formItemLayout}>
						{getFieldDecorator('transactionAmount', {
							initialValue: payment?.originalAmount,
							rules: [{
								required: true, message: 'Please Enter Transaction Amount!',
							}],
						})(
							<InputNumber
								symbol={payment.originalCurrency?.isoCode}
								placeholder="Enter Transaction Amount"
								disabled={type === 'revers' || type === 'partialRevers'}
							/>,
						)}
					</FormItem>
				)}
				{type === 'partialRevers' && (
					<FormItem label="AMOUNT TO BE REVERSED" {...formItemLayout}>
						{getFieldDecorator('amountToBeReversed', {
							rules: [{ validator: this.validateAmount }],
						})(
							<InputNumber
								symbol={payment.originalCurrency?.isoCode}
								placeholder="Enter Amount To Be Reversed"
							/>,
						)}
					</FormItem>
				)}
				{(type === 'repeat' || type === 'create')
				&& (
					<>
						<FormItem label="BILLING AMOUNT" {...formItemLayout}>
							{getFieldDecorator('billingAmount', {
								initialValue: payment?.billingAmount,
								rules: [{
									required: true, message: 'Please Enter Billing Amount',
								}],
							})(
								<InputNumber
									symbol={payment.originalCurrency?.isoCode}
									placeholder="Enter Billing Amount"
								/>,
							)}
						</FormItem>
						{staticData.map((el) => (
							<FormItem
								label={ActivityHelpers.transformToReadable(el.name)}
								{...formItemLayout}
								key={el.name}
							>
								{getFieldDecorator(el.name, {
									initialValue: el.initialValue,
									rules: [{
										required: true,
										message: `Please select ${ActivityHelpers.transformToReadable(el.name)}!`,
									}],
								})(
									<Select
										showSearch
										placeholder={`Select ${ActivityHelpers.transformToReadable(el.name)}`}
										optionFilterProp="children"
										filterOption={
											(input, data) => data.props.children.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										disabled={type === 'repeat'}
									>
										{el.options.map((option) => (
											<Option
												value={option.id || option.code}
												key={option.id || option.code}
											>
												{option.name || option.description}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
						))}
						<FormItem label="MERCHANT ID" {...formItemLayout}>
							{getFieldDecorator('merchantId', {
								initialValue: payment?.merchantId,
								rules: [{
									required: true, message: 'Please Enter Merchant Id',
								}],
							})(
								<Input
									placeholder="Enter Merchant Id"
									maxLength={24}
									minLength={4}
									disabled={type === 'repeat'}
								/>,
							)}
						</FormItem>
						<FormItem label="MERCHANT NAME" {...formItemLayout}>
							{getFieldDecorator('merchantName', {
								initialValue: payment?.merchantName,
								rules: [{
									required: true, message: 'Please Enter Merchant Name',
								}],
							})(
								<Input
									placeholder="Enter Merchant Name"
									maxLength={100}
									minLength={4}
									disabled={type === 'repeat'}
								/>,
							)}
						</FormItem>
					</>
				)}
				<div className="form-buttons">
					<Form.Item>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</Form.Item>
					<Form.Item>
						<CPButton
							type="primary"
							action={this.submit}
							text={`${type} card payment`}
						/>
					</Form.Item>
				</div>
			</Form>
		);
	}
}

const SimulationForm = Form.create()(Simulation);

export default SimulationForm;
