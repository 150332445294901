// @flow

import {
	ACCOUNTS, ACTIVE_ACCOUNT, ACCOUNT, GET_ACCOUNT_STATISTICS, GET_ACCOUNT_POSITIONS,
} from '../const/types';

export function getAccounts(userId: string, pagType: string = 'numbered-pages', pagOffset: number = 0, pagLimit: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: ACCOUNTS,
		payload: {
			request: {
				url: `/users/${userId}/accounts`,
				headers: {
					'pagination-type': pagType,
					'pagination-limit': pagLimit,
					'pagination-offset': pagOffset,
				},
			},
		},
	});
}

export function getAccount(accountId: string) {
	return (dispatch: Function): void => dispatch({
		type: ACCOUNT,
		payload: {
			request: {
				url: `/accounts/${accountId}`,
			},
		},
	});
}

export function setActiveAccount(account: Object) {
	return (dispatch: Function): void => dispatch({
		type: ACTIVE_ACCOUNT,
		data: {
			account,
		},
	});
}

export function getAccountStatistics(accountId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_STATISTICS,
		payload: {
			request: {
				url: `/accounts/${accountId}/statistics`,
			},
		},
	});
}

export function getAccountPositions(accountId: string, page: number = 1, pageSize: number = 50 ) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_POSITIONS,
		payload: {
			request: {
				url: `/accounts/${accountId}/positions`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}
