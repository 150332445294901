// @flow

import uniqBy from 'lodash/uniqBy';
import type { Account } from './AccountsReducer';

const initialState = {
	users: [],
	usersTotal: 0,
	isFetchingUsers: false,
	usersIsFetched: false,
	fullUser: {},
	isFetchingUser: false,
	userIsFetched: false,
	error: false,
	kycReports: [],
	kycReportsTotal: 0,
	isFetchingKycReports: false,
	sFetchedKycReports: false,
	kycReportsError: false,
	documents: [],
	documentsError: false,
	isFetchingKycDocuments: false,
	sFetchedKycDocuments: false,
	userReportingCurrency: '',
	userSettings: {},
	isFetchingUserSettings: false,
	userSettingsIsFetched: false,
	isUpdatingUserSettings: false,
	userSettingsIsUpdated: false,
	suspendReason: {},
	isFetchingSuspendReason: false,
	suspendReasons: [],
	isFetchingSuspendReasons: false,
	suspensions: [],
	isFetchingSuspensions: false,
};

export type UserInfo = {
	birthday: string,
	firstName: string,
	lastName: string,
	dialingPrefix: string,
	phone: string,
	suitabilityQuestions: Object,
}
export type UserAddress = {
	addressLine1: ?string,
	addressLine2: ?string,
	street: string,
	streetNumber: string,
	city: ?string,
	countryCode: ?string,
	zipCode: ?string,
	country: {
		name: string,
		a3: string,
	},
	state: string,
}
export type SuspendReason = {
	applicationId: string,
	createdAt: string,
	deletedAt: string,
	description: string,
	id: string,
	title: string,
	updatedAt: string,
}
type Document = {
	id: string,
	documentType: string,
	files: Array<{
		createdAt: string,
		filename: string,
		id: string,
		url: string,
	}>,
	name: string,
}
export type FullUser = {
	id: string,
	name: string,
	email: string,
	status: string,
	name: string,
	initials: string,
	createdAt: string,
	groupId: string,
	group: {
		name: string,
		id: string,
	},
	userAddress: UserAddress,
	userInfo: UserInfo,
	accounts: Array<Account>,
	meta: {
		kyc: {
			applicantId: string,
		}
	},
	suspended: boolean,
	suspensions: Array<{
		id: string,
		suspendReasonId: string,
		updatedAt: string,
		note: string,
		suspendReason: SuspendReason,
	}>,
	documents: Array<Document>,
	riskLevel: string,
}
export type User = {
	id: string,
	name: string,
	email: string,
	status: string,
	createdAt: string,
	userInfo: UserInfo,
	group: {
		name: string,
		id: string,
	},
	userAddress: UserAddress,
	suspended: true,
}
export type Users = Array<User>
export type KycReport = {
	id: string,
	createdAt: string,
	providerInstanceId: string,
	reportType: string,
	reportData: Object,
	status: string,
	userId: string,
}
export type KycReports = Array<KycReport>
export type Documents = Array<Document>
export type UserSettings = {
	marginTrading: boolean,
	collateralMultiplier: string,
	accrualPeriod: string,
	annualInterestRate: string,
	compoundPeriod: string,
	postTradeSettlement: boolean,
	maximumRiskExposureAmount: string,
	maximumRiskExposureInstrument: string,
}
export type Suspension = {
	id: string,
    note: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    suspendReasonId: string,
}
export type UsersState = {
	users: Users,
	fullUser: FullUser,
	isFetchingUsers: boolean,
	usersIsFetched: boolean,
	isFetchingUser: boolean,
	userIsFetched: boolean,
	error: boolean | Object,
	usersTotal: number,
	kycReports: Array<KycReport>,
	kycReportsTotal: number,
	isFetchingKycReports: boolean,
	sFetchedKycReports: boolean,
	kycReportsError: boolean | Object,
	documents: Documents,
	documentsError: boolean,
	isFetchingKycDocuments: boolean,
	sFetchedKycDocuments: boolean,
	userReportingCurrency: string,
	userSettings: UserSettings,
	isFetchingUserSettings: boolean,
	userSettingsIsFetched: boolean,
	isUpdatingUserSettings: boolean,
	userSettingsIsUpdated: boolean,
	suspendReason: SuspendReason,
	isFetchingSuspendReason: boolean,
	suspendReasons: Array<SuspendReason>,
	isFetchingSuspendReasons: boolean,
	suspensions: Array<Suspension>,
	isFetchingSuspensions: boolean,
	taggedItems: Object;
}
type UsersAction = {
	type:
	| 'USERS'
	| 'USERS_FAIL'
	| 'USERS_SUCCESS'
	| 'SEARCH_USERS'
	| 'SEARCH_USERS_FAIL'
	| 'SEARCH_USERS_SUCCESS',
	payload: {
		data: Users,
		headers: {
			'total-count': number,
		}
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}
type UserAction = {
	type:
	| 'FULL_USER'
	| 'FULL_USER_FAIL'
	| 'FULL_USER_SUCCESS'
	| 'MOVE_USER_TO_GROUP_SUCCESS'
	| 'UPDATE_STATUS_SUCCESS'
	| 'CREATE_APPLICANT_SUCCESS'
	| 'SUSPEND_USER_SUCCESS'
	| 'UNSUSPEND_USER_SUCCESS'
	| 'UPDATE_USER_SUCCESS',
	payload: {
		data: FullUser,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}
type DocumentsAction = {
	type:
	| 'DOCUMENTS'
	| 'DOCUMENTS_FAIL'
	| 'DOCUMENTS_SUCCESS',
	payload: {
		data: Documents,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}
type KycAction = {
	type:
	| 'KYC_REPORTS'
	| 'KYC_REPORTS_FAIL'
	| 'KYC_REPORTS_SUCCESS',
	payload: {
		data: Array<KycReport>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}

type CheckAction = {
	type:
		'TRIGGER_CHECK_SUCCESS',
	payload: {
		data: KycReport,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}
type CurrencyAction = {
	type:
	| 'USER_CURRENCY',
	data: {
		currency: string,
	},
}

type SettingsAction = {
	type:
	| 'USERS_SETTINGS'
	| 'USERS_SETTINGS_FAIL'
	| 'USERS_SETTINGS_SUCCESS'
	| 'UPDATE_USERS_SETTINGS'
	| 'UPDATE_USERS_SETTINGS_FAIL'
	| 'UPDATE_USERS_SETTINGS_SUCCESS',
	payload: {
		data: UserSettings,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}
type SuspendsAction = {
	type:
	| 'GET_SUSPEND_REASONS'
	| 'GET_SUSPEND_REASONS_SUCCESS'
	| 'GET_SUSPEND_REASONS_FAIL',
	payload: {
		data: Array<SuspendReason>,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}

type SuspendAction = {
	type:
	| 'GET_SUSPEND_REASON'
	| 'GET_SUSPEND_REASON_SUCCESS'
	| 'GET_SUSPEND_REASON_FAIL'
	| 'ADD_SUSPEND_REASON_SUCCESS'
	| 'UPDATE_SUSPEND_REASON_SUCCESS',
	payload: {
		data: SuspendReason,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}

type SuspensionsAction = {
	type:
	| 'GET_SUSPENSIONS'
	| 'GET_SUSPENSIONS_SUCCESS'
	| 'GET_SUSPENSIONS_FAIL',
	payload: {
		data: Array<Suspension>,
	},
	error: {
		response: {
			data: {
				message: string,
			}
		}
	},
}

type Action =
	| UserAction
	| UsersAction
	| DocumentsAction
	| KycAction
	| CurrencyAction
	| SettingsAction
	| CheckAction
	| SuspendAction
	| SuspendsAction
	| SuspensionsAction
	| TagsAction;
export default function usersReducer(
	state: UsersState = initialState,
	action: Action,
): UsersState {
	switch (action.type) {
	case 'USERS':
		return {
			...state,
			isFetchingUsers: true,
			usersIsFetched: false,
			users: [],
		};
	case 'USERS_FAIL':
		return {
			...state,
			isFetchingUsers: false,
			usersIsFetched: false,
			users: [],
			error: action.error,
		};
	case 'USERS_SUCCESS':
		return {
			...state,
			isFetchingUsers: false,
			usersIsFetched: true,
			error: false,
			users: action.payload.data,
			usersTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'FULL_USER':
		return {
			...state,
			isFetchingUser: true,
			userIsFetched: false,
			fullUser: {},
		};
	case 'FULL_USER_FAIL':
		return {
			...state,
			isFetchingUser: false,
			userIsFetched: false,
			error: action.error,
		};
	case 'FULL_USER_SUCCESS':
	case 'MOVE_USER_TO_GROUP_SUCCESS':
	case 'CREATE_APPLICANT_SUCCESS':
	case 'SUSPEND_USER_SUCCESS':
	case 'UNSUSPEND_USER_SUCCESS':
	case 'UPDATE_USER_SUCCESS':
		return {
			...state,
			isFetchingUser: false,
			userIsFetched: true,
			error: false,
			fullUser: action.payload.data,
		};
	case 'UPDATE_STATUS_SUCCESS':
		return {
			...state,
			users: state.users.map((user) => (
				user.id === action.payload.data.id
					? {
						...user,
						status: action.payload.data.status,
					}
					: user
			)),
			fullUser: {
				...state.fullUser,
				status: action.payload.data.status,
			},
		};
	case 'KYC_REPORTS':
		return {
			...state,
			isFetchingKycReports: true,
			isFetchedKycReports: false,
			kycReports: [],
		};
	case 'KYC_REPORTS_FAIL':
		return {
			...state,
			isFetchingKycReports: false,
			isFetchedKycReports: false,
			kycReportsError: action.error,
		};
	case 'KYC_REPORTS_SUCCESS':
		return {
			...state,
			isFetchingKycReports: false,
			isFetchedKycReports: true,
			kycReports: action.payload.data,
			kycReportsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'TRIGGER_CHECK_SUCCESS':
		return {
			...state,
			kycReports: uniqBy([...state.kycReports, action.payload.data], 'id'),
		};
	case 'DOCUMENTS':
		return {
			...state,
			isFetchingDocuments: true,
		};
	case 'DOCUMENTS_FAIL':
		return {
			...state,
			isFetchingDocuments: false,
			isFetchedDocuments: false,
			kycReportsError: action.error,
		};
	case 'DOCUMENTS_SUCCESS':
		return {
			...state,
			isFetchingDocuments: false,
			isFetchedDocuments: true,
			documents: action.payload.data,
		};
	case 'SEARCH_USERS':
		return {
			...state,
			isFetchingUsers: true,
			usersIsFetched: false,
		};
	case 'SEARCH_USERS_FAIL':
		return {
			...state,
			isFetchingUsers: false,
			usersIsFetched: false,
			error: action.error,
		};
	case 'SEARCH_USERS_SUCCESS':
		return {
			...state,
			isFetchingUsers: false,
			usersIsFetched: true,
			error: false,
			users: action.payload.data,
			usersTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'USER_CURRENCY':
		return {
			...state,
			userReportingCurrency: action.data.currency,
		};
	case 'USERS_SETTINGS':
		return {
			...state,
			isFetchingUserSettings: true,
			userSettingsIsFetched: false,
		};
	case 'USERS_SETTINGS_FAIL':
		return {
			...state,
			isFetchingUserSettings: false,
			userSettingsIsFetched: false,
			error: action.error,
		};
	case 'USERS_SETTINGS_SUCCESS':
		return {
			...state,
			isFetchingUserSettings: false,
			userSettingsIsUpdated: true,
			error: false,
			userSettings: action.payload.data,
		};
	case 'UPDATE_USERS_SETTINGS':
		return {
			...state,
			isUpdatingUserSettings: true,
			userSettingsIsUpdated: false,
		};
	case 'UPDATE_USERS_SETTINGS_FAIL':
		return {
			...state,
			isUpdatingUserSettings: false,
			userSettingsIsUpdated: false,
			error: action.error,
		};
	case 'UPDATE_USERS_SETTINGS_SUCCESS':
		return {
			...state,
			isUpdatingUserSettings: false,
			userSettingsIsUpdated: true,
			error: false,
			userSettings: { ...state.userSettings, ...action.payload.data },
		};
	case 'GET_SUSPEND_REASONS':
		return {
			...state,
			isFetchingSuspendReasons: true,
			suspendReasons: [],
			error: false,
		};
	case 'GET_SUSPEND_REASONS_FAIL':
		return {
			...state,
			isFetchingSuspendReasons: false,
			error: action.error,
		};
	case 'GET_SUSPEND_REASONS_SUCCESS':
		return {
			...state,
			isFetchingSuspendReasons: false,
			suspendReasons: action.payload.data,
		};
	case 'GET_SUSPEND_REASON':
		return {
			...state,
			isFetchingSuspendReason: true,
			suspendReason: {},
			error: false,
		};
	case 'GET_SUSPEND_REASON_FAIL':
		return {
			...state,
			isFetchingSuspendReason: false,
			error: action.error,
		};
	case 'GET_SUSPEND_REASON_SUCCESS':
		return {
			...state,
			isFetchingSuspendReason: false,
			suspendReason: action.payload.data,
		};
	case 'UPDATE_SUSPEND_REASON_SUCCESS':
		return {
			...state,
			isFetchingSuspendReason: false,
			suspendReason: action.payload.data,
			suspendReasons: state.suspendReasons.map((el) => {
				if (el.id === action.payload.data.id) {
					return action.payload.data;
				} return el;
			}),
		};
	case 'ADD_SUSPEND_REASON_SUCCESS':
		return {
			...state,
			isFetchingSuspendReason: false,
			suspendReasons: [...state.suspendReasons, action.payload.data],
		};
	case 'GET_SUSPENSIONS':
		return {
			...state,
			isFetchingSuspensions: true,
			suspensions: [],
			error: false,
		};
	case 'GET_SUSPENSIONS_FAIL':
		return {
			...state,
			isFetchingSuspensions: false,
			error: action.error,
		};
	case 'GET_SUSPENSIONS_SUCCESS':
		return {
			...state,
			isFetchingSuspensions: false,
			suspensions: action.payload.data,
		};
	default:
		return state;
	}
}
