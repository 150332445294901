// @flow

import React from 'react';
import { connect } from 'react-redux';
//= import components
import Checkbox from '../../../components/UiElements/Checkbox';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
//= import actions
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
// = import types
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { Documents } from '../../../modules/reducers/UsersReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Value = {
	provider: string,
	check: string,
}

type Props = {
	form: *,
	submitChanges: (Value) => void,
	handleCancelClick: () => void,
	applicationId: string,
	getProvidersInstances: (string) => void,
	providerInstances: Array<ProviderInstance>,
	documents: Documents,
	type: string
}
type LocalState = {
	errorMessage: string,
	providerSelected: string,
	performAll: boolean,
}

class Kyc extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		providerSelected: 'all',
		performAll: false,
	}

	componentDidMount() {
		const {
			getProvidersInstances: getProvidersInstancesAction,
			applicationId,
			providerInstances,
		} = this.props;
		if (!providerInstances.length) {
			getProvidersInstancesAction(applicationId);
		}
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			providerSelected: 'all',
			performAll: false,
		});
	}

	selectProvider = (value: Array<string>) => {
		const { getFieldValue, setFieldsValue } = this.props.form;
		const checkType: Array<string> = getFieldValue('checkType');
		if (value.length === 0) {
			setFieldsValue({ checkType: [] });
			this.setState({
				providerSelected: 'all',
			});
		} else if (value.length === 2) {
			this.setState({
				providerSelected: 'all',
			});
		} else {
			const selected = value[0];
			this.setState({
				providerSelected: selected,
			});
			if (!StatusHelpers.checkTypes[selected].includes(checkType)) {
				setFieldsValue({ checkType: [] });
			}
		}
	}

	selectAll = (e) => {
		const { form: { setFieldsValue }, providerInstances } = this.props;
		const kycProviders = providerInstances.filter((el) => (el.provider.type === 'kyc')).map((provider) => provider.provider.name);
		if (e.target.checked) {
			this.setState({
				performAll: true,
			});
			setFieldsValue({ checkType: StatusHelpers.checkTypes.all, provider: kycProviders });
		} else {
			this.setState({
				performAll: false,
			});
			setFieldsValue({ checkType: [], provider: [] });
		}
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err: Object, values: Value) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator },
			handleCancelClick,
			providerInstances,
			documents,
			type,
		} = this.props;
		const {
			errorMessage,
			providerSelected,
			performAll,
		} = this.state;

		const kycProviders = type === 'checkProofOfAddress'
			? providerInstances.filter((el) => el.provider.supports.includes('proofOfAddress'))
			: providerInstances.filter((el) => el.provider.type === 'kyc');

		const availableCheckTypes = type === 'checkProofOfAddress'
			? { internal: ['proofOfAddress'], all: ['proofOfAddress'] }
			: StatusHelpers.checkTypes;

		const availableDocuments = type === 'checkProofOfAddress'
			? documents.filter((el) => StatusHelpers.documentTypes.includes(el.documentType))
			: documents;

		const { Option } = Select;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		return (
			<>
				{kycProviders.length
					?			(
						<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
							<FormItem label="PROVIDER" help="" {...formItemLayout}>
								{getFieldDecorator('provider', {
									rules: [{ required: true, message: "Provider can't be left empty" }],
									initialValue: type === 'checkProofOfAddress' ? kycProviders[0]?.provider?.name : undefined,
								})(
									<Select
										allowClear
										showSearch
										placeholder="Select Provider"
										mode="multiple"
										onChange={this.selectProvider}
										disabled={performAll}
									>
										{kycProviders.map((instance) => (
											<Option
												value={instance.provider.name}
												key={instance.id}
												className={styles.options}
											>
												{instance.provider.displayName}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="CHECK TYPE" help="" {...formItemLayout}>
								{getFieldDecorator('checkType', {
									rules: [{ required: true, message: "Check Type can't be left empty" }],
									initialValue: type === 'checkProofOfAddress' ? availableCheckTypes[providerSelected] : undefined,
								})(
									<Select
										allowClear
										showSearch
										placeholder="Select Check Type"
										mode="multiple"
										disabled={performAll}
									>
										{availableCheckTypes[providerSelected].map((check) => (
											<Option
												value={check}
												key={check}
												className={styles.options}
											>
												{check}
											</Option>
										))}
									</Select>,
								)}
							</FormItem>
							<FormItem label="PERFORM ALL CHECKS" {...formItemLayout} help="">
								{getFieldDecorator('performAll', {
									valuePropName: 'checked',
								})(
									<Checkbox onChange={this.selectAll} disabled={type === 'checkProofOfAddress'} />,
								)}
							</FormItem>
							{!!availableDocuments.length && (
								<div className={styles.document}>
									<p>
										Attached Documents
									</p>
									{availableDocuments.map((document) => document.files.map((file) => (
										<img
											key={file.id}
											src={file.url}
											alt="KYC document"
										/>
									)))}
								</div>
							)}
							{errorMessage && (
								<ErrorMessage message={errorMessage} />
							)}
							<div className="form-buttons">
								<FormItem>
									<CPButton ghost action={handleCancelClick} text="cancel" />
								</FormItem>
								<FormItem>
									<CPButton type="primary" action={this.submit} text="check" />
								</FormItem>
							</div>
						</Form>
					)
					: (
						<div className={styles.noProvider}>
							<h4>
								You are currently unable to perform the KYC check,
								as there is no enabled KYC provider for this application.
								Please contact support or enable the KYC provider for your application.
							</h4>
						</div>
					)}
			</>
		);
	}
}

const KycForm = Form.create()(Kyc);

const mapStateToProps = (state: State) => ({
	providerInstances: state.providers.providersInstances,
});
const mapDispatchToProps = {
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(KycForm);
