// @flow

import React from 'react';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Card from '../../../components/UiElements/Card';
import Icon from '../../../components/UiElements/Icon';
//= import types
import type { LimitControlType, Currency, MerchantScope } from '../../../modules/reducers/CardsReducer';
//= import styles
import styles from '../assets/cards.module.scss';
import CPButton from '../../../components/UiElements/Button';

type Props = {
	limitControlsData: Array<{
		level: string,
		limitControl: LimitControlType,
	}>,
	title: string,
	addLimitControl?: Function,
	handleOpenDrawer?: ?Function,
	handleEditClick?: Function,
	applicationId: string,
}

const LimitControls = (props: Props) => {
	const {
		limitControlsData,
		title,
		addLimitControl,
		handleOpenDrawer,
		handleEditClick,
		applicationId,
	} = props;

	const columns = [
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 272,
			render: (text: string) => (
				<div className={styles.linkText}>
					{text}
				</div>
			),
		},
		{
			title: 'amount limit',
			dataIndex: 'amountLimit',
			key: 'amountLimit',
			width: 160,
		},
		{
			title: 'limit control currency',
			dataIndex: 'currency',
			key: 'currency',
			width: 208,
			render: (text: Currency) => (
				<div className="capitalize">
					{text.name || '-'}
				</div>
			),
		},
		{
			title: 'usage limit',
			dataIndex: 'usageLimit',
			key: 'usageLimit',
			width: 144,
		},
		{
			title: 'velocity window',
			dataIndex: 'velocityWindow',
			key: 'velocityWindow',
			width: 176,
		},
		{
			title: 'is active',
			dataIndex: 'isActive',
			key: 'isActive',
			width: 112,
			render: (text: boolean) => (
				<div style={text ? { maxWidth: 80 } : { maxWidth: 80, color: '#999999' }}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'transaction type strategy',
			dataIndex: 'transactionTypeStrategy',
			key: 'transactionTypeStrategy',
			width: 240,
			render: (text: string) => (
				<div className="wrap">
					{text}
				</div>
			),
		},
		{
			title: 'transaction types',
			dataIndex: 'transactionTypes',
			key: 'transactionTypes',
			width: 240,
			render: (text: Array<{[string]: string}>) => (
				<div className="wrap">
					{text.length ? text.map((el) => el.name).join(', ') : '-'}
				</div>
			),
		},
		{
			title: 'entry mode strategy',
			dataIndex: 'entryModeStrategy',
			key: 'entryModeStrategy',
			width: 240,
			render: (text: string) => (
				<div className="wrap">
					{text}
				</div>
			),
		},
		{
			title: 'entry modes',
			dataIndex: 'entryModes',
			key: 'entryModes',
			width: 240,
			render: (text: Array<{[string]: string}>) => (
				<div className="wrap">
					{text.length ? text.map((el) => el.name).join(', ') : '-'}
				</div>
			),
		},
		{
			title: 'merchant scope',
			dataIndex: 'merchantScope',
			key: 'merchantScope',
			width: 240,
			render: (text: MerchantScope) => (
				<div className="wrap">
					{text ? text.name : '-'}
				</div>
			),
		},

	];

	const completeColumns = handleEditClick
		? [
			...columns,
			{
				title: 'edit',
				dataIndex: '',
				key: 'action',
				width: 80,
				render: () => (
					<Icon name="Edit" />
				),
				onCell: (record: LimitControlType) => ({
					onClick: (e) => {
						e.stopPropagation();
						handleEditClick(record);
					},
				}),
			},
		]
		: columns;

	return (
		<Card className={styles.designCards} bordered={false}>
			<div className="flex-container">
				<h2>{title}</h2>
				{!!addLimitControl && <CPButton text="add new limit control" icon="Plus" ghost action={addLimitControl} />}
			</div>
			<CustomTable
				columns={completeColumns}
				data={limitControlsData.map((el) => el.limitControl)}
				total={limitControlsData.length}
				headerHeight={16}
				numberOnPage={limitControlsData.length}
				place="limitControls"
				applicationId={applicationId}
				onRowAction={handleOpenDrawer}
				rowClassName="clickable"
			/>
		</Card>
	);
};

LimitControls.defaultProps = {
	addLimitControl: false,
	handleEditClick: false,
	handleOpenDrawer: false,
};

export default LimitControls;
