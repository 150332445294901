// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactJson from 'react-json-view';
import orderBy from 'lodash/orderBy';
//= import components
import Title from '../../../components/UiElements/Title';
import CPButton from '../../../components/UiElements/Button';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Card from '../../../components/UiElements/Card';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import Drawer from '../../../components/UiElements/Drawer';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import InfoCard from '../../../components/UiElements/CustomCards/InfoCard';
import PaymentTimeline from '../components/PaymentTimeline';
//= import methods
import { getCardPayment } from '../../../modules/actions/CardAction';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Payment } from '../../../modules/reducers/CardsReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	cardPayment: Payment,
	isFetchingCardPayment: boolean,
	getCardPayment: (string, string) => void,
	match: {
		params: {
			applicationId: string,
			paymentId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
		length: number,
	},
}
function CardPaymentPage(props: Props) {
	const {
		cardPayment,
		isFetchingCardPayment,
		getCardPayment: getCardPaymentAction,
		match: { params: { applicationId, paymentId } },
		history,
	} = props;

	const [drawerTitle, setDrawerTitle] = useState('');
	const [drawer, setDrawer] = useState(false);
	const [drawerData, setDrawerData] = useState({});

	useEffect(() => {
		getCardPaymentAction(applicationId, paymentId);
	}, [getCardPaymentAction, applicationId, paymentId]);

	const handleBackClicked = () => {
		props.history.goBack();
	};

	const handleOpenDrawer = (title: string, data: Object) => {
		setDrawer(true);
		setDrawerTitle(title);
		setDrawerData(data);
	};

	const onClose = () => {
		setDrawer(false);
	};

	const amountData = {
		originalAmount: PriceHelpers.formatAmount(
			PriceHelpers.formatNumber(
				cardPayment.originalAmount?.toString(),
				cardPayment.originalCurrency?.digits,
			),
			cardPayment.originalCurrency?.isoCode,
			Numbers.sign(cardPayment.originalAmount?.toString() || '0'),
		),
		billingAmount: PriceHelpers.formatAmount(
			PriceHelpers.formatNumber(
				cardPayment.billingAmount?.toString(),
				cardPayment.billingCurrency?.digits,
			),
			cardPayment.billingCurrency?.isoCode,
			Numbers.sign(cardPayment.billingAmount?.toString() || '0'),
		),
		settlementAmount: PriceHelpers.formatAmount(
			PriceHelpers.formatNumber(
				cardPayment.settlementAmount?.toString(),
				cardPayment.settlementCurrency?.digits,
			),
			cardPayment.settlementCurrency?.isoCode,
			Numbers.sign(cardPayment.settlementAmount?.toString() || '0'),
		),
	};
	const detailsData = {
		id: <CopyComponent
			text={cardPayment.id}
			content={cardPayment.id}
		/>,
		timeCreated: moment(cardPayment.createdAt).format('YYYY-MM-DD HH:mm:ss'),
		timeUpdated: moment(cardPayment.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
		cardId: (
			<a
				href={`/application/${applicationId}/cards/${cardPayment.cardId}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{cardPayment.cardId}
			</a>),
		acquirerId: cardPayment.acquirerId,
		entryMode: `${cardPayment.entryMode?.name} / ${cardPayment.entryMode?.description}`,
		mcc: `${cardPayment.mcc?.code} / ${cardPayment.mcc?.description}`,
		merchant: `${cardPayment.merchantId} / ${cardPayment.merchantName}`,
		transactionType: `${cardPayment.transactionType?.name} / ${cardPayment.transactionType?.description}`,
		transactionCountry: cardPayment.transactionCountry?.name,
		isSuspicious: cardPayment.isSuspicious ? 'Yes' : 'No',
		traceLogId: cardPayment.traceLogId,
		transLink: cardPayment.transLink,
	};

	const riskData = {
		changeAccountStatusToSuspended: cardPayment.riskActions?.changeAccountStatusToSuspended ? <span className="red-text">Yes</span> : 'No',
		changeCardStatusToRisk: cardPayment.riskActions?.changeCardStatusToRisk ? <span className="red-text">Yes</span> : 'No',
		markTransactionAsSuspicious: cardPayment.riskActions?.markTransactionAsSuspicious ? <span className="red-text">Yes</span> : 'No',
		notifyCardholderBySendingTaisNotification: cardPayment.riskActions?.notifyCardholderBySendingTaisNotification ? <span className="red-text">Yes</span> : 'No',
		rejectTransaction: cardPayment.riskActions?.rejectTransaction ? <span className="red-text">Yes</span> : 'No',
	};
	const securityData = {
		'3DSecure': cardPayment.securityChecks?.['3DSecure'] ? 'Yes' : 'No',
		avs: cardPayment.securityChecks?.avs ? 'Yes' : 'No',
		cardExpirationDatePresent: cardPayment.securityChecks?.cardExpirationDatePresent ? 'Yes' : 'No',
		chipData: cardPayment.securityChecks?.chipData ? 'Yes' : 'No',
		cvv2: cardPayment.securityChecks?.cvv2 ? 'Yes' : 'No',
		magneticStripe: cardPayment.securityChecks?.magneticStripe ? 'Yes' : 'No',
		offlinePin: cardPayment.securityChecks?.offlinePin ? 'Yes' : 'No',
		onlinePin: cardPayment.securityChecks?.onlinePin ? 'Yes' : 'No',
		phoneNumber: cardPayment.securityChecks?.phoneNumber ? 'Yes' : 'No',
	};
	const timelineData = cardPayment.paymentActions?.length
		? orderBy(cardPayment.paymentActions, 'createdAt')
		: [];
	return (
		<>
			{!isFetchingCardPayment ? (
				<div>
					<Title
						backAction={history.length > 1 ? handleBackClicked : undefined}
						title="Card Payment"
						tags={[cardPayment.status?.toLowerCase()]}
					/>
					<div className="page-container">
						<Row gutter={24}>
							<Col span={16} className="col-margin">
								<InfoCard
									data={amountData}
									title="Amounts"
								/>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<TextCard
									left="left-2"
									title="Payment Details"
									data={detailsData}
									colNumber={1}
								/>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12} className="col-margin">
								<Card className="payload" bordered={false}>
									<h2>Response Code</h2>
									<p className="raw-response">
										{ActivityHelpers.transformToReadable(cardPayment.responseCode || '')}
									</p>
									{cardPayment.rejectionReason
										&& (
											<p className="raw-response">
												{`${cardPayment.rejectionReason?.code} ${cardPayment.rejectionReason?.description}`}
											</p>
										)}
								</Card>
							</Col>
							<Col span={12} className="col-margin">
								<Card className="payload" bordered={false}>
									<h2>Message</h2>
									<p className="raw-response">
										{cardPayment.rawMessage || '-'}
									</p>
								</Card>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<PaymentTimeline
									timelineData={timelineData}
									handleOpenDrawer={handleOpenDrawer}
								/>
								<TextCard
									title="Risk Actions"
									data={riskData}
									colNumber={1}
								/>
								<TextCard
									title="Security Checks"
									data={securityData}
									colNumber={1}
								/>
							</Col>
							<Col span={12}>
								<Card className="payload" bordered={false}>
									<h2>Payload</h2>
									<div className="raw-response">
										<ReactJson
											src={cardPayment}
											theme="shapeshifter:inverted"
											enableClipboard={false}
											style={{
												backgroundColor: '#F0F6FA',
												padding: '16px',
											}}
											collapseStringsAfterLength={36}
										/>
									</div>
								</Card>
							</Col>
						</Row>
					</div>
					<Drawer
						width={600}
						placement="right"
						closable
						onClose={onClose}
						visible={drawer}
						className="drawer"
					>
						<h2>{`${ActivityHelpers.transformToReadable(drawerTitle || '')} Payload`}</h2>
						<div className="raw-response">
							<ReactJson
								src={drawerData}
								theme="shapeshifter:inverted"
								enableClipboard={false}
								style={{
									backgroundColor: '#F0F6FA',
									padding: '16px',
								}}
								collapseStringsAfterLength={36}
							/>
						</div>
						<div className="drawer-button">
							<CPButton
								action={onClose}
								text="close"
							/>
						</div>
					</Drawer>
				</div>
			)
				: <Spin spinning={isFetchingCardPayment} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	cardPayment: state.cards.cardPayment,
	isFetchingCardPayment: state.cards.isFetchingCardPayment,
});

const mapDispatchToProps = {
	getCardPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentPage);
