// @flow

import React, { Component } from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import Input from '../../../components/UiElements/Input';
import CPButton from '../../../components/UiElements/Button';
import Icon from '../../../components/UiElements/Icon';
import MenuIcon from '../../../components/UiElements/Icon/MenuIcon';
//= import sub components
const FormItem = Form.Item;

export type Values = {
	username: string,
	password: string,
	appName: string,
}

type Props = {
	form: *,
	handleSubmit: Values => Promise<void>,
		loading: boolean,
}

type State = {
	preview: boolean,
}
class LoginForm extends Component<Props, State> {
	state = {
		preview: false,
	}

	handlePreview = () => {
		this.setState((prevState) => ({
			preview: !prevState.preview,
		}));
	}

	submit = (e) => {
		e.preventDefault();
		const { form, handleSubmit } = this.props;
		form.validateFields((err, values) => {
			if (!err) {
				handleSubmit(values)
					.catch((error) => {
						form.setFields({
							username: {
								value: values.username,
								errors: [new Error('')],
							},
							password: {
								value: values.password,
								errors: [new Error('')],
							},
							appName: {
								value: values.appName,
								errors: [new Error(error.error && error.error.response ? error.error.response.data.message : 'Network Error')],
							},
						});
					});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator }, loading,
		} = this.props;
		const { preview } = this.state;
		return (
			<div className="login-container">
				<MenuIcon type="CompanyLogo" styles={{ margin: '64px 160px 40px 160px' }} width="278px" height="48px" color="#1D0D41" viewBox="0 0 174 40" />
				<Form layout="vertical" onSubmit={this.submit} className="modal-form login-form" hideRequiredMark>
					<FormItem
						label="E-MAIL"
					>
						{getFieldDecorator('username', {
							rules: [{
								required: true, message: 'E-mail is required!',
							}],
						})(
							<Input
								name="username"
							/>,
						)}
					</FormItem>
					<FormItem
						label="PASSWORD"
					>
						<div style={{ position: 'relative' }}>
							{getFieldDecorator('password', {
								rules: [{
									required: true, message: 'Please input your password!',
								}],
							})(
								<Input type={preview ? 'text' : 'password'} name="password" />,
							)}
							<span className="preview-button">
								<Icon name={preview ? 'ViewDisabled' : 'View'} onClick={this.handlePreview} size={18} />
							</span>
						</div>
					</FormItem>
					<FormItem
						label="APPLICATION NAME"
					>
						{getFieldDecorator('appName', {
							rules: [{
								required: true, message: 'Please input your application name!',
							}],
						})(
							<Input
								name="appName"
							/>,
						)}
					</FormItem>
					<FormItem>
						<div className="login-form-button">
							<CPButton
								type="primary"
								loading={loading}
								htmlType="submit"
								text={loading ? '' : 'SIGN IN'}
							/>
						</div>
					</FormItem>
				</Form>
			</div>
		);
	}
}

const WrappedLoginForm = Form.create()(LoginForm);

export default WrappedLoginForm;
