import React from 'react';
import { Switch, Route } from 'react-router-dom';
//= import components
import Customer from '../Customer';
import FourZeroFour from '../Page/404';

const PrivateCustomerRoutes = () => (
	<div>
		<Switch>
			<Route
				exact
				path="/profile"
				component={FourZeroFour}
			/>
			<Route
				exact
				path="/support"
				component={FourZeroFour}
			/>
			<Route
				path="/"
				component={Customer}
			/>
		</Switch>
	</div>
);

export default PrivateCustomerRoutes;
