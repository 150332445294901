// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
//= import components
import Search from '../../../components/UiElements/Search';
import Filter from '../../../components/UiElements/Filter';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Spin from '../../../components/UiElements/Spin';
import EmptyGroup from '../../../components/UiElements/Illustrations/EmptyGroup';
import CustomTable from '../../../components/UiElements/CustomTable';
import Tag from '../../../components/UiElements/Tag';
import CPButton from '../../../components/UiElements/Button';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import actions
import { getUsers, searchUsers } from '../../../modules/actions/UsersActions';
import { getStaticCountries } from '../../../modules/actions/StaticActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import selectors
import { countryMappedSelector } from '../../../modules/selectors/UsersSelector';
//= import types
import type { Country } from '../../../modules/reducers/StaticReducer';
import type { UserInfo, UserAddress, User } from '../../../modules/reducers/UsersReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getUsers: (string, number, number, string) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	searchUsers: (string, string) => Promise<Object>,
	getStaticCountries: () => void,
	history: {
		push: string => void,
	},
	groupUsersPage: number,
	usersTotal: number,
	countries: { [string]: Object },
	countriesOptions: Array<Country>,
	applicationId: string,
	groupId: string,
	filters: { [string]: string },
	currentSearch: string,
	update: boolean,
	groupUsersTotal: number,
}

const UsersTab = (props: Props) => {
	const {
		groupUsersPage,
		applicationId,
		usersTotal,
		countries,
		filters,
		groupId,
		countriesOptions,
		currentSearch,
		setCurrentPage: setCurrentPageAction,
		getUsers: getUsersAction,
		searchUsers: searchUsersAction,
		getStaticCountries: getStaticCountriesAction,
		update,
		groupUsersTotal,
	} = props;

	const [users, setUsers] = useState([]);
	const [dataCompleted, setCompleted] = useState(false);
	const [filterVisible, setFilterVisible] = useState(false);

	useEffect(() => {
		getStaticCountriesAction();
	}, [getStaticCountriesAction]);

	useEffect(() => {
		const groupFilters = { ...filters, groupId };
		if (currentSearch) {
			searchUsersAction(applicationId, currentSearch)
				.then((res) => {
					setUsers(res.payload.data);
				});
		} else {
			getUsersAction(applicationId, groupUsersPage, 50, queryString.stringify(groupFilters, { arrayFormat: 'comma' }))
				.then((res) => {
					setUsers(res.payload.data);
					setCompleted(true);
				});
		}
	}, [
		filters,
		currentSearch,
		groupUsersPage,
		usersTotal,
		applicationId,
		getUsersAction,
		groupId,
		searchUsersAction,
		update,
	]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('groupUsersPage', page);
	};

	const columns = [
		{
			title: 'date joined',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 112,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
		{
			title: 'name',
			dataIndex: 'userInfo',
			key: 'name',
			width: 192,
			render: (userInfo: UserInfo, record: User) => (
				<a
					href={`/application/${applicationId}/clients/${record.id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{userInfo ? `${userInfo.firstName || 'Name Not Set'} ${userInfo.lastName || ''}` : 'Name Not Set'}
				</a>
			),
		},
		{
			title: 'e-mail',
			dataIndex: 'email',
			key: 'email',
			width: 240,
		},
		{
			title: 'country',
			dataIndex: 'userAddress',
			key: 'country',
			width: 160,
			render: (address: UserAddress) => (
				<div>
					{address && address.countryCode && Object.entries(countries).length
						? countries[address.countryCode].name
						: '-'}
				</div>
			),
		}, {
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 160,
			render: (text: string) => (
				<Tag status={text} />
			),
		},
	];

	return (
		<div>
			<Row gutter={24} className="filter-in-tab">
				<Col span={6}>
					<Search
						placeholder="Search For Clients..."
						place="groupUsers"
						disabled={false}
					/>
				</Col>
				<Filter
					fields={{
						country: countriesOptions,
						status: StatusHelpers.statuses,
					}}
					visible={filterVisible}
					place="groupUsers"
					position={288}
					closeFunction={() => setFilterVisible(false)}
				/>
				<div className="logs">
					<CPButton
						action={() => setFilterVisible(true)}
						text="filter"
						icon="Funnel"
					/>
				</div>
			</Row>
			{dataCompleted
				? (
					<div>
						{groupUsersTotal === 0
							? (
								<div className="center">
									<EmptyGroup />
									<p>This Group is currently empty. Transfer Clients from another Group.</p>
								</div>
							)
							: (
								<CustomTable
									columns={columns}
									data={users}
									total={usersTotal}
									headerHeight={304}
									handlePageChange={handlePageChange}
									currentPage={groupUsersPage}
									place="groupUsers"
									applicationId={applicationId}
								/>
							)}
					</div>
				)
				: <Spin spinning={!dataCompleted} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	groupUsersPage: state.ui.groupUsersPage,
	usersTotal: state.users.usersTotal,
	filters: state.ui.groupUsersFilters,
	countries: countryMappedSelector(state),
	countriesOptions: state.static.countries,
	currentSearch: state.ui.groupUsersSearch,
});

const mapDispatchToProps = {
	getUsers,
	setCurrentPage,
	searchUsers,
	getStaticCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
