// @flow

import {
	GROUP,
	GROUPS,
	ADD_GROUP,
	UPDATE_GROUP,
	DELETE_GROUP,
	MOVE_USER_TO_GROUP,
	GROUP_USERS,
} from '../const/types';

export function getGroup(appId: string, groupId: string) {
	return (dispatch: Function): void => dispatch({
		type: GROUP,
		payload: {
			request: {
				url: `/applications/${appId}/groups/${groupId}`,
			},
		},
	});
}

export function getGroups(appId: string) {
	return (dispatch: Function): void => dispatch({
		type: GROUPS,
		payload: {
			request: {
				url: `/applications/${appId}/groups`,
			},
		},
	});
}

export function addGroup(appId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_GROUP,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${appId}/groups`,
				data,
			},
		},
	});
}

export function updateGroup(appId: string, groupId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_GROUP,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${appId}/groups/${groupId}`,
				data,
			},
		},
	});
}

export function deleteGroup(appId: string, groupId: string) {
	return (dispatch: Function): void => dispatch({
		type: DELETE_GROUP,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${appId}/groups/${groupId}`,
			},
		},
	});
}

export function moveUsersToGroup(userId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: MOVE_USER_TO_GROUP,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}/update-group`,
				data,
			},
		},
	});
}

export function getGroupUsers(appId: string, groupId: string, pagType: string, pagOffset: string, pagLimit: number) {
	return (dispatch: Function): void => dispatch({
		type: GROUP_USERS,
		payload: {
			request: {
				headers: {
					'pagination-type': pagType,
					'pagination-limit': pagLimit,
					'pagination-offset': pagOffset,
				},
				url: `applications/${appId}/users?groupId=${groupId}`,
			},
		},
	});
}
