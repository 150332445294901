// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MenuIcon from '../../../Icon/MenuIcon';
import TopBarActions from './TopBarActions';
import TopBanner from '../TopBanner';
import { check2FA } from '../../../../../modules/actions/OauthActions';

const ANNOUNCEMENT = "Strengthen your account's security by turning on two-factor authentication in the settings!";

type Props = {
	data: {
		inApp: boolean,
		oneApp: boolean,
		name: string,
		userName: string
	},
	check2FA: () => Promise
};

const TopBarLayout = (props: Props) => {
	const {
		data: {
			inApp, name, userName, oneApp,
		},
		check2FA: check2FAAction,
	} = props;
	const [showBanner, setShowBanner] = useState(false);

	useEffect(() => {
		check2FAAction()
			.catch((error) => {
				const errorMessage = error?.error?.response.data?.message;
				if (errorMessage?.includes('not activated')) setShowBanner(!showBanner);
			});
		// eslint-disable-next-line
			}, [check2FAAction]);

	return (
		<div>
			<TopBanner
				announcement={ANNOUNCEMENT}
				showBanner={showBanner}
			/>
			<div className="topbar">
				{inApp
					? <div className="name">{name}</div>
					: <MenuIcon type="CompanyLogo" height="40px" width="278px" color="#1D0D41" viewBox="0 0 174 40" />}
				<TopBarActions userName={userName} oneApp={oneApp} />
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	check2FA,
};
export default connect(null, mapDispatchToProps)(TopBarLayout);
