// @flow

import React from 'react';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Icon from '../../../components/UiElements/Icon';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { ProviderInstance, Provider } from '../../../modules/reducers/ProviderReducer';

type Props = {
	symbols: Array<Symbol>,
	applicationId: string,
	handleEditClick?: Function,
	place: string,
	groupId?: string,
	providersInstances: Array<ProviderInstance>,
	providers: Array<Provider>,
	currentPage: number,
	handlePageChange: Function,
	headerHeight: number,
	symbolsTotal: number,
}
type Quantity = {
	baseInstrument: string,
	quotingInstrument: string,
}

const SymbolsTable = (props: Props) => {
	const {
		symbols,
		handleEditClick,
		applicationId,
		place,
		groupId,
		providersInstances,
		providers,
		currentPage,
		handlePageChange,
		headerHeight,
		symbolsTotal,
	} = props;

	const columns = [
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			width: 112,
			render: (text: string, record: Symbol) => (
				groupId
					? (
						<a
							href={`/application/${applicationId}/system/groups/${groupId}/${record.id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{text}
						</a>
					)
					: (
						<Link
							to={`/application/${applicationId}/system/symbols/${text}`}
						>
							{text}
						</Link>
					)
			),
		},
		{
			title: 'name',
			dataIndex: 'displayName',
			key: 'name',
			width: 240,
		},
		{
			title: 'tradable',
			dataIndex: 'isTradable',
			key: 'isTradable',
			width: 112,
			render: (text: boolean) => (
				<div className={text ? 'wrap' : 'disabled-color'}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'visible',
			dataIndex: 'isVisible',
			key: 'isVisible',
			width: 112,
			render: (text: boolean) => (
				<div className={text ? 'wrap' : 'disabled-color'}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'close only',
			dataIndex: 'closeOnly',
			key: 'closeOnly',
			width: 112,
			render: (text: boolean) => (
				<div className={text ? 'wrap' : 'disabled-color'}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'min quantity set',
			dataIndex: ['orderSize', 'minQuantity'],
			key: 'minQuantity',
			align: 'right',
			width: 176,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.baseInstrument
						? PriceHelpers.formatAmount(
							quantity.baseInstrument,
							record.baseInstrumentId,
						)
						: '-'}
				</div>
			),
		},
		{
			title: 'max quantity set',
			dataIndex: ['orderSize', 'maxQuantity'],
			key: 'maxQuantity',
			align: 'right',
			width: 176,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.baseInstrument
						? PriceHelpers.formatAmount(quantity.baseInstrument, record.baseInstrumentId)
						: '-'}
				</div>
			),
		},
		{
			title: 'min amount set',
			dataIndex: ['orderSize', 'minQuantity'],
			key: 'minAmount',
			align: 'right',
			width: 160,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.quotingInstrument
						? PriceHelpers.formatAmount(
							quantity.quotingInstrument,
							record.quotingInstrumentId,
						)
						: '-'}
				</div>
			),
		},
		{
			title: 'max amount set',
			dataIndex: ['orderSize', 'maxQuantity'],
			key: 'maxAmount',
			align: 'right',
			width: 160,
			render: (quantity: Quantity, record: Symbol) => (
				<div>
					{quantity.quotingInstrument
						? PriceHelpers.formatAmount(quantity.quotingInstrument, record.quotingInstrumentId)
						: '-'}
				</div>
			),
		},
		{
			title: 'exchange',
			dataIndex: ['exchange', 'name'],
			key: 'exchange',
			width: 192,
			render: (text: string) => (
				<div>
					{ActivityHelpers.transformSnakeCase(text)}
				</div>
			),
		},
		{
			title: 'is open',
			dataIndex: 'isOpen',
			key: 'isOpen',
			width: 96,
			render: (text: string) => (
				<div>
					{text ? 'True' : 'False'}
				</div>
			),
		},
		{
			title: 'exchange minimum',
			dataIndex: 'exchangeMinimum',
			key: 'exchangeMinimum',
			align: 'right',
			width: 176,
		},
		{
			title: 'exchange precision',
			dataIndex: 'exchangePrecision',
			key: 'exchangePrecision',
			width: 176,
		},
		{
			title: 'ask price',
			dataIndex: ['price', 'ask'],
			key: 'askPrice',
			align: 'right',
			width: 160,
			render: (text: string, record: Symbol) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrumentId)}
				</div>
			),
		},
		{
			title: 'ask price offset',
			dataIndex: ['offset', 'ask'],
			key: 'offsetAsk',
			align: 'right',
			width: 160,
			render: (text: string, record: Symbol) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrumentId)}
				</div>
			),
		},
		{
			title: 'bid price',
			dataIndex: ['price', 'bid'],
			key: 'bidPrice',
			align: 'right',
			width: 160,
			render: (text: string, record: Symbol) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrumentId)}
				</div>
			),
		},
		{
			title: 'bid price offset',
			dataIndex: ['offset', 'bid'],
			key: 'offsetBid',
			align: 'right',
			width: 160,
			render: (text: string, record: Symbol) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrumentId, Numbers.sign(text))}
				</div>
			),
		},
		{
			title: 'base precision',
			dataIndex: 'basePrecision',
			key: 'basePrecision',
			width: 144,
		},
		{
			title: 'quoting precision',
			dataIndex: 'quotingPrecision',
			key: 'quotingPrecision',
			width: 160,
		},
		{
			title: 'trading provider',
			dataIndex: 'tradingProviderId',
			key: 'tradingProviderId',
			width: 192,
			render: (text: string) => (
				<div>
					{providersInstances?.find((el) => el.id === text)?.provider.displayName || text || '-'}
				</div>
			),
		},
		{
			title: 'quoting provider',
			dataIndex: 'quotingProviderId',
			key: 'quotingProviderId',
			width: 192,
			render: (text: string) => (
				<div>
					{providers?.find((el) => el.id === text)?.displayName || text || '-'}
				</div>
			),
		},
		{
			title: 'supported order types',
			dataIndex: 'supportedOrderTypes',
			key: 'supportedOrderTypes',
			width: 192,
			render: (text: string) => (
				<div>
					{text.join(' ')}
				</div>
			),
		},
	];

	const editColumn = handleEditClick ? [{
		title: 'edit',
		dataIndex: '',
		key: 'action',
		width: 80,
		render: () => (
			<Icon name="Edit" />
		),
		onCell: (record: Symbol) => ({
			onClick: (e) => {
				e.stopPropagation();
				handleEditClick(record);
			},
		}),
	}] : [];

	return (
		<CustomTable
			columns={[...columns, ...editColumn]}
			data={symbols}
			total={symbolsTotal}
			headerHeight={headerHeight}
			place={place}
			applicationId={applicationId}
			currentPage={currentPage}
			handlePageChange={handlePageChange}
		/>
	);
};

SymbolsTable.defaultProps = {
	handleEditClick: undefined,
	groupId: '',
};

export default SymbolsTable;
