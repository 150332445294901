import React from 'react';
//= import components
import { Pie } from 'ant-design-pro/lib/Charts';

type Props = {
	height: number,
	marginUtilization: string,
}
const DonutChart = (props: Props) => {
	const { height, marginUtilization } = props;

	return (
		<Pie
			height={height}
			percent={marginUtilization > 100 ? 100 : marginUtilization}
			total={`${marginUtilization}%`}
			color="#E64B79"
		/>
	);
};

export default DonutChart;
