// @flow

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Tag from '../../../components/UiElements/Tag';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { StockSplit } from '../../../modules/reducers/StockSplitReducer';

type Props = {
	activeTab: string,
	isFetchingStockSplits: boolean,
	stockSplits: Array<StockSplit>,
	stockSpitTotal: number,
	applicationId: string,
	headerHeight: number,
	currentPage: number,
	handlePageChange: Function,
}

const StockSplitsList = (props: Props) => {
	const {
		activeTab,
		isFetchingStockSplits,
		stockSplits,
		stockSpitTotal,
		applicationId,
		headerHeight,
		currentPage,
		handlePageChange,
	} = props;

	const upcomingColumns = [
		{
			title: 'Symbol',
			dataIndex: 'symbolId',
			key: 'dividendInstrumentId',
			width: 176,
			render: (text: string, record: StockSplit) => (
				<Link
					to={`/application/${applicationId}/system/stock_splits/${record.id}`}
				>
					{text}
				</Link>
			),
		},
		{
			title: 'split rate',
			dataIndex: 'splitRate',
			key: 'splitRate',
			width: 176,
		},
		{
			title: 'split side',
			dataIndex: 'splitSide',
			key: 'splitSide',
			width: 176,
			render: (text: string) => (
				<div className="capitalize">{text}</div>
			),
		},
		{
			title: 'record date',
			dataIndex: 'recordDate',
			key: 'recordDate',
			width: 150,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
		{
			title: 'ex date',
			dataIndex: 'exDate',
			key: 'exDate',
			width: 150,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
		},
	];
	const executedColumns = [
		{
			title: 'closing price',
			dataIndex: 'closingPrice',
			key: 'closingPrice',
			align: 'right',
			width: 144,
			render: (text: string, record: StockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.quotingInstrument || '',
					)}
				</div>
			),
		},
		{
			title: 'total before',
			dataIndex: 'executionSummary.before.total',
			key: 'totalBefore',
			width: 176,
			render: (text: string, record: StockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId || '',
					)}
				</div>
			),
		},
		{
			title: 'total after',
			dataIndex: 'executionSummary.after.total',
			key: 'totalAfter',
			width: 176,
			render: (text: string, record: StockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId || '',
					)}
				</div>
			),
		},
		{
			title: 'remainder',
			dataIndex: 'executionSummary.remainder.baseAmount',
			key: 'remainder',
			width: 176,
			render: (text: string, record: StockSplit) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId || '',
					)}
				</div>
			),
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			width: 176,
			render: (text: string) => <Tag status={text} />,
		},
	];

	const columns = activeTab === 'upcomingStockSplits'
		? upcomingColumns
		: [...upcomingColumns, ...executedColumns];

	return (
		<div>
			{!isFetchingStockSplits
				? (
					<CustomTable
						columns={columns}
						data={stockSplits}
						total={stockSpitTotal}
						place={activeTab}
						applicationId={applicationId}
						headerHeight={headerHeight}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
					/>
				) : <Spin spinning={isFetchingStockSplits} />}
		</div>
	);
};

export default StockSplitsList;
