// @flow

import {
	GET_SECTIONS,
	GET_SECTION,
	ADD_SECTION,
	UPDATE_SECTION,
	REMOVE_SECTION,
	ADD_GROUP_TO_SECTION,
	REMOVE_GROUP_FROM_SECTION,
} from '../const/types';

export function getSections(applicationId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_SECTIONS,
		payload: {
			request: {
				url: `/applications/${applicationId}/explore/sections`,
			},
		},
	});
}

export function getSection(applicationId: string, exploreSectionId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_SECTION,
		payload: {
			request: {
				url: `/applications/${applicationId}/explore/sections/${exploreSectionId}`,
			},
		},
	});
}

export function addSection(applicationId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_SECTION,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/explore/sections`,
				data,
			},
		},
	});
}

export function updateSection(applicationId: string, exploreSectionId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_SECTION,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/explore/sections/${exploreSectionId}`,
				data,
			},
		},
	});
}

export function removeSection(applicationId: string, exploreSectionId: string) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_SECTION,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/explore/sections/${exploreSectionId}`,
			},
		},
	});
}

export function addGroupToSection(applicationId: string, exploreSectionId: string, exploreGroupId: string) {
	return (dispatch: Function): void => dispatch({
		type: ADD_GROUP_TO_SECTION,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/explore/sections/${exploreSectionId}/add-group/${exploreGroupId}`,
			},
		},
	});
}

export function removeGroupFromSection(applicationId: string, exploreSectionId: string, exploreGroupId: string) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_GROUP_FROM_SECTION,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/explore/sections/${exploreSectionId}/remove-group/${exploreGroupId}`,
			},
		},
	});
}
