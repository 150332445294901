// @flow

import React from 'react';

type ActionProps = {
	action: string,
}
type TimeProps = {
	time: string,
	diff: string,
	status?: string,
}

export const ActionDot = (props: ActionProps) => {
	const { action } = props;
	return (
		<div className="action">
			<div>
				<div>
					<div />
					<span>{action}</span>
				</div>
			</div>
		</div>
	);
};

export const TimeDot = (props: TimeProps) => {
	const { time, diff, status = 'filled' } = props;
	return (
		<div className={`dot ${status}`}>
			<div>
				<div>
					<div />
					<span>{time}</span>
				</div>
				{diff && <span>{diff}</span>}
			</div>
		</div>
	);
};

TimeDot.defaultProps = {
	status: 'filled',
};

export const InvisibleDot = () => (
	<div className="invisible-dot" />
);
