// @flow

import React from 'react';
import moment from 'moment';
//= import componnets
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { DividendPayout } from '../../../modules/reducers/DividendsReducer';

type Props = {
	handlePageChange: Function,
	payoutIsFetching: boolean,
	payouts: Array<DividendPayout>,
	payoutsTotal: number,
	applicationId: string,
	currentPage: number,
}

const Payouts = (props: Props) => {
	const {
		payoutIsFetching,
		payouts,
		payoutsTotal,
		applicationId,
		currentPage,
		handlePageChange,
	} = props;

	const columns = [
		{
			title: 'dividend instrument',
			dataIndex: 'dividend.dividendInstrumentId',
			key: 'dividendInstrumentId',
			width: 176,
		},
		{
			title: 'payout instrument',
			dataIndex: 'dividend.payoutInstrumentId',
			key: 'payoutInstrumentId',
			width: 176,
		},
		{
			title: 'client id',
			dataIndex: 'userId',
			key: 'userId',
			width: 340,
			render: (text: string, record: DividendPayout) => (
				<div>
					<a
						href={`/application/${applicationId}/clients/${text}?accountId=${record.accountId}&tab=payouts`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{text}
					</a>
				</div>
			),
		},
		{
			title: 'amount',
			dataIndex: 'quantity',
			key: 'quantity',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendPayout) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.dividend.dividendInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'gross rate',
			dataIndex: 'grossAmount',
			key: 'grossAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendPayout) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.dividend.payoutInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'tax',
			dataIndex: 'taxAmount',
			key: 'taxAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendPayout) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.dividend.payoutInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'executed at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'announcement date',
			dataIndex: 'dividend.announcementDate',
			key: 'announcementDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'ex dividend date',
			dataIndex: 'dividend.exDividendDate',
			key: 'exDividendDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'record date',
			dataIndex: 'dividend.recordDate',
			key: 'recordDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'payout date',
			dataIndex: 'dividend.payoutDate',
			key: 'payoutDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'trading account',
			dataIndex: 'accountId',
			key: 'accountId',
			width: 192,
		},
	];

	return (
		<div>
			{!payoutIsFetching
				? (
					<CustomTable
						columns={columns}
						data={payouts}
						total={payoutsTotal}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						place="payouts"
						applicationId={applicationId}
						headerHeight={0}
					/>
				) : <Spin spinning={!payoutIsFetching} />}
		</div>
	);
};

export default Payouts;
