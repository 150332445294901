// @flow

import React, {
	useEffect, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import Switch from '../../../components/UiElements/Switch';
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import ValueCard from '../../../components/UiElements/CustomCards/ValueCard';
import Menu from '../../../components/UiElements/Menu';
import Icon from '../../../components/UiElements/Icon';
import Export from '../../../components/UiElements/Export';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import FormModal from '../../../components/UiElements/Modal/FormModal';
// = import actions
import { getInventoryWallet, getInventoryWalletTransactions, exportInventoryWalletTransactions } from '../../../modules/actions/InventoryWalletsAction';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { InventoryWallet, InventoryWalletTransaction } from '../../../modules/reducers/InventoryWalletReducer';

type Props = {
	inventoryWallet: InventoryWallet,
	inventoryWalletTransactions: Array<InventoryWalletTransaction>,
	getInventoryWallet: (string, string) => Promise<Object>,
	getInventoryWalletTransactions: (string, string, number) => Promise<Object>,
	exportInventoryWalletTransactions: (string, string, string) => Promise<Object>,
	inventoryWalletTransactionsTotal: number,
	setCurrentPage: (string, number) => void,
	currentPage: number,
	match: {
		params: {
			symbolId: string,
			applicationId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	}
}

const InventoryWalletPage = (props: Props) => {
	const {
		inventoryWallet,
		inventoryWalletTransactions,
		inventoryWalletTransactionsTotal,
		currentPage,
		getInventoryWallet: getInventoryWalletAction,
		getInventoryWalletTransactions: getInventoryWalletTransactionsAction,
		exportInventoryWalletTransactions: exportInventoryWalletTransactionsAction,
		setCurrentPage: setCurrentPageAction,
		match: { params: { applicationId, symbolId } },
	} = props;

	const [dataCompleted, setDataCompleted] = useState(false);
	const [filteredTransactions, setFilteredTransactions] = useState([]);
	const [displayAdjustment, setDisplayAdjustment] = useState(false);
	const [exportVisible, setExportVisible] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [message, setMessage] = useState({});
	const [success, setSuccess] = useState(false);

	const targetRef = useRef();

	useEffect(() => {
		getInventoryWalletAction(applicationId, symbolId);
		setCurrentPageAction('inventoryTransactionsPage', 1);
	}, [
		applicationId,
		symbolId,
		getInventoryWalletAction,
		setCurrentPageAction,
	]);

	useEffect(() => {
		async function fetchData() {
			setDataCompleted(false);
			await getInventoryWalletTransactionsAction(applicationId, symbolId, currentPage);
			setDataCompleted(true);
		}
		fetchData();
	}, [
		applicationId,
		symbolId,
		currentPage,
		getInventoryWalletTransactionsAction,
	]);

	useEffect(() => {
		if (displayAdjustment) {
			setFilteredTransactions(inventoryWalletTransactions);
		} else {
			setFilteredTransactions(inventoryWalletTransactions.filter((transaction) => transaction.type !== 'adjustment'));
		}
	}, [displayAdjustment, inventoryWalletTransactions, currentPage]);

	const handleTransactionPageChange = (page: number) => {
		setCurrentPageAction('inventoryTransactionsPage', page);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleExport = () => {
		setSuccess(false);
		setExportVisible(true);
	};

	const handleExportCancel = () => {
		setExportVisible(false);
	};

	const closeWarning = () => {
		setWarningModal(false);
	};

	const exportData = (value) => {
		setExportVisible(false);
		const params: string = queryString.stringify(value, { arrayFormat: 'bracket' });
		exportInventoryWalletTransactionsAction(applicationId, inventoryWallet.symbolId, params)
			.then((res) => {
				setSuccess(true);
				setWarningModal(true);
				setMessage({ firstPart: res.payload.data.message });
			})
			.catch((error) => {
				setSuccess(false);
				setWarningModal(true);
				setMessage({
					firstPart: error.error.response.data.errors
						? error.error.response.data.errors[0].messages[0].message
						: error.error.response.data.message,
				});
			});
	};

	const walletColumns = inventoryWallet.symbol && [
		{
			title: 'updated at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => (
				<span className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</span>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 160,
			render: (text: string) => (
				<span className="capitalize">
					{text}
				</span>
			),
		},
		{
			title: 'side',
			dataIndex: 'side',
			key: 'side',
			width: 144,
			render: (text: string) => (
				<span className="capitalize">
					{text}
				</span>
			),
		},
		{
			title: 'amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			width: 176,
			render: (text: string, record: InventoryWalletTransaction) => (
				<span className={(record.type !== 'reservation' && (Numbers.sign(text) ? 'red-text' : 'green-text')) || ''}>
					{
						PriceHelpers.formatAmount(
							text,
							inventoryWallet.symbol.baseInstrumentId,
							true,
						)
					}
				</span>
			),
		},
		{
			title: 'price',
			dataIndex: 'price',
			key: 'price',
			align: 'right',
			width: 176,
			render: (text: string) => (
				<span>
					{PriceHelpers.formatAmount(
						text,
						inventoryWallet.symbol ? inventoryWallet.symbol.quotingInstrumentId : '-',
					)}
				</span>
			),
		},
		{
			title: 'available',
			dataIndex: 'available',
			key: 'available',
			align: 'right',
			width: 176,
			render: (text: string) => (
				<span className="bold">
					{PriceHelpers.formatAmount(
						text,
						inventoryWallet.symbol ? inventoryWallet.symbol.baseInstrumentId : '-',
						Numbers.sign(text),
					)}
				</span>
			),
		},
		{
			title: 'allocated',
			dataIndex: 'allocated',
			key: 'allocated',
			align: 'right',
			width: 176,
			render: (text: string) => (
				<span>
					{PriceHelpers.formatAmount(
						text,
						inventoryWallet.symbol ? inventoryWallet.symbol.baseInstrumentId : '-',
						Numbers.sign(text),
					)}
				</span>
			),
		},
		{
			title: 'adjustment',
			dataIndex: 'adjustment',
			key: 'adjustment',
			align: 'right',
			width: 176,
			render: (text: string) => (
				<span className="bold">
					{PriceHelpers.formatAmount(
						text,
						inventoryWallet.symbol ? inventoryWallet.symbol.baseInstrumentId : '-',
						Numbers.sign(text),
					)}
				</span>
			),
		},
	];

	const overlayComponent = (
		<Menu className="wallet-actions">
			<Menu.Item key="export" onClick={handleExport} disabled={inventoryWallet.delisted}>
				<Icon name="Open" colorName="text" size={14} />
				<span>Export</span>
			</Menu.Item>
		</Menu>
	);

	const exportOptions = {
		dateRange: { options: 'time', initialValue: [], label: 'date range' },
		type: { options: ['adjustment', 'external', 'internal'], initialValue: ['adjustment', 'external', 'internal'], label: 'type' },
		side: { options: ['buy', 'sell'], initialValue: ['buy', 'sell'], label: 'side' },
	};

	return (
		<>
			{inventoryWallet.symbol
				? (
					<div>
						<Title
							backAction={handleBackClick}
							title={`${inventoryWallet.symbolId} Wallet`}
							tags={[inventoryWallet.delisted && 'delisted']}
							actions={{
								overlay: overlayComponent,
							}}
						>
							<div className="wallet-switch">
								<span className="switch-text">{displayAdjustment ? 'hide adjustment' : 'show adjustment'}</span>
								<Switch
									defaultChecked={displayAdjustment}
									onChange={(value) => setDisplayAdjustment(value)}
								/>
							</div>
						</Title>
						<div className="container">
							<div className="value-cards" ref={targetRef}>
								<ValueCard
									value={PriceHelpers.formatAmount(
										inventoryWallet.available,
										inventoryWallet.symbol.baseInstrumentId,
										Numbers.sign(inventoryWallet.available),
									)}
									title="unallocated balance"
									styles={{ backgroundColor: '#6EBA1A' }}
								/>
								<ValueCard
									value={PriceHelpers.formatAmount(
										inventoryWallet.allocated,
										inventoryWallet.symbol.baseInstrumentId,
										Numbers.sign(inventoryWallet.allocated),
									)}
									title="allocated balance"
									styles={{ backgroundColor: '#999999' }}
								/>
								<ValueCard
									value={PriceHelpers.formatAmount(
										Numbers.sum(
											inventoryWallet.available,
											inventoryWallet.allocated,
										),
										inventoryWallet.symbol.baseInstrumentId,
									)}
									title="total balance"
									styles={{ backgroundColor: '#7266BA' }}
								/>
							</div>
							{dataCompleted
								? (
									<CustomTable
										columns={walletColumns}
										data={filteredTransactions}
										total={inventoryWalletTransactionsTotal}
										handlePageChange={handleTransactionPageChange}
										currentPage={currentPage}
										headerHeight={176}
										place="inventoryLedger"
										applicationId={applicationId}
									/>
								)
								: (
									<Spin spinning={!dataCompleted} />
								)}
						</div>
						<FormModal
							destroyOnClose
							title="Export Symbol Inventory Ledge"
							visible={exportVisible}
							cancelFunction={handleExportCancel}
							form={(
								<Export
									submitFunction={exportData}
									cancelFunction={handleExportCancel}
									exportOptions={exportOptions}
								/>
							)}
						/>
						<WarningModal
							title="Processing export request"
							visible={warningModal}
							cancelFunction={closeWarning}
							footer={[
								{ type: 'cancel', action: closeWarning, text: 'close' },
							]}
							message={message}
							success={success}
						/>
					</div>
				)
				: <Spin spinning={!dataCompleted} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	inventoryWallet: state.inventoryWallets.inventoryWallet,
	inventoryWalletTransactions: state.inventoryWallets.inventoryTransactions,
	inventoryWalletTransactionsTotal: state.inventoryWallets.inventoryTransactionsTotal,
	currentPage: state.ui.inventoryTransactionsPage,
});

const mapDispatchToProps = {
	getInventoryWallet,
	getInventoryWalletTransactions,
	exportInventoryWalletTransactions,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryWalletPage);
