// @flow

import React from 'react';
import { Link } from 'react-router-dom';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	data: number,
	title: string,
	applicationId: string,
	accountId: string,
	userId: string,
}

const PendingCard = (props: Props) => {
	const {
		data, applicationId, accountId, title, userId,
	} = props;
	return (
		<div
			className={styles.pending}
			role="presentation"
		>
			<Link
				to={`/application/${applicationId}/clients/${userId}/payments?accountId=${accountId}&type=${title}`}
			>
				<div>
					<span>{`Pending ${title}`}</span>
					<h4>
						{data}
					</h4>
				</div>
			</Link>
		</div>
	);
};

export default PendingCard;
