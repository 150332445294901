// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import InputNumber from '../../../components/UiElements/InputNumber';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	quotingInstruments: Array<string>,
}

type State = {
	errorMessage: string,
	instrument: string,
}

class Delist extends React.Component<Props, State> {
	state = {
		errorMessage: '',
		instrument: this.props.quotingInstruments[0],
	}

	selectInstrument = (value) => {
		this.setState({
			instrument: value,
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			instrument: this.props.quotingInstruments[0],
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					destinationInstrumentId: values.destinationInstrumentId,
					delistingPrice: values.delistingPrice.toString(),
				};
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched }, quotingInstruments, handleCancelClick,
		} = this.props;
		const { errorMessage, instrument } = this.state;
		const { Option } = Select;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 12	 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
			labelAlign: 'left',
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<Form.Item label="DESTINATION INSTRUMENT ID" {...formItemLayout} help="">
					{getFieldDecorator('destinationInstrumentId', {
						initialValue: quotingInstruments[0],
					})(
						<Select
							showSearch
							placeholder="Select Destination Instrument"
							onChange={this.selectInstrument}
						>
							{quotingInstruments.map((el) => (
								<Option value={el} key={el}>
									{el}
								</Option>
							))}
						</Select>,
					)}
				</Form.Item>
				<Form.Item label="DELISTING PRICE" {...formItemLayout}>
					{getFieldDecorator('delistingPrice', {
						rules: [{ required: true, message: "Destination Price can't be left empty" }],
					})(
						<InputNumber
							placeholder="Set Delisting Price"
							symbol={instrument}
						/>,
					)}
				</Form.Item>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="delist instrument" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const DelistForm = Form.create()(Delist);

export default DelistForm;
