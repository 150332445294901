// @flow

import React from 'react';

type IconStyle = {
    width?: string,
    height?: string,
}

const IconPlaceholder = ({
	width = '58px',
	height = '58px',
}: IconStyle) => (
	<svg width={width} height={height} viewBox="0 0 58 58" version="1.1">
		<title>icon_placeholder</title>
		<desc>Created with Sketch.</desc>
		<g id="icon_placeholder" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Bitmap" fillRule="nonzero">
				<rect id="Mask" fill="#E3E4E5" x="0" y="0" width="58" height="58" rx="5.76" />
				<path d="M54,40.3864399 L54,51.0014666 C54,52.6583208 52.6568542,54.0014666 51,54.0014666 L7,54.0014666 C5.34314575,54.0014666 4,52.6583208 4,51.0014666 L4,45.5417576 L13.853929,34.8522615 C14.9769217,33.6340443 16.8748474,33.5568495 18.0930647,34.6798423 C18.1758426,34.7561496 18.2542594,34.8370562 18.3279429,34.922178 L28.051314,46.154956 C28.4127737,46.5725268 29.0443026,46.6180139 29.4618734,46.2565543 C29.5536087,46.1771459 29.6301273,46.0817061 29.6876894,45.9748994 L40.9609424,25.057309 C41.7469937,23.598786 43.5665807,23.05364 45.0251037,23.8396913 C45.5645483,24.1304176 46.0020234,24.5793698 46.2786857,25.1261607 L54,40.3864399 Z M20,23 C16.6862915,23 14,20.3137085 14,17 C14,13.6862915 16.6862915,11 20,11 C23.3137085,11 26,13.6862915 26,17 C26,20.3137085 23.3137085,23 20,23 Z" id="Combined-Shape" fill="#FFFFFF" />
			</g>
		</g>
	</svg>
);

IconPlaceholder.defaultProps = {
	width: '58px',
	height: '58px',
};

export default IconPlaceholder;
