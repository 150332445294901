// @flow
import moment from 'moment';

const BASE_PAGE_SIZE = 50;

const initialState = {
	activityLogPage: 1,
	appUsersPage: 1,
	appUsersPageSize: Number(localStorage.getItem('appUsersPageSize')) || BASE_PAGE_SIZE, // The following are dedicated page sizes per table, preserved via localStorage.
	appOrdersPageSize: Number(localStorage.getItem('appOrdersPageSize')) || BASE_PAGE_SIZE,
	cardPaymentsSimulationsPageSize: Number(localStorage.getItem('cardPaymentsSimulationsPageSize')) || BASE_PAGE_SIZE,
	appPaymentsPageSize: Number(localStorage.getItem('appPaymentsPageSize')) || BASE_PAGE_SIZE,
	appCardPaymentsPageSize: Number(localStorage.getItem('appCardPaymentsPageSize')) || BASE_PAGE_SIZE,
	userOrdersPageSize: Number(localStorage.getItem('userOrdersPageSize')) || BASE_PAGE_SIZE,
	userPaymentsPageSize: Number(localStorage.getItem('userPaymentsPageSize')) || BASE_PAGE_SIZE,
	clientAccountsPageSize: Number(localStorage.getItem('clientAccountsPageSize')) || BASE_PAGE_SIZE,
	basicUserWalletsPageSize: Number(localStorage.getItem('basicUserWalletsPageSize')) || BASE_PAGE_SIZE,
	productsPageSize: Number(localStorage.getItem('productsPageSize')) || BASE_PAGE_SIZE,
	userWalletsPageSize: Number(localStorage.getItem('userWalletsPageSize')) || BASE_PAGE_SIZE,
	customerApplicationPaymentsPageSize: Number(localStorage.getItem('customerApplicationPaymentsPageSize')) || BASE_PAGE_SIZE,
	kycReportsPageSize: Number(localStorage.getItem('kycReportsPageSize')) || BASE_PAGE_SIZE,
	kytReportsPageSize: Number(localStorage.getItem('kytReportsPageSize')) || BASE_PAGE_SIZE,
	positionsPageSize: Number(localStorage.getItem('positionsPageSize')) || BASE_PAGE_SIZE,
	webhooksPageSize: Number(localStorage.getItem('webhooksPageSize')) || BASE_PAGE_SIZE,
	webhookLogsPageSize: Number(localStorage.getItem('webhookLogsPageSize')) || BASE_PAGE_SIZE,
	archivedWebhooksPageSize: Number(localStorage.getItem('archivedWebhooksPageSize')) || BASE_PAGE_SIZE,
	groupUsersPage: 1,
	instrumentPage: 1,
	appSymbolsPage: 1,
	cardsPage: 1,
	groupSymbolsPage: 1,
	walletPage: 1,
	orderPage: 1,
	paymentPage: 1,
	providerLogPage: 1,
	transactionPage: 1,
	appPaymentsPage: 1,
	inventoryWalletsPage: 1,
	inventoryTransactionsPage: 1,
	webhookLogPage: 1,
	endpointsPage: 1,
	archivedPage: 1,
	borrowersPage: 1,
	appOrdersPage: 1,
	marginChargesPage: 1,
	payoutsPage: 1,
	accountPayoutPage: 1,
	kycPage: 1,
	kytPage: 1,
	marginPage: 1,
	accountStockSplitsPage: 1,
	customerAccountsPage: 1,
	delistedInventoryPage: 1,
	delistedInstrumentsPage: 1,
	bankAccountPaymentsPage: 1,
	cardPaymentsPage: 1,
	cardsFilters: {},
	bankApplicationPaymentsPage: 1,
	bankApplicationPaymentsSearch: '',
	clientAccountsPage: 1,
	userAccountsPage: 1,
	accountAccountsPage: 1,
	customerAccountPaymentsPage: 1,
	customerApplicationPaymentsPage: 1,
	fractionalPage: 1,
	settlementPage: 1,
	conversionsPage: 1,
	closeOnlyPage: 1,
	upcomingStockSplitsPage: 1,
	executedStockSplitsPage: 1,
	appPaymentsFilters: {},
	appPaymentsSorter: {},
	paymentsFilters: {},
	appOrdersFilters: {},
	ordersFilters: {},
	activityLogFilters: {},
	providerLogFilters: {},
	providersFilters: {},
	webhookLogFilters: {},
	appSymbolsFilters: {},
	groupSymbolsFilters: {},
	appUsersFilters: {},
	groupUsersFilters: {},
	kycFilters: {},
	kytFilters: {},
	dividendsFilters: {
		from: moment().format('YYYY-MM-DD 00:00:00'),
		to: moment().add('30', 'days').format('YYYY-MM-DD 00:00:00'),
	},
	upcomingStockSplitsFilters: {},
	executedStockSplitsFilters: {},
	accountStockSplitsFilters: {},
	customerAccountsFilters: {},
	bankApplicationPaymentsFilters: {},
	bankAccountPaymentsFilters: {},
	clientAccountsFilters: {},
	userAccountsFilters: {},
	accountAccountsFilters: {},
	customerApplicationPaymentsFilters: {},
	customerAccountPaymentsFilters: {},
	conversionsFilters: {},
	instrumentFilters: {},
	marginFilters: {},
	providersSearch: '',
	appUsersSearch: '',
	groupUsersSearch: '',
	appSymbolsSearch: '',
	groupSymbolsSearch: '',
	fractionalSearch: '',
	settlementSearch: '',
	delistedSearch: '',
	closeOnlySearch: '',
	feeTab: 'deposit',
	user_feeTab: 'deposit',
	accountTab: 'user_wallets',
	groupTab: 'users',
	userTab: 'accounts',
	marginTab: 'collateral',
	webhookTab: 'endpoints',
	logTab: 'activityLog',
	exploreTab: 'Group',
	dividendsTab: 'dividends',
	stockSplitTab: 'upcomingStockSplits',
	delistingsTab: 'closeOnly',
	cardTab: 'programs',
	detailsTab: 'appDetails',
	menuCollapsed: false,
};

export type FilterType = {
	[string]: Array<string>,
}
export type FiltersType = Array<FilterType>
export type UiState = {
	appUsersPage: number,
	appUsersPageSize: number,
	appOrdersPageSize: number,
	positionsPageSize: number,
	webhooksPageSize: number,
	webhookLogsPageSize: number,
	archivedWebhooksPageSize: number,
	cardPaymentsSimulationsPageSize: number,
	appCardPaymentsPageSize: number,
	instrumentPage: number,
	basicUserWalletsPageSize: number,
	userWalletsPageSize: number,
	appSymbolsPage: number,
	groupSymbolsPage: number,
	activityLogPage: number,
	walletPage: number,
	orderPage: number,
	paymentPage: number,
	providerLogPage: number,
	transactionPage: number,
	appPaymentsPage: number,
	appPaymentsPageSize: number,
	appPaymentsSorter: Object,
	inventoryWalletsPage: number,
	inventoryTransactionsPage: number,
	groupUsersPage: number,
	webhookLogPage: number,
	endpointsPage: number,
	archivedPage: number,
	borrowersPage: number,
	appOrdersPage: number,
	marginChargesPage: number,
	payoutsPage: number,
	accountPayoutPage: number,
	delistedInventoryPage: number,
	delistedInstrumentsPage: number,
	customerAccountsPage: number,
	bankAccountPaymentsPage: number,
	cardPaymentsPage: number,
	cardsFilters: { [string]: Array<string> },
	bankApplicationPaymentsPage: number,
	kycPage: number,
	kytPage: number,
	userAccountsPage: number,
	productsPageSize: number,
	cardsPage: number,
	accountAccountsPage: number,
	customerAccountPaymentsPage: number,
	customerApplicationPaymentsPage: number,
	clientAccountsPage: number,
	conversionsPage: number,
	closeOnlyPage: number,
	fractionalPage: number,
	settlementPage: number,
	marginPage: number,
	upcomingStockSplitsPage: number,
	executedStockSplitsPage: number,
	accountStockSplitsPage: number,
	appPaymentsFilters: { [string]: Array<string> },
	appOrdersFilters: { [string]: Array<string> },
	ordersFilters: { [string]: Array<string> },
	paymentsFilters: { [string]: Array<string> },
	groupUsersFilters: { [string]: Array<string> },
	appUsersFilters: { [string]: Array<string> },
	activityLogFilters: { [string]: Array<string> },
	providerLogFilters: { [string]: Array<string> },
	providersFilters: { [string]: Array<string> },
	webhookLogFilters: { [string]: Array<string> },
	appSymbolsFilters: { [string]: Array<string> },
	groupSymbolsFilters: { [string]: Array<string> },
	dividendsFilters: { [string]: Array<string> },
	upcomingStockSplitsFilters: { [string]: Array<string> },
	executedStockSplitsFilters: { [string]: Array<string> },
	accountStockSplitsFilters: { [string]: Array<string> },
	kycFilters: { [string]: Array<string> },
	kytFilters: { [string]: Array<string> },
	customerAccountsFilters: { [string]: Array<string> },
	bankApplicationPaymentsFilters: { [string]: Array<string> },
	bankApplicationPaymentsSearch: string,
	bankAccountPaymentsFilters: { [string]: Array<string> },
	clientAccountsFilters: { [string]: Array<string> },
	userAccountsFilters: { [filter: string]: Array<string> },
	accountAccountsFilters: { [filter: string]: Array<string> },
	customerApplicationPaymentsFilters: { [filter: string]: Array<string> },
	customerAccountPaymentsFilters: { [filter: string]: Array<string> },
	conversionsFilters: { [string]: Array<string> },
	providersSearch: string,
	instrumentFilters: { [string]: Array<string> },
	marginFilters: { [string]: Array<string> },
	appUsersSearch: string,
	appSymbolsSearch: string,
	groupUsersSearch: string,
	groupSymbolsSearch: string,
	fractionalSearch: string,
	settlementSearch: string,
	delistedSearch: string,
	closeOnlySearch: string,
	feeTab: string,
	user_feeTab: string,
	accountTab: string,
	groupTab: string,
	userTab: string,
	marginTab: string,
	webhookTab: string,
	logTab: string,
	exploreTab: string,
	dividendsTab: string,
	stockSplitTab: string,
	delistingsTab: string,
	cardTab: string,
	detailsTab: string,
	menuCollapsed: boolean,
	kytReportsPageSize: number,
}
export type UiAction = {
	type: string,
	page: number,
	filters: Object,
	place: string,
	tab: string,
	collapsed: boolean,
	search: string,
	sortData: string,
}
export default function uiReducer(
	state: UiState = initialState,
	action: UiAction,
): UiState {
	switch (action.type) {
	case 'CURRENT_PAGE':
		return {
			...state,
			[action.place]: action.page,
		};
	case 'CURRENT_PAGE_SIZE':
			return {
				...state,
				[action.place]: action.pageSize,
			};
	case 'CURRENT_FILTERS':
		return {
			...state,
			[action.place]: action.filters,
		};
	case 'CURRENT_SORTER':
		return {
			...state,
			[action.place]: action.sortData,
		};
	case 'ACTIVE_TAB':
		return {
			...state,
			[action.place]: action.tab,
		};
	case 'MENU_COLLAPSED':
		return {
			...state,
			menuCollapsed: action.collapsed,
		};
	case 'CLEAR_FILTERS':
		return {
			...state,
			paymentsFilters: {},
			appPaymentsFilters: {},
			appOrdersFilters: {},
			ordersFilters: {},
			providerLogFilters: {},
			providersFilters: {},
			webhookLogFilters: {},
			groupUsersFilters: {},
			appUsersFilters: {},
			appSymbolsFilters: {},
			groupSymbolsFilters: {},
			activityLogFilters: {},
			bankAccountPaymentsFilters: {},
			dividendsFilters: {
				from: moment().format('YYYY-MM-DD 00:00:00'),
				to: moment().add('30', 'days').format('YYYY-MM-DD 00:00:00'),
			},
			appUsersSearch: '',
			bankApplicationPaymentsSearch: '',
			providersSearch: '',
			groupUserSearch: '',
			symbolsSearch: '',
			groupUsersSearch: '',
			appSymbolsSearch: '',
			groupSymbolsSearch: '',
			fractionalSearch: '',
			settlementSearch: '',
			delistedSearch: '',
			closeOnlySearch: '',
			feeTab: 'deposit',
			user_feeTab: 'deposit',
		};
	case 'CURRENT_SEARCH':
		return {
			...state,
			[action.place]: action.search,
		};
	default:
		return state;
	}
}
