// @flow

import React, {
	useEffect,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';
//= import components
import Title from '../../../components/UiElements/Title';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Tag from '../../../components/UiElements/Tag';
import Card from '../../../components/UiElements/Card';
import NumbersCard from '../../../components/UiElements/CustomCards/NumbersCard';
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
//= import actions
import { getBankPayment, getClientBankAccount } from '../../../modules/actions/BankActions';
import { getApplicationPayments } from '../../../modules/actions/PaymentActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { PaymentType, BankAccountType } from '../../../modules/reducers/BankReducer';
import type { Payment } from '../../../modules/reducers/PaymentReducer';
//= import styles
import styles from '../assets/banking.module.scss';

type Props = {
	getBankPayment: (string) => Promise<Object>,
	bankPayment: PaymentType,
	isFetchingBankPayment: boolean,
	getClientBankAccount: (string) => Promise<Object>,
	getApplicationPayments: (string, number, Object) => void,
	bankAccount: BankAccountType,
	appPayment: Payment,
	match: {
		params: {
			bankPaymentId: string,
			applicationId: string,
		}
	}
}

function BankPaymentPage(props: Props) {
	const {
		getBankPayment: getBankPaymentAction,
		getClientBankAccount: getClientBankAccountActions,
		getApplicationPayments: getApplicationPaymentsAction,
		bankPayment,
		isFetchingBankPayment,
		bankAccount,
		appPayment,
		match: { params: { bankPaymentId, applicationId } },
	} = props;

	useEffect(() => {
		async function getData() {
			const paymentRes = await getBankPaymentAction(bankPaymentId);
			const accRes = await getClientBankAccountActions(paymentRes.payload.data.bankAccountId);
			if (accRes.payload.data.type === 'user') {
				const parameter = { bankPaymentId };
				await getApplicationPaymentsAction(applicationId, -1, queryString.stringify(parameter, { arrayFormat: 'comma' }));
			}
		}
		getData();
	}, [
		applicationId,
		getBankPaymentAction,
		bankPaymentId,
		getClientBankAccountActions,
		getApplicationPaymentsAction,
	]);

	const amountData = [{
		key: `${bankPayment.scheme} ${bankPayment.direction}`,
		value: PriceHelpers.formatAmount(
			bankPayment.amount,
			bankPayment.instrumentId,
		),
		color: bankPayment.direction === 'outbound' ? '#F03E3E' : '#1DC46E',
	}];

	const generalInfo = [{
		title: 'General Info',
		data: {
			createdAt: moment(bankPayment.createdAt).format('YYYY-MM-DD HH:mm:ss'),
			lastUpdatedAt: moment(bankPayment.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
			client: bankAccount.type === 'user' ? (
				<a
					href={`/application/${applicationId}/clients/${appPayment?.user?.id}?tab=user_bank_accounts`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{`${appPayment?.user?.firstName || '-'} ${appPayment?.user?.lastName || ''}`}
				</a>
			) : 'Customer',
			bankAccountId: bankAccount.type === 'user'
				? (
					<div className="flex-container">
						<a
							href={`/application/${applicationId}/client_bank_accounts/${bankPayment.bankAccountId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{bankPayment.bankAccountId}
						</a>
						<CopyComponent
							text={bankPayment.bankAccountId}
							content=""
						/>
					</div>
				)
				: (
					<div className="flex-container">
						<a
							href={`/application/${applicationId}/banking/${bankPayment.bankAccountId}?place=customer`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{bankPayment.bankAccountId}
						</a>
						<CopyComponent
							text={bankPayment.bankAccountId}
							content=""
						/>
					</div>
				),
			provider: bankPayment?.request?.provider || '-',
		},
	}];

	const paymentData = bankPayment.id ? [
		{
			title: 'from',
			data: bankPayment?.direction === 'inbound'
				? bankPayment?.counterparty?.details
				: {
					name: bankAccount.type === 'user'
						? (
							<a
								href={`/application/${applicationId}/clients/${appPayment?.user?.id}?tab=user_bank_accounts`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{`${appPayment?.user?.firstName || '-'} ${appPayment?.user?.lastName || ''}`}
							</a>
						)
						: 'Customer',
					bankAccountId: bankAccount.type === 'user'
						? (
							<a
								href={`/application/${applicationId}/client_bank_accounts/${bankPayment.bankAccountId}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{bankPayment.bankAccountId}
							</a>
						)
						: (
							<a
								href={`/application/${applicationId}/banking/${bankPayment.bankAccountId}?place=customer`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{bankPayment.bankAccountId}
							</a>
						),
				},
			nested: true,
		},
		{
			title: 'to',
			data: bankPayment?.direction === 'inbound'
				? {
					name: bankAccount.type === 'user'
						? (
							<a
								href={`/application/${applicationId}/clients/${appPayment?.user?.id}?tab=user_bank_accounts`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{`${appPayment?.user?.firstName || '-'} ${appPayment?.user?.lastName || ''}`}
							</a>
						)
						: 'Customer',
					bankAccountId: bankAccount.type === 'user'
						? (
							<a
								href={`/application/${applicationId}/client_bank_accounts/${bankPayment.bankAccountId}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{bankPayment.bankAccountId}
							</a>
						)
						: (
							<a
								href={`/application/${applicationId}/banking/${bankPayment.bankAccountId}?place=customer`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{bankPayment.bankAccountId}
							</a>
						),
				}
				: bankPayment?.counterparty?.details,
			nested: true,
		},
	] : [];

	const paymentInfo = [{
		title: 'Payment Information',
		tag: bankPayment.status,
		data: {
			paymentId: appPayment?.id
				? (
					<div className="flex-container">
						<a
							href={`/application/${applicationId}/payments/${appPayment?.id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{appPayment?.id}
						</a>
						<CopyComponent
							text={appPayment?.id}
							content=""
						/>
					</div>
				)
				: '-',
			amount: PriceHelpers.formatAmount(
				bankPayment.amount,
				bankPayment.instrumentId,
			),
			fee: PriceHelpers.formatAmount(
				appPayment?.fee,
				appPayment?.instrumentId,
			),
			accountId: bankAccount.userBankAccount ? (
				<div className="flex-container">
					<a
						href={`/application/${applicationId}/clients/${bankAccount.userBankAccount?.bankUser.userId}?accountId=${bankAccount.userBankAccount?.accountId}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{bankAccount.userBankAccount.accountId}
					</a>
					<CopyComponent
						text={bankAccount.userBankAccount.accountId}
						content=""
					/>
				</div>
			) : 'Customer Account',
			walletId: bankAccount.userBankAccount
				? (
					<div className="flex-container">
						<a
							href={`/application/${applicationId}/clients/${bankAccount.userBankAccount?.bankUser.userId}/user_wallets/${bankAccount.userBankAccount?.walletId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{bankAccount.userBankAccount.walletId}
						</a>
						<CopyComponent
							text={bankAccount.userBankAccount.walletId}
							content=""
						/>
					</div>
				) : 'Customer Wallet',
		},
	}];

	const transfers = bankPayment.bankTransfers ? orderBy(bankPayment.bankTransfers, 'createdAt') : [];

	const getFrom = (el) => {
		if (el.direction === 'inbound') {
			return el?.counterparty?.details?.accountHolderName
			|| el.request?.Destination?.Id
			|| el.request?.Destination?.Name;
		}
		return bankAccount.type === 'user' ? `${appPayment?.user?.firstName || '-'} ${appPayment?.user?.lastName || ''}` : 'Customer';
	};

	const getTo = (el) => {
		if (el.direction === 'inbound') {
			return bankAccount.type === 'user' ? `${appPayment?.user?.firstName || '-'} ${appPayment?.user?.lastName || ''}` : 'Customer';
		}
		return el?.counterparty?.details?.accountHolderName
		|| el.request?.Destination?.Id
		|| el.request?.Destination?.Name;
	};

	return (
		<>
			{!isFetchingBankPayment
				? (
					<>
						<div>
							<Title
								title={`${bankPayment.direction || ''} ${bankPayment.scheme || ''} Bank Payment`}
								applicationId={applicationId}
								place="bankPayment"
								tags={[bankPayment.status]}
							/>
						</div>
						<div className="page-container">
							{bankPayment.id
								? (
									<Row gutter={24} className="payment-details">
										<Col span={12}>
											<NumbersCard parts={amountData} />
											<DataCard parts={paymentData} />
											<DataCard parts={generalInfo} />

										</Col>
										<Col span={12}>
											<DataCard parts={paymentInfo} />
											<Card bordered={false} className={styles.transfer}>
												<h4>Bank Transfers</h4>
												{transfers[0] && (
													<div className={styles.item}>
														<p>{`${transfers[0].scheme} / ${transfers[0].direction}`}</p>
														<div className={styles.from}>
															<p>{`${getFrom(transfers[0])} -> ${getTo(transfers[0])}`}</p>
															<Tag status={transfers[0].status} />
														</div>
													</div>
												)}
												{transfers[1] && (
													<div className={styles.item}>
														<p>{`${transfers[1].scheme} / ${transfers[1].direction}`}</p>
														<div className={styles.from}>
															<p>{`${getTo(transfers[0])} -> ${getTo(transfers[1])}`}</p>
															<Tag status={transfers[1].status} />
														</div>
													</div>
												)}
											</Card>

										</Col>
									</Row>
								)
								: (
									<div className="empty-state">
										<UserNoChecks />
										<p>Not Found</p>
									</div>
								)}
						</div>
					</>
				) : <Spin spinning={isFetchingBankPayment} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	bankPayment: state.bank.bankPayment,
	isFetchingBankPayment: state.bank.isFetchingBankPayment,
	bankAccount: state.bank.bankAccount,
	user: state.users.fullUser,
	appPayment: state.payment.appPayments[0],
});

const mapDispatchToProps = {
	getBankPayment,
	getClientBankAccount,
	getApplicationPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankPaymentPage);
