import Decimal from 'decimal.js';

// Configure Decimal.js to not convert long numbers to exponent notation
Decimal.set({
	toExpPos: 64,
	toExpNeg: -64,
	precision: 64,
});

const STATIC_ALIASES = {
	sub: ['subtract'],
	mul: ['multiply'],
	div: ['divide'],
	greaterThan: ['gt'],
	greaterThanOrEqualTo: ['gte'],
	lessThan: ['lt'],
	lessThanOrEqualTo: ['lte'],
};

const PROTOTYPE_ALIASES = {
	absoluteValue: ['abs'],
	plus: ['add'],
	minus: ['sub', 'subtract'],
	dividedBy: ['div', 'divide'],
	times: ['mul', 'multiply'],
	equals: ['eq'],
	greaterThan: ['gt'],
	greaterThanOrEqualTo: ['gte'],
	lessThan: ['lt'],
	lessThanOrEqualTo: ['lte'],
	isInteger: ['isInt'],
	isPositive: ['isPos'],
	isNegative: ['isNeg'],
	decimalPlaces: ['dp'],
	negated: ['neg'],
};

const ROUNDING = {
	CEIL: Decimal.ROUND_CEIL,
	FLOOR: Decimal.ROUND_FLOOR,
};

/**
 * Class for handling numbers on the platform
 *
 * @borrows Numbers.sub as Numbers.subtract
 * @borrows Numbers.mul as Numbers.multiply
 * @borrows Numbers.div as Numbers.divide
 *
 * @class Numbers
 */
class Numbers {
	/**
	 * Creates an instance of Numbers.
	 *
	 * @borrows Numbers#plus as Numbers#add
	 * @borrows Numbers#absoluteValue as Numbers#abs
	 * @borrows Numbers#minus as Numbers#sub
	 * @borrows Numbers#minus as Numbers#subtract
	 * @borrows Numbers#dividedBy as Numbers#div
	 * @borrows Numbers#dividedBy as Numbers#divide
	 * @borrows Numbers#times as Numbers#mul
	 * @borrows Numbers#times as Numbers#multiply
	 * @borrows Numbers#equals as Numbers#eq
	 * @borrows Numbers#greaterThan as Numbers#gt
	 * @borrows Numbers#greaterThanOrEqualTo as Numbers#gte
	 * @borrows Numbers#lessThan as Numbers#lt
	 * @borrows Numbers#lessThanOrEqualTo as Numbers#lte
	 *
	 * @param {string|Decimal|Numbers} value Value.
	 */
	constructor(value) {
		this.value = Numbers.toBigNumber(value);
	}

	/**
	 * Get the caller function name so we can determine if user is trying to convert to number.
	 *
	 * @returns {string} Function name.
	 *
	 * @memberof Numbers
	 */
	getCallerName() {
		const error = new Error();
		const frame = error.stack.split('\n')[3];
		return frame.split(' ')[5];
	}

	/**
	 * Converts the Numbers instance to string.
	 *
	 * @returns {string} String representation.
	 *
	 * @throws {MethodNotAllowedError} When user tries to convert to a number.
	 *
	 * @memberof Numbers
	 */
	toString() {
		const callerName = this.getCallerName();
		if (['Number', 'parseFloat', 'parseInt'].includes(callerName)) {
			console.log('Converting to regular numbers is not allowed, please use Numbers.toString() instead.');
		}

		return this.value.toString();
	}

	/**
	 * Converts the Numbers instance to string with fixed number of decimal places.
	 *
	 * @param {number} precision Precision.
	 * @param {ROUNDING.FLOOR|ROUNDING.CEIL} rounding Rounding Mode.
	 *
	 * @returns {string} String representation with fixed number of decimal places.
	 *
	 * @throws When precision is less than value precision but no rounding mode is specified
	 *
	 * @memberof Numbers
	 */
	toFixed(precision) {
		return this.value.toFixed(precision);
	}

	/**
	 * Converts the Numbers instance to JSON.
	 *
	 * @returns {object} JSON Representation.
	 *
	 * @memberof Numbers
	 */
	toJSON() {
		return this.value.toJSON();
	}

	/**
	 * Not allowed.
	 *
	 * @throws {MethodNotAllowedError} Method is not allowed. Use toString instead.
	 *
	 * @memberof Numbers
	 */
	toNumber() {
		console.log('Converting to regular numbers is not allowed, please use Numbers.toString() instead.');
	}

	/**
	 * Not allowed.
	 *
	 * @throws {MethodNotAllowedError} Method is not allowed. Use toString instead.
	 *
	 * @memberof Numbers
	 */
	valueOf() {
		console.log('valueOf method is not allowed, please use Numbers.toString() instead.');
	}

	replace(pattern, replacement) {
		return this.toString().replace(pattern, replacement);
	}

	/**
	 * Adds a number.
	 *
	 * @param {string|Decimal|Numbers} value Value to add.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	plus(value) {
		return new Numbers(this.value.plus(Numbers.toBigNumber(value)));
	}

	/**
	 * Subtracts a number.
	 *
	 * @param {string|Decimal|Numbers} value Value to subtract.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	minus(value) {
		return new Numbers(this.value.minus(Numbers.toBigNumber(value)));
	}

	/**
	 * Divide by a number.
	 *
	 * @param {string|Decimal|Numbers} value Value to divide by.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	dividedBy(value) {
		return new Numbers(this.value.dividedBy(Numbers.toBigNumber(value)));
	}

	/**
	 * Multiply with a number.
	 *
	 * @param {string|Decimal|Numbers} value Value to multiply with.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	*/
	times(value) {
		return new Numbers(this.value.times(Numbers.toBigNumber(value)));
	}

	/**
	 * Absolute value of a number.
	 *
	 * @returns {Numbers} New instance with an absolute value.
	 *
	 * @memberof Numbers
	 */
	absoluteValue() {
		return new Numbers(this.value.absoluteValue());
	}

	/**
	 * Floor to precision.
	 *
	 * @param {number} precision Precision.
	 *
	 * @returns {Numbers} New instance with a floored value.
	 *
	 * @throws {InvalidArgumentTypeError} Precision is not an integer.
	 *
	 * @memberof Numbers
	 */
	floor(precision) {
		if (!Number.isInteger(precision)) {
			console.log('Precision must be an integer.');
		}
		return this.value.toDecimalPlaces
			? new Numbers(this.value.toDecimalPlaces(precision, ROUNDING.FLOOR))
			: new Numbers(0);
	}

	/**
	 * Ceil to precision.
	 *
	 * @param {number} precision Precision.
	 *
	 * @returns {Numbers} New instance with a ceiled value.
	 *
	 * @throws {InvalidArgumentTypeError} Precision is not an integer.
	 *
	 * @memberof Numbers
	 */
	ceil(precision) {
		if (!Number.isInteger(precision)) {
			console.log('Precision must be an integer.');
		}

		if (this.value.toDecimalPlaces) {
			return new Numbers(this.value.toDecimalPlaces(precision, ROUNDING.CEIL));
		}
		return 0;
	}

	/**
	 * Not allowed.
	 *
	 * @throws Method is not allowed. Use {@link Numbers#floor} or {@link Numbers#ceil} instead.
	 *
	 * @memberof Numbers
	 */
	round() {
		console.log('Rounding is not allowed, please use ceil or floor instead');
	}

	/**
	 * Check if values are equal.
	 *
	 * @param {string|Decimal|Numbers} value Value to compare with.
	 *
	 * @returns {boolean} Is the value equal.
	 *
	 * @memberof Numbers
	 */
	equals(value) {
		return this.value.equals(Numbers.toBigNumber(value));
	}

	/**
	* Check if Numbers instance is greater than value.
	*
	* @param {string|Decimal|Numbers} value Value to compare with.
	*
	* @returns {boolean} Numbers instance holds the greater value.
	*
	* @memberof Numbers
	*/
	greaterThan(value) {
		return this.value.greaterThan(Numbers.toBigNumber(value));
	}

	/**
	 * Check if Numbers instance is greater than or equal to value.
	 *
	 * @param {string|Decimal|Numbers} value Value to compare with.
	 *
	 * @returns {boolean} Numbers instance holds the greater or equal value.
	 *
	 * @memberof Numbers
	 */
	greaterThanOrEqualTo(value) {
		return this.value.greaterThanOrEqualTo(Numbers.toBigNumber(value));
	}

	/**
	 * Check if Numbers instance is lesser than value.
	 *
	 * @param {string|Decimal|Numbers} value Value to compare with.
	 *
	 * @returns {boolean} Numbers instance holds the lesser value.
	 *
	 * @memberof Numbers
	 */
	lessThan(value) {
		return this.value.lessThan(Numbers.toBigNumber(value));
	}

	/**
	 * Check if Numbers instance is lesser than or equal to value.
	 *
	 * @param {string|Decimal|Numbers} value Value to compare with.
	 *
	 * @returns {boolean} Numbers instance holds the lesser or equal value.
	 *
	 * @memberof Numbers
	 */

	lessThanOrEqualTo(value) {
		return this.value.lessThanOrEqualTo(Numbers.toBigNumber(value));
	}

	isInteger() {
		return this.value.isInteger();
	}

	isNaN() {
		return this.value.isNaN();
	}

	isNegative() {
		return this.value.isNegative();
	}

	isPositive() {
		return this.value.isPositive();
	}

	isZero() {
		return this.value.isZero();
	}

	decimalPlaces() {
		return this.value.decimalPlaces();
	}

	negated() {
		return new Numbers(this.value.negated());
	}

	pow(exponent) {
		return new Numbers(this.value.pow(exponent));
	}

	/**
	 * Convert a value to Decimal.
	 *
	 * @static
	 * @param {string|Decimal} value Value.
	 * @returns {Decimal} Decimal instance.
	 * @memberof Numbers
	 */
	static toBigNumber(value) {
		if (typeof value !== 'string' && !(value instanceof Decimal) && !(value instanceof this)) {
			console.log('Value must be a String or BigNumber.');
		}

		// Convert a string
		if (typeof value === 'string') {
			try {
				return new Decimal(value);
			} catch (e) {
				console.log('Can not convert to BigNumber.');
			}
		}

		// We got a Numbers instance
		if (value instanceof this) {
			return value.value;
		}

		// It's already Decimal
		return value;
	}

	/**
	 * Adds two numbers.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} x First value.
	 * @param {string|Decimal|Numbers} y Second value.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	static add(x, y) {
		return new this(Decimal.add(this.toBigNumber(x), this.toBigNumber(y)));
	}

	/**
	 * Subtracts two numbers.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} x First value.
	 * @param {string|Decimal|Numbers} y Second value.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	static sub(x, y) {
		return new this(Decimal.sub(this.toBigNumber(x), this.toBigNumber(y)));
	}

	/**
	 * Divides two numbers.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} x First value.
	 * @param {string|Decimal|Numbers} y Second value.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	static div(x, y) {
		return new this(Decimal.div(this.toBigNumber(x), this.toBigNumber(y)));
	}

	/**
	 * Multiplies two numbers.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} x First value.
	 * @param {string|Decimal|Numbers} y Second value.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	static mul(x, y) {
		return new this(Decimal.mul(this.toBigNumber(x), this.toBigNumber(y)));
	}

	/**
	 * Sums the values.
	 *
	 * @static
	 *
	 * @param {...string|Decimal|Numbers} values Values.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	static sum(...values) {
		if (values.length < 2) {
			console.log('Numbers.sum() requires at least 2 arguments');
		}
		return values.reduce((prev, curr) => {
			if (!prev) {
				return new this(curr);
			}

			return prev.plus(curr);
		}, null);
	}

	/**
	 * Muliply the values.
	 *
	 * @static
	 *
	 * @param {...string|Decimal|Numbers} values Values.
	 *
	 * @returns {Numbers} New instance with calculated value.
	 *
	 * @memberof Numbers
	 */
	static product(...values) {
		if (values.length < 2) {
			console.log('Numbers.prod() requires at least 2 arguments');
		}
		return values.reduce((prev, curr) => {
			if (!prev) {
				return new this(curr);
			}

			return prev.times(curr);
		}, null);
	}

	/**
	 * Absolute value.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} value Value.
	 *
	 * @returns {Numbers} New instance with absolute value.
	 *
	 * @memberof Numbers
	 */
	static abs(value) {
		return new this(value).abs();
	}

	/**
	 * Floor the value to a precision.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} value Value.
	 * @param {number} precision Precision.
	 *
	 * @returns {Numbers} New instance with floored value.
	 *
	 * @memberof Numbers
	 */
	static floor(value, precision) {
		return new this(value).floor(precision);
	}

	/**
	 * Ceil the value to a precision.
	 *
	 * @static
	 *
	 * @param {string|Decimal|Numbers} value Value.
	 * @param {number} precision Precision.
	 *
	 * @returns {Numbers} New instance with ceiled value.
	 *
	 * @memberof Numbers
	 */
	static ceil(value, precision) {
		return new this(value).ceil(precision);
	}

	/**
	 * Not allowed.
	 *
	 * @static
	 *
	 * @throws Method is not allowed. Use {@link Numbers.floor} or {@link Numbers.ceil} instead.
	 *
	 * @memberof Numbers
	 */
	static round() {
		console.log('Rounding is not allowed, please use ceil or floor instead');
	}

	static fix(value, precision) {
		return new Numbers(value).toFixed(precision);
	}

	// TODO: write unit tests
	static greaterThanOrEqualTo(x, y) {
		return new Numbers(x).greaterThanOrEqualTo(y);
	}

	// TODO: write unit tests
	static greaterThan(x, y) {
		return new Numbers(x).greaterThan(y);
	}

	// TODO: write unit tests
	static lessThanOrEqualTo(x, y) {
		return new Numbers(x).lessThanOrEqualTo(y);
	}

	// TODO: write unit tests
	static lessThan(x, y) {
		return new Numbers(x).lessThan(y);
	}

	// TODO: write unit tests
	static pow(x, y) {
		return new Numbers(x).pow(y);
	}

	// TODO: write unit tests
	static mod(x, y) {
		return new Numbers(Decimal.mod(this.toBigNumber(x), this.toBigNumber(y)));
	}

	static sign(x) {
		return new Numbers(x).isNegative(x);
	}
}

Numbers.ROUNDING = ROUNDING;

Object.keys(STATIC_ALIASES).forEach((original) => {
	const aliases = STATIC_ALIASES[original];
	aliases.forEach((alias) => {
		Numbers[alias] = Numbers[original];
	});
});

Object.keys(PROTOTYPE_ALIASES).forEach((original) => {
	const aliases = PROTOTYPE_ALIASES[original];
	aliases.forEach((alias) => {
		Numbers.prototype[alias] = Numbers.prototype[original];
	});
});

export default Numbers;
