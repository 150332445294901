// @flow

import React, { useState } from 'react';
//= import components
import Form from './index';
import CPButton from '../Button';
import Table from '../Table';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	data: Array<Object>,
	btnText: string,
	columnsData: Array<string>,
}

const AddTo = (props: Props) => {
	const [selectRow, setSelectRow] = useState('');
	const handleChange = (selectedRowKeys) => {
		setSelectRow(selectedRowKeys);
	};

	const submit = (e) => {
		e.preventDefault();
		props.submitChanges(selectRow);
	};

	const {
		form: { getFieldDecorator }, handleCancelClick, data, btnText, columnsData,
	} = props;
	const FormItem = Form.Item;
	const columns = columnsData.map((col, index) => ({
		title: ActivityHelpers.transformToReadable(col).toUpperCase(),
		dataIndex: col,
		key: col,
		width: (index + 1) * 176,
		render: (text: string) => (
			<span className="capitalize wrap" style={{ maxWidth: (index + 1) * 176 - 16 }}>
				{text || '-'}
			</span>
		),
	}));
	const rowSelection = {
		onChange: handleChange,
		type: 'radio',
	};
	return (
		<Form onSubmit={submit} className="add-to-table">
			<Form.Item>
				{getFieldDecorator('data')(
					<Table
						rowSelection={rowSelection}
						columns={columns}
						dataSource={data}
						pagination={false}
						rowKey={(record) => record.id}
					/>,
				)}
			</Form.Item>
			<div className="form-buttons">
				<FormItem>
					<CPButton ghost action={handleCancelClick} text="cancel" />
				</FormItem>
				<FormItem>
					<CPButton type="primary" action={submit} disabled={!selectRow} text={btnText} />
				</FormItem>
			</div>
		</Form>
	);
};

const AddToForm = Form.create()(AddTo);

export default AddToForm;
