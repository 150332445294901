// @flow

const initialState = {
	instruments: [],
	isFetchingInstruments: false,
	isFetchedInstruments: false,
	instrumentsTotal: 0,
	instrument: {},
	isFetchingInstrument: false,
	isFetchedInstrument: false,
	isAdded: false,
	isAdding: false,
	isRemoving: false,
	isRemoved: false,
	isUpdating: false,
	isUpdated: false,
	error: false,
};

export type Instrument = {
	default: boolean,
	depositPaymentMethods: Array<string>,
	description: string,
	haircut: number,
	id: string,
	instrumentId: string,
	isin: string,
	keywords: Array<string>,
	meta: {[string]: string},
	name: string,
	type: string,
	precision: number,
	withdrawalPaymentMethods: Array<string>,
	delisted: boolean,
	formatting: {
		grapheme: string,
	},
	rank: number,
}
export type InstrumentState = {
	instruments: Array<Instrument>,
	isFetchingInstruments: boolean,
	isFetchedInstruments: boolean,
	instrumentsTotal: number,
	instrument: Instrument,
	isFetchingInstrument: boolean,
	isFetchedInstrument: boolean,
	isAdded: boolean,
	isAdding: boolean,
	isRemoving: boolean,
	isRemoved: boolean,
	isUpdating: boolean,
	isUpdated: boolean,
	instrument: Instrument,
	isFetchingInstrument: boolean,
	isFetchedInstrument: boolean,
	error: Object | boolean,
}
type InstrumentAction = {
	type:
	| 'INSTRUMENT'
	| 'INSTRUMENT_SUCCESS'
	| 'INSTRUMENT_FAIL'
	| 'UPDATE_INSTRUMENT'
	| 'UPDATE_INSTRUMENT_SUCCESS'
	| 'UPDATE_INSTRUMENT_FAIL'
	| 'ADD_PAYMENT_METHODS'
	| 'ADD_PAYMENT_METHODS_SUCCESS'
	| 'ADD_PAYMENT_METHODS_FAIL'
	| 'REMOVE_PAYMENT_METHODS'
	| 'REMOVE_PAYMENT_METHODS_SUCCESS'
	| 'REMOVE_PAYMENT_METHODS_FAIL'
	| 'DELIST_INSTRUMENT'
	| 'DELIST_INSTRUMENT_SUCCESS'
	| 'DELIST_INSTRUMENT_FAIL',
	payload: {
		data: Instrument,
	},
	error: {
        code: number,
        message: string,
    }
}
type InstrumentsAction = {
	type:
	| 'APPLICATION_INSTRUMENTS'
	| 'APPLICATION_INSTRUMENTS_SUCCESS'
	| 'APPLICATION_INSTRUMENTS_FAIL',
	payload: {
		data: Array<Instrument>,
		headers: {
			'total-count': number,
		}
	},
	error: {
        code: number,
        message: string,
    }
}
type Action = InstrumentAction | InstrumentsAction;
function instrumentReducer(state: InstrumentState = initialState, action: Action): InstrumentState {
	switch (action.type) {
	case 'APPLICATION_INSTRUMENTS':
		return {
			...state,
			isFetchingInstruments: true,
			sFetchedInstruments: false,
			instruments: [],
		};
	case 'APPLICATION_INSTRUMENTS_FAIL':
		return {
			...state,
			isFetchingInstruments: false,
			isFetchedInstruments: false,
			instrumentsTotal: 0,
			error: action.error,
		};
	case 'APPLICATION_INSTRUMENTS_SUCCESS':
		return {
			...state,
			isFetchingInstruments: false,
			isFetchedInstruments: true,
			error: false,
			instruments: action.payload.data,
			instrumentsTotal: action.payload.headers['total-count'] ? parseInt(action.payload.headers['total-count'], 10) : state.instrumentsTotal,
		};
	case 'INSTRUMENT':
		return {
			...state,
			isFetchingInstrument: true,
			isFetchedInstrument: false,
		};
	case 'INSTRUMENT_FAIL':
		return {
			...state,
			isFetchingInstrument: false,
			isFetchedInstrument: false,
			error: action.error,
		};
	case 'INSTRUMENT_SUCCESS':
		return {
			...state,
			isFetchingInstrument: false,
			isFetchedInstrument: true,
			error: false,
			instrument: action.payload.data,
		};
	case 'ADD_PAYMENT_METHODS':
		return {
			...state,
			isAdding: true,
			isAdded: false,
		};
	case 'ADD_PAYMENT_METHODS_FAIL':
		return {
			...state,
			isAdding: false,
			isAdded: false,
			error: action.error,
		};
	case 'ADD_PAYMENT_METHODS_SUCCESS':
		return {
			...state,
			isAdding: false,
			isAdded: true,
			error: false,
			instrument: action.payload.data,
			instruments: state.instruments.map((instrument: Instrument) => {
				if (instrument.id === action.payload.data.id) {
					return action.payload.data;
				}
				return instrument;
			}),
		};
	case 'REMOVE_PAYMENT_METHODS':
		return {
			...state,
			isRemoving: true,
			isRemoved: false,
		};
	case 'REMOVE_PAYMENT_METHODS_FAIL':
		return {
			...state,
			isRemoving: false,
			isRemoved: false,
			error: action.error,
		};
	case 'REMOVE_PAYMENT_METHODS_SUCCESS':
		return {
			...state,
			isRemoving: false,
			isRemoved: true,
			error: false,
			instrument: action.payload.data,
			instruments: state.instruments.map((instrument: Instrument) => {
				if (instrument.id === action.payload.data.id) {
					return action.payload.data;
				}
				return instrument;
			}),
		};
	case 'UPDATE_INSTRUMENT':
		return {
			...state,
			isUpdating: true,
			isUpdated: false,
		};
	case 'UPDATE_INSTRUMENT_FAIL':
		return {
			...state,
			isUpdating: false,
			isUpdated: false,
			error: action.error,
		};
	case 'UPDATE_INSTRUMENT_SUCCESS':
		return {
			...state,
			isUpdating: false,
			isUpdated: true,
			error: false,
			instrument: action.payload.data,
			instruments: state.instruments.map((instrument: Instrument) => {
				if (instrument.id === action.payload.data.id) {
					return action.payload.data;
				}
				return instrument;
			}),
		};
	case 'DELIST_INSTRUMENT':
		return {
			...state,
			isFetchingInstrument: true,
			isFetchedInstrument: false,
		};
	case 'DELIST_INSTRUMENT_FAIL':
		return {
			...state,
			isFetchingInstrument: false,
			isFetchedInstrument: false,
			error: action.error,
		};
	case 'DELIST_INSTRUMENT_SUCCESS':
		return {
			...state,
			isFetchingInstrument: false,
			isFetchedInstrument: true,
			error: false,
			instrument: action.payload.data,
		};
	default:
		return state;
	}
}

export default instrumentReducer;
