import React, {
	useState, useEffect, useCallback,
} from 'react';
import { connect } from 'react-redux';
//= import components
import Title from '../../../components/UiElements/Title';
import Switch from '../../../components/UiElements/Switch';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
//= import actions
import { getAppFeatures, updateAppFeatures } from '../../../modules/actions/FeaturesAction';
//= import types
import type { Feature } from '../../../modules/reducers/FeatureReducer';
//= import styles
import styles from '../assets/system.module.scss';

type Props = {
	getAppFeatures: (string) => Promise<Object>,
	updateAppFeatures: (string, string, Object) => Promise<Object>,
	applicationId: string,
	match: {
		params: {
			applicationId: string,
			featureId: string,
		},
	},
	history: {
		goBack: () => void,
	},
}

type FeaturesResponse = {
	payload: {
		data: Array<Feature>,
	},
}

const NewFeature = (props: Props) => {
	const {
		match: { params: { applicationId, featureId } },
		getAppFeatures: getAppFeaturesAction,
		updateAppFeatures: updateAppFeaturesAction,
	} = props;

	const [newFeature, setFeature] = useState({});
	const [switchKey, setSwitchKey] = useState('first');
	const [data, setData] = useState({});
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		getAppFeaturesAction(applicationId)
			.then((res: FeaturesResponse) => {
				const featureData: ?Feature = res.payload.data.find(
					(feature: Feature) => feature.featureId === featureId,
				);
				if (featureData) {
					setFeature(featureData);
				}
			});
	}, [applicationId, getAppFeaturesAction, data, featureId]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleToggle = (value: boolean): void => {
		const updateData = { enabled: value };
		setData(updateData);
	};

	const handleTogleCancel = () => {
		setToggle(false);
		setSwitchKey('changed');
		setData({});
	};

	const submitUpdate = useCallback(() => {
		updateAppFeaturesAction(applicationId, newFeature.featureId, data)
			.then(() => {
				setData({});
				setToggle(false);
			})
			.catch(() => setSwitchKey('changed'));
	}, [applicationId, data, newFeature.featureId, updateAppFeaturesAction]);

	useEffect(() => {
		if (data.enabled) {
			submitUpdate();
		} else if (data.enabled === false) {
			setToggle(true);
		}
	}, [data, submitUpdate]);

	return (
		<>
			<Title
				backAction={handleBackClick}
				title={newFeature.feature ? newFeature.feature.name : featureId}
				switchComponent={{
					key: switchKey,
					defaultChecked: newFeature.enabled,
					action: handleToggle,
				}}
			>
				<div className={styles.feature}>
					<Switch
						key={switchKey}
						checked={newFeature.enabled}
						onChange={(value: boolean) => handleToggle(value)}
					/>
				</div>
			</Title>

			<DeleteModal
				okFunction={submitUpdate}
				cancelFunction={handleTogleCancel}
				visible={toggle}
				btnText="DISABLE"
				message={`You're about to disable ${newFeature.feature ? newFeature.feature.name : featureId} feature for all your clients across entire Application.`}
			/>
		</>
	);
};

const mapDispatchToProps = {
	getAppFeatures,
	updateAppFeatures,
};

export default connect(null, mapDispatchToProps)(NewFeature);
