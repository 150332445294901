// @flow
import type { Symbol } from './SymbolReducer';

const initialState = {
	error: false,
	isFetchingInventoryWallets: false,
	isFetchedInventoryWallets: false,
	inventoryWallets: [],
	inventoryWalletsTotal: 0,
	inventoryWallet: {},
	isFetchingInventoryWallet: false,
	isFetchedInventoryWallet: false,
	inventoryTransactions: [],
	isFetchingInventoryTransactions: false,
	isFetchedInventoryTransactions: false,
	inventoryTransactionsTotal: 0,
};

export type InventoryWallet = {
    allocated: string,
    available: string,
    createdAt: string,
    deletedAt: string,
    id: string,
    reserved: string,
    symbol: Symbol,
    symbolId: string,
	updatedAt: string,
	delisted: boolean,
}
export type InventoryWalletTransaction = {
    amount: string,
    available: string,
    createdAt: string,
    debtBalanceAfter: string,
    id: string,
    instrumentId: string,
    reference: string,
    referenceId: string,
    reserved: string,
    total: string,
    type: string,
    updatedAt: string,
    walletId: string,
}
export type InventoryWalletState = {
    error: boolean | Object,
    isFetchingInventoryWallets: boolean,
    isFetchedInventoryWallets: boolean,
    inventoryWallets: Array<InventoryWallet>,
    inventoryWalletsTotal: number,
    inventoryWallet: InventoryWallet,
    isFetchingInventoryWallet: boolean,
    isFetchedInventoryWallet: boolean,
    inventoryTransactions: Array<InventoryWalletTransaction>,
    isFetchingInventoryTransactions: boolean,
    isFetchedInventoryTransactions: boolean,
    inventoryTransactionsTotal: number,
}

export type WalletAction = {
    type:
    | 'GET_INVENTORY_WALLET_SUCCESS'
    | 'GET_INVENTORY_WALLET'
    | 'GET_INVENTORY_WALLET_FAIL',
    payload: {
        data: InventoryWallet,
        headers: {
            'total-count': number,
        }
    },
    error: {
        code: number,
        message: string,
    }
}
export type WalletsAction = {
    type:
    | 'GET_INVENTORY_WALLETS_SUCCESS'
    | 'GET_INVENTORY_WALLETS_FAIL'
    | 'GET_INVENTORY_WALLETS',
    payload: {
        data: Array<InventoryWallet>,
        headers: {
            'total-count': number,
        }
    },
    error: {
        code: number,
        message: string,
    }
}
export type TransactionActions = {
    type:
    | 'GET_INVENTORY_TRANSACTIONS'
    | 'GET_INVENTORY_TRANSACTIONS_SUCCESS'
    | 'GET_INVENTORY_TRANSACTIONS_FAIL',
    payload: {
        data: Array<InventoryWalletTransaction>,
        headers: {
            'total-count': number,
        }
    },
    error: {
        code: number,
        message: string,
    }
}
export type Actions = WalletAction | WalletsAction | TransactionActions

export default function walletReducer(
	state: InventoryWalletState = initialState,
	action: Actions,
): InventoryWalletState {
	switch (action.type) {
	case 'GET_INVENTORY_WALLETS': {
		return {
			...state,
			isFetchingInventoryWallets: true,
			isFetchedInventoryWallets: false,
			error: false,
		};
	}
	case 'GET_INVENTORY_WALLETS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingInventoryWallets: false,
			isFetchedInventoryWallets: true,
			inventoryWallets: action.payload.data,
			inventoryWalletsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'GET_INVENTORY_WALLETS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingInventoryWallets: false,
			isFetchedInventoryWallets: false,
		};
	}
	case 'GET_INVENTORY_WALLET': {
		return {
			...state,
			isFetchingInventoryWallet: true,
			isFetchedInventoryWallet: false,
			inventoryWallet: {},
			error: false,
		};
	}
	case 'GET_INVENTORY_WALLET_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingInventoryWallet: false,
			isFetchedInventoryWallet: true,
			inventoryWallet: action.payload.data,
		};
	}
	case 'GET_INVENTORY_WALLET_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingInventoryWallet: false,
			isFetchedInventoryWallet: false,
		};
	}
	case 'GET_INVENTORY_TRANSACTIONS': {
		return {
			...state,
			isFetchingInventoryTransactions: true,
			isFetchedInventoryTransactions: false,
			inventoryTransactions: [],
			error: false,
		};
	}
	case 'GET_INVENTORY_TRANSACTIONS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingInventoryTransactions: false,
			isFetchedInventoryTransactions: true,
			inventoryTransactions: action.payload.data,
			inventoryTransactionsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'GET_INVENTORY_TRANSACTIONS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingInventoryTransactions: false,
			isFetchedInventoryTransactions: false,
		};
	}
	default:
		return state;
	}
}
