// @flow

import React, {
	useEffect, useState, useCallback, useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
//= import components
import Title from '../../components/UiElements/Title';
import ActivitiLog from './containers/ActivityLog';
import ProviderLogs from './containers/ProviderLogs';
//= import actions
import { getActivityLog, getProviderLogs } from '../../modules/actions/LogsActions';
import { getUsers, searchUsers } from '../../modules/actions/UsersActions';
import { getProvidersInstances } from '../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../modules/actions/UiActions';
import { getEvents } from '../../modules/actions/WebhooksActions';
//= import helpers
import ProviderHelpers from '../../lib/helpers/providerHelpers';
//= import types
import type { Event } from '../../modules/reducers/WebhooksReducer';
import type { ProviderInstance } from '../../modules/reducers/ProviderReducer';
import type { User } from '../../modules/reducers/UsersReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	setCurrentPage: (string, number) => void,
	getUsers: (string, number, number) => void,
	searchUsers: (string, string) => void,
	getProvidersInstances: (string) => void,
	getActivityLog: (string, number, number, string) => void,
	users: Array<User>,
	activeTab: string,
	filters: { [string]: Array<string> },
	providers: Array<ProviderInstance>,
	currentPage: number,
	providerFilters: { [string]: string },
	providerPage: number,
	getProviderLogs: (string, number, number, string) => void,
	getEvents: () => Promise<Object>,
	events: Array<Event>,
	match: {
		params: {
			applicationId: string
		}
	},
	location: {
		pathname: string,
		search: string,
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}

const Logs = (props: Props) => {
	const {
		match: { params: { applicationId } },
		activeTab,
		location: { search },
		filters,
		users,
		providers,
		currentPage,
		getActivityLog: getActivityLogAction,
		getUsers: getUsersAction,
		searchUsers: searchUsersAction,
		getProvidersInstances: getAppProvidersAction,
		providerFilters,
		providerPage,
		getProviderLogs: getProviderLogsAction,
		setCurrentPage: setCurrentPageAction,
		getEvents: getEventsAction,
		events,
	} = props;

	const [active, setActive] = useState('');
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterHeight, setFilterHeight] = useState(0);

	useEffect(() => {
		getEventsAction();
	}, [getEventsAction]);

	useEffect(() => {
		setActive(queryString.parse(search).active);
		getUsersAction(applicationId, 1, 50);
		getAppProvidersAction(applicationId);
	}, [applicationId, getAppProvidersAction, getUsersAction, search]);

	useEffect(() => {
		const affectedModels = uniq(filters.eventName?.map((el) => el.split('.')[0]));
		const eventNames = uniq(filters.eventName?.map((el) => el.split('.')[1]));
		const filterParam = {
			...filters,
			eventName: eventNames,
			affectedModel: affectedModels,

		};
		getActivityLogAction(applicationId, 50, currentPage, queryString.stringify(filterParam, { arrayFormat: 'comma' }));
	}, [applicationId, filters, currentPage, getActivityLogAction]);

	useEffect(() => {
		getProviderLogsAction(applicationId, 50, providerPage, queryString.stringify(providerFilters, { arrayFormat: 'bracket' }));
	}, [applicationId, providerFilters, providerPage, getProviderLogsAction]);

	const filterRef = useRef(null);

	useEffect(() => {
		if (filterRef.current) {
			setFilterHeight(filterRef.current.clientHeight);
		} else {
			setFilterHeight(0);
		}
	}, [filters, providerFilters, activeTab]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction(`${activeTab}Page`, page);
	};

	const searchForUsers = (value: string) => {
		searchUsersAction(applicationId, value);
	};

	const getAllUsers = () => {
		getUsersAction(applicationId, 1, 50);
	};

	const debounceSearchUsers = useCallback(debounce(searchForUsers, 500), []);

	const debounceGetUsers = useCallback(debounce(getAllUsers, 500));


	const handleSearch = (value: string) => {
		if (value) {
			debounceSearchUsers(value);
		} else {
			debounceGetUsers();
		}
	};

	const usersForFilter = !!users.length && users.map((user) => (
		{
			id: user.id,
			name: user.userInfo ? `${user.userInfo.firstName || 'Name Not Set'} ${user.userInfo.lastName || ''} ` : 'Name Not Set',
		}
	));

	const handleBackClick = () => {
		props.history.goBack();
	};

	const mapProviders = providers.map((provider) => (
		{
			id: provider.provider.name,
			name: provider.provider.displayName,
		}));

	const allNames = events.map((el) => ({
		id: el.name,
		name: el.name,
	}));

	const eventNames = uniqBy(allNames, 'name');

	return (
		<>
			<div ref={filterRef}>
				<Title
					backAction={handleBackClick}
					title="Logs"
					place={activeTab}
					buttons={[
						{
							action: () => setFilterVisible(true),
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					tabs={[
						{
							key: 'activityLog',
							title: 'ACTIVITY LOGS',
						},
						{
							key: 'providerLog',
							title: 'PROVIDER LOGS',
						},
					]}
					fields={activeTab === 'activityLog'
						? {
							eventName: eventNames,
							performedBy: usersForFilter,
							dateRange: 'time',
						}
						: {
							reference: ProviderHelpers.referenceOptions,
							action: ProviderHelpers.actionOptions,
							name: mapProviders,
							dateRange: 'time',
						}}
					date
					tab="log"
					searchFunction={(value: string) => handleSearch(value)}
					visible={filterVisible}
					closeFunction={() => setFilterVisible(false)}
					applicationId={applicationId}
					filter
				/>
			</div>
			<div className="container">
				{activeTab === 'activityLog' && (
					<ActivitiLog
						applicationId={applicationId}
						search={search}
						handlePageChange={handlePageChange}
						filterHeight={filterHeight}
						active={active}
					/>
				)}
				{activeTab === 'providerLog' && (
					<ProviderLogs
						applicationId={applicationId}
						handlePageChange={handlePageChange}
						filterHeight={filterHeight}
					/>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	users: state.users.users,
	activeTab: state.ui.logTab,
	providers: state.providers.providersInstances,
	filters: state.ui.activityLogFilters,
	currentPage: state.ui.activityLogPage,
	providerFilters: state.ui.providerLogFilters,
	providerPage: state.ui.providerLogPage,
	events: state.webhooks.events,
});

const mapDispatchToProps = {
	getUsers,
	searchUsers,
	setCurrentPage,
	getProvidersInstances,
	getActivityLog,
	getProviderLogs,
	getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
