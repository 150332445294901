// @flow

import React from 'react';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Avatar from '../../../components/UiElements/Avatar';
import Tag from '../../../components/UiElements/Tag';
//= import images
import b2c2 from '../../../assets/img/providers/provider_b2c2.svg';
import bitstamp from '../../../assets/img/providers/provider_bitstamp.svg';
import blockCypher from '../../../assets/img/providers/provider_blockcypher.svg';
import branch from '../../../assets/img/providers/provider_branch.svg';
import coinapiio from '../../../assets/img/providers/provider_coinapi.svg';
import coinpulse from '../../../assets/img/providers/provider_coinpulse.png';
import complyAdvantage from '../../../assets/img/providers/provider_complyadvantage.svg';
import cryptoCompare from '../../../assets/img/providers/provider_cryptocompare.svg';
import currencyLayer from '../../../assets/img/providers/provider_currencylayer.svg';
import customerIo from '../../../assets/img/providers/provider_customerio.svg';
import iex from '../../../assets/img/providers/provider_iexcloud.svg';
import interactivebrokers from '../../../assets/img/providers/provider_interactivebrokers.svg';
import onesignal from '../../../assets/img/providers/provider_onesignal.svg';
import onfido from '../../../assets/img/providers/provider_onfido.svg';
import quickpay from '../../../assets/img/providers/provider_quickpay.svg';
import s3 from '../../../assets/img/providers/provider_s3.svg';
import safecharge from '../../../assets/img/providers/provider_safecharge.svg';
import segment from '../../../assets/img/providers/provider_segment.svg';
import tipRanks from '../../../assets/img/providers/provider_tipRanks.jpg';
import interactiveBrokers from '../../../assets/img/providers/provider_interactiveBrokers.jpg';
import modulr from '../../../assets/img/providers/provider_modulr.png';
import trapets from '../../../assets/img/providers/provider_trapets.png';
import fireblocks from '../../../assets/img/providers/provider_fireblocks.png';
import clearJunction from '../../../assets/img/providers/provider_clearjunction.png';
import bitFuryCrystal from '../../../assets/img/providers/provider_bitFuryCrystal.png';
import reyn from '../../../assets/img/providers/provider_reyn.png';
import tribe from '../../../assets/img/providers/provider_tribe.png';
import bcLogo from '../../../assets/img/company_icon_dark.png';
//= import styles
import styles from '../assets/providers.module.scss';

type Props = {
	name: string,
	displayName: string,
	type: string,
	providerInstanceTypes: Array<string>,
	id: string,
	action: Function,
	primary?: boolean,
	instance?: boolean,
	active?: boolean,
}
const imageMap = {
	b2c2,
	bitstamp,
	blockCypher,
	branch,
	coinapiio,
	complyAdvantage,
	cryptoCompare,
	currencyLayer,
	customerIo,
	iex,
	interactivebrokers,
	onesignal,
	onfido,
	quickpay,
	s3,
	safecharge,
	segment,
	coinpulse,
	tipRanks,
	interactiveBrokers,
	modulr,
	trapets,
	fireblocks,
	clearJunction,
	bitFuryCrystal,
	reyn,
	tribe,
};
const typeMap = {
	kyc: '#F5A623',
	payment: '#4ABCE2',
	liquidity: '#6EBA1A',
	market: '#E64BAF',
	push: '#2C77D0',
	analytics: '#E66E19',
	upload: '#999999',
	news: '#9013FE',
};

const ProviderCard = (props: Props) => {
	const {
		name,
		displayName,
		type,
		providerInstanceTypes,
		id,
		action,
		primary,
		active,
		instance,
	} = props;

	return (
		<div className={styles.card}>
			<div className={styles.title}>
				<div className={styles.title}>
					{imageMap[name] ? <Avatar src={imageMap[name]} size="small" /> : <img src={bcLogo} className={styles.bcMedium} alt="bc" />}
					<div>
						<h2>{displayName}</h2>
						<div className={styles.type}>
							<div className={styles.dot} style={{ backgroundColor: typeMap[type] }} />
							<div className="capitalize">{type}</div>
							{providerInstanceTypes.includes('shared')
					&& (
						<img src={bcLogo} className={styles.bcSmall} alt="bc" />
					)}
						</div>
					</div>
				</div>
				<div className={styles.tags}>
					{instance && primary && (
						<div className={styles.primary}>
							<Tag status="primary" />
						</div>
					)}
					{instance && active && (
						<div className={styles.primary}>
							<Tag status="active" />
						</div>
					)}
				</div>
			</div>
			<div className="flex-container">
				{instance
					? <Tag status="enabled" />
					: <CPButton text="enable" action={() => action(id)} />}
				{instance && (
					<CPButton ghost icon="Edit" action={() => action(id)} />
				)}
			</div>
		</div>
	);
};
ProviderCard.defaultProps = {
	primary: false,
	instance: false,
	active: false,
};

export default ProviderCard;
