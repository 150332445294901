// @flow

import {
	WALLETS,
	CREDIT_WALLET,
	DEBIT_WALLET,
	GET_WALLET,
	GET_WALLET_TRANSACTIONS,
	CREATE_WALLET,
	EXPORT_WALLET_TRANSACTIONS,
	TRANSFER_TO_WALLET,
	EXECUTE_CONVERSION,
	GET_CONVERSIONS,
	GET_CONVERSION_QUOTE,
	EXPORT_CONVERSIONS,
} from '../const/types';

export function getWallets(accountId: string, page: number, pageSize: number = 50) {
	return (dispatch: Function): void => dispatch({
		type: WALLETS,
		payload: {
			request: {
				url: `/accounts/${accountId}/wallets`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function getWallet(walletId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_WALLET,
		payload: {
			request: {
				url: `/wallets/${walletId}`,
			},
		},
	});
}

export function getWalletTransactions(walletId: string, page: number) {
	return (dispatch: Function): void => dispatch({
		type: GET_WALLET_TRANSACTIONS,
		payload: {
			request: {
				url: `/wallets/${walletId}/transactions`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function exportWalletTransactions(walletId: string, params: string) {
	return (dispatch: Function): void => dispatch({
		type: EXPORT_WALLET_TRANSACTIONS,
		payload: {
			request: {
				url: `/wallets/${walletId}/export/wallet-transactions?${params}`,
			},
		},
	});
}

export function creditWallet(applicationId: string, walletId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: CREDIT_WALLET,
		payload: {
			request: {
				method: 'POST',
				url: `applications/${applicationId}/wallets/${walletId}/credit`,
				data,
			},
		},
	});
}

export function debitWallet(applicationId: string, walletId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: DEBIT_WALLET,
		payload: {
			request: {
				method: 'POST',
				url: `applications/${applicationId}/wallets/${walletId}/debit`,
				data,
			},
		},
	});
}

export function createWallet(accountId: string, data: { [string]: string }) {
	return (dispatch: Function): void => dispatch({
		type: CREATE_WALLET,
		payload: {
			request: {
				method: 'POST',
				url: `/accounts/${accountId}/wallets`,
				data,
			},
		},
	});
}

export function transferToWallet(walletId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: TRANSFER_TO_WALLET,
		payload: {
			request: {
				method: 'POST',
				url: `/wallets/${walletId}/transfer`,
				data,
			},
		},
	});
}

export function executeConversion(applicationId: string, data: {[string]: string}) {
	return (dispatch: Function): void => dispatch({
		type: EXECUTE_CONVERSION,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/conversions`,
				data,
			},
		},
	});
}

export function getConversions(applicationId: string, page: number, parametar: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_CONVERSIONS,
		payload: {
			request: {
				url: `/applications/${applicationId}/conversions?${parametar}`,
				headers: {
					'pagination-limit': 50,
					'pagination-offset': (page - 1) * 50,
				},
			},
		},
	});
}

export function getConversionQuote(data: {[string]: string}) {
	return (dispatch: Function): void => dispatch({
		type: GET_CONVERSION_QUOTE,
		payload: {
			request: {
				url: `/conversion/rate-quote?accountId=${data.accountId}&fromCurrency=${data.fromCurrency}&toCurrency=${data.toCurrency}&amount=${data.amount}&fixedSide=${data.fixedSide}`,
			},
		},
	});
}

export function exportConversions(applicationId: string, params: string) {
	return (dispatch: Function): void => dispatch({
		type: EXPORT_CONVERSIONS,
		payload: {
			request: {
				url: `applications/${applicationId}/export/conversions?${params}`,
			},
		},
	});
}
