// @flow

import React from 'react';
//= import components
import List from '../../../components/UiElements/List';
import StrategyComponent from './StrategyComponent';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import styles
import styles from '../assets/cards.module.scss';


type Props = {
	controlData: {
		name?: string,
		details?: Array<{
			key: string,
			value: string | number,
		}>,
		control?: Object,
	}
}

const ControlComponent = (props: Props) => {
	const {
		controlData,
	} = props;

	const authorizationData = controlData.control ? {
		name: controlData.control.name,
		data: [
			{
				name: 'Transaction Type Strategy',
				radioValues: StatusHelpers.transactionStrategy,
				radioValue: controlData.control.transactionTypeStrategy,
			}, {
				name: controlData.control.transactionTypes.length ? 'Transaction Type Ids' : '',
				checkboxValues: controlData.control.transactionTypes,
			}, {
				name: 'Entry Mode Strategy',
				radioValues: StatusHelpers.entryModeStrategy,
				radioValue: controlData.control.entryModeStrategy,
			}, {
				name: controlData.control.entryModes.length ? 'Entry Mode Ids' : '',
				checkboxValues: controlData.control.entryModes,
			},
		],
	} : {};
	const merchantData = controlData.control?.merchantScope ? {
		name: controlData.control.merchantScope?.name,
		data: controlData.control.merchantScope
			? [{
				name: 'Merchant Category Strategy',
				radioValues: StatusHelpers.merchantCategoryStrategy,
				radioValue: controlData.control.merchantScope.merchantCategoryStrategy,
			}, {
				name: controlData.control.merchantScope.merchantCategoryCodes.length ? 'Merchant Category Codes' : '',
				idValues: controlData.control.merchantScope.merchantCategoryCodes,
			}, {
				name: 'Merchant Strategy',
				radioValues: StatusHelpers.merchantStrategy,
				radioValue: controlData.control.merchantScope.merchantStrategy,
			}, {
				name: controlData.control.merchantScope.merchantIds.length ? 'Merchant Ids' : '',
				idValues: controlData.control.merchantScope.merchantIds,
			}]
			: [],
	} : {};

	return (
		<div className={styles.limit}>
			<h2>{controlData.name}</h2>
			<h4>{authorizationData.name}</h4>
			{controlData.details && (
				<div className={styles.list}>
					<List
						itemLayout="horizontal"
						dataSource={controlData.details}
						split={false}
						renderItem={
							(item) => (
								<List.Item key={item.key}>
									<List.Item.Meta
										title={item.key}
									/>
									<div className={styles.listValue}>{item.value}</div>
								</List.Item>
							)
						}
					/>
				</div>
			)}
			<div>
				{authorizationData.data?.length ? authorizationData.data.map((el) => (
					<StrategyComponent
						key={el.name}
						name={el.name}
						radioValues={el.radioValues || []}
						radioValue={el.radioValue || ''}
						checkboxValues={el.checkboxValues || []}
					/>
				)) : ''}
			</div>
			<div>
				<h4>{merchantData.name}</h4>
				{merchantData.data?.length ? merchantData.data.map((el) => (
					<StrategyComponent
						key={el.name}
						name={el.name}
						radioValues={el.radioValues || []}
						radioValue={el.radioValue || ''}
						checkboxValues={el.checkboxValues || []}
						idValues={el.idValues || []}
					/>
				)) : ''}
			</div>
		</div>
	);
};

export default ControlComponent;
