// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import SymbolsTable from '../components/SymbolsTable';
import Spin from '../../../components/UiElements/Spin';
import Title from '../../../components/UiElements/Title';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import EditSymbolForm from '../components/EditSymbolForm';
//= import methods
import { getAppSymbols, updateSymbol } from '../../../modules/actions/SymbolActions';
import { getProvidersInstances, getProviders } from '../../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
// import statusHelpers from
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { ProviderInstance, Provider } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';
import StatusHelpers from '../../../lib/helpers/statusHelpers';

type Props = {
	symbols: Array<Symbol>,
	isFetchingSymbols: boolean,
	getAppSymbols: (string, string, number, string) => void,
	updateSymbol: (string, string, Object) => Promise<Object>,
	appSymbolsSearch: string,
	getProvidersInstances: (string) => void,
	getProviders: (string) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	providersInstances: Array<ProviderInstance>,
	providers: Array<Provider>,
	currentPage: number,
	symbolsTotal: number,
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}

const Symbols = (props: Props) => {
	const {
		match: { params: { applicationId } },
		symbols,
		isFetchingSymbols,
		symbolsTotal,
		appSymbolsSearch,
		getAppSymbols: getAppSymbolsAction,
		updateSymbol: updateSymbolAction,
		getProvidersInstances: getProvidersInstancesAction,
		getProviders: getProvidersAction,
		setCurrentPage: setCurrentPageAction,
		providersInstances,
		providers,
		currentPage,
	} = props;

	const [edit, setEdit] = useState(false);
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [selectedSymbol, setSelectedSymbol] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getProvidersAction(applicationId);
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, getProvidersAction, applicationId]);

	useEffect(() => {
		const filters = { search: appSymbolsSearch };
		getAppSymbolsAction(applicationId, 'numered-page', (currentPage - 1) * StatusHelpers.paginationLimit, queryString.stringify(filters, { arrayFormat: 'bracket' }));
	}, [getAppSymbolsAction, applicationId, appSymbolsSearch, currentPage]);

	const handleEditClick = (data) => {
		setSelectedSymbol(data);
		setEdit(true);
	};

	const handlePageChange = (page: number) => {
		setCurrentPageAction('appSymbolsPage', page);
	};

	const formRef = useRef(null);

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setEdit(false);
		setWarning(false);
	};

	const handleWarningCancel = () => {
		setEdit(true);
		setWarning(false);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setEdit(false);
			setWarning(false);
		}
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		if (typeof error === 'string') {
			return error;
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setEdit(false);
		setWarning(true);
		setWarningTitle('Error!');
		setMessage({
			firstPart: error.error.response.data.message,
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitChanges = (value) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		updateSymbolAction(applicationId, selectedSymbol.id, value)
			.then(() => {
				setWarning(false);
				setButtonLoading(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueChanges = (value: { [string]: string }) => {
		setEdit(false);
		setWarning(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes, they will affect this ${selectedSymbol.name} Symbol and all its Group Symbols.`,
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitChanges(value), text: 'save changes' },
		]);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const availableProviders = !!providersInstances.length && providersInstances.filter((el) => (
		el.provider.type === 'market' && el.provider.supports.includes('trade')));

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Symbol list"
				searchComponent={{
					placeholder: 'Search For Symbols...',
					disabled: false,
				}}
				place="appSymbols"
			/>
			<div className="container">
				{!isFetchingSymbols
					? (
						<SymbolsTable
							symbols={symbols}
							applicationId={applicationId}
							handleEditClick={handleEditClick}
							place="appSymbols"
							providersInstances={providersInstances}
							providers={providers}
							headerHeight={48}
							handlePageChange={handlePageChange}
							currentPage={currentPage}
							symbolsTotal={symbolsTotal}
						/>
					) : <Spin spinning={isFetchingSymbols} />}
				<FormModal
					title={`Edit ${selectedSymbol.displayName} ${selectedSymbol.id}`}
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<EditSymbolForm
							submitChanges={continueChanges}
							handleCancelClick={handleEditCancel}
							symbol={selectedSymbol}
							wrappedComponentRef={formRef}
							parentSymbol={{}}
							availableProviders={availableProviders}
							key={selectedSymbol.id}
						/>
					)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warning}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	symbols: state.symbol.symbols,
	isFetchingSymbols: state.symbol.isFetchingSymbols,
	symbolsTotal: state.symbol.symbolsTotal,
	appSymbolsSearch: state.ui.appSymbolsSearch,
	providersInstances: state.providers.providersInstances,
	providers: state.providers.providers,
	currentPage: state.ui.appSymbolsPage,
});

const mapDispatchToProps = {
	getAppSymbols,
	updateSymbol,
	getProvidersInstances,
	getProviders,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Symbols);
