// @flow
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Input from '../../../components/UiElements/Input';
import Table from '../../../components/UiElements/Table';
import Select from '../../../components/UiElements/Select';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import {
	getAppSymbols, getGroupSymbols,
} from '../../../modules/actions/SymbolActions';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
//= import styles

type SymbolsResponse = {
	payload: {
		data: Array<Symbol>,
	}
}

type Props = {
	form: *,
	getAppSymbols: (string, string, string, string) => Promise<SymbolsResponse>,
	getGroupSymbols: (string, string, string, string) => Promise<SymbolsResponse>,
	submitChanges: (Symbol, {string: boolean}) => void,
	handleCancelClick: () => void,
	groupId: string,
	edit: boolean,
	symbolToEdit: Symbol,
	applicationId: string,
}

type LocalState = {
	selectedKey: Array<string>,
	symbols: Array<Object>,
	selectedSymbol: Symbol | Object,
	errorMessage: string,
	loading: boolean,
	infiniteScrollLoading: boolean,
	search: string,
}

class SettlementForm extends React.Component<Props, LocalState> {
	state = {
		selectedKey: [],
		symbols: [],
		selectedSymbol: {},
		errorMessage: '',
		loading: false,
		infiniteScrollLoading: false,
		search: '',
	}

	loadingRef = React.createRef();

	componentDidMount() {
		const {
			applicationId,
			groupId,
			symbolToEdit,
			getAppSymbols: getAppSymbolsAction,
			getGroupSymbols: getGroupSymbolsAction,
		} = this.props;
		const { search } = this.state;

		if (Object.keys(symbolToEdit).length) {
			this.setState({
				selectedSymbol: symbolToEdit,
			});
		} else {
			this.setState({
				loading: true,
			});
			const date = new Date();
			const param = queryString.stringify({ search });
			if (groupId) {
				if (groupId === 'applicationSymbols') {
					getAppSymbolsAction(applicationId, 'infinite-scroll', date.toISOString(), param)
						.then((appSymbolsRes: SymbolsResponse) => {
							const appSymbols = appSymbolsRes.payload.data;
							this.setState({
								symbols: appSymbols,
								loading: false,
							});
						});
				} else {
					getGroupSymbolsAction(groupId, 'infinite-scroll', date.toISOString(), param)
						.then((groupSymbolsRes) => {
							const groupSymbols = groupSymbolsRes.payload.data;
							this.setState({
								symbols: groupSymbols,
								loading: false,
							});
						});
				}
			} else {
				this.setState({
					symbols: [],
					loading: false,
				});
			}
			const tableContent = document.querySelector('.ant-table-tbody');
			tableContent.addEventListener('scroll', this.handleEvent);
		}
	}

	handleEvent = (e) => {
		const {
			applicationId,
			groupId,
			getAppSymbols: getAppSymbolsAction,
			getGroupSymbols: getGroupSymbolsAction,
		} = this.props;
		const { symbols, search } = this.state;
		const maxScroll = e.target.scrollHeight - e.target.clientHeight;
		const currentScroll = e.target.scrollTop;
		if (currentScroll === maxScroll) {
			this.setState({
				infiniteScrollLoading: true,
			});
			const paginationOffset = symbols[symbols.length - 1].createdAt;
			const param = queryString.stringify({ search });
			if (groupId === 'applicationSymbols') {
				getAppSymbolsAction(applicationId, 'infinite-scroll', paginationOffset, param)
					.then((res) => this.setState((prevState) => ({
						symbols: [...prevState.symbols, ...res.payload.data],
						infiniteScrollLoading: false,
					})));
			} else {
				getGroupSymbolsAction(groupId, 'infinite-scroll', paginationOffset, param)
					.then((res) => this.setState((prevState) => ({
						symbols: [...prevState.symbols, ...res.payload.data],
						infiniteScrollLoading: false,
					})));
			}
		}
	}

	onSymbolSelect = (key: Array<string>) => {
		const selectedSymbol = this.state.symbols.find((symbol: Symbol) => symbol.id === key[0]);
		this.setState(() => ({
			selectedKey: key,
			selectedSymbol,
		}));
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form } = this.props;
		const { selectedSymbol } = this.state;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(selectedSymbol, values);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			selectedKey: [],
			symbols: [],
			errorMessage: '',
		});
	}

	handleSearch = (value) => {
		const {
			applicationId,
			groupId,
			getAppSymbols: getAppSymbolsAction,
			getGroupSymbols: getGroupSymbolsAction,
		} = this.props;
		this.setState({
			search: value.toLowerCase(),
			loading: true,
		});
		const date = new Date();
		const param = queryString.stringify({ search: value });
		if (groupId) {
			if (groupId === 'applicationSymbols') {
				getAppSymbolsAction(applicationId, 'infinite-scroll', date.toISOString(), param)
					.then((res) => this.setState({
						symbols: res.payload.data,
						loading: false,
					}));
			} else {
				getGroupSymbolsAction(groupId, 'infinite-scroll', date.toISOString(), param)
					.then((res) => this.setState({
						symbols: res.payload.data,
						loading: false,
					}));
			}
		} else {
			this.setState({
				symbols: [],
				loading: false,
			});
		}
	}

	render() {
		const {
			handleCancelClick,
			edit,
			groupId,
			form: {
				getFieldDecorator, isFieldsTouched,
			},
		} = this.props;
		const {
			selectedKey,
			selectedSymbol,
			errorMessage,
			loading,
			symbols,
			search,
			infiniteScrollLoading,
		} = this.state;

		const columns = [
			{
				title: 'id',
				dataIndex: 'id',
				key: 'id',
				width: 112,
			},
			{
				title: 'name',
				dataIndex: 'displayName',
				key: 'name',
				width: 160,
				render: (text: string) => (
					<span className="wrap" style={{ maxWidth: 176 }}>
						{text}
					</span>
				),
			},
			{
				title: 'symbol type',
				dataIndex: 'group',
				key: 'type',
				width: 144,
				render: () => (
					<span className="wrap" style={{ maxWidth: 144 }}>
						{groupId === 'applicationSymbols' ? 'Application Symbol' : 'Group Symbol'}
					</span>
				),
			},
		];
		const rowSelection = {
			onChange: this.onSymbolSelect,
			type: 'radio',
			selectedRowKeys: selectedKey,
		};
		const FormItem = Form.Item;
		const { Option } = Select;
		const fixedLayout = {
			labelCol: {
				xs: { span: 15 },
			},
			wrapperCol: {
				xs: { span: 9 },
			},
		};

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{!edit
					&& (
						<div>
							<Row gutter={24}>
								<Col span={14}>
									<Input.Search
										key="search"
										className="search"
										placeholder="Search For Symbols..."
										onSearch={this.handleSearch}
										defaultValue={search}
										onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
									/>
								</Col>
							</Row>
							<div ref={this.loadingRef} onScroll={this.handleEvent} className="scroll-div">
								{!loading
									? (
										<Table
											loading={infiniteScrollLoading}
											rowSelection={rowSelection}
											rowKey={(record) => record.id}
											columns={columns}
											dataSource={symbols}
											pagination={false}
											scroll={{ y: (window.innerHeight - 624) }}
											rowClassName="editable-row"
										/>
									)
									: <Spin spinning={loading} />}
							</div>
						</div>
					)}
				<p>
					Application Symbol can have values True or False only.
					Group Symbol can have values True, False or Null.
					If Null is set on the Group Symbol,
					it will inherit the value from the related Application Symbol.
				</p>
				<Row>
					<Col span={24} className="field-left">
						<FormItem label="Post-Trade Settlement" {...fixedLayout} className="form-item-small">
							{getFieldDecorator(
								'isPostTradeSettlementAllowed', {
									initialValue: selectedSymbol.isPostTradeSettlementAllowed,
									rules: [
										{
											required: true,
											message: 'Please select value!',
										},
									],
								},
							)(
								<Select
									allowClear
									className="select"
									placeholder="Post-Trade Settlement"
									dropdownClassName="options"
								>
									<Option value>
										True
									</Option>
									<Option value={false}>
										False
									</Option>
								</Select>,
							)}
						</FormItem>
					</Col>
				</Row>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="add post-trade settlement" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EnableSettlementForm = Form.create()(SettlementForm);

const mapDispatchToProps = {
	getGroupSymbols,
	getAppSymbols,
};
export default connect(null, mapDispatchToProps)(EnableSettlementForm);
