import groupBy from 'lodash/groupBy';

const initialState = {
	error: false,
	isFetching: false,
	isFetched: false,
	ledger: {},
	mappedLedger: {},
};

export default function ledgerReducer(state = initialState, action) {
	switch (action.type) {
	case 'LEDGER_SUCCESS': {
		return {
			...state,
			error: false,
			isFetching: false,
			isFetched: true,
			ledger: action.payload.data,
			mappedLedger: groupBy(action.payload.data, 'walletId'),
		};
	}
	case 'LEDGER': {
		return {
			...state,
			isFetching: true,
			error: false,
		};
	}
	case 'LEDGER_FAIL': {
		return {
			...state,
			error: action.error,
			isFetching: false,
			isFetched: false,
		};
	}
	default:
		return state;
	}
}
