// @flow

import {
	GET_EVENT_SUBSCRIPTIONS,
	ADD_EVENT_SUBSCRIPTIONS,
	GET_EVENT_SUBSCRIPTION,
	REMOVE_EVENT_SUBSCRIPTIONS,
	ADD_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS,
	GET_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS,
	REMOVE_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS,
	ADD_EVENT_SUBSCRIPTIONS_EVENTS,
	GET_EVENT_SUBSCRIPTIONS_EVENTS,
	REMOVE_EVENT_SUBSCRIPTIONS_EVENTS,
	GET_EVENT_HANDLERS,
} from '../const/types';

export function getEventSubscriptions() {
	return (dispatch: Function): void => dispatch({
		type: GET_EVENT_SUBSCRIPTIONS,
		payload: {
			request: {
				url: '/event-subscriptions',
			},
		},
	});
}

export function addEventSubscriptions(data: Object) {
	return (dispatch: Function): void => dispatch({
		type: ADD_EVENT_SUBSCRIPTIONS,
		payload: {
			request: {
				method: 'POST',
				url: '/event-subscriptions',
				data,
			},
		},
	});
}

export function getEventSubscription(id: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_EVENT_SUBSCRIPTION,
		payload: {
			request: {
				url: `/event-subscriptions/${id}`,
			},
		},
	});
}


export function removeEventSubscription(eventSubscriptionId: string) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_EVENT_SUBSCRIPTIONS,
		payload: {
			request: {
				method: 'DELETE',
				url: `/event-subscriptions/${eventSubscriptionId}`,
			},
		},
	});
}

export function getEventSubscriptionsEventHandlers(
	applicationId: string,
	eventSubscriptionId: string,
) {
	return (dispatch: Function): void => dispatch({
		type: GET_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS,
		payload: {
			request: {
				url: `/applications/${applicationId}/event-subscriptions/${eventSubscriptionId}/handlers`,
			},
		},
	});
}

export function addEventSubscriptionsEventHandlers(
	applicationId: string,
	eventSubscriptionId: string,
	data: Object,
) {
	return (dispatch: Function): void => dispatch({
		type: ADD_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/event-subscriptions/${eventSubscriptionId}/handlers`,
				data,
			},
		},
	});
}

export function removeEventSubscriptionsEventHandlers(
	applicationId: string,
	eventSubscriptionId: string,
	handlerId: string,
) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_EVENT_SUBSCRIPTIONS_EVENT_HANDLERS,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/event-subscriptions/${eventSubscriptionId}/handlers/${handlerId}`,
			},
		},
	});
}

export function addEventSubscriptionsEvents(
	applicationId: string,
	eventSubscriptionId: string,
	data: Object,
) {
	return (dispatch: Function): void => dispatch({
		type: ADD_EVENT_SUBSCRIPTIONS_EVENTS,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/event-subscriptions/${eventSubscriptionId}/events`,
				data,
			},
		},
	});
}

export function getEventSubscriptionsEvents(applicationId: string, eventSubscriptionId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_EVENT_SUBSCRIPTIONS_EVENTS,
		payload: {
			request: {
				url: `/applications/${applicationId}/event-subscriptions/${eventSubscriptionId}/events`,
			},
		},
	});
}

export function removeEventSubscriptionsEvents(
	applicationId: string,
	eventSubscriptionId: string,
	eventId: string,
) {
	return (dispatch: Function): void => dispatch({
		type: REMOVE_EVENT_SUBSCRIPTIONS_EVENTS,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/event-subscriptions/${eventSubscriptionId}/events/${eventId}`,
			},
		},
	});
}

export function getEventHandlers() {
	return (dispatch: Function): void => dispatch({
		type: GET_EVENT_HANDLERS,
		payload: {
			request: {
				url: '/event-handlers',
			},
		},
	});
}
