// @flow

import React from 'react';
//= import components
import Form from '../../../components/UiElements/Form';
import Input from '../../../components/UiElements/Input';
import InputNumber from '../../../components/UiElements/InputNumber';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Popover from '../../../components/UiElements/Popover';
import Switch from '../../../components/UiElements/Switch';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';

type Props = {
	form: *,
	submitChanges: Function,
	handleCancelClick: Function,
	instrument: Instrument,
}

type State = {
	errorMessage: string,
	default: boolean,
	isTouched: boolean,
}

class InstrumentForm extends React.Component<Props, State> {
	state = {
		errorMessage: '',
		default: false,
		isTouched: false,
	}

	componentDidMount() {
		this.setState({
			default: this.props.instrument.default,
		});
	}

	handleDepositChange = (value) => {
		this.setState({
			isTouched: true,
		},
		() => this.props.form.setFieldsValue({
			depositPaymentMethods: value.includes('Not Depositable') ? ['Not Depositable'] : value,
		}));
	}

	handleWithdrawalChange = (value) => {
		this.setState({
			isTouched: true,
		},
		() => this.props.form.setFieldsValue({
			withdrawalPaymentMethods: value.includes('Not Withdrawable') ? ['Not Withdrawable'] : value,
		}));
	}

	handleDefaultChange = (value) => {
		this.setState({
			default: value,
			isTouched: true,
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			errorMessage: '',
			isTouched: false,
			default: this.props.instrument.default,
		});
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		form.validateFields((err, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				submitChanges(values);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldsTouched }, instrument, handleCancelClick,
		} = this.props;
		const { errorMessage, isTouched } = this.state;
		const { Option } = Select;
		const { TextArea } = Input;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 9 },
			},
			labelAlign: 'left',
		};
		const descriptionLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 14 },
			},
			labelAlign: 'left',
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<Form.Item label="DEFAULT" {...formItemLayout}>
					<div>
						{(instrument.type !== 'fiat' || instrument.default)
							&& (
								<Popover
									content={instrument.default
										? 'Set another fiat instrument as default in order to switch this one off.'
										: 'Only fiat can be default'}
								>
									<div className="tooltip-visible" />
								</Popover>
							)}
						{getFieldDecorator('default', { initialValue: this.state.default })(
							<Switch
								checked={this.state.default}
								disabled={instrument.type !== 'fiat' || instrument.default}
								style={{ pointerEvents: 'auto', display: 'inline-block' }}
								onChange={this.handleDefaultChange}
								checkedChildren="Yes"
								unCheckedChildren="No"
							/>,
						)}
					</div>
				</Form.Item>
				<Form.Item label="DEPOSIT METHODS" {...formItemLayout} help="">
					{getFieldDecorator('depositPaymentMethods', {
						initialValue: instrument.depositPaymentMethods.length
							? instrument.depositPaymentMethods
							: ['Not Depositable'],
						rules: [{ required: true, message: "Deposit payment methods can't be left empty" }],
					})(
						<Select mode="multiple" allowClear onChange={this.handleDepositChange}>
							<Option value="card" disabled={instrument.type !== 'fiat'}>Credit Card</Option>
							<Option value="wire" disabled={instrument.type !== 'fiat'}>Wire Transfer</Option>
							<Option value="manual">Manual</Option>
							<Option value="Not Depositable">Not Depositable</Option>
						</Select>,
					)}
				</Form.Item>
				<Form.Item label="WITHDRAWAL METHODS" {...formItemLayout} help="">
					{getFieldDecorator('withdrawalPaymentMethods', {
						initialValue: instrument.withdrawalPaymentMethods.length
							? instrument.withdrawalPaymentMethods
							: ['Not Withdrawable'],
						rules: [{ required: true, message: "Withdrawal payment methods can't be left empty" }],
					})(
						<Select mode="multiple" allowClear onChange={this.handleWithdrawalChange}>
							<Option value="card" disabled={instrument.type !== 'fiat'}>Credit Card</Option>
							<Option value="wire" disabled={instrument.type !== 'fiat'}>Wire Transfer</Option>
							<Option value="manual">Manual</Option>
							<Option value="Not Withdrawable">Not Withdrawable</Option>
						</Select>,
					)}
				</Form.Item>
				<Form.Item label="RANK" {...formItemLayout}>
					{getFieldDecorator('rank', { initialValue: instrument.rank })(
						<InputNumber
							min={1}
							placeholder="Set Instrument Rank"
							type="number"
						/>,
					)}
				</Form.Item>
				<Form.Item label="KEYWORDS" {...formItemLayout}>
					{getFieldDecorator('keywards', { initialValue: instrument.keywords.join(', ') })(
						<TextArea readOnly autoSize />,
					)}
				</Form.Item>
				<Form.Item label="DESCRIPTION" {...descriptionLayout}>
					{getFieldDecorator('description', { initialValue: instrument.description })(
						<TextArea readOnly autoSize />,
					)}
				</Form.Item>
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched() && !isTouched} text="save changes" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EditInstrumentForm = Form.create()(InstrumentForm);

export default EditInstrumentForm;
