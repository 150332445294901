// @flow

const initialState = {
	fees: [],
	isFetchingFees: false,
	isFetchedFees: false,
	error: false,
	addingFee: false,
	feeAdded: false,
	removingFee: false,
	removedFee: false,
	updatingFee: false,
	updatedFee: false,
	feeQuote: {},
	isFetchingFeeQuote: false,
	isFetchedFeeQuote: false,
};

export type Fee = {
	id: string,
    priority: 1,
    type: string,
    instrumentId: string,
    percentageFee: string,
    netFee: string,
    minimumFee: string,
    maximumFee: string,
    applicationId: string,
    updatedAt: string,
    createdAt: string,
    orderType: null,
    orderSide: null,
    providerInstanceId: null,
    symbolId: null,
	deletedAt: null,
	userId: string,
}
export type Fees = Array<Fee>
export type FeeQuote = {
	id: string,
  priority: number,
  type: string,
  instrumentId: string,
  percentageFee: string,
  netFee: string,
  minimumFee: string,
  maximumFee: string,
  applicationId: string,
  createdAt: string,
  updatedAt: string,
  providerId: string,
  feeAmount: string,
}
export type FeeState = {
	fees: Fees,
	isFetchingFees: boolean,
	isFetchedFees: boolean,
	error: Object | boolean,
	addingFee: boolean,
	feeAdded: boolean,
	removingFee: boolean,
	removedFee: boolean,
	updatingFee: boolean,
	updatedFee: boolean,
	feeQuote: Fee,
	isFetchingFeeQuote: boolean,
	isFetchedFeeQuote: boolean,
}
type FeeAction = {
	type:
	| 'UPDATE_FEE'
	| 'UPDATE_FEE_SUCCESS'
	| 'UPDATE_FEE_FAIL'
	| 'ADD_FEE'
	| 'ADD_FEE_SUCCESS'
	| 'ADD_FEE_FAIL'
	| 'GET_FEE_QUOTE_SUCCESS',
	payload: {
		data: Fee,
	},
	error: {
        code: number,
        message: string,
    }
}
type FeesAction = {
	type:
	| 'GET_FEES'
	| 'GET_FEES_SUCCESS'
	| 'GET_FEES_FAIL',
	payload: {
		data: Array<Fee>,
		headers: {
			'total-count': number,
		}
	},
	error: {
        code: number,
        message: string,
    }
}
type Actions = FeeAction | FeesAction;

export default function feeReducer(
	state: FeeState = initialState,
	action: Actions,
): FeeState {
	switch (action.type) {
	case 'GET_FEES': {
		return {
			...state,
			isFetchingFees: true,
			isFetchedFees: false,
			fees: [],
		};
	}
	case 'GET_FEES_FAIL': {
		return {
			...state,
			error: false,
			isFetchingFees: false,
			isFetchedFees: false,
		};
	}
	case 'GET_FEES_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingFees: false,
			isFetchedFees: true,
			fees: action.payload.data,
		};
	}
	case 'UPDATE_FEE': {
		return {
			...state,
			updatingFee: true,
			updatedFee: false,
		};
	}
	case 'UPDATE_FEE_FAIL': {
		return {
			...state,
			error: action.error,
			updatingFee: false,
			updatedFee: false,
		};
	}
	case 'UPDATE_FEE_SUCCESS': {
		return {
			...state,
			error: false,
			updatingFee: false,
			updatedFee: true,
			fees: state.fees.map((fee) => {
				if (fee.id === action.payload.data.id) {
					return action.payload.data;
				}
				return fee;
			}),
		};
	}
	case 'ADD_FEE': {
		return {
			...state,
			addingFee: true,
			feeAdded: false,
		};
	}
	case 'ADD_FEE_FAIL': {
		return {
			...state,
			error: action.error,
			addingFee: false,
			feeAdded: false,
		};
	}
	case 'ADD_FEE_SUCCESS': {
		return {
			...state,
			error: false,
			addingFee: false,
			feeAdded: true,
			fees: [...state.fees, action.payload.data],
		};
	}
	case 'GET_FEE_QUOTE_SUCCESS': {
		return {
			...state,
			error: false,
			feeQuote: action.payload.data,
		};
	}
	default:
		return state;
	}
}
