// @flow

import Api from '../../lib/api';

import {
	LOGIN_SUCCESS,
	STORE_USER,
	INITIALIZED,
	LOGOUT,
	TURN_ON_2FA,
	TURN_OFF_2FA,
	CHECK_2FA,
	CONFIRM_2FA,
} from '../const/types';

export function initialized() {
	return (dispatch: Function): void => dispatch({
		type: INITIALIZED,
		data: true,
	});
}

//= store user
export function storeUser() {
	return (dispatch: Function): void => dispatch({
		type: STORE_USER,
	});
}

//= login success
export function loginSuccess(response: string) {
	return (dispatch: Function): void => dispatch({
		response,
		type: LOGIN_SUCCESS,
	});
}

//= ====================================================
//= FETCH USER DATA
//= ====================================================
function fetchUserData() {
	return (dispatch: Function): void => dispatch({
		type: 'FETCH_USER',
		payload: {
			request: {
				url: 'users/me',
			},
		},
	})
		.then((response) => {
			Api.storeUserData(response);
			dispatch(storeUser());
			return Promise.all([
				dispatch(loginSuccess(response)),
			])
				.then(() => {
					dispatch(initialized());
					return response;
				});
		});
}
//= ====================================================
//= LOGIN
//= ====================================================
export function login(username: string, password: string, appName: string) {
	return (dispatch: Function): void => dispatch({
		type: 'LOGIN',
		payload: {
			client: 'oauth',
			request: {
				headers: {
					'X-Tradecore-App-Name': appName,
				},
				method: 'POST',
				data: {
					username,
					password,
				},
				url: '/auth/token/customer',
			},
		},
	})
		.then((response) => {
			Api.storeOAuth(response.payload.data, appName);
			return response;
		})
		.then((response) => {
			dispatch(fetchUserData());
			return response;
		})
		.catch((error) => Promise.reject(error));
}
//= ====================================================
//= LOGIN
//= ====================================================
export function loginWith2FA(username: string, password: string, appName: string, token: number) {
	return (dispatch: Function): void => dispatch({
		type: 'LOGIN_WITH_2FA',
		payload: {
			client: 'oauth',
			request: {
				headers: {
					'X-Tradecore-App-Name': appName,
				},
				method: 'POST',
				data: {
					username,
					password,
					token,
				},
				url: '/auth/token/customer',
			},
		},
	})
		.then((response) => {
			Api.storeOAuth(response.payload.data, appName);
			return response;
		})
		.then((response) => {
			dispatch(fetchUserData());
			return response;
		})
		.catch((error) => Promise.reject(error));
}
//= ====================================================
//= CHECK AUTH
//= ====================================================
export function checkOauth() {
	return (dispatch: Function) => {
		//= get token from local storage
		const token = localStorage.getItem('@Auth:auth');
		//= if token doesn't exists
		if (!token) {
			return dispatch(initialized());
		}
		//= set token in Api.oauth
		Api.oauth = JSON.parse(token);
		return dispatch({
			type: 'FETCH_USER',
			payload: {
				request: {
					url: '/users/me',
				},
			},
		}).then(() => {
			dispatch(loginSuccess(token));
			dispatch(fetchUserData());
		}).catch(() => {
			dispatch(initialized());
		});
	};
}
//= ====================================================
//= LOGOUT
//= ====================================================
export function logout() {
	return (dispatch: Function): void => {
		localStorage.removeItem('@Auth:appName');
		localStorage.removeItem('@Auth:auth');
		return dispatch({
			type: LOGOUT,
		});
	};
}

//= ====================================================
//= CHANGE PASSWORD
//= ====================================================
export function changePassword(passwordOld: string, newPassword: string) {
	return (dispatch: Function): void => dispatch({
		type: 'CHANGE_PASSWORD',
		payload: {
			request: {
				method: 'PATCH',
				data: {
					passwordOld,
					password: newPassword,
					passwordRepeat: newPassword,
				},
				url: `/users/${Api.user.payload.data.id}/change-password`,
			},
		},
	}).catch((error) => Promise.reject(error));
}

export function turnOn2FA() {
	return (dispatch: Function): void => dispatch({
		type: TURN_ON_2FA,
		payload: {
			request: {
				method: 'POST',
				url: `/totp/on`,
			},
		},
	})
}

export function turnOff2FA(token: string) {
	return (dispatch: Function): void => dispatch({
		type: TURN_OFF_2FA,
		payload: {
			request: {
				method: 'POST',
				url: `/totp/off`,
				data: {
					token,
				},
			},
		},
	})
}

export function check2FA() {
	return (dispatch: Function): void => dispatch({
		type: CHECK_2FA,
		payload: {
			request: {
				method: 'GET',
				url: `/totp/status`,
			},
		},
	})
}

export function confirm2FA(token: string) {
	return (dispatch: Function): void => dispatch({
		type: CONFIRM_2FA,
		payload: {
			request: {
				method: 'POST',
				url: `/totp/confirm`,
				data: {
					token,
				}
			},
		},
	})
}