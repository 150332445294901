// @flow

import {
	GET_STOCK_SPLITS,
	GET_STOCK_SPLIT,
	GET_ACCOUNT_STOCK_SPLITS,
	GET_ACCOUNT_STOCK_SPLIT,
	ADD_STOCK_SPLIT,
	UPDATE_STOCK_SPLIT,
	DELETE_STOCK_SPLIT,
	EXECUTE_STOCK_SPLIT,
} from '../const/types';

export function getStockSplits(appId: string, page: number, pagLimit: number, params: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_STOCK_SPLITS,
		payload: {
			request: {
				url: `/applications/${appId}/stock-splits?${params}`,
				headers: {
					'pagination-limit': pagLimit,
					'pagination-offset': (page - 1) * pagLimit,
				},
			},
		},
	});
}

export function getStockSplit(applicationId: string, id: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_STOCK_SPLIT,
		payload: {
			request: {
				url: `/applications/${applicationId}/stock-splits/${id}`,
			},
		},
	});
}

export function getAccountStockSplits(
	accountId: string,
	page: number,
	pagLimit: number,
	params: string,
) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_STOCK_SPLITS,
		payload: {
			request: {
				url: `/accounts/${accountId}/stock-splits?${params}`,
				headers: {
					'pagination-limit': pagLimit,
					'pagination-offset': (page - 1) * pagLimit,
				},
			},
		},
	});
}

export function getAccountStockSplit(accountId: string, stockSplitId: string) {
	return (dispatch: Function): void => dispatch({
		type: GET_ACCOUNT_STOCK_SPLIT,
		payload: {
			request: {
				url: `/accounts/${accountId}/stock-splits/${stockSplitId}`,
			},
		},
	});
}

export function addStockSplit(applicationId: string, data: string) {
	return (dispatch: Function): void => dispatch({
		type: ADD_STOCK_SPLIT,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/stock-splits`,
				data,
			},
		},
	});
}

export function updateStockSplit(applicationId: string, id: string, data: string) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_STOCK_SPLIT,
		payload: {
			request: {
				method: 'PUT',
				url: `/applications/${applicationId}/stock-splits/${id}`,
				data,
			},
		},
	});
}

export function deleteStockSplit(applicationId: string, id: string) {
	return (dispatch: Function): void => dispatch({
		type: DELETE_STOCK_SPLIT,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/stock-splits/${id}`,
			},
		},
	});
}

export function executeStockSplit(applicationId: string, id: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: EXECUTE_STOCK_SPLIT,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/stock-splits/${id}/execute`,
				data,
			},
		},
	});
}
