// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import methods
import { getAccountPositions } from '../../../modules/actions/AccountsActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Position } from '../../../modules/reducers/AccountsReducer';

type Props = {
	positions: Array<Position>,
	isFetchingPositions: boolean,
	accountId: string,
	applicationId: string,
	getAccountPositions: (string) => void,
	positionsTotal: number,
	pageSize: number,
}

const PositionsTab = (props: Props) => {
	const {
		accountId,
		applicationId,
		positions,
		isFetchingPositions,
		getAccountPositions: getAccountPositionsAction,
		positionsTotal,
		pageSize,
	} = props;

	const [page, setPage] = useState(1);

	useEffect(() => {
		getAccountPositionsAction(accountId);
	}, [accountId, getAccountPositionsAction]);

	const handlePositionsPageChange = async(page: number, size: number) => {
		await getAccountPositionsAction(accountId, page, size);
		setPage(page);
	}

	const columns = [
		{
			title: 'symbol',
			dataIndex: 'symbolId',
			key: 'symbol',
			width: 144,
		},
		{
			title: 'side',
			dataIndex: 'side',
			key: 'side',
			width: 144,
		},
		{
			title: 'quantity',
			dataIndex: 'positionQuantity',
			key: 'quantity',
			align: 'right',
			width: 144,
		},
		{
			title: 'average price',
			dataIndex: 'averagePrice',
			key: 'averagePrice',
			align: 'right',
			width: 144,
			render: (text, record) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrument?.id, Numbers.sign(text || '0'))}
				</div>
			),
		},
		{
			title: 'cost basis',
			dataIndex: 'costBasis',
			key: 'costBasis',
			align: 'right',
			width: 144,
			render: (text, record) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrument?.id, Numbers.sign(text || '0'))}
				</div>
			),
		},
		{
			title: 'market value',
			dataIndex: 'marketValue',
			key: 'marketValue',
			align: 'right',
			width: 144,
			render: (text, record) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrument?.id, Numbers.sign(text || '0'))}
				</div>
			),
		},
		{
			title: 'unrealized gain',
			dataIndex: 'unrealizedPnL',
			key: 'unrealizedPnL',
			align: 'right',
			width: 160,
			render: (text, record) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrument?.id, Numbers.sign(text || '0'))}
				</div>
			),
		},
		{
			title: 'realized gain',
			dataIndex: 'realizedPnL',
			key: 'realizedPnL',
			align: 'right',
			width: 160,
			render: (text, record) => (
				<div>
					{PriceHelpers.formatAmount(text, record.quotingInstrument?.id, Numbers.sign(text || '0'))}
				</div>
			),
		},
	];
	return (
		<div>
			{!isFetchingPositions
				? (
					<CustomTable
						columns={columns}
						data={positions}
						total={positionsTotal}
						headerHeight={208}
						numberOnPage={pageSize}
						currentPage={page}
						handlePageChange={handlePositionsPageChange}
						place="positions"
						applicationId={applicationId}
					/>
				) : <Spin spinning={isFetchingPositions} position="spin-relative" />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	positions: state.account.accountPositions,
	positionsTotal: state.account.accountPositionsTotal,
	isFetchingPositions: state.account.isFetchingAccountPositions,
	pageSize: state.ui.positionsPageSize,
});

const mapDispatchToProps = {
	getAccountPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionsTab);
