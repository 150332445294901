// @flow
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
//= import components
import DatePicker from '../../../components/UiElements/DatePicker';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import Spin from '../../../components/UiElements/Spin';
import InputNumber from '../../../components/UiElements/InputNumber';
import Popover from '../../../components/UiElements/Popover';
import Icon from '../../../components/UiElements/Icon';
//= import actions
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { StockSplit } from '../../../modules/reducers/StockSplitReducer';
//= import styles
import styles from '../assets/corporateAction.module.scss';

type Props = {
	form: *,
	getAppSymbols: (string, string, number, string) => Promise<Object>,
	submitChanges: (value: Object) => void,
	handleCancelClick: () => void,
	applicationId: string,
	stockSplit: StockSplit,
	type: string,
}

type LocalState = {
	symbolData: Array<Symbol>,
	symbolFetching: boolean,
	errorMessage: string,
	announcementDate: string,
	recordDate: string,
	notFoundContent: string,
}

class AddStockSplit extends React.Component<Props, LocalState> {
	state = {
		symbolData: [],
		symbolFetching: false,
		errorMessage: '',
		announcementDate: '',
		recordDate: '',
		notFoundContent: '',
	}

	handleSymbolSearch = debounce((symbolValue: string) => {
		const { getAppSymbols: getAppSymbolsAction, applicationId } = this.props;
		this.setState({ symbolFetching: true });
		if (symbolValue) {
			const filterOptions: string = queryString.stringify({ search: symbolValue });
			getAppSymbolsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							symbolData: data,
							symbolFetching: false,
						});
					} else {
						this.setState({
							symbolData: [],
							symbolFetching: false,
							notFoundContent: `Symbol with id ${symbolValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						symbolFetching: false,
						symbolData: [],
						notFoundContent: 'Symbol not found',
					});
				});
		} else {
			this.setState({
				symbolFetching: false,
				symbolData: [],
				notFoundContent: 'Enter Id to find the Symbol',
			});
		}
	}, 500);

	setAnnouncementDate = (value: string) => {
		this.setState({
			announcementDate: value,
			recordDate: '',
		});
		this.props.form.setFieldsValue({ recordDate: '', exDate: '' });
	}

	setRecordDate = (value: string) => {
		this.setState({
			recordDate: value,
		});
		this.props.form.setFieldsValue({ exDate: '' });
	}

	submit = (e) => {
		e.preventDefault();
		const { submitChanges, form, type } = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					schedule: {
						symbolId: values.symbolId,
						announcementDate: values.announcementDate ? values.announcementDate.format('YYYY-MM-DD') : undefined,
						exDate: values.exDate ? values.exDate.format('YYYY-MM-DD') : undefined,
						recordDate: values.recordDate ? values.recordDate.format('YYYY-MM-DD') : undefined,
						splitRate: `${values.splitRatioLeft}:${values.splitRatioRight}`,
					},
					edit: {
						announcementDate: values.announcementDate ? values.announcementDate.format('YYYY-MM-DD') : undefined,
						exDate: values.exDate ? values.exDate.format('YYYY-MM-DD') : undefined,
						recordDate: values.recordDate ? values.recordDate.format('YYYY-MM-DD') : undefined,
						splitRate: `${values.splitRatioLeft}:${values.splitRatioRight}`,
					},
					execute: {
						closingPrice: values.closingPrice?.toString(),
					},
				};
				submitChanges(data[type]);
			}
		});
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			symbolData: [],
			symbolFetching: false,
			errorMessage: '',
			announcementDate: '',
			recordDate: '',
			notFoundContent: '',
		});
	}

	render() {
		const {
			handleCancelClick,
			stockSplit,
			type,
			form: {
				getFieldDecorator,
				isFieldTouched,
			},
		} = this.props;

		const {
			errorMessage,
			symbolData,
			symbolFetching,
			announcementDate,
			recordDate,
			notFoundContent,
		} = this.state;
		const FormItem = Form.Item;
		const { Option } = Select;
		const fixedLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const ratioLeftLayout = {
			labelCol: {
				xs: { span: 17 },
			},
			wrapperCol: {
				xs: { span: 7 },
			},
		};
		const ratioRightLayout = {
			labelCol: {
				xs: { span: 3 },
			},
			wrapperCol: {
				xs: { span: 21 },
			},
		};

		const disableSubmit = type === 'schedule' && !(isFieldTouched('symbolId')
			&& isFieldTouched('splitRatioLeft')
			&& isFieldTouched('splitRatioRight')
			&& isFieldTouched('announcementDate')
			&& isFieldTouched('recordDate')
			&& isFieldTouched('exDate'));

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{type === 'execute' && (
					<h4 className="subtitle capitalize">
						Stock Split Execution will affect the portfolios of all clients that hold Symbol.
						Please double-check the data below before execution and processed with caution.
					</h4>
				)}
				<FormItem label="SYMBOL ID" {...fixedLayout}>
					{getFieldDecorator(
						'symbolId', {
							initialValue: stockSplit?.symbolId,
							rules: [
								{
									required: true,
									message: 'Please select Symbol Id!',
								},
							],
						},
					)(
						<Select
							allowClear
							showSearch
							className={styles.firstSelect}
							placeholder="Search Symbol by SymbolId"
							notFoundContent={symbolFetching ? <Spin size={16} /> : notFoundContent}
							filterOption={false}
							onSearch={this.handleSymbolSearch}
							disabled={type !== 'schedule'}
						>
							{symbolData.map((el) => (
								<Option
									value={el.id}
									key={el.id}
									className={styles.options}
								>
									{el.id}
								</Option>
							))}
						</Select>,
					)}
				</FormItem>
				<div className={styles.stockSplitForm}>
					<FormItem label="SPLIT RATE" {...ratioLeftLayout}>
						{getFieldDecorator('splitRatioLeft', {
							initialValue: stockSplit?.splitRate.split(':')[0],
							rules: [
								{
									required: true,
									message: 'Please set Split Rate!',
								},
							],
						})(
							<InputNumber type="number" disabled={type === 'execute'} />,
						)}
					</FormItem>
					<FormItem label=":" {...ratioRightLayout}>
						{getFieldDecorator('splitRatioRight', {
							initialValue: stockSplit?.splitRate.split(':')[1],
							rules: [
								{
									required: true,
									message: 'Please set Split Rate!',
								},
							],
						})(
							<InputNumber type="number" disabled={type === 'execute'} />,
						)}
					</FormItem>
				</div>
				{type === 'schedule' && (
					<FormItem label="ANNOUNCEMENT DATE" {...fixedLayout}>
						{getFieldDecorator('announcementDate', {
							initialValue: stockSplit?.announcementDate
								? moment(stockSplit?.announcementDate)
								: null,
							rules: [
								{
									required: true,
									message: 'Please select Announcement Date!',
								},
							],
						})(
							<DatePicker
								className={styles.date}
								dropdownClassName={styles.picker}
								onChange={this.setAnnouncementDate}
							/>,
						)}
					</FormItem>
				)}
				<FormItem label="RECORD DATE" {...fixedLayout}>
					{getFieldDecorator('recordDate', {
						initialValue: stockSplit?.recordDate
							? moment(stockSplit?.recordDate)
							: null,
						rules: [
							{
								required: true,
								message: 'Please select Record Date!',
							},
						],
					})(
						<DatePicker
							className={styles.date}
							dropdownClassName={styles.picker}
							disabledDate={(current) => (current < moment(announcementDate).add(1, 'day'))}
							onChange={this.setRecordDate}
							disabled={type === 'execute'}
						/>,
					)}
				</FormItem>
				<FormItem label="EX-DATE" {...fixedLayout}>
					{getFieldDecorator('exDate', {
						initialValue: stockSplit?.exDate
							? moment(stockSplit?.exDate)
							: null,
						rules: [
							{
								required: true,
								message: 'Please select EX-Date!',
							},
						],
					})(
						<DatePicker
							className={styles.date}
							dropdownClassName={styles.picker}
							disabledDate={(current) => current < moment(recordDate).add(1, 'day')}
							disabled={type === 'execute'}
						/>,
					)}
				</FormItem>
				{type === 'execute' && (
					<FormItem label="CLOSING PRICE" {...fixedLayout}>
						<div className="fee-info">
							<Popover
								content="We use Closing Price to calculate the cash payout amount if Split results with fractions."
								overlayClassName="button-popover"
							>
								<div className="info-icon">
									<Icon name="Info" />
								</div>
							</Popover>
							{getFieldDecorator('closingPrice', {
								rules: [
									{
										required: true,
										message: 'Please set Closing Price!',
									},
								],
							})(
								<InputNumber placeholder="Set Closing Price" symbol={stockSplit.quotingInstrument} />,
							)}
						</div>
					</FormItem>
				)}
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" disabled={disableSubmit} action={this.submit} text={`${type} stock split`} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const StockSplitForm = Form.create()(AddStockSplit);

const mapDispatchToProps = {
	getAppSymbols,
};
export default connect(null, mapDispatchToProps)(StockSplitForm);
