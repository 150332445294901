// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//= import components
import CustomTable from '../../components/UiElements/CustomTable';
import Title from '../../components/UiElements/Title';
import Spin from '../../components/UiElements/Spin';
//= import methods
import { getInventoryWallets } from '../../modules/actions/InventoryWalletsAction';
import { setCurrentPage } from '../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../lib/helpers/priceHelpers';
import ActivityHelpers from '../../lib/helpers/activityHelpers';
import Numbers from '../../lib/helpers/numbersHelpers';
//= import types
import type { InventoryWallet } from '../../modules/reducers/InventoryWalletReducer';
import type { Symbol } from '../../modules/reducers/SymbolReducer';
import type { State } from '../../modules/types/FlowTypes';

type Props = {
	getInventoryWallets: (string, number) => void,
	inventoryWallets: Array<InventoryWallet>,
	isFetchingInventoryWallets: boolean,
	inventoryWalletsTotal: number,
	currentPage: number,
	match: {
		params: {
			applicationId: string
		}
	},
	history: {
		push: (string) => void,
	},
	setCurrentPage: (string, number) => void,
}

function InventoryWalletsPage(props: Props) {
	const {
		inventoryWallets,
		isFetchingInventoryWallets,
		inventoryWalletsTotal,
		currentPage,
		getInventoryWallets: getInventoryWalletsAction,
		setCurrentPage: setCurrentPageAction,
		match: { params: { applicationId } },
	} = props;

	useEffect(() => {
		getInventoryWalletsAction(applicationId, currentPage);
	}, [currentPage, getInventoryWalletsAction, applicationId]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('inventoryWalletsPage', page);
	};

	const columns = [
		{
			title: 'wallet',
			dataIndex: 'symbolId',
			key: 'symbolId',
			width: 224,
			render: (text: string) => (
				<Link
					to={`/application/${applicationId}/inventory/${text}`}
				>
					{text}
				</Link>
			),
		},
		{
			title: 'exchange',
			dataIndex: ['symbol', 'exchangeId'],
			key: 'exchange',
			width: 224,
			render: (text: string) => (
				<div className="capitalize">
					{ActivityHelpers.transformSnakeCase(text)}
				</div>
			),
		},
		{
			title: 'unallocated balance',
			dataIndex: 'available',
			key: 'available',
			align: 'right',
			width: 224,
			render: (text: string, record: { symbol: Symbol }) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.symbol.baseInstrumentId,
						Numbers.sign(text),
					)}
				</div>
			),
		},
		{
			title: 'allocated balance',
			dataIndex: 'allocated',
			key: 'allocated',
			align: 'right',
			width: 224,
			render: (text: string, record: { symbol: Symbol }) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.symbol.baseInstrumentId,
						Numbers.sign(text),
					)}
				</div>
			),
		},
		{
			title: 'adjustment',
			dataIndex: 'adjustment',
			key: 'adjustment',
			align: 'right',
			width: 224,
			render: (text: string, record: { symbol: Symbol }) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.symbol.baseInstrumentId,
						Numbers.sign(text),
					)}
				</div>
			),
		},
	];
	return (
		<>
			<Title
				title="my wallets"
				searchComponent={{
					placeholder: 'Search For Wallets...',
					place: 'appWalletsSearch',
					disabled: true,
				}}
			/>
			<div className="container">
				{!isFetchingInventoryWallets
					? (
						<CustomTable
							columns={columns}
							data={inventoryWallets}
							total={inventoryWalletsTotal}
							handlePageChange={handlePageChange}
							currentPage={currentPage}
							headerHeight={0}
							place="inventoryWallets"
							applicationId={applicationId}
						/>
					) : <Spin spinning={isFetchingInventoryWallets} />}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	inventoryWallets: state.inventoryWallets.inventoryWallets,
	inventoryWalletsTotal: state.inventoryWallets.inventoryWalletsTotal,
	isFetchingInventoryWallets: state.inventoryWallets.isFetchingInventoryWallets,
	currentPage: state.ui.inventoryWalletsPage,
});

const mapDispatchToProps = {
	getInventoryWallets,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryWalletsPage);
