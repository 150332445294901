// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';
//= import componnets
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DepositFees from '../../Fee/components/DepositFees';
import WithdrawalFees from '../../Fee/components/WithdrawalFees';
import TradingFees from '../../Fee/components/TradingFees';
import CardFxFees from '../../Fee/components/CardFxFees';
import EditFeeForm from '../../Fee/components/EditFeeForm';
import Tabs from '../../../components/UiElements/Tabs';
//= import methods
import { getFees, updateFee } from '../../../modules/actions/FeeActions';
import { feesMappedSelector } from '../../../modules/selectors/FeeSelector';
import { setActiveTab } from '../../../modules/actions/UiActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
//= import types
import type { MappedFee } from '../../../modules/selectors/FeeSelector';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	fees: Array<MappedFee>,
	activeTab: string,
	getFees: (string, string) => void,
	updateFee: (string, string, Object) => Promise<Object>,
	getProvidersInstances: (string) => void,
	providers: Array<ProviderInstance>,
	applicationId: string,
	userId: string,
	setActiveTab: (string, string) => void,
	viewFee: (fee: MappedFee) => void,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

const AllFees = (props: Props) => {
	const {
		applicationId,
		userId,
		fees,
		activeTab,
		providers,
		viewFee,
		getFees: getFeesAction,
		setActiveTab: setActiveTabAction,
		updateFee: updateFeeAction,
		getProvidersInstances: getProvidersInstancesAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [title, setTitle] = useState('');
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [feeToEdit, setEditFee] = useState(null);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		const param: string = queryString.stringify({ userId });
		if (!providers.length) {
			getProvidersInstancesAction(applicationId);
		}
		getFeesAction(applicationId, param);
	}, [getFeesAction, applicationId, userId, getProvidersInstancesAction, providers]);

	const changeActiveTab = (activeKey: string) => {
		setActiveTabAction('user_feeTab', activeKey);
	};

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitRankChanges = async (feeId: string, rankData) => {
		setButtonLoading(true);
		const param = queryString.stringify({ userId });
		try {
			await updateFeeAction(applicationId, feeId, rankData);
			await getFeesAction(applicationId, param);
			setButtonLoading(false);
			setWarningModal(false);
		} catch (error) { handleOnError(error); }
	};

	const editFeeRank = (fee: MappedFee, hoverIndex: number) => {
		const rankData = { priority: hoverIndex + 1 };
		setWarningModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you confirm these changes, ${fee.feeName}, rank #${fee.priority} will change to rank #${hoverIndex + 1},
					 and all fee ranks below will be affected. It is advised to proceed editing with caution.`,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'cancel' },
			{ type: 'continue', action: () => submitRankChanges(fee.id, rankData), text: 'save changes' },
		]);
	};

	const submitEditFee = async (data) => {
		setButtonLoading(true);
		const param = queryString.stringify({ userId });
		try {
			await updateFeeAction(applicationId, feeToEdit ? feeToEdit.id : '', data);
			getFeesAction(applicationId, param);
			setButtonLoading(false);
			setWarningModal(false);
		} catch (error) { handleOnError(error); }
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const continueEditing = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect all Fee Rules below.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEditFee(value), text: 'edit fee' },
		]);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	// edit fee

	const selectFeeToEdit = (feeData) => {
		setEditFee(() => feeData);
		setTitle(`Edit Fee Rule ${feeData.feeName}`);
		setEdit(true);
	};

	const allFees = {
		deposit: fees && orderBy(fees.filter((fee) => fee.type === 'deposit'), 'priority'),
		withdrawal: fees && orderBy(fees.filter((fee) => fee.type === 'withdrawal'), 'priority'),
		trading: fees && orderBy(fees.filter((fee) => fee.type === 'trading'), 'priority'),
		cardFx: fees && orderBy(fees.filter((fee) => fee.type === 'cardFx'), 'priority'),
	};
	return (
		<div className={styles.feeContainer}>
			<Tabs
				type="card"
				className="tabs"
				activeKey={activeTab}
				onChange={changeActiveTab}
			>
				<Tabs.TabPane
					tab="DEPOSIT"
					key="deposit"
				>
					<DepositFees
						editFeeRank={editFeeRank}
						depositFees={allFees.deposit}
						viewFee={viewFee}
						setFeeToEdit={selectFeeToEdit}
						applicationId={applicationId}
						place="userFees"
					/>
				</Tabs.TabPane>
				<Tabs.TabPane
					tab="WITHDRAWAL"
					key="withdrawal"
				>
					<WithdrawalFees
						editFeeRank={editFeeRank}
						withdrawalFees={allFees.withdrawal}
						viewFee={viewFee}
						setFeeToEdit={selectFeeToEdit}
						applicationId={applicationId}
						place="userFees"
					/>
				</Tabs.TabPane>
				<Tabs.TabPane
					tab="TRADING"
					key="trading"
				>
					<TradingFees
						editFeeRank={editFeeRank}
						tradingFees={allFees.trading}
						viewFee={viewFee}
						setFeeToEdit={selectFeeToEdit}
						applicationId={applicationId}
						place="userFees"
					/>
				</Tabs.TabPane>
				<Tabs.TabPane
					tab="CARD FX FEES"
					key="cardFx"
				>
					<CardFxFees
						editFeeRank={editFeeRank}
						cardFxFees={allFees.cardFx}
						viewFee={viewFee}
						setFeeToEdit={selectFeeToEdit}
						applicationId={applicationId}
						place="userFees"
					/>
				</Tabs.TabPane>
			</Tabs>
			<FormModal
				title={title}
				visible={edit}
				cancelFunction={handleEditCancel}
				form={(
					<EditFeeForm
						submitChanges={continueEditing}
						handleCancelClick={handleEditCancel}
						fee={feeToEdit}
						providers={providers}
						key={feeToEdit?.id}
						wrappedComponentRef={formRef}
						applicationId={applicationId}
					/>

				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warningModal}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	fees: feesMappedSelector(state),
	activeTab: state.ui.user_feeTab,
	providers: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getFees,
	updateFee,
	setActiveTab,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllFees);
