// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Product } from '../../../modules/reducers/CardsReducer';

type Props = {
	products: Array<Product>,
	isFetchingProducts: boolean,
	applicationId: string,
}

function Products(props: Props) {
	const {
		products,
		isFetchingProducts,
		applicationId,
	} = props;

	const columns = [
		{
			title: 'time created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 176,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD HH:mm:ss')}
				</div>
			),
		}, {
			title: 'product name',
			dataIndex: 'name',
			key: 'name',
			width: 240,
			render: (text: string, record: Product) => (
				<div className="capitalize">
					<Link
						to={`/application/${applicationId}/system/card/products/${record.id}`}
					>
						{text}
					</Link>
				</div>
			),
		}, {
			title: 'is active',
			dataIndex: 'isActive',
			key: 'isActive',
			width: 112,
			render: (text: boolean) => (
				<div style={text ? { maxWidth: 80 } : { maxWidth: 80, color: '#999999' }}>
					{text ? 'Yes' : 'No'}
				</div>
			),
		}, {
			title: 'program scheme',
			dataIndex: 'cardScheme',
			key: 'cardScheme',
			width: 160,
		}, {
			title: 'BIN type',
			dataIndex: 'binType',
			key: 'binType',
			width: 160,
		}, {
			title: 'active by',
			dataIndex: 'endDate',
			key: 'endDate',
			width: 160,
			render: (text: string) => (
				<div className="wrap">
					{moment(text).format('YYYY-MM-DD')}
				</div>
			),
		},
	];

	return (
		<div className="container">
			{!isFetchingProducts
				? (
					<CustomTable
						columns={columns}
						data={products}
						total={products.length}
						headerHeight={16}
						place="products"
						applicationId={applicationId}
					/>
				) : <Spin spinning={!isFetchingProducts} />}
		</div>
	);
}

const mapStateToProps = (state: State) => ({
	products: state.cards.products,
	isFetchingProducts: state.cards.isFetchingProducts,
});

export default connect(mapStateToProps)(Products);
