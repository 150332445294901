// @flow

import * as React from 'react';
import { connect } from 'react-redux';
//= import components
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Title from '../../../components/UiElements/Title';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import Spin from '../../../components/UiElements/Spin';
import SwitchComponent from '../components/SwitchComponent';
import TextCard from '../../../components/UiElements/CustomCards/TextCard';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import OrderComponent from '../components/OrderComponent';
import EditSymbolForm from '../components/EditSymbolForm';
import UpcomingEvents from '../components/UpcomingEvents';
//= import methods
import {
	getSymbol,
	updateSymbol,
	getGroupSymbol,
	updateGroupSymbol,
} from '../../../modules/actions/SymbolActions';
import { getProviders, getProvidersInstances } from '../../../modules/actions/ProviderActions';
// = import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { ProviderInstance, Provider } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getSymbol: (string, string) => Promise<Object>,
	getGroupSymbol: (string, string) => Promise<Object>,
	updateSymbol: (string, string, Object) => Promise<Object>,
	updateGroupSymbol: (string, string, Object) => Promise<Object>,
	getProviders: (string) => Promise<Object>,
	getProvidersInstances: (string) => void,
	providers: Array<Provider>,
	providersInstances: Array<ProviderInstance>,
	match: {
		params: {
			symbolId: string,
			applicationId: string,
			groupId: string,
		},
	},
	history: {
		push: (string) => void,
		goBack: () => void,
		length: number,
	}
}
type SymbolRes = {
	payload: {
		data: Symbol,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}
type LocalState = {
	edit: boolean,
	warningModal: boolean,
	title: string,
	footer: Array<Object>,
	message: Object,
	warningTitle: string,
	symbol: Symbol,
	group: boolean,
	parentSymbol: Symbol,
	switchKey: boolean,
	buttonLoading: boolean,
	loading: boolean,
}

type Data = {
	[string]: string,
}
class SymbolDetails extends React.Component<Props, LocalState> {
	state = {
		edit: false,
		warningModal: false,
		title: '',
		footer: [],
		message: {},
		warningTitle: 'Warning!',
		symbol: {},
		parentSymbol: {},
		switchKey: false,
		group: false,
		buttonLoading: false,
		loading: false,
	}

	formRef: ?EditSymbolForm;

	async componentDidMount() {
		const {
			match: { params: { applicationId, symbolId, groupId } },
			getProviders: getProvidersAction,
			getProvidersInstances: getProvidersInstancesAction,
			getSymbol: getSymbolAction,
			getGroupSymbol: getGroupSymbolAction,
		} = this.props;
		this.setState({
			group: !!groupId,
			loading: true,
		});
		getProvidersAction(applicationId);
		getProvidersInstancesAction(applicationId);
		try {
			if (groupId) {
				const symbolRes: SymbolRes = await getSymbolAction(applicationId, symbolId);
				this.setState({
					parentSymbol: symbolRes.payload.data,
				});
				const groupRes: SymbolRes = await getGroupSymbolAction(groupId, symbolId);
				this.setState({
					symbol: groupRes.payload.data,
				});
			} else {
				const symbolRes: SymbolRes = await getSymbolAction(applicationId, symbolId);
				this.setState({
					symbol: symbolRes.payload.data,
				});
			}
		} catch (error) {
			this.setState({
				message: { firstPart: error.error.response ? error.error.response.data.message : 'Error' },
			});
		} finally {
			this.setState({
				loading: false,
			});
		}
	}

	handleBackClick = () => {
		this.props.history.goBack();
	}

	handleEditClick = () => {
		this.setState((prevState) => ({
			edit: true,
			title: `Edit ${prevState.symbol.displayName} ${prevState.symbol.id}`,
		}));
	}

	handleEditCancel = () => {
		if (this.formRef) {
			if (this.formRef.props.form.isFieldsTouched()) {
				this.setState({
					edit: false,
					warningModal: true,
					warningTitle: 'Warning!',
					message: { firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' },
					footer: [
						{ type: 'cancel', action: this.handleErrorCancel, text: 'cancel edit' },
						{ type: 'continue', action: this.handleWarningCancel, text: 'continue editing' },
					],
				});
			} else {
				this.setState({
					edit: false,
					warningModal: false,
				});
			}
		}
	}

	handleWarningCancel = () => {
		this.setState({
			edit: true,
			warningModal: false,
		});
	}

	printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	handleOnError = (error: ErrorType) => {
		this.setState((prevState) => ({
			buttonLoading: false,
			warningModal: true,
			warningTitle: 'Error',
			message: {
				firstPart: error.error?.response ? error.error.response.data.message : 'Error',
				secondPart: this.printError(error.error.response?.data?.errors),
			},
			footer: [
				{ type: 'cancel', action: this.handleErrorCancel, text: 'close' },
			],
			switchKey: !prevState.switchKey,
		}));
	}

	handleErrorCancel = () => {
		if (this.formRef) {
			this.formRef.handleReset();
		}
		this.setState({
			edit: false,
			warningModal: false,
		});
	}

	handleToggle = (action, value) => {
		const data = { [action]: value };
		if (value) {
			this.submitChanges(data);
		} else {
			this.continueToggle(data);
		}
	}

	continueToggle = (data: Data) => {
		this.setState((prevState) => ({
			warningModal: true,
			edit: false,
			warningTitle: 'Are you sure?',
			message: {
				firstPart: `Once you turn off 
				${data.action === 'isVisible' ? 'visibility' : 'tradability'} of this Symbol, your Client won’t be able to 
				${data.action === 'isVisible' ? 'see' : 'trade'} it 
				${prevState.group ? '' : 'and all of it’s Group Symbols'} anymore.`,
				secondPart: 'It is advised to proceed editing with caution.',
			},
			footer: [
				{ type: 'cancel', action: this.handleToggleCancel, text: 'cancel' },
				{ type: 'continue', action: () => this.submitChanges(data), text: 'turn off' },
			],
		}));
	}

	handleToggleCancel = () => {
		this.setState((prevState) => ({
			warningModal: false,
			switchKey: !prevState.switchKey,
		}));
	}

	continueChanges = (value) => {
		this.setState((prevState) => ({
			warningModal: true,
			edit: false,
			warningTitle: 'Are you sure?',
			message: {
				firstPart: `Once you confirm these changes, they will affect this ${prevState.group ? 'Group Symbol' : 'Symbol and all its Group Symbols'}.`,
				secondPart: 'It is advised to proceed editing with caution.',
			},
			footer: [
				{ type: 'cancel', action: this.handleWarningCancel, text: 'go back' },
				{ type: 'continue', action: () => this.submitChanges(value), text: 'save changes' },
			],
		}));
	}

	submitChanges = (value) => {
		const { match: { params: { applicationId, symbolId, groupId } } } = this.props;
		this.setState({ warningModal: false, buttonLoading: true });
		if (this.formRef) {
			this.formRef.handleReset();
		}
		if (this.state.group) {
			this.props.updateGroupSymbol(groupId, symbolId, value)
				.then((res: SymbolRes) => this.setState((prevState) => ({
					symbol: res.payload.data,
					buttonLoading: false,
					switchKey: !prevState.switchKey,
				})))
				.catch((error: ErrorType) => this.handleOnError(error));
		} else {
			this.props.updateSymbol(applicationId, symbolId, value)
				.then((res: SymbolRes) => this.setState((prevState) => ({
					symbol: res.payload.data,
					buttonLoading: false,
					switchKey: !prevState.switchKey,
				})))
				.catch((error: ErrorType) => this.handleOnError(error));
		}
	}

	render() {
		const {
			providers,
			providersInstances,
			history,
		} = this.props;
		const {
			edit,
			title,
			footer,
			message,
			warningModal,
			warningTitle,
			symbol,
			group,
			parentSymbol,
			switchKey,
			buttonLoading,
			loading,
		} = this.state;

		const switchValues = {
			visible: {
				name: 'isVisible',
				value: symbol.isVisible,
				parent: parentSymbol.isVisible,
			},
			tradable: {
				name: 'isTradable',
				value: symbol.isTradable,
				parent: parentSymbol.isTradable,
			},
			closeOnly: {
				name: 'closeOnly',
				value: symbol.closeOnly,
				parent: parentSymbol.closeOnly,
			},
		};
		const symbolDetailsData = symbol.supportedOrderTypes && {
			displayName: symbol.displayName || '-',
			orderTypes: symbol.supportedOrderTypes.join(', '),
		};
		const precisionData = symbol.baseInstrument && {
			base: symbol.baseInstrumentId,
			exchangePrecision: symbol.exchangePrecision,
			basePrecision: symbol.basePrecision,
			increment: symbol.orderSize.increment,
			exchangeMinimum: PriceHelpers.formatAmount(symbol.exchangeMinimum, symbol.baseInstrumentId),
			minimum: PriceHelpers.formatAmount(
				symbol.orderSize.minQuantity.baseInstrument,
				symbol.baseInstrumentId,
			),
			quoting: symbol.quotingInstrumentId,
			quotingPrecision: symbol.quotingPrecision,
		};
		const offsetData = symbol.offset && {
			askPrice: PriceHelpers.formatAmount(symbol.price.ask, symbol.quotingInstrumentId),
			askPriceOffset: PriceHelpers.formatAmount(symbol.offset.ask, symbol.quotingInstrumentId),
			bidPrice: PriceHelpers.formatAmount(symbol.price.bid, symbol.quotingInstrumentId),
			bidPriceOffset: PriceHelpers.formatAmount(
				symbol.offset.bid,
				symbol.quotingInstrumentId,
				Numbers.sign(symbol.offset.bid),
			),
		};
		const snapshotProvider = !!providers.length && providers.find((el) => (
			el.id === symbol.snapshotProviderId));
		const quotingProvider = !!providers.length && providers.find((el) => (
			el.id === symbol.quotingProviderId));
		const tradingProvider = !!providersInstances.length && providersInstances.find((el) => (
			el.id === symbol.tradingProviderId));
		const providersData = symbol.exchangeId
			? {
				exchange: symbol.exchangeId,
				quoting: quotingProvider
					? quotingProvider.displayName
					: symbol.quotingProviderId,
				trading: tradingProvider
					? tradingProvider.provider.displayName
					: symbol.tradingProviderId,
				snapshot: snapshotProvider
					? snapshotProvider.displayName
					: symbol.snapshotProviderId,
			} : {
				exchange: '-',
				quotingProvider: '-',
				tradingProvider: '-',
				snapshotProvider: '-',
			};
		const availableProviders = !!providersInstances.length && providersInstances.filter((el) => (
			el.provider.type === 'market' && el.provider.supports.includes('trade')));

		return (
			<>
				{!loading
					? (
						<div>
							<Title
								backAction={history.length > 1 ? this.handleBackClick : undefined}
								title={`${symbol.displayName || ''} ${symbol.id || ''}`}
								tags={[
									group && 'groupSymbol',
									symbol.closeOnly && 'closeOnly',
									symbol.basePrecision > symbol.exchangePrecision && 'fractionalTrading',
								]}
								buttons={[{
									action: this.handleEditClick,
									text: group ? 'edit group symbol' : 'edit symbol',
								}]}
							/>
							{symbol.id
								? (
									<div className="page-container">
										<Row gutter={24}>
											<Col span={14} className="symbol">
												<SwitchComponent
													handleToggle={this.handleToggle}
													values={switchValues}
													group={group}
													switchKey={switchKey}
												/>
												<OrderComponent
													data={symbol}
													price={symbol.price.ask}
													opacity={!symbol.isVisible || !symbol.isTradable}
													parentSymbol={parentSymbol}
												/>
											</Col>
											<Col span={10} className="symbol" style={symbol.isVisible ? { opacity: 1 } : { opacity: 0.7 }}>
												<TextCard data={symbolDetailsData} colNumber={1} />
												<TextCard title="Precisions" data={precisionData} colNumber={1} />
												<TextCard title="Prices" data={offsetData} colNumber={1} />
												<TextCard title="Providers" data={providersData} colNumber={1} />
												{symbol.upcomingEvents
										&& <UpcomingEvents data={symbol.upcomingEvents} />}
											</Col>
										</Row>
										<FormModal
											title={title}
											visible={edit}
											cancelFunction={this.handleEditCancel}
											form={(
												<EditSymbolForm
													submitChanges={this.continueChanges}
													handleCancelClick={this.handleEditCancel}
													symbol={symbol}
													parentSymbol={parentSymbol}
													group={group}
													wrappedComponentRef={(element) => { this.formRef = element; }}
													availableProviders={availableProviders}
												/>
											)}
										/>
										<WarningModal
											title={warningTitle}
											visible={warningModal}
											cancelFunction={this.handleErrorCancel}
											footer={footer}
											message={message}
											loading={buttonLoading}
										/>
									</div>
								)
								: (
									<div className="empty-state">
										<UserNoChecks />
										<p>{message.firstPart}</p>
									</div>
								)}
						</div>
					)
					: <Spin spinning={loading} />}
			</>
		);
	}
}

const mapStateToProps = (state: State) => ({
	providers: state.providers.providers,
	providersInstances: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getSymbol,
	updateSymbol,
	getGroupSymbol,
	updateGroupSymbol,
	getProviders,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetails);
