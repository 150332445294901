// @flow

import React from 'react';
import moment from 'moment';
//= import components
import Timeline from '../../../components/UiElements/Timeline';
import Tooltip from '../../../components/UiElements/Tooltip';
import Icon from '../../../components/UiElements/Icon';
import {
	TimeDot, InvisibleDot, ActionDot,
} from '../../../components/UiElements/TimeDot';
//= import helpers
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { FormattedOrder } from '../../../modules/selectors/OrdersSelector';
import type { FullOrder } from '../../../modules/reducers/OrderReducer';

type Props = {
	firstWallet: Array<Object>,
	secondWallet: Array<Object>,
	thirdWallet: Array<Object>,
	order: FormattedOrder,
	orderPayload: FullOrder,
	place: string,
	action: string,
	handleOpenDrawer: Function,
	activeItem: string,
	provider: string,
}
const OrderTimeline = (props: Props) => {
	const {
		firstWallet,
		secondWallet,
		thirdWallet,
		order,
		orderPayload,
		place,
		action,
		handleOpenDrawer,
		activeItem,
		provider,
	} = props;

	const {
		mappedOrders,
		commandFormatted,
		reservationFormatted,
		symbolId,
		type,
		side,
		status,
		createdAt,
		executedAt,
		logTime,
	} = order;
	return (
		<Timeline>
			<Timeline.Item
				dot={<ActionDot action={action} />}
			>
				<div className="invisible-div" />
			</Timeline.Item>
			<Timeline.Item
				dot={place === 'first' ? <TimeDot time={createdAt} diff="" /> : <InvisibleDot />}
			>
				<div className="invisible-div" />
				<div
					className={place === 'first' ? ((activeItem === commandFormatted.id && 'visible active-item') || 'visible') : 'hidden'}
					onClick={() => handleOpenDrawer('orderPlaced', commandFormatted.id, 'Order Placed')}
					role="presentation"
				>
					<p>
						{orderPayload.orderCommand && orderPayload.orderCommand.quantity === '0'
							? (
								<span>
									<span className="order-number">{`Buy ${order.baseInstrumentId}`}</span>
									<span className="order-text"> for </span>
									<span className="order-number">{commandFormatted.amount}</span>
								</span>
							)
							: <span className="order-number">{commandFormatted.quantity}</span>}
						<span className="order-text"> at </span>
						{orderPayload.orderCommand.price === '0.00'
							? (
								<Tooltip
									title="Price reference is taken from the order reservation"
								>
									<span className="order-number">{reservationFormatted.price}</span>
									<Icon name="Info" />
								</Tooltip>
							)
							: <span className="order-number">{commandFormatted.price}</span>}
					</p>
					<p className="capitalize">{`${type} ${side} ${symbolId} Order Placed`}</p>
				</div>
			</Timeline.Item>
			{firstWallet && firstWallet.length ? (
				<Timeline.Item
					dot={place === 'second'
						? (
							<TimeDot
								time={firstWallet[0].time}
								diff={`T +${moment(firstWallet[0].time).diff(moment(orderPayload.createdAt), 'miliseconds')}ms`}
								status="wallet"
							/>
						)
						: <InvisibleDot />}
				>
					<div className="invisible-div" />
					<div
						className={place === 'second' ? ((activeItem === firstWallet[0].transactionId && 'visible active-item') || 'visible') : 'hidden'}
						onClick={() => handleOpenDrawer('wallet', firstWallet[0].transactionId, `${firstWallet[0].reference} ${firstWallet[0].type}`, firstWallet[0].instrumentId)}
						role="presentation"
					>
						<p>
							<span className={(!Numbers.sign(firstWallet[0].amount) ? `order-number ${firstWallet[0].type}` : `order-number red-text ${firstWallet[0].type}`)}>
								{firstWallet[0].amountFormatted}
							</span>
							<span className="capitalize">{` ${firstWallet[0].reference} ${firstWallet[0].type}`}</span>
						</p>
						<p>
							<span className="order-available">
								{firstWallet[0].availableFormatted}
							</span>
							<span> Available After</span>
						</p>
					</div>
				</Timeline.Item>
			) : null}
			{(order.status === 'cancelled' || order.status === 'failedToExecute') && (
				<Timeline.Item
					dot={place === 'first' ? (
						<TimeDot
							time={logTime || firstWallet[0]?.time || '-'}
							diff={(orderPayload.providerLogs.length || firstWallet[0]) ? `T +${moment(orderPayload.providerLogs.length ? orderPayload.providerLogs[0].createdAt : firstWallet[0].time).diff(moment(orderPayload.createdAt), 'miliseconds')}ms` : '-'}
							status={order.status}
						/>
					) : <InvisibleDot />}
				>
					<div className="invisible-div" />
					<div
						className={place === 'first' ? ((activeItem === order.id && 'visible active-item') || 'visible') : 'hidden'}
						onClick={() => handleOpenDrawer('failed', order.id, `Order ${order.status}`)}
						role="presentation"
					>
						<p className="capitalize">{`${type} ${side} ${symbolId} Order ${status}`}</p>
					</div>
				</Timeline.Item>
			)}
			{mappedOrders.length ? (
				<Timeline.Item
					dot={place === 'first'
						? (
							<TimeDot
								time={executedAt || logTime || firstWallet[0].time}
								diff={`T +${moment(orderPayload.executedAt || (orderPayload.providerLogs.length ? orderPayload.providerLogs[0].createdAt : firstWallet[0].time)).diff(moment(orderPayload.createdAt), 'miliseconds')}ms`}
							/>
						)
						: <InvisibleDot />}
				>
					<div className="invisible-div" />
					<div
						className={place === 'first' ? ((activeItem === mappedOrders[0].id && 'visible active-item') || 'visible') : 'hidden'}
						onClick={() => handleOpenDrawer('trades', mappedOrders[0].id, `Order ${status} on ${provider}`)}
						role="presentation"
					>
						<p>
							<span className="order-number">{mappedOrders[0].quantity}</span>
							<span className="order-text"> at </span>
							<span className="order-number">{mappedOrders[0].price}</span>
						</p>
						<p className="capitalize">{`${type} ${side} ${symbolId} Order ${status} on ${provider}`}</p>
					</div>
				</Timeline.Item>
			) : null}
			{firstWallet && firstWallet.length > 1 ? firstWallet.slice(1).map((wallet) => (
				<Timeline.Item
					key={wallet.amount}
					dot={place === 'second'
						? (
							<TimeDot
								time={wallet.time}
								diff={`T +${moment(wallet.time).diff(moment(createdAt), 'miliseconds')}ms`}
								status="wallet"
							/>
						)
						: <InvisibleDot />}
				>
					<div className="invisible-div" />
					<div
						className={place === 'second' ? ((activeItem === wallet.transactionId && 'visible active-item') || 'visible') : 'hidden'}
						onClick={() => handleOpenDrawer('wallet', wallet.transactionId, ` ${wallet.reference} ${wallet.type}`, wallet.instrumentId)}
						role="presentation"
					>
						<p>
							<span className={(!Numbers.sign(wallet.amount) ? `order-number ${wallet.type}` : `order-number red-text ${wallet.type}`)}>
								{wallet.amountFormatted}
							</span>
							<span className="capitalize">{` ${wallet.reference} ${wallet.type}`}</span>
						</p>
						<p>
							<span className="order-available">
								{wallet.availableFormatted}
							</span>
							<span> Available After</span>
						</p>
					</div>
				</Timeline.Item>
			)) : null}
			{secondWallet && secondWallet.length && secondWallet.map((wallet) => (
				<Timeline.Item
					key={wallet.amount}
					dot={place === 'third'
						? (
							<TimeDot
								time={wallet.time}
								diff={`T +${moment(wallet.time).diff(moment(createdAt), 'miliseconds')}ms`}
								status="wallet"
							/>
						)
						: <InvisibleDot />}
				>
					<div className="invisible-div" />
					<div
						className={place === 'third' ? ((activeItem === wallet.transactionId && 'visible active-item') || 'visible') : 'hidden'}
						onClick={() => handleOpenDrawer('wallet', wallet.transactionId, ` ${wallet.reference} ${wallet.type}`, wallet.instrumentId)}
						role="presentation"
					>
						<p>
							<span className={(!Numbers.sign(wallet.amount) ? `order-number ${wallet.type}` : `order-number red-text ${wallet.type}`)}>
								{wallet.amountFormatted}
							</span>
							<span className="capitalize">{` ${wallet.reference} ${wallet.type}`}</span>
						</p>
						<p>
							<span className="order-available">
								{wallet.availableFormatted}
							</span>
							<span> Available After</span>
						</p>
					</div>
				</Timeline.Item>
			))}
			{thirdWallet && thirdWallet.length && thirdWallet.map((wallet) => (
				<Timeline.Item
					key={wallet.amount}
					dot={place === 'fourth'
						? (
							<TimeDot
								time={wallet.time}
								diff={`T +${moment(wallet.time).diff(moment(createdAt), 'miliseconds')}ms`}
								status="wallet"
							/>
						)
						: <InvisibleDot />}
				>
					<div className="invisible-div" />
					<div
						className={place === 'fourth' ? ((activeItem === wallet.transactionId && 'visible active-item') || 'visible') : 'hidden'}
						onClick={() => handleOpenDrawer('wallet', wallet.transactionId, ` ${wallet.reference} ${wallet.type}`, wallet.instrumentId)}
						role="presentation"
					>
						<p>
							<span className={(!Numbers.sign(wallet.amount) ? `order-number ${wallet.type}` : `order-number red-text ${wallet.type}`)}>
								{wallet.amountFormatted}
							</span>
							<span className="capitalize">{` ${wallet.reference} ${wallet.type}`}</span>
						</p>
						<p>
							<span className="order-available">
								{wallet.availableFormatted}
							</span>
							<span> Available After</span>
						</p>
					</div>
				</Timeline.Item>
			))}
			<Timeline.Item
				dot={<InvisibleDot />}
			/>
		</Timeline>
	);
};

export default OrderTimeline;
