// @flow

const initialState = {
	error: false,
	isFetchingApplications: false,
	isFetchedApplications: false,
	applications: [],
	applicationSettings: {},
	isFetchedSettings: false,
	isFetchingSettings: false,
	isFetchingApplication: false,
	isFetchedApplication: false,
	application: {},
	isUpdatingApplication: false,
	isUpdatedApplication: false,
	applicationCountries: [],
	isFetchingApplicationCountries: false,
	isFetchedApplicationCountries: false,
};

export type ApplicationDetail = {
	addressLine2: string,
	applicationId: string,
	companyName: string,
	contactEmail: string,
	country: string,
	createdAt: string,
	faxNumber: string,
	icon: string,
	id: string,
	logo: string,
	phone: string,
	registrationNumber: string,
	state: string,
	streetName: string,
	streetNumber: string,
	updatedAt: string,
	vatNumber: string,
	website: string,
	zipCode: string,
}
export type AppCountry = {
	id: string,
	countryCode: string,
	name: string,
	country: {
		name: string,
		countryCode: string,
	}
}
export type Application = {
	customer: {
		id: string,
		email: string,
		name: string,
		reportingCurrency: string,
	},
	applicationDetail: ApplicationDetail,
	id: string,
	name: string,
};
export type Applications = Array<Application>
export type ApplicationSettings = {
	annualInterestRate: number,
	accrualPeriod: string,
	compoundPeriod: string,
}
export type ApplicationState = {
	applications: Applications,
	error: Object | boolean,
	isFetchingApplications: boolean,
	isFetchedApplications: boolean,
	applicationSettings: ApplicationSettings,
	isFetchingSettings: boolean,
	isFetchedSettings: boolean,
	isFetchingApplication: boolean,
	isFetchedApplication: boolean,
	application: Application,
	isUpdatingApplication: boolean,
	isUpdatedApplication: boolean,
	applicationCountries: Array<AppCountry>,
	isFetchingApplicationCountries: boolean,
	isFetchedApplicationCountries: boolean,
};
export type ApplicationsAction = {
	type:
	| 'APPLICATIONS_SUCCESS'
	| 'APPLICATIONS'
	| 'APPLICATIONS_FAIL',
	payload: {
		data: Array<Application>,
	},
	error: {
		code: number,
		message: string,
	}
}

export type ApplicationAction = {
	type:
	| 'APPLICATION_SUCCESS'
	| 'APPLICATION'
	| 'APPLICATION_FAIL'
	| 'UPDATE_APPLICATION'
	| 'UPDATE_APPLICATION_SUCCESS'
	| 'UPDATE_APPLICATION_FAIL',
	payload: {
		data: Application,
	},
	data: string,
	error: {
		code: number,
		message: string,
	}
}

export type ApplicationsSettingsAction = {
	type:
	| 'APPLICATIONS_SETTINGS_SUCCESS'
	| 'APPLICATIONS_SETTINGS'
	| 'APPLICATIONS_SETTINGS_FAIL'
	| 'UPDATE_APPLICATION_SETTINGS_SUCCESS'
	| 'UPDATE_APPLICATION_SETTINGS'
	| 'UPDATE_APPLICATION_SETTINGS_FAIL',
	payload: {
		data: ApplicationSettings,
	},
	error: {
		code: number,
		message: string,
	}
}

export type CountryAction = {
	type:
	| 'GET_APPLICATIONS_COUNTRIES'
	| 'GET_APPLICATIONS_COUNTRIES_SUCCESS'
	| 'GET_APPLICATIONS_COUNTRIES_FAIL',
	payload: {
		data: Array<Object>,
	},
	error: {
		code: number,
		message: string,
	}
}

export type Actions = ApplicationAction
| ApplicationsAction
| ApplicationsSettingsAction
| CountryAction;

export default function applicationReducer(
	state: ApplicationState = initialState,
	action: Actions,
): ApplicationState {
	switch (action.type) {
	case 'APPLICATIONS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingApplications: false,
			isFetchedApplications: true,
			applications: action.payload.data,
		};
	}
	case 'APPLICATIONS': {
		return {
			...state,
			isFetchingApplications: true,
			isFetchedApplications: false,
			error: false,
		};
	}
	case 'APPLICATIONS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingApplications: false,
			isFetchedApplications: false,
		};
	}
	case 'APPLICATIONS_SETTINGS': {
		return {
			...state,
			isFetchingSettings: true,
			isFetchedSettings: false,
		};
	}
	case 'APPLICATIONS_SETTINGS_SUCCESS': {
		return {
			...state,
			isFetchingSettings: false,
			isFetchedSettings: true,
			applicationSettings: action.payload.data,
		};
	}
	case 'APPLICATIONS_SETTINGS_FAIL': {
		return {
			...state,
			isFetchingSettings: false,
			isFetchedSettings: false,
			error: action.error,
		};
	}
	case 'APPLICATION_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingApplication: false,
			isFetchedApplication: true,
			application: action.payload.data,
		};
	}
	case 'APPLICATION': {
		return {
			...state,
			isFetchingApplication: true,
			isFetchedApplication: false,
			error: false,
		};
	}
	case 'APPLICATION_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingApplication: false,
			isFetchedApplication: false,
		};
	}
	case 'UPDATE_APPLICATION_SUCCESS': {
		return {
			...state,
			error: false,
			isUpdatingApplication: false,
			isUpdatedApplication: true,
			application: action.payload.data,
		};
	}
	case 'UPDATE_APPLICATION': {
		return {
			...state,
			isUpdatingApplication: true,
			isUpdatedApplication: false,
			error: false,
		};
	}
	case 'UPDATE_APPLICATION_FAIL': {
		return {
			...state,
			error: action.error,
			isUpdatingApplication: false,
			isUpdatedApplication: false,
		};
	}
	case 'UPDATE_APPLICATION_SETTINGS_SUCCESS': {
		return {
			...state,
			error: false,
			isUpdatingApplication: false,
			isUpdatedApplication: true,
			applicationSettings: action.payload.data,
		};
	}
	case 'UPDATE_APPLICATION_SETTINGS': {
		return {
			...state,
			isUpdatingApplication: true,
			isUpdatedApplication: false,
			error: false,
		};
	}
	case 'UPDATE_APPLICATION_SETTINGS_FAIL': {
		return {
			...state,
			error: action.error,
			isUpdatingApplication: false,
			isUpdatedApplication: false,
		};
	}
	case 'GET_APPLICATIONS_COUNTRIES': {
		return {
			...state,
			applicationCountries: [],
			isFetchingApplicationCountries: true,
			isFetchedApplicationCountries: false,
			error: false,
		};
	}
	case 'GET_APPLICATIONS_COUNTRIES_SUCCESS': {
		return {
			...state,
			error: false,
			applicationCountries: action.payload.data,
			isFetchingApplicationCountries: false,
			isFetchedApplicationCountries: true,
		};
	}
	case 'GET_APPLICATIONS_COUNTRIES_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingApplicationCountries: false,
			isFetchedApplicationCountries: false,
		};
	}
	default:
		return state;
	}
}
