// @flow

import React from 'react';
import map from 'lodash/map';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';

type Props = {
	data: {[string]: string},
	styles: {[string]: string},
}
const PnLCard = (props: Props) => {
	const {
		data, styles,
	} = props;

	return (
		<div className="pie-container" style={styles}>
			<div className="pie-card">
				<div className="legend pnl">
					{map(data, (value, key) => (
						<div key={key}>
							<div>
								<span className="info square" />
								<h6>{ActivityHelpers.transformToReadable(key)}</h6>
							</div>
							<span className="bold">{value}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PnLCard;
