// @flow

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import Tag from '../../../components/UiElements/Tag';
import Spin from '../../../components/UiElements/Spin';
import CustomTable from '../../../components/UiElements/CustomTable';
import CopyComponent from '../../../components/UiElements/CopyComponent';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Payment } from '../../../modules/reducers/CardsReducer';

type Props = {
	cardPayments: Array<Object>,
	totalCardPayments: number,
	pageSize: number,
	isFetchingCardPayments: boolean,
	applicationId: string,
	place: string,
	currentPage: number,
	handlePageChange: (number) => void,
}

function CardPaymentsList(props: Props) {
	const {
		cardPayments,
		totalCardPayments,
		isFetchingCardPayments,
		applicationId,
		currentPage,
		place,
		pageSize,
		handlePageChange,
	} = props;

	const infoColumns = [{
		title: 'created at',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 176,
		render: (text: string) => (
			<div className="wrap">
				{moment(text).format('YYYY-MM-DD HH:mm:ss')}
			</div>
		),
	}, {
		title: 'id',
		dataIndex: 'id',
		key: 'id',
		width: 340,
		render: (text: string) => (
			<CopyComponent
				text={text}
				content={(
					<Link
						to={`/application/${applicationId}/card_payments/${text}`}
					>
						{text}
					</Link>
				)}
			/>
		),
	}];

	const paymentsColumns = [{
		title: 'status',
		dataIndex: 'status',
		key: 'status',
		width: 176,
		render: (text: string) => (
			<Tag status={text} />
		),
	}, {
		title: 'original amount',
		dataIndex: 'originalAmount',
		key: 'originalAmount',
		width: 160,
		render: (text: string, record: Payment) => (
			<div>
				{PriceHelpers.formatAmount(
					PriceHelpers.formatNumber(text.toString(), record.originalCurrency.digits),
					record.originalCurrency.isoCode,
					Numbers.sign(text.toString()),
				)}
			</div>
		),
	}, {
		title: 'billing amount',
		dataIndex: 'billingAmount',
		key: 'billingAmount',
		width: 160,
		render: (text: string, record: Payment) => (
			<div>
				{PriceHelpers.formatAmount(
					PriceHelpers.formatNumber(text.toString(), record.billingCurrency.digits),
					record.billingCurrency.isoCode,
					Numbers.sign(text.toString()),
				)}
			</div>
		),
	}, {
		title: 'transaction type',
		dataIndex: 'transactionType.name',
		key: 'transactionType',
		width: 208,
		render: (text: string) => (
			<div className="wrap">
				{text}
			</div>
		),
	}, {
		title: 'transaction country',
		dataIndex: 'transactionCountry.name',
		key: 'transactionCountry',
		width: 240,
		render: (text: string) => (
			<div className="wrap">
				{text}
			</div>
		),
	}, {
		title: 'merchant name',
		dataIndex: 'mcc',
		key: 'merchantName',
		width: 340,
		render: (text: {[string]: string}) => (
			<div className="wrap">
				{text.description}
			</div>
		),
	}];
	const appPaymentsColumns = [{
		title: 'card id',
		dataIndex: 'cardId',
		key: 'cardId',
		width: 340,
		render: (text: string) => (
			<Link to={`/application/${applicationId}/cards/${text}`}>
				{text}
			</Link>

		),
	}];
	const userColumn = [{
		title: 'client',
		dataIndex: 'user',
		key: 'user',
		width: 208,
		render: (text: {firstName: string, lastName: string}, record: Payment) => (
			<Link
				to={`/application/${applicationId}/clients/${record.userPlatformId}`}
			>
				{`${text ? text.firstName : '-'} ${text ? text.lastName : ''}`}
			</Link>
		),
	}, {
		title: 'wallet Id',
		dataIndex: 'walletId',
		key: 'walletId',
		width: 288,
		render: (text: string, record: Payment) => (
			<Link
				to={`/application/${applicationId}/clients/${record.userPlatformId}/user_wallets/${text}`}
			>
				{text || '-'}
			</Link>
		),
	}];

	const columns = place === 'cardPage'
		? [...infoColumns, ...paymentsColumns]
		: [...infoColumns, ...appPaymentsColumns, ...paymentsColumns, ...userColumn];

	return (
		<>
			{!isFetchingCardPayments
				? (
					<CustomTable
						columns={columns}
						data={cardPayments}
						total={totalCardPayments}
						headerHeight={45}
						numberOnPage={pageSize}
						place={place}
						applicationId={applicationId}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
					/>
				)
				: <Spin spinning={isFetchingCardPayments} />}
		</>
	);
}

export default CardPaymentsList;
