// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import componnets
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { State } from '../../../modules/types/FlowTypes';

type Props = {
	getAppInstruments: (string, string, number, string) => void,
	instruments: Array<Instrument>,
	isFetchingInstruments: boolean,
	applicationId: string,
	currentPage: number,
	instrumentsTotal: number,
	setCurrentPage: (string, number) => void,
}
const DelistedInstruments = (props: Props) => {
	const {
		getAppInstruments: getAppInstrumentsAction,
		instruments,
		applicationId,
		isFetchingInstruments,
		currentPage,
		instrumentsTotal,
		setCurrentPage: setCurrentPageAction,
	} = props;

	useEffect(() => {
		const filters = { delisted: true };
		getAppInstrumentsAction(applicationId, 'numbered-pages', (currentPage - 1) * StatusHelpers.paginationLimit, queryString.stringify(filters));
	}, [getAppInstrumentsAction, applicationId, currentPage]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('delistedInstrumentsPage', page);
	};

	const columns = [
		{
			title: 'id',
			dataIndex: 'instrumentId',
			key: 'instrumentId',
			width: 96,
		},
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 208,
			render: (text: string) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'depositable',
			dataIndex: 'depositPaymentMethods',
			key: 'depositPaymentMethods',
			width: 256,
			render: (text: string, record: Instrument) => (
				<div>
					{record.depositPaymentMethods.length === 0
						? <span className="disabled-color">Not Depositable</span>
						: (
							<span>
								{record.depositPaymentMethods.map((method) => StatusHelpers.paymentMethods(method)).join(', ')}
							</span>
						)}
				</div>
			),
		},
		{
			title: 'withdrawable',
			dataIndex: 'withdrawalPaymentMethods',
			key: 'withdrawalPaymentMethods',
			width: 256,
			render: (text: string, record: Instrument) => (
				<div>
					{record.withdrawalPaymentMethods.length === 0
						? <span className="disabled-color">Not Withdrawable</span>
						: (
							<span>
								{record.withdrawalPaymentMethods.map((method) => StatusHelpers.paymentMethods(method)).join(', ')}
							</span>
						)}
				</div>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 112,
			render: (text: string) => (
				<div style={{ color: StatusHelpers.typeColor(text) }}>
					{StatusHelpers.typeName(text)}
				</div>
			),
		},
		{
			title: 'precision',
			dataIndex: 'precision',
			key: 'precision',
			width: 112,
		},
		{
			title: 'haircut',
			dataIndex: 'haircut',
			key: 'haircut',
			width: 112,
			render: (text: string) => (
				<div>
					{text || '-'}
				</div>
			),
		},
	];

	return (
		<div>
			{!isFetchingInstruments
				? (
					<CustomTable
						columns={columns}
						data={instruments}
						total={instrumentsTotal}
						handlePageChange={handlePageChange}
						currentPage={currentPage}
						headerHeight={16}
						place="delistedInstruments"
						applicationId={applicationId}
					/>
				)
				: <Spin spinning={isFetchingInstruments} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	isFetchingInstruments: state.instrument.isFetchingInstruments,
	instruments: state.instrument.instruments,
	instrumentsTotal: state.instrument.instrumentsTotal,
	currentPage: state.ui.delistedInstrumentsPage,
});

const mapDispatchToProps = {
	getAppInstruments,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DelistedInstruments);
