// @flow

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import componnets
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
//= import types
import type { DividendsCalendar } from '../../../modules/reducers/DividendsReducer';

type Props = {
	calendarIsFetching: boolean,
	dividendsCalendars: Array<DividendsCalendar>,
	applicationId: string,
	headerHeight: number,
}

const Calendar = (props: Props) => {
	const {
		calendarIsFetching,
		dividendsCalendars,
		applicationId,
		headerHeight,
	} = props;

	const columns = [
		{
			title: 'dividend instrument',
			dataIndex: 'dividendInstrumentId',
			key: 'dividendInstrumentId',
			width: 176,
			render: (text: string, record: DividendsCalendar) => (
				<Link
					to={`/application/${applicationId}/system/dividends/${record.id}`}
				>
					{text}
				</Link>
			),
		},
		{
			title: 'payout instrument',
			dataIndex: 'payoutInstrumentId',
			key: 'payoutInstrumentId',
			width: 176,
			render: (text: string) => <div>{text}</div>,
		},
		{
			title: 'price per share',
			dataIndex: 'grossAmount',
			key: 'grossAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendsCalendar) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.payoutInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'tax amount',
			dataIndex: 'taxAmount',
			key: 'taxAmount',
			align: 'right',
			width: 144,
			render: (text: string, record: DividendsCalendar) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.payoutInstrumentId,
					)}
				</div>
			),
		},
		{
			title: 'executed',
			dataIndex: 'isExecuted',
			key: 'isExecuted',
			width: 144,
			render: (text: string) => <div>{text ? 'Yes' : 'No'}</div>,
		},
		{
			title: 'announcement date',
			dataIndex: 'announcementDate',
			key: 'announcementDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'ex dividend date',
			dataIndex: 'exDividendDate',
			key: 'exDividendDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'record date',
			dataIndex: 'recordDate',
			key: 'recordDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
		{
			title: 'payout date',
			dataIndex: 'payoutDate',
			key: 'payoutDate',
			width: 176,
			render: (text: string) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
		},
	];

	return (
		<div>
			{!calendarIsFetching
				? (
					<CustomTable
						columns={columns}
						data={dividendsCalendars}
						total={dividendsCalendars.length}
						place="dividendsCalendars"
						applicationId={applicationId}
						headerHeight={headerHeight}
					/>
				) : <Spin spinning={!calendarIsFetching} />}
		</div>
	);
};

export default Calendar;
