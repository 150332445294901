// @flow
import uuidv4 from 'uuid/dist/v4';
import {
	USERS,
	FULL_USER,
	UPDATE_USER,
	KYC_REPORTS,
	SEARCH_USERS,
	USER_CURRENCY,
	USERS_SETTINGS,
	UPDATE_USERS_SETTINGS,
	CREATE_APPLICANT,
	TRIGGER_CHECK,
	SUSPEND_USER,
	UNSUSPEND_USER,
	GET_SUSPEND_REASONS,
	ADD_SUSPEND_REASON,
	GET_SUSPEND_REASON,
	UPDATE_SUSPEND_REASON,
	DELETE_SUSPEND_REASON,
	GET_SUSPENSIONS,
	UPLOAD_KYC_DOCUMENT,
	ONFIDO_SDK_TOKEN,
} from '../const/types';

export function getUsers(applicationId: string, page: number, pagLimit: number, parametar:string = '') {
	return (dispatch: Function): void => dispatch({
		type: USERS,
		payload: {
			request: {
				headers: {
					'pagination-limit': pagLimit,
					'pagination-offset': (page - 1) * pagLimit,
				},
				url: `applications/${applicationId}/users?${parametar}`,
			},
		},
	});
}

export function getUser(userId: string) {
	return (dispatch: Function): void => dispatch({
		type: FULL_USER,
		payload: {
			request: {
				url: `users/${userId}`,
			},
		},
	});
}

export function updateUser(userId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_USER,
		payload: {
			request: {
				method: 'PUT',
				url: `users/${userId}`,
				data,
			},
		},
	});
}

export function getUserSettings(userId: string) {
	return (dispatch: Function): void => dispatch({
		type: USERS_SETTINGS,
		payload: {
			request: {
				url: `users/${userId}/settings`,
			},
		},
	});
}

export function updateUserSettings(userId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_USERS_SETTINGS,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}/settings`,
				data,
			},
		},
	});
}

export function getKycReports(appId: string, page: number, param: string, pageSize: number = 50) {
	return (dispatch: Function) => dispatch({
		type: KYC_REPORTS,
		payload: {
			request: {
				url: `applications/${appId}/kyc-reports?${param}`,
				headers: {
					'pagination-limit': pageSize,
					'pagination-offset': (page - 1) * pageSize,
				},
			},
		},
	});
}

export function getOnfidoSDKToken(userId: string): object {
	return (dispatch: Function) => dispatch({
		type: ONFIDO_SDK_TOKEN,
		payload: {
			request: {
				url: `users/${userId}/onfido-sdk-token`,
				method: 'POST',
				data: {
					referrer: `${process.env.REACT_APP_ONFIDO_WEB_SDK_URL}*`,
				},
			},
		},
	});
}

export function createApplicant(userId: string, providerName: string) {
	const data = {
		idempotenceId: uuidv4(),
	};
	return (dispatch: Function) => dispatch({
		type: CREATE_APPLICANT,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}/applicant/${providerName}`,
				data,
			},
		},
	});
}

export function uploadKycDocument(
	userId: string,
	providerName: string,
	checkType: string,
	data: {[string]: string},
) {
	return (dispatch: Function) => dispatch({
		type: UPLOAD_KYC_DOCUMENT,
		payload: {
			request: {
				method: 'POST',
				url: `/users/${userId}/kyc-documents/${providerName}/${checkType}`,
				data,
			},
		},
	});
}

export function triggerCheck(userId: string, providerName: string, checkType: string, reportId: string) {
	return (dispatch: Function) => dispatch({
		type: TRIGGER_CHECK,
		payload: {
			request: {
				method: 'POST',
				url: `/users/${userId}/kyc-reports/${providerName}/${checkType}${reportId ? `?reportId=${reportId}` : ''}`,
			},
		},
	});
}

export function searchUsers(applicationId: string, keyword: string) {
	return (dispatch: Function) => dispatch({
		type: SEARCH_USERS,
		payload: {
			request: {
				url: `applications/${applicationId}/search/users/${keyword}`,
			},
		},
	});
}

export function setUserReportingCurrency(currency: string) {
	return (dispatch: Function): void => dispatch({
		type: USER_CURRENCY,
		data: {
			currency,
		},
	});
}

export function suspendUser(applicationId: string, userId: string, data: Object) {
	return (dispatch: Function) => dispatch({
		type: SUSPEND_USER,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/users/${userId}/suspend`,
				data,
			},
		},
	});
}

export function unsuspendUser(applicationId: string, userId: string) {
	return (dispatch: Function) => dispatch({
		type: UNSUSPEND_USER,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/users/${userId}/unsuspend`,
			},
		},
	});
}

export function getSuspendReasons(applicationId: string) {
	return (dispatch: Function) => dispatch({
		type: GET_SUSPEND_REASONS,
		payload: {
			request: {
				url: `/applications/${applicationId}/suspend-reasons`,
			},
		},
	});
}

export function addSuspendReason(applicationId: string, data: Object) {
	return (dispatch: Function) => dispatch({
		type: ADD_SUSPEND_REASON,
		payload: {
			request: {
				method: 'POST',
				url: `/applications/${applicationId}/suspend-reasons`,
				data,
			},
		},
	});
}

export function getSuspendReason(applicationId: string, suspendReasonId: string) {
	return (dispatch: Function) => dispatch({
		type: GET_SUSPEND_REASON,
		payload: {
			request: {
				url: `/applications/${applicationId}/suspend-reasons/${suspendReasonId}`,
			},
		},
	});
}

export function updateSuspendReason(applicationId: string, suspendReasonId: string, data: Object) {
	return (dispatch: Function) => dispatch({
		type: UPDATE_SUSPEND_REASON,
		payload: {
			request: {
				method: 'PATCH',
				url: `/applications/${applicationId}/suspend-reasons/${suspendReasonId}`,
				data,
			},
		},
	});
}

export function deleteSuspendReason(applicationId: string, suspendReasonId: string) {
	return (dispatch: Function) => dispatch({
		type: DELETE_SUSPEND_REASON,
		payload: {
			request: {
				method: 'DELETE',
				url: `/applications/${applicationId}/suspend-reasons/${suspendReasonId}`,
			},
		},
	});
}

export function getSuspensions(applicationId: string, reasonId: string) {
	return (dispatch: Function) => dispatch({
		type: GET_SUSPENSIONS,
		payload: {
			request: {
				url: `/applications/${applicationId}/suspensions?suspendReasonId=${reasonId}`,
			},
		},
	});
}
