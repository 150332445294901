
// @flow

import {
	ACCOUNT_MARGIN,
	RISKIEST_BORROWERS,
	UPDATE_USERS_MARGIN_INTEREST,
	ACCOUNT_MARGIN_INTEREST,
	ACCOUNT_MARGIN_CHARGES,
} from '../const/types';

export function updateUserMarginInterest(userId: string, data: Object) {
	return (dispatch: Function): void => dispatch({
		type: UPDATE_USERS_MARGIN_INTEREST,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}/settings/margin-interest`,
				data,
			},
		},
	});
}

export function getAccountMargin(accountId: string) {
	return (dispatch: Function) => dispatch({
		type: ACCOUNT_MARGIN,
		payload: {
			request: {
				url: `/accounts/${accountId}/margin-info`,
			},
		},
	});
}

export function getAccountMarginInterest(accountId: string) {
	return (dispatch: Function) => dispatch({
		type: ACCOUNT_MARGIN_INTEREST,
		payload: {
			request: {
				url: `/accounts/${accountId}/margin-interest`,
			},
		},
	});
}

export function getAccountMarginCharges(accountId: string, page: number, pagLimit: number = 50) {
	return (dispatch: Function) => dispatch({
		type: ACCOUNT_MARGIN_CHARGES,
		payload: {
			request: {
				headers: {
					'pagination-limit': pagLimit,
					'pagination-offset': (page - 1) * pagLimit,
				},
				url: `/accounts/${accountId}/margin-charges`,
			},
		},
	});
}

export function getRiskiestBorrowers(applicationId: string) {
	return (dispatch: Function) => dispatch({
		type: RISKIEST_BORROWERS,
		payload: {
			request: {
				url: `/applications/${applicationId}/accounts/riskiest-borrowers`,
			},
		},
	});
}
