// @flow

import currencies from 'world-currencies';
import Numbers from './numbersHelpers';

// = import types
import type { Account } from '../../modules/reducers/AccountsReducer';
import type { Wallet } from '../../modules/reducers/WalletReducer';
import type { Rates } from '../../modules/reducers/StaticReducer';

function getNumber(amount: string, precision: number, rounding?: string) {
	if (precision === null || precision === undefined) {
		return amount;
	}
	if (rounding) {
		const formatedNumber = rounding === 'floor' ? Numbers.floor(amount, precision) : Numbers.ceil(amount, precision);
		return formatedNumber;
	}
	const formatedNumber = Numbers.fix(amount, precision);
	return formatedNumber;
}

function getAmount(amount: string, currencyCode: string, showSign: boolean = false): string {
	if (!amount || amount === '-') {
		return '-';
	}

	let stringAmount;
	if (typeof amount !== 'string') {
		stringAmount = amount.toString();
	} else {
		stringAmount = amount;
	}

	const currency = currencies[currencyCode];
	const sign: string = stringAmount.startsWith('-') ? '-' : '+';
	const absoluteValue = stringAmount.replace('-', '').replace('+', '');

	const decimalPoint = absoluteValue.lastIndexOf('.');
	let decimalPart = decimalPoint > 0 ? absoluteValue.substr(decimalPoint) : '';
	const numberParts = decimalPoint > 0 ? absoluteValue.substr(0, decimalPoint) : absoluteValue;
	const scientificNotation = decimalPart.toLowerCase().includes('e') || numberParts.toLowerCase().includes('e');
	if (scientificNotation) {
		const e = decimalPart.lastIndexOf('e');
		const exponent = decimalPart.substr(e);
		decimalPart = exponent || decimalPart;
	}
	const firstPartFormated = scientificNotation ? numberParts : numberParts.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	const renderSign = (showsign: boolean) => {
		if (sign === '-') {
			return sign;
		} if (showsign) {
			return sign;
		}
		return '';
	}

	const moneyFormated = `${firstPartFormated}${decimalPart}`;
	if (currency) {
		return `${renderSign(showSign)}${currency.units.major.symbol}${moneyFormated}`;
	}
	return `${renderSign(showSign)}${moneyFormated} ${currencyCode}`;
}

function getWalletsSum(
	params: string,
	wallets: Array<Wallet>,
	reportingCurrency: string,
	rates: Rates,
	precision: number = 2,
) {
	let total: string = '0';
	let rate;
	for (let i = 0; i < wallets.length; i += 1) {
		if (!rates[`${reportingCurrency}${wallets[i].instrumentId}`]) {
			total = '-';
			break;
		}
		if ((wallets[i].instrumentId !== reportingCurrency) && !rates[`${reportingCurrency}${wallets[i].instrumentId}`].bid) {
			if (wallets[i].delisted) {
				rate = '0';
			} else {
				total = '-';
				break;
			}
		} else {
			rate = (wallets[i].instrumentId === reportingCurrency)
				? '1'
				: 1 / (rates[`${reportingCurrency}${wallets[i].instrumentId}`].bid || 1);
			const value: string = wallets[i][params];
			const valueInRate: string = Numbers.mul(value, rate.toString());
			const valueToPrecision: string = getNumber(valueInRate, precision);
			total = Numbers.add(total, valueToPrecision);
		}
	}
	return total;
}

class PriceHelpers {
	// format number with precision

	static formatNumber(amount: string, precision: number, rounding?: string): string {
		return getNumber(amount, precision, rounding);
	}

	// format number with currency

	static formatAmount(amount: string, currencyCode: string, showSign: boolean = false): string {
		return getAmount(amount, currencyCode, showSign);
	}

	// total account value

	static getAccountValue(
		params: string,
		wallets: Array<Object>,
		reportingCurrency: string,
		rates: Rates,
		precision: number = 2,
	) {
		const total = getWalletsSum(
			params,
			wallets,
			reportingCurrency,
			rates,
		);
		const result = total === '-' ? '-' : getAmount(
			getNumber(total, precision),
			reportingCurrency,
			Numbers.sign(total),
		);
		return result;
	}

	// total profil value

	static getTotalValue(
		params: string,
		accounts: Array<Account>,
		reportingCurrency: string,
		rates: Rates,
		precision: number = 2,
	) {
		let total: string = '0';
		for (let i = 0; i < accounts.length; i += 1) {
			const value = getWalletsSum(params, accounts[i].wallets, reportingCurrency, rates);
			if (value === '-') {
				total = value;
				break;
			} else {
				total = Numbers.add(total, value);
			}
		}
		const result = total === '-' ? '-' : getAmount(
			getNumber(total, precision),
			reportingCurrency,
			Numbers.sign(total),
		);
		return result;
	}

	static convertToCurrency(
		value: string,
		sourceCurrency: string,
		currency: string,
		rates: Rates,
		precision: number = 2,
		delisted: boolean = false,
	) {
		let exchangeRates;
		if (sourceCurrency === currency) {
			exchangeRates = '1';
		} else if (!Object.keys(rates[`${sourceCurrency}${currency}`]).length || !rates[`${sourceCurrency}${currency}`].bid) {
			if (delisted) {
				exchangeRates = '0';
			} else { return '-'; }
		} else {
			exchangeRates = (1 / rates[`${sourceCurrency}${currency}`].bid).toString();
		}

		const newValue = Numbers.mul(exchangeRates, value);
		return getAmount(
			getNumber(newValue, precision),
			sourceCurrency,
			Numbers.sign(value),
		);
	}

	static priceDiff(
		executionPrice: string,
		reservationPrice: string,
		side: string,
		precision: number,
	) {
		const sign = (side === 'buy' && Numbers.greaterThan(reservationPrice, executionPrice))
		|| (side === 'sell' && Numbers.greaterThan(executionPrice, reservationPrice))
			? '+'
			: '-';
		const value = Numbers.abs(Numbers.sub(executionPrice, reservationPrice));
		return getNumber(`${sign}${value}`, precision);
	}
}

export default PriceHelpers;
