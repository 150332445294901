// @flow

import React, {
	useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//= import components
import CPButton from '../../../components/UiElements/Button';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import Spin from '../../../components/UiElements/Spin';
import Title from '../../../components/UiElements/Title';
import Drawer from '../../../components/UiElements/Drawer';
import List from '../../../components/UiElements/List';
import Card from '../../../components/UiElements/Card';
import Checkbox from '../../../components/UiElements/Checkbox';
import DesignCard from '../components/DesignCards';
import LimitControls from '../components/LimitControls';
import ControlComponent from '../components/ControlComponent';
//= import actions
import {
	getProgram,
	getCardDesign,
	getAuthorizationControls,
	getLimitControls,
	getMerchantScopes,
} from '../../../modules/actions/CardAction';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Program, CardDesign, LimitControlType } from '../../../modules/reducers/CardsReducer';
//= import images
import master from '../../../assets/img/cards/master.png';
import visa from '../../../assets/img/cards/visa.png';
import bcLogo from '../../../assets/img/company_logo.svg';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	program: Program,
	isFetchingProgram: boolean,
	cardDesign: Array<CardDesign>,
	getProgram: (string, string) => void,
	getCardDesign: (string, string) => void,
	getAuthorizationControls: (string) => void,
	getLimitControls: (string) => void,
	getMerchantScopes: (string) => void,
	match: {
		params: {
			applicationId: string,
			programId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
	},
}

function ProgramPage(props: Props) {
	const {
		program,
		isFetchingProgram,
		match: { params: { applicationId, programId } },
		getProgram: getProgramAction,
		getCardDesign: getCardDesignAction,
		getAuthorizationControls: getAuthorizationControlsAction,
		getLimitControls: getLimitControlsAction,
		getMerchantScopes: getMerchantScopesAction,
		cardDesign,
	} = props;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [drawerData, setDrawerData] = useState({});

	useEffect(() => {
		getProgramAction(applicationId, programId);
		getCardDesignAction(applicationId, programId);
		getAuthorizationControlsAction(applicationId);
		getLimitControlsAction(applicationId);
		getMerchantScopesAction(applicationId);
	}, [
		applicationId,
		programId,
		getProgramAction,
		getCardDesignAction,
		getAuthorizationControlsAction,
		getLimitControlsAction,
		getMerchantScopesAction,
	]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleOpenDrawer = (limitControl: LimitControlType) => {
		setDrawerOpen(true);
		const data = {
			name: 'Limit Control',
			details: [
				{ key: 'Amount Limit', value: limitControl.amountLimit },
				{ key: 'Limit Currency', value: limitControl.currency.name },
				{ key: 'Usage Limit', value: limitControl.usageLimit },
				{ key: 'Velocity Window', value: limitControl.velocityWindow },
			],
			control: limitControl,
		};
		setDrawerData(data);
	};

	const onClose = () => {
		setDrawerOpen(false);
	};

	const handleCreateClick = () => {
		props.history.push(`/application/${applicationId}/system/card/create_product?programId=${program.id}`);
	};

	const imageMap = {
		MasterCard: master,
		Visa: visa,
	};

	const detailsData = program.name ? [
		{ key: 'Id', value: program.id },
		{ key: 'Name', value: program.name },
		{ key: 'Card Scheme', value: program.cardScheme },
		{ key: 'Card Type', value: program.cardType },
		{ key: 'Card Purpose', value: program.cardPurpose },
		{ key: 'Description', value: program.description },
		{ key: 'Default Currency', value: program.defaultCurrency.name },
		{ key: 'Currencies', value: program.currencies.map((el) => el.name).join(', ') },
		{ key: 'Countries', value: program.countries.map((el) => el.name).join(', ') },
		{ key: 'Bin Type', value: program.binType },
		{ key: 'Card Embossing Strategy', value: program.cardEmbossingStrategy },
		{ key: 'Card Validity Period In Months', value: program.cardValidityPeriodInMonths },
		{ key: 'Loop Type', value: program.loopType },
		{ key: 'Max Cards Per Client', value: program.maxCardsPerUser },
		{ key: 'Delivery Methods', value: program.deliveryMethods.map((el) => el.name).join(', ') },
		{ key: 'Product Ids', value: program.productIds.join(', ') || '-' },
		{ key: 'Created At', value: moment(program.createdAt).format('YYYY-MM-DD HH:mm:ss') },
		{ key: 'Updated At', value: moment(program.updatedAt).format('YYYY-MM-DD HH:mm:ss') },
	] : [];
	const checkData = program.name ? [
		{ key: 'External Wallets Enabled', value: program.externalWalletsEnabled },
		{ key: 'Auto Conversion Enabled', value: program.autoConversionEnabled },
		{ key: 'Multi Currency Enabled', value: program.multiCurrencyEnabled },
		{ key: 'Physical Cards Enabled', value: program.physicalCardsEnabled },
		{ key: 'Three Domain Secure Enabled', value: program.threeDomainSecureEnabled },
		{ key: 'Virtual Cards Enabled', value: program.virtualCardsEnabled },
	] : [];
	const controlData = program.authorizationControl ? {
		name: 'Authorization Control',
		control: program.authorizationControl,
	} : {};

	return (
		<>
			{!isFetchingProgram ? (
				<>
					<Title
						backAction={handleBackClick}
						title={program.name}
						place="program"
						buttons={[
							{
								text: 'create card product',
								icon: 'Plus',
								action: handleCreateClick,
							},
						]}
					>
						{imageMap[program.cardScheme]
							? <img src={imageMap[program.cardScheme]} className={styles.titleImg} alt="bc" />
							: <img src={bcLogo} className={styles.titleImg} alt="bc" />}
					</Title>
					<div className="page-container">
						<Row gutter={24}>
							<Col span={14}>
								<Card bordered={false} className={styles.productCard}>
									<h2>Program Details</h2>
									<div className={styles.list}>
										<List
											itemLayout="horizontal"
											dataSource={detailsData}
											split={false}
											renderItem={
												(item: {[string]: string}) => (
													<List.Item key={item.key}>
														<List.Item.Meta
															title={item.key}
														/>
														<div className={styles.listValue}>{item.value}</div>
													</List.Item>
												)
											}
										/>
									</div>
									<div className={styles.checkGroup}>
										{checkData.map((el) => (
											<Checkbox
												key={el.key}
												checked={el.value}
											>
												{el.key}
											</Checkbox>
										))}
									</div>
								</Card>
							</Col>
							<Col span={10}>
								<DesignCard cardDesign={cardDesign} />
							</Col>
						</Row>
						{!!program.authorizationControl
						&& (
							<Row span={12} className="col-margin">
								<Card bordered={false}>
									<ControlComponent controlData={controlData} />
								</Card>
							</Row>
						)}
						{!!program.limitControls
						&& (
							<Row>
								<LimitControls
									limitControlsData={program.limitControls}
									title="Program Limit Controls"
									applicationId={applicationId}
									handleOpenDrawer={handleOpenDrawer}
									addLimitControl={false}
								/>
							</Row>
						)}
					</div>
				</>
			) : <Spin spinning={isFetchingProgram} />}
			<Drawer
				width={600}
				placement="right"
				onClose={onClose}
				visible={drawerOpen}
				closable={false}
			>
				<ControlComponent controlData={drawerData} />
				<div className={styles.close}>
					<CPButton
						text="close"
						action={onClose}
					/>
				</div>
			</Drawer>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	program: state.cards.program,
	isFetchingProgram: state.cards.isFetchingProgram,
	cardDesign: state.cards.cardDesign,
});
const mapDispatchToProps = {
	getProgram,
	getCardDesign,
	getAuthorizationControls,
	getLimitControls,
	getMerchantScopes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramPage);
