// @flow

import { createSelector } from 'reselect';
import moment from 'moment';
//= import helpers
import PriceHelpers from '../../lib/helpers/priceHelpers';
import Numbers from '../../lib/helpers/numbersHelpers';
//= import types
import type { State } from '../types/FlowTypes';
import type { Order, WalletOrderTransaction, FullOrder } from '../reducers/OrderReducer';
import type { Wallet } from '../reducers/WalletReducer';

export type MappedWalletTransactions = WalletOrderTransaction & {
	time: string,
	timeUpdated: string,
	transactionId: string,
	amountFormatted: string,
	availableFormatted: string,
	reservedFormatted: string,
	totalFormatted: string,
	instrumentId: string,
}
export type FormattedOrder = FullOrder & {
	createdAt: string,
	placedOnExchangeAt: string,
	executedAt: string,
	commandFormatted: {
		quantity: string,
		amount: string,
		id: string,
		price: string,
		expectedCost: string,
	},
	reservationFormatted: {
		quantity: string,
		price: string,
		expectedCost: string,
	},
	summaryFormatted: {
		averageTransactionPrice: string,
		orderAmount: string,
		orderFee: string,
		quantityExecuted: string,
	},
	mappedWalletTransactions: Array<MappedWalletTransactions>,
	mappedOrders: Array<Object>,
	logTime: string,
}

export type FormattedOrders = Order & {
	orderCommandQuantity: string,
	orderReservationQuantity: string,
	orderReservationAmount: string,
	orderExecutedQuantity: string,
	orderCommandPrice: string,
	orderReservationPrice: string,
	averagePurchasePrice: string,
	priceDiff: string,
	timeDiff: string,
}

const orderSelector = (state: State): Order => state.orders.order;

export const walletsSelector = (state: State): Array<Wallet> => state.wallet.wallets;

export const ordersFormattedSelector = (state: State, place: string) => (
	state.orders[place].map((order: Order): FormattedOrders => ({
		...order,
		orderCommandQuantity: order.orderCommand
			? PriceHelpers.formatAmount(
				order.orderCommand.quantity,
				order.baseInstrumentId,
			) : '-',
		orderReservationQuantity: order.reservationInfo
			? PriceHelpers.formatAmount(
				order.reservationInfo.quantity,
				order.baseInstrumentId,
			) : '-',
		orderReservationAmount: order.reservationInfo
			? PriceHelpers.formatAmount(
				PriceHelpers.formatNumber(
					Numbers.mul(order.reservationInfo.price, order.reservationInfo.quantity),
					2,
					5,
				),
				order.quotingInstrumentId,
			) : '-',
		orderExecutedQuantity: PriceHelpers.formatAmount(
			order.executionSummary.quantityExecuted,
			order.baseInstrumentId,
		),
		orderExecutedFee: PriceHelpers.formatAmount(
			order.executionSummary.orderFee
				? PriceHelpers.formatNumber(
					order.executionSummary.orderFee,
					order.orderCommand?.pricePrecision,
				) : '-',
			order.quotingInstrumentId,
		),
		orderCommandPrice: order.orderCommand && Number(order.orderCommand.price) !== 0
			? PriceHelpers.formatAmount(
				order.orderCommand.price,
				order.quotingInstrumentId,
			) : '-',
		orderReservationPrice: order.reservationInfo
			? PriceHelpers.formatAmount(
				PriceHelpers.formatNumber(
					order.reservationInfo.price,
					order.orderCommand?.pricePrecision,
				),
				order.quotingInstrumentId,
			) : '-',
		averagePurchasePrice: order.executionSummary.averageTransactionPrice && Number(order.executionSummary.averageTransactionPrice) !== 0
			? PriceHelpers.formatAmount(
				order.executionSummary.averageTransactionPrice,
				order.quotingInstrumentId,
			) : '-',
		priceDiff: order.reservationInfo
			&& Number(order.executionSummary.averageTransactionPrice) !== 0
			&& order.executionSummary.averageTransactionPrice !== order.reservationInfo.price
			? PriceHelpers.formatAmount(
				PriceHelpers.priceDiff(
					order.executionSummary.averageTransactionPrice,
					order.reservationInfo.price,
					order.side,
					order.orderCommand.pricePrecision,
				),
				order.quotingInstrumentId,
				true,
			)
			: '-',
		timeDiff: order.createdAt && order.executedAt
			? `${moment(order.executedAt).diff(moment(order.createdAt), 'milliseconds')}ms`
			: '-',
	}))
);

export const orderFormattedSelector = createSelector(
	orderSelector,
	(order: FullOrder): FormattedOrder => (Object.entries(order).length ? ({
		...order,
		createdAt: moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
		placedOnExchangeAt: order.placedOnExchangeAt ? moment(order.placedOnExchangeAt).format('YYYY-MM-DD HH:mm:ss.SSS') : '-',
		executedAt: order.executedAt ? moment(order.executedAt).format('YYYY-MM-DD HH:mm:ss.SSS') : '-',
		commandFormatted: {
			quantity: order.orderCommand.quantity !== '0'
				? PriceHelpers.formatAmount(
					order.orderCommand.quantity,
					order.baseInstrumentId,
				)
				: '-',
			amount: order.orderCommand
				? PriceHelpers.formatAmount(
					order.orderCommand.amount,
					order.wallet?.instrumentId,
				)
				: '-',
			price: order.orderCommand
				? PriceHelpers.formatAmount(
					order.orderCommand.price,
					order.quotingInstrumentId,
				)
				: '-',
			expectedCost: order.orderCommand.quantity === '0'
				? PriceHelpers.formatAmount(
					order.orderCommand.amount,
					order.quotingInstrumentId,
				)
				: PriceHelpers.formatAmount(
					Numbers.mul(order.orderCommand.price, order.orderCommand.quantity),
					order.quotingInstrumentId,
				),
			id: order.orderCommand ? order.orderCommand.id : '-',
		},
		reservationFormatted: {
			quantity: PriceHelpers.formatAmount(
				order.reservationInfo.quantity,
				order.baseInstrumentId,
			),
			price: PriceHelpers.formatAmount(
				order.reservationInfo.price,
				order.quotingInstrumentId,
			),
			expectedCost: PriceHelpers.formatAmount(
				Numbers.mul(order.reservationInfo.price, order.reservationInfo.quantity),
				order.quotingInstrumentId,
			),
		},
		mappedOrders: order.orderTransactions.length
			? order.orderTransactions.map((transaction) => ({
				...transaction,
				quantity: order.orderCommand
					? PriceHelpers.formatAmount(
						transaction.quantity,
						order.baseInstrumentId,
					)
					: '-',
				price: order.orderCommand
					? PriceHelpers.formatAmount(
						transaction.price,
						order.quotingInstrumentId,
					)
					: '-',
				cost: PriceHelpers.formatAmount(
					Numbers.mul(transaction.quantity, transaction.price),
					order.quotingInstrumentId,
				),
				time: moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
				timeUpdated: moment(transaction.updatedAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
			}))
			: [],
		mappedWalletTransactions: order.walletTransactions && order.walletTransactions.length
			? order.walletTransactions.map((transaction) => ({
				...transaction,
				time: moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
				timeUpdated: moment(transaction.updatedAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
				transactionId: transaction.id,
				amountFormatted: PriceHelpers.formatAmount(
					transaction.amount,
					transaction.instrumentId,
					true,
				),
				availableFormatted: PriceHelpers.formatAmount(
					transaction.available,
					transaction.instrumentId,
					Numbers.sign(transaction.available),
				),
				reservedFormatted: PriceHelpers.formatAmount(
					transaction.reserved,
					transaction.instrumentId,
					Numbers.sign(transaction.reserved),
				),
				totalFormatted: PriceHelpers.formatAmount(
					transaction.total,
					transaction.instrumentId,
					Numbers.sign(transaction.total),
				),
			}))
			: [],
		summaryFormatted: {
			averageTransactionPrice: PriceHelpers.formatAmount(
				order.executionSummary.averageTransactionPrice,
				order.quotingInstrumentId,
			),
			orderAmount: PriceHelpers.formatAmount(
				order.executionSummary.orderAmount,
				order.quotingInstrumentId,
			),
			orderFee: PriceHelpers.formatAmount(
				order.executionSummary.orderFee,
				order.quotingInstrumentId,
			),
			quantityExecuted: PriceHelpers.formatAmount(
				order.executionSummary.quantityExecuted,
				order.baseInstrumentId,
			),
		},
		logTime: order.providerLogs?.[0] ? moment(order.providerLogs[0]?.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS') : '',
	}) : {}),
);
