// @flow

import React from 'react';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import components
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import EmptyWallet from '../../../components/UiElements/Illustrations/EmptyWallet';
//= types
import type { WalletTransaction } from '../../../modules/reducers/PaymentReducer';
import styles from '../../../components/UiElements/CustomCards/assets/card.module.scss';

type Props = {
	wallets: Array<WalletTransaction>,
	userId: string,
	applicationId: string,
	status: string,
	instrumentId: string,
}

const AffectedWalletCard = (props: Props) => {
	const {
		wallets, applicationId, userId, status, instrumentId,
	} = props;

	const walletInstrumentId:string = wallets[0]?.meta?.paymentInstrument?.instrumentId || instrumentId;

	const dataSource = wallets.length ? [{
		key: 'reserved',
		value: PriceHelpers.formatAmount(
			wallets.reduce((acc, curr) => (Numbers.add(acc, (curr.type === 'reservation' ? curr.amount : '0'))), '0'),
			walletInstrumentId,
			true,
		),
		color: Numbers.sign(wallets.reduce((acc, curr) => (Numbers.add(acc, (curr.type === 'reservation' ? curr.amount : '0'))), '0'))
			? 'red-text'
			: 'text',
	}, {
		key: 'executed',
		value: PriceHelpers.formatAmount(
			wallets.reduce((acc, curr) => (Numbers.add(
				acc,
				(curr.type === 'transaction' || curr.type === 'internalTransfer' || curr.type === 'fee' ? curr.amount : '0'),
			)), '0'),
			walletInstrumentId,
			true,
		),
		color: Numbers.sign(wallets.reduce((acc, curr) => (Numbers.add(
			acc,
			(curr.type === 'transaction' || curr.type === 'internalTransfer' || curr.type === 'fee' ? curr.amount : '0'),
		)), '0'))
			? 'red-text'
			: 'text',
	}, {
		key: 'newBalance',
		value: PriceHelpers.formatAmount(
			wallets[0]?.available || '',
			walletInstrumentId,
			Numbers.sign(wallets[0].available),
		),
		color: Numbers.sign(wallets[0].available)
			? 'red-text'
			: 'green-text',
	},
	] : [];

	const affectedWalletData = wallets.length ? [
		{
			title: 'Affected Wallets',
			component: (
				<a
					href={`/application/${applicationId}/clients/${userId}/user_wallets/${wallets[0].walletId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className="button-text">{`${wallets[0]?.meta?.paymentInstrument?.instrumentId || instrumentId} Wallet`}</span>
				</a>
			),
		}, {
			component: wallets.map((wallet) => (
				<div className={styles.data} key={wallet.id}>
					<div className={styles.key}>{`${wallet.reference || ''} ${wallet.type || ''}`}</div>
					<div className={Numbers.sign(wallet.amount) ? 'red-text' : styles.value}>
						{PriceHelpers.formatAmount(
							wallet?.amount || '',
							walletInstrumentId,
							true,
						)}
					</div>
				</div>
			)),
		}, {
			component: dataSource.map((el) => (
				<div className={styles.data} key={el.key}>
					<div className={styles.key}>{el.key}</div>
					<div className={el.color}>
						{el.value}
					</div>
				</div>
			)),
		},
	] : [{
		title: 'Affected Wallets',
		component: (
			<div className="empty-state">
				<EmptyWallet />
				<p className="part">Payment is {status}, so no wallets have been affected.</p>
			</div>),
	}];

	return (
		<DataCard parts={affectedWalletData} />
	);
};

export default AffectedWalletCard;
