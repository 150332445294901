// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
//= import components
import Title from '../../../components/UiElements/Title';
import CustomTable from '../../../components/UiElements/CustomTable';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import AddSubscriptionForm from '../components/SubscriptionForm';
//= import actions
import {
	getEventSubscriptions,
	addEventSubscriptions,
} from '../../../modules/actions/EventSubscriptionAction';
//= import types
import type { EventSubscription } from '../../../modules/reducers/EventSubscriptionReducer';

type Props = {
	match: {
		params: {
			applicationId: string,
		}
	},
	history: {
		push: (Object) => void,
		goBack: () => void,
	},
	eventSubscriptions: Array<EventSubscription>,
	getEventSubscriptions: () => void,
	addEventSubscriptions: (Object) => void,
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

function EventSubscriptions(props: Props) {
	const {
		match: { params: { applicationId } },
		getEventSubscriptions: getEventSubscriptionsAction,
		addEventSubscriptions: addEventSubscriptionsAction,
		eventSubscriptions,
	} = props;

	const [add, setAdd] = useState(false);
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [subscriptionAdded, setSubscriptionAdded] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getEventSubscriptionsAction();
	}, [getEventSubscriptionsAction, applicationId, subscriptionAdded]);

	const handleBackClick = () => {
		props.history.goBack();
	};

	const handleAddNew = () => {
		setAdd(true);
	};

	const formRef = useRef(null);

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setAdd(false);
		setWarning(false);
	};

	const handleWarningCancel = () => {
		setAdd(true);
		setWarning(false);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setAdd(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setAdd(false);
			setWarning(false);
		}
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setAdd(false);
		setWarning(true);
		setWarningTitle('Error!');
		setMessage({
			firstPart: error.error.response.data.message,
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitAddSubscription = async (data) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		const subscriptionData = {
			name: data.name,
			applicationId,
		};
		try {
			await addEventSubscriptionsAction(subscriptionData);
			setButtonLoading(false);
			setSubscriptionAdded(!subscriptionAdded);
			setWarning(false);
		} catch (error) { handleOnError(error); }
	};

	const continueAdding = (value) => {
		setWarning(true);
		setAdd(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect Subscriptions.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitAddSubscription(value), text: 'add events subscription' },
		]);
	};

	const columns = [
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
			width: 208,
			render: (text: String, record: EventSubscription) => (
				<Link
					to={`/application/${applicationId}/system/event_subscriptions/${record.id}`}
				>
					{text}
				</Link>
			),
		},
		{
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 160,
			render: (text: string) => (
				<div className="capitalize">
					{text}
				</div>
			),
		},
		{
			title: 'active',
			dataIndex: 'active',
			key: 'active',
			width: 160,
			render: (text: boolean) => (
				<div>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 208,
			render: (text: string) => (
				<div className="capitalize">
					{moment(text).format('YYYY-MM-DD')}
				</div>
			),
		},
	];

	return (
		<>
			<Title
				backAction={handleBackClick}
				title="Event Subscriptions"
				buttons={[
					{
						text: 'add new subscription',
						icon: 'Plus',
						action: handleAddNew,
					},
				]}
			/>
			<div className="container">
				<CustomTable
					columns={columns}
					data={eventSubscriptions}
					total={eventSubscriptions.length}
					currentPage={1}
					place="eventSubscriptions"
					applicationId={applicationId}
					headerHeight={16}
					width={736}
				/>
			</div>
			<FormModal
				title="Add New Event Subscription"
				visible={add}
				cancelFunction={handleEditCancel}
				form={(
					<AddSubscriptionForm
						submitChanges={continueAdding}
						handleCancelClick={handleEditCancel}
						wrappedComponentRef={formRef}
					/>
				)}
			/>
			<WarningModal
				title={warningTitle}
				visible={warning}
				cancelFunction={handleErrorCancel}
				footer={footer}
				message={message}
				loading={buttonLoading}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	eventSubscriptions: state.eventSubscriptions.eventSubscriptions,

});

const mapDispatchToProps = {
	getEventSubscriptions,
	addEventSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSubscriptions);
