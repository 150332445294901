// @flow

const initialState = {
	error: false,
	isFetchingPayments: false,
	isFetchedPayments: false,
	payments: [],
	paymentsTotal: 0,
	approving: false,
	approved: false,
	rejecting: false,
	rejected: false,
	isFetchingPayment: false,
	isFetchedPayment: false,
	payment: {},
	isFetchingAppPayments: false,
	isFetchedAppPayments: false,
	appPayments: [],
	appPaymentsTotal: 0,
	recurringPayment: {},
};

type Meta = {
    paymentFee: string,
    isRecurring: boolean,
    paymentType: string,
    counterparty: string,
    paymentAmount: string,
    paymentMethod: string,
    transactionCheck: string,
    paymentInstrument: {
		instrumentId: string,
		instrumentName: string,
	}
};

export type WalletTransaction = {
	amount: string,
	available: string,
	availableBefore: string,
	createdAt: string,
	debt: string,
	debtBefore: string,
	id: string,
	instrumentId: string,
	reserved: string,
	reservedBefore: string,
	total: string,
	totalBefore: string,
	type: string,
	walletId: string,
	result: string,
	reference: string,
	meta: Meta,
}
export type Payment = {
	id: string,
	type: string,
	amount: string,
	instrumentId: string,
	walletId: string,
	paymentMethod: {
		paymentMethodInfo: {
			type: string,
		},
		paymentMethodType: string,
	},
	meta: {
		networkFee: string,
	},
	status: string,
	reference: string,
	providerName: string,
	externalTransactionId: string,
	networkTransactionId: string,
	createdAt: string,
	updatedAt: string,
	fee: string,
	walletTransactions: Array<WalletTransaction>,
	accountId: string,
	user: {
		id: string,
		firstName: string,
		lastName: string,
	},
	providerInstanceId: string,
	recurrenceId: string,
	providerLogs: Array<Object>,
	message: string,
	bankAccountId: string,
	bankPaymentId: string,
	complianceReview: string,
}
export type RecurringPayment = {
	intervalCount: number,
	interval: string,
	repeatedSoFar: number,
	repeatCount: number,
	payments: Array<{
		id: string,
		type: string,
		amount: string,
		status: string,
		createdAt: string,
	}>
}
export type PaymentState = {
	error: boolean | Object,
	isFetchingPayments: boolean,
	isFetchedPayments: boolean,
	payments: Array<Payment>,
	paymentsTotal: number,
	approving: boolean,
	approved: boolean,
	rejecting: boolean,
	rejected: boolean,
	isFetchingPayment: boolean,
	isFetchedPayment: boolean,
	payment: Payment,
	isFetchingAppPayments: boolean,
	isFetchedAppPayments: boolean,
	appPayments: Array<Payment>,
	appPaymentsTotal: number,
	recurringPayment: RecurringPayment,
}

export type PaymentAction = {
	type:
	| 'PAYMENT'
	| 'PAYMENT_SUCCESS'
	| 'PAYMENT_FAIL'
	| 'APPROVE_WITHDRAWAL_REQUEST'
	| 'APPROVE_WITHDRAWAL_REQUEST_SUCCESS'
	| 'APPROVE_WITHDRAWAL_REQUEST_FAIL'
	| 'REJECT_WITHDRAWAL_REQUEST'
	| 'REJECT_WITHDRAWAL_REQUEST_SUCCESS'
	| 'REJECT_WITHDRAWAL_REQUEST_FAIL',
	payload: {
		data: Payment,
	},
	error: {
		code: number,
		message: string,
	}
}
export type PaymentsAction = {
	type:
	| 'PAYMENTS'
	| 'PAYMENTS_SUCCESS'
	| 'PAYMENTS_FAIL'
	| 'APP_PAYMENTS'
	| 'APP_PAYMENTS_SUCCESS'
	| 'APP_PAYMENTS_FAIL'
	| 'WALLET_PAYMENTS'
	| 'WALLET_PAYMENTS_SUCCESS'
	| 'WALLET_PAYMENTS_FAIL',
	payload: {
		data: Array<Payment>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
		message: string,
	}
}
export type RecurringAction = {
	type:
	| 'RECURRING_PAYMENT'
	| 'RECURRING_PAYMENT_SUCCESS'
	| 'RECURRING_PAYMENT_FAIL',
	payload: {
		data: RecurringPayment,
	},
	error: {
		code: number,
		message: string,
	}
}
export type Action = PaymentAction | PaymentsAction | RecurringAction;

export default function paymentReducer(
	state: PaymentState = initialState,
	action: Action,
): PaymentState {
	switch (action.type) {
	case 'PAYMENTS': {
		return {
			...state,
			isFetchingPayments: true,
			isFetchedPayments: false,
			payments: [],
			error: false,
		};
	}
	case 'PAYMENTS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingPayments: false,
			isFetchedPayments: true,
			payments: action.payload.data,
			paymentsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'PAYMENTS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingPayments: false,
			isFetchedPayments: false,
		};
	}
	case 'PAYMENT': {
		return {
			...state,
			isFetchingPayment: true,
			isFetchedPayment: false,
			error: false,
		};
	}
	case 'PAYMENT_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingPayment: false,
			isFetchedPayment: true,
			payment: action.payload.data,
		};
	}
	case 'PAYMENT_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingPayment: false,
			isFetchedPayment: false,
		};
	}
	case 'APP_PAYMENTS': {
		return {
			...state,
			isFetchingAppPayments: true,
			isFetchedAppPayments: false,
			appPayments: [],
			error: false,
		};
	}
	case 'APP_PAYMENTS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingAppPayments: false,
			isFetchedAppPayments: true,
			appPayments: action.payload.data,
			appPaymentsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'APP_PAYMENTS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingAppPayments: false,
			isFetchedAppPayments: false,
		};
	}
	case 'WALLET_PAYMENTS': {
		return {
			...state,
			isFetchingPayments: true,
			isFetchedPayments: false,
			payments: [],
			error: false,
		};
	}
	case 'WALLET_PAYMENTS_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingPayments: false,
			isFetchedPayments: true,
			payments: action.payload.data,
			paymentsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	}
	case 'WALLET_PAYMENTS_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingPayments: false,
			isFetchedPayments: false,
		};
	}
	case 'APPROVE_WITHDRAWAL_REQUEST': {
		return {
			...state,
			approving: true,
			approved: false,
			error: false,
		};
	}
	case 'APPROVE_WITHDRAWAL_REQUEST_SUCCESS': {
		return {
			...state,
			error: false,
			approving: false,
			approved: true,
			payments: state.payments.map((payment) => {
				if (payment.id === action.payload.data.id) {
					return action.payload.data;
				}
				return payment;
			}),
		};
	}
	case 'APPROVE_WITHDRAWAL_REQUEST_FAIL': {
		return {
			...state,
			error: action.error,
			approving: false,
			approved: false,
		};
	}
	case 'REJECT_WITHDRAWAL_REQUEST': {
		return {
			...state,
			rejecting: true,
			rejected: false,
			error: false,
		};
	}
	case 'REJECT_WITHDRAWAL_REQUEST_SUCCESS': {
		return {
			...state,
			error: false,
			rejecting: false,
			rejected: true,
			payments: state.payments.map((payment) => {
				if (payment.id === action.payload.data.id) {
					return action.payload.data;
				}
				return payment;
			}),
		};
	}
	case 'REJECT_WITHDRAWAL_REQUEST_FAIL': {
		return {
			...state,
			error: action.error,
			rejecting: false,
			rejected: false,
		};
	}
	case 'RECURRING_PAYMENT_FAIL': {
		return {
			...state,
			error: action.error,
			isFetchingRecurring: false,
			isFetchedRecurring: false,
		};
	}
	case 'RECURRING_PAYMENT': {
		return {
			...state,
			isFetchingRecurring: true,
			isFetchedRecurring: false,
			error: false,
		};
	}
	case 'RECURRING_PAYMENT_SUCCESS': {
		return {
			...state,
			error: false,
			isFetchingRecurring: false,
			isFetchedRecurring: true,
			recurringPayment: action.payload.data,
		};
	}
	default:
		return state;
	}
}
