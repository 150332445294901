// @flow

import React from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
//= import components
import CopyComponent from '../../../components/UiElements/CopyComponent';
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import Tag from '../../../components/UiElements/Tag';
//= import types
import type { KytReport } from '../../../modules/reducers/KytReducer';
import styles from '../../../components/UiElements/CustomCards/assets/card.module.scss';

type Props = {
	data: Array<KytReport>,
}
const KytCard = (props: Props) => {
	const {
		data,
	} = props;

	const listData = (el) => [
		{
			title: 'id',
			content: <CopyComponent
				text={el.id}
				content={el.id}
			/>,
		},
		{ title: 'created at', content: moment(el.createdAt).format('YYYY-MM-DD HH:mm:ss') },
		{
			title: 'address',
			content: <CopyComponent
				text={el.address}
				content={el.address}
			/>,
		},
		{ title: 'risk score', content: el.payload?.data?.riskscore || el.payload?.data?.riskscore === 0 ? el.payload?.data?.riskscore : '-' },
	];

	const parts = data.map((el) => ({
		component: (
			<div className="kyt-parts">
				<div>
					<div className={styles.title}>
						<h4>KYT Check Info</h4>
						<Tag status={el.status} />
					</div>
					<div>
						{listData(el).map((part) => (
							<div className={styles.data} key={part.title}>
								<div className={styles.key}>{part.title}</div>
								<div className={styles.value}>{part.content}</div>
							</div>
						))}
					</div>
				</div>
				<div>
					<div className={styles.title}>
						<h4>Counterparty</h4>
					</div>
					<div className={styles.json}>
						<ReactJson
							src={el.counterparty || {}}
							theme="shapeshifter:inverted"
							enableClipboard={false}
							style={{
								backgroundColor: '#F0F6FA',
								padding: '16px',
							}}
							collapseStringsAfterLength={36}
						/>
					</div>
				</div>
			</div>
		),
	}));

	return (
		<DataCard parts={parts} />
	);
};

export default KytCard;
