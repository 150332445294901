// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//= import components
import Search from '../../../components/UiElements/Search';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import EmptyGroup from '../../../components/UiElements/Illustrations/EmptyGroup';
import SymbolsTable from '../../Symbol/components/SymbolsTable';
import Spin from '../../../components/UiElements/Spin';
//= import actions
import { getGroupSymbols } from '../../../modules/actions/SymbolActions';
import { getProvidersInstances, getProviders } from '../../../modules/actions/ProviderActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Symbol } from '../../../modules/reducers/SymbolReducer';
import type { ProviderInstance, Provider } from '../../../modules/reducers/ProviderReducer';

type Props = {
	getGroupSymbols: (string, string, number, string) => Promise<Object>,
	symbolsPage: number,
	groupId: string,
	applicationId: string,
	groupSymbolsSearch: string,
	symbolAdded: boolean,
	groupSymbolsTotal: number,
	symbolsTotal: number,
	getProvidersInstances: (string) => void,
	getProviders: (string) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	providersInstances: Array<ProviderInstance>,
	providers: Array<Provider>,
	groupSymbolsPage: number,
}
type SymbolRes = {
	payload: {
		data: Array<Symbol>,
	}
}
const SymbolsTab = (props: Props) => {
	const {
		groupId,
		symbolsPage,
		applicationId,
		getGroupSymbols: getGroupSymbolsAction,
		groupSymbolsSearch,
		symbolAdded,
		groupSymbolsTotal,
		symbolsTotal,
		getProvidersInstances: getProvidersInstancesAction,
		getProviders: getProvidersAction,
		providersInstances,
		providers,
		groupSymbolsPage,
		setCurrentPage: setCurrentPageAction,
	} = props;

	const [groupSymbols, setGroupSymbols] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getProvidersAction(applicationId);
		getProvidersInstancesAction(applicationId);
	}, [getProvidersInstancesAction, getProvidersAction, applicationId]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			const filters = { search: groupSymbolsSearch };
			const res: SymbolRes = await getGroupSymbolsAction(groupId, 'numbered-pages', (groupSymbolsPage - 1) * StatusHelpers.paginationLimit, queryString.stringify(filters));
			setGroupSymbols(res.payload.data);
			setLoading(false);
		}
		fetchData();
	}, [
		groupSymbolsSearch,
		symbolsPage,
		getGroupSymbolsAction,
		groupId,
		symbolAdded,
		groupSymbolsPage,
	]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('groupSymbolsPage', page);
	};

	return (
		<div>
			<Row gutter={24} className="filter-in-tab">
				<Col span={6}>
					<Search
						place="groupSymbols"
						placeholder="Search For Symbols..."
						disabled={false}
					/>
				</Col>
			</Row>
			{!loading
				? (
					<div>
						{groupSymbolsTotal === 0
							? (
								<div className="center">
									<EmptyGroup />
									<p>This Group is currently empty. Add Group Symbols to it.</p>
								</div>
							)
							: (
								<SymbolsTable
									symbols={groupSymbols}
									applicationId={applicationId}
									place="groupSymbols"
									groupId={groupId}
									providersInstances={providersInstances}
									providers={providers}
									handlePageChange={handlePageChange}
									currentPage={groupSymbolsPage}
									symbolsTotal={symbolsTotal}
									headerHeight={16}
								/>
							)}
					</div>
				)
				: <Spin spinning={loading} />}
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	groupSymbolsSearch: state.ui.groupSymbolsSearch,
	providersInstances: state.providers.providersInstances,
	providers: state.providers.providers,
	groupSymbolsPage: state.ui.groupSymbolsPage,
	symbolsTotal: state.symbol.groupSymbolsTotal,
});

const mapDispatchToProps = {
	getGroupSymbols,
	getProvidersInstances,
	getProviders,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolsTab);
