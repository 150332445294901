// @flow

import React, {
	useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import RegexHelpers from '../../../lib/helpers/regexHelpers';
//= import components
import Title from '../../../components/UiElements/Title';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import CardList from '../components/CardList';
//= import actions
import { getCards, getCardPayments } from '../../../modules/actions/CardAction';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { CardType } from '../../../modules/reducers/CardsReducer';
import { setCurrentPage } from '../../../modules/actions/UiActions';

type Props = {
	getCards: (applicationId: string, page: number, pageSize: number, queryString: string) => void,
	queryFilters: object,
	cards: Array<CardType>,
	pageSize: number,
	page: number,
	cardsTotal: number,
	setCurrentPage: (place: string, page: number) => void,
	isFetchingCards: boolean,
	match: {
		params: {
			applicationId: string,
			userId: string,
		}
	},
}

function Cards(props: Props) {
	const {
		getCards: getCardsAction,
		match: { params: { applicationId } },
		cards,
		isFetchingCards,
		pageSize,
		page,
		cardsTotal,
		setCurrentPage: setCurrentPageAction,
		queryFilters,
	} = props;

	const [errorMessage, setErrorMessage] = useState('');
	const [isFilterVisible, setIsFilterVisible] = useState(false);

	const queryTemplate = (filters) => {
		let string = '';
		Object.entries(filters).forEach((entry) => {
			if (!entry[1].length) return;
			const key = RegexHelpers.camelCaseToUnderscore(entry[0]);
			const value = (key === 'currency_iso_code' || key === 'country_iso_code') ? entry[1][0].toUpperCase() : entry[1][0];
			string += `${key}==${value}&`;
		});
		return string;
	};

	const filterToggle = (): void => {
		setIsFilterVisible(true);
	};

	const filterRef = useRef(null);

	useEffect(() => {
		async function getData() {
			try {
				if (Object.keys(queryFilters).length) {
					const queryString = queryTemplate(queryFilters);
					await getCardsAction(applicationId, page, pageSize, queryString);
					setErrorMessage('');
				} else {
					await getCardsAction(applicationId, page, pageSize, '');
					setErrorMessage('');
				}
			} catch (err) {
				const errMessage: string = err.error?.response?.data?.message;
				if (errMessage && errMessage.startsWith('Application with id')) {
					setErrorMessage("This feature is not available for your application. If you're interested in it, please contact your TradeCore representative.");
				}
			}
		}
		getData();
	}, [applicationId, getCardsAction, queryFilters, pageSize, page]);

	const handlePageChange = (page: number) => {
		setCurrentPageAction('cardsPage', page);
	}

	return (
		<>
			<div ref={filterRef}>
				<Title
					title="Cards"
					buttons={[
						{
							action: filterToggle,
							text: 'filter',
							icon: 'Funnel',
						},
					]}
					applicationId={applicationId}
					place="cards"
					fields={{
						countryIsoCode: 'input',
						currencyIsoCode: 'input',
						userPlatformId: 'input',
						accountPlatformId: 'input',
						id: 'input',
						status: 'input',
					}}
					visible={isFilterVisible}
					closeFunction={()=> setIsFilterVisible(false)}
					filter
				/>
			</div>
			<div className="container">
				{errorMessage
					? (
						<div className="empty-state">
							<UserNoChecks />
							<p>{errorMessage}</p>
						</div>
					)
					: (
						<CardList
							applicationId={applicationId}
							cards={cards}
							total={cardsTotal}
							isFetchingCards={isFetchingCards}
							place="appCards"
							pageSize={pageSize}
							page={page}
							handlePageChange={handlePageChange}
						/>
					)}
			</div>
		</>
	);
}

const mapStateToProps = (state: State) => ({
	cards: state.cards.cards,
	isFetchingCards: state.cards.isFetchingCards,
	queryFilters: state.ui.cardsFilters,
	pageSize: state.ui.productsPageSize,
	page: state.ui.cardsPage,
	cardsTotal: state.cards.cardsTotal,
});
const mapDispatchToProps = {
	getCards,
	getCardPayments,
	setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
