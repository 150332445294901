// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import Icon from '../../../components/UiElements/Icon';
import Spin from '../../../components/UiElements/Spin';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Tooltip from '../../../components/UiElements/Tooltip';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import NumbersCard from '../../../components/UiElements/CustomCards/NumbersCard';
import CopyComponent from '../../../components/UiElements/CopyComponent';
import UserNoChecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
import AffectedWalletCard from './AffectedWalletCard';
import RecurringPaymentCard from './RecurringPaymentsCard';
import KytCard from './KytCard';
//= import methods
import {
	approveWithdrawalRequest, rejectWithdrawalRequest, getPayment, getRecurringPayment,
} from '../../../modules/actions/PaymentActions';
import { getAccount } from '../../../modules/actions/AccountsActions';
import { getFeeQuote } from '../../../modules/actions/FeeActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
import { getWallet } from '../../../modules/actions/WalletActions';
import { getBankPayment } from '../../../modules/actions/BankActions';
import { getKytReports, updateComplianceReviewStatus } from '../../../modules/actions/KYTActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { Payment, RecurringPayment } from '../../../modules/reducers/PaymentReducer';
import type { State } from '../../../modules/types/FlowTypes';
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { FeeQuote } from '../../../modules/reducers/FeeReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { KytReport } from '../../../modules/reducers/KytReducer';

type PaymentResponse = {
	payload: {
		data: Payment,
	}
}
type ProviderResponse = {
	payload: {
		data: Array<ProviderInstance>,
	}
}
type FeeResponse = {
	payload: {
		data: FeeQuote,
	}
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				errors: Array<Object>,
			}
		}
	}
}
type Props = {
	payment: Payment,
	account: Account,
	getPayment: (string, string) => Promise<PaymentResponse>,
	approveWithdrawalRequest: (string) => Promise<PaymentResponse>,
	rejectWithdrawalRequest: (string) => Promise<PaymentResponse>,
	getRecurringPayment: (string, string) => void,
	getAccount: (string) => void,
	getFeeQuote: (string, string) => Promise<FeeResponse>,
	getProvidersInstances: (string) => Promise<ProviderResponse>,
	getWallet: (string) => Promise<Object>,
	recurringPayment: RecurringPayment,
	wallet: Wallet,
	getBankPayment: (string) => Promise<Object>,
	updateComplianceReviewStatus: (string, string) => Promise<Object>,
	getKytReports: (string, number, string) => void,
	kytReports: Array<KytReport>,
	match: {
		params: {
			paymentId: string,
			instrumentId: string,
			userId: string,
			applicationId: string,
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
		length: number,
	}
}
function PaymentPage(props: Props) {
	const {
		match: { params: { applicationId, paymentId } },
		account,
		payment,
		recurringPayment,
		approveWithdrawalRequest: approveWithdrawalRequestAction,
		rejectWithdrawalRequest: rejectWithdrawalRequestAction,
		getPayment: getPaymentAction,
		getRecurringPayment: getRecurringPaymentAction,
		getAccount: getAccountAction,
		getFeeQuote: getFeeQuoteAction,
		getProvidersInstances: getProvidersInstancesAction,
		getWallet: getWalletAction,
		getBankPayment: getBankPaymentAction,
		getKytReports: getKytReportsAction,
		updateComplianceReviewStatus: updateComplianceReviewStatusAction,
		history,
		wallet,
		kytReports,
	} = props;

	const [rejectModal, setRejectModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [loading, setLoading] = useState(true);
	const [expectedFee, setExpectedFee] = useState({});
	const [bankPayment, setBankPayment] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		async function fetchData() {
			try {
				const paymentRes = await getPaymentAction(applicationId, paymentId);
				getKytReportsAction(applicationId, 1, queryString.stringify({ paymentId }));
				getAccountAction(paymentRes.payload.data.accountId);
				if (paymentRes.payload.data.recurrenceId) {
					getRecurringPaymentAction(
						paymentRes.payload.data.accountId,
						paymentRes.payload.data.recurrenceId,
					);
				}
				if (paymentRes.payload.data.type === 'withdrawal' && (paymentRes.payload.data.status === 'pending' || paymentRes.payload.data.status === 'pendingNew')) {
					await getWalletAction(paymentRes.payload.data.walletId);
				}
				if (paymentRes.payload.data.status !== 'executed') {
					if (paymentRes.payload.data.providerInstanceId) {
						const parameter = {
							type: paymentRes.payload.data.type,
							walletInstrumentId: paymentRes.payload.data.instrumentId,
							providerInstanceId: paymentRes.payload.data.providerInstanceId,
							amount: paymentRes.payload.data.amount,
							userId: paymentRes.payload.data.user.id,
						};
						const feeQuote = await getFeeQuoteAction(applicationId, queryString.stringify(parameter, { arrayFormat: 'comma' }));
						setExpectedFee(feeQuote.payload.data);
					} else {
						const providerRes = await getProvidersInstancesAction(applicationId);
						const providerInstance = providerRes.payload.data.filter((provider) => (provider.provider.name === 'internal' && provider.provider.type === 'payment'));
						const parameter = {
							type: paymentRes.payload.data.type,
							walletInstrumentId: paymentRes.payload.data.instrumentId,
							providerInstanceId: providerInstance[0].id,
							amount: paymentRes.payload.data.amount,
							userId: paymentRes.payload.data.user.id,
						};
						const feeQuote = await getFeeQuoteAction(applicationId, queryString.stringify(parameter, { arrayFormat: 'comma' }));
						setExpectedFee(feeQuote.payload.data);
					}
				}
				if (paymentRes.payload.data.bankPaymentId) {
					const bankRes = await getBankPaymentAction(paymentRes.payload.data.bankPaymentId);
					setBankPayment(bankRes.payload.data);
				}
			} catch (error) {
				setMessage({
					firstPart: error.error.response ? error.error.response.data.message : 'Error',
				});
			} finally { setLoading(false); }
		}
		fetchData();
	}, [
		applicationId,
		paymentId,
		getAccountAction,
		getProvidersInstancesAction,
		getFeeQuoteAction,
		getPaymentAction,
		getRecurringPaymentAction,
		getWalletAction,
		getBankPaymentAction,
		getKytReportsAction,
	]);

	const handleBackClicked = () => {
		props.history.goBack();
	};

	const handleCancel = () => {
		setRejectModal(false);
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setRejectModal(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error.response ? error.error.response.data.message : 'Error',
			secondPart: printError(error.error.response.data.errors),
		});
		setFooter([
			{ type: 'cancel', action: handleCancel, text: 'close' },
		]);
	};

	const approve = () => {
		approveWithdrawalRequestAction(payment.id)
			.then(() => getPaymentAction(applicationId, paymentId))
			.catch((error: ErrorType) => handleOnError(error));
	};

	const reject = () => {
		setButtonLoading(true);
		rejectWithdrawalRequestAction(payment.id)
			.then(() => {
				setButtonLoading(false);
				getPaymentAction(applicationId, paymentId);
			})
			.then(() => setRejectModal(false))
			.catch((error: ErrorType) => handleOnError(error));
	};

	const handleRejectClicked = () => {
		setRejectModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: "Once you reject Client's Payment Request you won't be able to approve it anymore.",
		});
		setFooter([
			{ type: 'cancel', action: handleCancel, text: 'cancel' },
			{ type: 'continue', action: reject, text: 'reject' },
		]);
	};

	const updateStatus = async (status) => {
		setButtonLoading(true);
		try {
			await updateComplianceReviewStatusAction(paymentId, status);
			getPaymentAction(applicationId, paymentId);
			setButtonLoading(false);
			setRejectModal(false);
		} catch (error) { handleOnError(error); }
	};

	const handleUpdateComplianceReview = (status: string) => {
		setRejectModal(true);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: `Once you change Compliance review status to ${status} you won't be able to change it anymore.`,
		});
		setFooter([
			{ type: 'cancel', action: handleCancel, text: 'cancel' },
			{ type: 'continue', action: () => updateStatus(status), text: status },
		]);
	};

	const getData = () => {
		if (payment.type === 'withdrawal') {
			if (payment.status === 'pending' || payment.status === 'pendingNew') {
				const data = [{
					key: 'requested',
					value: PriceHelpers.formatAmount(
						payment.amount,
						payment.instrumentId,
					),
					color: '#F03E3E',
				}, {
					key: 'calculatedFee',
					value: (
						<Tooltip
							title="Fee will be deducted from the requested amount"
						>
							<span className="expected-fee">
								<span>
									{PriceHelpers.formatAmount(
										payment.fee || expectedFee.feeAmount,
										payment.instrumentId,
									)}
								</span>
								<Icon name="Info" />
							</span>
						</Tooltip>
					),
					color: '#F03E3E',
				}, {
					key: 'payoutAmount',
					value: PriceHelpers.formatAmount(
						Numbers.sub(payment.amount, payment.fee || expectedFee.feeAmount || '0'),
						payment.instrumentId,
					),
					color: '#0578FC',
				}, {
					key: 'availableAfter',
					value: wallet.available
						? PriceHelpers.formatAmount(
							wallet.available,
							payment.instrumentId,
						) : '-',
					color: '#1DC46E',
				}];
				return data;
			}
			const data = [{
				key: 'requested',
				value: PriceHelpers.formatAmount(
					payment.amount,
					payment.instrumentId,
				),
				color: '#F03E3E',
			}, {
				key: 'fee',
				value: PriceHelpers.formatAmount(
					payment.fee || expectedFee.feeAmount,
					payment.instrumentId,
				),
				color: '#F03E3E',
			}, {
				key: 'payoutAmount',
				value: PriceHelpers.formatAmount(
					Numbers.sub(payment.amount, payment.fee || expectedFee.feeAmount || '0'),
					payment.instrumentId,
				),
				color: '#0578FC',
			}, {
				key: 'availableAfter',
				value: payment.walletTransactions.length
					? PriceHelpers.formatAmount(
						payment.walletTransactions[0].available,
						payment.instrumentId,
					) : '-',
				color: '#1DC46E',
			}];
			return data;
		}
		const data = [{
			key: 'requested',
			value: PriceHelpers.formatAmount(
				payment.amount,
				payment.instrumentId,
			),
			color: '#F03E3E',
		}, {
			key: 'fee',
			value: PriceHelpers.formatAmount(
				payment.fee,
				payment.instrumentId,
			),
			color: '#F03E3E',
		}, {
			key: 'availableAfter',
			value: payment.walletTransactions?.length
				? PriceHelpers.formatAmount(
					payment.walletTransactions[0].available,
					payment.instrumentId,
				) : '-',
			color: '#1DC46E',
		}];
		return data;
	};

	const recurringData = {
		interval: `${recurringPayment.intervalCount} ${recurringPayment.interval}`,
		repeatedSoFar: recurringPayment.repeatedSoFar || '-',
		repeatCount: recurringPayment.repeatCount || '-',
	};

	const paymentDetails = {
		paymentId: <CopyComponent
			text={payment.id}
			content={payment.id}
		/>,
		timeZone: 'Local Time Zone',
		createdAt: moment(payment.createdAt).format('YYYY-MM-DD HH:mm:ss'),
		lastUpdatedAt: moment(payment.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
		externalProvider: payment.providerName || '-',
		externalTransactionId: payment.externalTransactionId || '-',
		networkFee: payment.meta?.networkFee || '-',
		transactionHash: payment.networkTransactionId || '-',

	};
	const userData = {
		tradingAccount: (
			<a
				href={`/application/${applicationId}/clients/${payment.user?.id}?accountId=${payment.accountId}&tab=user_payments`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{account.name}
			</a>),
		placedBy: (
			<a
				href={`/application/${applicationId}/clients/${payment.user?.id}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{`${payment.user?.firstName || 'Name Not Set'} ${payment.user?.lastName || ''}`}
			</a>),
	};
	const paymentData = payment.recurrenceId
		? [{
			title: 'Payment Information',
			data: {
				...paymentDetails,
				...recurringData,
				...userData,
			},
		}] : [{
			title: 'Payment Information',
			data: {
				...paymentDetails,
				...userData,
			},
		}];

	const bankPaymentData = bankPayment.id ? [
		{
			title: 'Bank Payment Information',
			tag: payment.status,
			data: {
				bankPaymentId: (
					<div className="flex-container">
						<a
							href={`/application/${applicationId}/bank_payments/${payment.bankPaymentId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{payment.bankPaymentId}
						</a>
						<CopyComponent
							text={payment.bankPaymentId}
							content=""
						/>
					</div>),
				scheme: bankPayment.scheme,
				bankAccount: (
					<div className="flex-container">
						<a
							href={`/application/${applicationId}/client_bank_accounts/${payment.bankAccountId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{payment.bankAccountId}
						</a>
						<CopyComponent
							text={payment.bankPaymentId}
							content=""
						/>
					</div>),
			},
		},
	] : [];

	const pendingWithdrawalButton = [{
		action: handleRejectClicked,
		type: 'danger',
		text: 'reject',
	},
	{
		action: approve,
		text: 'approve',
	}];

	const executedReviewButton = [{
		action: () => handleUpdateComplianceReview('reject'),
		type: 'danger',
		text: 'reject',
	},
	{
		action: () => handleUpdateComplianceReview('approve'),
		text: 'approve',
	}];

	return (
		<>
			{!loading
				? (
					<div>
						<Title
							backAction={history.length > 1 ? handleBackClicked : undefined}
							title={`${payment.paymentMethod ? payment.paymentMethod.paymentMethodType || 'Manual' : 'Payment'} ${ActivityHelpers.transformToReadable(payment.type || '')}`}
							tags={[payment.status, !!payment.recurrenceId && 'recurring', payment.complianceReview]}
							buttons={
								(payment.type === 'withdrawal'
								&& (payment.status === 'pendingNew' || (wallet?.instrument?.type !== 'crypto' && payment.status === 'pending'))
								&& pendingWithdrawalButton)
							|| (payment.status === 'executed' && payment.complianceReview === 'review' && executedReviewButton)
							}
						/>
						{payment.id
							? (
								<div className="page-container">
									<NumbersCard parts={getData()} />
									<Row gutter={24}>
										<Col span={15}>
											{bankPayment.id && <DataCard parts={bankPaymentData} />}
											<AffectedWalletCard
												wallets={payment.walletTransactions || []}
												type="payment"
												userId={payment.user?.id}
												applicationId={applicationId}
												status={payment.status}
												instrumentId={payment.instrumentId}
											/>
											{!!kytReports.length && <KytCard data={kytReports} />}
											{!!payment.recurrenceId
											&& (
												<RecurringPaymentCard
													recurringPayments={recurringPayment}
													applicationId={applicationId}
												/>
											)}
										</Col>
										<Col span={9}>
											<DataCard parts={paymentData} />
											<DataCard parts={[{
												title: 'Message',
												data: {
													content: payment.message,
												},
											}]}
											/>
											<DataCard parts={[{
												title: 'Provider log',
												json: payment.providerLogs,
											}]}
											/>
										</Col>
									</Row>
									<WarningModal
										title={warningTitle}
										visible={rejectModal}
										cancelFunction={handleCancel}
										footer={footer}
										message={message}
										loading={buttonLoading}
									/>
								</div>
							)
							: (
								<div className="empty-state">
									<UserNoChecks />
									<p>{message.firstPart}</p>
								</div>
							)}
					</div>
				)
				: <Spin spinning={loading} />}
		</>
	);
}

const mapStateToProps = (state: State) => ({
	payment: state.payment.payment,
	account: state.account.account,
	recurringPayment: state.payment.recurringPayment,
	wallet: state.wallet.wallet,
	kytReports: state.kyt.kytReports,
});

const mapDispatchToprops = {
	approveWithdrawalRequest,
	rejectWithdrawalRequest,
	getAccount,
	getPayment,
	getFeeQuote,
	getProvidersInstances,
	getRecurringPayment,
	getWallet,
	getBankPayment,
	getKytReports,
	updateComplianceReviewStatus,
};

export default connect(mapStateToProps, mapDispatchToprops)(PaymentPage);
