// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
//= import components
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import Title from '../../../components/UiElements/Title';
import Card from '../../../components/UiElements/Card';
import Col from '../../../components/UiElements/Layout/Col';
import Row from '../../../components/UiElements/Layout/Row';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';
import ReasonForm from '../components/ReasonForm';
import SuspensionsList from '../components/SuspensionsList';
//= import methods
import {
	getSuspendReason, addSuspendReason, updateSuspendReason, deleteSuspendReason, getSuspensions,
} from '../../../modules/actions/UsersActions';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Suspension, SuspendReason } from '../../../modules/reducers/UsersReducer';
//= import styles
import styles from '../assets/suspensions.module.scss';

type Props = {
	suspendReason: SuspendReason,
	suspensions: Array<Suspension>,
	getSuspendReason: (string, string) => void,
	updateSuspendReason: (string, string, Object) => Promise<Object>,
	deleteSuspendReason: (string, string) => Promise<Object>,
	getSuspensions: (string, string) => void,
	match: {
		params: {
			applicationId: string,
			reasonId: string,
		},
	},
	history: {
		push: string => void,
		goBack: () => void,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
			}
		}
	}
}

const SuspendReasons = (props: Props) => {
	const {
		match: { params: { applicationId, reasonId } },
		history: { goBack },
		suspendReason,
		suspensions,
		getSuspendReason: getSuspendReasonAction,
		updateSuspendReason: updateSuspendReasonAction,
		deleteSuspendReason: deleteSuspendReasonAction,
		getSuspensions: getSuspensionsAction,
	} = props;

	const [edit, setEdit] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning!');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [deleteClicked, setDeleteClicked] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const formRef = useRef(null);

	useEffect(() => {
		getSuspendReasonAction(applicationId, reasonId);
		getSuspensionsAction(applicationId, reasonId);
	}, [
		applicationId,
		reasonId,
		getSuspendReasonAction,
		getSuspensionsAction,
	]);

	const handleWarningCancel = () => {
		setWarningModal(false);
		setEdit(true);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setWarningModal(false);
	};

	const handleOnError = (error: ErrorType) => {
		setButtonLoading(false);
		setWarningModal(true);
		setWarningTitle('Error');
		setMessage({ firstPart: error.error.response.data.message });
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const handleUpdateClick = () => {
		setEdit(true);
	};

	const submitEditReason = (data) => {
		setButtonLoading(true);
		if (formRef.current) {
			formRef.current.handleReset();
		}
		updateSuspendReasonAction(applicationId, suspendReason.id, data)
			.then(() => {
				setButtonLoading(false);
				setWarningModal(false);
			})
			.catch((error: ErrorType) => handleOnError(error));
	};

	const continueEditing = (value) => {
		setWarningModal(true);
		setEdit(false);
		setWarningTitle('Are you sure?');
		setMessage({
			firstPart: 'Once you confirm these changes, they will affect Suspend Reason.',
			secondPart: 'It is advised to proceed editing with caution.',
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitEditReason(value), text: 'Update Suspend Reason' },
		]);
	};

	const handleEditCancel = () => {
		if (formRef.current && formRef.current.props.form.isFieldsTouched()) {
			setEdit(false);
			setWarningModal(true);
			setWarningTitle('Warning!');
			setMessage({
				firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.',
			});
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			if (formRef.current) {
				formRef.current.handleReset();
			}
			setEdit(false);
			setWarningModal(false);
		}
	};

	const handleDeleteClick = () => {
		setDeleteClicked(true);
	};

	const handleDeleteCancel = () => {
		setWarningModal(false);
		setDeleteClicked(false);
	};

	const deleteReason = async () => {
		setButtonLoading(true);
		setDeleteClicked(false);
		try {
			await deleteSuspendReasonAction(applicationId, reasonId);
			setButtonLoading(false);
			goBack();
		} catch (error) { handleOnError(error); }
	};

	const handleBackClick = () => {
		goBack();
	};

	return (
		<>
			<Title
				backAction={handleBackClick}
				title={`Suspend Reason ${suspendReason.title}`}
				buttons={[
					{
						text: 'Update Suspend Reasons',
						action: handleUpdateClick,
						disabled: suspensions.length,
						tooltip: suspensions.length
							? 'You can’t update this suspend reason, as it is associated with one or more suspended clients.'
							: '',
					},
					{
						text: 'Delete Suspend Reasons',
						action: handleDeleteClick,
						type: 'danger',
						disabled: suspensions.length,
						tooltip: suspensions.length
							? 'You can’t delete this suspend reason, as it is associated with one or more suspended clients.'
							: '',
					},
				]}
			/>
			<div className="page-container">
				<Row gutter={24} type="flex">
					<Col span={12} className="col-margin">
						<Card bordered={false} className={styles.reasonInfo}>
							<h2>Suspend Reason id</h2>
							<p>
								{suspendReason.id}
							</p>
						</Card>
					</Col>
					<Col span={12} className="col-margin">
						<Card bordered={false} className={styles.reasonInfo}>
							<h2>Description</h2>
							<p>
								{suspendReason.description}
							</p>
						</Card>
					</Col>
				</Row>
				<Card bordered={false} className={styles.suspensionsTable}>
					<h2>Suspended Clients</h2>
					<SuspensionsList
						suspensions={suspensions}
						suspensionsTotal={suspensions.length}
						applicationId={applicationId}
						currentPage={1}
					/>
				</Card>
				<FormModal
					title={`Update Suspend Reason ${suspendReason.title || ''}`}
					visible={edit}
					cancelFunction={handleEditCancel}
					form={(
						<ReasonForm
							submitChanges={continueEditing}
							handleCancelClick={handleEditCancel}
							reason={suspendReason}
							key={suspendReason.id}
							wrappedComponentRef={formRef}
							applicationId={applicationId}
							type="edit"
						/>

					)}
				/>
				<WarningModal
					title={warningTitle}
					visible={warningModal}
					cancelFunction={handleErrorCancel}
					footer={footer}
					message={message}
					loading={buttonLoading}
				/>
				<DeleteModal
					okFunction={deleteReason}
					cancelFunction={handleDeleteCancel}
					visible={deleteClicked}
					btnText="Delete Suspend Reason"
					message={`Once you delete the ${suspendReason.title} Suspend Reason
									you won't be able to retrieve it.`}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	suspendReason: state.users.suspendReason,
	suspensions: state.users.suspensions,
});

const mapDispatchToProps = {
	getSuspendReason,
	updateSuspendReason,
	addSuspendReason,
	deleteSuspendReason,
	getSuspensions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuspendReasons);
