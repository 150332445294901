// @flow

import React from 'react';
import moment from 'moment';
//= import components
import Card from '../../../components/UiElements/Card';
import Timeline from '../../../components/UiElements/Timeline';
import { TimeDot, InvisibleDot } from '../../../components/UiElements/TimeDot';
//= import helpers
import ActivityHelpers from '../../../lib/helpers/activityHelpers';
//= import styles
import styles from '../assets/cards.module.scss';

type Props = {
	timelineData: Array<Object>,
	handleOpenDrawer: Function,
}

function PaymentTimeline(props: Props) {
	const {
		timelineData,
		handleOpenDrawer,
	} = props;

	return (
		<Card className={styles.timeline} bordered={false}>
			<h2>Timeline</h2>
			<Timeline>
				{timelineData.map((el, index) => (
					<Timeline.Item
						key={el.id}
						dot={(
							<TimeDot
								time={el.createdAt}
								diff={index > 0
									? `T +${moment(el.createdAt).diff(moment(timelineData[index - 1].createdAt), 'miliseconds')}ms`
									: ''}
							/>
						)}
					>
						<div className="invisible-div" />
						<div
							onClick={() => handleOpenDrawer(el.actionType, el.payload)}
							role="presentation"
							className="visible"
						>
							<p>{ActivityHelpers.transformToReadable(el.actionType || '')}</p>
							<p>{el.id}</p>
						</div>
					</Timeline.Item>
				))}
				<Timeline.Item
					dot={<InvisibleDot />}
				/>
			</Timeline>
		</Card>
	);
}

export default PaymentTimeline;
