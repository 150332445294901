// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
//= import components
import Title from '../../../components/UiElements/Title';
import Card from '../../../components/UiElements/Card';
import CustomTable from '../../../components/UiElements/CustomTable';
import Spin from '../../../components/UiElements/Spin';
import Switch from '../../../components/UiElements/Switch';
import Menu from '../../../components/UiElements/Menu';
import ValueCard from '../../../components/UiElements/CustomCards/ValueCard';
import EmptyWallet from '../../../components/UiElements/Illustrations/EmptyWallet';
import FormModal from '../../../components/UiElements/Modal/FormModal';
import WarningModal from '../../../components/UiElements/Modal/WarningModal';
import CreditDebitForm from '../../FullUser/components/CreditDebitForm';
import ConversionForm from '../../FullUser/components/ConversionForm';
import Icon from '../../../components/UiElements/Icon';
import Export from '../../../components/UiElements/Export';
import TableInfoComponent from '../../../components/UiElements/TableInfoComponent';
import AccountMarginCard from './AccountMarginCard';
import PnLCard from './PnLCard';
// = import actions
import {
	getWallet,
	getWalletTransactions,
	creditWallet,
	debitWallet,
	transferToWallet,
	exportWalletTransactions,
	executeConversion,
	getConversionQuote,
	getWallets,
	createWallet,
} from '../../../modules/actions/WalletActions';
import { cryptoPayout } from '../../../modules/actions/PaymentActions';
import { getAccountStatistics, getAccountPositions } from '../../../modules/actions/AccountsActions';
import { setCurrentPage } from '../../../modules/actions/UiActions';
//= import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
import StatusHelpers from '../../../lib/helpers/statusHelpers';
import Numbers from '../../../lib/helpers/numbersHelpers';
//= import types
import type { State } from '../../../modules/types/FlowTypes';
import type { Wallet, WalletTransaction } from '../../../modules/reducers/WalletReducer';
import type { Statistics, Statistic, Position } from '../../../modules/reducers/AccountsReducer';

type Data = {
	amount: string,
	netFee: string,
}
type TransactionRes = {
	payload: {
		data: Array<WalletTransaction>,
	}
}
type WalletRes = {
	payload: {
		data: Wallet,
	}
}
type StatisticRes = {
	payload: {
		data: Statistics,
	}
}
type PositionRes = {
	payload: {
		data: Array<Position>,
	}
}
type Props = {
	wallet: Wallet,
	walletTransactions: Array<WalletTransaction>,
	walletTransactionsTotal: number,
	transactionPage: number,
	accountWallets: Array<Wallet>,
	getWallet: (string) => Promise<WalletRes>,
	getWalletTransactions: (string, number) => Promise<TransactionRes>,
	exportWalletTransactions: (string, string) => Promise<Object>,
	creditWallet: (string, string, Data) => Promise<Object>,
	debitWallet: (string, string, Data) => Promise<Object>,
	transferToWallet: (string, Object) => Promise<Object>,
	setCurrentPage: (string, number) => void,
	getAccountStatistics: (string) => Promise<StatisticRes>,
	getAccountPositions: (string) => Promise<PositionRes>,
	cryptoPayout: (string, Object) =>Promise<Object>,
	executeConversion: (string, Object) =>Promise<Object>,
	getWallets: (string) =>Promise<Object>,
	createWallet: (string, { [string]: string }) => Promise<Object>,
	match: {
		params: {
			walletId: string,
			applicationId: string,
			userId: string
		}
	},
	history: {
		push: (string) => void,
		goBack: () => void,
		length: number,
	}
}
type Value = {
	wallet: Wallet,
	instrumentId: string,
	fee: string,
	amount: string,
	fromWallet: string,
	toWallet: string,
	toCurrency: string,
	fixedRate: string,
	fixedSide: string,
	values: {
		netFee: number,
		amount: number,
		wallet: string,
		message: string,
		destinationWalletId: string,
		destinationAddress: string,
		fromWalletId: string,
		toWalletId: string,
		fixedSide: string,
	},
}
type ErrorType = {
	error: {
		response: {
			data: {
				message: string,
				title: string,
				errors: Array<Object>,
			}
		}
	}
}

const WalletPage = (props: Props) => {
	const {
		wallet,
		walletTransactions,
		transactionPage,
		getWallet: getWalletAction,
		getWalletTransactions: getWalletTransactionsAction,
		exportWalletTransactions: exportWalletTransactionsAction,
		creditWallet: creditWalletAction,
		debitWallet: debitWalletAction,
		transferToWallet: transferToWalletAction,
		setCurrentPage: setCurrentPageAction,
		getAccountStatistics: getAccountStatisticsAction,
		getAccountPositions: getAccountPositionsAction,
		cryptoPayout: cryptoPayoutAction,
		executeConversion: executeConversionAction,
		getWallets: getWalletsAction,
		createWallet: createWalletAction,
		match: { params: { applicationId, walletId } },
		history,
		walletTransactionsTotal,
		accountWallets,
	} = props;

	const [filteredTransactions, setFilteredTransactions] = useState([]);
	const [displayReservations, setDisplayReservations] = useState(false);
	const [modal, setModal] = useState(false);
	const [type, setType] = useState('');
	const [warning, setWarning] = useState(false);
	const [warningTitle, setWarningTitle] = useState('Warning');
	const [message, setMessage] = useState({});
	const [footer, setFooter] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [statistics, setStatistics] = useState({});
	const [position, setPositions] = useState({});
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		async function getData() {
			try {
				setLoading(true);
				const walletData = await getWalletAction(walletId);
				const [positionsRes, statisticsRes] = await Promise.all([
					getAccountPositionsAction(walletData.payload.data.accountId),
					getAccountStatisticsAction(walletData.payload.data.accountId),
				]);
				const positionData = positionsRes.payload.data
					.find((el: Position) => el.symbolId.startsWith(walletData.payload.data.instrumentId));
				setPositions(positionData || {});
				const statisticData = statisticsRes.payload.data.statistics
					.find((el: Statistic) => el.walletId === walletId);
				setStatistics(statisticData || {});
				setLoading(false);
			} catch (error) { setLoading(false); }
		}
		getData();
	}, [
		walletId,
		submitted,
		getWalletAction,
		getAccountStatisticsAction,
		getAccountPositionsAction,
	]);

	useEffect(() => {
		async function getData() {
			try {
				setLoading(true);
				await getWalletTransactionsAction(walletId, transactionPage);
				setLoading(false);
			} catch (error) { setLoading(false); }
		}
		getData();
	}, [
		transactionPage,
		walletId,
		getWalletTransactionsAction,
	]);

	useEffect(() => {
		async function getData() {
			try {
				setLoading(true);
				setCurrentPageAction('transactionPage', 1);
				await getWalletTransactionsAction(walletId, 1);
				setLoading(false);
			} catch (error) { setLoading(false); }
		}
		getData();
	}, [
		submitted,
		walletId,
		getWalletTransactionsAction,
		setCurrentPageAction,
	]);

	useEffect(() => {
		if (displayReservations) {
			setFilteredTransactions(walletTransactions);
		} else {
			setFilteredTransactions(walletTransactions.filter((transaction) => transaction.type !== 'reservation'));
		}
	}, [displayReservations, walletTransactions, transactionPage]);

	const viewUser = () => {
		history.goBack();
	};

	const handleTransactionPageChange = (page: number) => {
		setCurrentPageAction('transactionPage', page);
	};

	const openModal = async (action: string) => {
		if (action === 'conversion') {
			await getWalletsAction(wallet.accountId);
			setSuccess(false);
			setModal(true);
			setType(action);
		} else {
			setSuccess(false);
			setModal(true);
			setType(action);
		}
	};

	const formRef = useRef(null);

	const handleWarningCancel = () => {
		setModal(true);
		setWarning(false);
	};

	const handleErrorCancel = () => {
		if (formRef.current) {
			formRef.current.handleReset();
		}
		setModal(false);
		setWarning(false);
	};

	const handleEditCancel = () => {
		if ((formRef.current && formRef.current.props.form.isFieldsTouched())
			|| (formRef.current && formRef.current.state.isTouched)) {
			setModal(false);
			setWarning(true);
			setWarningTitle('Warning!');
			setMessage({ firstPart: 'There are some unsaved changes. If you leave the page, changes will not be saved.' });
			setFooter([
				{ type: 'cancel', action: handleErrorCancel, text: 'cancel edit' },
				{ type: 'continue', action: handleWarningCancel, text: 'continue editing' },
			]);
		} else {
			setModal(false);
			setWarning(false);
		}
	};

	const printError = (error: Array<Object>) => {
		if (!error) {
			return '';
		}
		const text = error.length
			? error.map((el) => `${el.field}: ${el.messages.map((msg) => msg.message).join(' ')}`)
			: [];
		return text.map((el: string) => (<div key={el}>{el}</div>));
	};

	const handleOnError = (error: ErrorType): void => {
		setButtonLoading(false);
		setWarning(true);
		setWarningTitle('Error');
		setMessage({
			firstPart: error.error && error.error.response.data
				? error.error.response.data.title || error.error.response.data.message
				: 'Error',
			secondPart: error.error && error.error.response.data
				? printError(error.error.response.data.errors)
				: '',
		});
		setFooter([
			{ type: 'cancel', action: handleErrorCancel, text: 'close' },
		]);
	};

	const submitCreditOrDebit = async (value: Value) => {
		setButtonLoading(true);
		if (type === 'credit') {
			const data = {
				amount: value.values.amount.toString(),
				netFee: value.values.netFee.toString(),
			};
			creditWalletAction(applicationId, wallet.id, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setSubmitted(!submitted);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else if (type === 'debit') {
			const data = {
				amount: value.values.amount.toString(),
				netFee: value.values.netFee.toString(),
			};
			debitWalletAction(applicationId, wallet.id, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setSubmitted(!submitted);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else if (type === 'cryptoPayout') {
			const data = {
				amount: value.values.amount.toString(),
				destinationAddress: value.values.destinationAddress,
			};
			cryptoPayoutAction(wallet.id, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setSubmitted(!submitted);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else if (type === 'internalTransfer') {
			const data = {
				amount: value.values.amount.toString(),
				message: value.values.message,
				destinationWalletId: value.values.destinationWalletId,
				userId: wallet.userId,
			};
			transferToWalletAction(wallet.id, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setSubmitted(!submitted);
				})
				.catch((error: ErrorType) => handleOnError(error));
		} else {
			const toWallet = accountWallets.find((el) => el.instrumentId === value.toCurrency);
			let data;
			if (toWallet) {
				data = {
					accountId: wallet.accountId,
					amount: value.amount.toString(),
					fromWallet: value.fromWallet,
					toWallet: toWallet.id,
					fixedSide: value.fixedSide,
					fixedRate: value.fixedRate ? value.fixedRate.toString() : undefined,
				};
			} else {
				const instrumentData = { instrumentId: value.toCurrency };
				const walletRes = await createWalletAction(wallet.accountId, instrumentData);
				data = {
					accountId: wallet.accountId,
					amount: value.amount.toString(),
					fromWallet: value.fromWallet,
					toWallet: walletRes.payload.data.id,
					fixedSide: value.fixedSide,
					fixedRate: value.fixedRate ? value.fixedRate.toString() : undefined,
				};
			}
			executeConversionAction(applicationId, data)
				.then(() => {
					setButtonLoading(false);
					setWarning(false);
					setSubmitted(!submitted);
				})
				.catch((error: ErrorType) => handleOnError(error));
		}
		if (formRef.current) {
			formRef.current.handleReset();
		}
	};

	const createMessage = (value) => {
		if (type === 'internalTransfer') {
			return `You're about to transfer ${value.amount} from ${value.wallet.instrumentId || value.instrumentId}
			Wallet to Wallet with ID ${value.values.destinationWalletId}.`;
		} if (type === 'cryptoPayout') {
			return `You're about to pay out ${value.amount} from ${value.wallet.instrumentId || value.instrumentId}
			Wallet to the following Address ${value.values.destinationAddress}.`;
		} if (type === 'conversion') {
			return `You're about to convert ${value.amount} from ${wallet.instrumentId} Wallet
			to the ${value.toCurrency} Wallet.`;
		}
		return `You're about to ${type} ${value.wallet.instrumentId || value.instrumentId}
		Wallet with ${value.amount}.
		A ${value.fee} fee amount will also be applied.`;
	};

	const continueChanges = (value: Value) => {
		setWarning(true);
		setModal(false);
		setWarningTitle('Confirmation');
		setMessage({
			firstPart: createMessage(value),
		});
		setFooter([
			{ type: 'cancel', action: handleWarningCancel, text: 'go back' },
			{ type: 'continue', action: () => submitCreditOrDebit(value), text: 'confirm' },
		]);
	};

	const exportData = (value) => {
		setButtonLoading(true);
		const params: string = queryString.stringify(value, { arrayFormat: 'bracket' });
		exportWalletTransactionsAction(wallet.id, params)
			.then((res: Object) => {
				setButtonLoading(false);
				setSuccess(true);
				setWarningTitle('Confirmation');
				setModal(false);
				setWarning(true);
				setMessage({ firstPart: res.payload.data.message });
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'close' },
				]);
			})
			.catch((error: ErrorType) => {
				setSuccess(false);
				setModal(false);
				setWarning(true);
				setMessage({
					firstPart: error.error.response.data.errors
						? error.error.response.data.errors[0].messages[0].message
						: error.error.response.data.message,
				});
				setFooter([
					{ type: 'cancel', action: handleErrorCancel, text: 'close' },
				]);
			});
	};

	const getReferenceLink = (reference, referenceId) => {
		const referenceLink = {
			compoundCharge: `/application/${applicationId}/clients/${wallet.userId}?tab=margin`,
			dividendPayout: `/application/${applicationId}/clients/${wallet.userId}/user_payouts/${referenceId}`,
			order: `/application/${applicationId}/orders/${referenceId}`,
			payment: `/application/${applicationId}/payments/${referenceId}`,
			delisting: `/application/${applicationId}/orders/${referenceId}`,
			stockSplit: `/application/${applicationId}/system`,
			cardPayment: `/application/${applicationId}/card_payments/${referenceId}`,
			conversion: `/application/${applicationId}/system/conversions`,
		};
		return referenceLink[reference];
	};

	const getContextText = (reference: string, meta: {[string]: Object}) => {
		const contextText = {
			order: meta
				? `${meta.baseInstrument?.name || '-'} order at ${PriceHelpers.formatAmount(
					meta.price || '-',
					meta.quotingInstrument?.id,
				)}`
				: reference,
			payment: meta
				? `${meta.paymentMethod || ''} ${meta.paymentType || '-'}`
				: reference,
			cardPayment: meta
				? `${meta.merchantName || ''} ${meta.transactionType?.description || '-'}`
				: reference,
			conversion: meta
				? `${meta.fromCurrencyId || '-'} to ${meta.toCurrenctId || '-'} conversion at ${meta.conversionRate || '-'}`
				: reference,
			compoundCharge: 'Margin interest charge',
			delisting: meta
				? `${meta.symbolName || '-'} delisting automatic sale at ${PriceHelpers.formatAmount(
					meta.price || '-',
					meta.quotingInstrument?.id,
				)}`
				: reference,
			dividendPayout: meta
				? `${meta.dividendInstrument?.name || '-'} dividend payout at  ${PriceHelpers.formatAmount(
					meta.payoutAmount || '-',
					meta.payoutInstrument?.id,
				)}`
				: reference,
			split: meta
				? `${meta.baseInstrument?.name || '-'} ${meta.splitRate || '-'} split`
				: reference,
		};
		return contextText[reference];
	};

	const walletColumns = [
		{
			title: 'last updated',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 176,
			render: (text: string) => <span className="wrap">{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
		}, {
			title: 'type',
			dataIndex: 'type',
			key: 'type',
			width: 208,
			render: (text: string, record: WalletTransaction) => (
				<a
					href={getReferenceLink(record.reference, record.referenceId) || `/application/${applicationId}/clients/${wallet.userId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="capitalize">
						{`${record.reference} ${text}`}
					</div>
				</a>
			),
		},
		{
			title: 'amount',
			dataIndex: 'amount',
			key: 'amount',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div className={(!Numbers.sign(text) && record.type !== 'reservation' && 'green-text')
					|| (Numbers.sign(text) && record.type !== 'reservation' && 'red-text') || ''}
				>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
						true,
					)}
				</div>
			),
		},
		{
			title: 'context',
			dataIndex: 'meta',
			key: 'context',
			width: 320,
			render: (meta: {[string]: Object}, record: WalletTransaction) => (
				<div className="capitalize">
					{getContextText(record.reference, meta) || ''}
				</div>
			),
		},
		{
			title: 'new available balance',
			dataIndex: 'available',
			key: 'available',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'available before',
			dataIndex: 'availableBefore',
			key: 'availableBefore',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'debt',
			dataIndex: 'debt',
			key: 'debt',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'debt before',
			dataIndex: 'debtBefore',
			key: 'debtBefore',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'reserved balance',
			dataIndex: 'reserved',
			key: 'reserved',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'reserved before',
			dataIndex: 'reservedBefore',
			key: 'reservedBefore',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'total balance',
			dataIndex: 'total',
			key: 'total',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'total before',
			dataIndex: 'totalBefore',
			key: 'totalBefore',
			width: 176,
			render: (text: string, record: WalletTransaction) => (
				<div>
					{PriceHelpers.formatAmount(
						text,
						record.instrumentId,
					)}
				</div>
			),
		},
		{
			title: 'message',
			dataIndex: 'message',
			key: 'message',
			width: 288,
			render: (text: string) => (
				<div className="wrap">{text || '-'}</div>
			),
		},
		{
			title: 'meta',
			dataIndex: 'meta',
			key: 'meta',
			width: 160,
			render: (meta: Object) => (
				<TableInfoComponent
					content={meta || {}}
					text="Meta"
					name="meta"
				/>

			),
		},
	];

	const overlayComponent = (
		<Menu className="wallet-actions">
			<Menu.Item key="credit" onClick={() => openModal('credit')} disabled={wallet.delisted}>
				<Icon name="Credit" colorName={wallet.delisted ? 'ghost' : 'text'} size={14} />
				<span>Credit Wallet</span>
			</Menu.Item>
			<Menu.Item key="debit" onClick={() => openModal('debit')} disabled={wallet.delisted}>
				<Icon name="Debit" colorName={wallet.delisted ? 'ghost' : 'text'} size={14} />
				<span>Debit Wallet</span>
			</Menu.Item>
			<Menu.Item key="internalTransfer" onClick={() => openModal('internalTransfer')} disabled={wallet.delisted || (wallet.instrument && wallet.instrument.type === 'stock')}>
				<Icon name="InternalTransaction" colorName={wallet.delisted || (wallet.instrument && wallet.instrument.type === 'stock') ? 'ghost' : 'text'} size={14} />
				<span>Internal Transfer</span>
			</Menu.Item>
			<Menu.Item key="conversion" onClick={() => openModal('conversion')} disabled={wallet.delisted || (wallet.instrument && wallet.instrument.type !== 'fiat')}>
				<Icon name="EUR" colorName={wallet.delisted || (wallet.instrument && wallet.instrument.type !== 'fiat') ? 'ghost' : 'text'} size={14} />
				<span>Fiat Conversion</span>
			</Menu.Item>
			<Menu.Item key="cryptoPayout" onClick={() => openModal('cryptoPayout')} disabled={wallet.delisted || (wallet.instrument && wallet.instrument.type !== 'crypto')}>
				<Icon name="BTC" colorName={wallet.delisted || (wallet.instrument && wallet.instrument.type !== 'crypto') ? 'ghost' : 'text'} size={14} />
				<span>Crypto Payout</span>
			</Menu.Item>
			<Menu.Item key="export" onClick={() => openModal('export')} disabled={wallet.delisted}>
				<Icon name="Open" colorName={wallet.delisted ? 'ghost' : 'text'} size={14} />
				<span>Export</span>
			</Menu.Item>
		</Menu>
	);

	const exportOptions = {
		dateRange: { options: 'time', initialValue: [], label: 'time' },
		type: {
			options: StatusHelpers.ledgerType,
			initialValue: StatusHelpers.ledgerType,
			label: 'type',
		},
		reference: {
			options: StatusHelpers.references,
			initialValue: StatusHelpers.references.map((el) => el.id),
			label: 'reference',
		},
		secondaryReference: {
			options: ['orderTransaction'],
			initialValue: ['orderTransaction'],
			label: 'secondary reference',
		},
	};

	const pnl = {
		position: position.positionQuantity,
		unrealizedPnL: PriceHelpers.formatAmount(position.unrealizedPnL, position.quotingInstrument?.id, Numbers.sign(position.unrealizedPnL || '0')),
		marketValue: PriceHelpers.formatAmount(position.marketValue, position.quotingInstrument?.id, Numbers.sign(position.marketValue || '0')),
		side: position.side,
		realizedPnL: PriceHelpers.formatAmount(position.realizedPnL, position.quotingInstrument?.id, Numbers.sign(position.realizedPnL || '0')),
		costBasis: PriceHelpers.formatAmount(position.costBasis, position.quotingInstrument?.id, Numbers.sign(position.costBasis || '0')),
	};

	return (
		<>
			{!loading && !!wallet.accountId
				? (
					<div>
						<Title
							backAction={history.length > 1 ? viewUser : undefined}
							title={`${wallet.instrumentId} Wallet`}
							tags={[wallet.primary && 'primary', wallet.delisted && 'delisted']}
							actions={{
								overlay: overlayComponent,
							}}
						>
							<div className="wallet-switch">
								<span className="switch-text">{displayReservations ? 'hide reservation' : 'show reservation'}</span>
								<Switch
									defaultChecked={displayReservations}
									onChange={(value: boolean) => setDisplayReservations(value)}
								/>
							</div>
						</Title>
						<div className="page-container">
							<div className="value-cards">
								<ValueCard
									value={PriceHelpers.formatAmount(
										wallet.available,
										wallet.instrumentId,
										Numbers.sign(wallet.available),
									)}
									title="available balance"
									styles={{ backgroundColor: '#6EBA1A' }}
								/>
								<ValueCard
									value={PriceHelpers.formatAmount(
										wallet.reserved,
										wallet.instrumentId,
										Numbers.sign(wallet.reserved),
									)}
									title="reserved balance"
									styles={{ backgroundColor: '#FAAD14' }}
								/>
								<ValueCard
									value={PriceHelpers.formatAmount(
										wallet.total,
										wallet.instrumentId,
										Numbers.sign(wallet.total),
									)}
									title="total balance"
									styles={{ backgroundColor: '#7266BA' }}
								/>
								<AccountMarginCard
									value1={{
										name: 'Total Deposit',
										type: 'deposit',
										value: PriceHelpers.formatAmount(
											statistics.walletNet?.totalDeposits || '-',
											statistics.instrumentId || wallet.instrumentId,
											Numbers.sign(statistics.walletNet?.totalDeposits || '0'),
										),
									}}
									value2={{
										name: 'Total Withdrawal',
										type: 'withdrawal',
										value: PriceHelpers.formatAmount(
											statistics.walletNet?.totalWithdrawals || '-',
											statistics.instrumentId || wallet.instrumentId,
											Numbers.sign(statistics.walletNet?.totalWithdrawals || '0'),
										),
									}}
									marginUtilization=""
									styles={{ backgroundColor: '#999999' }}
								/>
								{!!position.side && (
									<PnLCard
										data={pnl}
										styles={{ backgroundColor: '#999999' }}
									/>
								)}
							</div>
							{filteredTransactions.length ? (
								<CustomTable
									columns={walletColumns}
									data={filteredTransactions}
									total={walletTransactionsTotal}
									currentPage={transactionPage}
									handlePageChange={handleTransactionPageChange}
									headerHeight={200}
									place="walletLedger"
									applicationId={applicationId}
								/>
							) : (
								<Card bordered={false}>
									<div className="empty-state">
										<EmptyWallet />
										<p>There are no transactions on this wallet.</p>
									</div>
								</Card>
							)}
						</div>
						{
							(type === 'credit' || type === 'debit' || type === 'internalTransfer' || type === 'cryptoPayout' || type === 'conversion')
								? (
									<FormModal
										title={`${type} ${wallet.instrumentId} Wallet`}
										visible={modal}
										cancelFunction={handleEditCancel}
										form={(
											type === 'conversion'
												? (
													<ConversionForm
														submitChanges={continueChanges}
														handleCancelClick={handleEditCancel}
														wallets={accountWallets}
														place="wallet"
														wrappedComponentRef={formRef}
														accountId={wallet.accountId}
														applicationId={applicationId}
														walletId={wallet.id}
													/>
												)
												: (
													<CreditDebitForm
														subtitle=""
														submitChanges={continueChanges}
														handleCancelClick={handleEditCancel}
														wallets={[wallet]}
														type={type}
														place="wallet"
														wrappedComponentRef={formRef}
													/>
												)
										)}
									/>
								)
								: (
									<FormModal
										destroyOnClose
										title="Export Wallet Ledger"
										visible={modal}
										cancelFunction={handleEditCancel}
										form={(
											<Export
												submitFunction={exportData}
												exportOptions={exportOptions}
												cancelFunction={handleEditCancel}
											/>
										)}
									/>
								)
						}
						<WarningModal
							title={warningTitle}
							visible={warning}
							cancelFunction={handleErrorCancel}
							footer={footer}
							message={message}
							success={success}
							loading={buttonLoading}
						/>
					</div>
				)
				: <Spin spinning={loading} />}
		</>
	);
};

const mapStateToProps = (state: State) => ({
	wallet: state.wallet.wallet,
	walletTransactionsTotal: state.wallet.walletTransactionsTotal,
	walletTransactions: state.wallet.walletTransactions,
	transactionPage: state.ui.transactionPage,
	accountWallets: state.wallet.wallets,
});

const mapDispatchToProps = {
	getWallet,
	getWalletTransactions,
	setCurrentPage,
	debitWallet,
	creditWallet,
	transferToWallet,
	exportWalletTransactions,
	getAccountStatistics,
	getAccountPositions,
	cryptoPayout,
	executeConversion,
	getConversionQuote,
	getWallets,
	createWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);
