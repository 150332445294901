class RegexHelpers {
    static validateURL = (value) => {
    	const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/;
    	const urlChecker = new RegExp(expression);
    	return !!value.match(urlChecker);
    }

	static camelCaseToUnderscore = (value) => {
		return value.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
	}
}

export default RegexHelpers;
