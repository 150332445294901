// @flow

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Row from '../../../components/UiElements/Layout/Row';
import Popover from '../../../components/UiElements/Popover';
import Icon from '../../../components/UiElements/Icon';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import Spin from '../../../components/UiElements/Spin';
// = import actions
import { getAppInstruments, getInstrument } from '../../../modules/actions/InstrumentAction';
import { getAppSymbols } from '../../../modules/actions/SymbolActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
// = import types
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { Fee } from '../../../modules/reducers/FeeReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';

type Values = {
	providerInstanceId: ?string,
	symbolId?: ?string,
	orderType?: ?string,
	orderSide?: ?string,
	instrumentId?: ?string,
	maximumFee?: string,
	minimumFee?: string,
	netFee?: string,
	percentageFee?: string,
}
type Props = {
	form: *,
	fee: Fee,
	submitChanges: (Values) => void,
	handleCancelClick: () => void,
	providers: Array<ProviderInstance>,
	applicationId: string,
	getAppInstruments: (string, string, number, string) => Promise<Object>,
	getInstrument: (string, string) => Promise<Object>,
	getAppSymbols: (string, string, number, string) => Promise<Object>,
}

type LocalState = {
	errorMessage: string,
	hasInstrument: boolean,
	instrument: Instrument,
	hasSymbol: boolean,
	instrumentData: Array<Instrument>,
	instrumentFetching: boolean,
	symbolData: Array<Instrument>,
	symbolFetching: boolean,
	notFoundContent: string,
}

class FeeForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		hasInstrument: !!this.props.fee.instrumentId,
		instrument: {},
		hasSymbol: !!this.props.fee.symbolId,
		instrumentData: [],
		instrumentFetching: false,
		symbolData: [],
		symbolFetching: false,
		notFoundContent: '',
	}

	componentDidMount() {
		const { applicationId, fee, getInstrument: getInstrumentAction } = this.props;
		if (fee.instrumentId) {
			getInstrumentAction(applicationId, fee.instrumentId)
				.then((res) => {
					this.setState({
						instrument: res.payload.data,
						instrumentData: [res.payload.data],
					});
				});
		}
		this.setState({
			hasInstrument: !!fee.instrumentId,
			hasSymbol: !!fee.symbolId,
			errorMessage: fee.instrumentId
				? ''
				: 'When Instrument is not selected, you can set only Percentage Fee to All Instruments.',
		});
	}

	handleInstrumentSearch = debounce((instrumentValue: string) => {
		const { getAppInstruments: getAppInstrumentsAction, applicationId } = this.props;
		this.setState({ instrumentFetching: true });
		if (instrumentValue && instrumentValue.match(StatusHelpers.validInstrument)) {
			const idsFilter = instrumentValue?.toUpperCase();
			const filterOptions: string = queryString.stringify({ ids: idsFilter });
			getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							instrumentData: data,
							instrumentFetching: false,
						});
					} else {
						this.setState({
							instrumentData: [],
							instrumentFetching: false,
							notFoundContent: `Instrument with id ${instrumentValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						instrumentFetching: false,
						instrumentData: [],
						notFoundContent: 'Instrument not found',
					});
				});
		} else {
			this.setState({
				instrumentFetching: false,
				instrumentData: [],
				notFoundContent: 'Enter Valid Id to find the Instrument',
			});
		}
	}, 500);

	handleInstrumentChange = (value: string) => {
		if (value) {
			this.setState((prevState) => {
				const selectInstrument = prevState.instrumentData.filter(
					(instrument) => instrument.instrumentId === value,
				)[0];
				return {
					instrument: selectInstrument,
					hasInstrument: true,
					errorMessage: '',
				};
			});
		} else {
			this.setState({
				hasInstrument: false,
				errorMessage: 'When Instrument is not selected, you can set only Percentage Fee to All Instruments.',
				instrument: {},
			});
		}
	};

	handleSymbolSearch = debounce((symbolValue: string) => {
		const { getAppSymbols: getAppSymbolsAction, applicationId } = this.props;
		this.setState({ symbolFetching: true });
		if (symbolValue) {
			const filterOptions: string = queryString.stringify({ search: symbolValue });
			getAppSymbolsAction(applicationId, 'numbered-pages', 0, filterOptions)
				.then((res) => {
					const { data } = res.payload;
					if (data.length) {
						this.setState({
							symbolData: data,
							symbolFetching: false,
						});
					} else {
						this.setState({
							symbolData: [],
							symbolFetching: false,
							notFoundContent: `Symbol with id ${symbolValue} not found`,
						});
					}
				})
				.catch(() => {
					this.setState({
						symbolFetching: false,
						symbolData: [],
						notFoundContent: 'Symbol not found',
					});
				});
		} else {
			this.setState({
				symbolFetching: false,
				symbolData: [],
				notFoundContent: 'Enter Id to find the Symbol',
			});
		}
	}, 500);

	handleSymbolChange = (value) => {
		if (value) {
			this.setState({
				hasSymbol: true,
			});
		} else {
			this.setState({
				hasSymbol: false,
			});
		}
	};

	handleReset = () => {
		this.props.form.resetFields();
	}

	validateNetFee = (rule, value, callback) => {
		const { form } = this.props;
		const { instrument: { precision } } = this.state;
		const netFee = form.getFieldValue('netFee');
		const percentageFee = form.getFieldValue('percentageFee');
		if (!netFee && !percentageFee) {
			callback('The Fee rule must contain at least one value: Net Fee or Percentage Fee');
		}
		if (value && typeof value !== 'string') {
			if (parseFloat(value.toFixed(precision)) !== value) {
				callback(`Value must have precision less than or equal to ${precision}`);
			}
		}
		callback();
	}

	validatePercentageFee = (rule, value, callback) => {
		const { form } = this.props;
		const netFee = form.getFieldValue('netFee');
		const percentageFee = form.getFieldValue('percentageFee');
		if (value && typeof value !== 'string') {
			if (value > 100) {
				callback('Value has to be between 0 and 100 (%)!');
			}
			if (parseFloat(value.toFixed(2)) !== value) {
				callback('Value must have precision less than or equal to 2');
			}
		}
		if (!netFee && !percentageFee) {
			callback('The Fee rule must contain at least one value: Net Fee or Percentage Fee');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges, fee: { type } } = this.props;
		const { hasSymbol } = this.state;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const tradingFeeData = this.state.hasInstrument
					? {
						...values,
						percentageFee: values.percentageFee
							? values.percentageFee.toString()
							: values.percentageFee,
						providerInstanceId: values.providerInstanceId === 'All Liquidity Providers'
							|| values.providerInstanceId === 'All Payment Providers'
							|| !values.providerInstanceId ? undefined : values.providerInstanceId,
						orderType: values.orderType === 'All Order Types'
							|| !values.orderType ? undefined : values.orderType,
						orderSide: values.orderSide === 'All Order Sides'
							|| !values.orderSide ? undefined : values.orderSide,
						symbolId: hasSymbol ? values.symbolId : null,
					}
					: {
						percentageFee: form.isFieldTouched('percentageFee') && values.percentageFee
							? values.percentageFee.toString()
							: undefined,
						providerInstanceId: values.providerInstanceId === 'All Liquidity Providers'
							|| values.providerInstanceId === 'All Payment Providers'
							|| !values.providerInstanceId ? undefined : values.providerInstanceId,
						orderType: values.orderType === 'All Order Types'
							|| !values.orderSide ? undefined : values.orderType,
						orderSide: values.orderSide === 'All Order Sides'
							|| !values.orderSide ? undefined : values.orderSide,
						symbolId: hasSymbol ? values.symbolId : null,
						instrumentId: null,
					};
				const feeData = this.state.hasInstrument
					? {
						...values,
						netFee: values.netFee
							? values.netFee.toString()
							: values.netFee,
						maximumFee: values.maximumFee
							? values.maximumFee.toString()
							: values.maximumFee,
						minimumFee: values.minimumFee
							? values.minimumFee.toString()
							: values.minimumFee,
						percentageFee: values.percentageFee
							? values.percentageFee.toString()
							: values.percentageFee,
						providerInstanceId: values.providerInstanceId === 'All Liquidity Providers'
						|| values.providerInstanceId === 'All Payment Providers'
						|| !values.providerInstanceId ? undefined : values.providerInstanceId,
					}
					: {
						...values,
						percentageFee: values.percentageFee
							? values.percentageFee.toString()
							: values.percentageFee,
						providerInstanceId: values.providerInstanceId === 'All Liquidity Providers'
							|| values.providerInstanceId === 'All Payment Providers'
							|| !values.providerInstanceId ? undefined : values.providerInstanceId,
					};
				
				submitChanges(type === 'trading' ? tradingFeeData : feeData);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator }, handleCancelClick, fee, providers,
		} = this.props;
		const {
			errorMessage,
			instrument,
			hasInstrument,
			hasSymbol,
			instrumentData,
			instrumentFetching,
			symbolData,
			symbolFetching,
			notFoundContent,
		} = this.state;

		const { Option } = Select;
		const FormItem = Form.Item;
		const feeLayout = {
			labelCol: {
				xs: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};
		const numberLayout = {
			labelCol: {
				xs: { span: 11 },
			},
			wrapperCol: {
				xs: { span: 13 },
			},
		};
		const paymentProviders = providers.filter((el) => el.provider.type === 'payment');
		const tradingProvider = providers.filter((el) => el.provider.type === 'market');
		const subtitle1 = `${fee.type} Fee Parameters`;
		const subtitle2 = `${fee.type} Fee Rules`;
		const parameterRule = fee.type === 'trading'
			? "If you don't select any Liquidity Provider, Order Type or Order Size, the rule will apply as if All is selected. If you don't select any Symbol, the rule will apply to all Symbols for the selected Instrument."
			: "If you don't select any Parameter, the rule will apply as if All is selected.";

		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				{fee.type !== 'cardFx' && (
					<>
						<h4 className="subtitle capitalize">
							{subtitle1}
						</h4>
						<p>{parameterRule}</p>
						{fee.type === 'trading'
							? (
								<div>
									<FormItem label="LIQUIDITY PROVIDER" {...feeLayout}>
										{getFieldDecorator('providerInstanceId', { initialValue: fee.providerInstanceId || 'All Liquidity Providers' })(
											<Select
												showSearch
												placeholder="All Liquidity Providers"
											>
												{tradingProvider.map((provider) => (
													<Option
														value={provider.id}
														key={provider.id}
													>
														{provider.provider.displayName}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="INSTRUMENT" {...feeLayout}>
										<div className="fee-info">
											<Popover
												content="Fee is set on a Symbol level. Selecting an Instrument will apply fee rule to all Symbols for selected instrument."
												overlayClassName="button-popover"
											>
												<div className="info-icon">
													<Icon name="Info" />
												</div>
											</Popover>
											{getFieldDecorator('instrumentId', { initialValue: fee.instrumentId || (hasSymbol ? "Symbol's Quoting Instrument" : 'All Instruments') })(
												<Select
													showSearch
													allowClear
													placeholder={hasSymbol ? "Symbol's Quoting Instrument" : 'All Instruments'}
													disabled={hasSymbol}
													notFoundContent={instrumentFetching
														? <Spin size={16} />
														: notFoundContent}
													filterOption={false}
													onSearch={this.handleInstrumentSearch}
													onChange={this.handleInstrumentChange}
												>
													{instrumentData.map((el) => (
														<Option
															value={el.instrumentId}
															key={el.instrumentId}
														>
															{el.instrumentId}
														</Option>
													))}
												</Select>,
											)}
										</div>
									</FormItem>
									<FormItem label="SYMBOL" {...feeLayout}>
										{getFieldDecorator('symbolId', { initialValue: fee.symbolId || 'All Symbols' })(
											<Select
												showSearch
												allowClear
												placeholder="All Symbols"
												disabled={hasInstrument}
												notFoundContent={symbolFetching ? <Spin size={16} /> : notFoundContent}
												filterOption={false}
												onSearch={this.handleSymbolSearch}
												onChange={this.handleSymbolChange}
											>
												{symbolData.map((symbol) => (
													<Option
														value={symbol.id}
														key={symbol.id}
													>
														{symbol.id}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="ORDER TYPE" {...feeLayout}>
										{getFieldDecorator('orderType', { initialValue: fee.orderType || 'All Order Types' })(
											<Select
												showSearch
												placeholder="All Order Types"
											>
												{StatusHelpers.orderTypes.map((el) => (
													<Option value={el.id} key={el.id}>
														{el.name}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="ORDER SIDE" {...feeLayout}>
										{getFieldDecorator('orderSide', { initialValue: fee.orderSide || 'All Order Sides' })(
											<Select
												showSearch
												placeholder="All Order Sides"
											>
												<Option
													value="buy"
													key="buy"
												>
													Buy
												</Option>
												<Option
													value="sell"
													key="sell"
												>
													Sell
												</Option>
											</Select>,
										)}
									</FormItem>
								</div>
							)
							: (
								<div className="fee-field-right">
									<FormItem label="PAYMENT PROVIDER" {...feeLayout}>
										{getFieldDecorator('providerInstanceId', { initialValue: fee.providerInstanceId || 'All Payment Providers' })(
											<Select
												showSearch
												placeholder="All Payment Providers"
											>
												{paymentProviders.map((provider) => (
													<Option
														value={provider.id}
														key={provider.id}
													>
														{provider.provider.displayName}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
									<FormItem label="INSTRUMENT" {...feeLayout}>
										{getFieldDecorator('instrumentId', { initialValue: instrument.instrumentId || 'All Instruments' })(
											<Select
												showSearch
												allowClear
												placeholder={hasSymbol ? "Symbol's Quoting Instrument" : 'All Instruments'}
												disabled={hasSymbol}
												notFoundContent={instrumentFetching ? <Spin size={16} /> : notFoundContent}
												filterOption={false}
												onSearch={this.handleInstrumentSearch}
												onChange={this.handleInstrumentChange}
											>
												{instrumentData.map((el) => (
													<Option
														value={el.instrumentId}
														key={el.instrumentId}
													>
														{el.instrumentId}
													</Option>
												))}
											</Select>,
										)}
									</FormItem>
								</div>
							)}
					</>
				)}
				<h4 className="subtitle capitalize">
					{subtitle2}
				</h4>
				{fee.type === 'trading' || fee.type === 'cardFx'
					? (

						<FormItem label="PERCENTAGE FEE" {...feeLayout} className="form-item-small">
							{getFieldDecorator('percentageFee', {
								initialValue: fee.percentageFee,
								rules: [{ validator: this.validatePercentageFee }],
							})(
								<InputNumber
									symbol="%"
									placeholder="Set Percentage Fee"
								/>,
							)}
						</FormItem>
					)
					: (
						<Row>
							<FormItem label="PERCENTAGE FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('percentageFee', {
									initialValue: fee.percentageFee,
									rules: [{ validator: this.validatePercentageFee }],
								})(
									<InputNumber
										symbol="%"
										placeholder="Set Percentage Fee"
									/>,
								)}
							</FormItem>
							<FormItem label="MINIMUM FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('minimumFee', {
									initialValue: fee.minimumFee,
									rules: [{ validator: this.validateNetFee }],
								})(
									<InputNumber
										disabled={!hasInstrument}
										symbol={instrument?.instrumentId}
										placeholder="Set Minimum Fee"
									/>,
								)}
							</FormItem>
							<FormItem label="NET FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('netFee', {
									initialValue: fee.netFee,
									rules: [{ validator: this.validateNetFee }],
								})(
									<InputNumber
										disabled={!hasInstrument}
										symbol={instrument?.instrumentId}
										placeholder="Set Net Fee"
									/>,
								)}
							</FormItem>
							<FormItem label="MAXIMUM FEE" {...numberLayout} className="form-item-small">
								{getFieldDecorator('maximumFee', {
									initialValue: fee.maximumFee,
									rules: [{ validator: this.validateNetFee }],
								})(
									<InputNumber
										disabled={!hasInstrument}
										symbol={instrument?.instrumentId}
										placeholder="Set Maximum Fee"
									/>,
								)}
							</FormItem>
						</Row>
					)}
				{errorMessage && fee.type !== 'trading' && fee.type !== 'cardFx' && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} text="edit fee" />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const EditFeeForm = Form.create()(FeeForm);

const mapDispatchToProps = {
	getAppInstruments,
	getInstrument,
	getAppSymbols,
};

export default connect(null, mapDispatchToProps)(EditFeeForm);
