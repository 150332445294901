// @flow

import React from 'react';

type IconStyle = {
    width?: string,
    height?: string,
	color?: string,
	background?: string,
}

const Carousel = ({
	width = '162px',
	height = '87px',
	color = '#E3E4E5',
	background = 'none',
}: IconStyle) => (
	<svg width={width} height={height} viewBox="0 0 162 87" version="1.1">
		<title>carousel</title>
		<desc>Created with Sketch.</desc>
		<g id="carousel" stroke="none" strokeWidth="1" fill={background} fillRule="evenodd">
			<g id="Group">
				<rect id="Rectangle" x="0" y="0" width="162" height="87" rx="4" />
				<path d="M152,30.7931034 L152,77 L123.141935,77 C121.371061,77 119.935484,75.5518727 119.935484,73.7655172 L119.935484,34.0275862 C119.935484,32.2412307 121.371061,30.7931034 123.141935,30.7931034 L152,30.7931034 Z M12.2903226,10 L81.916129,10 C83.1810393,10 84.2064516,11.0254123 84.2064516,12.2903226 L84.2064516,19.2614016 C84.2064516,20.5263118 83.1810393,21.5517241 81.916129,21.5517241 L12.2903226,21.5517241 C11.0254123,21.5517241 10,20.5263118 10,19.2614016 L10,12.2903226 C10,11.0254123 11.0254123,10 12.2903226,10 Z M13.2064516,30.7931034 L52.6,30.7931034 C54.3708743,30.7931034 55.8064516,32.2286807 55.8064516,33.9995551 L55.8064516,73.7935484 C55.8064516,75.5644227 54.3708743,77 52.6,77 L13.2064516,77 C11.4355773,77 10,75.5644227 10,73.7935484 L10,33.9995551 C10,32.2286807 11.4355773,30.7931034 13.2064516,30.7931034 Z M68.1741935,30.7931034 L107.567742,30.7931034 C109.338616,30.7931034 110.774194,32.2286807 110.774194,33.9995551 L110.774194,73.7935484 C110.774194,75.5644227 109.338616,77 107.567742,77 L68.1741935,77 C66.4033192,77 64.9677419,75.5644227 64.9677419,73.7935484 L64.9677419,33.9995551 C64.9677419,32.2286807 66.4033192,30.7931034 68.1741935,30.7931034 Z" id="Combined-Shape" fill={color} />
			</g>
		</g>
	</svg>
);

Carousel.defaultProps = {
	width: '162px',
	height: '87px',
	color: '#E3E4E5',
	background: 'none',
};

export default Carousel;
