import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//= import ant's css
import 'ant-design-pro/dist/ant-design-pro.css';
//= import components
import Layout from '../../components/UiElements/Layout';
import Sidebar from '../../components/UiElements/Layout/Sidebar';
import ApplicationRoutes from './ApplicationRoutes';
import ErrorBoundary from '../../ErrorBoundary';
import AppBreadcrumb from '../../components/UiElements/Breadcrumb';
//= import methods
import { getApplication } from '../../modules/actions/ApplicationActions';
import { clearFilters } from '../../modules/actions/UiActions';
//= import types
import type { State } from '../../modules/types/FlowTypes';
import type { CustomerType } from '../../modules/reducers/OauthReducer';
import type { Application } from '../../modules/reducers/ApplicationReducer';

type Props = {
	customer: CustomerType,
	match: {
		path: string,
		params: {
			applicationId: string
		}
	},
	location: {
		pathname: string,
	},
	application: Application;
	getApplication: (string) => void,
	clearFilters: () => void,
}

const InApplication = (props: Props) => {
	const {
		customer,
		application,
		getApplication: getApplicationAction,
		clearFilters: clearFiltersAction,
		match: { params: { applicationId } },
		location,
	} = props;

	useEffect(() => {
		async function fetchData() {
			const res = await getApplicationAction(customer.referenceId, applicationId);
			const currency = res.payload.data ? res.payload.data.customer.reportingCurrency : '';
			localStorage.setItem('customer', JSON.stringify({ reportingCurrency: currency }));
		}
		fetchData();
	}, [
		getApplicationAction,
		applicationId,
		customer.referenceId,
	]);

	const selected = location.pathname.split('/')[3];

	useEffect(() => {
		if (selected !== 'payments' && selected !== 'clients') {
			clearFiltersAction();
		}
	}, [selected, clearFiltersAction]);

	return (
		<Layout>
			<Sidebar
				applicationId={applicationId}
				selected={selected}
				applicationName={application.name}
			/>
			{application && customer
				&& (
					<Layout>
						<ErrorBoundary applicationId={applicationId} applicationName={application.name}>
							<AppBreadcrumb location={location} applicationId={applicationId} />
							<div className="layout">
								<ApplicationRoutes />
							</div>
						</ErrorBoundary>
					</Layout>
				)}
		</Layout>
	);
};

const mapStateToProps = (state: State) => ({
	customer: state.oauth.customer,
	application: state.application.application,
});

const mapDespatchToProps = {
	getApplication,
	clearFilters,
};
export default connect(mapStateToProps, mapDespatchToProps)(InApplication);
